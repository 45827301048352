import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { set } from '../../store/actions/pages';
import { create, getByName, update } from '../../store/actions/search';

import { selectSessionUserId } from '../../store/selectors/session';
import { fromLocalStorage, toLocalStorage } from '../../util/storage';
import PagedTableCompoment from './PagedTable';

export const PagedTable = connect(
    () => {
        const selectPageName = (state, props) => props.name;

        const selectTable = createSelector(
            (state, props) => props.name,
            (state) => state.pages,
            (name, pages) => pages[name]
        );

        const selectData = createSelector(
            selectTable,
            (state, props) => props.data,
            (table, data) => {
                if (data) {
                    return data;
                }
                return table.list;
            }
        );

        const selectFilter = createSelector(
            selectTable,
            (state, props) => props.defaultFilter,
            (table, defaultFilter) => {

                if (table.filter.length === 0 && defaultFilter) {
                    return [...defaultFilter];
                }
                return table.filter;
            }
        );

        const selectTimeFilter = createSelector(
            selectTable,
            (table) => table.timeFilter,
        );

        const selectSort = createSelector(
            selectTable,
            (state, props) => props.defaultSort,
            (table, defaultSort) => {
                if (table.sort.length === 0) {
                    return [...defaultSort];
                }
                return table.sort;
            },
        );

        const selectPagination = createSelector(
            selectTable,
            (table) => table.page,
        );

        const selectIsCustomFilterSetEmpty = createSelector(
            selectTable,
            (table) => table.isCustomFilterSetEmpty,
        );

        const selectCustomFilter = createSelector(
            selectTable,
            (state, props) => props.defaultCustomFilter,
            selectIsCustomFilterSetEmpty,
            (table, defaultCustomFilter, isCustomFilterSetEmpty) => {
                if (table.customFilter.length === 0 && (defaultCustomFilter && defaultCustomFilter.length > 0)) {
                    if (isCustomFilterSetEmpty) {
                        return []
                    } else {
                        return [...defaultCustomFilter];
                    }
                }
                return table.customFilter;
            }
        );

        const hasCustomFilter = createSelector(
            selectTable,
            (table) => table.customFilter.length > 0,
        );

        const selectPending = createSelector(
            (state, props) => props.name,
            (state) => state.pages,
            (state) => state.search.getByName.pending,
            (name, pages, searchPending) => {
                return pages[name].pending || searchPending
            }
        );
        const selectError = createSelector(
            (state, props) => props.name,
            (state) => state.pages,
            (name, pages) => pages[name].error,
        );


        const selectName = createSelector(
            (state, props) => props.title,
            (name) => name,
        );

        const selectCriteria = createSelector(
            selectTable,
            (table) => table.selectedFilter,
        );

        const selectWaitForCriteriaFilter = createSelector(
            selectTable,
            (table) => {
                return table.waitForCriteriaFilter ?? false;
            }
        );

        const selectHiddenFields = createSelector(
            selectTable,
            selectSessionUserId,
            selectPageName,
            (state, props) => props.defaultHiddenFields,
            (state, props) => props.showFilterColumnsDialog,
            (state, props) => props.fields,
            (table, userId, pageName, defaultHiddenFields, haveFilterDialog, allFields) => {
                if (haveFilterDialog) {
                    const pagesData = fromLocalStorage(`${userId}-slicedhealth-table-data-v2`);
                    if (pagesData) {
                        if (pagesData[pageName] && pagesData[pageName].hiddenFields) {
                            return pagesData[pageName].hiddenFields
                        }
                        const temp = table.hiddenFields ?? [...defaultHiddenFields];
                        toLocalStorage(`${userId}-slicedhealth-table-data-v2`,
                            {
                                ...pagesData,
                                [pageName]: {
                                    ...pagesData[pageName],
                                    hiddenFields: temp
                                }
                            }
                        );
                        return temp;
                    }
                }
                return []
            },
        );

        const selectOrderPreference = createSelector(
            selectTable,
            selectSessionUserId,
            selectPageName,
            (state, props) => props.showReorderColumnsDialog,
            (table, userId, pageName, haveReorderDialog) => {
                if (haveReorderDialog) {
                    const pagesData = fromLocalStorage(`${userId}-slicedhealth-table-data-v2`);
                    if (pagesData && pagesData[pageName] && pagesData[pageName].orderPreference) {
                        return pagesData[pageName].orderPreference
                    }
                }
                return []
            },
        );

        return (state, props) => ({
            sort: selectSort(state, props),
            filterData:selectTable(state,props),
            data: selectData(state, props),
            filter: selectFilter(state, props),
            customFilter: selectCustomFilter(state, props),
            criteria: selectCriteria(state, props),
            hasCustomFilter: hasCustomFilter(state, props),
            timeFilter: selectTimeFilter(state, props),
            pagination: selectPagination(state, props),
            filterSaveState: state.search.create,
            name: selectName(state, props),
            pageName: selectPageName(state, props),
            error: selectError(state, props),
            pending: selectPending(state, props),
            hiddenFields: selectHiddenFields(state, props),
            orderPreference: selectOrderPreference(state, props),

            waitForCriteriaFilter: selectWaitForCriteriaFilter(state, props),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setFilter: (value) => set(props.name, 'filter', value),
            setPage: (value) => set(props.name, null, value),
            setTimeFilter: (value) => set(props.name, 'timeFilter', value),
            setSort: (value) => set(props.name, 'sort', value),
            setPagination: (value) => set(props.name, 'pagination', value),
            setCustomFilter: (value) => set(props.name, 'customFilter', value),
            setSelectedFilter: (value) => set(props.name, 'selectedFilter', value),
            setListData: (value) => set(props.name, 'list', value),
            saveCriteriaFilter: create,
            updateCriteriaFilter: update,
            searchGetByName: getByName,
        }, dispatch)
    })
)(injectIntl(PagedTableCompoment));