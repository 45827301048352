import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { get, resetStats, setStatisticsFilter } from '../../../../store/actions/statistics';
import { COLORS } from '../../../../store/constants/color';
import { RootState } from '../../../../store/reducers';
import { selectClientListTiny } from '../../../../store/selectors/clients';
import { selectFilters } from '../../../../store/selectors/statistics';
import { asDate, getLastNDays, last12MonthsArr } from '../../../../util/date';
import TrimStatsPageComponent from './TrimStatsPage';

const selectStatisticsObject = createSelector(
    state => state.statistics.stats,
    (stats) => {
        if (stats && stats.length > 0) {
            return stats.reduce((result, stat) => {
                return {
                    totalFilesUploaded: result.totalFilesUploaded + stat.totalFilesUploaded,
                    totalInstitutionalCharges: result.totalInstitutionalCharges + stat.totalInstitutionalCharges,
                    totalProfessionalCharges: result.totalProfessionalCharges + stat.totalProfessionalCharges,
                    totalClaimPayments: result.totalClaimPayments + stat.totalClaimPayments,
                    totalClaimServicePayments: result.totalClaimServicePayments + stat.totalClaimServicePayments,
                    totalProviderAdjustments: result.totalProviderAdjustments + stat.totalProviderAdjustments,
                };
            });
        } else {
            return {
                totalFilesUploaded: 0,
                totalInstitutionalCharges: 0,
                totalProfessionalCharges: 0,
                totalClaimPayments: 0,
                totalClaimServicePayments: 0,
                totalProviderAdjustments: 0,
            };
        }
    }
);

const formData = (_client, _last12Months, _jsonData, _freq) => {

    const filteredItems = _jsonData.filter(item => item.clientId === _client.clientId);
    return _last12Months.map(month => {

        const monthData = filteredItems.filter(item => {
            if (_freq === 'daily') {
                return new Date(`${item.runDate}`).getDate() === new Date(`${month.key}`).getDate()
                    && new Date(`${item.runDate}`).getMonth() === new Date(`${month.key}`).getMonth()
                    && new Date(`${item.runDate}`).getFullYear() === new Date(`${month.key}`).getFullYear()
            } else {
                return new Date(`${item.runDate}`).getMonth() === new Date(`${month.label}`).getMonth()
                    && new Date(`${item.runDate}`).getFullYear() === new Date(`${month.label}`).getFullYear()
            }
        }
        );

        let dataObj = {
            totalFilesUploaded: 0,
            totalInstitutionalCharges: 0,
            totalProfessionalCharges: 0,
            totalClaimPayments: 0,
            totalClaimServicePayments: 0,
            totalProviderAdjustments: 0,
        };
        if (monthData && monthData.length > 0) {
            dataObj = monthData.reduce((result, stat) => {
                return {
                    totalFilesUploaded: result.totalFilesUploaded + stat.totalFilesUploaded,
                    totalInstitutionalCharges: result.totalInstitutionalCharges + stat.totalInstitutionalCharges,
                    totalProfessionalCharges: result.totalProfessionalCharges + stat.totalProfessionalCharges,
                    totalClaimPayments: result.totalClaimPayments + stat.totalClaimPayments,
                    totalClaimServicePayments: result.totalClaimServicePayments + stat.totalClaimServicePayments,
                    totalProviderAdjustments: result.totalProviderAdjustments + stat.totalProviderAdjustments,
                };
            });
        }
        return {
            y: dataObj?.totalFilesUploaded,
            otherInfo: dataObj
        }
    })
}

const formDataWeekly = (_client, _lastFourQuarters, _jsonData) => {
    const filteredItems = _jsonData.filter(item => item.clientId === _client.clientId);
    return _lastFourQuarters.map(month => {
        const monthData = filteredItems.filter(item => new Date(item.runDate) >= new Date(month.startDate) && new Date(item.runDate) <= new Date(month.endDate));
        let dataObj = {
            totalFilesUploaded: 0,
            totalInstitutionalCharges: 0,
            totalProfessionalCharges: 0,
            totalClaimPayments: 0,
            totalClaimServicePayments: 0,
            totalProviderAdjustments: 0,
        };
        if (monthData && monthData.length > 0) {
            dataObj = monthData.reduce((result, stat) => {
                return {
                    totalFilesUploaded: result.totalFilesUploaded + stat.totalFilesUploaded,
                    totalInstitutionalCharges: result.totalInstitutionalCharges + stat.totalInstitutionalCharges,
                    totalProfessionalCharges: result.totalProfessionalCharges + stat.totalProfessionalCharges,
                    totalClaimPayments: result.totalClaimPayments + stat.totalClaimPayments,
                    totalClaimServicePayments: result.totalClaimServicePayments + stat.totalClaimServicePayments,
                    totalProviderAdjustments: result.totalProviderAdjustments + stat.totalProviderAdjustments,
                };
            });
        }
        return {
            y: dataObj?.totalFilesUploaded,
            otherInfo: dataObj
        }
    })
}

const selectDataset = createSelector(
    selectFilters,
    selectClientListTiny,
    state => state.statistics.stats,
    (filters, tinyClients, stats) => {
        if (filters.frequency === 'monthly') {
            const last12Months = last12MonthsArr().reverse();
            let dataset = {
                labels: last12Months.map(item => item.label),
                datasets: tinyClients.map((client, index) => {
                    const randonColor = COLORS[index];
                    return {
                        label: tinyClients.find(clt => clt.clientId === client?.clientId)?.name,
                        backgroundColor: randonColor,
                        borderColor: randonColor,
                        borderWidth: 1,
                        data: formData(client, last12Months, stats, 'monthly'),
                    }
                })
            };

            return dataset;
        } else if (filters.frequency === 'daily') {
            const lastNDays = getLastNDays(7);
            let dataset = {
                labels: lastNDays.map(item => asDate(item.key)),
                datasets: tinyClients.map((client, index) => {
                    const randonColor = COLORS[index];
                    return {
                        label: tinyClients.find(clt => clt.clientId === client?.clientId)?.name,
                        backgroundColor: randonColor,
                        borderColor: randonColor,
                        borderWidth: 1,
                        data: formData(client, lastNDays, stats, 'daily'),
                    }
                })
            };

            return dataset;
        } else if (filters.frequency === 'weekly') {
            const last28Days = getLastNDays(28).reverse();

            const chunk = (target, size) => {
                return target.reduce((memo, value, index) => {
                    // Here it comes the only difference
                    if (index % (target.length / size) == 0 && index !== 0) memo.push([])
                    memo[memo.length - 1].push(value)
                    return memo
                }, [[]])
            }

            const quarters = chunk(last28Days, 4)

            const quartersModified = quarters.map(qtr => {
                return {
                    label: `${asDate(qtr[6].key)} to ${asDate(qtr[0].key)}`,
                    startDate: qtr[6].key,
                    endDate: qtr[0].key,
                }
            }).reverse()

            let dataset = {
                labels: quartersModified.map(item => item.label),
                datasets: tinyClients.map((client, index) => {
                    const randonColor = COLORS[index];
                    return {
                        label: tinyClients.find(clt => clt.clientId === client?.clientId)?.name,
                        backgroundColor: randonColor,
                        borderColor: randonColor,
                        borderWidth: 1,
                        data: formDataWeekly(client, quartersModified, stats),
                    }
                })
            };

            return dataset;
        }
    }
)

export const TrimStatsPage = injectIntl(
    connect(
        (state : RootState) => ({
            stats: selectStatisticsObject(state),
            dataset: selectDataset(state),
            filters: selectFilters(state),
            clients: selectClientListTiny(state),

            getStatsPending: state.statistics.get.pending,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                setStatisticsFilter: setStatisticsFilter,
                statisticsGet: get,
                resetStats: resetStats
            }, dispatch)
        })
    )(TrimStatsPageComponent));