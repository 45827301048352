import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import ReorderIcon from "@material-ui/icons/Reorder";
import { reorderArray } from '../../util';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? "#94c83d" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});

function ReorderColumns(props) {
    const { open, handleDialogClose, getFields, handleColumnsReorderApply } = props;

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (open) {
            setItems(getFields());
        }
    }, [getFields, setItems, open])

    const handleClose = () => {
        handleDialogClose();
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const temp = reorderArray(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(temp)
    }

    const applyOrder = () => {

        handleColumnsReorderApply(items)
    }

    return (
        <div>
            <Dialog fullWidth open={open}>
                <DialogTitle>
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            Reorder Columns
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="reorder-columns">
                            {(provided) => (

                                <List
                                    className='p-0'
                                    ref={provided.innerRef} {...provided.droppableProps}
                                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                    subheader={<ListSubheader>Drag to reorder columns</ListSubheader>}
                                >

                                    {items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <ListItem
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <ListItemIcon>
                                                        <ReorderIcon />
                                                    </ListItemIcon>
                                                    <ListItemText id={item.label} primary={item.label} />
                                                </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DialogContent>
                <DialogActions>
                    <Grid container className='justify-content-end py-2 px-3'>
                        <Button onClick={applyOrder} color="primary" variant='contained'>
                            Apply
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ReorderColumns
