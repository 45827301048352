import React from 'react';
import { ErrorDetails } from '../errorDetails';
import 'react-toastify/dist/ReactToastify.css';

const ToastMessage = (props) => {
    const { errorMessage, innerException, errorDetails } = props;

    const extraErrorDetails = innerException?.length > 0 || errorDetails?.length > 0 ?
        { innerException, errorDetails }
        : null;

    return (
        <>
            {errorMessage}
            {extraErrorDetails && <ErrorDetails extraDetails={extraErrorDetails} />}
        </>
    )
}

export default ToastMessage
