import {
    METHOD_GET,
    METHOD_POST,
    METHOD_DELETE,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../headers';
import { apiHandleResponse } from '../../util/api';

const listDashboardFilters = () => {
    let endpoint = `${SERVER_URL}/api/v1/dashboard/filters`;
    return fetch(endpoint, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getDashboardFiltersTiny = () => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/filters/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getDashboardFilter = (dashboardFilterId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/filter/${dashboardFilterId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createDashboardFilter = (dashboardFilter) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/filter`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(dashboardFilter)
    }).then(apiHandleResponse);
};

const updateDashboardFilter = (dashboardFilter) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/filter`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(dashboardFilter)
    }).then(apiHandleResponse);
};

const deleteDashboardFilter = (dashboardFilterId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/filter/${dashboardFilterId}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
}

export {
    listDashboardFilters,
    getDashboardFiltersTiny,
    getDashboardFilter,
    createDashboardFilter,
    updateDashboardFilter,
    deleteDashboardFilter,
};