import React from 'react';
import HeaderColumn from '../HeaderColumn';
import { Checkbox, makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    fixedCell: {
        zIndex: 3
    }
}));


export default function Header(props) {
    const classes = useStyles();

    const {
        fields, hasCheckbox, showSelectAll, hasActions, hasRadio,
        onSort, columnOrder, direction, customActionColumnName,
        onSelectAll, selectAllChecked, data = [], isCollapsible, isDnDEnabled
    } = props;

    return (
        <>
            {isDnDEnabled && (<
                HeaderColumn field={{ label: '' }} />
            )}
            {isCollapsible && (<
                HeaderColumn field={{ label: '' }} />
            )}
            {showSelectAll && (
                <TableCell className={classes.fixedCell}>
                    <Checkbox
                        color="primary"
                        checked={selectAllChecked}
                        onChange={(event) => onSelectAll(event)}
                        tabIndex={-1}
                        disableRipple
                        disabled={data.length === 0}
                    />
                </TableCell>
            )}
            {((hasRadio || hasCheckbox) && !(showSelectAll)) && (
                <TableCell>&nbsp;</TableCell>
            )}
            {fields.map((field, index) => (
                !field?.options.hideFromGrid && < HeaderColumn
                    key={`column-${index}`}
                    field={field}
                    onSort={onSort}
                    direction={direction}
                    columnOrder={columnOrder}
                />
            ))}
            {hasActions && (
                <HeaderColumn field={{ label: customActionColumnName ?? 'Actions' }} />
            )}
        </>
    )
}