import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import ResetPasswordComponent from './ResetPassword.js';
import { resetPassword, verifyToken } from '../../../store/actions/forgotPassword';
import { selectResetPasswordPending, selectResetPasswordError, selectResetPasswordErrorMessage, selectToken } from '../../../store/selectors/forgotPassword';

export const ResetPasswordPage = connect(
    () => {
        const selectUrlToken = (state, props) => new URLSearchParams(props.location.search).get('token');
        return (state, props) => ({
            tokenToverify: selectUrlToken(state, props),
            resetPasswordToken: selectToken(state),
            resetPasswordPending: selectResetPasswordPending(state),
            resetPasswordError: selectResetPasswordError(state),
            resetPasswordErrorMessage: selectResetPasswordErrorMessage(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            verifyToken: verifyToken,
            resetPassword: resetPassword,
        }, dispatch)
    })
)(injectIntl(ResetPasswordComponent));