import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { set } from '../../../../store/actions/pages';
import {getFiles} from '../../../../store/actions/statistics';
import { selectFiles } from '../../../../store/selectors/statistics';

import { createPlainDateField, createStringDataField } from '../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import FilesGridComponent from './FilesGrid';

const DEFAULT_SORT = [{ id: 'displayName', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('displayName', 'Display Name', { sortable: true }),
        createPlainDateField('lastReceived', 'Last Received', { sortable: true }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`statistics-files-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`statistics-files-landing`].filter,
    selectSort,
    selectFiles,
);

export const FilesGrid = connect(
    () => {
        return (state, props) => ({
            statisticsGetFilesPending: state.statistics.getFiles.pending,
            statisticsGetFilesError: state.statistics.getFiles.error,
            statisticsGetFilesErrorMessage: state.statistics.getFiles.errorMessage,
            fields: selectFields(state as never),
            files: selectFiles(state),
            filteredData: selectFilteredData(state),
            order: state.pages[`statistics-files-landing`].order,
            filter: state.pages[`statistics-files-landing`].filter,
            sort: selectSort(state, props),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            statisticsGetFiles: getFiles,
            setFilter: (value) => set(`statistics-files-landing`, 'filter', value),
            setOrder: (value) => set(`statistics-files-landing`, 'order', value),
            setSort: (value) => set(`statistics-files-landing`, 'sort', value),
        }, dispatch)
    })
)(injectIntl(FilesGridComponent));