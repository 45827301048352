import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import CloneRuleComponent from './CloneRule';
import { getContractedServices, tiny } from '../../../../../../store/actions/contracts';
import { selectContractedService, selectTiny } from '../../../../../../store/selectors/contracts';
import { ruleClone } from '../../../../../../store/actions/rules';

export const CloneRule = connect(
    () => {

        const selectRuleId = createSelector(
            state => state.rules.cloneRuleDialog,
            (cloneRuleDialog) => {
                return cloneRuleDialog?.ruleId ?? null;
            }
        )

        const selectServiceTypeObj = createSelector(
            state => state.rules.cloneRuleDialog,
            (cloneRuleDialog) => {
                return cloneRuleDialog?.serviceTypeObj ?? null;
            }
        )

        const selectRuleName = createSelector(
            state => state.rules.cloneRuleDialog,
            (cloneRuleDialog) => {
                return cloneRuleDialog?.ruleName ?? '';
            }
        )

        const selectContractId = createSelector(
            state => state.rules.cloneRuleDialog,
            (cloneRuleDialog) => {
                return cloneRuleDialog?.contractId ?? '';
            }
        )

        const selectOpen = createSelector(
            state => state.rules.cloneRuleDialog,
            (cloneRuleDialog) => {
                if (cloneRuleDialog) {
                    return cloneRuleDialog?.open
                }
                return false
            }
        )

        const selectInitialValues = createSelector(
            selectRuleName,
            selectContractId,
            selectTiny,
            (ruleName, contractId, contracts) => {
                return {
                    newRuleName: ruleName,
                    targetContractId: contracts.find(item => item.id === parseInt(contractId)) ?? null,
                }
            }
        )

        const selectSortedContract = createSelector(selectTiny,
            (contracts) => {
                return contracts.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            ruleName: selectRuleName(state),
            ruleId: selectRuleId(state),
            initialValues: selectInitialValues(state, props),
            contracts: selectSortedContract(state),
            contractedServices: selectContractedService(state),
            getContractsPending: state.contracts.getTiny.pending,
            getContractedServicePending: state.contracts.getContractedServices.pending,
            cloneRulePending: state.rules.cloneRule.pending,
            serviceTypeObj: selectServiceTypeObj(state),
            existingContractId: selectContractId(state, props)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getTinyContracts: tiny,
            getContractedServices: getContractedServices,
            ruleClone: ruleClone
        }, dispatch)
    })
)(injectIntl(CloneRuleComponent));