import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { exportPayer, importPayerInfo, payerImportErrorReset } from '../../../store/actions/payers';
import PayerImportComponent from './PayerImport';
import { createCurrencyDataField, createIconDataField, createNumberDataField, createStringDataField } from '../../../util/format';
import { integerEquators, StringEquators } from '../../../store/constants/pages';
import { set } from '../../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../../util/selector';

export const PayerImport = connect(
    () => {
        const selectPayerContracts = (state) => state.payers.payerContracts;
        const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

        const selectFields = createSelector(
            selectPayerContracts,
            (payerContracts) => [
                createStringDataField('name', 'Payer Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                createStringDataField('type', 'Type', { sortable: true, equators: StringEquators }),
                createStringDataField('payerContract', 'Payer Contract', { sortable: true, equators: StringEquators }),
                //createStringDataField('payerContract.displayName', 'Payer Contract', { isDropdown: true, dropdownValues: payerContracts.map(pc => pc.displayName), sortable: true, equators: StringEquators, secondLevel: 'displayName' }),
                createStringDataField('claimTypes', 'Claim Types', { sortable: true, equators: StringEquators }),
                createNumberDataField('totalClaimCount', 'Total Claims', { sortable: true, equators: integerEquators }),
                createCurrencyDataField('totalClaimAmount', 'Total Claim Amount', { sortable: true, equators: integerEquators }),
                createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
            ]
        );
        
        const selectPayersData = createSelector(
            (state) => state.payers.payerImports,
            (data) => {
                return data;
            }
        );

        const selectFilteredFields = createSelector(
            selectFields,
            (fields) => {
                return fields.map(field => field.id);
            }
        );
        
        const selectSort = createSelector(
            (state, props) => state.pages[`claim-payers-import-landing`].sort,
            (sort) => sort || DEFAULT_SORT,
        );
        
        const selectFilteredData = createFilteredSortedDataSelector(
            selectFilteredFields,
            (state, props) => state.pages[`claim-payers-import-landing`].filter,
            selectSort,
            selectPayersData,
        );

        return (state, props) => ({
            fields: selectFields(state),
            filteredData: selectFilteredData(state),
            payerImportPending: state.payers.getPayerImport.pending,
            order: state.pages[`claim-payers-import-landing`].order,
            filter: state.pages[`claim-payers-import-landing`].filter,
            sort: selectSort(state, props),
            payerExportPending: state.payers.exportPayer.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            importPayerInfo: importPayerInfo,
            payerImportErrorReset: payerImportErrorReset,
            exportPayers: exportPayer,
            setList: (value) => set('claim-payers-import-landing', 'list', value),
            setFilter: (value) => set('claim-payers-import-landing', 'filter', value),
            setSort: (value) => set('claim-payers-import-landing', 'sort', value),
            setOrder: (value) => set('claim-payers-import-landing', 'order', value),
        }, dispatch)
    })
)(injectIntl(PayerImportComponent));