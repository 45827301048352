import { createSelector } from "reselect";

// Vendor Management -- Selectors
export const selectDepartments = (state) => state.vendors.departments.filter(item => item !== null);
export const selectGLCodes = (state) => state.vendors.glCodes.filter(item => item !== null);
export const selectLocations = (state) => state.vendors.locations.filter(item => item !== null);
export const selectResourceTypes = (state) => state.vendors.resourceTypes.filter(item => item !== null);

export const selectVendorContractData = (state) => state.vendors.data;


export const selectVendorContract = createSelector(
    selectVendorContractData,
    (contract) => contract,
);

export const createVendorContractSelector = (selectVendorContractId) => createSelector(
    selectVendorContractId,
    selectVendorContract,
    (contractId, vendorContracts) => vendorContracts.find((contract) => contract.id === parseInt(contractId))
);
