import { IconButton, makeStyles, TablePagination, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from "react";
import { DataFilter, DataTable, Loadable } from '../../common';
import { TablePaginationActions } from '../../common/tablePaginationActions';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from "../../store/constants/roles";
import { DependencyDialog } from "./DependencyDialog";
import { FacilityDialog } from './FacilityDialog';

const useStyles = makeStyles(theme => {
    return {
        underline: {
            textDecoration: 'underline',
        },
        textField: {
            width: 400,
        },
        button: {
            margin: theme.spacing(1),
        },
    }
});

export default function FacilitiesPage(props) {
    const { facilityPending, fields, filteredData, order, sort, history, facilityActivatePending, facilityDeactivatePending,
        userRole,
        actions: { getFacilities, setFilter, setSort, setOrder, createFacilityReset, facilityReset,
            setFacilityDependencyDialog, deactivateFacility, activateFacility, setList }
    } = props;
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [facility, setFacility] = useState(null);
    const [dialogTitle, setDialogTitle] = useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getFacilities();

        return function cleanup() {
            facilityReset();
        }
    }, [getFacilities]);

    const handleClose = (shouldReloadGrid) => {
        setOpenDialog(false);
        createFacilityReset();
        if (shouldReloadGrid) {
            handleCallBack();
        }
    }

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleAddFacility() {
        setOpenDialog(true);
        setFacility(null);
    }

    const handleFacilityEdit = (facility) => {
        setOpenDialog(true);
        setFacility(facility);
    }

    const handleCallBack = () => {
        setList([]);
        getFacilities();
    }

    const handleFacilityDependencyDialog = ({ id, name }) => {
        setFacilityDependencyDialog({ facilityId: id, open: true, name: name });
    }

    const handleEnableDisable = ({ id, active }) => {
        if (active) {
            deactivateFacility(id, handleCallBack);
        } else {
            activateFacility(id, handleCallBack);
        }
    }

    function facilityActions(rowData) {
        return <div className='d-flex'>
            {(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN) &&
                <>
                    <IconButton
                        color="primary"
                        className='p-1'
                        aria-label="Edit"
                        onClick={() => handleFacilityEdit(rowData)}
                    >
                        <Tooltip title="Edit">
                            <EditIcon />
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        onClick={() => handleFacilityDependencyDialog(rowData)}
                        color={"primary"}
                        className='p-1'
                        aria-label="Delete"
                    >
                        <Tooltip title={"Delete Facility"} >
                            <DeleteIcon />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        color="primary"
                        className='p-1'
                        aria-label="Follow"
                        onClick={() => handleEnableDisable(rowData)}
                    >
                        <Tooltip title={rowData.active ? 'Deactivate' : 'Activate'}>
                            {rowData.active ? (
                                <i className="fa fa-check-circle text-success font-18"></i>
                            ) : (
                                <i className="fa fa-check-circle text-secondary font-18"></i>
                            )}
                        </Tooltip>
                    </IconButton>
                </>
            }
        </div>
    }

    function handleRefresh() {
        getFacilities()
    }


    return (
        <>
            <Loadable loading={facilityPending || facilityActivatePending || facilityDeactivatePending}>
                <Portlet className={classes.portlet}>
                    <PortletHeader
                        title="Facilities"
                        toolbar={
                            <PortletHeaderToolbar>
                                <DataFilter
                                    onChange={handleSearchInput}
                                    placeholder="Search"
                                    cssClass={classes.textField}
                                />
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody>
                        <DataTable
                            size="medium"
                            fields={fields}
                            data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            order={order}
                            onOrder={setOrder}
                            sort={sort}
                            onSort={setSort}
                            stickyHeader={true}
                            showPagination={true}
                            footerPagination={() => (
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                    colSpan={fields.length + 2}
                                    count={filteredData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            )}
                            onAdd={Boolean(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN) ? handleAddFacility : undefined}
                            name="Facility"
                            handleRefresh={handleRefresh}

                            hasActions={Boolean(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN)}
                            renderActionColumn={facilityActions}
                        />
                    </PortletBody>
                </Portlet>
            </Loadable>
            <FacilityDialog title={dialogTitle} facility={facility} open={openDialog} handleClose={handleClose} />
            <DependencyDialog handleRefresh={handleRefresh} />
        </>
    );
}