import React, { useState } from 'react';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../../partials/content/Portlet';
import { Helmet } from 'react-helmet';
import { Button, Grid } from '@material-ui/core';
import { PagedTable } from '../../../../common';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AnalysisResultsDialog } from './AnalysisResultsDialog';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { BackButton } from '../../../../common/BackButton';
import { ModelResultsStatistics } from './ModelResultsStatistics';

const DEFAULT_SORT = [{ id: 'Claimnumber', direction: 'asc' }];

function ModelResultsPage(props) {
    const { history, fields, modelsData, modelName,
        actions: { modelExecutionsResultLanding, setList, setAnalysisDialog } } = props;

    const params = useParams();
    const modelId = params.modelId;

    const [refresh, setRefresh] = useState(Promise.resolve());

    useEffect(() => {

        return function cleanup() {
            setList([]);
        }
    }, [setList]);

    function handleLoad(sort, filter, pagination) {
        modelExecutionsResultLanding(pagination, sort, filter, modelId);
        // HACK - stats will be called inside landing API to avoid render.
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    const handleClickColumn = (fieldName, rowData, index) => {
        // setAnalysisDialog({
        //     open: true,
        //     analysisIds: rowData.AnalysisIds
        // });
        props.history.push(`/cms/modeling/results/view/${rowData.AnalysisIds}`);
    }


    return (
        <>
            <Helmet title="Model Results" />
            <Portlet>
                <PortletHeader
                    title={`Model Results (${modelName === '' ? modelId : modelName})`}
                    name="model-executions-result-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={false}
                    shouldNotSetDefault={modelId ? true : false}
                    toolbar={
                        <PortletHeaderToolbar>
                            <Grid className="d-flex align-items-center justify-content-end">
                                <BackButton defaultRoute='/modeling' />
                            </Grid>
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <ModelResultsStatistics />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <PagedTable
                                name="model-executions-result-landing"
                                title="Model"
                                fields={fields}
                                data={modelsData || []}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                criteriasSaveEnabled={true}
                                showSearchFilters={false}
                                handleClickColumn={handleClickColumn}
                            />
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet >
        </>
    )
}

export default ModelResultsPage

