import { createReducer } from '../../util';
import * as actions from '../actions/users';
import { StatusState } from './common';

const addObjectIfNotExist = (state, user) => {
    const data = state.data;
    const { id } = user;

    const userExist = data.find(x => x.id === id);
    if (!userExist) {
        return {
            data: [...state.data, user]
        }
    }
};

const makeTinyObject = (users) => {
    return users.reduce((a, user) => ({ ...a, [user.userId]: user.username }), {})
}

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    tinyList: [], // a object <ID: <data>>
    userRoles: [],
    tinyObjectAll: {},
    usersByRole: [],
    list: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    tiny: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    tinyWithoutClient: {
        ...initialStatusState
    },
    deactivate: {
        ...initialStatusState,
    },
    activate: {
        ...initialStatusState,
    },
    getuserRoles: {
        ...initialStatusState
    },
    getUsersByRole: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {
    [actions.USERS_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.USERS_LIST_FULFILLED]: (state, users) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: users,
    }),
    [actions.USERS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.USERS_LIST_TINY_PENDING]: (state) => ({
        ...state,
        tinyList: [],
        tiny: {
            pending: true,
        },
    }),
    [actions.USERS_LIST_TINY_FULFILLED]: (state, users) => ({
        ...state,
        tiny: {
            ...initialStatusState,
        },
        tinyList: users,
    }),
    [actions.USERS_LIST_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        tiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.USERS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.USERS_GET_FULFILLED]: (state, user) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, user),
    }),
    [actions.USERS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.USERS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.USERS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.USERS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.USERS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.USERS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.USERS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.USERS_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.USERS_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.USERS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.USERS_DEACTIVATE_PENDING]: (state) => ({
        ...state,
        deactivate: {
            pending: true,
        },
    }),
    [actions.USERS_DEACTIVATE_FULFILLED]: (state) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
        },
    }),
    [actions.USERS_DEACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.USERS_ACTIVATE_PENDING]: (state) => ({
        ...state,
        activate: {
            pending: true,
        },
    }),
    [actions.USERS_ACTIVATE_FULFILLED]: (state) => ({
        ...state,
        activate: {
            ...initialStatusState,
        },
    }),
    [actions.USERS_ACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        activate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.USERS_LIST_TINY_WITHOUT_CLIENT_PENDING]: (state) => ({
        ...state,
        tinyWithoutClient: {
            pending: true,
        },
    }),
    [actions.USERS_LIST_TINY_WITHOUT_CLIENT_FULFILLED]: (state, users) => ({
        ...state,
        tinyWithoutClient: {
            ...initialStatusState,
        },
        tinyObjectAll: makeTinyObject(users),
    }),
    [actions.USERS_LIST_TINY_WITHOUT_CLIENT_REJECTED]: (state, errorMessage) => ({
        ...state,
        tinyWithoutClient: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.USERS_ROLES_LIST_PENDING]: (state) => ({
        ...state,
        getuserRoles: {
            pending: true,
        },
    }),
    [actions.USERS_ROLES_LIST_FULFILLED]: (state, userRoles) => ({
        ...state,
        getuserRoles: {
            ...initialStatusState,
        },
        userRoles
    }),
    [actions.USERS_ROLES_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        getuserRoles: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.USERS_TINY_BY_ROLE_PENDING]: (state) => ({
        ...state,
        getUsersByRole: {
            pending: true,
        },
    }),
    [actions.USERS_TINY_BY_ROLE_FULFILLED]: (state, usersByRole) => ({
        ...state,
        getUsersByRole: {
            ...initialStatusState,
        },
        usersByRole
    }),
    [actions.USERS_TINY_BY_ROLE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getUsersByRole: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.USERS_ERROR_RESET]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        },
        delete: {
            ...initialStatusState,
        },
    }),

    [actions.USERS_RESET]: () => ({ ...initialState }),
});
