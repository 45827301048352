import { createSelector } from "reselect";
import { PRIMARY, SECONDARY } from "../constants/brand";

export const selectBrand = (state) => state.brand.data;
export const selectBrandId = (state) => state.brand.data?.brandId;

export const selectBrandColors = createSelector(
    selectBrand,
    (brand) => {
        if (brand) {
            const { themeMetadata } = brand;

            if (themeMetadata) {
                return {
                    [PRIMARY]: themeMetadata[PRIMARY],
                    [SECONDARY]: themeMetadata[SECONDARY],
                }
            }
        }
        return {
            [PRIMARY]: '#94c83d',
            [SECONDARY]: '#0bcc47',
        }
    }
)