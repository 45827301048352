import React, { useState, useEffect } from 'react';
import { getStartDate, getEndDate } from '../../util/date';
import moment from 'moment';

import {
    MenuItem, Grid, ClickAwayListener, Box,
    makeStyles, TextField, colors, IconButton, Tooltip, Switch, Typography,
} from "@material-ui/core";
import './DateRangeControl.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { searchPeriods } from '../../store/constants/date';

const useStyles = makeStyles(theme => ({
    textField: {
        // maxWidth: 245,
        width: (props) => props?.width ?? '100%',
        marginTop: (props) => props.marginTop ?? theme.spacing(1),
        marginLeft: (props) => props.marginLeft ?? 4,
        marginRight: (props) => props.marginRight ?? 4,
    },
    dateField: {
        width: '80%',
        marginTop: theme.spacing(1),
    },
    container: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    dateSection: {
        backgroundColor: colors.grey[50],
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        paddingBottom: 0
    },
    formControl: {
        width: 220,
    },
    actionSection: {
        justifyContent: 'flex-end',
        marginTop: -14,
    },
}));

export default function DateRangeControl(props) {

    const { fiscalYear, refresh, filterName, setDates, fields, customFilter, hasCustomField, relativeFilter,
        relativeFilterValue, setRelativeFilter, disabled, style, defaultControlEnabled } = props;
    const classes = useStyles(style);

    const [open, setOpen] = useState(false);
    const [dateError, setDateError] = useState(null);
    const [shouldCallAPI, setShouldCallApi] = useState(false);
    const [values, setValues] = useState({
        relativeFilter: 'Last12Months',
        startdate: getStartDate('Last12Months'),
        enddate: getEndDate('Last12Months'),
        enabled: defaultControlEnabled ?? true,
        customField: 'remitdate'
    });

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (relativeFilterValue) {
            setValues(prevState => {
                return {
                    ...prevState,
                    relativeFilter: relativeFilterValue
                }
            });
        } else if (relativeFilter && !relativeFilterValue) {
            setValues(prevState => {
                return {
                    ...prevState,
                    relativeFilter
                }
            });
        }
    }, [relativeFilter, relativeFilterValue])

    useEffect(() => {
        if (fields && fields.length > 0) {
            if (customFilter && customFilter.length > 0) {
                const ef = customFilter.filter((cf) => {
                    return fields.find((f) => {
                        return cf.field === f.value;
                    });
                });


                const startEF = ef.find(item => item.equator === 'greater_than_or_equals_to');
                const endEF = ef.find(item => item.equator === 'less_than_or_equals_to');

                if (ef.length > 0) {
                    setValues(prevState => {
                        return {
                            ...prevState,
                            enabled: true,
                            customField: ef[0].field,
                            startdate: startEF ? startEF?.value : getStartDate('Last12Months'),
                            enddate: endEF ? endEF?.value : getEndDate('Last12Months'),
                            relativeFilter: ef[0].relativeFilter ?? 'Last12Months'
                        }
                    });
                } else {
                    setValues(prevState => {
                        return {
                            ...prevState,
                            customField: fields[0].value,
                            enabled: false,
                        }
                    });
                }
            } else {
                setValues(prevState => {
                    return {
                        ...prevState,
                        enabled: false,
                        customField: fields[0].value,
                    }
                });
            }
        }
    }, [fields, customFilter]);

    const handleRelativeFilterChange = (event) => {
        if (setRelativeFilter) {
            setRelativeFilter(event.target.value);// set RelativeFilter As Custom
        }
        setShouldCallApi(true);
        setValues(prevState => {
            return {
                ...prevState,
                relativeFilter: event.target.value,
                startdate: getStartDate(event.target.value, fiscalYear),
                enddate: getEndDate(event.target.value, fiscalYear),
            }
        });
        if (getStartDate(event.target.value, fiscalYear) < getEndDate(event.target.value, fiscalYear)) {
            setDateError(null);
        }
    };

    const handleCustomFieldChange = (event) => {
        event.persist();
        setShouldCallApi(true);
        setValues(prevState => {
            return {
                ...prevState,
                customField: event.target.value
            }
        });
    }

    const startDateHandler = (event) => {
        if (setRelativeFilter) {
            setRelativeFilter('custom');// set RelativeFilter As Custom
        }

        event.persist();

        setValues(prevState => ({
            ...prevState,
            startdate: event.target.value,
        }));
        setShouldCallApi(true);

        if (new Date(event.target.value) < new Date(values.enddate)) {
            setDateError(null);
        } else {
            setDateError("Start date can not be after End date");
        }
    };

    const endDateHandler = (event) => {
        if (setRelativeFilter) {
            setRelativeFilter('custom');// set RelativeFilter As Custom
        }

        event.persist();

        setValues(prevState => ({
            ...prevState,
            enddate: event.target.value,
        }));
        setShouldCallApi(true);

        if (new Date(event.target.value) > new Date(values.startdate)) {
            setDateError(null);
        } else {
            setDateError("End date can not be before Start date");
        }

    };

    const handleSwitchChange = (event) => {
        event.persist();
        if (!event.target.checked && setRelativeFilter) {
            setRelativeFilter('custom');
        }
        setValues(prevState => {
            return {
                ...prevState,
                enabled: event.target.checked,
            }
        });
        setShouldCallApi(true);
    }

    const handleFilterApply = () => {
        if (new Date(values.startdate) > new Date(values.enddate) && values.enabled) {
            return
        }
        handleClickAway();
        setDates(values.startdate, values.enddate, values.enabled, values.customField, values.relativeFilter);

        if (refresh) {
            refresh();
        }
        setShouldCallApi(false);
    }

    function getFormattedDisplayDate() {
        if (!values.enabled) {
            return '[DISABLED]';
        }
        const startDate = moment(values.startdate, "YYYY/MM/DD");
        const endDate = moment(values.enddate, "YYYY/MM/DD");

        return `${startDate.format("MM/DD/YYYY")}  -  ${endDate.format("MM/DD/YYYY")}`;
    }

    function getFormattedLabel() {
        if (!values.enabled) {
            return filterName ?? 'Date Filter';
        }
        const label = hasCustomField ? getCustomLabel() : 'Date Range';
        return label;
    }

    const handleLostFocus = () => {
        if (new Date(values.startdate) > new Date(values.enddate) && values.enabled) {
            return
        }
        if (shouldCallAPI) {
            handleFilterApply();
            setShouldCallApi(false);
        } else {
            handleClickAway();
        }
    }

    const getCustomLabel = () => {
        const field = fields.find(item => item.value === values.customField)
        return field && field?.label
    }

    return (
        <ClickAwayListener onClickAway={handleLostFocus}>
            <div className='MyDatePicker'>
                <TextField
                    disabled={disabled}
                    name="Date"
                    label={getFormattedLabel()}
                    value={getFormattedDisplayDate()}
                    className={classes.textField}
                    onClick={handleClick}
                />
                {open ? (
                    <Box className='mdp-container'>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container className={classes.container}>
                                    <Grid item xs={9}>
                                        {hasCustomField &&
                                            <div className='mb-4'>
                                                <TextField
                                                    disabled={!values.enabled}
                                                    select
                                                    label="Field"
                                                    className={classes.formControl}
                                                    value={values.customField}
                                                    onChange={handleCustomFieldChange}
                                                    SelectProps={{
                                                        MenuProps: { disablePortal: true }
                                                    }}
                                                >
                                                    {fields.map(({ value, label }) => (
                                                        <MenuItem key={value} value={value}>{label}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>
                                        }
                                        <TextField
                                            select
                                            disabled={!values.enabled}
                                            label="Relative Filter"
                                            className={classes.formControl}
                                            value={values.relativeFilter}
                                            onChange={handleRelativeFilterChange}
                                            SelectProps={{
                                                MenuProps: { disablePortal: true }
                                            }}
                                        >
                                            {searchPeriods.map(item => <MenuItem key={item.id} value={item.id}>{item.value} </MenuItem>)}
                                            <MenuItem disabled value="custom">Custom</MenuItem>
                                            {/* <MenuItem value="ThisFiscalYear">Current Fiscal Year</MenuItem>
                                            <MenuItem value="LastFiscalYear">Previous Fiscal Year</MenuItem> */}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container className={classes.actionSection}>
                                            <Grid item xs={4}>
                                                <Tooltip title="Apply">
                                                    <IconButton size='small' onClick={handleFilterApply}>
                                                        <CheckCircleOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Tooltip title="Cancel">
                                                    <IconButton size='small' edge='end' onClick={handleClickAway}>
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className={classes.dateSection}>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="date"
                                            disabled={!values.enabled}
                                            label="Start Date"
                                            name="startdate"
                                            className={classes.dateField}
                                            margin="normal"
                                            onChange={startDateHandler}
                                            value={values.startdate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="date"
                                            disabled={!values.enabled}
                                            label="End Date"
                                            name="enddate"
                                            className={classes.dateField}
                                            margin="normal"
                                            onChange={endDateHandler}
                                            value={values.enddate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {dateError &&
                                    <Typography variant="caption" display="block" gutterBottom className='text-danger px-3'>{dateError}</Typography>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className={classes.disableSection}>
                                    <Switch
                                        checked={values.enabled}
                                        onChange={handleSwitchChange}
                                        name="enabled"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}