import { FormLabel, IconButton, TextareaAutosize, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { CodesEditDialog } from '../../../../common';

const useStyles = makeStyles(theme => ({

    codesLabel: {
        marginLeft: theme.spacing(1),
        fontSize: '0.8rem',
        marginBottom: 6
    },
    textareaAutosize: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        padding: 0,
        width: '95%',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        outline: 'none',
        '&:focus': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
        },
        '&:focus-visible': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
        }
    },
    errorColor: {
        color: '#fd397a',
    },
    helperText: {
        marginLeft: '8px'
    },
    textAreaError: {
        borderColor: '#fd397a',
        borderWidth: '2px'
    },
    helpClass: {
        position: 'relative',
        top: 10,
        marginLeft: -12,
    },
}));

function SomeTimesPayCodes({ touched, errors, values, setFieldValue }) {

    const classes = useStyles();

    const [isCodesOpen, setCodeOpen] = useState(false);

    const handleCodesOpen = () => {
        setCodeOpen(true);
    }

    const handleCodesClose = () => {
        setCodeOpen(false);
    }

    const handleCodesChange = (setFieldValue) => (codes) => {
        const withoutLineBreaks = codes.replace(/[\r\n]/gm, '');
        setFieldValue('sometimesPayCodes', withoutLineBreaks);
    }

    return (
        <>
            <FormLabel className={`ml-0 ${classes.codesLabel} ${(Boolean(errors?.sometimesPayCodes)) ? classes.errorColor : ''}`} id="sometimes-codes">Codes *
                <IconButton onClick={handleCodesOpen} className="p-1">
                    <ZoomInIcon />
                </IconButton>
            </FormLabel>
            <TextareaAutosize
                aria-labelledby="sometimesPayCodes"
                multiline
                minRows='1'
                maxRows='8'
                label="Codes"
                // placeholder="Codes"
                name="sometimesPayCodes"
                className={`ml-0 ${classes.textareaAutosize} ${(Boolean(errors?.sometimesPayCodes)) ? classes.textAreaError : ''}`}
                value={values?.sometimesPayCodes}
                helperText="e.g. 80883, 80443, 99284"
                error={Boolean(errors?.sometimesPayCodes)}
                onChange={e => {
                    const withoutLineBreaks = e.target.value.replace(/[\r\n]/gm, '').replace(/\s/g, '');
                    setFieldValue('sometimesPayCodes', withoutLineBreaks);
                }}
            />
            <p className={`ml-0 ${(Boolean(errors?.sometimesPayCodes)) && classes.errorColor} ${classes.helperText} MuiFormHelperText-root`}>
                {Boolean(values.sometimesPayCodes.length > 0 && errors?.sometimesPayCodes)
                    ? `${errors?.sometimesPayCodes} e.g. 80883, 80443, 99284` :
                    'e.g. 80883, 80443, 99284'}
            </p>

            <CodesEditDialog
                open={isCodesOpen}
                codes={values.sometimesPayCodes}
                handleClose={handleCodesClose}
                handleCodesChange={handleCodesChange(setFieldValue)}
            />
        </>
    )
}

export default SomeTimesPayCodes
