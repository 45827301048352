import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import ClaimLineItemSectionComponent from './ClaimLineItemSection';
import { bindActionCreators } from 'redux';
import { createCurrencyDataField, createNumberDataField, createSecondLevelField, createStringDataField } from '../../../../util/format';
import { createSelector } from 'reselect';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import { selectClaimAllDetails, selectClaimAnalysis } from '../../../../store/selectors/claimAnalysis';
import { set } from '../../../../store/actions/pages';
import { resetClaimLineItem, setAdjustment } from '../../../../store/actions/claimAnalysis';

const DEFAULT_SORT = [{ id: 'revcode', orderBy: 'asc' }];

const selectFields = createSelector(
    (state) => [
        createSecondLevelField('rule', 'Rule Name', 'name', { onHover: true }),
        createStringDataField('revcode', 'RevCode', { sortable: true, onHover: true }),
        createStringDataField('pxcode', 'PxCode', { sortable: true, onHover: true }),
        createNumberDataField('units', 'Qty/Units', { sortable: true }),
        createCurrencyDataField('chargedAmount', 'Charged Amount', { sortable: true, calculateTotal: true }),
        createCurrencyDataField('expectedPay', 'Expected Pay', { sortable: true, calculateTotal: true }),
        createCurrencyDataField('actualPay', 'Allowable', { sortable: true, calculateTotal: true }),
        createCurrencyDataField('variance', 'Variance', { sortable: true, calculateTotal: true }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`claim-drill-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`claim-drill-landing`].filter,
    selectSort,
    selectClaimAnalysis,
);

export const selectClaimNumber = createSelector(
    selectClaimAllDetails,
    (claimDetails) => {
        if (claimDetails && claimDetails.claimnumber)
            return claimDetails.claimnumber;
        else
            return null;
    }
);

export const ClaimLineItemSection = connect(
    () => {

        return (state, props) => ({
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            order: state.pages[`claim-drill-landing`].order,
            filter: state.pages[`claim-drill-landing`].filter,
            sort: selectSort(state, props),
            claimnumber: selectClaimNumber(state, props),
            claimLineItemPending: state.claimAnalysis.get.pending,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setFilter: (value) => set(`claim-drill-landing`, 'filter', value),
            setOrder: (value) => set(`claim-drill-landing`, 'order', value),
            setSort: (value) => set(`claim-drill-landing`, 'sort', value),
            resetClaimLineItem: resetClaimLineItem,
            setAdjustment: setAdjustment,
        }, dispatch)
    })
)(injectIntl(ClaimLineItemSectionComponent));