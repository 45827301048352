import { createSelector } from 'reselect';
import { ROLE_SUPERADMIN, ROLE_PARTNER_ADMIN, ROLE_ADMIN, ROLE_API, ROLE_PARTNER_USER } from '../constants/roles';
import { selectSessionRole } from './session';
import { selectUserRoles } from './users';

export const selectRoleList = (state) => state.roles.data;
export const selectRolePermissionList = (state) => state.roles.permissions;
export const selectAllocatedPermissionList = (state) => state.roles.allocatedPermissions;

export const selectRoles = createSelector(
    selectRoleList,
    (roles) => roles,
);

export const selectRolePermissions = createSelector(
    selectRolePermissionList,
    (permissions) => permissions,
);

export const selectAllocatedPermission = createSelector(
    selectAllocatedPermissionList,
    (allocatedPermissions) => allocatedPermissions
);

//while creating the users, superadmin can only create another super admin
export const selectRolesBasedOnRole = createSelector(
    selectRoleList,
    selectSessionRole,
    (roles, sessionRole) => {
        if (sessionRole !== ROLE_SUPERADMIN) {
            if (sessionRole === ROLE_PARTNER_ADMIN) {
                return roles.filter(role => role.name !== ROLE_SUPERADMIN && role.name !== ROLE_PARTNER_ADMIN);
            }
            return roles.filter(role => role.name !== ROLE_SUPERADMIN);
        }
        return roles;
    }
);

export const selectUserRolesBasedOnRole = createSelector(
    selectUserRoles,
    selectSessionRole,
    (roles, sessionRole) => {
        if (sessionRole !== ROLE_SUPERADMIN) {
            if (sessionRole === ROLE_PARTNER_ADMIN) {
                return roles.filter(role => role.name !== ROLE_SUPERADMIN && role.name !== ROLE_API);
            }
            if (sessionRole === ROLE_ADMIN) {
                return roles.filter(role => role.name !== ROLE_SUPERADMIN && role.name !== ROLE_PARTNER_ADMIN && role.name !== ROLE_PARTNER_USER);
            }
            return roles.filter(role => role.name !== ROLE_SUPERADMIN);
        }
        return roles.sort((a, b) => a.name.localeCompare(b.name))
    }
);

export const createRoleSelector = (selectRoleId) => createSelector(
    selectRoleId,
    selectRoles,
    (roleId, roles) => {
        return roles.find((role) => role.id === parseInt(roleId));
    }
);