import { Formik } from "formik";
import React, { useEffect } from "react";
import { Loadable } from '../../../../common';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import {
  Button,
  Checkbox, FormControlLabel,
  List, ListItem, ListItemIcon, ListItemText,
  makeStyles,
  Switch,
  TextField
} from "@material-ui/core";
import { BackButton } from "../../../../common/BackButton";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 400,
  },
  switch: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  clientIdError: {
    color: '#fd397a',
    fontSize: '12px',
    marginBottom: 0
  },
  redColor: {
    color: '#fd397a',
  },
  listItemSpan: {
    "& span": {
      color: 'rgba(0, 0, 0, 0.54) !important',
      opacity: 0.5
    }
  }
}));

export default function UsersEditPage(props) {
  const {
    intl, mode, partnersCreatePending, partnersUpdatePending, partnersGetPending,
    isSuperAdmin, initialValues, partner,
    partnerId, partners,
    actions: {
      partnerCreate, partnerUpdate, partnerGet,
    }
  } = props;
  const isEdit = mode === 'edit';
  const classes = useStyles();

  const handleCallback = () => {
    props.history.push('/admin/partners');
  }

  useEffect(() => {
    if (mode === 'edit') {
      partnerGet(partnerId);
    }
  }, [partnerId, mode, partnerGet]);


  const handleClientToggle = (clientId, setFieldValue, values) => () => {
    const currentIndex = values.clientIds.indexOf(clientId);
    const tempClients = [...values.clientIds];

    if (currentIndex === -1) {
      tempClients.push(clientId);
    } else {
      tempClients.splice(currentIndex, 1);
    }
    setFieldValue('clientIds', tempClients);
  };

  const handleSaveBegin = (values) => {
    handleSaveChanges(values);
  }

  const handleSaveChanges = (values) => {

    let payload = {
      name: values.name,
      description: values.description,
      active: values.active,
      clientIds: values.clientIds,
    }

    if (isSuperAdmin) {
      payload = {
        ...payload,
        clientIds: values.clientIds,
      }
    }

    // create
    if (mode === 'create') {
      payload = {
        ...payload,

      }
      partnerCreate(payload, handleCallback);
    }
    // update
    else {
      payload = {
        ...payload,
        id: values.id,
      }
      partnerUpdate(values.id, payload, handleCallback);
    }
  }

  return (
    <Loadable loading={partnersCreatePending || partnersUpdatePending || partnersGetPending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.description) {
            errors.description = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (values.description.length > 500) {
            errors.description = 'Maximum length for this field is 500 characters';
          }

          if (values.clientIds.length === 0) {
            errors.clientIds = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          return errors;
        }}
        onSubmit={(values) => {
          handleSaveBegin(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={!isEdit ? "Add new Partner" : "Edit partner"}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/admin/partners" />
                    <Button
                      variant="contained"
                      color="primary"
                      className='ml-3'
                      type="submit"
                      disabled={partnersCreatePending || partnersUpdatePending}
                    >
                      Save
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className="main-content">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-8">
                          <TextField
                            required
                            label="Name"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <TextField
                            required
                            multiline
                            rows={3}
                            label="Description"
                            name="description"
                            className={classes.textArea}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            helperText={touched.description && errors.description}
                            error={Boolean(touched.description && errors.description)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={values.active}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.active}
                              />
                            }
                            label="Active"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="row">
                        <div className="col-md-10 offset-md-1">
                          <h4 className={`${Boolean(touched.clientIds && errors.clientIds) && classes.redColor} text-left`}>Select Client</h4>
                          {Boolean(touched.clientIds && errors.clientIds) && <p className={classes.clientIdError}>Please select at least one client</p>}
                          <List className={classes.root}>
                            {partners.map(({ clientId, name, active }) => {
                              return (
                                <ListItem
                                  disabled={values.clientIds.indexOf(clientId) === -1 && !active}
                                  key={clientId}
                                  role={undefined}
                                  dense
                                  button
                                  divider
                                  onClick={handleClientToggle(clientId, setFieldValue, values)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      checked={values.clientIds.indexOf(clientId) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': clientId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText className={!active && classes.listItemSpan} id={clientId} primary={name} />
                                </ListItem>
                              );
                            })}
                          </List>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </form>
        )}
      </Formik>
    </Loadable>
  );
}