import { TableRow } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'

function FeeScheduleDraggable({ children, item, classes, selectedFeeScheduleIds }) {

    const rowRef = useRef();

    useEffect(() => {

        const handleAppearDraggingItem = (e) => {
            var elem = document.createElement("div");
            elem.id = "drag-ghost";
            elem.textNode = "Dragging";
            elem.style.position = "absolute";
            elem.style.top = "-1000px";
            elem.textContent = `Move ${selectedFeeScheduleIds.length > 0 ? selectedFeeScheduleIds.length : 1} item`
            document.body.appendChild(elem);
            e.dataTransfer.setDragImage(elem, 0, 0);
        }

        if (rowRef && rowRef.current) {
            rowRef.current.addEventListener(
                "dragstart",
                handleAppearDraggingItem,
                false
            );
            // Specify how to clean up after this effect:
            return function cleanup() {
                rowRef.current.removeEventListener("dragstart", handleAppearDraggingItem, false)
            };
        }
    });

    useEffect(() => {

        const handleRemoveDraggingItem = (e) => {
            var ghost = document.getElementById("drag-ghost");
            if (ghost.parentNode) {
                ghost.parentNode.removeChild(ghost);
            }
        }

        if (rowRef && rowRef.current) {
            rowRef.current.addEventListener(
                "dragend",
                handleRemoveDraggingItem,
                false
            );
            // Specify how to clean up after this effect:
            return function cleanup() {
                rowRef.current.removeEventListener("dragend", handleRemoveDraggingItem, false)
            };
        }

    });

    const handleDragStart = (e) => {
        e.dataTransfer.setData('items', JSON.stringify([e.target.id]));
    };

    return (
        <>
            <TableRow ref={rowRef} id={`feeschedule-${item.id}`} component={'div'} draggable onDragStart={handleDragStart} className={`${classes.trBody} fee-schedule-row`} key={item.id}>
                {children}
            </TableRow>
        </>
    )
}

export default FeeScheduleDraggable
