export const ALLOWABLES_LIST = 'ALLOWABLES_LIST';
export const ALLOWABLES_PENDING = 'ALLOWABLES_PENDING';
export const ALLOWABLES_FULFILLED = 'ALLOWABLES_FULFILLED';
export const ALLOWABLES_REJECTED = 'ALLOWABLES_REJECTED';

export const ALLOWABLES_ERROR_RESET = 'ALLOWABLES_ERROR_RESET';
export const ALLOWABLES_RESET = 'ALLOWABLES_RESET';

export const ALLOWABLES_UPDATE_STATUS = 'ALLOWABLES_UPDATE_STATUS';
export const ALLOWABLES_UPDATE_STATUS_PENDING = 'ALLOWABLES_UPDATE_STATUS_PENDING';
export const ALLOWABLES_UPDATE_STATUS_FULFILLED = 'ALLOWABLES_UPDATE_STATUS_FULFILLED';
export const ALLOWABLES_UPDATE_STATUS_REJECTED = 'ALLOWABLES_UPDATE_STATUS_REJECTED';

export const ALLOWABLES_SELECT_ALL = 'ALLOWABLES_SELECT_ALL';
export const ALLOWABLES_SELECT_ALL_PENDING = 'ALLOWABLES_SELECT_ALL_PENDING';
export const ALLOWABLES_SELECT_ALL_FULFILLED = 'ALLOWABLES_SELECT_ALL_FULFILLED';
export const ALLOWABLES_SELECT_ALL_REJECTED = 'ALLOWABLES_SELECT_ALL_REJECTED';

export const getList = (from, to) => {
    return {
        type: ALLOWABLES_LIST
    }
};

export const updateStatus = (id, enabled) => {
    return {
        type: ALLOWABLES_UPDATE_STATUS,
        payload: { id, enabled },
    }
};

export const errorReset = (...errorKeys) => ({
    type: ALLOWABLES_ERROR_RESET,
    payload: { errorKeys },
});

export const selectDeselectAll = (payload, callback) => ({
    type: ALLOWABLES_SELECT_ALL,
    payload: { payload, callback },
});