import { Button, Grid, Link, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loadable } from '../../../../common';
import { asDate } from '../../../../util/date';

const useStyles = makeStyles(theme => ({
    labelDiv: {
        width: 150
    },
    viewLink: {
        fontWeight: 'bold'
    },
    tableHead: {
        backgroundColor: '#fafafa'
    },
}));

interface ChargeMasterStepProps {
    intl : any;
    history: any;
    getStatsPending: boolean;
    stats: Array<{
        facilityName: string;
        totalDepartments: number;
        totalCDM: number;
        lastUpdatedAt: string | null;
    }>;
    activeStep: number;
    handleNext: () => void;
    actions: {
        getChargeMasterStats: () => void;
    };
}

function ChargeMasterStep({ history, getStatsPending, stats, activeStep, handleNext, actions: { getChargeMasterStats } }: ChargeMasterStepProps & RouteComponentProps) {
    const classes = useStyles();

    useEffect(() => {
        if (activeStep === 0) {
            getChargeMasterStats();
        }
    }, [activeStep, getChargeMasterStats]);

    const handleRedirectToChargeMaster = () => {
        history.push('/pricer/chargeMaster?fromPricerSetup=true')
    }

    return (
        <Loadable loading={getStatsPending}>
            <Grid item xs={12} className="mt-3">
                <Card>
                    <Card.Header as="h5" className='text-center'>Charge Master</Card.Header>
                    <Card.Body className='text-dark px-5 py-4'>
                        <Grid container>
                            <Grid item md={12} container>
                                <Table aria-label="Charge Master Table">
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell align='left'>Facility </TableCell>
                                            <TableCell align='left'>Total Departments </TableCell>
                                            <TableCell align='left'>Total Charges ( 
                                                <Link
                                                    className={classes.viewLink}
                                                    component="button"
                                                    variant="body2"
                                                    onClick={handleRedirectToChargeMaster}
                                                >
                                                    View
                                                </Link>
                                            )</TableCell>
                                            <TableCell align='left'>Last Updated </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stats.map((stat, index) => (
                                            <TableRow key={index}>
                                                <TableCell align='left'>{stat.facilityName}</TableCell>
                                                <TableCell align='left'>{stat.totalDepartments}</TableCell>
                                                <TableCell align='left'>{stat.totalCDM}</TableCell>
                                                <TableCell align='left'>{asDate(stat.lastUpdatedAt) || 'N/A'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                        <Grid className='pt-3 justify-content-end' container>
                            <Button
                                disabled={stats.length > 0 ? false : true}
                                size='small'
                                onClick={handleNext}
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        </Grid>
                    </Card.Body>
                </Card>
            </Grid>
        </Loadable>
    )
}
export default withRouter(ChargeMasterStep)
