import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import {
    createStringDataField, createTimestampField, createExceptionHoverField, createTimeDifferenceField
} from '../../../util/format';
import { dateEquators, StringEquators } from '../../../store/constants/pages';
import JobStatisticsPageComponent from './JobStatisticsPage';
import { getJobStatistics } from '../../../store/actions/controlRoom';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { set } from '../../../store/actions/pages';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'desc' }];

const selectFields = createSelector(
    () => {
        return [
            createStringDataField('name', 'Name', { sortable: true, equators: StringEquators }),
            createStringDataField('type', 'Type', { sortable: true, equators: StringEquators }),
            createTimestampField('triggerTime', 'Triggered At', { sortable: true, equators: dateEquators }),
            createTimestampField('completionTime', 'Completed At', { sortable: true, equators: dateEquators }),
            createTimeDifferenceField('triggerTime', 'completionTime', 'Execution Time', { returnWholeRow: true, hideFromFilter: true }),
            createExceptionHoverField('status', 'Status', 'COMPLETED', { returnWholeRow: true })
        ]
    }
);

export const selectJobStatisticsList = (state) => state.controlRoom.jobStatistics;

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`job-statistics-landing`] ? state.pages[`job-statistics-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`job-statistics-landing`] ? state.pages[`job-statistics-landing`].filter : null,
    selectSort,
    selectJobStatisticsList,
);
export const JobStatisticsPage = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            jobStatisticsData: selectFilteredData(state),
            order: state.pages['job-statistics-landing'] ? state.pages['job-statistics-landing'].order : null,
            filter: state.pages['job-statistics-landing'] ? state.pages['job-statistics-landing'].filter : null,
            sort: selectSort(state, props),

            getJobStatisticsPending: state.controlRoom.getJobStatistics.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getJobStatistics: getJobStatistics,
            setFilter: (value) => set(`job-statistics-landing`, 'filter', value),
            setOrder: (value) => set(`job-statistics-landing`, 'order', value),
            setSort: (value) => set(`job-statistics-landing`, 'sort', value),
        }, dispatch)
    })
)(injectIntl(JobStatisticsPageComponent));