import React, { useEffect } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import {
  MenuItem,
  FormControl,
  Select,
  makeStyles, Grid,
} from '@material-ui/core';
import { Loadable } from '../../../common';
import { QuickStats } from "../../../common/widgets/QuickStats";
import { BarChart } from '../../../common/widgets/BarChart';
import Helmet from 'react-helmet';
import { FilesGrid } from './FilesGrid';

const useStyles = makeStyles(theme => ({
  filterFormControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 150,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 300,
  },
}));

export default function StatisticsPage(props) {
  const classes = useStyles();
  const {
    statisticsPending,
    stats,
    isSuperAdmin,
    isPartner,
    defaultClientFilter,
    statistics: {
      totalFilesUploaded, totalFilesFailed,
      totalFilesSucceeded, totalTransactionFilesProcessed
    },
    actions: {
      statisticsGet,
    }
  } = props;

  const [filters, setFilters] = React.useState({
    frequency: 'daily',
    clientId: defaultClientFilter,
  });

  function handleChange(event) {
    setFilters(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }

  const handleFilterChange = (handleChange, event) => {
    handleChange(event);
  }

  useEffect(() => {
    statisticsGet(filters);
  }, [filters, statisticsGet]);

  return (
    <>
      <Helmet title="Statistics" />
      <Portlet fit="true">
        <Loadable loading={statisticsPending}>
          <PortletHeader
            title="Client Statistics"
            toolbar={
              <PortletHeaderToolbar>
                <form>
                  <FormControl className={classes.filterFormControl}>
                    <Select
                      value={filters.frequency}
                      onChange={(event) => handleFilterChange(handleChange, event)}
                      name="frequency"
                    >
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                    </Select>
                  </FormControl>
                  {(isSuperAdmin || isPartner) && (
                    <FormControl className={classes.formControl}>
                      <Select
                        value={filters.clientId}
                        onChange={(event) => handleFilterChange(handleChange, event)}
                        name="clientId"
                      >
                        {isSuperAdmin && (
                          <MenuItem value="all">
                            <em>All Clients</em>
                          </MenuItem>
                        )}
                        {props.clients.map(client => (
                          <MenuItem
                            key={client.clientId}
                            value={client.clientId}
                          >
                            {client.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </form>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody fit="true">
            <div className="row" style={{ padding: '10px' }}>
              <div className="col-md-6">
                <div className="row row-full-height">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Portlet fluid="true" fit="true">
                      <PortletBody fluid="true" fit="true">
                        <QuickStats
                          loading={statisticsPending}
                          title="Uploaded"
                          value={totalFilesUploaded}
                          desc="Total Files"
                          color="warning"
                        />
                      </PortletBody>
                    </Portlet>

                    <div className="kt-space-20" />

                    <Portlet fluid="true" fit="true">
                      <PortletBody fluid="true" fit="true">
                        <QuickStats
                          loading={statisticsPending}
                          title="Failed"
                          value={totalFilesFailed}
                          desc="Total Files"
                          color="danger"
                        />
                      </PortletBody>
                    </Portlet>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Portlet fluid="true" fit="true">
                      <PortletBody fluid="true" fit="true">
                        <QuickStats
                          loading={statisticsPending}
                          title="Succeeded"
                          value={totalFilesSucceeded}
                          desc="Total Files"
                          color="success"
                        />
                      </PortletBody>
                    </Portlet>
                    <div className="kt-space-20" />
                    <Portlet fluid="true" fit="true">
                      <PortletBody fluid="true" fit="true">
                        <QuickStats
                          loading={statisticsPending}
                          title="Processed"
                          value={totalTransactionFilesProcessed}
                          desc="Transaction"
                        />
                      </PortletBody>
                    </Portlet>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <Portlet fluid="true" fit="true">
                  <BarChart
                    loading={statisticsPending}
                    data={stats}
                    title="Files Summary"
                    desc="Files Status breakdown by Clients"
                    options={
                      {
                        type: "bar",
                        options: {
                          title: { display: false },
                          tooltips: {
                            intersect: false,
                            mode: "x-axis",
                            xPadding: 10,
                            yPadding: 10,
                            caretPadding: 10
                          },
                          legend: { display: false },
                          responsive: true,
                          maintainAspectRatio: false,
                          barRadius: 4,
                          scales: {
                            xAxes: [{ display: false, gridLines: false, stacked: true }],
                            yAxes: [{ display: false, stacked: true, gridLines: false }]
                          },
                          layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } }
                        }
                      }
                    }
                  />
                </Portlet>
              </div>
            </div>
            <div className="row" style={{ padding: '10px' }}>
              <div className="col-md-12">
                <FilesGrid />
              </div>
            </div>
          </PortletBody>
        </Loadable>
      </Portlet>
    </>
  );
}