import {
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

const getRoles = () => {
    return fetch(`${SERVER_URL}/api/v1/authz/roles`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getRoleMetadata = () => {
    return fetch(`${SERVER_URL}/api/v1/authz/roles`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPermission = () => {
    return fetch(`${SERVER_URL}/api/v1/authz/permissions/default`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPermissionById = (roleId) => {
    return fetch(`${SERVER_URL}/api/v1/authz/permissions/${roleId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const updatePermission = (options) => {
    return fetch(`${SERVER_URL}/api/v1/authz/role`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleResponse);
};

const createPermission = (options) => {
    return fetch(`${SERVER_URL}/api/v1/authz/role`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleResponse);
};

export {
    getRoles,
    getRoleMetadata,
    getPermission,
    getPermissionById,
    updatePermission,
    createPermission
};