import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET, METHOD_PUT,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuth } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

const getBrand = () => {
    return fetch(`${SERVER_URL}/api/v1/pricer/brand`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};
const getDeploymentStatus = () => {
    return fetch(`${SERVER_URL}/api/v1/pricer/brand/status`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createBrand = (formData) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/brand`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: formData,
    }).then(apiHandleResponse);
};

const updateBrand = (formData) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/brand`, {
        method: METHOD_PUT,
        headers: headersAuth(),
        body: formData,
    }).then(apiHandleResponse);
};

export {
    getBrand,
    createBrand,
    updateBrand,
    getDeploymentStatus
};