import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import ClaimViewComponent from './ClaimViewPage';
import { get, getClaimAllDetails, getClaimComments, getHistory, resetClaimLineItem,setClaimCommentsConfirmationDialog,setClaimComments } from '../../../store/actions/claimAnalysis';
import { bindActionCreators } from 'redux';
import { tiny } from '../../../store/actions/contracts';


const selectClaimId = (state, props) => props.match.params.id;

export const ClaimViewPage = connect(
    () => {

        return (state, props) => ({
            claimId: selectClaimId(state, props),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getClaimAllDetails: getClaimAllDetails,
            getClaimLineItems: get,
            getHistory: getHistory,
            resetClaimLineItem: resetClaimLineItem,
            getClaimComments: getClaimComments,
            getTinyContracts: tiny,
            setClaimCommentsConfirmationDialog,
            setClaimComments
        }, dispatch)
    })
)(injectIntl(ClaimViewComponent));