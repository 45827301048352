import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles, Table, TableBody, TableCell, TableHead, TableRow,Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { CustomLabel, Loadable,EllipsisText } from '../../../common';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tableContainer: {
        height: 200,
        overflow: 'auto',
    },
    
}));



function DependencyDialog(props) {
    const { open, feeScheduleId, feeScheduleDependencies, feeScheduleDependencyPending, feeScheduleDeletePending, feeScheduleName,
        handleRefresh, actions: { setFeeScheduleDepdendencyDialog, getFeeScheduleDependencies, deleteFeeSchedule,resetFeeScheduleItems } } = props;

    const classes = useStyles();

    useEffect(() => {
        if (open && feeScheduleId) {
            getFeeScheduleDependencies(feeScheduleId)
        }
    }, [open, feeScheduleId, getFeeScheduleDependencies]);


    
    const handleDialogClose = () => {
        setFeeScheduleDepdendencyDialog(null);
        resetFeeScheduleItems()
    }

    const handleDeleteCallback = () => {
        setFeeScheduleDepdendencyDialog(null);
        handleRefresh(true);
    }

    const handleDelete = () => {
        if (feeScheduleDependencies.length > 0) return
        deleteFeeSchedule(feeScheduleId, handleDeleteCallback);
    }
    return (
        <Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose} >
            <Loadable loading={feeScheduleDependencyPending || feeScheduleDeletePending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Dependencies
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    <Grid>
                        {feeScheduleDependencies?.records && feeScheduleDependencies?.records?.length > 0 ?
                            <Typography>Can not delete this fee schedule. Please delete all the dependencies first</Typography> :
                            <Typography>No dependency found. Please click to delete</Typography>
                        }
                    </Grid>
             
                    {feeScheduleDependencies?.records?.length > 0 && <Grid className='pt-2'>
                        <ol className='lower-alpha'>
                            {feeScheduleDependencies?.records?.map((item, index) =>
                                <li key={`key-${index}`}>
                                    <b>{item.name} </b>
                                    <Grid>
                                        <ul>
                                            {item?.dependents?.map((dep, pcIndex) => <li key={`contract-key-${pcIndex}`}>
                                                <CustomLabel label={`${dep.dependentType}(${dep.count.toString()})`} data={
                                                dep?.isGridView ?
                                                <Box className={classes.tableContainer}>            
                                                <Table stickyHeader={true} >
                                                    <TableHead>
                
                                                        <TableRow className={`${classes.tr} fee-schedule-thr`}>
                                                        {dep?.gridHeaders?.split(',')?.map((header,key)=>
                                                            <TableCell key={key}>{ header}</TableCell>
                                                        
                                                        )}
                                                        </TableRow>
                
                                                    </TableHead>

                                                <TableBody>

                                                {dep?.names?.map((depNames,key)=>
                                                
                                                <TableRow key={key}>

                                                    {depNames?.split(',')?.map((item,key)=> 
                                                    <TableCell key={key}>
                                                        {item}
                                                        
                                                        </TableCell>)}

                                                    </TableRow>)}


                                                </TableBody>
            
                                            </Table>
                                            </Box>:
            
                                                    <EllipsisText textArr={dep?.names} />
                                                } />
                                            </li>)}
                                        </ul>
                                    </Grid>
                                </li>
                            )}
                        </ol>
                    </Grid>}
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        disabled={feeScheduleDependencyPending || feeScheduleDeletePending || (feeScheduleDependencies?.records?.length > 0 ? true : false)}
                        color="primary"
                    >
                        Delete
                    </Button>               
                </DialogActions>
            </Loadable>
        </Dialog>
    )
}

export default DependencyDialog
