import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { claimBulkProcess, claimProcess, flag, processBulkOnDemand, setProcessedDialog } from '../../../store/actions/claimAnalysis';
import ProcessClaimComponent from './ProcessClaimDialog';
import { setV2 } from '../../../store/actions/pagesV2';

export const ProcessClaimDialog = connect(
    () => {
        const selectPageRecord = createSelector(
            (state) => state.pages['claimAnalysis-landing'],
            (table) => table.page
        );

        const selectClaimIds = (state, props) => state.pagesV2['claimAnalysis-landing'].selected;

        const selectSignificance = createSelector(
            (state) => state.pages['claimAnalysis-landing'],
            (table) => {
                return table.significance;
            }
        );

        return (state, props) => ({
            claimPageRecord: selectPageRecord(state),
            open: state.claimAnalysis.openPlayDialog,
            selectedItems: selectClaimIds(state, props),
            significanceFlag: selectSignificance(state, props),

            claimProcessPending: state.claimAnalysis.claimprocess.pending,
            claimBulkProcessPending: state.claimAnalysis.claimBulkProcess.pending,
            claimBulkOnDemandPending: state.claimAnalysis.processBulkOnDemand.pending,
            claimBulkProcessError: state.claimAnalysis.claimBulkProcess.error,
            claimBulkProcessErrorMessage: state.claimAnalysis.claimBulkProcess.errorMessage,
            claimprocessError: state.claimAnalysis.claimprocess.error,
            claimprocessErrorMessage: state.claimAnalysis.claimprocess.errorMessage,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            claimAnalysisProcess: claimProcess,
            claimAnalysisBulkProcess: claimBulkProcess,
            setSelected: (value) => setV2('claimAnalysis-landing', 'selected', value),
            processBulkOnDemand: processBulkOnDemand,
            setProcessedDialog: setProcessedDialog
        }, dispatch)
    })
)(injectIntl(ProcessClaimComponent));