import React from 'react';
import { toAbsoluteUrl } from "../../../_metronic";

export default function Container(props) {
    const { children } = props

    return (
        <>
            <div className="kt-grid kt-grid--ver kt-grid--root">
                <div
                    id="kt_login"
                    className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--signin"
                >
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
                            <div className="kt-login__container">
                                <div className="kt-login__logo">
                                    <a href="/#">
                                        <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-mini-md.v3.png?v=4")} />
                                    </a>
                                </div>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}