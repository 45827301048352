import {
    METHOD_POST,
    SERVER_URL,
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getAllowablesList = (options) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/allowables/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updateAllowablesStatus = (id, enabled) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/allowables/status`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify({ id, enabled }),
    }).then(apiHandleResponse);
};

const selectDeselectAll = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/allowables/status/all`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload)
    }).then(apiHandleResponse);
};

export {
    getAllowablesList,
    updateAllowablesStatus,
    selectDeselectAll
};