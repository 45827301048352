import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { CustomLabel } from '../CustomLabel';
import { ROLE_SUPERADMIN } from '../../store/constants/roles';

const useStyles = makeStyles(theme => ({
    detailText: {
        marginLeft: '5px',
        cursor: 'pointer',
        display: 'inline'
    },
}));

function ErrorDetails({ extraDetails, userRole }) {
    const classes = useStyles();
    const [isDetailsOpen, setDetailsOpen] = useState(false);

    const handleOpen = () => {
        setDetailsOpen(true);
    }

    const handleClose = () => {
        setDetailsOpen(false);
    }

    return (
        <>
            {userRole === ROLE_SUPERADMIN && <Typography onClick={handleOpen} className={classes.detailText}>
                <u><b>Details</b></u>
            </Typography>}

            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="sm"
                open={isDetailsOpen}
                aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <Grid container className='align-items-center'>
                        <Grid item xs={11}>
                            <Typography variant="h6">Error Details</Typography>
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid xs={12}>
                            <CustomLabel label="Inner Exception" data={extraDetails?.innerException} />
                        </Grid>
                        <Grid xs={12}>
                            <CustomLabel label="Error Details" data={extraDetails?.errorDetails} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container className='justify-content-end'>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            color="default">
                            Close
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ErrorDetails