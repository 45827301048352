import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/opps';
import * as API from '../api/opps';
import { sessionErrorHandling } from './session';

function* create(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.OPPS_CREATE_PENDING });
        const payload = yield call(API.createOPPS, values);
        yield put({ type: actions.OPPS_CREATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.OPPS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.OPPS_UPDATE_PENDING });
        const payload = yield call(API.editOPPS, values);
        yield put({ type: actions.OPPS_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.OPPS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteDRG(action) {
    try {
        const { oppsId, callback } = action.payload;
        yield put({ type: actions.OPPS_DELETE_PENDING });
        const payload = yield call(API.deleteOPPS, oppsId);
        yield put({ type: actions.OPPS_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.OPPS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDRG(action) {
    try {
        const { oppsId } = action.payload;
        yield put({ type: actions.OPPS_GET_PENDING });
        const payload = yield call(API.getOPPS, oppsId);
        yield put({ type: actions.OPPS_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.OPPS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDRGTiny(action) {
    try {
        yield put({ type: actions.OPPS_GET_TINY_PENDING });
        const payload = yield call(API.getOPPSListTiny);
        yield put({ type: actions.OPPS_GET_TINY_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.OPPS_GET_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getOPPSTinyByContract(action) {
    try {
        const { contractId } = action.payload;
        yield put({ type: actions.OPPS_GET_TINY_BY_CONTRACT_PENDING });
        const payload = yield call(API.getOPPSListTinyByContract, contractId);
        yield put({ type: actions.OPPS_GET_TINY_BY_CONTRACT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.OPPS_GET_TINY_BY_CONTRACT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getOPPSTinyByTargetClient(action) {
    try {
        const { targetClientId } = action.payload;
        yield put({ type: actions.OPPS_GET_TINY_BY_TARGET_CLIENT_PENDING });
        const payload = yield call(API.getOPPSListByTargetClient, targetClientId);
        yield put({ type: actions.OPPS_GET_TINY_BY_TARGET_CLIENT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.OPPS_GET_TINY_BY_TARGET_CLIENT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getOPPSDependencies(action) {
    try {
        const { id } = action.payload;
        yield put({ type: actions.OPPS_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getOPPSDependency, id);
        yield put({ type: actions.OPPS_DELETE_DEPENDENCIES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.OPPS_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.OPPS_CREATE, create);
    yield takeEvery(actions.OPPS_UPDATE, update);
    yield takeEvery(actions.OPPS_DELETE, deleteDRG);
    yield takeEvery(actions.OPPS_GET, getDRG);
    yield takeEvery(actions.OPPS_GET_TINY, getDRGTiny);
    yield takeEvery(actions.OPPS_GET_TINY_BY_CONTRACT, getOPPSTinyByContract);
    yield takeEvery(actions.OPPS_GET_TINY_BY_TARGET_CLIENT, getOPPSTinyByTargetClient);
    yield takeEvery(actions.OPPS_DELETE_DEPENDENCIES, getOPPSDependencies);
}