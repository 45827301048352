import { createReducer } from '../../util';
import * as actions from '../actions/roles';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    permissions: [],
    allocatedPermissions: [],
    list: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    getDefaultPermission: {
        ...initialStatusState,
    },
    rolePermisssionById: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {
    [actions.ROLES_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.ROLES_LIST_FULFILLED]: (state, roles) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: roles,
    }),
    [actions.ROLES_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.ROLES_PERMISSIONS_GET_PENDING]: (state) => ({
        ...state,
        getDefaultPermission: {
            pending: true,
        },
    }),
    [actions.ROLES_PERMISSIONS_GET_FULFILLED]: (state, permissions) => ({
        ...state,
        getDefaultPermission: {
            ...initialStatusState,
        },
        permissions,
    }),
    [actions.ROLES_PERMISSIONS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDefaultPermission: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.ROLES_PERMISSION_ROLE_ID_PENDING]: (state) => ({
        ...state,
        rolePermisssionById: {
            pending: true,
        },
    }),
    [actions.ROLES_PERMISSION_ROLE_ID_FULFILLED]: (state, allocatedPermissions) => ({
        ...state,
        rolePermisssionById: {
            ...initialStatusState,
        },
        allocatedPermissions: allocatedPermissions,
    }),
    [actions.ROLES_PERMISSION_ROLE_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        rolePermisssionById: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.ROLES_PERMISSION_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.ROLES_PERMISSION_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
    }),
    [actions.ROLES_PERMISSION_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.ROLES_PERMISSION_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.ROLES_PERMISSION_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
    }),
    [actions.ROLES_PERMISSION_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.ROLES_RESET]: () => ({ ...initialState }),
});
