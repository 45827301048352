import { createReducer } from '../../util';
import * as actions from '../actions/partners';
import { StatusState } from './common';

const addObjectIfNotExist = (state, partner) => {
    const data = state.data;
    const { id } = partner;

    const partnerExist = data.find(x => x.id === id);
    if (!partnerExist) {
        return {
            data: [...state.data, partner]
        }
    }
};

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    tiny: [],
    partnerDependencyDialog: null,
    dependency: { records: [], canDelete: false },
    products: null,

    list: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    activate: {
        ...initialStatusState,
    },
    deactivate: {
        ...initialStatusState,
    },
    getTinyPartners: {
        ...initialStatusState
    },
    getDependencies: {
        ...initialStatusState
    },
    getProducts: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {
    [actions.PARTNERS_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.PARTNERS_LIST_FULFILLED]: (state, partners) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: partners,
    }),
    [actions.PARTNERS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARTNERS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.PARTNERS_GET_FULFILLED]: (state, partner) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, partner),
    }),
    [actions.PARTNERS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARTNERS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.PARTNERS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.PARTNERS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARTNERS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.PARTNERS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.PARTNERS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARTNERS_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.PARTNERS_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.PARTNERS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARTNERS_ACTIVATE_PENDING]: (state) => ({
        ...state,
        activate: {
            pending: true,
        },
    }),
    [actions.PARTNERS_ACTIVATE_FULFILLED]: (state) => ({
        ...state,
        activate: {
            ...initialStatusState,
        },
    }),
    [actions.PARTNERS_ACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        activate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARTNERS_DEACTIVATE_PENDING]: (state) => ({
        ...state,
        deactivate: {
            pending: true,
        },
    }),
    [actions.PARTNERS_DEACTIVATE_FULFILLED]: (state) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
        },
    }),
    [actions.PARTNERS_DEACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARTNERS_GET_TINY_PENDING]: (state) => ({
        ...state,
        getTinyPartners: {
            pending: true,
        },
    }),
    [actions.PARTNERS_GET_TINY_FULFILLED]: (state, tiny) => ({
        ...state,
        getTinyPartners: {
            ...initialStatusState,
        },
        tiny
    }),
    [actions.PARTNERS_GET_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getTinyPartners: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PARTNERS_GET_DEPENDENCY_PENDING]: (state) => ({
        ...state,
        getDependencies: {
            pending: true,
        },
    }),
    [actions.PARTNERS_GET_DEPENDENCY_FULFILLED]: (state, dependency) => ({
        ...state,
        getDependencies: {
            ...initialStatusState,
        },
        dependency
    }),
    [actions.PARTNERS_GET_DEPENDENCY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.PARTNERS_GET_PRODUCTS_PENDING]: (state) => ({
        ...state,
        getProducts: {
            pending: true,
        },
    }),
    [actions.PARTNERS_GET_PRODUCTS_FULFILLED]: (state, products) => ({
        ...state,
        getProducts: {
            ...initialStatusState,
        },
        products
    }),
    [actions.PARTNERS_GET_PRODUCTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getProducts: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARTNERS_RESET_PRODUCTS]: (state) => ({
        ...state,
        products: null
    }),

    [actions.PARTNERS_SET_DEPENDENCY_DIALOG]: (state, { partnerDependencyDialog }) => ({
        ...state,
        partnerDependencyDialog
    }),

    [actions.PARTNERS_RESET]: () => ({ ...initialState }),
});