import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { deleteContract, getContractDependencies, setContractDependencyDialog } from '../../../store/actions/contracts';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.contracts.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectContractId = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.contractId) {
                    return claimDependencyDialog?.contractId
                }
                return null
            }
        );

        const selectContractName = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.contractName) {
                    return claimDependencyDialog?.contractName
                }
                return ''
            }
        );



        return (state, props) => ({
            open: selectOpen(state),
            contractId: selectContractId(state, props),
            contractDependencies: state.contracts.dependencies,
            contractDependencyPending: state.contracts.getContractDependency.pending,
            contractDeletePending: state.contracts.contractDelete.pending,
            contractName: selectContractName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setContractDependencyDialog: setContractDependencyDialog,
            getContractDependencies: getContractDependencies,
            deleteContract: deleteContract
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));