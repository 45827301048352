import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import ChangePasswordPageComponent from './ChangePasswordPage.js';
import {changePassword} from '../../../store/actions/session';
import {selectChangePasswordPending, selectChangePasswordError, selectChangePasswordErrorMessage, selectChangePasswordDone} from '../../../store/selectors/session';

export const ChangePasswordPage = injectIntl(
    connect(
        (state) => ({
            changePasswordPending: selectChangePasswordPending(state),
            changePasswordDone: selectChangePasswordDone(state),
            changePasswordError: selectChangePasswordError(state),
            changePasswordErrorMessage: selectChangePasswordErrorMessage(state),
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                sessionChangePassword: changePassword,
            }, dispatch)
        })
)(ChangePasswordPageComponent));