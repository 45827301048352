import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getAPIKeys = (options) => {
    return fetch(`${SERVER_URL}/api/v1/key/list`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getAPIKeyById = (id) => {
    return fetch(`${SERVER_URL}/api/v1/key/value/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createAPIKey = (options) => {
    return fetch(`${SERVER_URL}/api/v1/key`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const activateAPIKey = (id) => {
    return fetch(`${SERVER_URL}/api/v1/key/activate/${id}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const deactivateAPIKey = (id) => {
    return fetch(`${SERVER_URL}/api/v1/key/deactivate/${id}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};


const getTinyList = () => {
    return fetch(`${SERVER_URL}/api/v1/application/tiny/list`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getAPIKeys,
    getAPIKeyById,
    createAPIKey,
    activateAPIKey,
    deactivateAPIKey,
    getTinyList
};