import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ClientBrandingPageComponent from './ClientBrandingPage';
import { createBranding, getBranding, updateBranding } from '../../store/actions/clients.js';
import { selectSelectedClient } from '../../store/selectors/clients';
import { selectSessionUserClientId } from '../../store/selectors/session';
import { get } from '../../store/actions/brand';


export const ClientBrandingPage = connect(
    () => {

        const selectBranding = (state) => state.clients.branding;
        const selectBrand = (state) => state.brand.data;

        const selectClientId = createSelector(selectSelectedClient, selectSessionUserClientId, (selectedClientId, sessionClientId) => {
            return selectedClientId ?? sessionClientId
        })

        const selectInitialValues = createSelector(
            selectBranding,
            (branding) => {
                if (branding && branding.id) {
                    const payload = {
                        id: branding.id,
                        logo: branding.logo,
                        logoPath: branding.logoPath,
                        usePricerLogo: branding.usePricerLogo,
                        signatureLabels: branding.signatureLabelList && branding.signatureLabelList.length > 0 ? branding.signatureLabelList : [''],
                        disclaimer: branding.disclaimer,
                        pricerBucket: branding.pricerBucket
                    }
                    return payload;
                }

                return {
                    id: '',
                    logo: null,
                    logoPath: '',
                    usePricerLogo: false,
                    signatureLabels: [''],
                    disclaimer: '',
                    pricerBucket: branding ? branding.pricerBucket : ''
                }
            }
        )

        return (state, props) => ({
            brand: selectBrand(state, props),
            initialValues: selectInitialValues(state, props),
            clientId: selectClientId(state), 
            getBrandingPending: state.clients.getBranding.pending,
            createBrandingPending: state.clients.createBranding.pending,
            updateBrandingPending: state.clients.updateBranding.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getBranding: getBranding,
            getBrand: get,
            createBranding: createBranding,
            updateBranding: updateBranding,
        }, dispatch)
    })
)(injectIntl(ClientBrandingPageComponent));
