import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import {
    Portlet, PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";

import {
    makeStyles, Grid, IconButton, Tooltip
} from "@material-ui/core";
import { PagedTable } from '../../common';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Edit as EditIcon } from '@material-ui/icons';
import { DeleteConfirmation } from '../../common/DeleteConfirmation';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];
const DEFAULT_CUSTOM_FILTER = [
    {
        field: 'isPayer',
        equator: 'equals',
        value: false,
    }
];

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function VendorsPage(props) {
    const classes = useStyles();
    const [refresh, setRefresh] = useState(Promise.resolve());

    const {
        fields,
        vendorContractsData,
        history,
        deleteVendorPending,
        actions: {
            vendorContractsLanding,
            getFacilities,
            searchGetByName,
            searchReset,
            resetPagedTable,
            deleteVendor
        }
    } = props;

    useEffect(() => {
        getFacilities()
    }, [getFacilities]);

    useEffect(() => {
        const pageName = {
            name: 'vendor-contracts-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name)
        }
    }, [searchGetByName]);

    const handleVendorEdit = (contract) => {
        history.push(`/vendor/edit/${contract.id}`);
    }

    const handleVendorView = (contract) => {
        props.history.push(`/vendor/view/${contract.id}`);
    }

    function handleAddVendor() {
        history.push('/vendor/edit/new');
    }

    function handleLoad(sort, filter, pagination) {
        vendorContractsLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handleVendorDelete({ id }) {
        deleteVendor(id, handleRefresh);
    }

    function vendorActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="View"
                onClick={() => handleVendorView(rowData)}
            >
                <Tooltip title="View">
                    <PageviewIcon />
                </Tooltip>
            </IconButton>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleVendorEdit(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
            <DeleteConfirmation handleDelete={() => handleVendorDelete(rowData)} />
        </div>
    }

    return (
        <>
            <Helmet title="Vendor Management" />
            <Portlet>
                <PortletHeader
                    title="Vendor Management"
                    name="vendor-contracts-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                />
                <PortletBody>
                    <div className={classes.container}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="vendor-contracts-landing"
                                    title="vendor contract"
                                    fields={fields}
                                    data={vendorContractsData}
                                    onLoad={handleLoad}
                                    onAdd={handleAddVendor}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    criteriasSaveEnabled={true}
                                    defaultCustomFilter={DEFAULT_CUSTOM_FILTER}
                                    loading={deleteVendorPending}

                                    hasActions={true}
                                    renderActionColumn={vendorActions}
                                    showSearchFilters={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
        </>
    );
}