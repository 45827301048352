import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from 'react';
import { DataTable } from '../../../../common';
import Draggable from 'react-draggable';

function ClaimAdjustments(props) {
    const { open, adjustmentId, type, fields, filteredData, order, sort, serviceAdjustmentPending, claimAdjustmentPending,claimId,
        actions: { setAdjustment, getServiceAdjustments, getClaimAdjustments, setOrder, setSort } } = props;

    const [refresh, setRefresh] = useState(Promise.resolve());


    useEffect(() => {
        if (open && type) {
            if (type === 'SERVICE') {
                getServiceAdjustments(adjustmentId,claimId)
            } else if (type === 'CLAIM') {
                getClaimAdjustments(adjustmentId,claimId);
            }
        }
    }, [open, adjustmentId, type, refresh])


    const closeAdjustmentHandler = () => {
        const payload = { open: false }
        setAdjustment(payload);
    }

    const handleRefresh = () => {
        setRefresh(Promise.resolve());
    }

    const hoverData = (adjustment) => {
        const hoverColumns = new Map();
        const reasonCodeColumn = [
            {
                label: "",
                type: 'ELLIPSE',
                data: adjustment.reasonShortDescription,
                longData: adjustment.reasonDescription
            }
        ];

        hoverColumns.set('casadjreasoncode', reasonCodeColumn);

        return hoverColumns;
    }

    function PaperComponent(props) {
        return (
            <Draggable
                handle="#claim-adjustments-dialog"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <div>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="md"
                open={open}
                onClose={closeAdjustmentHandler}
                PaperComponent={PaperComponent}
                aria-labelledby="claim-adjustments-dialog"
            >
                <DialogTitle style={{ cursor: 'move' }} id="claim-adjustments-dialog">
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            {type === 'CLAIM' ? 'Claim' : type === 'SERVICE' ? 'Service' : ''} Adjustments
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={closeAdjustmentHandler}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <DataTable
                        fields={fields}
                        data={filteredData}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        loading={serviceAdjustmentPending || claimAdjustmentPending}
                        handleRefresh={handleRefresh}
                        hoverData={hoverData}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ClaimAdjustments