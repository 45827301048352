import { createReducer } from '../../util';
import * as actions from '../actions/facilities';
import { StatusState } from './common';

const addObjectIfNotExist = (state, facility) => {
    const data = state.data;
    const { id } = facility;

    const facilityExist = data.find(x => x.id === id);
    if (!facilityExist) {
        return {
            data: [...state.data, facility]
        }
    }
};

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    facilitiesFromTargetClient: [],
    facilityDependencies: { records: [], canDelete: false },
    dependencyDialog: null, //{open:true,facilityId:}
    list: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getFacilitiesFromTargetClient: {
        ...initialStatusState
    },
    getFacilityDependencies: {
        ...initialStatusState
    },
    activate: {
        ...initialStatusState
    },
    deactivate: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {
    [actions.FACILITIES_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
        data: []
    }),
    [actions.FACILITIES_LIST_FULFILLED]: (state, facilities) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: facilities,
    }),
    [actions.FACILITIES_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FACILITIES_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.FACILITIES_GET_FULFILLED]: (state, facility) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, facility),
    }),
    [actions.FACILITIES_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FACILITIES_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.FACILITIES_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.FACILITIES_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FACILITIES_EDIT_RESET]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        },
    }),
    [actions.FACILITIES_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.FACILITIES_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.FACILITIES_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FACILITIES_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.FACILITIES_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.FACILITIES_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FACILITIES_BY_TARGET_CLIENT_PENDING]: (state) => ({
        ...state,
        getFacilitiesFromTargetClient: {
            pending: true,
        },
    }),
    [actions.FACILITIES_BY_TARGET_CLIENT_FULFILLED]: (state, facilitiesFromTargetClient) => ({
        ...state,
        getFacilitiesFromTargetClient: {
            ...initialStatusState,
        },
        facilitiesFromTargetClient
    }),
    [actions.FACILITIES_BY_TARGET_CLIENT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFacilitiesFromTargetClient: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FACILITIES_GET_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getFacilityDependencies: {
            pending: true,
        },
    }),
    [actions.FACILITIES_GET_DEPENDENCIES_FULFILLED]: (state, facilityDependencies) => ({
        ...state,
        getFacilityDependencies: {
            ...initialStatusState,
        },
        facilityDependencies
    }),
    [actions.FACILITIES_GET_DEPENDENCIES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFacilityDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FACILITIES_ACTIVATE_PENDING]: (state) => ({
        ...state,
        activate: {
            pending: true,
        },
    }),
    [actions.FACILITIES_ACTIVATE_FULFILLED]: (state) => ({
        ...state,
        activate: {
            ...initialStatusState,
        }
    }),
    [actions.FACILITIES_ACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        activate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FACILITIES_DEACTIVATE_PENDING]: (state) => ({
        ...state,
        deactivate: {
            pending: true,
        },
    }),
    [actions.FACILITIES_DEACTIVATE_FULFILLED]: (state) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
        }
    }),
    [actions.FACILITIES_DEACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FACILITIES_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),
    [actions.FACILITIES_RESET_DEPENDENCY]: (state) => ({
        ...state,
        facilityDependencies: { records: [], canDelete: false }
    }),
    [actions.FACILITIES_RESET]: () => ({ ...initialState }),
});
