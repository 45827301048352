import { apiHandleResponse } from '../../util/api';
import {
    METHOD_GET,
    METHOD_PUT,
    SERVER_URL
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson } from '../headers';

const list = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/settings`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const update = (service) => {
    return fetch(`${SERVER_URL}/api/v1/cms/setting`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(service)
    }).then(apiHandleResponse);
};

const getReleaseVersion = () => {
    return fetch(`${SERVER_URL}/api/v1/platform/release`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getReleaseNotes = () => {
    return fetch(`${SERVER_URL}/api/v1/platform/release/notes`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getReleaseNotes, getReleaseVersion, list,
    update
};
