import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import {
    Portlet, PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";

import {
    makeStyles, Grid, Tooltip, IconButton
} from "@material-ui/core";
import { PagedTable } from '../../../common';

import { Edit as EditIcon } from '@material-ui/icons';
import { DeleteConfirmation } from "../../../common/DeleteConfirmation";


import { ContractsFamilyDialog } from '../ContractFamilyDialog';


const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    button: {
        padding: '0.25rem',
    },
    banIcon: {
        fontSize: 17
    }
}));


const DEFAULT_CUSTOM_FILTER = [
];


export default function ContractsFamilyPage(props) {
    const classes = useStyles();
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [isAddContractFamilyOpen, setIsAddContractFamilyOpen] = useState(false);


    const {
        fields,
        contractsData,
        history,
        deletePending,
        actions: {
            contractsLanding,
            searchGetByName,
            searchReset,
            deleteReset,
            resetPagedTable,
            deleteContractFamily

        }
    } = props;

    const handleContractEdit = (contractFamily) => {
        props.history.push(`/cms/family/edit/${contractFamily.id}`);
    }

    function handleLoad(sort, filter, pagination) {
        contractsLanding(pagination, sort, filter);
        deleteReset();
    }

    function handleCreateFamilyDialogOpen() {
        setIsAddContractFamilyOpen(true);
    }
    function handleCreateFamilyDialogClose() {
        setIsAddContractFamilyOpen(false);
    }


    const handleClose = () => { 
        setIsAddContractFamilyOpen(false);
        handleRefresh()
    }

    useEffect(() => {
        const pageName = {
            name: 'contracts-family-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            deleteReset();
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }
    const handleFamilyDelete = (rowData) => { 
        deleteContractFamily(rowData?.id,handleRefresh)
    }

    function contractActions(rowData) {
        return <div className='d-flex'>
       
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleContractEdit(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
            <DeleteConfirmation handleDelete={() => handleFamilyDelete(rowData)} />

        </div>
    }

    return (
        <>
            <Helmet title="Contract Family Management" />
            <Portlet>
                <PortletHeader
                    title="Contract Family Management"
                    name="contracts-family-landing"
                    
                />
                <PortletBody>
                    <div className={classes.container}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className="error">
                                    {/* {renderMessage()} */}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="contracts-family-landing"
                                    title="contract family"
                                    fields={fields}
                                    data={contractsData}
                                    onLoad={handleLoad}
                                    onAdd={handleCreateFamilyDialogOpen}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    criteriasSaveEnabled={true}
                                    defaultCustomFilter={DEFAULT_CUSTOM_FILTER}
                                    loading={deletePending}
                                    hasActions={true}
                                    renderActionColumn={contractActions}
                                    // showSearchFilters={true}
                                />

                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
          

            <ContractsFamilyDialog open={isAddContractFamilyOpen} handleClose={handleClose} handleCancel={handleCreateFamilyDialogClose} handleRefresh={handleRefresh}/>

        </>
    );
}
