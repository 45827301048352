import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import ConfirmationDialog from './ConfirmationDialog'


export const ConfigurationClearConfirmationDialog = connect(
    () => ({
    }),
    (dispatch) => ({
        actions: bindActionCreators(
            {
              
            },
            dispatch
        )
    })
)(injectIntl(ConfirmationDialog));
