import React, { useEffect, useState } from "react";
import { Divider, FormControl, Input, InputLabel, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { Loadable, PopoverLink } from '../../../common';
import { Helmet } from "react-helmet";
import { SettingsEditPage } from "./settingsEditPage";

const useStyles = makeStyles(theme => ({
    color: {
        color: '#48465b'
    },
    helpIcon: {
        fontSize: '1.2rem'
    },
    formControl: {
        width: 300,
        margin: '0'
    },
    testingDiv: {
        width: '100%',
        border: '2px solid #eff0f6',
        padding: '2rem',
        marginTop: '1rem',
    }
}));

export default function SettingsComponent(props) {
    const { settingPending, filteredData, searchOptions, filter,
        actions: { getSettings, setFilter, }
    } = props;
    const classes = useStyles();

    useEffect(() => {
        getSettings();
        return () => {
            setFilter("All");
        }
    }, [getSettings, setFilter]);

    const filterChangeHandler = (event) => {
        setFilter(event.target.value);
    }

    const handleCallBack = () => {
        getSettings();
    }

    function hoverColumn(setting) {
        return (
            <PopoverLink
                columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-primary`}></i>}
                hoverData={[
                    {
                        data: setting?.helpText
                    }
                ]}
                title={setting?.name}
            />
        );
    }

    return (
        <>
            <Helmet title="Settings" />
            <Portlet className={classes.portlet}>
                <PortletHeader
                    title="Settings"
                    toolbar={
                        <PortletHeaderToolbar>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="filter">Select Module</InputLabel>
                                <Select
                                    value={filter}
                                    onChange={filterChangeHandler}
                                    input={<Input id="filter" />}
                                    inputProps={{
                                        name: "filter",
                                        id: "filter"
                                    }}
                                >
                                    <MenuItem
                                        value='All'
                                    >
                                        All
                                    </MenuItem>
                                    {searchOptions.map((module) =>
                                        <MenuItem
                                            key={module.id}
                                            value={module.id}
                                        >
                                            {module.name}
                                        </MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </PortletHeaderToolbar>
                    }
                />
                <Loadable loading={settingPending}>
                    <PortletBody>
                        {filteredData.map((setting, idx) => {
                            return (
                                <React.Fragment key={`${idx}-setting`}>
                                    <div className={classes.color + " row ml-n1 py-3"}>
                                        <div className="col-md-4 col-xl-3 py-1">
                                            <span className="font-weight-bold mr-1">{setting.name}: </span>
                                            {hoverColumn(setting)}
                                        </div>
                                        <div className="col-md-3 font-weight-600 py-1">
                                            <span className="mr-1">{filter === 'All' ? setting.productTypes : filter} </span>
                                        </div>
                                        <div className="col-md-3 py-1">
                                            <SettingsEditPage
                                                setting={setting}
                                                handleCallBack={handleCallBack}
                                            />
                                        </div>
                                    </div>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                        {filteredData.length === 0 && "No Setting available"}

                    </PortletBody>
                </Loadable>
            </Portlet>

        </>
    );
}