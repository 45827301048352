import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import APIKeysPageComponent from './APIKeysPage';
import { set } from '../../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { createAPIKeyField, createDateField, createIconDataField, createStringDataField, createTimestampField } from '../../../util/format';
import { selectAPIKeys } from '../../../store/selectors/apikey';
import { activateAPIKey, deactivateAPIKey, getAPIKeys, resetAPIKeysList } from '../../../store/actions/apikey';
import { getAPIKeyValue, resetActualKey } from '../../../store/actions/apikey';
import { selectActualAPIKeyObject } from '../../../store/selectors/apikey';


const DEFAULT_SORT = [{ id: 'expiresOn', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Name', { sortable: true }),
        createStringDataField('userName', 'User', { sortable: true }),
        createIconDataField('expired', 'Expired', { hideFromFilter: false, isStrictDropdown: true, icon: "fas fa-times-circle", checkedClass: 'text-danger' }),
        createTimestampField('expiresOn', 'Expires On', { sortable: true, fieldType: 'Date' }),
        createStringDataField('createdBy', 'Created By', { sortable: true }),
        createDateField('createdAt', 'Created At', { sortable: true }),
        createTimestampField('lastUsed', 'Last Used', { sortable: true, fieldType: 'Date' }),
        createStringDataField('applicationName', 'Application Name', { sortable: true }),
        createAPIKeyField('value', 'Key', { sortable: true, returnWholeRow: true,appId:false }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['api-keys-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['api-keys-landing'].filter,
    selectSort,
    selectAPIKeys,
);

export const APIKeysPage = injectIntl(
    connect(
        (state) => ({
            order: state.pages['api-keys-landing'].order,
            filter: state.pages['api-keys-landing'].filter,
            sort: selectSort(state),
            apiKeysData: selectFilteredData(state),
            fields: selectFields(state),
            getAPIKeysPending: state.apikey.getList.pending,
            getAPIKeyValuePending: state.apikey.getValueById.pending,
            deactivateAPIKeyPending: state.apikey.deactivate.pending,
            activateAPIKeyPending: state.apikey.activate.pending,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                getAPIKeys: getAPIKeys,
                deactivateAPIKey: deactivateAPIKey,
                activateAPIKey: activateAPIKey,
                resetAPIKeysList: resetAPIKeysList,
                setFilter: (value) => set('api-keys-landing', 'filter', value),
                setOrder: (value) => set('api-keys-landing', 'order', value),
                setSort: (value) => set('api-keys-landing', 'sort', value),
            }, dispatch)
        })
    )(APIKeysPageComponent));