import * as yup from 'yup';
import { PASSWORD_REGEX,CLIENT_PASSWORD_REGEX, ALPHABETS_REGEX, CLIENT_SCHEMA_REGEX, LOWER_CASE_ALPHABETS_REGEX, CIDR_REGEX,PUBLIC_KEY_REGEX} from '../constants/pages';


export const addValidationSchema = yup.object().shape({
    name: yup.string().required("Required field")
        .matches(ALPHABETS_REGEX, "Only allows alphabetical"),
    displayName: yup.string().required("Required field"),
    maxUsers: yup.string().required("Required field"),
    schema: yup.string().required("Required field")
        .matches(ALPHABETS_REGEX, "Only allows alphabetical")
        .matches(CLIENT_SCHEMA_REGEX, "Maximum 20 characters are allowed"),
    buckets: yup.array().of(
        yup.object().shape({
            name: yup.string().required("Required field").matches(LOWER_CASE_ALPHABETS_REGEX, "Bucket name should only have lower case letters"),
            parser: yup.string().required("Required field"),
            ftpUser: yup.object().shape({
                hasPassword:yup.boolean(),
                name: yup.string().required("Required field"),
               password: yup.string()
                    .when('hasPassword', {
                        is: true,
                        then: yup.string()
                            .required("Required field")
                            .matches(CLIENT_PASSWORD_REGEX, "Password must be between 12 to 20 characters, contains at least one numeric digit, one uppercase, one lowercase letter, and no special characters."),
                        otherwise: yup.string().notRequired()
                    }),
                confirmPassword: yup.string()
                    .when('hasPassword', {
                        is: true,
                        then: yup.string()
                            .required("Required field")
                            .matches(CLIENT_PASSWORD_REGEX, "Password must be between 12 to 20 characters, contains at least one numeric digit, one uppercase, one lowercase letter, and no special characters.")
                            .oneOf([yup.ref('password'), null], "Password and Confirm Password didn't match."),
                        otherwise: yup.string().notRequired()
                    }),

                newPassword: yup.string()
                .matches(CLIENT_PASSWORD_REGEX, "Password must be between 12 to 20 characters, contains at least one numeric digit, one uppercase, one lowercase letter, and no special characters."),

                allowedIp: yup.string()
                .when('hasPassword', {
                        is: true,
                        then: yup.string()
                            .required("Required field").matches(CIDR_REGEX, "IP must be in comma-separated CIDR format, e.g., 192.168.1.0/24,10.8.0.100/32"),
                            
                        otherwise: yup.string().notRequired()
                    }),
                publicKey:yup.string()
                .when('hasPassword', {
                    is:(value)=> value=== false || !value,
                    then: yup.string()
                        .required("Required field")
                        .matches(PUBLIC_KEY_REGEX, "Please Enter a Valid Public Key"),
                        otherwise: yup.string().notRequired()
                }),
            }),
            
        })
    ),
    address: yup.string().required("Required field"),
    contact: yup.string().required("Required field"),
});

export const editValidationSchema = yup.object().shape({
    displayName: yup.string().required("Required field"),
    address: yup.string().required("Required field"),
    contact: yup.string().required("Required field"),
    useNewFTP: yup.boolean(),

    buckets: yup.array()
        .when('useNewFTP', {
            is: true,
            then: yup.array().of(
                yup.object().shape({
                    name: yup.string().required("Required field").matches(LOWER_CASE_ALPHABETS_REGEX, "Bucket name should only have lower case letters"),
                    parser: yup.string().required("Required field"),
                    id: yup.number(),
                    ftpUser: yup.object().shape({
                        hasPassword:yup.boolean(),
                        name: yup.string().required("Required field"),
                        
                        password: yup.string()
                            .when('hasPassword', {
                                is: true,
                                then: yup.string()
                                    .required("Required field")
                                    .matches(CLIENT_PASSWORD_REGEX, "Password must be between 12 to 20 characters, contains at least one numeric digit, one uppercase, one lowercase letter, and no special characters."),
                                otherwise: yup.string().notRequired()
                            }),
                            confirmPassword: yup.string()
                            .nullable()
                            .oneOf([yup.ref('password'), null], "Password and Confirm Password didn't match")
                            .test('confirmPasswordCheck', 'Confirm Password is required', function(value) {
                              const { password, bucketId,id,hasPassword } = this.parent;
                              if (password && (!bucketId && id===0) && !value && hasPassword===true) {
                                return false;
                              }
                              return true;
                            }),
        
                            newPassword: yup.string()
                            .matches(CLIENT_PASSWORD_REGEX, "Password must be between 12 to 20 characters, contains at least one numeric digit, one uppercase, one lowercase letter, and no special characters.")
                            .test('is-different', "New password must be different from the current password.", function (value) {
                                const { password } = this.parent; // Access the parent object to get password
                                // Only apply the check if both password and newPassword are defined and non-empty
                                return (password && value) ? password !== value : true;
                            })
                            .when(['hasPassword', 'password'], {
                                is: (hasPassword, password) => hasPassword === true && !password,
                                then: yup.string().required("Required field."),
                                otherwise: yup.string().notRequired()
                            }),
                        confirmNewPassword: yup.string()
                        .when('newPassword', {
                            is: (value) => value && value.length > 0,
                            then: yup.string()
                                .oneOf([yup.ref('newPassword'), null], "Passwords do not match.")
                                .required("Required field."),
                        }),
                        allowedIp: yup.string()
                        .when('hasPassword', {
                                is: true,
                                then: yup.string()
                                    .required("Required field").matches(CIDR_REGEX, "IP must be in comma-separated CIDR format, e.g., 192.168.1.0/24,10.8.0.100/32"),
                                    
                                otherwise: yup.string().notRequired()
                            }),
                        publicKey:yup.string()
                        .when('hasPassword', {
                            is:(value)=> value=== false || !value,
                            then: yup.string()
                                .required("Required field")
                                .matches(PUBLIC_KEY_REGEX, "Please Enter a Valid Public Key"),
                                otherwise: yup.string().notRequired()
                        }),
                        
        
        
                    }),
                    
                })),
            otherwise: yup.array().notRequired(), // Skip `buckets` validation when `useNewFTP` is false
        })
});
