import { takeEvery, put, call, select } from 'redux-saga/effects';
import * as actions from '../actions/users';
import * as API from '../api/users';
import { sessionErrorHandling } from './session';
import { ROLE_SUPERADMIN, ROLE_PARTNER_ADMIN } from '../constants/roles';

function* list() {
    try {
        yield put({ type: actions.USERS_LIST_PENDING });
        const { clientId, role } = yield select((state) => state.session.user);

        // if superadmin or Partner then get the list.
        // rest all the users, get based on clientId
        const id = (role === ROLE_SUPERADMIN || role === ROLE_PARTNER_ADMIN) ? undefined : clientId;
        const payload = yield call(API.getUsers, id);
        yield put({ type: actions.USERS_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* listTiny(action) {
    try {
        yield put({ type: actions.USERS_LIST_TINY_PENDING });
        const { clientId } = action.payload;
        const payload = yield call(API.getUsersTiny, clientId);
        yield put({ type: actions.USERS_LIST_TINY_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_LIST_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* listTinyWithoutClient(action) {
    try {
        yield put({ type: actions.USERS_LIST_TINY_WITHOUT_CLIENT_PENDING });
        const payload = yield call(API.getUsersTinyWithoutClient);
        yield put({ type: actions.USERS_LIST_TINY_WITHOUT_CLIENT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_LIST_TINY_WITHOUT_CLIENT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.USERS_GET_PENDING });
        const { userId, callback } = action.payload;
        const payload = yield call(API.getUser, userId);
        yield put({ type: actions.USERS_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.USERS_CREATE_PENDING });
        const {
            user,
            callback
        } = action.payload;

        const payload = {
            ...user,
        }
        const newUser = yield call(API.createUser, payload);
        yield put({ type: actions.USERS_CREATE_FULFILLED, newUser });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.USERS_UPDATE_PENDING });
        const {
            user,
            callback
        } = action.payload;

        const newUser = yield call(API.updateUser, user);
        yield put({ type: actions.USERS_UPDATE_FULFILLED, newUser });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteUser(action) {
    try {
        yield put({ type: actions.USERS_DELETE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deleteUser, id);
        yield put({ type: actions.USERS_DELETE_FULFILLED, payload: { id } });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deactivateUser(action) {
    try {
        yield put({ type: actions.USERS_DEACTIVATE_PENDING });
        const { userId, callback } = action.payload;
        yield call(API.deactivateUser, userId);
        yield put({ type: actions.USERS_DEACTIVATE_FULFILLED, payload: { userId } });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_DEACTIVATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* activateUser(action) {
    try {
        yield put({ type: actions.USERS_ACTIVATE_PENDING });
        const { userId, callback } = action.payload;
        yield call(API.activateUser, userId);
        yield put({ type: actions.USERS_ACTIVATE_FULFILLED, payload: { userId } });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_ACTIVATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getUsersByRole(action) {
    try {
        yield put({ type: actions.USERS_TINY_BY_ROLE_PENDING });
        const { role } = action.payload;
        const payload = yield call(API.getUsersTinyByRole, role);
        yield put({ type: actions.USERS_TINY_BY_ROLE_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.USERS_TINY_BY_ROLE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.USERS_RESET });
}

export default function* () {
    yield takeEvery(actions.USERS_LIST, list);
    yield takeEvery(actions.USERS_LIST_TINY, listTiny);
    yield takeEvery(actions.USERS_CREATE, create);
    yield takeEvery(actions.USERS_UPDATE, update);
    yield takeEvery(actions.USERS_GET, get);
    yield takeEvery(actions.USERS_DELETE, deleteUser);
    yield takeEvery(actions.USERS_LIST_TINY_WITHOUT_CLIENT, listTinyWithoutClient);
    yield takeEvery(actions.USERS_DEACTIVATE, deactivateUser);
    yield takeEvery(actions.USERS_ACTIVATE, activateUser);
    yield takeEvery(actions.USERS_TINY_BY_ROLE, getUsersByRole);
}
