import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { exportRemitPayer, importRemitPayer, remitPayerImportErrorReset } from '../../../store/actions/payers';
import PayerImportComponent from './PayerImport';
import { createCurrencyDataField, createIconDataField, createNumberDataField, createStringDataField } from '../../../util/format';
import { integerEquators, StringEquators } from '../../../store/constants/pages';
import { set } from '../../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../../util/selector';

export const PayerImport = connect(
    () => {

        const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

        const selectFields = createSelector(
            () => [
                createStringDataField('name', 'Payer Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                createStringDataField('contracts', 'Contracts', { sortable: true, equators: StringEquators }),
                createNumberDataField('totalClaimsCount', 'Total Claims', { sortable: true, equators: integerEquators }),
                createCurrencyDataField('totalClaimAmount', 'Total Claim Amount', { sortable: true, equators: integerEquators }),
                createCurrencyDataField('totalPaidAmount', 'Total Paid Amount', { sortable: true, equators: integerEquators }),
                createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
            ]
        );
        
        const selectPayersData = createSelector(
            (state) => state.payers.remitPayerImports,
            (data) => {
                return data;
            }
        );

        const selectFilteredFields = createSelector(
            selectFields,
            (fields) => {
                return fields.map(field => field.id);
            }
        );
        
        const selectSort = createSelector(
            (state, props) => state.pages[`remit-payers-import-landing`].sort,
            (sort) => sort || DEFAULT_SORT,
        );
        
        const selectFilteredData = createFilteredSortedDataSelector(
            selectFilteredFields,
            (state, props) => state.pages[`remit-payers-import-landing`].filter,
            selectSort,
            selectPayersData,
        );

        return (state, props) => ({
            fields: selectFields(state),
            filteredData: selectFilteredData(state),
            remitPayerImportPending: state.payers.getRemitPayerImport.pending,
            order: state.pages[`remit-payers-import-landing`].order,
            filter: state.pages[`remit-payers-import-landing`].filter,
            sort: selectSort(state, props),
            remitPayerExportPending: state.payers.exportRemitPayer.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            importRemitPayer: importRemitPayer,
            remitPayerImportErrorReset: remitPayerImportErrorReset,
            exportRemitPayers: exportRemitPayer,
            setList: (value) => set('remit-payers-import-landing', 'list', value),
            setFilter: (value) => set('remit-payers-import-landing', 'filter', value),
            setSort: (value) => set('remit-payers-import-landing', 'sort', value),
            setOrder: (value) => set('remit-payers-import-landing', 'order', value),
        }, dispatch)
    })
)(injectIntl(PayerImportComponent));