import React, { useEffect, useState } from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import { DataTable, DataFilter } from '../../../common';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Helmet from 'react-helmet';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { DeleteConfirmation } from "../../../common/DeleteConfirmation";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  textField: {
    width: 400,
  },
}));

export default function DashboardTypesPage(props) {
  const classes = useStyles();
  const search = useLocation().search;
  const persist = new URLSearchParams(search).get('persist');

  const {
    dashboardsFilteredData, fields, sort, dashboardTypeListPending, dashboardTypeDeletePending,
    order, filter, actions: { setFilter, setSort, setOrder, dashboardTypeList, dashboardTypeDelete }
  } = props;


  useEffect(() => {
    dashboardTypeList();
    if (!persist) {
      setFilter('');
    }
  }, [dashboardTypeList]);

  const handleDashboardEdit = (dashboard) => {
    props.history.push(`/admin/dashboardTypes/edit/${dashboard.id}`);
  }

  const handleCallBack = () => {
    dashboardTypeList();
  }

  const handleDashboardTypeDelete = (dashboardType) => {
    if (dashboardType) {
      dashboardTypeDelete(dashboardType.id, handleCallBack);
    }
  }

  const handleSearchInput = (value) => {
    setFilter(value);
  }

  const handleAddDashboardType = () => {
    props.history.push('/admin/dashboardTypes/edit/new');
  }

  function typesAction(rowData) {
    return <div className='d-flex'>
      <IconButton
        color="primary"
        className='p-1'
        aria-label="Edit"
        onClick={() => handleDashboardEdit(rowData)}
      >
        <Tooltip title="Edit">
          <EditIcon />
        </Tooltip>
      </IconButton>
      <DeleteConfirmation handleDelete={() => handleDashboardTypeDelete(rowData)} />

    </div>
  }

  function handleRefresh() {
    dashboardTypeList();
  }

  return (
    <>
      <Helmet title="Dashboard Configuration" />
      <Portlet>
        <PortletHeader
          title="Dashboard Types"
          toolbar={
            <PortletHeaderToolbar>
              <DataFilter
                onChange={handleSearchInput}
                placeholder="Search"
                cssClass={classes.textField}
                filter={filter}
              />
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <DataTable
            fields={fields}
            data={dashboardsFilteredData}
            onAdd={handleAddDashboardType}
            order={order}
            onOrder={setOrder}
            sort={sort}
            onSort={setSort}
            loading={dashboardTypeListPending || dashboardTypeDeletePending}
            name="Dashboard Type"
            handleRefresh={handleRefresh}

            renderActionColumn={typesAction}
            hasActions={true}
          />
        </PortletBody>
      </Portlet>
    </>
  );
}
