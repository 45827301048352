import React, { Component } from "react";
import * as actions from "../../store/actions/session";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { set } from '../../store/actions/pages';
import { LayoutSplashScreen } from "../../../_metronic";

class Logout extends Component {
  componentDidMount() {
    //TODO need to pass manual flag here.
    this.props.logout(true);
    localStorage.removeItem("isCriteriaCleared") //Remove both custom maintained states from storage after logout even on same tab
    sessionStorage.removeItem("pages")
    this.props.set('claimAnalysis-landing', 'selectedFilter', null)
    this.props.set('claimAnalysis-landing', 'waitForCriteriaFilter', true)
    sessionStorage.removeItem("duplicated");             
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

export default connect(
  ({ session }) => ({ hasAuthToken: Boolean(session.authToken) }),
  {...actions,set}
)(Logout);
