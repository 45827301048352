import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { Loadable } from "../../../common";

const useStyles = makeStyles(theme => ({
    textField: {
        width: "85%"
    },
    formControlAuto: {
        marginTop: theme.spacing(5),
        width: "85%"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    dropZone: {
        minHeight: 150,
        border: "none",
        backgroundColor: "#EAEAEA",
        borderRadius: "20px"
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: "100%"
    }
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function UploadDialog(props) {
    const {
        intl,
        open,
        handleClose,
        uploadFilePending,
        initialValues,
        tinyContracts,
        handleCancel,
        actions: {getTinyContracts ,createContractFamily}
    } = props;
    const classes = useStyles();


    useEffect(() => {
        getTinyContracts();
    }, [getTinyContracts]);
    

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={handleCancel}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            Add Contract Family
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleCancel}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={uploadFilePending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};

                            if (!values.name) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (values.name.length>250) {
                                errors.name = "Contract family name should not exceed 250 characters.";
    
                            }    

                            if (values.contracts.length < 2) {
                                errors.contracts = "Select at least two contracts.";
                            }
                            

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {

                           const contractFamilyPrecedence=values?.contracts.map((item,index)=>({contractId:item?.id,precedence:index}))
                           const payload={
                            "name": values?.name,
                            "description": values?.description,
                            "active": true,
                            "contractFamilyXref": contractFamilyPrecedence

                           }
                            createContractFamily(payload,handleClose)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                                className={classes.container}
                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            label="Name"
                                            name="name"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            helperText={
                                                touched.name && errors.name
                                            }
                                            error={Boolean(
                                                touched.name && errors.name
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                        
                                            label="Description"
                                            name="description"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            helperText={
                                                touched.description &&
                                                errors.description
                                            }
                                            error={Boolean(
                                                touched.description &&
                                                    errors.description
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formControlAuto}
                                        >
                                            <Autocomplete
                                                onChange={(e, value) =>
                                                    setFieldValue(
                                                        "contracts",
                                                        value
                                                    )
                                                }
                                                value={values.contracts}
                                                multiple
                                                options={tinyContracts}
                                                disableCloseOnSelect
                                                getOptionLabel={option =>
                                                    option.name
                                                }
                                                renderOption={(
                                                    option,
                                                    { selected }
                                                ) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={
                                                                checkedIcon
                                                            }
                                                            style={{
                                                                marginRight: 8
                                                            }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </React.Fragment>
                                                )}
                                                renderTags={(
                                                    tagValue,
                                                    getTagProps
                                                ) => {
                                                    const numTags =
                                                        tagValue.length;
                                                    return (
                                                        <Typography variant="caption">
                                                            {tagValue
                                                                .slice(0, 2)
                                                                .map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <Chip
                                                                            size="small"
                                                                            color="secondary"
                                                                            className={
                                                                                classes.chip
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                            label={
                                                                                option.name
                                                                            }
                                                                            {...getTagProps(
                                                                                {
                                                                                    index
                                                                                }
                                                                            )}
                                                                        />
                                                                    )
                                                                )}
                                                            {numTags > 2 &&
                                                                ` + ${numTags -
                                                                    2}`}
                                                        </Typography>
                                                    );
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                    
                                                        {...params}
                                                        label="Contracts"
                                                        error={Boolean(touched.contracts && errors.contracts)}
                                                        helperText={touched.contracts && errors.contracts}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        container
                                        className="justify-content-end pt-2"
                                    >
                                        <Button
                                            type="button"
                                            onClick={handleCancel}
                                            variant="contained"
                                            className="mr-3"
                                            color="default"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    );
}

export default UploadDialog;
