import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import { bindActionCreators } from 'redux';

import ContractModelSimulatorComponent from './ContractModelSimulator';

export const ContractModelSimulator = injectIntl(
    connect(
        (state, props) => ({
        }),
        (dispatch) => ({
            actions: bindActionCreators({
            }, dispatch)
        })
    )(ContractModelSimulatorComponent));