import React, { useState } from "react";
import { Typography, makeStyles, Popover, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";
import { CustomLabel, Loadable } from "..";

const useStyles = makeStyles(theme => {
    return {
        label: {
            fontSize: "1rem"
        },
        textField: {
            width: 400
        },
        portlet: {
            "box-shadow": "none",
            marginBottom: 0,
            "& .kt-portlet__head": {
                borderColor: theme.palette.secondary.main,
                marginLeft: theme.spacing(3.5)
            }
        },
        popover: {
            pointerEvents: "none"
        },
        paper: {
            pointerEvents: "auto",
            width: 300
        },
        underline: {
            textDecoration: "underline"
        }
    };
});

export default function CustomLabelPopover(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [serviceDetails, setServiceDetails] = React.useState(null);
    const [openedPopover, setOpenedPopover] = useState(false);
    const [serviceLoading, setServiceLoading] = useState(false);

    const { label, data, getDetails } = props;
    const classes = useStyles();

    const preventDefault = event => event.preventDefault();
    const paperEnter = ({ currentTarget }) => {
        setOpenedPopover(true);
    };
    const popoverEnter = (event, name) => {
        setServiceLoading(true);

        setAnchorEl(event.currentTarget);
        setOpenedPopover(true);
        const details = getDetails(name);
        if (details) {
            setServiceDetails(details);
        }
        setServiceLoading(false);
    };

    const popoverLeave = ({ currentTarget }) => {
        setOpenedPopover(false);
    };


    return (
        <>
            <Typography
                className={classes.label}
                variant="subtitle2"
                display="inline"
            >{`${label}: `}</Typography>
            <Typography variant="subtitle1" display="inline">
                <Link
                    to=""
                    href="#"
                    onClick={preventDefault}
                    aria-owns="service-mouse-over-popover"
                    aria-haspopup="true"
                    onMouseEnter={event => popoverEnter(event, data)}
                    onMouseLeave={popoverLeave}
                    className={classes.underline}
                >
                    {data}
                </Link>{" "}
            </Typography>
            <Popover
                id="service-mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper
                }}
                open={openedPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
                PaperProps={{
                    onMouseEnter: paperEnter,
                    onMouseLeave: popoverLeave
                }}
                disableRestoreFocus
            >
                <Loadable loading={serviceLoading}>
                    <Portlet className={classes.portlet}>
                        <PortletHeader
                            className={classes.portletHeader}
                            title={data}
                            showTitle={true}
                        />
                        <PortletBody>
                            {serviceDetails && (
                                <Grid className={classes.root} container>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <CustomLabel
                                                    label="Name"
                                                    data={serviceDetails?.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomLabel
                                                    label="Service Type"
                                                    data={
                                                        serviceDetails?.serviceType
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomLabel
                                                    label="Primary Code"
                                                    data={
                                                        serviceDetails?.primaryCodes
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomLabel
                                                    label="Primary Rule Method"
                                                    data={
                                                        serviceDetails
                                                            .primaryRuleMethod
                                                            ?.name
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomLabel
                                                    label="Qualifier Codes"
                                                    data={
                                                        serviceDetails
                                                            .qualifierCodes
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomLabel
                                                    label="Qualifier Rule Method"
                                                    data={
                                                        serviceDetails
                                                            .qualifierRuleMethod?.name
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </PortletBody>
                    </Portlet>
                </Loadable>
            </Popover>
        </>
    );
}
