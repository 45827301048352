import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import { bindActionCreators } from 'redux';

import EulaPageComponent from './eulaPage.js';
import {selectDashboardUrl, selectDashboardListByClient} from '../../store/selectors/dashboards';
import { logout, acceptEula } from '../../store/actions/session';


export const EulaPage = injectIntl(
    connect(
        (state, props) => ({
            dashboardUrl: selectDashboardUrl(state),
            dashboardList: selectDashboardListByClient(state, props),
            eulaAcceptPending: state.session.eula.pending,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                sessionLogout: logout,
                sessionAcceptEula: acceptEula,
            }, dispatch)
        })
)(EulaPageComponent));