import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/rules';
import * as API from '../api/rules';
import { sessionErrorHandling } from './session';

function* list(action) {
    try {
        const { contractServiceId } = action.payload;
        yield put({ type: actions.RULES_GET_PENDING });
        const payload = yield call(API.listRules, contractServiceId);
        yield put({ type: actions.RULES_GET_FULFILLED, payload: { rules: payload, serviceId: contractServiceId } });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.RULES_GET_PENDING });
        const { ruleId, callback } = action.payload;
        const payload = yield call(API.getRule, ruleId);
        yield put({ type: actions.RULES_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.RULES_CREATE_PENDING });
        const {
            rule, callback,
        } = action.payload;

        const newRule = yield call(API.createRule, rule);
        yield put({ type: actions.RULES_CREATE_FULFILLED, newRule });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.RULES_UPDATE_PENDING });
        const {
            rule,
            callback
        } = action.payload;

        const newRule = yield call(API.updateRule, rule);
        yield put({ type: actions.RULES_UPDATE_FULFILLED, newRule });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getMethodTypes(action) {
    try {
        yield put({ type: actions.RULES_GET_METHOD_TYPES_PENDING });
        const {
            callback
        } = action.payload;
        const payload = yield call(API.getMethodTypes);
        yield put({ type: actions.RULES_GET_METHOD_TYPES_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_METHOD_TYPES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getRuleTypes(action) {
    try {
        yield put({ type: actions.RULES_GET_TYPES_PENDING });
        const {
            callback
        } = action.payload;
        const payload = yield call(API.getRuletypes);
        yield put({ type: actions.RULES_GET_TYPES_FULFILLED, payload });

        if (callback) {
            callback(payload);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_TYPES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getGpcis(action) {
    try {
        yield put({ type: actions.RULES_GET_GPCIS_PENDING });
        const {
            callback
        } = action.payload;
        const payload = yield call(API.getGpcis);
        yield put({ type: actions.RULES_GET_GPCIS_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_GPCIS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayMethods(action) {
    try {
        yield put({ type: actions.RULES_GET_PAYMETHODS_PENDING });
        const {
            callback
        } = action.payload;
        const payload = yield call(API.getPayMethods);
        yield put({ type: actions.RULES_GET_PAYMETHODS_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_PAYMETHODS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}
function* getCarveoutCodeDescriptions(action) {
    try {
        yield put({ type: actions.RULES_GET_CARVEOUT_DESCRIPTION_PENDING });
        const { serviceId } = action.payload;
        const payload = yield call(API.getCarveoutCodeDescription, serviceId);
        yield put({ type: actions.RULES_GET_CARVEOUT_DESCRIPTION_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_CARVEOUT_DESCRIPTION_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPaySources(action) {
    try {
        yield put({ type: actions.RULES_GET_PAYSOURCES_PENDING });
        const payload = yield call(API.getPaySources);
        yield put({ type: actions.RULES_GET_PAYSOURCES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_PAYSOURCES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getMultiPrecedences(action) {
    try {
        yield put({ type: actions.RULES_GET_MULTIPROC_PRECEDENCES_PENDING });
        const payload = yield call(API.getMultiPrecedences);
        yield put({ type: actions.RULES_GET_MULTIPROC_PRECEDENCES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_MULTIPROC_PRECEDENCES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* disableRule(action) {
    try {
        yield put({ type: actions.RULES_DISABLE_PENDING });
        const { ruleId, callback } = action.payload;
        const payload = yield call(API.disableRule, ruleId);
        yield put({ type: actions.RULES_DISABLE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_DISABLE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* enableRule(action) {
    try {
        yield put({ type: actions.RULES_ENABLE_PENDING });
        const { ruleId, callback } = action.payload;
        const payload = yield call(API.enableRule, ruleId);
        yield put({ type: actions.RULES_ENABLE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_ENABLE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* changePrecedence(action) {
    try {
        yield put({ type: actions.RULES_CHANGE_PRECEDENCE_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.setPrecedence, options);
        yield put({ type: actions.RULES_CHANGE_PRECEDENCE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_CHANGE_PRECEDENCE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* validateCodes(action) {
    try {
        yield put({ type: actions.RULES_CODES_VALIDATE_PENDING });
        const {
            codes,
            callback
        } = action.payload;
        const payload = yield call(API.validateCodes, codes);
        yield put({ type: actions.RULES_CODES_VALIDATE_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_CODES_VALIDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error, true);
    }
}

function* getRuleCriteria(action) {
    try {
        yield put({ type: actions.RULES_GET_CRITERIA_PENDING });
        const payload = yield call(API.getRuleCriteria);
        yield put({ type: actions.RULES_GET_CRITERIA_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_CRITERIA_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getRulesByContractId(action) {
    try {
        const { contractId } = action.payload;
        yield put({ type: actions.RULES_GET_BY_CONTRACT_ID_PENDING });
        const payload = yield call(API.getRulesByContractId, contractId);
        yield put({ type: actions.RULES_GET_BY_CONTRACT_ID_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_GET_BY_CONTRACT_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* ruleClone(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.RULES_CLONE_PENDING });
        const payload = yield call(API.cloneRule, options);
        yield put({ type: actions.RULES_CLONE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RULES_CLONE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.RULES_RESET });
}

export default function* () {
    yield takeEvery(actions.RULES_LIST, list);
    yield takeEvery(actions.RULES_GET, get);
    yield takeEvery(actions.RULES_CREATE, create);
    yield takeEvery(actions.RULES_UPDATE, update);
    yield takeEvery(actions.RULES_GET_METHOD_TYPES, getMethodTypes);
    yield takeEvery(actions.RULES_GET_TYPES, getRuleTypes);
    yield takeEvery(actions.RULES_GET_GPCIS, getGpcis);
    yield takeEvery(actions.RULES_GET_PAYMETHODS, getPayMethods);
    yield takeEvery(actions.RULES_GET_CARVEOUT_DESCRIPTION, getCarveoutCodeDescriptions);
    yield takeEvery(actions.RULES_GET_PAYSOURCES, getPaySources);
    yield takeEvery(actions.RULES_GET_MULTIPROC_PRECEDENCES, getMultiPrecedences);
    yield takeEvery(actions.RULES_DISABLE, disableRule);
    yield takeEvery(actions.RULES_ENABLE, enableRule);
    yield takeEvery(actions.RULES_CHANGE_PRECEDENCE, changePrecedence);
    yield takeEvery(actions.RULES_CODES_VALIDATE, validateCodes);
    yield takeEvery(actions.RULES_GET_CRITERIA, getRuleCriteria);
    yield takeEvery(actions.RULES_GET_BY_CONTRACT_ID, getRulesByContractId);
    yield takeEvery(actions.RULES_CLONE, ruleClone);
}