import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import RolesEditPageComponent from './RolesEditPage.js';

import { createPermission, getPermission, getPermissionByRoleId, updatePermission } from '../../../../store/actions/roles';

import { createRoleSelector, selectRolePermissions, selectRolesBasedOnRole } from '../../../../store/selectors/roles';

export const RolesEditPage = connect(
    () => {
        const selectRoleId = (state, props) => props.match.params.roleId;

        const selectMode = (state, props) => props.match.params.roleId === 'new' ? 'create' : 'edit';

        const selectRole = createRoleSelector(selectRoleId);

        const selectInitialValues = createSelector(
            selectMode,
            selectRole,
            (mode, role) => {
                if (mode === 'edit' && role) {
                    return {
                        id: role.id,
                        name: role.name,
                        description: role.description || '',
                    }
                }

                return {
                    name: '',
                    description: '',
                }
            }
        )

        return (state, props) => ({

            // // Loaders
            rolesCreatePending: state.roles.create.pending,
            rolesUpdatePending: state.roles.update.pending,
            roleGetPending: state.roles.rolePermisssionById.pending,
            // clientsGetPending: state.roles.get.pending,
            dashboardListTinyPending: state.dashboards.tiny.pending,

            rolePermissions: selectRolePermissions(state),
            roles: selectRolesBasedOnRole(state),
            roleId: selectRoleId(state, props),
            // edit
            role: selectRole(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            roleCreate: createPermission,
            // roleMetadata: get,
            rolesPermission: getPermission,
            rolesPermissionByRoleId: getPermissionByRoleId,
            roleUpdate: updatePermission
            // dashboardListTiny: listTinyByClientId,
            // dashboardListTinyReset: tinyReset,
        }, dispatch)
    })
)(injectIntl(RolesEditPageComponent));