export const EAPG_CREATE = 'EAPG_CREATE';
export const EAPG_CREATE_PENDING = 'EAPG_CREATE_PENDING';
export const EAPG_CREATE_FULFILLED = 'EAPG_CREATE_FULFILLED';
export const EAPG_CREATE_REJECTED = 'EAPG_CREATE_REJECTED';

export const EAPG_UPDATE = 'EAPG_UPDATE';
export const EAPG_UPDATE_PENDING = 'EAPG_UPDATE_PENDING';
export const EAPG_UPDATE_FULFILLED = 'EAPG_UPDATE_FULFILLED';
export const EAPG_UPDATE_REJECTED = 'EAPG_UPDATE_REJECTED';

export const EAPG_DELETE = 'EAPG_DELETE';
export const EAPG_DELETE_PENDING = 'EAPG_DELETE_PENDING';
export const EAPG_DELETE_FULFILLED = 'EAPG_DELETE_FULFILLED';
export const EAPG_DELETE_REJECTED = 'EAPG_DELETE_REJECTED';

export const EAPG_GET = 'EAPG_GET';
export const EAPG_GET_PENDING = 'EAPG_GET_PENDING';
export const EAPG_GET_FULFILLED = 'EAPG_GET_FULFILLED';
export const EAPG_GET_REJECTED = 'EAPG_GET_REJECTED';

export const EAPG_GET_TINY = 'EAPG_GET_TINY';
export const EAPG_GET_TINY_PENDING = 'EAPG_GET_TINY_PENDING';
export const EAPG_GET_TINY_FULFILLED = 'EAPG_GET_TINY_FULFILLED';
export const EAPG_GET_TINY_REJECTED = 'EAPG_GET_TINY_REJECTED';

export const EAPG_CREATE_UPDATE_RESET = 'EAPG_CREATE_UPDATE_RESET';

export const EAPG_GET_TINY_BY_CONTRACT = 'EAPG_GET_TINY_BY_CONTRACT';
export const EAPG_GET_TINY_BY_CONTRACT_PENDING = 'EAPG_GET_TINY_BY_CONTRACT_PENDING';
export const EAPG_GET_TINY_BY_CONTRACT_FULFILLED = 'EAPG_GET_TINY_BY_CONTRACT_FULFILLED';
export const EAPG_GET_TINY_BY_CONTRACT_REJECTED = 'EAPG_GET_TINY_BY_CONTRACT_REJECTED';

export const EAPG_GET_TINY_BY_TARGET_CLIENT = 'EAPG_GET_TINY_BY_TARGET_CLIENT';
export const EAPG_GET_TINY_BY_TARGET_CLIENT_PENDING = 'EAPG_GET_TINY_BY_TARGET_CLIENT_PENDING';
export const EAPG_GET_TINY_BY_TARGET_CLIENT_FULFILLED = 'EAPG_GET_TINY_BY_TARGET_CLIENT_FULFILLED';
export const EAPG_GET_TINY_BY_TARGET_CLIENT_REJECTED = 'EAPG_GET_TINY_BY_TARGET_CLIENT_REJECTED';

export const EAPG_DELETE_DEPENDENCIES = 'EAPG_DELETE_DEPENDENCIES';
export const EAPG_DELETE_DEPENDENCIES_PENDING = 'EAPG_DELETE_DEPENDENCIES_PENDING';
export const EAPG_DELETE_DEPENDENCIES_FULFILLED = 'EAPG_DELETE_DEPENDENCIES_FULFILLED';
export const EAPG_DELETE_DEPENDENCIES_REJECTED = 'EAPG_DELETE_DEPENDENCIES_REJECTED';

export const EAPG_VERSIONS = 'EAPG_VERSIONS';
export const EAPG_VERSIONS_PENDING = 'EAPG_VERSIONS_PENDING';
export const EAPG_VERSIONS_FULFILLED = 'EAPG_VERSIONS_FULFILLED';
export const EAPG_VERSIONS_REJECTED = 'EAPG_VERSIONS_REJECTED';

export const EAPG_SET_DEPENDENCY_DIALOG = 'EAPG_SET_DEPENDENCY_DIALOG';
export const EAPG_RESET_DEPENDENCIES = 'EAPG_RESET_DEPENDENCIES';

export const createEAPG = (values, callback, shouldReloadGrid) => ({
    type: EAPG_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const editEAPG = (values, callback, shouldReloadGrid) => ({
    type: EAPG_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const deleteEAPG = (eapgId, callback) => ({
    type: EAPG_DELETE,
    payload: { eapgId, callback }
});

export const createEditReset = () => ({
    type: EAPG_CREATE_UPDATE_RESET
});

export const getEAPG = (eapgId) => ({
    type: EAPG_GET,
    payload: { eapgId }
});

export const getEAPGTiny = () => ({
    type: EAPG_GET_TINY
});

export const getEAPGTinyByContract = (contractId) => ({
    type: EAPG_GET_TINY_BY_CONTRACT,
    payload: { contractId }
});

export const getEAPGTinyByTargetClient = (targetClientId) => ({
    type: EAPG_GET_TINY_BY_TARGET_CLIENT,
    payload: { targetClientId }
});

export const getEAPGDeleteDependencies = (id) => ({
    type: EAPG_DELETE_DEPENDENCIES,
    payload: { id }
});

export const setDependencyDialog = (dependencyDialog) => ({
    type: EAPG_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});

export const resetDependencies = () => ({
    type: EAPG_RESET_DEPENDENCIES,
});

export const getEAPGVersions = () => ({
    type: EAPG_VERSIONS,
});