import {
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    METHOD_DELETE,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

const getFacilities = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/facilities`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getFacility = (facilityId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/facility/${facilityId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createFacility = (facility) => {
    return fetch(`${SERVER_URL}/api/v2/cms/facility`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(facility)
    }).then(apiHandleResponse);
};

const updateFacility = (facility) => {
    return fetch(`${SERVER_URL}/api/v2/cms/facility`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(facility)
    }).then(apiHandleResponse);
};

const deleteFacility = (facilityId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/facility/${facilityId}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
}

const getFacilitiesFromTargetClient = (targetClient) => {
    return fetch(`${SERVER_URL}/api/v2/cms/facilities/list/${targetClient}`, {
        method: METHOD_GET,
        headers: headersAuth(),
    }).then(apiHandleResponse);
}

const getFacilityDependencies = (facilityId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/facility/dependencies/${facilityId}`, {
        method: METHOD_GET,
        headers: headersAuth(),
    }).then(apiHandleResponse);
}

const activateFacility = (facilityId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/facility/activate/${facilityId} `, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const deactivateFacility = (facilityId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/facility/deactivate/${facilityId} `, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getFacilities,
    createFacility,
    updateFacility,
    getFacility,
    deleteFacility,
    getFacilitiesFromTargetClient,
    getFacilityDependencies,
    activateFacility,
    deactivateFacility
};