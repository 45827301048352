import { createReactRouterV5Options, Faro, FaroRoute, getWebInstrumentations, initializeFaro, ReactIntegration } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { createBrowserHistory } from 'history';
import { Route as StandardRoute } from 'react-router-dom';
import pkg from '../../../package.json';

export const history = createBrowserHistory();

let faro : Faro = null,
  Route = StandardRoute; // Declare `faro` variable outside the conditional to prevent errors elsewhere in your code

if (window.location.hostname !== 'localhost') {  // Only initialize Faro if the hostname is not localhost
  const faro = initializeFaro({
    url: process.env.REACT_APP_FARO_URL,
    app: {
      name: 'slicedhealth-ui',
      version : pkg.version,
      environment: process.env.REACT_APP_ENVIRONMENT,
    },

    instrumentations: [
      // Load the default Web instrumentations
      ...getWebInstrumentations(),

        // Tracing package to get end-to-end visibility for HTTP requests.
      new TracingInstrumentation(),

      new ReactIntegration({
        // or createReactRouterV4Options
        router: createReactRouterV5Options({
          history, // the history object used by react-router
          Route, // Route component imported from react-router package
        }),
      }),
    ],
  });

  Route = FaroRoute as any; // Use the FaroRoute component instead of the standard Route component
}

export { faro, Route };
