import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { create, update, get } from '../../../store/actions/facilities';
import FacilityDialogComponent from './FacilityDialog';

export const FacilityDialog = connect(
    () => {
        const selectFacility = (state, props) => props.facility;
        const selectMode = (state, props) => {
            return props.facility ? 'edit' : 'create';
        }

        const selectInitialValues = createSelector(
            selectMode,
            selectFacility,
            (mode, facility) => {
                if (mode === 'edit' && facility) {
                    const payload = {
                        id: facility.id,
                        name: facility.name,
                        description: facility.description,
                        ein: facility.ein,
                        active: facility.active,
                        npis: facility.billingProviderNPIs.toString(),
                        billingProviderNPIs: facility.billingProviderNPIs,
                        address: facility.address,
                        phone: facility.phone || '',
                        enableForPricer: facility.enableForPricer ?? false,
                        facilityNPI: facility.facilityNPI,


                    }
                    return payload;
                }

                return {
                    name: '',
                    description: '',
                    ein: '',
                    address:'',
                    phone: '',
                    active: true,
                    npis: '',
                    billingProviderNPIs: [],
                    enableForPricer: false,
                    facilityNPI:''
                }
            }
        )

        return (state, props) => ({
            //create facility
            facilityCreatePending: state.facilities.create.pending,

            // update facility
            facilityUpdatePending: state.facilities.update.pending,

            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            facilityCreate: create,
            facilityUpdate: update,
            facilityGet: get,
        }, dispatch)
    })
)(injectIntl(FacilityDialogComponent));