import { createReducer } from '../../util';
import * as actions from '../actions/associated';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    associatedList: [],
    enableDialog: null,//{open:true,isEnableClicked:true}
    list: {
        ...initialStatusState,
    },
    updateStatus: {
        ...initialStatusState,
    },
    all: {
        ...initialStatusState,
    },
    refreshCharges: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {
    [actions.ASSOCIATED_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.ASSOCIATED_FULFILLED]: (state, associatedList) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        associatedList,
    }),
    [actions.ASSOCIATED_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.ASSOCIATED_UPDATE_STATUS_PENDING]: (state) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            pending: true,
        },
    }),
    [actions.ASSOCIATED_UPDATE_STATUS_FULFILLED]: (state, payload) => ({
        ...state,
        associatedList: state.associatedList.map(
            (associated, i) => associated.id === payload.id ? { ...associated, enabled: payload.enabled } : associated //update associated status on the base of ID
        ),
        updateStatus: {
            ...initialStatusState,
        },
    }),
    [actions.ASSOCIATED_UPDATE_STATUS_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.ASSOCIATED_SELECT_ALL_PENDING]: (state) => ({
        ...state,
        all: {
            pending: true,
        },
    }),
    [actions.ASSOCIATED_SELECT_ALL_FULFILLED]: (state) => ({
        ...state,
        all: {
            ...initialStatusState,
        }
    }),
    [actions.ASSOCIATED_SELECT_ALL_REJECTED]: (state, errorMessage) => ({
        ...state,
        all: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.ASSOCIATED_REFRESH_PENDING]: (state) => ({
        ...state,
        refreshCharges: {
            pending: true,
        },
    }),
    [actions.ASSOCIATED_REFRESH_FULFILLED]: (state) => ({
        ...state,
        refreshCharges: {
            ...initialStatusState,
        }
    }),
    [actions.ASSOCIATED_REFRESH_REJECTED]: (state, errorMessage) => ({
        ...state,
        refreshCharges: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.ASSOCIATED_SET_ENABLE_DIALOG]: (state, { enableDialog }) => ({
        ...state,
        enableDialog
    }),

    [actions.ASSOCIATED_RESET]: () => ({ ...initialState }),
});