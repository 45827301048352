export const FACILITIES_LIST = 'FACILITIES_LIST';
export const FACILITIES_LIST_PENDING = 'FACILITIES_LIST_PENDING';
export const FACILITIES_LIST_FULFILLED = 'FACILITIES_LIST_FULFILLED';
export const FACILITIES_LIST_REJECTED = 'FACILITIES_LIST_REJECTED';

export const FACILITIES_GET = 'FACILITIES_GET';
export const FACILITIES_GET_PENDING = 'FACILITIES_GET_PENDING';
export const FACILITIES_GET_FULFILLED = 'FACILITIES_GET_FULFILLED';
export const FACILITIES_GET_REJECTED = 'FACILITIES_GET_REJECTED';

export const FACILITIES_CREATE = 'FACILITIES_CREATE';
export const FACILITIES_CREATE_PENDING = 'FACILITIES_CREATE_PENDING';
export const FACILITIES_CREATE_FULFILLED = 'FACILITIES_CREATE_FULFILLED';
export const FACILITIES_CREATE_REJECTED = 'FACILITIES_CREATE_REJECTED';
export const FACILITIES_EDIT_RESET = 'FACILITIES_EDIT_RESET';

export const FACILITIES_UPDATE = 'FACILITIES_UPDATE';
export const FACILITIES_UPDATE_PENDING = 'FACILITIES_UPDATE_PENDING';
export const FACILITIES_UPDATE_FULFILLED = 'FACILITIES_UPDATE_FULFILLED';
export const FACILITIES_UPDATE_REJECTED = 'FACILITIES_UPDATE_REJECTED';

export const FACILITIES_DELETE = 'FACILITIES_DELETE';
export const FACILITIES_DELETE_PENDING = 'FACILITIES_DELETE_PENDING';
export const FACILITIES_DELETE_FULFILLED = 'FACILITIES_DELETE_FULFILLED';
export const FACILITIES_DELETE_REJECTED = 'FACILITIES_DELETE_REJECTED';

export const FACILITIES_BY_TARGET_CLIENT = 'FACILITIES_BY_TARGET_CLIENT';
export const FACILITIES_BY_TARGET_CLIENT_PENDING = 'FACILITIES_BY_TARGET_CLIENT_PENDING';
export const FACILITIES_BY_TARGET_CLIENT_FULFILLED = 'FACILITIES_BY_TARGET_CLIENT_FULFILLED';
export const FACILITIES_BY_TARGET_CLIENT_REJECTED = 'FACILITIES_BY_TARGET_CLIENT_REJECTED';

export const FACILITIES_GET_DEPENDENCIES = 'FACILITIES_GET_DEPENDENCIES';
export const FACILITIES_GET_DEPENDENCIES_PENDING = 'FACILITIES_GET_DEPENDENCIES_PENDING';
export const FACILITIES_GET_DEPENDENCIES_FULFILLED = 'FACILITIES_GET_DEPENDENCIES_FULFILLED';
export const FACILITIES_GET_DEPENDENCIES_REJECTED = 'FACILITIES_GET_DEPENDENCIES_REJECTED';

export const FACILITIES_ACTIVATE = 'FACILITIES_ACTIVATE';
export const FACILITIES_ACTIVATE_PENDING = 'FACILITIES_ACTIVATE_PENDING';
export const FACILITIES_ACTIVATE_FULFILLED = 'FACILITIES_ACTIVATE_FULFILLED';
export const FACILITIES_ACTIVATE_REJECTED = 'FACILITIES_ACTIVATE_REJECTED';

export const FACILITIES_DEACTIVATE = 'FACILITIES_DEACTIVATE';
export const FACILITIES_DEACTIVATE_PENDING = 'FACILITIES_DEACTIVATE_PENDING';
export const FACILITIES_DEACTIVATE_FULFILLED = 'FACILITIES_DEACTIVATE_FULFILLED';
export const FACILITIES_DEACTIVATE_REJECTED = 'FACILITIES_DEACTIVATE_REJECTED';

export const FACILITIES_SET_DEPENDENCY_DIALOG = 'FACILITIES_SET_DEPENDENCY_DIALOG';
export const FACILITIES_ERROR_RESET = 'FACILITIES_ERROR_RESET';
export const FACILITIES_RESET = 'FACILITIES_RESET';
export const FACILITIES_RESET_DEPENDENCY = 'FACILITIES_RESET_DEPENDENCY';

export const list = () => ({
    type: FACILITIES_LIST,
});

export const get = (facilityId, callback) => ({
    type: FACILITIES_GET,
    payload: { facilityId, callback },
});

export const create = (facility, callback, shouldReloadGrid) => ({
    type: FACILITIES_CREATE,
    payload: { facility, callback, shouldReloadGrid },
});

export const update = (facility, callback, shouldReloadGrid) => ({
    type: FACILITIES_UPDATE,
    payload: { facility, callback, shouldReloadGrid },
});

export const deleteFacility = (id, callback) => ({
    type: FACILITIES_DELETE,
    payload: { id, callback },
})

export const errorReset = (...errorKeys) => ({
    type: FACILITIES_ERROR_RESET,
    payload: { errorKeys },
});

export const createFacilityReset = () => ({
    type: FACILITIES_EDIT_RESET
});

export const reset = () => ({
    type: FACILITIES_RESET
});

export const getFacilitiesByTargetClient = (targetClient) => ({
    type: FACILITIES_BY_TARGET_CLIENT,
    payload: { targetClient }
});

export const getFacilityDependencies = (facilityId) => ({
    type: FACILITIES_GET_DEPENDENCIES,
    payload: { facilityId }
});

export const setFacilityDependencyDialog = (dependencyDialog) => ({
    type: FACILITIES_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});

export const resetFacilityDependency = () => ({
    type: FACILITIES_RESET_DEPENDENCY,
});

export const activateFacility = (facilityId, callback) => ({
    type: FACILITIES_ACTIVATE,
    payload: { facilityId, callback }
});

export const deactivateFacility = (facilityId, callback) => ({
    type: FACILITIES_DEACTIVATE,
    payload: { facilityId, callback }
});