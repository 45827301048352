import { createReducer } from '../../util';
import * as actions from '../actions/apikey';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    list: [],
    tinyList:[],
    actualKey: null,// { id:'',key:'' }
    getList: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    activate: {
        ...initialStatusState,
    },
    deactivate: {
        ...initialStatusState
    },
    getValueById: {
        ...initialStatusState
    },
    getTinyList:{
        ...initialStatusState
    }
    
};

export default createReducer(initialState, {
    [actions.API_KEYS_GET_PENDING]: (state) => ({
        ...state,
        getList: {
            pending: true,
        },
    }),
    [actions.API_KEYS_GET_FULFILLED]: (state, list) => ({
        ...state,
        getList: {
            ...initialStatusState,
        },
        list,
    }),
    [actions.API_KEYS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.API_KEY_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.API_KEY_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
    }),
    [actions.API_KEY_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.API_KEY_GET_VALUE_PENDING]: (state) => ({
        ...state,
        getValueById: {
            pending: true,
        },
    }),
    [actions.API_KEY_GET_VALUE_FULFILLED]: (state, actualKey) => ({
        ...state,
        getValueById: {
            ...initialStatusState,
        },
        actualKey
    }),
    [actions.API_KEY_GET_VALUE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getValueById: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.API_KEY_DEACTIVATE_PENDING]: (state) => ({
        ...state,
        deactivate: {
            pending: true,
        },
    }),
    [actions.API_KEY_DEACTIVATE_FULFILLED]: (state) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
        },
    }),
    [actions.API_KEY_DEACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.API_KEY_ACTIVATE_PENDING]: (state) => ({
        ...state,
        activate: {
            pending: true,
        },
    }),
    [actions.API_KEY_ACTIVATE_FULFILLED]: (state) => ({
        ...state,
        activate: {
            ...initialStatusState,
        },
    }),
    [actions.API_KEY_ACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        activate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.API_KEY_RESET_ACTUAL_KEY]: (state) => ({
        ...state,
        actualKey: null
    }),

    [actions.API_KEY_RESET_LIST]: (state) => ({
        ...state,
        list: null
    }),


    [actions.API_KEY_GET_TINY_LIST_PENDING]: (state) => ({
        ...state,
        getTinyList: {
            pending: true,
        },
        tinyList: []
    }),
    [actions.API_KEY_GET_TINY_LIST_FULFILLED]: (state, tinyList) => ({
        ...state,
        getTinyList: {
            ...initialStatusState,
        },
        tinyList,
    }),
    [actions.API_KEY_GET_TINY_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        getTinyList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});