import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import {
    Portlet, PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";

import { makeStyles, Grid, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { PagedTable } from '../../common';
import { ROLE_SUPERADMIN } from '../../store/constants/roles';
import { EXECUTION_STATE_COMPLETED, EXECUTION_STATE_FAILED, EXECUTION_STATE_INPROGRESS } from '../../store/constants/execution';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useLocation } from 'react-router-dom';

const DEFAULT_SORT = [{ id: 'id', direction: 'desc' }];

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function ExecutionPage(props) {
    const classes = useStyles();
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [isStopConfirmOpen, setStopConfirm] = useState(false);
    const [stopExecutionId, setStopExecutionId] = useState(null);

    const search = useLocation().search;
    const executionId = new URLSearchParams(search).get('executionId');

    const {
        fields,
        executionsData,
        userRole,
        stopPending,
        downloadReportPending,
        hasExecutionLogsAccess,
        actions: {
            executionsLanding,
            searchGetByName,
            searchReset,
            setFilter,
            resetCriteriaWaiting,
            stopExecution,
            resetErrorMessages,
            downloadReportById,
            resetPagedTable,
        }
    } = props;

    function handleLoad(sort, filter, pagination) {
        executionsLanding(pagination, sort, filter);
    }

    useEffect(() => {
        if (executionId && executionId !== 'list') {
            const idFilter = {
                field: 'id',
                equator: 'equals',
                value: executionId,
            }
            setFilter([idFilter]);
        }
    }, [executionId]);

    useEffect(() => {
        handleRefresh();
    }, [setFilter]);

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    useEffect(() => {
        const timer = setInterval(() => {
            handleRefresh();
        }, 30000);
        return () => {
            resetErrorMessages();
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        const pageName = {
            name: 'executions-landing'
        }
        resetCriteriaWaiting(pageName.name)


        return function cleanup() {
            // searchReset();
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    function handleStopExecution() {
        handleStopConfirmClose();
        stopExecution(stopExecutionId, stopExecutionCallback);
    }

    function stopExecutionCallback() {
        handleRefresh();
        setStopExecutionId(null);
    }

    function handleDownloadReport(id) {
        downloadReportById(id, downloadTemplateCallback)
    }

    function downloadTemplateCallback(fileUrl) {
        window.open(fileUrl)
    }

    function handleStopConfirmOpen(_id) {
        setStopExecutionId(_id);
        setStopConfirm(true);
    }
    function handleStopConfirmClose() {
        setStopConfirm(false);
    }

    function executionActions(rowData) {
        return (
            <>
                {userRole === ROLE_SUPERADMIN && rowData.state === EXECUTION_STATE_INPROGRESS &&
                    <Grid item>
                        <Tooltip title="Stop Execution">
                            <IconButton aria-label="edit" size='small' onClick={() => handleStopConfirmOpen(rowData?.id)}>
                                <i className="fas fa-stop-circle"></i>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
                {(rowData.state === EXECUTION_STATE_COMPLETED || rowData.state === EXECUTION_STATE_FAILED) &&
                    <Grid item>
                        <Tooltip title="Download Report">
                            <IconButton aria-label="edit" size='small' onClick={() => handleDownloadReport(rowData?.id)}>
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </>
        );
    }

    return (
        <>
            <Helmet title="Analysis Executions" />
            <Portlet>
                <PortletHeader
                    title="Analysis Executions"
                    name="executions-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    shouldNotSetDefault={executionId === null ? false : true}
                />
                <PortletBody>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="executions-landing"
                                    title="Analysis Executions"
                                    fields={fields}
                                    data={executionsData}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    criteriasSaveEnabled={true}
                                    hasActions={hasExecutionLogsAccess}
                                    loading={stopPending || downloadReportPending}
                                    renderActionColumn={executionActions}
                                    showSearchFilters={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
            <Dialog open={isStopConfirmOpen} onClose={handleStopConfirmClose}>
                <DialogTitle>
                    Stop Execution
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to stop this execution?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleStopConfirmClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleStopExecution}
                        color="primary">
                        Stop
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}