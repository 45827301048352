import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ActiveFacilitiesDialogComponent from './ActiveFacilitiesDialog';


export const ActiveFacilitiesDialog = connect(

    () => {
        const selectInitialValues = createSelector(
            () => {
                return {
                    facility: null,
                }
            }
        )
        return (state, props) => ({
            initialValues: selectInitialValues(state),


        });
    },
    (dispatch) => ({
        actions: bindActionCreators({

        }, dispatch)
    })
)(injectIntl(ActiveFacilitiesDialogComponent));