import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import {createSelector} from 'reselect';

import DashboardDataSourcesPageComponent from './DashboardDataSourcesPage';
import { list, deleteDashboardDataSource } from '../../../store/actions/dashboardDataSources';
import {selectDashboardDataSources} from '../../../store/selectors/dashboardDataSources';
import { set } from '../../../store/actions/pages';
import {createFilteredSortedDataSelector} from '../../../util/selector';
import {createStringDataField} from '../../../util/format';

const DEFAULT_SORT = [{id: 'name', orderBy: 'asc'}];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Name', {sortable: true}),
        createStringDataField('viewName', 'View Name', {sortable: true}),
        createStringDataField('description', 'Description'),
    ]
);

const selectFilteredFields = createSelector(    
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['dashboard-dataSources-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['dashboard-dataSources-landing'].filter,
    selectSort,
    selectDashboardDataSources,
);

export const DashboardDataSourcesPage = injectIntl(
    connect(
        (state, props) => ({
            fields: selectFields(state),
            dashboardsFilteredData: selectFilteredData(state),
            sort: selectSort(state),
            dashboardDataSourceListPending: state.dashboardDataSources.list.pending,
            dashboardDataSourceDeletePending: state.dashboardDataSources.delete.pending,
            order: state.pages['dashboard-dataSources-landing'].order,
            filter: state.pages['dashboard-dataSources-landing'].filter,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                dashboardDataSourceList: list,
                dashboardDataSourceDelete: deleteDashboardDataSource,
                setFilter: (value) => set('dashboard-dataSources-landing', 'filter', value),
                setOrder: (value) => set('dashboard-dataSources-landing', 'order', value),
                setSort: (value) => set('dashboard-dataSources-landing', 'sort', value),
            }, dispatch)
        })
)(DashboardDataSourcesPageComponent));