export const MODEL_SEARCH_CLAIMS = 'MODEL_SEARCH_CLAIMS';
export const MODEL_SEARCH_CLAIMS_PENDING = 'MODEL_SEARCH_CLAIMS_PENDING';
export const MODEL_SEARCH_CLAIMS_FULFILLED = 'MODEL_SEARCH_CLAIMS_FULFILLED';
export const MODEL_SEARCH_CLAIMS_REJECTED = 'MODEL_SEARCH_CLAIMS_REJECTED';

export const MODEL_ESTIMATE = 'MODEL_ESTIMATE';
export const MODEL_ESTIMATE_PENDING = 'MODEL_ESTIMATE_PENDING';
export const MODEL_ESTIMATE_FULFILLED = 'MODEL_ESTIMATE_FULFILLED';
export const MODEL_ESTIMATE_REJECTED = 'MODEL_ESTIMATE_REJECTED';

export const MODEL_ANALYZE_ESTIMATE = 'MODEL_ANALYZE_ESTIMATE';
export const MODEL_ANALYZE_ESTIMATE_PENDING = 'MODEL_ANALYZE_ESTIMATE_PENDING';
export const MODEL_ANALYZE_ESTIMATE_FULFILLED = 'MODEL_ANALYZE_ESTIMATE_FULFILLED';
export const MODEL_ANALYZE_ESTIMATE_REJECTED = 'MODEL_ANALYZE_ESTIMATE_REJECTED';

export const MODEL_LOCK = 'MODEL_LOCK';
export const MODEL_LOCK_PENDING = 'MODEL_LOCK_PENDING';
export const MODEL_LOCK_FULFILLED = 'MODEL_LOCK_FULFILLED';
export const MODEL_LOCK_REJECTED = 'MODEL_LOCK_REJECTED';

export const MODEL_UNLOCK = 'MODEL_UNLOCK';
export const MODEL_UNLOCK_PENDING = 'MODEL_UNLOCK_PENDING';
export const MODEL_UNLOCK_FULFILLED = 'MODEL_UNLOCK_FULFILLED';
export const MODEL_UNLOCK_REJECTED = 'MODEL_UNLOCK_REJECTED';

export const MODEL_ANALYSIS_LANDING = 'MODEL_ANALYSIS_LANDING';
export const MODEL_ANALYSIS_LANDING_PENDING = 'MODEL_ANALYSIS_LANDING_PENDING';
export const MODEL_ANALYSIS_LANDING_FULFILLED = 'MODEL_ANALYSIS_LANDING_FULFILLED';
export const MODEL_ANALYSIS_LANDING_REJECTED = 'MODEL_ANALYSIS_LANDING_REJECTED';

export const MODEL_GET_BY_ID = 'MODEL_GET_BY_ID';
export const MODEL_GET_BY_ID_PENDING = 'MODEL_GET_BY_ID_PENDING';
export const MODEL_GET_BY_ID_FULFILLED = 'MODEL_GET_BY_ID_FULFILLED';
export const MODEL_GET_BY_ID_REJECTED = 'MODEL_GET_BY_ID_REJECTED';

export const MODEL_EXECUTION_RESULTS_STATISTICS = "MODEL_EXECUTION_RESULTS_STATISTICS";
export const MODEL_EXECUTION_RESULTS_STATISTICS_PENDING = "MODEL_EXECUTION_RESULTS_STATISTICS_PENDING";
export const MODEL_EXECUTION_RESULTS_STATISTICS_FULFILLED = "MODEL_EXECUTION_RESULTS_STATISTICS_FULFILLED";
export const MODEL_EXECUTION_RESULTS_STATISTICS_REJECTED = "MODEL_EXECUTION_RESULTS_STATISTICS_REJECTED";

export const MODEL_SET_ANALYSIS_DIALOG = 'MODEL_SET_ANALYSIS_DIALOG';
export const CONTRACT_MODEL_SET_EDIT_DIALOG = 'CONTRACT_MODEL_SET_EDIT_DIALOG';

export const STOP_EXECUTION_MODELING = 'STOP_EXECUTION_MODELING';
export const STOP_EXECUTION_MODELING_PENDING = 'STOP_EXECUTION_MODELING_PENDING';
export const STOP_EXECUTION_MODELING_FULFILLED = 'STOP_EXECUTION_MODELING_FULFILLED';
export const STOP_EXECUTION_MODELING_REJECTED = 'STOP_EXECUTION_MODELING_REJECTED';

export const STOP_EXECUTION_MODELING_DIALOG = 'STOP_EXECUTION_MODELING_DIALOG';

export const MODEL_GET_MODEL_ALL_DETAILS = 'MODEL_GET_MODEL_ALL_DETAILS';
export const MODEL_GET_MODEL_ALL_DETAILS_PENDING = 'MODEL_GET_MODEL_ALL_DETAILS_PENDING';
export const MODEL_GET_MODEL_ALL_DETAILS_FULFILLED = 'MODEL_GET_MODEL_ALL_DETAILS_FULFILLED';
export const MODEL_GET_MODEL_ALL_DETAILS_REJECTED = 'MODEL_GET_MODEL_ALL_DETAILS_REJECTED';

export const MODEL_GET_CONSOLIDATED_CLAIMS = 'MODEL_GET_CONSOLIDATED_CLAIMS';
export const MODEL_GET_CONSOLIDATED_CLAIMS_PENDING = 'MODEL_GET_CONSOLIDATED_CLAIMS_PENDING';
export const MODEL_GET_CONSOLIDATED_CLAIMS_FULFILLED = 'MODEL_GET_CONSOLIDATED_CLAIMS_FULFILLED';
export const MODEL_GET_CONSOLIDATED_CLAIMS_REJECTED = 'MODEL_GET_CONSOLIDATED_CLAIMS_REJECTED';

export const MODEL_RESET_CONSOLIDATED_CLAIMS = 'MODEL_RESET_CONSOLIDATED_CLAIMS';

export const searchClaimsForModeling = (options, callback) => {
    return {
        type: MODEL_SEARCH_CLAIMS,
        payload: { options, callback }
    }
};

export const modelEstimate = (options, callback) => {
    return {
        type: MODEL_ESTIMATE,
        payload: { options, callback }
    }
};

export const analyzeModelEstimate = (options, callback) => {
    return {
        type: MODEL_ANALYZE_ESTIMATE,
        payload: { options, callback }
    }
};

export const lockModel = (modelId, callback) => {
    return {
        type: MODEL_LOCK,
        payload: { modelId, callback }
    }
};

export const unlockModel = (modelId, callback) => {
    return {
        type: MODEL_UNLOCK,
        payload: { modelId, callback }
    }
};

export const modelAnalysisLanding = (options) => {
    return {
        type: MODEL_ANALYSIS_LANDING,
        payload: { options }
    }
};

export const setAnalysisDialog = (anaylsisResultsDialog) => {
    return {
        type: MODEL_SET_ANALYSIS_DIALOG,
        payload: { anaylsisResultsDialog }
    }
};

export const getModelById = (modelId) => {
    return {
        type: MODEL_GET_BY_ID,
        payload: { modelId }
    }
};

export const setContractModelEditDialog = (contractModelEditDialog) => ({
    type: CONTRACT_MODEL_SET_EDIT_DIALOG,
    payload: { contractModelEditDialog }
});

export const modelExecutionResultsStats = (pagination, sort, filter, modelId) => {
    return {
        type: MODEL_EXECUTION_RESULTS_STATISTICS,
        payload: { pagination, sort, filter, modelId },
    }
};


export const stopExecution = (executionId, callback) => {
    return {
        type: STOP_EXECUTION_MODELING,
        payload: { executionId, callback }
    }
};
export const setStopExecutionDialog = (stopExecutionDialog) => ({
    type: STOP_EXECUTION_MODELING_DIALOG,
    payload: { stopExecutionDialog }
});

export const getModelAllDetails = (analysisIds) => ({
    type: MODEL_GET_MODEL_ALL_DETAILS,
    payload: { analysisIds }
});

export const getModelConsolidatedClaims = (analysisIds) => ({
    type: MODEL_GET_CONSOLIDATED_CLAIMS,
    payload: { analysisIds }
});

export const resetModelConsolidatedClaimsView = () => ({
    type: MODEL_RESET_CONSOLIDATED_CLAIMS
});