import {createSelector} from 'reselect';

export const selectFacilityList = (state) => state.facilities.data;

export const selectFacilities = createSelector(
    selectFacilityList,
    (facilities) => facilities,
);

export const createFacilitySelector = (selectFacilityId) => createSelector(
    selectFacilityId,
    selectFacilities,
    (facilityId, facilities) => {
        return facilities.find((facility) => facility.id === parseInt(facilityId));
    }
);