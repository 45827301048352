import React, { useEffect, useState } from "react";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import {  makeStyles, TablePagination } from "@material-ui/core";
import { DataTable, DataFilter, TablePaginationActions } from '../../../common';
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
    textField: {
        width: 400,
    },
}));

export default function APIKeysPage(props) {
    const classes = useStyles();

    const {
        apiKeysData, fields, sort, getAppIdsPending, getAppIdValuePending,
        order, filter, actions: { setSort, setOrder, setFilter, getAppIds, resetAppIdsList }
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getAppIds();
        return () => {
            resetAppIdsList();
        }
    }, [getAppIds, resetAppIdsList]);

    const handleCallBack = () => {
        handleRefresh();
    }

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleAddKey = () => {
        props.history.push('/admin/app-id-management/edit/new');
    }


    function handleRefresh() {
        resetAppIdsList();
        getAppIds();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Helmet title="Application Management" />
            <Portlet>
                <PortletHeader
                    title="Applications"
                    toolbar={
                        <PortletHeaderToolbar>
                            <DataFilter
                                onChange={handleSearchInput}
                                placeholder="Search"
                                cssClass={classes.textField}
                                filter={filter}
                            />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <DataTable
                        fields={fields}
                        data={apiKeysData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        onAdd={handleAddKey}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        loading={getAppIdsPending || getAppIdValuePending }
                        name="API Keys"
                        handleRefresh={handleRefresh}
                        showPagination={true}
                        footerPagination={() => (
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                colSpan={fields.length + 2}
                                count={apiKeysData?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        )}

                        hasActions={false}
                        // renderActionColumn={usersActions}
                    />
                </PortletBody>
            </Portlet>
        </>
    );
}

