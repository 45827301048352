import { createReducer } from '../../util';
import * as actions from '../actions/dashboardTypes';
import { StatusState } from './common';

const addObjectIfNotExist = (state, dashboardType) => {
    const data = state.data;
    const { id } = dashboardType;

    const dashboardTypeExist = data.find(x => x.id === id);
    if (!dashboardTypeExist) {
        return {
            data: [...state.data, dashboardType]
        }
    }
};

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    list: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
};

export default createReducer(initialState, {

    [actions.DASHBOARD_TYPES_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_TYPES_LIST_FULFILLED]: (state, dashboardTypes) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: dashboardTypes,
    }),
    [actions.DASHBOARD_TYPES_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.DASHBOARD_TYPES_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_TYPES_GET_FULFILLED]: (state, dashboardType) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, dashboardType),
    }),
    [actions.DASHBOARD_TYPES_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_TYPES_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_TYPES_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.DASHBOARD_TYPES_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_TYPES_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_TYPES_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.DASHBOARD_TYPES_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_TYPES_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_TYPES_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.DASHBOARD_TYPES_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_TYPES_RESET]: () => ({ ...initialState }),
    [actions.DASHBOARD_TYPES_ERROR_RESET]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        }
    }),
});