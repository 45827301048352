import { Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { PagedTable } from '../../common';
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet';
import { UploadDialog } from './uploadDialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DownloadTemplate } from './downloadTemplateDialog';
import GetAppIcon from '@material-ui/icons/GetApp';

const DEFAULT_SORT = [{ id: 'uploadedAt', direction: 'asc' }];

function Uploads(props) {
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [isUploadOpen, setUploadOpen] = useState(false);
    const [isDownloadDialogOpen, setDownloadDialog] = useState(false);

    const {
        fields, uploadsData,
        actions: {
            uploadsLanding,
        }
    } = props;

    function handleLoad(sort, filter, pagination) {
        uploadsLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handleUploadDialogOpen() {
        setUploadOpen(true);
    }
    function handleUploadDialogClose() {
        setUploadOpen(false);
    }

    function uploadFileCallback() {
        handleUploadDialogClose();
        handleRefresh();
    }

    function tableLevelActions() {
        return (
            <>
                <Grid item>
                    <Tooltip title="Upload Files">
                        <IconButton aria-label="enable" size='small' onClick={handleUploadDialogOpen}>
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Download Template">
                        <IconButton aria-label="download" size='small' onClick={handleDownloadDialogOpen}
                        >
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    function handleDownloadDialogOpen() {
        setDownloadDialog(true);
    }
    function handleDownloadDialogClose() {
        setDownloadDialog(false);
    }

    return (
        <>
            <Helmet title="Uploads" />
            <Portlet>
                <PortletHeader
                    title="Uploads"
                    name="uploads-landing"
                    handleRefresh={handleRefresh}
                // showSearchFilters={true}

                />
                <PortletBody>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="uploads-landing"
                                    title="Uploads"
                                    fields={fields}
                                    data={uploadsData}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    criteriasSaveEnabled={true}
                                    tableLevelActions={tableLevelActions}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
            <UploadDialog open={isUploadOpen}
                handleClose={handleUploadDialogClose}
                uploadFileCallback={uploadFileCallback}
            />
            <DownloadTemplate
                open={isDownloadDialogOpen}
                handleClose={handleDownloadDialogClose}
            />
        </>
    )
}

export default Uploads