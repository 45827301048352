import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { getAlertUsers } from '../../../store/actions/contracts';

import { selectAlertUsers, selectBaseRateTypes, selectCarveOutTypes, selectMethodTypes, selectServicesTypes } from "../../../store/selectors/contracts";

import { list } from '../../../store/actions/facilities';
import { createVendorContract, getDepartments, getGLCode, getLocations, getResourceTypes, getVendorContract, getVendorDocumentList, updateVendorContract, vendorEditReset } from '../../../store/actions/vendors';
import { selectSelectedClient } from '../../../store/selectors/clients';
import { selectFacilityList } from '../../../store/selectors/facilities';
import { selectSessionUserClientId } from '../../../store/selectors/session';
import { createVendorContractSelector, selectDepartments, selectGLCodes, selectLocations, selectResourceTypes } from '../../../store/selectors/vendors';
import { asIsoDate } from '../../../util/date';
import VendorsEditPageComponent from './VendorsEditPage';

const contact = {
    name: '',
    title: '',
    emails: '',
    phone: '',
}

export const VendorsEditPage = connect(
    () => {
        const selectVendorContractId = (state, props) => props.match.params.contractId;

        const selectMode = (state, props) => props.match.params.contractId === 'new' ? 'create' : 'edit';

        const selectVendorContract = createVendorContractSelector(selectVendorContractId);

        const selectInitialValues = createSelector(
            selectMode,
            selectVendorContract,
            selectAlertUsers,
            selectFacilityList,
            (mode, contract, alertUsers, allFacilities) => {
                if (mode === 'edit' && contract && alertUsers && allFacilities) {
                    let payload = {
                        id: contract.id,
                        name: contract.name,
                        effectiveDate: asIsoDate(contract.effectiveDate),
                        terminationDate: asIsoDate(contract.terminationDate),
                        isPayer: contract.isPayer ? "true" : "false",
                        description: contract.description,
                        notification: contract.terms && contract.terms.length > 0 ? contract.terms[0].notification : false,
                        alert: contract.terms && contract.terms.length > 0 ? asIsoDate(contract.terms[0].alert) : '',
                        renew: contract.terms && contract.terms.length > 0 ? asIsoDate(contract.terms[0].renew) : '',
                        documentMetadata: [{
                            documentName: '',
                            file: {},
                        },
                        ...contract.documentMetadata,
                        ],
                        vendorContacts: contract.vendorContacts,
                        alertContacts: alertUsers.filter(user => contract.alertContacts.some(y => y.userId == user.id)),
                        facilityIds: allFacilities.filter(facility => contract.contractFacilityXref?.some(y => y.facilityId == facility.id)),
                        contractStateId: contract.contractStateId,
                        location: contract.location,
                        department: contract.department,
                        glCode: contract.glCode,
                        resourceType: contract.resourceType,
                        notes: contract.notes
                    }

                    return payload;
                }
                let payload = {
                    vendorContacts: [],
                    name: '',
                    terminationDate: '',
                    isPayer: 'false',
                    effectiveDate: '',
                    description: '',
                    notification: false,
                    documentMetadata: [{
                        file: {},
                    }],
                    alertContactIds: [],
                    alertContacts: [],
                    facilityIds: [],
                    location: '',
                    department: '',
                    glCode: '',
                    resourceType: '',
                    notes: ''
                }
                return payload
            }
        );

        const selectSortedUsers = createSelector(
            selectAlertUsers,
            (users) => {
                return users.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        const selectSortedFacilities = createSelector(
            selectFacilityList,
            (users) => {
                return users.sort((a, b) => a.name.localeCompare(b.name))
            }
        );


        const selectClientId = createSelector(selectSelectedClient, selectSessionUserClientId, (selectedClientId, sessionClientId) => {
            return selectedClientId ?? sessionClientId
        })

        return (state, props) => ({
            //create contract
            contractsCreatePending: state.vendors.create.pending,

            // update dashboard
            contractsUpdatePending: state.vendors.update.pending,
            contractsGetPending: state.vendors.get.pending,

            methodTypes: selectMethodTypes(state),
            baseRateTypes: selectBaseRateTypes(state),
            carveOutTypes: selectCarveOutTypes(state),
            serviceTypes: selectServicesTypes(state),
            alertUsers: selectSortedUsers(state),
            facilities: selectSortedFacilities(state),
            contactTemplate: contact,
            clientId: selectClientId(state),


            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            contractId: selectVendorContractId(state, props),

            //Vendor Management
            departments: selectDepartments(state),
            glCodes: selectGLCodes(state),
            locations: selectLocations(state),
            resourceTypes: selectResourceTypes(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            contractCreate: createVendorContract,
            contractUpdate: updateVendorContract,
            contractGet: getVendorContract,
            contractDocumentList: getVendorDocumentList,
            contractGetAlertUsers: getAlertUsers,
            contractGetFacilities: list,
            vendorEditReset: vendorEditReset,

            // Vendor Management
            getDepartments: getDepartments,
            getLocations: getLocations,
            getResourceTypes: getResourceTypes,
            getGLCode: getGLCode,
        }, dispatch)
    })
)(injectIntl(VendorsEditPageComponent));