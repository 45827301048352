import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/pricerPayers';
import * as API from '../api/pricerPayers';
import { sessionErrorHandling } from './session';

function* getPayers(action) {
    try {
        yield put({ type: actions.PRICER_PAYERS_PENDING });
        const payload = yield call(API.getPayers);
        yield put({ type: actions.PRICER_PAYERS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_PAYERS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updatePayerStatus(action) {
    try {
        yield put({ type: actions.PRICER_PAYERS_UPDATE_STATUS_PENDING });
        const {
            id,
            enabled
        } = action.payload;
        yield call(API.updatePayerStatus, id, enabled);
        yield put({ type: actions.PRICER_PAYERS_UPDATE_STATUS_FULFILLED, payload: { id: id, enabled: !enabled } });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_PAYERS_UPDATE_STATUS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.PRICER_PAYER_CREATE_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        const payload = {
            ...payer,
        }
        yield call(API.createPayer, payload);
        yield put({ type: actions.PRICER_PAYER_CREATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_PAYER_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.PRICER_PAYER_UPDATE_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.updatePayer, payer);
        yield put({ type: actions.PRICER_PAYER_UPDATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_PAYER_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* createPayerContract(action) {
    try {
        yield put({ type: actions.PRICER_PAYER_CONTRACT_CREATE_PENDING });
        const {
            payerContract,
            callback,
            shouldReloadGrid
        } = action.payload;

        const payload = {
            ...payerContract,
        }
        yield call(API.createPayerContract, payload);
        yield put({ type: actions.PRICER_PAYER_CONTRACT_CREATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_PAYER_CONTRACT_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updatePayerContract(action) {
    try {
        yield put({ type: actions.PRICER_PAYER_CONTRACT_UPDATE_PENDING });
        const {
            payerContract,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.updatePayerContract, payerContract);
        yield put({ type: actions.PRICER_PAYER_CONTRACT_UPDATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_PAYER_CONTRACT_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* selectDeselectAll(action) {
    try {
        yield put({ type: actions.PRICER_PAYER_SELECT_ALL_PENDING });
        const {
            payload,
            callback,
        } = action.payload;
        yield call(API.selectDeselectAll, payload);
        yield put({ type: actions.PRICER_PAYER_SELECT_ALL_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_PAYER_SELECT_ALL_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayerContractsTiny(action) {
    try {
        yield put({ type: actions.PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_PENDING });
        const payload = yield call(API.getPayerContractsTiny);
        yield put({ type: actions.PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.PRICER_PAYERS_RESET });
}

export default function* () {
    yield takeEvery(actions.PRICER_PAYERS_LIST, getPayers);
    yield takeEvery(actions.PRICER_PAYERS_UPDATE_STATUS, updatePayerStatus);
    yield takeEvery(actions.PRICER_PAYER_CREATE, create);
    yield takeEvery(actions.PRICER_PAYER_UPDATE, update);
    yield takeEvery(actions.PRICER_PAYER_CONTRACT_CREATE, createPayerContract);
    yield takeEvery(actions.PRICER_PAYER_CONTRACT_UPDATE, updatePayerContract);
    yield takeEvery(actions.PRICER_PAYER_SELECT_ALL, selectDeselectAll);
    yield takeEvery(actions.PRICER_PAYER_GET_PAYER_CONTRACTS_TINY, getPayerContractsTiny);
}