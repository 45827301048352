import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from "reselect";
import { set } from '../../../../store/actions/pages';
import StepThreeComponent from './StepThree';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import { createStringDataField, createStringFieldFromArrayJoin, createTooltipIconForEmptyField } from '../../../../util/format';
import { StringEquators } from '../../../../store/constants/pages';
import { getConfigNegotiatedRates, getPricerConfigContracts, processContracts } from '../../../../store/actions/pricers';
import { setV2 } from '../../../../store/actions/pagesV2';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'desc' }];


const selectFields = createSelector(
    () => [
        createStringDataField('displayName', 'Payer Contract Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringFieldFromArrayJoin('contracts', 'Contracts', 'name', { sortable: false, hideFromFilter: true, truncate: true }),
        createTooltipIconForEmptyField('payerPlanName', 'Plan', { iconClass: 'fas fa-exclamation-circle', iconColor: 'text-danger', sortable: true, returnWholeRow: true, equators: StringEquators, whiteSpace: 'break-spaces', errorTooltip: 'Plan name is required for MRF' }),
        createStringFieldFromArrayJoin('facilityNames', 'Facilities', null, { sortable: false, hideFromFilter: true, truncate: true }),
    ]
);

export const selectPricerConfigContracts = (state) => state.pricers.contracts ?? [];

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`pricer-config-contracts-landing`] ? state.pages[`pricer-config-contracts-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`pricer-config-contracts-landing`] ? state.pages[`pricer-config-contracts-landing`].filter : null,
    selectSort,
    selectPricerConfigContracts,
);

const selectFormattedData = createSelector(
    selectFilteredData, 
    (fields) => {
        return fields.map(field => ({
            ...field,
            disabledCheckbox: !field.payerPlanName
        }));
});

const selectSelectedItems = createSelector(
    (state) => state.pagesV2['pricer-config-contracts-landing'].selected,
    (data) => {
        return data
    }
);

export const StepThree = connect(
    () => {

        return (state, props) => ({
            order: state.pages['pricer-config-contracts-landing'] ? state.pages['pricer-config-contracts-landing'].order : null,
            filter: state.pages['pricer-config-contracts-landing'] ? state.pages['pricer-config-contracts-landing'].filter : null,
            sort: selectSort(state, props),
            getContractsPending: state.pricers.getContracts.pending,
            processContractsPending: state.pricers.processContracts.pending,
            contractsFields: selectFields(state, props),
            contractsData: selectFormattedData(state, props),
            selectedItems: selectSelectedItems(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setFilter: (value) => set(`pricer-config-contracts-landing`, 'filter', value),
            setOrder: (value) => set(`pricer-config-contracts-landing`, 'order', value),
            setSort: (value) => set(`pricer-config-contracts-landing`, 'sort', value),
            setSelected: (value) => setV2('pricer-config-contracts-landing', 'selected', value),

            getPricerConfigContracts: getPricerConfigContracts,
            processContracts: processContracts,
            getConfigNegotiatedRates: getConfigNegotiatedRates
        }, dispatch)
    })
)(injectIntl(StepThreeComponent));