import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { deleteEAPG, getEAPGDeleteDependencies, resetDependencies, setDependencyDialog } from '../../../store/actions/eapg';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.eapg.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (eapgDependencyDialog) => {
                if (eapgDependencyDialog && eapgDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectEAPGId = createSelector(
            selectDependencyDialog,
            (eapgDependencyDialog) => {
                if (eapgDependencyDialog && eapgDependencyDialog?.eapgId) {
                    return eapgDependencyDialog?.eapgId
                }
                return null
            }
        );

        const selectEAPGName = createSelector(
            selectDependencyDialog,
            (eapgDependencyDialog) => {
                if (eapgDependencyDialog && eapgDependencyDialog?.name) {
                    return eapgDependencyDialog?.name
                }
                return ''
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            eapgId: selectEAPGId(state, props),
            dependencies: state.eapg.dependencies?.records ?? [],
            canDelete: state.eapg.dependencies?.canDelete ?? false,
            getDependenciesPending: state.eapg.getEAPGDependencies.pending,
            deletePendng: state.eapg.delete.pending,
            eapgName: selectEAPGName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getEAPGDeleteDependencies: getEAPGDeleteDependencies,
            deleteEAPG: deleteEAPG,
            setDependencyDialog: setDependencyDialog,
            resetDependencies: resetDependencies
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));