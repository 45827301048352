import { createSelector } from 'reselect';

export const selectDashboardFilterList = (state) => state.dashboardFilters.data;

export const selectDashboardFilterListTiny = (state) => state.dashboardFilters.tinyList;

export const selectDataTypes = createSelector(
    () => (
        ['String', 'Integer', 'Number', 'Datetime']
    )
);

export const selectValueTypes = createSelector(
    () => (
        ['Single', 'Multiple']
    )
);

export const selectDashboardFilters = createSelector(
    selectDashboardFilterList,
    (dashboardFilter) => dashboardFilter,
);

export const createDashboardFilterSelector = (selectDashboardFilterId) => createSelector(
    selectDashboardFilterId,
    selectDashboardFilters,
    (dashboardFilterId, dashboardFilters) => dashboardFilters.find((dashboardFilter) => dashboardFilter.id === parseInt(dashboardFilterId))
);