import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { createFeeSchedule, getFeeschedule, getFeeScheduleTypes, updateFeeSchedule } from '../../../store/actions/contracts';
import { setFeeScheduleLabelDepdendencyDialog } from '../../../store/actions/feeScheduleItems';
import { getHierarchy } from '../../../store/actions/feeScheduleV2';
import {
    selectFeeSchduleTypes
} from "../../../store/selectors/contracts";
import { asIsoDate } from '../../../util/date';
import FeeScheduleEditDialogComponent from './FeeScheduleEditDialog';

export const FeeScheduleEditDialog = connect(
    () => {
        
        const selectFeeScheduleId = (state, props) => props.match.params.id;
        const selectFeeScheduleDirectoryId = (state, props) => props.match.params.directoryId;



        const selectMode = createSelector(
            selectFeeScheduleId,
            (feeScheduleId) => {
                return feeScheduleId !== 'new' ?'edit' :'create';
            }
        )

        const selectFeeSchedule = (state) => state.contracts.feeSchedule;

        const additionalRates = createSelector(
            selectFeeScheduleId,
            (feeScheduleId) => {
                return [
                    { id: 'Rate', name: 'Rate' },
                    { id: 'Rate1', name: 'Rate 1' },
                    { id: 'Rate2', name: 'Rate 2' },
                    { id: 'Rate3', name: 'Rate 3' },
                    { id: 'Rate4', name: 'Rate 4' },
                    { id: 'Rate5', name: 'Rate 5' },
                    { id: 'Rate6', name: 'Rate 6' },
                    { id: 'Rate7', name: 'Rate 7' },
                    { id: 'Rate8', name: 'Rate 8' },
                    { id: 'Rate9', name: 'Rate 9' },
                    { id: 'Rate10', name: 'Rate 10' },
                ];
            }
        )

        const selectInitialValues = createSelector(
            selectMode,
            selectFeeSchedule,
            selectFeeScheduleId,
            additionalRates,
            (mode, feeSchedule,feeScheduleId, rates) => {
                if (feeScheduleId !== 'new' && feeSchedule && feeSchedule.length !== 0) {
                    const payload = {
                        id: feeSchedule.id,
                        name: feeSchedule.name ?? '',
                        effectiveDate: asIsoDate(feeSchedule.effectiveDate),
                        expirationDate: asIsoDate(feeSchedule.expirationDate),
                        description: feeSchedule.description,
                        feeScheduleTypeId: feeSchedule.feeScheduleTypeId ?? '',
                        feescheduleLabelToRateXRef: feeSchedule?.feescheduleLabelToRateXRef && feeSchedule?.feescheduleLabelToRateXRef.length > 0 ? feeSchedule?.feescheduleLabelToRateXRef : [
                            {   
                                id: 0,
                                label: '',
                                rateColumn: rates?.find(x=> x.id === `Rate`).name,
                                feeScheduleId: feeSchedule.id
                            }],
                    }
                    return payload;
                }

                return {
                    name: '',
                    effectiveDate: '',
                    expirationDate: '',
                    description: '',
                    feeScheduleTypeId: '',
                    feescheduleLabelToRateXRef: [
                    {   
                        id: 0,
                        label: '',
                        rateColumn: rates?.find(x=> x.id === `Rate`).name,
                        feeScheduleId: feeScheduleId
                    }],
                }
            }
        );

        return (state, props) => ({
            feeScheduleCreatePending: state.contracts.createFeeSchedule.pending,
            feeScheduleUpdatePending: state.contracts.updateFeeSchedule.pending,
            feeScheduleGetPending: state.contracts.feeScheduleList.pending,
            additionalRates: additionalRates(state, props),
            feescheduleTypes: selectFeeSchduleTypes(state),
            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            feeScheduleId: selectFeeScheduleId(state, props),
            directoryId: selectFeeScheduleDirectoryId(state, props),

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getFeeScheduleTypes: getFeeScheduleTypes,
            createFeeSchedule: createFeeSchedule,
            updateFeeSchedule: updateFeeSchedule,
            getFeeschedule: getFeeschedule,
            getHierarchy:getHierarchy,
            setFeeScheduleLabelDepdendencyDialog: setFeeScheduleLabelDepdendencyDialog
        }, dispatch)
    })
)(injectIntl(FeeScheduleEditDialogComponent));