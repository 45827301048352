import {
    METHOD_GET,
    METHOD_POST,
    METHOD_DELETE,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson } from '../headers';
import { apiHandleResponse } from '../../util/api';

const listRules = (contractServiceId) => {
    let endpoint = `${SERVER_URL}/api/v1/cms/rules/${contractServiceId}`;
    return fetch(endpoint, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getRule = (ruleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/${ruleId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createRule = (rule) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(rule)
    }).then(apiHandleResponse);
};

const updateRule = (rule) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(rule)
    }).then(apiHandleResponse);
};

const getMethodTypes = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/methodtypes`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getRuletypes = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/ruletypes`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getGpcis = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/gpcis`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPayMethods = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/paymethods`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getCarveoutCodeDescription = (serviceId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/descriptions/${serviceId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPaySources = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/paysources`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getMultiPrecedences = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/multiproc/precedences`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const enableRule = (ruleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/enable/${ruleId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const disableRule = (ruleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/disable/${ruleId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const setPrecedence = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rules/precedences`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleResponse);
};

const validateCodes = (codes) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/codes/validate`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(codes)
    }).then(apiHandleResponse);
};

const getRuleCriteria = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/criteria/list`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getRulesByContractId = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rules/bycontract/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const cloneRule = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/clone`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleResponse);
};

export {
    listRules,
    getRule,
    createRule,
    updateRule,
    getMethodTypes,
    getRuletypes,
    getGpcis,
    getPayMethods,
    getCarveoutCodeDescription,
    getPaySources,
    getMultiPrecedences,
    enableRule,
    disableRule,
    setPrecedence,
    validateCodes,
    getRuleCriteria,
    getRulesByContractId,
    cloneRule
};