import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/partners';
import * as API from '../api/partners';
import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.PARTNERS_LIST_PENDING });
        const payload = yield call(API.getPartners);
        yield put({ type: actions.PARTNERS_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.PARTNERS_GET_PENDING });
        const { partnerId, callback } = action.payload;
        const payload = yield call(API.getPartner, partnerId);
        yield put({ type: actions.PARTNERS_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.PARTNERS_CREATE_PENDING });
        const {
            partner,
            callback
        } = action.payload;

        const payload = {
            ...partner,
        }
        const newPartner = yield call(API.createPartner, payload);
        yield put({ type: actions.PARTNERS_CREATE_FULFILLED, newPartner });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.PARTNERS_UPDATE_PENDING });
        const {
            partner,
            callback
        } = action.payload;

        const newPartner = yield call(API.updatePartner, partner);
        yield put({ type: actions.PARTNERS_UPDATE_FULFILLED, newPartner });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deletePartner(action) {
    try {
        yield put({ type: actions.PARTNERS_DELETE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deletePartner, id);
        yield put({ type: actions.PARTNERS_DELETE_FULFILLED, payload: { id } });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* activatePartner(action) {
    try {
        yield put({ type: actions.PARTNERS_ACTIVATE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.activatePartner, id);
        yield put({ type: actions.PARTNERS_ACTIVATE_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_ACTIVATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deactivatePartner(action) {
    try {
        yield put({ type: actions.PARTNERS_DEACTIVATE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deactivatePartner, id);
        yield put({ type: actions.PARTNERS_DEACTIVATE_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_DEACTIVATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPartnerTiny() {
    try {
        yield put({ type: actions.PARTNERS_GET_TINY_PENDING });
        const payload = yield call(API.getPartnersTiny);
        yield put({ type: actions.PARTNERS_GET_TINY_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_GET_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPartnerDependencies(action) {
    try {
        const { partnerId } = action.payload;
        yield put({ type: actions.PARTNERS_GET_DEPENDENCY_PENDING });
        const payload = yield call(API.getPartnerDependencies, partnerId);
        yield put({ type: actions.PARTNERS_GET_DEPENDENCY_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_GET_DEPENDENCY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPartnersClientsProducts(action) {
    try {
        const { partnerId } = action.payload;
        yield put({ type: actions.PARTNERS_GET_PRODUCTS_PENDING });
        const payload = yield call(API.getPartnersClientsProducts, partnerId);
        yield put({ type: actions.PARTNERS_GET_PRODUCTS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PARTNERS_GET_PRODUCTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.PARTNERS_RESET });
}

export default function* () {
    yield takeEvery(actions.PARTNERS_LIST, list);
    yield takeEvery(actions.PARTNERS_CREATE, create);
    yield takeEvery(actions.PARTNERS_UPDATE, update);
    yield takeEvery(actions.PARTNERS_GET, get);
    yield takeEvery(actions.PARTNERS_DELETE, deletePartner);
    yield takeEvery(actions.PARTNERS_ACTIVATE, activatePartner);
    yield takeEvery(actions.PARTNERS_DEACTIVATE, deactivatePartner);
    yield takeEvery(actions.PARTNERS_GET_TINY, getPartnerTiny);
    yield takeEvery(actions.PARTNERS_GET_DEPENDENCY, getPartnerDependencies);
    yield takeEvery(actions.PARTNERS_GET_PRODUCTS, getPartnersClientsProducts);
}