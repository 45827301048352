import React, { useEffect, useState } from 'react';
import {
    Portlet, PortletBody, PortletHeader, PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { Checkbox, Chip, FormControl, FormControlLabel, Grid, makeStyles, Switch, TextField, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DataTable, DateRangeControl } from '../../common';
import { getLast12Months } from '../../util/date';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    formControlAuto: {
        width: '100%',
        margin: theme.spacing(1),
    },
    portlet: {
        display: 'flex',
        flexGrow: 1,
    },
    switch: {
        marginRight: 0,
        marginTop: 20,
        marginLeft: 0
    },
}));

export default function PayerScoreCard(props) {
    const classes = useStyles();

    const {
        data, fields, payersOptions, sort, order, getScoreCardPending, filter,
        actions: {
            getPayersScoreCard,
            getPayerContractsTiny,
            setSort, setOrder, setFilter
        }
    } = props;

    const [refresh, setRefresh] = useState(Promise.resolve());
    const [paidOnly, setPaidOnly] = useState(true);
    const [dateData, setDateData] = useState({
        beginDate: getLast12Months().startdate,
        endDate: getLast12Months().enddate,
        isDateFilterActive: true
    })

    useEffect(() => {
        getPayerContractsTiny();
    }, [getPayerContractsTiny]);

    useEffect(() => {
        if (payersOptions.length > 0) {
            setFilter(payersOptions)
        }
    }, [payersOptions, setFilter]);

    useEffect(() => {
        getPayersScoreCard(
            {
                PayerContractId: null,
                PaidOnly: paidOnly,
                FilterDates: dateData.isDateFilterActive,
                BeginDate: dateData.beginDate,
                EndDate: dateData.endDate
            }
        );
    }, [getPayersScoreCard, paidOnly, refresh]);


    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    const filterOptions = createFilterOptions({
        stringify: option => option.displayName,
    });

    const setDates = (beginDate, endDate, isDateFilterActive) => {
        setDateData({
            beginDate,
            endDate,
            isDateFilterActive
        });
    }

    const handlePaidOnlyChange = (e) => {
        setPaidOnly(e.target.checked);
    }

    return (
        <>
            <Helmet title="Payer Scorecard" />
            <Portlet>
                <PortletHeader
                    title="Payer Scorecard"
                    name="payers-scorecard-landing"
                    handleRefresh={handleRefresh}
                    // showSearchFilters={true}
                    toolbar={
                        <PortletHeaderToolbar className={classes.portlet}>
                            <Grid container spacing={3} justify="flex-end" className='pr-3'>
                                <Grid item md={2} className='text-right'>
                                    <FormControlLabel
                                        className={classes.switch}
                                        control={
                                            <Switch
                                                checked={paidOnly}
                                                size="small"
                                                color="primary"
                                                onChange={handlePaidOnlyChange}
                                            />
                                        }
                                        label="Paid Only?"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <DateRangeControl
                                        refresh={handleRefresh}
                                        filterName="Date Filter"
                                        setDates={setDates}
                                    />
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <FormControl className={classes.formControlAuto}>
                                        <Autocomplete
                                            fullWidth
                                            onChange={(e, value) => setFilter(value)}
                                            filterOptions={filterOptions}
                                            value={filter}
                                            multiple
                                            options={payersOptions}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.displayName}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.displayName}
                                                </React.Fragment>
                                            )}
                                            renderTags={(tagValue, getTagProps) => {
                                                const numTags = tagValue.length;
                                                return (
                                                    <Typography variant="caption">
                                                        {tagValue.slice(0, 2).map((option, index) => (
                                                            <Chip
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.chip}
                                                                key={index}
                                                                label={option.displayName}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))}
                                                        {numTags > 2 && ` + ${numTags - 2}`}
                                                    </Typography>
                                                )
                                            }
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Payers"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <Grid container>
                        <Grid item md={12}>
                            <DataTable
                                fields={fields}
                                data={data}
                                order={order}
                                onOrder={setOrder}
                                sort={sort}
                                onSort={setSort}
                                hasActions={false}
                                loading={getScoreCardPending}
                                name="Payers Scorecard"
                                handleRefresh={handleRefresh}
                            />
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet>
        </>
    );
}