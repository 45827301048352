import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import ModelResultsStatisticsComponent from './ModelResultsStatistics';

export const ModelResultsStatistics = connect(
    () => {
        const selectModelExecutionResultsStats = (state) => state.modeling.modelStats;


        return (state, props) => ({
            getModelStatsPending: state.modeling.getModelStats.pending,
            modelStats: selectModelExecutionResultsStats(state),
        });
    }
)(injectIntl(ModelResultsStatisticsComponent));