import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import AssociatedComponent from './AssociatedPage.js';

import { updateStatus, selectDeselectAll, setEnableDialog, refreshAssociatedCharges } from '../../../store/actions/associated';
import {
    createStringDataField, createCurrencyDataField,
    createPercentageDataField, createNumberDataField, createIconDataField
} from '../../../util/format';
import { StringEquators, integerEquators } from '../../../store/constants/pages';
import { associatedLanding, resetPage, set } from '../../../store/actions/pages';
import { getByName, reset } from '../../../store/actions/search.js';

const selectFields = createSelector(
    () => [
        createStringDataField('pxCode', 'Base CPT/HCPCS', { sortable: true, equators: StringEquators }),
        createStringDataField('description', 'Base Desc', { sortable: true, equators: StringEquators }),
        createStringDataField('revCode', 'Rev Code', { sortable: true, equators: StringEquators }),
        createStringDataField('associatedPxCode', 'Associated CPT Code', { sortable: true, equators: StringEquators }),
        createStringDataField('associatedDescription', 'Associated Desc', { sortable: true, equators: StringEquators }),
        createStringDataField('associatedRevCode', 'Associated Rev Code', { sortable: true, equators: StringEquators }),
        createCurrencyDataField('associated_Charge', 'Associated Charge', { sortable: true, equators: integerEquators }),
        createNumberDataField('appearanceInClaim', 'Appearance In Claim', { sortable: true, equators: integerEquators }),
        createPercentageDataField('percentage', 'Percentage', { sortable: true, hideFromFilter: true, equators: integerEquators }),
        createIconDataField('enabled', 'Enabled', { hideFromFilter: true, icon: 'fas fa-check-circle' }),
    ]
);

const selectAssociatedData = createSelector(
    (state) => state.pages['associated-landing'].list,
    (data) => data
);

export const selectCheckboxStatus = createSelector(
    selectAssociatedData,
    (associatedList) => {
        const enabledAssociated = associatedList.filter(associated => associated.enabled).length;
        if (associatedList.length === enabledAssociated) {
            return true;
        }
        return false;
    }
)

export const AssociatedPage = connect(
    () => {
        return (state, props) => ({
            associatedListPending: state.associated.list.pending,
            updateStatusPending: state.associated.updateStatus.pending,
            refreshChargesPending: state.associated.refreshCharges.pending,
            data: selectAssociatedData(state),
            fields: selectFields(state),
            checkboxStatus: selectCheckboxStatus(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            associatedLanding: associatedLanding,
            updateStatus: updateStatus,
            selectDeselectAll: selectDeselectAll,
            searchGetByName: getByName,
            searchReset: reset,
            setSelected: (value) => set('associated-landing', 'selected', value),
            resetPagedTable: resetPage,
            setEnableDialog: setEnableDialog,
            refreshAssociatedCharges: refreshAssociatedCharges
        }, dispatch)
    })
)(injectIntl(AssociatedComponent));