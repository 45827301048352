import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

export default function LicenseAgreement(props) {
    const { open, handleClose } = props;

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>CPT Disclaimer </DialogTitle>
            <DialogContent dividers={true}>
                U.S. Government End Users. CPT is commercial technical data, which was developed exclusively
                at private expense by the American Medical Association (AMA), 330 North Wabash Avenue,
                Chicago, Illinois 60611. Use of CPT in connection with this product shall not be construed to grant
                the Federal Government a direct license to use CPT based on FAR 52.227-14 (Data Rights -
                General) and DFARS 252.227-7015 (Technical Data - Commercial Items).
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}