import { createSelector } from 'reselect';

export const selectDashboardDataSourceList = (state) => state.dashboardDataSources.data;
export const selectDashboardDataSourceFieldsList = (state) => state.dashboardDataSources.fields;

export const selectDataTypes = createSelector(
    () => (
        ['String', 'Integer', 'Number', 'Datetime']
    )
);

export const selectValueTypes = createSelector(
    () => (
        ['Single', 'Multiple']
    )
);

export const selectDashboardDataSources = createSelector(
    selectDashboardDataSourceList,
    (dashboardDataSources) => dashboardDataSources,
);

export const createDashboardDataSourceSelector = (selectDashboardDataSourceId) => createSelector(
    selectDashboardDataSourceId,
    selectDashboardDataSources,
    (dashboardDataSourceId, dashboardDataSources) => dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.id === parseInt(dashboardDataSourceId))
);

export const selectDashboardDataSourceFields = createSelector(
    selectDashboardDataSourceFieldsList,
    (dashboardDataSourceFields) => dashboardDataSourceFields,
);