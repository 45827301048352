import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ClaimAndRemitsPageComponent from './Claims&RemitsPage';
import { createSelector } from 'reselect';
import { selectClientListTiny } from '../../../../store/selectors/clients';
import { get, resetStats, setRemitsAndPayersStatisticsFilter,getRemitsAndPayersStatistics } from '../../../../store/actions/statistics';
import { createCurrencyDataField,createNumberDataField ,createDateField, createPlainDateField} from '../../../../util/format';

const selectStatisticsObject = createSelector(
    state => state.statistics.stats,
    (stats:any) => {
        if (stats && stats.length > 0) {
            return stats.reduce((result, stat) => {
                return {
                    totalFilesUploaded: result.totalFilesUploaded + stat.totalFilesUploaded,
                    totalInstitutionalCharges: result.totalInstitutionalCharges + stat.totalInstitutionalCharges,
                    totalProfessionalCharges: result.totalProfessionalCharges + stat.totalProfessionalCharges,
                    totalClaimPayments: result.totalClaimPayments + stat.totalClaimPayments,
                    totalClaimServicePayments: result.totalClaimServicePayments + stat.totalClaimServicePayments,
                    totalProviderAdjustments: result.totalProviderAdjustments + stat.totalProviderAdjustments,
                };
            });
        } else {
            return {
                totalFilesUploaded: 0,
                totalInstitutionalCharges: 0,
                totalProfessionalCharges: 0,
                totalClaimPayments: 0,
                totalClaimServicePayments: 0,
                totalProviderAdjustments: 0,
            };
        }
    }
);

const selectClaimFields:any = createSelector(
    () => [
        createCurrencyDataField('claimAmount', 'Claim Amount', { sortable: true, calculateTotal: true }),
        createNumberDataField('count', 'Count'),
        createDateField('creationDate', 'Creation Date'),
    ]
);

const selectRemitFields:any = createSelector(
    () => [
        createCurrencyDataField('paidAmount', 'Paid Amount', { sortable: true, calculateTotal: true }),
        createNumberDataField('count', 'Count'),
        createPlainDateField('checkEffectiveDate', 'Effective Date'),
    ]
);


const selectSelectedClient = createSelector(
    selectClientListTiny,
    (state) => state.dashboards.selectedClient,
    (clients: any, selectedClient: number) =>
        clients?.find((item) => item?.clientId === selectedClient)?.schema
);

export const ClaimsAndRemitsPage = injectIntl(
    connect(
        (state:any) => ({
            stats: selectStatisticsObject(state),
            filters:state.statistics.remitsAndPayersfilters ,
            clients: selectClientListTiny(state),
            getStatsPending: state.statistics.getRemitPayersAndStatisticsPending.pending,
            claimFields: selectClaimFields(),
            remitFields: selectRemitFields(),
            remitAndPayersStatistics: state.statistics.remitAndPayersStatistics,
            selectedClient:selectSelectedClient(state)
            

        }),
        (dispatch) => ({
            actions: bindActionCreators({
                setStatisticsFilter: setRemitsAndPayersStatisticsFilter,
                statisticsGet: get,
                resetStats: resetStats,
                getRemitsAndPayersStatistics
            }, dispatch)
        })
    )(ClaimAndRemitsPageComponent));