import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Loadable, TextMaskCustom } from '../../../../common';
import { DAYS, MONTHS } from '../../../../store/constants/date';
import { addValidationSchema, editValidationSchema } from '../../../../store/validations/client';
import { ErrorFocus } from '../../../../util/ErrorFocus';
import BucketFieldArray from './BucketFieldArray';

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import {
    Box,
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, Input, InputLabel,
    makeStyles, MenuItem, Select, Switch, TextField, Typography
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { Card } from "react-bootstrap";
import { BackButton } from "../../../../common/BackButton";
import { STATES, TIMEZONES } from "../../../../store/constants/clients";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 8,
        width: '90%',
    },
    autoComplete: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 8,
        width: '90%',
    },
    portlet: {
        'box-shadow': 'none',
        margin: 8
    },
    error: {
        width: 800
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: '80%'
    },
}));

export default function ClientEditPage({
    initialValues, isActive, intl, history, clientId,
    bucket, bucketValidity, ftpUserValidity, isSuperAdmin, clientGetPending,
    clientsCreatePending, clientsUpdatePending, mode, parserList, clientsActivatePending, clientsDeactivatePending, client,
    actions: { resetBuckets, clientGet, clientCreate, clientUpdate, getParsers, bucketAvailability, ftpUserAvailability,
        deactivateClient, activateClient, getClientsTiny } }
) {
    const [isClientActive, setClientActive] = useState(true);
    const [isConfirmOpen, setConfirmOpen] = useState(false);

    let arrayPush = null;

    const classes = useStyles();
    const isEdit = mode === 'edit';

    const handleCallback = () => {
        history.push('/admin/clients?persist=true');
    }

    useEffect(() => {
        if (mode === 'edit') {
            clientGet(clientId);
        }
    }, []);

    //Setting Toggle with client status
    useEffect(() => {
        if (mode === 'edit') {
            setClientActive(isActive)
        }
    }, [isActive])

    useEffect(() => {
        getParsers();

        return function cleanup() {
            resetBuckets();
        }
    }, [getParsers, resetBuckets]);

    const onClickAddBucket = () => {
        if (arrayPush) {
            if (isEdit && client?.clientId) {
                arrayPush({
                    ...bucket,
                    clientId: client?.clientId,
                    id: 0,
                    ftpUser: {
                        ...bucket.ftpUser,
                        id: 0,

                    }

                });
                return
            }
            arrayPush({
                ...bucket
            });
        }
    };

    const bindArrayPush = (push) => {
        arrayPush = push;
    };

    const getFiscalYear = (day, month) => {
        return month + '/' + day;
    }

    const handleStatusToggle = (e) => {
        setClientActive(e.target.checked);
        handleConfirmOpen();
    }

    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    }
    const handleConfirmClose = () => {
        setConfirmOpen(false);
        //resetting if user cancel the operation
        setClientActive(isActive);
    }

    const handleClientStatusChange = () => {
        setConfirmOpen(false);
        if (isClientActive) {
            activateClient(clientId, handleStatusCallback)
        } else {
            deactivateClient(clientId, handleStatusCallback);
        }
    }

    const handleStatusCallback = () => {
        handleCallback();
        getClientsTiny();
    }

    const isBucketOrUsernameInvalid = () => {
        return JSON.stringify(bucketValidity).includes('true') || JSON.stringify(ftpUserValidity).includes('true')

    }


    return (
        <Loadable loading={clientsCreatePending || clientsUpdatePending || clientsActivatePending || clientsDeactivatePending || clientGetPending}>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={mode === 'create' ? addValidationSchema : editValidationSchema}
                validate={values => {
                    const errors: { [key: string]: string } = {};

                    if (values.state === null) {
                        errors.state = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.timeZone === null) {
                        errors.timeZone = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!values.licenseNumber) {
                        errors.licenseNumber = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (values.contact.replace(/\D/g, '').length === 0) {
                        errors.contact = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (values.contact.replace(/\D/g, '').length !== 0 && !(/^[1-9]\d{9}$/.test(values.contact.replace(/\D/g, '')))) {
                        errors.contact = 'Invalid Phone number';
                    }

                    if (values.licenseNumber.length > 10) {
                        errors.licenseNumber = "License Number should not exceed 10 characters.";

                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {

                    let payload = {
                        name: values.name,
                        displayName: values.displayName,
                        licenseNumber: values.licenseNumber,
                        schema: values.schema,
                        goLive: values.goLive,
                        address: values.address,
                        contact: values.contact.replace(/\D/g, '').length === 0 ? '' : values.contact,
                        maxUsers: values.maxUsers,
                        fiscalYear: getFiscalYear(values.fiscalDay, values.fiscalMonth),
                        timeZone: values.timeZone.id,
                        state: values.state.abbreviation,
                        products: {
                            hasDashboardAccess: values?.hasDashboardAccess ?? false,
                            hasCMSAccess: values?.hasCMSAccess ?? false,
                            hasEstimatorAccess: values?.hasEstimatorAccess ?? false,
                            hasPricerAccess: values?.hasPricerAccess ?? false,
                            hasVendorManagementAccess: values?.hasVendorManagementAccess ?? false,
                        },
                        buckets: values.buckets,

                        ...(
                            mode !== 'create' ? {
                                clientId: values.clientId,
                            } : {}
                        )
                    }

                    if (mode === 'create') {
                        clientCreate(payload, handleCallback);
                    }
                    else {
                        clientUpdate(payload, handleCallback);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    dirty
                }) => {
                    const customhandleBlur = (e) => {
                        if (e.target.value !== '') {
                            bucketAvailability(e.target.value, e.target.name);
                        }
                        setFieldTouched(e.target.name, true, false);
                    }
                    const customftpUserhandleBlur = (e) => {
                        if (e.target.value !== '') {
                            ftpUserAvailability(e.target.value, e.target.name);
                        }
                        setFieldTouched(e.target.name, true, false);
                    }

                    const hasValidationErrors = Object.keys(errors).length > 0;

                    return (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <Portlet>
                                <PortletHeader
                                    toolbar={
                                        <PortletHeaderToolbar className='w-100'>
                                            <AlignedRow
                                                left={
                                                    mode === 'edit' && isSuperAdmin &&
                                                        <FormGroup>
                                                            <Typography component="div">
                                                                <Grid component="label" className='mb-0' container alignItems="center" spacing={0}>
                                                                    <Grid item>Inactive</Grid>
                                                                    <Grid item>
                                                                        <Switch
                                                                            checked={isClientActive}
                                                                            onChange={handleStatusToggle}
                                                                            name="active" />
                                                                    </Grid>
                                                                    <Grid item>Active</Grid>
                                                                </Grid>
                                                            </Typography>
                                                        </FormGroup>
                                                }

                                                right={<>
                                                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/admin/clients?persist=true" />

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className='ml-3'
                                                        type="submit"
                                                        disabled={clientsCreatePending || clientsUpdatePending || (mode !== 'create' && !dirty) || isBucketOrUsernameInvalid()}
                                                    >
                                                        Save
                                                    </Button>
                                                </>}
                                            >
                                                <ValidationAlert errors={errors} />
                                            </AlignedRow>
                                        </PortletHeaderToolbar>
                                    }
                                />
                                <PortletBody className="pt-1">
                                    <div className="row">
                                        <div className="col-md-9 pt-3">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <TextField
                                                        required
                                                        label="Client Name"
                                                        name="name"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        disabled={isEdit}
                                                        helperText={touched.name && errors.name}
                                                        error={Boolean(touched.name && errors.name)}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <TextField
                                                        required
                                                        label="Display Name"
                                                        name="displayName"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.displayName}
                                                        helperText={touched.displayName && errors.displayName}
                                                        error={Boolean(touched.displayName && errors.displayName)}
                                                    />
                                                </div>


                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <TextField
                                                        required
                                                        label="Schema"
                                                        name="schema"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        disabled={isEdit}
                                                        value={values.schema}
                                                        helperText={touched.schema && errors.schema}
                                                        error={Boolean(touched.schema && errors.schema)}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <TextField
                                                        type="date"
                                                        label="Go Live"
                                                        name="goLive"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.goLive}
                                                        helperText={touched.goLive && errors.goLive}
                                                        error={Boolean(touched.goLive && errors.goLive)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <TextField
                                                        required
                                                        label="Address"
                                                        name="address"
                                                        multiline
                                                        rowsMax="3"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.address}
                                                        helperText={touched.address && errors.address}
                                                        error={Boolean(touched.address && errors.address)}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <TextField
                                                        required
                                                        label="Contact"
                                                        name="contact"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.contact}
                                                        helperText={touched.contact && errors.contact}
                                                        error={Boolean(touched.contact && errors.contact)}
                                                        InputProps={{
                                                            inputComponent: TextMaskCustom,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <TextField
                                                        type="number"
                                                        required
                                                        label="Max Users"
                                                        name="maxUsers"
                                                        className={classes.textField}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.maxUsers}
                                                        helperText={touched.maxUsers && errors.maxUsers}
                                                        error={Boolean(touched.maxUsers && errors.maxUsers)}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel htmlFor="fiscalDay">Fiscal Day</InputLabel>
                                                                <Select
                                                                    value={values.fiscalDay}
                                                                    onChange={handleChange}
                                                                    error={Boolean(touched.fiscalDay && errors.fiscalDay)}
                                                                    input={<Input id="fiscalDay" />}
                                                                    inputProps={{
                                                                        name: "fiscalDay",
                                                                        id: "fiscalDay"
                                                                    }}
                                                                >
                                                                    {DAYS.map(day => (
                                                                        <MenuItem
                                                                            key={day}
                                                                            value={day}
                                                                        >
                                                                            {day}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel htmlFor="fiscalMonth">Fiscal Month</InputLabel>
                                                                <Select
                                                                    value={values.fiscalMonth}
                                                                    onChange={handleChange}
                                                                    error={Boolean(touched.fiscalMonth && errors.fiscalMonth)}
                                                                    input={<Input id="fiscalMonth" />}
                                                                    inputProps={{
                                                                        name: "fiscalMonth",
                                                                        id: "fiscalMonth"
                                                                    }}
                                                                >
                                                                    {MONTHS.map(({ id, name }) => (
                                                                        <MenuItem
                                                                            key={id}
                                                                            value={id}
                                                                        >
                                                                            {name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <Autocomplete
                                                        className={classes.autoComplete}
                                                        value={values.state}
                                                        disableClearable={false}
                                                        onChange={(e, value) => setFieldValue("state", value)}
                                                        getOptionSelected={(option, value) => option === value}
                                                        options={STATES}
                                                        getOptionLabel={(option) => `${option.displayName} - ${option.abbreviation}`}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                name={`state`}
                                                                label="State*"
                                                                error={Boolean(touched.state && errors.state)}
                                                                helperText={touched.state && errors.state}
                                                                margin="normal" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <Autocomplete
                                                        className={classes.autoComplete}
                                                        value={values.timeZone}
                                                        disableClearable={false}
                                                        onChange={(e, value) => setFieldValue("timeZone", value)}
                                                        getOptionSelected={(option, value) => option === value}
                                                        options={TIMEZONES}
                                                        getOptionLabel={(option) => `${option.displayName} - ${option.id}`}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                name={`timeZone`}
                                                                label="Time Zone*"
                                                                error={Boolean(touched.timeZone && errors.timeZone)}
                                                                helperText={touched.timeZone && errors.timeZone}
                                                                margin="normal" />
                                                        }
                                                    />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <TextField
                                                        required
                                                        label="License Number"
                                                        name="licenseNumber"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.licenseNumber}
                                                        helperText={
                                                            touched.licenseNumber && errors.licenseNumber
                                                        }
                                                        error={Boolean(
                                                            touched.licenseNumber && errors.licenseNumber
                                                        )}

                                                    />
                                                </div>

                                                {isEdit && <div className="col-md-5">
                                                    <TextField
                                                        disabled
                                                        label="FTP URL"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        value={values?.useNewFTP ? 'transfer.slicedhealth.com' : 'sftp.slicedhealth.com'}
                                                    />
                                                </div>}
                                            </div>
                                            <div className="clearfix"></div>

                                            {values.useNewFTP &&
                                                <Card className="mt-3">
                                                    <Card.Header>
                                                        <Grid container className="align-items-center">
                                                            <Grid xs={10} item>
                                                                <h5 className="mb-0">Buckets</h5>
                                                            </Grid>
                                                            <Grid xs={2} className="text-right" item>
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={onClickAddBucket}
                                                                >
                                                                    Add
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Card.Header>
                                                    <Card.Body>

                                                        <BucketFieldArray
                                                            setFieldValue={setFieldValue}
                                                            values={values}
                                                            bindArrayPush={bindArrayPush}
                                                            mode={mode}
                                                            parserList={parserList}
                                                            handleBlur={handleBlur}
                                                            customhandleBlur={customhandleBlur}
                                                            customftpUserhandleBlur={customftpUserhandleBlur}
                                                            handleChange={handleChange}
                                                            touched={touched}
                                                            errors={errors}
                                                            bucketValidity={bucketValidity}
                                                            ftpUserValidity={ftpUserValidity}
                                                            clientsBuckets={client?.buckets}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            }
                                            <Card className="mt-3">
                                                <Card.Header>
                                                    <Grid container className="align-items-center">
                                                        <Grid xs={10} item>
                                                            <h5 className="mb-0">Products</h5>
                                                        </Grid>
                                                    </Grid>
                                                </Card.Header>
                                                <Card.Body>
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name="hasDashboardAccess"
                                                                        color="primary"
                                                                        checked={values.hasDashboardAccess}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.hasDashboardAccess}
                                                                    />
                                                                }
                                                                label="Dashboards"
                                                            />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name="hasCMSAccess"
                                                                        color="primary"
                                                                        checked={values.hasCMSAccess}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.hasCMSAccess}
                                                                    />
                                                                }
                                                                label="Contract Mangement"
                                                            />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name="hasEstimatorAccess"
                                                                        color="primary"
                                                                        checked={values.hasEstimatorAccess}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.hasEstimatorAccess}
                                                                    />
                                                                }
                                                                label="Claim Estimation"
                                                            />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name="hasPricerAccess"
                                                                        color="primary"
                                                                        checked={values.hasPricerAccess}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.hasPricerAccess}
                                                                    />
                                                                }
                                                                label="Price Transparency"
                                                            />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name="hasVendorManagementAccess"
                                                                        color="primary"
                                                                        checked={values.hasVendorManagementAccess}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.hasVendorManagementAccess}
                                                                    />
                                                                }
                                                                label="Vendor Management"
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <ErrorFocus />
                                </PortletBody>
                            </Portlet>
                        </form>
                    )
                }}
            </Formik>
            <Dialog open={isConfirmOpen} onClose={handleConfirmClose}>
                <DialogTitle>
                    {isClientActive ? 'Activate' : 'Deactivate'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to  {isClientActive ? 'activate' : 'deactivate'} this client ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type='button' onClick={handleConfirmClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        type='button'
                        onClick={handleClientStatusChange}
                        color="primary">
                        {isClientActive ? 'Activate' : 'Deactivate'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Loadable>
    );
}


const useValidationAlertStyles = makeStyles(theme => ({
    validationAlert: {
        padding: "0 16px 0 16px"
    }
}));

const ValidationAlert = ({ errors }) => {
    const classes = useValidationAlertStyles();
    return Object.keys(errors).length > 0 ?
        <Alert severity="error" classes={{ root: classes.validationAlert }}>
            Please fill out required fields and fix validation errors
        </Alert>
        : null;
}

const AlignedRow = ({ left, right, children } : {
    left?: JSX.Element,
    right?: JSX.Element,
    children?: JSX.Element | JSX.Element[]
}) =>
    <Box display="flex" flexGrow={1} alignItems="center" justifyContent="space-between">
        <Box>
            {left}
        </Box>
        <Box>
            {children}
        </Box>
        <Box>
            {right}
        </Box>
    </Box>
