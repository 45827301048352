import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { addClaims,resetAddedClaims } from "../../../../store/actions/clients";
import AddClientDialogComponent from './ClientsDialog'


export const AddClientsDialog = connect(
    () => {

        return (state, props) => ({

            initialValues:{claims:'',claimNumbers:[]},
            mergedClaims: state.clients.mergedClaims,
            addClaimsPending: state.clients.addClaims.pending,

        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators(
            {
                addClaims ,
                resetAddedClaims
            },
            dispatch
        )
    })
)(injectIntl(AddClientDialogComponent));
