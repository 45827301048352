import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react'
import { Loadable } from '../../../../common';

function EnableDialog(props) {
    const { open, isEnableClicked, selectedItems, selectAllStatusPending, handleClose,
        actions: { setEnableDialog, setSelected, selectDeselectAll } } = props;

    function handleDialogClose() {
        setEnableDialog(null);
    }

    function handleDialogConfirmation() {
        const payload = {
            ids: selectedItems,
            enabled: isEnableClicked,
        };
        selectDeselectAll(payload, updateStatusCallback);
    }

    const updateStatusCallback = () => {
        setEnableDialog(null);
        setSelected([]);
        handleClose(true);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleDialogClose}>
                <Loadable loading={selectAllStatusPending}>
                    <DialogTitle>
                        {!isEnableClicked ? 'Disable' : 'Enable'} Records?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {selectedItems.length > 0 ? (
                                `Are you sure you want to ${!isEnableClicked ? 'disable' : 'enable'} records?`
                            ) : (
                                `Please select records to ${!isEnableClicked ? 'disable' : 'enable'}`
                            )}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="default">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDialogConfirmation}
                            disabled={selectedItems.length < 1}
                            color="primary">
                            {!isEnableClicked ? 'Disable' : 'Enable'}
                        </Button>
                    </DialogActions>
                </Loadable >
            </Dialog>
        </div>
    )
}

export default EnableDialog
