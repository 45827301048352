import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import DownloadTemplateComponent from './DownloadTemplate';
import { downloadTemplate } from '../../../store/actions/uploads';

export const DownloadTemplate = connect(

    () => {
        return (state, props) => ({
            downloadTemplatePending: state.uploads.downloadTemplate.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            downloadTemplate: downloadTemplate
        }, dispatch)
    })
)(injectIntl(DownloadTemplateComponent));