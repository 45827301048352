export const STOP_EXECUTION = 'STOP_EXECUTION';
export const STOP_EXECUTION_PENDING = 'STOP_EXECUTION_PENDING';
export const STOP_EXECUTION_FULFILLED = 'STOP_EXECUTION_FULFILLED';
export const STOP_EXECUTION_REJECTED = 'STOP_EXECUTION_REJECTED';

export const EXECUTION_RESET = 'EXECUTION_RESET';
export const EXECUTION_ERROR_RESET = 'EXECUTION_ERROR_RESET';

export const CLAIM_ANALYSIS_EXECUTE_COUNT = 'CLAIM_ANALYSIS_EXECUTE_COUNT';
export const CLAIM_ANALYSIS_EXECUTE_COUNT_PENDING = 'CLAIM_ANALYSIS_EXECUTE_COUNT_PENDING';
export const CLAIM_ANALYSIS_EXECUTE_COUNT_FULFILLED = 'CLAIM_ANALYSIS_EXECUTE_COUNT_FULFILLED';
export const CLAIM_ANALYSIS_EXECUTE_COUNT_REJECTED = 'CLAIM_ANALYSIS_EXECUTE_COUNT_REJECTED';

export const CLAIM_ANALYSIS_EXECUTE = 'CLAIM_ANALYSIS_EXECUTE';
export const CLAIM_ANALYSIS_EXECUTE_PENDING = 'CLAIM_ANALYSIS_EXECUTE_PENDING';
export const CLAIM_ANALYSIS_EXECUTE_FULFILLED = 'CLAIM_ANALYSIS_EXECUTE_FULFILLED';
export const CLAIM_ANALYSIS_EXECUTE_REJECTED = 'CLAIM_ANALYSIS_EXECUTE_REJECTED';

export const EXECUTION_DOWNLOAD_REPORT_BY_ID = 'EXECUTION_DOWNLOAD_REPORT_BY_ID';
export const EXECUTION_DOWNLOAD_REPORT_BY_ID_PENDING = 'EXECUTION_DOWNLOAD_REPORT_BY_ID_PENDING';
export const EXECUTION_DOWNLOAD_REPORT_BY_ID_FULFILLED = 'EXECUTION_DOWNLOAD_REPORT_BY_ID_FULFILLED';
export const EXECUTION_DOWNLOAD_REPORT_BY_ID_REJECTED = 'EXECUTION_DOWNLOAD_REPORT_BY_ID_REJECTED';

export const stopExecution = (executionId, callback) => {
    return {
        type: STOP_EXECUTION,
        payload: { executionId, callback }
    }
};

export const claimExecute = (payload, callback) => ({
    type: CLAIM_ANALYSIS_EXECUTE,
    payload: { payload, callback }
});

export const claimExecuteCount = (payload, callback) => ({
    type: CLAIM_ANALYSIS_EXECUTE_COUNT,
    payload: { payload, callback }
});

export const downloadReportById = (id, callback) => ({
    type: EXECUTION_DOWNLOAD_REPORT_BY_ID,
    payload: { id, callback }
})

export const resetErrorMessages = () => ({ type: EXECUTION_ERROR_RESET })