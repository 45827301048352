import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set } from '../../../store/actions/pages';
import { createStringDataField, createSearchFilterDataField, createIconDataField, createStringFieldFromObjectMapping } from '../../../util/format';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import CriteriaSettingsComponent from './CriteriaSettingsPage';
import { deletesearchCriteria, list, resetCriteriaDeleteState } from '../../../store/actions/search';
import { selectSearches } from '../../../store/selectors/search';
import { listTiny } from '../../../store/actions/users';
import { selectUserObjectTiny } from '../../../store/selectors/users';
import { pagesNames } from '../../../store/constants/pages';
import { selectSelectedClient } from '../../../store/selectors/clients';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    selectUserObjectTiny,
    (tinyUserObject) => [
        createStringDataField('criteriaName', 'Criteria Name', { sortable: true }),
        createStringFieldFromObjectMapping('name', 'Page Name', pagesNames, { sortable: false, hideFromFilter: true }),
        createSearchFilterDataField('criteria', 'Criteria', { sortable: false }),
        createStringDataField('searchCriteriaType.name', 'Criteria Type', { sortable: true }),
        createIconDataField('isPublic', 'Public', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createIconDataField('isDefault', 'Default', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createStringFieldFromObjectMapping('userId', 'Owner', tinyUserObject, { sortable: false, hideFromFilter: true }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`search-criteria-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`search-criteria-landing`].filter,
    selectSort,
    selectSearches,
);

const selectSearchOptions = createSelector(selectSearches,
    searches => {
        let uniqueNames = [...new Set(searches.map(data => data.name))];
        return uniqueNames.map(nameKey => {
            return {
                id: nameKey,
                name: pagesNames[nameKey]
            }
        })
    })

export const CriteriaSettingsPage = connect(
    () => {
        return (state, props) => ({
            searchPending: state.search.list.pending,
            searchDeletePending: state.search.delete.pending,
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            searchOptions: selectSearchOptions(state, props),
            order: state.pages[`search-criteria-landing`].order,
            filter: state.pages[`search-criteria-landing`].filter,
            sort: selectSort(state, props),
            clientId: selectSelectedClient(state),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getSearchCriterias: list,
            getUsersTiny: listTiny,
            deleteCriteria: deletesearchCriteria,
            setFilter: (value) => set(`search-criteria-landing`, 'filter', value),
            setOrder: (value) => set(`search-criteria-landing`, 'order', value),
            setSort: (value) => set(`search-criteria-landing`, 'sort', value),
            resetDeleteState: resetCriteriaDeleteState,
            resetSearchCriteria: (value) => set('claimAnalysis-landing', 'selectedFilter', value),

        }, dispatch)
    })
)(injectIntl(CriteriaSettingsComponent));