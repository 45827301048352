import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { create, update, get, errorReset } from '../../../../store/actions/dashboardDataSources';
import { createDashboardDataSourceSelector } from "../../../../store/selectors/dashboardDataSources";
import DashboardDataSourcesEditPageComponent from './DashboardDataSourcesEditPage';

export const DashboardDataSourcesEditPage = connect(
    () => {
        const selectDashboardDataSourceId = (state, props) => props.match.params.dashboardDataSourceId;

        const selectMode = (state, props) => props.match.params.dashboardDataSourceId === 'new' ? 'create' : 'edit';

        const selectDashboardDataSource = createDashboardDataSourceSelector(selectDashboardDataSourceId);

        const selectInitialValues = createSelector(
            selectMode,
            selectDashboardDataSource,
            (mode, dashboardDataSource) => {
                if (mode === 'edit' && dashboardDataSource) {
                    const payload = {
                        id: dashboardDataSource.id,
                        name: dashboardDataSource.name,
                        viewName: dashboardDataSource.viewName,
                        description: dashboardDataSource.description,
                    }

                    return payload;
                }

                return {
                    name: '',
                    viewName: '',
                    description: '',
                }
            }
        )

        return (state, props) => ({
            //create dashboard
            dashboardDataSourcesCreatePending: state.dashboardDataSources.create.pending,

            // update dashboard
            dashboardDataSourcesUpdatePending: state.dashboardDataSources.update.pending,
            dashboardDataSourcesGetPending: state.dashboardDataSources.get.pending,

            // edit
            dashboardDataSource: selectDashboardDataSource(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            dashboardDataSourceId: selectDashboardDataSourceId(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            dashboardDataSourceCreate: create,
            dashboardDataSourceUpdate: update,
            dashboardDataSourceGet: get,
            dashboardDataSourceErrorReset: errorReset,
        }, dispatch)
    })
)(injectIntl(DashboardDataSourcesEditPageComponent));