import {
    Card,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, makeStyles, Typography
} from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { CustomLabel, DataTable, PopoverLink } from '../../../../common';
import { SummaryTile } from '../../../../common/widgets/SummaryTile';
import { asDate } from '../../../../util/date';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 300
    },
    grid: {
        // margin: theme.spacing(1),
    },
    popover: {
        pointerEvents: 'none',

    },
    paper: {
        pointerEvents: 'auto',
        width: 300,
    },
    chip: {
        '& span': {
            fontSize: "14px"
        }
    },
    switchLabel: {
        maxWidth: '200px',
        marginBottom: 0
    },
    underline: {
        textDecoration: 'underline',
        color: '#2739c1',
        cursor: 'pointer'
    },
    traceDialogContent: {
        maxHeight: '450px'
    },
    backButton: {
        color: theme.palette.grey[500],
        marginRight: theme.spacing(1),
    },
}));

export default function ArchivedClaimDetails({
    open, intl, fields, order, sort, filteredData,
    archivedClaim, totalExpectedPay, totalVariance,
    drg, drgDescription, principalDx, principalDxDescription,
    actions: { setArchivedClaim, setOrder, setSort }
}) {
    const classes = useStyles();

    const handleClose = () => {
        setArchivedClaim(null, []);
    }

    const hoverData = (service) => {
        const hoverColumns = new Map();
        const contractNameColumns = [
            {
                label: "Name",
                data: service?.rule?.name
            }, {
                label: "Type",
                data: service?.rule?.ruleType?.name
            }, {
                label: "Pay Method",
                data: service?.rule?.payMethod?.name
            }, {
                label: "Pay Value",
                data: service?.rule?.payValue
            }
        ];

        const pxCodeColumns = [
            {
                label: "",
                data: service.pxcodeDescription
            }
        ];
        const revCodeColumns = [
            {
                label: "",
                data: service.revcodeDescription
            }
        ];

        hoverColumns.set('rule', contractNameColumns);
        hoverColumns.set('pxcode', pxCodeColumns);
        hoverColumns.set('revcode', revCodeColumns);

        return hoverColumns;
    }

    return (
        <>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <Grid container alignItems='center'>
                        <Grid item xs={4}>
                            <Typography variant="h6">
                                <ArchiveIcon />
                                {` Archived Claim Details (${filteredData.length})  `}
                                <Chip className={classes.chip} label={`# ${archivedClaim?.claimnumber}`} color="default" variant="outlined" />
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <CustomLabel label="Payer Name" data={archivedClaim?.payer?.name} />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomLabel label="Contract Name" data={archivedClaim?.contract?.name} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container md={12} className='px-3'>
                        <Grid item xs={4}>
                            <CustomLabel label="Visit" data={archivedClaim?.visit} />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomLabel label="DRG"
                                data={
                                    <PopoverLink
                                        columnData={drg}
                                        hoverData={[
                                            {
                                                label: "",
                                                data: drgDescription
                                            }
                                        ]}
                                        title={drg}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomLabel label="Principal DX"
                                data={
                                    <PopoverLink
                                        columnData={principalDx}
                                        hoverData={[
                                            {
                                                label: "",
                                                data: principalDxDescription
                                            }
                                        ]}
                                        title={principalDx}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomLabel label="Denial" data={archivedClaim?.isDenial ? 'Yes' : 'No'} />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomLabel label="Service Date" data={asDate(archivedClaim?.servicedate)} />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomLabel label="Remit Date" data={asDate(archivedClaim?.remitDate)} />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomLabel label="UpdatedAt" data={asDate(archivedClaim?.updatedAt)} />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomLabel label="UpdatedBy" data={archivedClaim?.updatedBy} />
                        </Grid>
                    </Grid>
                    <Card className='d-flex m-3' variant='outlined'>
                        <Grid container md={12} spacing={2}>
                            <Grid item sm={4} className='pb-0'>
                                <SummaryTile title="Claim Total" value={archivedClaim?.chargedAmount} color="secondary" cardPadding='5px 10px' />
                            </Grid>
                            <Grid item sm={4} className='pb-0'>
                                <SummaryTile title="Payment" value={archivedClaim?.claimPaidamount} color="seondary" cardPadding='5px 10px' />
                            </Grid>
                            <Grid item sm={4} className='pb-0'>
                                <SummaryTile title="Patient Responsibility" value={archivedClaim?.patientResponsibility} color="seondary" cardPadding='5px 10px' />
                            </Grid>
                            <Grid item sm={4} className='pt-0'>
                                <SummaryTile title="Total Expected Pay" value={totalExpectedPay} color="secondary" cardPadding='0px 10px' />
                            </Grid>
                            <Grid item sm={4} className='pt-0'>
                                <SummaryTile title="Total Allowable" value={archivedClaim?.actualPay} color="secondary" cardPadding='0px 10px' />
                            </Grid>
                            <Grid item sm={4} className='pt-0'>
                                <SummaryTile title="Total Variance" value={totalVariance} color="secondary" cardPadding='0px 10px' />
                            </Grid>
                        </Grid>
                    </Card>
                    <div className={classes.content}>
                        <DataTable
                            fields={fields}
                            data={filteredData}
                            order={order}
                            onOrder={setOrder}
                            sort={sort}
                            onSort={setSort}
                            // loading={claimAnalysisDrillPending}
                            displayTotal={true}
                            hoverData={hoverData}
                        // actions={tableLevelAction}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}