import {
    Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, makeStyles, Switch, TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

import { Formik } from "formik";
import { Loadable } from '../../../common';
import { asIsoDate } from '../../../util/date';

const useStyles = makeStyles(theme => ({
    button: {
    },
    commentText: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    textField: {
    }
}));

export default function FlagDialog({
    open, handleClose, mode, intl, initialValues, flagClaimPending, selectedClaims = [],
    isBulkFlagged,
    actions: { flagClaims, setSelected }
}) {
    const classes = useStyles();
    let shouldReloadGrid = false;

    function handleCallback(reload) {
        setSelected([]);
        handleClose(reload);
    }

    return (
        <Dialog fullWidth open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`Follow Claim (${selectedClaims.length})`}
                {/* {claimNumber && (
                    <Chip size="small" label={` # ${claimNumber}`} color="primary" variant="outlined" />
                )} */}
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={flagClaimPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values) => {
                            shouldReloadGrid = true;
                            let payload = {
                                analysisIds: values.analysisId,
                                flag: values.flag,
                                followupAlert: values.followupAlert
                            }
                            flagClaims(payload, handleCallback, shouldReloadGrid);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">

                                <div className={classes.root}>
                                    <Grid container>
                                        {selectedClaims && selectedClaims.length < 1 && (
                                            <Grid item xs={12}>
                                                <Alert severity="error">Please select atleast 1 claim to follow.</Alert>
                                            </Grid>
                                        )}

                                        {isBulkFlagged && <Grid item xs={12} md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="flag"
                                                        color="primary"
                                                        checked={values.flag}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.flag}
                                                    />
                                                }
                                                label="Enable"
                                            />
                                        </Grid>}

                                        <Grid item xs={12} md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="setFollowUpDate"
                                                        color="primary"
                                                        checked={values.setFollowUpDate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.setFollowUpDate}
                                                    />
                                                }
                                                label="Set Follow-up Date"
                                            />
                                        </Grid>
                                        {values.setFollowUpDate && <Grid item xs={12}>
                                            <TextField
                                                type="date"
                                                label="Followup Alert Date (Optional)"
                                                name="followupAlert"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.followupAlert}
                                                helperText={touched.followupAlert && errors.followupAlert}
                                                error={Boolean(touched.followupAlert && errors.followupAlert)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    min: asIsoDate()
                                                }}
                                            />
                                        </Grid>}
                                        <Grid container className='justify-content-end pt-3'>
                                            <Button
                                                onClick={() => handleClose(false)}
                                                variant="contained"
                                                color="default"
                                                className={`${classes.button} mr-3`}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={flagClaimPending || selectedClaims.length === 0}
                                            >
                                                {(flagClaimPending) ? 'Saving..' : 'Save'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}