import React from 'react'
import { Helmet } from 'react-helmet'
import { Portlet, PortletBody, PortletHeader } from '../../../../partials/content/Portlet'
import { ClaimSelection } from './ClaimSelection'
import { useEffect } from 'react';

function ModelEditPage(props) {
    const { mode, modelId, model, actions: { getModelById } } = props;

    useEffect(() => {
        if (mode === 'edit') {
            getModelById(modelId)
        }
    }, [getModelById, modelId, mode]);

    return (
        <>
            <Helmet title="Contract Model" />
            <Portlet>
                <PortletHeader
                    title={mode === 'edit' ? `Contract Model (${model?.name ?? ''}))` : 'Contract Model'}
                />
                <PortletBody>
                    <ClaimSelection mode={mode} modelId={modelId} />
                </PortletBody>
            </Portlet >
        </>
    )
}

export default ModelEditPage
