import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { editEstimateById, estimate, getAssociatedCharges, getCPTS, getDXCodes, getEstimateById, getRevenueCodes, setClaimChargeMode, setClaimEstimatorCharges } from '../../store/actions/claimAnalysis';
import { getTinyContractsByState } from '../../store/actions/contracts';
import { set } from '../../store/actions/pages';
import { CONTRACTED_SERVICE_OUTPATIENT } from '../../store/constants/contract';
import { selectTinyContractsByState } from '../../store/selectors/contracts';
import { asIsoDate } from '../../util/date';
import { createListField, createPlainDateField, createStringDataField } from '../../util/format';
import { createFilteredSortedDataSelector } from '../../util/selector';
import ClaimEstimatorComponent from './ClaimEstimatorPage';

export const ClaimEstimator = connect(
    () => {

        const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

        const selectMode = (state, props) => props.match.params.estimatorId === 'new' ? 'create' : 'edit';
        const selectEstimatorId = (state, props) => props.match.params.estimatorId;
        const selectDXCodes = (state) => state.claimAnalysis.dxCodes;

        const selectClaimChargeData = (state) => state.claimAnalysis.claimChargeData;
        const selectClaimChargeMode = (state) => state.claimAnalysis.claimChargeMode;
        const selectClaimChargeIndex = (state) => state.claimAnalysis.claimChargeIndex;
        const selectEstimateById = (state) => state.claimAnalysis.estimateById;

        const selectFields = createSelector(
            () => [
                createStringDataField('cpt', 'Procedure (CPT)', { sortable: true, onHover: true }),
                createStringDataField('revCode', 'Rev Code', { sortable: true, onHover: true }),
                createStringDataField('units', 'Units'),
                createStringDataField('chargeAmount', 'Per Unit Charge Amount'),
                createStringDataField('totalChargeAmount', 'Total Charge Amount'),
                createPlainDateField('serviceDate', 'Service Date'),
                createListField('modifiers', 'Modifiers', { returnWholeRow: true })
            ]
        );

        const selectFilteredFields = createSelector(
            selectFields,
            (fields) => {
                return fields.map(field => field.id);
            }
        );

        const selectSort = createSelector(
            (state) => state.pages['claim-estimator-landing'].sort,
            (sort) => sort || DEFAULT_SORT,
        );

        const selectFilteredData = createFilteredSortedDataSelector(
            selectFilteredFields,
            (state) => state.pages['claim-estimator-landing'].filter,
            selectSort,
            selectClaimChargeData,
        );

        const selectSortedTinyContracts = createSelector(selectTinyContractsByState,
            (contracts) => {
                return contracts.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        const selectInitialValues = createSelector(
            selectMode,
            selectEstimateById,
            selectDXCodes,
            (mode, estimateById, dxCodes) => {
                if (mode === 'edit' && estimateById) {
                    return {
                        claimType: estimateById.claimType,
                        contractId: estimateById.contract,
                        drg: null,
                        claimAmount: estimateById.claimAmount,
                        principalDX: { code: estimateById.principalDX, description: '' },
                        admitionDate: asIsoDate(estimateById.admitionDate),
                        dischargeDate: '',
                        patientFName: estimateById.patientFName,
                        patientLName: estimateById.patientLName,

                        dxInputValue: '',

                        charges: {
                            modifierField: '',
                            modifiers: [],
                            associatedCharges: [],

                            cpt: null,
                            revCodeDescription: '',
                            revCode: '',
                            chargeAmount: '',
                            totalClaimAmount: '',
                            associatedCharge: '',
                            units: '1',
                            serviceDate: asIsoDate(new Date()),

                            cptInputValue: ''
                        }
                    }
                }
                return {
                    claimType: CONTRACTED_SERVICE_OUTPATIENT,
                    contractId: null,
                    drg: null,
                    claimAmount: '0',
                    principalDX: null,
                    admitionDate: asIsoDate(new Date()),
                    dischargeDate: '',
                    patientFName: '',
                    patientLName: '',

                    dxInputValue: '',

                    charges: {
                        modifierField: '',
                        modifiers: [],
                        associatedCharges: [],

                        cpt: null,
                        revCodeDescription: '',
                        revCode: '',
                        chargeAmount: '',
                        associatedCharge: '',
                        units: '1',
                        serviceDate: asIsoDate(new Date()),

                        cptInputValue: ''
                    }
                }

            }
        )

        return (state, props) => ({
            estimatorMode: selectMode(state, props),
            estimationId: selectEstimatorId(state, props),
            estimateById: selectEstimateById(state),

            initialValues: selectInitialValues(state, props),
            mode: selectClaimChargeMode(state),
            selectedClaimChargeIndex: selectClaimChargeIndex(state),
            cptCodes: state.claimAnalysis.cptCodes,
            revenueCodes: state.claimAnalysis.revenueCodes,
            associatedCharges: state.claimAnalysis.associatedCharges,
            tinyContracts: selectSortedTinyContracts(state),

            getAssociatedChargesPending: state.claimAnalysis.getAssociatedCharges.pending,
            getCPTCodesPending: state.claimAnalysis.getCPTCodes.pending,

            estimatePending: state.claimAnalysis.estimateClaim.pending,
            editEstimatePending: state.claimAnalysis.editEstimateById.pending,
            getEstimatePending: state.claimAnalysis.getEstimateById.pending,

            dxCodes: selectDXCodes(state),
            dxCodesPending: state.claimAnalysis.getDXCodes.pending,

            fields: selectFields( state as never),
            claimChargeData: selectFilteredData(state),
            sort: selectSort(state),
            order: state.pages['claim-estimator-landing'].order,
            filter: state.pages['claim-estimator-landing'].filter,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getCPTS: getCPTS,
            getRevenueCodes: getRevenueCodes,
            getTinyContractsByState: getTinyContractsByState,
            getAssociatedCharges: getAssociatedCharges,
            setFilter: (value) => set('claim-estimator-landing', 'filter', value),
            setOrder: (value) => set('claim-estimator-landing', 'order', value),
            setSort: (value) => set('claim-estimator-landing', 'sort', value),
            setClaimEstimatorCharges: setClaimEstimatorCharges,
            estimate: estimate,
            setClaimChargeMode: setClaimChargeMode,
            getEstimateById: getEstimateById,
            editEstimateById: editEstimateById,
            getDXCodes: getDXCodes,
        }, dispatch)
    })
)(injectIntl(ClaimEstimatorComponent));
