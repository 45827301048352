import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/uploads';
import * as API from '../api/uploads';
import { sessionErrorHandling } from './session';

function* uploadFiles(action) {
    try {
        yield put({ type: actions.UPLOAD_FILES_PENDING });
        const {
            options,
            callback
        } = action.payload;

        yield call(API.uploadFiles, options);
        yield put({ type: actions.UPLOAD_FILES_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage, innerException, errorDetails } = (error && error.payload) || { error: '', errorDetails: '', innerException: '' };
        yield put({ type: actions.UPLOAD_FILES_REJECTED, payload: { errorMessage, innerException, errorDetails } });
        yield call(sessionErrorHandling, error);
    }
}

function* downloadTemplate(action) {
    try {
        yield put({ type: actions.UPLOAD_DOWNLOAD_TEMPLATE_PENDING });
        const {
            type,
            callback
        } = action.payload;

        const payload = yield call(API.downloadTemplate, type);
        yield put({ type: actions.UPLOAD_DOWNLOAD_TEMPLATE_FULFILLED });

        if (callback) {
            callback(payload);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.UPLOAD_DOWNLOAD_TEMPLATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.UPLOAD_FILES, uploadFiles);
    yield takeEvery(actions.UPLOAD_DOWNLOAD_TEMPLATE, downloadTemplate);
}