import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { create, update, errorReset, get,getDeploymentStatus } from '../../../store/actions/brand';
import { selectBrand, selectBrandColors, selectBrandId } from "../../../store/selectors/brand";

import BrandConfigurationPageComponent from './BrandConfigurationPage';
import { getNegotiatedRatesSignOff, negotiatedRatesSignOff, publishMRFs } from '../../../store/actions/pricers';
import { selectSignOff } from '../../../store/selectors/pricers';

const FOOTER_LINK_TEMPLATE = {
    label: '',
    url: '',
}
export const BrandConfigurationPage = connect(
    () => {

        const selectInitialValues = createSelector(
            selectBrand,
            (brand) => {
                if (brand) {
                    const payload = {
                        logo: brand.logo,
                        logoRedirectionURL: brand.logoRedirectionURL,
                        themeName: brand.themeName,
                        themeMetadata: brand.themeMetadata,
                        cashDiscountPercent: brand.cashDiscountPercent,
                        ein: brand.ein,
                        footerLinks: brand.footerLinks,
                        isOutOfPocketCalculationEnabled: brand.isOutOfPocketCalculationEnabled,
                        isHealthTalkAIEnabled: brand.isHealthTalkAIEnabled,
                        healthTalkClientId: brand.healthTalkClientId,
                        healthTalkSecret: '********',
                        healthTalkAudience: brand.healthTalkAudience,
                        healthTalkGrantType: brand.healthTalkGrantType || 'client_credentials',

                        isHealthTalkAINew: brand.isHealthTalkAIEnabled,
                    }
                    return payload;
                }

                return {
                    logo: null,
                    themeName: 'Theme1',
                    cashDiscountPercent: 0,
                    ein: '',
                    logoRedirectionURL: '',
                    footerLinks: [{
                        ...FOOTER_LINK_TEMPLATE,
                    }],
                    themeMetadata: {
                        primary: '#94c83d',
                        secondary: '#0bcc47',
                    },
                    isOutOfPocketCalculationEnabled: false,
                    isHealthTalkAIEnabled: false,
                    healthTalkClientId: '',
                    healthTalkSecret: '',
                    healthTalkAudience: '',
                    healthTalkGrantType: 'client_credentials',
                }
            }
        )

        const selectMRFRes = createSelector(
            state => state.pricers.publishMRFsRes,
            (mrfs) => {
                if (mrfs) {
                    let arr = []
                    for (const prop in mrfs) {
                        arr.push({
                            file: prop,
                            status: mrfs[prop]
                        })
                    }
                    return arr;
                }
                return [];
            }
        )

        return (state, props) => ({
            //create dashboard
            brandCreatePending: state.brand.create.pending,
            // update dashboard
            brandUpdatePending: state.brand.update.pending,
            // edit
            brand: selectBrand(state, props),
            initialValues: selectInitialValues(state, props),
            clientBrandId: selectBrandId(state),
            brandColors: selectBrandColors(state, props),
            getNegotiatedRatesSignOffPending: state.pricers.getNegotiatedRatesSignOff.pending,
            signOffPending: state.pricers.negotiatedRatesSignOff.pending,
            signOff: selectSignOff(state),
            mrfs: selectMRFRes(state),
            publishMRFloading: state.pricers.publishMRFs.pending,
            getDeploymentStatusPending:state.brand.getDeploymentStatus.pending,
            deploymentStatus:state.brand.deploymentStatus

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            brandCreate: create,
            brandUpdate: update,
            brandErrorReset: errorReset,
            getBrand: get,
            getNegotiatedRatesSignOff: getNegotiatedRatesSignOff,
            negotiatedRatesSignOff: negotiatedRatesSignOff,
            publishMRFs: publishMRFs,
            getDeploymentStatus:getDeploymentStatus
        }, dispatch)
    })
)(injectIntl(BrandConfigurationPageComponent));