import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import {
    Portlet, PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";

import {
    makeStyles, Grid, Tooltip, IconButton, MenuItem, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button
} from "@material-ui/core";
import { PagedTable } from '../../common';
import { CopyContractDialog } from './CopyContractDialog';
import CopyIcon from '@material-ui/icons/FileCopy';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Edit as EditIcon } from '@material-ui/icons';
import { EllipsisMenu } from '../../common/EllipsisMenu';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { ContractStateDialog } from './ContractStateDialog';
import { ContractsTopFilter } from './ContractsTopFilter.js';
import { DependencyDialog } from './DependencyDialog';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '0.25rem',
    },
    banIcon: {
        fontSize: 17
    }
}));

const DEFAULT_CUSTOM_FILTER = [
    {
        field: 'isPayer',
        equator: 'equals',
        value: true,
    },
    {
        field: "contractState.name",
        equator: "equals",
        value: "Finalized",
    }
];

export default function ContractsPage({
    intl,
    fields,
    contractsData,
    history,
    claimDeletePending,
    contractDeleteError, contractDeleteErrorMessage,
    claimDeleteError, claimDeleteErrorMessage,
    actions: {
        contractsLanding,
        getFacilities,
        searchGetByName,
        searchReset,
        deleteReset,
        deleteClaimByContractId,
        deleteClaimReset,
        resetPagedTable,
        getStates,
        setStateDialog,
        setContractDependencyDialog,
        setCopyContractDialog,
        getTinyContracts
    }
}) {
    const classes = useStyles();
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [existingContractId, setExistingContractId] = useState(null);
    const [isClaimConfirmOpen, setClaimConfimOpen] = useState(false);

    useEffect(() => {
        getStates();
    }, [getStates]);

    useEffect(() => {
        getTinyContracts()
    }, [getTinyContracts])
    

    useEffect(() => {
        getFacilities()
    }, [getFacilities]);

    const handleContractEdit = (contract) => {
        history.push(`/cms/contract/edit/${contract.id}`);
    }

    const handleContractView = (contract) => {
        history.push(`/cms/contract/view/${contract.id}`);
    }

    function handleAddContract() {
        history.push('/cms/contract/edit/new');
    }

    function handleLoad(sort, filter, pagination) {


        let newOperands=filter?.operands?.map(operand => {
            const newFilters = operand?.filters?.map(filter => {
                if (filter?.id === 'name' && filter?.equator?.includes('equal')) {
                    const newValue = filter?.value?.map(item => item.includes(',') ? `"${item}"` : item).join(',');
                    return {
                        ...filter,
                        value: newValue
                    };
                }
                return filter;
            });
            return {
                ...operand,
                filters: newFilters
            };
        });

        let commaEscapedFilters={...filter,operands:newOperands} || null;
        

        contractsLanding(pagination, sort, !filter ? null : commaEscapedFilters);
        deleteReset();
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    useEffect(() => {
        const pageName = {
            name: 'contracts-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            deleteReset();
            deleteClaimReset();
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    function handleCopyContract(contractObj) {
        deleteReset();
        setCopyContractDialog({ 
            open: true, 
            existingContractId: contractObj.id, 
            existingContractName: contractObj.name, 
            existingContractStateId: contractObj.contractStateId 
        });
    }

    const renderMessage = () => {
        if (contractDeleteError || claimDeleteError) {
            window.scroll(0, 0);
            return <div className="alert alert-danger alert-dismissible fade show" role="alert">
                {contractDeleteErrorMessage || claimDeleteErrorMessage}
                <button onClick={handleAlertClose} type="button" className="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div >
        }
    }

    const handleAlertClose = () => {
        deleteReset();
        deleteClaimReset();
    }

    const handleDeleteConfirmation = (rowData) => {
        setContractDependencyDialog({
            open: true,
            contractId: rowData.id,
            contractName: rowData.name
        })
    }

    const handleClaimOpen = (rowData) => {
        setClaimConfimOpen(true);
        setExistingContractId(rowData.id)
    }

    const handleChangeState = (rowData) => {
        setStateDialog({
            open: true,
            contractId: rowData.id,
            contractStateId: rowData.contractStateId
        });
    }

    const handleStateDialogClose = (shouldRefresh) => {
        setStateDialog(null);
        if (shouldRefresh) {
            handleRefresh();
        }
    }

    const handleClaimClose = () => {
        setClaimConfimOpen(false);
    }

    const handleAnalysisDelete = () => {
        handleClaimClose();
        deleteClaimByContractId(existingContractId, handleAnalysisDeleteCallback);
    }

    const handleAnalysisDeleteCallback = () => {
        setExistingContractId(null);
    }

    function contractActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleContractView(rowData)}
            >
                <Tooltip title="View">
                    <PageviewIcon />
                </Tooltip>
            </IconButton>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleContractEdit(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
            <div>
                <EllipsisMenu renderMenuItems={
                    [
                        <MenuItem
                            className='justify-content-center'
                            onClick={() => handleCopyContract(rowData)}
                        >
                            <IconButton
                                color="primary"
                                className='p-1'
                                aria-label="Copy"
                            >
                                <Tooltip title={`Copy Contract`}>
                                    <CopyIcon />
                                </Tooltip>
                            </IconButton>
                        </MenuItem>,
                        <Divider />,
                        <MenuItem
                            className='justify-content-center px-0'
                            onClick={() => handleDeleteConfirmation(rowData)}
                        >
                            <IconButton
                                color={"primary"}
                                className={classes.button}
                                aria-label="Delete"
                            >
                                <Tooltip title={"Delete Contract"} >
                                    <DeleteIcon />
                                </Tooltip>
                            </IconButton>
                        </MenuItem>,
                        <Divider />,
                        <MenuItem
                            onClick={() => handleClaimOpen(rowData)}
                            className='justify-content-center px-0'
                        >
                            <IconButton color='primary'
                                className='p-1'
                            >
                                <Tooltip title={"Delete Analysis"} >
                                    <i className={`${classes.banIcon} fas fa-ban`}></i>
                                </Tooltip>
                            </IconButton>
                        </MenuItem>,
                        <Divider />,
                        <MenuItem
                            onClick={() => handleChangeState(rowData)}
                            className='justify-content-center px-0'
                        >
                            <IconButton color='primary'
                                className='p-1'
                            >
                                <Tooltip title={"Change Contract State"} >
                                    <i className={`${classes.banIcon} fas fa-exchange-alt`}></i>
                                </Tooltip>
                            </IconButton>
                        </MenuItem>
                    ]
                }
                />
            </div>
        </div>
    }

    return (
        <>
            <Helmet title="Contract Management" />
            <Portlet>
                <PortletHeader
                    title="Contract Management"
                    name="contracts-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    toolbar={
                        <PortletHeaderToolbar>
                            <ContractsTopFilter handleRefresh={handleRefresh} />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className="error">
                                    {/* {renderMessage()} */}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="contracts-landing"
                                    title="contract"
                                    fields={fields}
                                    data={contractsData}
                                    onLoad={handleLoad}
                                    onAdd={handleAddContract}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    criteriasSaveEnabled={true}
                                    defaultCustomFilter={DEFAULT_CUSTOM_FILTER}
                                    loading={claimDeletePending}
                                    hasActions={true}
                                    renderActionColumn={contractActions}
                                    showSearchFilters={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
            <CopyContractDialog
                handleRefresh={handleRefresh}
            />
            <ContractStateDialog handleClose={handleStateDialogClose} />
            <DependencyDialog handleRefresh={handleRefresh} />
            <Dialog open={isClaimConfirmOpen} onClose={handleClaimClose}>
                <DialogTitle>
                    Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete Analysis of this contract?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClaimClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAnalysisDelete}
                        color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
