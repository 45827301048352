import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import StopExecutionsDialogComponent from './StopExecutionsDialog';
import { createSelector } from 'reselect';
import {setStopExecutionDialog } from '../../../../../store/actions/modeling';


export const StopExecutionDialog = connect(
    () => {

        const selectStopExecutionDialog = (state) => state.modeling.stopExecutionDialog;

        const selectOpen = createSelector(
            selectStopExecutionDialog,
            (stopExecutionDialog) => (
                stopExecutionDialog?.open || false
            )
        );
        
        return (state, props) => ({
            open:selectOpen(state),
            stopExecutionId:state.modeling.stopExecutionDialog?.id
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setStopExecutionDialog:setStopExecutionDialog
        }, dispatch)
    })
)(injectIntl(StopExecutionsDialogComponent));