import React, { useEffect } from 'react';
import { DropdownControl } from '../DropdownControl';

export default function DynamicDropdownControl(props) {
    const {
        label, selectAllLabel, filterId, loading,
        dashboardId, onChange, fieldValues = [],
        actions: { dashboardGetFieldName },
    } = props;

    useEffect(() => {
        dashboardGetFieldName({ filterId, dashboardId });
    }, [dashboardGetFieldName, dashboardId, filterId]);

    return (
        <div>
            <DropdownControl
                label={label}
                selectAllLabel={selectAllLabel}
                onChange={onChange}
                items={fieldValues}
                loading={loading}
                defaultSelect={true}
            />
        </div>
    );
}