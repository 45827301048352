import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import FeeScheduleDroppableComponent from './FeeScheduleDroppable';
import { moveDirectoryOrFeeSchedule } from '../../../store/actions/feeScheduleV2';
import { setV2 } from '../../../store/actions/pagesV2';

export const FeeScheduleDroppable = connect(
    () => {
        const selectFeeScheduleIds = (state, props) => state.pagesV2['feeSchedules-landing'].selected;

        return (state, props) => ({
            selectedFeeScheduleIds: selectFeeScheduleIds(state)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            moveDirectoryOrFeeSchedule: moveDirectoryOrFeeSchedule,
            resetSelectedFeeSchedule: () => setV2('feeSchedules-landing', 'selected', [])
        }, dispatch)
    })
)(injectIntl(FeeScheduleDroppableComponent));