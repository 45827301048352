import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import StatisticsTabPageComponent from './StatisticsTabPage';

import { getPayerContractsTiny } from '../../../store/actions/payers';
import { payersLanding, remitPayersLanding, resetPage } from '../../../store/actions/pages';
import { selectPayerContracts } from '../../../store/selectors/payers';
import { createStringDataField, createIconDataField, createCurrencyDataField, createNumberDataField, createListField } from '../../../util/format';
import { integerEquators, StringEquators } from '../../../store/constants/pages';
import { set } from '../../../store/actions/pages';

import { getByName, reset } from '../../../store/actions/search';
import { setV2 } from '../../../store/actions/pagesV2';
import { selectSessionRole } from '../../../store/selectors/session';

export const StatisticsTabPage = connect(
    () => {

        return (state, props) => ({
            
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            
        }, dispatch)
    })
)(injectIntl(StatisticsTabPageComponent));