import { createReducer } from '../../util';
import * as actions from '../actions/vendors';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    isPayer: false,
    documents: [],
    departments: [],
    locations: [],
    resourceTypes: [],
    glCodes: [],
    documentList: {
        ...initialStatusState,
    },

    defaults: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    upload: {
        ...initialStatusState,
    },
    getDepartments: {
        ...initialStatusState,
    },
    getLocations: {
        ...initialStatusState,
    },
    getGLCode: {
        ...initialStatusState,
    },
    getResourceTypes: {
        ...initialStatusState,
    },
    deleteVendor: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {

    [actions.VENDORS_CONTRACT_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.VENDORS_CONTRACT_GET_FULFILLED]: (state, contract) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        data: [contract]
    }),
    [actions.VENDORS_CONTRACT_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_CONTRACT_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.VENDORS_CONTRACT_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.VENDORS_CONTRACT_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_CONTRACT_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.VENDORS_CONTRACT_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.VENDORS_CONTRACT_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_EDIT_RESET]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        },
    }),
    [actions.VENDORS_CONTRACT_UPLOAD_PENDING]: (state) => ({
        ...state,
        upload: {
            pending: true,
        },
    }),
    [actions.VENDORS_CONTRACT_UPLOAD_FULFILLED]: (state) => ({
        ...state,
        upload: {
            ...initialStatusState,
        },
    }),
    [actions.VENDORS_CONTRACT_UPLOAD_REJECTED]: (state, errorMessage) => ({
        ...state,
        upload: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_CONTRACT_DOCUMENTS_LIST_PENDING]: (state) => ({
        ...state,
        documentList: {
            pending: true,
        },
        documents: []
    }),
    [actions.VENDORS_CONTRACT_DOCUMENTS_LIST_FULFILLED]: (state, documents) => ({
        ...state,
        documentList: {
            ...initialStatusState,
        },
        documents: documents,
    }),
    [actions.VENDORS_CONTRACT_DOCUMENTS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        documentList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),



    //Vendor info
    [actions.VENDORS_GET_LOCATIONS_PENDING]: (state) => ({
        ...state,
        getLocations: {
            pending: true,
        },
    }),
    [actions.VENDORS_GET_LOCATIONS_FULFILLED]: (state, data) => ({
        ...state,
        getLocations: {
            ...initialStatusState,
        },
        locations: data
    }),
    [actions.VENDORS_GET_LOCATIONS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getLocations: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_GET_DEPARTMENTS_PENDING]: (state) => ({
        ...state,
        getDepartments: {
            pending: true,
        },
    }),
    [actions.VENDORS_GET_DEPARTMENTS_FULFILLED]: (state, data) => ({
        ...state,
        getDepartments: {
            ...initialStatusState,
        },
        departments: data
    }),
    [actions.VENDORS_GET_DEPARTMENTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDepartments: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_GET_GL_CODE_PENDING]: (state) => ({
        ...state,
        getGLCode: {
            pending: true,
        },
    }),
    [actions.VENDORS_GET_GL_CODE_FULFILLED]: (state, data) => ({
        ...state,
        getGLCode: {
            ...initialStatusState,
        },
        glCodes: data
    }),
    [actions.VENDORS_GET_GL_CODE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getGLCode: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_GET_RESOURCE_TYPES_PENDING]: (state) => ({
        ...state,
        getResourceTypes: {
            pending: true,
        },
    }),
    [actions.VENDORS_GET_RESOURCE_TYPES_FULFILLED]: (state, data) => ({
        ...state,
        getResourceTypes: {
            ...initialStatusState,
        },
        resourceTypes: data
    }),
    [actions.VENDORS_GET_RESOURCE_TYPES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getResourceTypes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_CONTRACT_DELETE_PENDING]: (state) => ({
        ...state,
        deleteVendor: {
            pending: true,
        },
    }),
    [actions.VENDORS_CONTRACT_DELETE_FULFILLED]: (state) => ({
        ...state,
        deleteVendor: {
            ...initialStatusState,
        },
    }),
    [actions.VENDORS_CONTRACT_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteVendor: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDORS_EDIT_RESET]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        },
    }),
    // [actions.CLIENTS_RESET]: () => ({...initialState }),
});
