import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import { createFilteredSortedDataSelector } from '../../../util/selector';

import { set } from "../../../store/actions/pages";
import {
    createAPIKeyField,
    createDateField,
    createStringDataField,
} from "../../../util/format";
import AppIdPageComponent from "./AppIdPage";


import { getAppId, resetAppIdsList } from "../../../store/actions/appId";

const DEFAULT_SORT = [{ id: "expiresOn", orderBy: "asc" }];

const selectFields = createSelector(() => [
    createStringDataField("name", "Application Name", { sortable: true }),
    createStringDataField("description", "Description", { sortable: true }),
    
    createAPIKeyField("appId", "App Id", { sortable: true, returnWholeRow: true,appId:true }),

    createDateField('createdAt', 'Created At', { sortable: true }),

]);


const selectSort = createSelector(
    state => state.pages["app-id-landing"].sort,
    sort => sort || DEFAULT_SORT
);


const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['app-id-landing'].filter,
    selectSort,
    (state) => state.appId.list,
);

export const AppIdPage = injectIntl(
    connect(
        state => ({
            order: state.pages["app-id-landing"].order,
            filter: state.pages["app-id-landing"].filter,
            sort: selectSort(state),
            apiKeysData: selectFilteredData(state),
            fields: selectFields(state),
            getAppIdsPending: state.appId.get.pending,
            getAppIdValuePending: state.appId.getValueById.pending,
        }),
        dispatch => ({
            actions: bindActionCreators(
                {
                    getAppIds: getAppId,            
                    resetAppIdsList: resetAppIdsList,
                    setFilter: value => set("app-id-landing", "filter", value),
                    setOrder: value => set("app-id-landing", "order", value),
                    setSort: value => set("app-id-landing", "sort", value)
                },
                dispatch
            )
        })
    )(AppIdPageComponent)
);
