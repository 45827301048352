import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import { FIELD_TYPE_INT, FIELD_TYPE_DATE, alphabets } from '../../store/constants/pages'

function AlphaSearchFields({ handleBlur, handleChange, classes, values, touched, errors, fieldType }) {
    return (
        <Grid container>
            {fieldType === FIELD_TYPE_INT ?
                <>
                    <Grid item xs={6}>
                        <TextField
                            type='number'
                            name="startsAt"
                            className={classes.input}
                            placeholder="Starts at"
                            color="secondary"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.startsAt}
                            helperText={touched.startsAt && errors.startsAt}
                            error={Boolean(touched.startsAt && errors.startsAt)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type='number'
                            name="endsAt"
                            className={classes.input}
                            placeholder="Ends at"
                            color="secondary"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.endsAt}
                            helperText={touched.endsAt && errors.endsAt}
                            error={Boolean(touched.endsAt && errors.endsAt)}
                        />
                    </Grid>
                </>
                : fieldType === FIELD_TYPE_DATE ?
                    <>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                name="startsAt"
                                className={classes.input}
                                margin="normal"
                                placeholder="Starts at"
                                color="secondary"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.startsAt}
                                helperText={touched.startsAt && errors.startsAt}
                                error={Boolean(touched.startsAt && errors.startsAt)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                name="endsAt"
                                className={classes.input}
                                margin="normal"
                                placeholder="Ends at"
                                color="secondary"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.endsAt}
                                helperText={touched.endsAt && errors.endsAt}
                                error={Boolean(touched.endsAt && errors.endsAt)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </> :
                    <>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="startsAt">Starts At</InputLabel>
                                <Select
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.startsAt}
                                    error={Boolean(touched.startsAt && errors.startsAt)}
                                    inputProps={{
                                        name: "startsAt",
                                        id: "startsAt"
                                    }}
                                >
                                    {alphabets.map((item, index) => (
                                        <MenuItem
                                            key={`${index}-${item}`}
                                            value={item.toLowerCase()}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>{touched.startsAt && errors.startsAt}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="endsAt">Ends At</InputLabel>
                                <Select
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.endsAt}
                                    error={Boolean(touched.endsAt && errors.endsAt)}
                                    inputProps={{
                                        name: "endsAt",
                                        id: "endsAt"
                                    }}
                                >
                                    {alphabets.map((item, index) => (
                                        <MenuItem
                                            key={`${index}-${item}`}
                                            value={item.toLowerCase()}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>{touched.endsAt && errors.endsAt}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </>
            }
        </Grid>
    )
}

export default AlphaSearchFields