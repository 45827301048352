import { Box, Button, Chip, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopy';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Card as BCard } from 'react-bootstrap';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomLabel, Loadable, PopoverLink } from '../../../../common';
import { SummaryTile } from '../../../../common/widgets/SummaryTile';
import { asDate } from '../../../../util/date';
import { fromLocalStorage } from '../../../../util/storage';
import { ClaimHistory } from '../../ClaimHistory';
import { ExecutionErrorDialog } from '../../ExecutionErrorDialog';
import { AdvancedTrace } from '../AdvancedTrace';
import { ArchivedClaimDetails } from '../ArchivedClaimDetails';
import { ChangeContractComponent } from '../ChangeContractComponent';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { ChangeStatusComponent } from '../UpdateStatusComponent';

const useStyles = makeStyles(theme => {
    return {
        titleDiv: {
            padding: '16px 24px',
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
        },
        chip: {
            marginLeft: '15px',
            fontWeight: 600,
            fontSize: '12px'
        },
        underline: {
            textDecoration: 'underline',
            color: '#2739c1',
            cursor: 'pointer'
        },
        mainDiv: {
            padding: '16px 24px',
        },
        sectionHeader: {
            paddingLeft: '28px',
            minHeight: '48px',
            fontSize: '15px',
            display: 'flex',
            alignItems: 'center'
        },
        sectionCard: {
            borderRadius: '8px !important',
            height: '100%'
        },
        sectionHeaderTab: {
            padding: '0 28px',
            backgroundColor: '#EBFFF5'
        },
        box: {
            padding: '0px 20px',
            "& b": {
                '@media (max-width: 1295px)': {
                    fontSize: '12px !important'
                },
                '@media (max-width: 1240px)': {
                    fontSize: '11px !important'
                },
            },
        },
        tabs: {
            '& button': {
                minWidth: 'unset !important',
                padding: '0 5px',
                textTransform: 'none !important',
                fontSize: '14px'
            },
            '& .MuiTabs-indicator': {
                minWidth: 'unset !important'
            }
        },
        tabBody: {
            padding: '0 30px'
        },
        tabSecBorder: {
            border: '0.56px solid  rgba(0, 0, 0, 0.25)',
            marginLeft: '-2px'
        },
        tabSecLeft: {
            paddingRight: '30px !important'
        },
        tabSecRight: {
            paddingLeft: '30px !important'
        },
        basicDetailsGrid: {
            '& .loadable': {
                marginBottom: '0 !important'
            }
        },
        prevNextButtons: {
            color: '#72777f',
            textTransform: 'none',
            '& .MuiButton-startIcon': {
                marginRight: '0px !important'
            },
            '& .MuiButton-endIcon': {
                marginLeft: '0px !important'
            }
        },
        dataViewButton: {
            marginTop: '6px'
        },
        moreChip: {
            color: '#94c83d',
            '& a': {
                color: '#94c83d',
                textDecoration: 'none',
            },
        },
        totalExpectedPayLabel: {
            marginLeft: '0.5rem'
        }
    }
});

function BasicDetailsSection(props) {

    const { isFromModel, claimAllDetails, hasJSONViewAccess, recoveredAmountFlag, sections = [], claimHistory = [], reprocessClaimCallback,
        drg, drgDescription, principalDx, principalDxDescription, getClaimDetailsPending, claimBulkOnDemandPending, claimId, comment, consolidatedClaims,
        actions: { setAdjustment, setClaimServiceAdjustments, processBulkOnDemand, setClaimCommentsConfirmationDialog, getConsolidatedClaims, getModelConsolidatedClaims, resetConsolidatedClaimsView, resetModelConsolidatedClaimsView } } = props;

    const [isTraceOpen, setTraceOpen] = useState(false);
    const [jsonViewOpen, setJsonViewOpen] = useState(false);
    const [isHistoryOpen, setHistoryOpen] = useState(false);
    const [isProcessClaimResponseDialogOpen, setClaimProcessResponseDialog] = useState(false);
    const [processClaimResponse, setProcessClaimResponse] = useState(null);
    const [tab, setTab] = React.useState(0);
    const [isClaimnumberCopied, setCopied] = React.useState(false);

    const claimAnalysisBackup = isFromModel ? fromLocalStorage('modelAnalysis-backup') : fromLocalStorage('claimAnalysis-backup');

    const classes = useStyles();

    useEffect(() => {
        if (claimId) {
            isFromModel ? getModelConsolidatedClaims(claimId.replace('/', ',')) : getConsolidatedClaims(claimId)
            return () => {
                resetConsolidatedClaimsView();
                resetModelConsolidatedClaimsView();
            }
        }
    }, [claimId, isFromModel]);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleViewClaimAdjustment = () => {
        setAdjustment({
            open: true,
            type: 'CLAIM',
            id: claimAllDetails?.paymentid
        });
    }
    function handleViewClaimServiceAdjustment() {
        setClaimServiceAdjustments({
            open: true,
            id: claimAllDetails?.paymentid
        });
    }

    const handleOpenJsonView = () => {
        if (isFromModel) {
            localStorage.setItem('model_detail_Ids', claimId);
            props.history.push(`/cms/modeling/data-view/${claimAllDetails?.claimnumber}`);
        }
        else {
            props.history.push(`/cms/claimAnalysis/data-view/${claimAllDetails?.claimnumber}/${claimId}`);
        }
    }
    const handleCloseJsonView = () => {
        setJsonViewOpen(false);
    }

    function handleOpenHistory(event) {
        setHistoryOpen(true);
    }

    function handleCloseHistory() {
        setHistoryOpen(false);
    }

    const handleCopyClaimNumber = () => {
        const claimNumberStr = document.getElementById('claimnumber-chip').textContent;
        const claimNumber = claimNumberStr.substring(1);
        navigator.clipboard.writeText(claimNumber);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }

    const handleReprocessClaim = () => {
        const payload = {
            claimMetadata: [
                {
                    claimNumber: claimAllDetails?.claimnumber,
                    contractId: claimAllDetails?.contractid,
                }
            ]
        };
        processBulkOnDemand(payload, reprocessCallback);
    }


    const handleNextClaim = (e: MouseEvent, confirm?: any) => {
        if (comment && !confirm) {
            setClaimCommentsConfirmationDialog({ open: true, state: 'next' });
            return
        }

        const currentIndex = claimAnalysisBackup.findIndex(item => item === (isFromModel ? claimId : parseInt(claimId)));

        const nextindex = currentIndex + 1;
        const nextClaimId = claimAnalysisBackup[nextindex];
        if (nextClaimId) {
            props.history.push(isFromModel ? `/cms/modeling/results/view/${nextClaimId}` : `/cms/claimAnalysis/view/${nextClaimId}`)
        }
    }

    const handlePreviousClaim = (e: MouseEvent, confirm?: any) => {
        if (comment && !confirm) {
            setClaimCommentsConfirmationDialog({ open: true, state: 'previous' });
            return
        }

        const currentIndex = claimAnalysisBackup.findIndex(item => item === (isFromModel ? claimId : parseInt(claimId)));

        const prevIndex = currentIndex - 1;
        const prevClaimId = claimAnalysisBackup[prevIndex];
        if (prevClaimId) {
            props.history.push(isFromModel ? `/cms/modeling/results/view/${prevClaimId}` : `/cms/claimAnalysis/view/${prevClaimId}`)
        }
    }

    const isPreviousButtonAvailable = () => {
        const currentIndex = claimAnalysisBackup.findIndex(item => item === (isFromModel ? claimId : parseInt(claimId)));
        return currentIndex > 0;
    }

    const isNextButtonAvailable = () => {
        const currentIndex = claimAnalysisBackup.findIndex(item => item === (isFromModel ? claimId : parseInt(claimId)));
        return currentIndex !== (claimAnalysisBackup.length - 1);
    }

    function reprocessCallback(resArr) {

        const processItem = resArr.find(item => item.processingState === 'IGNORED' || item.processingState === 'FAILED');

        // display of error when processing state is ignored or failed.
        if (processItem) {
            setProcessClaimResponse(processItem);
            setClaimProcessResponseDialog(true);
        } else {
            reprocessClaimCallback();
        }
    }

    function handleProcessClaimDialogClose() {
        setClaimProcessResponseDialog(false);
        setProcessClaimResponse(null);

        // if status is ignored redirecting to claim analysis
        if (processClaimResponse?.processingState === 'IGNORED') {
            props.history.push(`/cms/claimAnalysis`);
        }
        else {
            // getting claim details after viewing error details
            reprocessClaimCallback();
        }
    }

    function renderPatientName() {
        return claimAllDetails?.patientLastname ? claimAllDetails?.patientLastname + ', ' + `${claimAllDetails?.patientFirstname ?? ''} ${claimAllDetails?.patientMiddlename ?? ''}` : ''
    }

    function renderPhysicianName() {
        return claimAllDetails?.physicianLastname ? claimAllDetails?.physicianLastname + ', ' + `${claimAllDetails?.physicianFirstname ?? ''}` : ''
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const handleDialogClose = () => {
        setClaimCommentsConfirmationDialog({ open: false, state: '' });
    }

    return (
        <Grid className={classes.basicDetailsGrid}>
            <Loadable loading={getClaimDetailsPending || claimBulkOnDemandPending}>
                <Grid container className={classes.titleDiv}>
                    <Grid xs={8} md={7} lg={8}>
                        <Grid container alignItems='center'>
                            <Grid>
                                {isPreviousButtonAvailable() &&
                                    <Button onClick={handlePreviousClaim} className={classes.prevNextButtons} startIcon={<ArrowBackIcon />}>
                                        Previous
                                    </Button>
                                }
                                {isNextButtonAvailable() && <Button onClick={handleNextClaim} className={classes.prevNextButtons} endIcon={<ArrowForwardIcon />}>Next</Button>}
                            </Grid>
                            <Chip id='claimnumber-chip' className={classes.chip} label={`#${claimAllDetails?.claimnumber ?? ''}`} color="primary" variant="outlined" />
                            <IconButton disabled={!claimAllDetails?.claimnumber} onClick={handleCopyClaimNumber} color={isClaimnumberCopied ? 'secondary' : 'primary'}>
                                <CopyIcon />
                                {isClaimnumberCopied && <span className='font-12 ml-2'>Copied</span>}
                            </IconButton>
                            {consolidatedClaims && consolidatedClaims.length > 0 &&
                                consolidatedClaims?.map((cClaim, index) => {
                                    if (index <= 3) {
                                        return <Chip id={`cc-chip-${index}`} className={classes.chip} label={`#${cClaim ?? ''}`} color="secondary" variant="outlined" />
                                    }
                                    else if (index === 4) {
                                        let remainingClaimsIds = consolidatedClaims?.slice(index, consolidatedClaims?.length);
                                        let remainingClaims = (consolidatedClaims?.length - index);
                                        return <Chip id={`cc-chip-${index}`} className={`${classes.chip} ${classes.moreChip}`} label={
                                            <PopoverLink
                                                columnData={`+${remainingClaims < 0 ? 0 : remainingClaims} more`}
                                                hoverData={[
                                                    {
                                                        label: "",
                                                        data: <ul>{remainingClaimsIds.map((cc, i) => { return <li key={i}>  {cc} </li> })}</ul>
                                                    }
                                                ]}
                                                title={`Consolidated Claims`}
                                            />
                                        } color="secondary" variant="outlined" />
                                    }
                                })
                            }
                        </Grid>
                        {!isFromModel && <Grid container>
                            <Typography onClick={handleViewClaimAdjustment} color='primary' className={`${classes.underline} mr-2`} >
                                Claim Adjustments
                            </Typography>
                            <Typography onClick={handleViewClaimServiceAdjustment} color='primary' className={`${classes.underline} mr-2`} >
                                Service Adjustments
                            </Typography>
                            {hasJSONViewAccess &&
                                <Typography onClick={handleOpenJsonView} color='primary' className={`${classes.underline} mr-2`} >
                                    Data View
                                </Typography>
                            }
                            {sections && sections.length > 0 && <Typography onClick={() => setTraceOpen(true)} color='primary' className={`${classes.underline} mr-2`} >
                                Trace
                            </Typography>}
                            <Typography onClick={handleOpenHistory} color='primary' className={`${classes.underline}`} >
                                History
                            </Typography>
                        </Grid>}

                    </Grid>
                    {!isFromModel && <Grid xs={4} md={5} lg={4}>
                        <Grid>
                            <CustomLabel label="Payer Name" data={claimAllDetails?.payer?.name}
                            />
                        </Grid>
                        <Grid>
                            <ChangeContractComponent claimAnalysis={claimAllDetails} isContractOverriden={claimAllDetails?.overridenContractid} setShouldReloadGrid={false} handleReprocessClaim={handleReprocessClaim} reprocessCallback={reprocessCallback} />

                            {claimAllDetails?.hasExecutionError && <ExecutionErrorDialog executionError={claimAllDetails?.executionError} />}

                        </Grid>
                    </Grid>}
                    {isFromModel &&
                        <Grid xs={4} md={5} lg={4} style={{ textAlign: 'right' }}>
                            <Button className='dataViewButton' onClick={handleOpenJsonView} color='primary' variant='contained'>
                                Data View
                            </Button>
                        </Grid>
                    }

                </Grid>
                <Grid className={classes.mainDiv}>
                    <Grid container spacing={3}>
                        <Grid sm={7} md={7} lg={7} xl={8} item>
                            <BCard className={classes.sectionCard}>
                                <BCard.Header as="h5" className={`${classes.sectionHeaderTab}`}>
                                    <Tabs
                                        value={tab}
                                        onChange={handleChange}
                                        aria-label="Claim details Tab"
                                        className={classes.tabs}
                                    >
                                        <Tab label="Claim Info" {...a11yProps(0)} />
                                        {!isFromModel && <Tab className='pl-3' label="Demographics" {...a11yProps(1)} />}
                                        {!isFromModel && <Tab className='pl-3' label="Version" {...a11yProps(2)} />}
                                    </Tabs>
                                </BCard.Header>
                                <BCard.Body className='px-0'>
                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <Box marginTop={1} marginBottom={1}>
                                            <Grid className={classes.tabBody}>
                                                {tab === 0 ? <>
                                                    {isFromModel &&
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} className={classes.tabSecLeft}>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Visit" data={claimAllDetails?.visit} />
                                                                </Grid>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="DRG" data={drg} />
                                                                </Grid>

                                                                <hr />
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Principal DX" data={principalDx} />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid className={classes.tabSecBorder}></Grid>
                                                            <Grid item xs={6} className={classes.tabSecRight}>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Service Date" data={asDate(claimAllDetails?.servicedate) || 'N/A'} />
                                                                </Grid>
                                                                <hr />

                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Updated At" data={asDate( claimAllDetails?.updatedAt, 'date-time') || 'N/A'} />
                                                                </Grid>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Updated By" data={claimAllDetails?.updatedBy} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {!isFromModel &&
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} className={classes.tabSecLeft}>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Visit" data={claimAllDetails?.visit} />
                                                                </Grid>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Denial" data={claimAllDetails?.isDenial ? <span className='text-danger'><b>Yes</b></span> : 'No'} />
                                                                </Grid>
                                                                <hr />
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Is Paid" data={claimAllDetails?.isPaid ? 'Yes' : 'No'} />
                                                                </Grid>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="DRG"
                                                                        data={
                                                                            <PopoverLink
                                                                                columnData={drg}
                                                                                hoverData={[
                                                                                    {
                                                                                        label: "",
                                                                                        data: drgDescription
                                                                                    }
                                                                                ]}
                                                                                title={drg}
                                                                            />
                                                                        }
                                                                    />
                                                                </Grid>

                                                                <hr />
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Principal DX"
                                                                        data={
                                                                            <PopoverLink
                                                                                columnData={principalDx}
                                                                                hoverData={[
                                                                                    {
                                                                                        label: "",
                                                                                        data: principalDxDescription
                                                                                    }
                                                                                ]}
                                                                                title={principalDx}
                                                                            />
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid className={classes.tabSecBorder}></Grid>
                                                            <Grid item xs={6} className={classes.tabSecRight}>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Service Date" data={asDate(claimAllDetails?.servicedate) || 'N/A'} />
                                                                </Grid>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Remit Date" data={asDate(claimAllDetails?.remitDate) || 'N/A'} />
                                                                </Grid>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Acknowledged Date" data={asDate(claimAllDetails?.acknowledgementDate) || 'N/A'} />
                                                                </Grid>
                                                                <hr />

                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Updated At" data={asDate( claimAllDetails?.updatedAt, 'date-time') || 'N/A'} />
                                                                </Grid>
                                                                <Grid className='d-flex justify-content-between'>
                                                                    <CustomLabel label="Updated By" data={claimAllDetails?.updatedBy} />
                                                                </Grid>

                                                                <ChangeStatusComponent claimAnalysis={claimAllDetails} setShouldReloadGrid={false} handleReprocessClaim={handleReprocessClaim} reprocessCallback={reprocessCallback} />


                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </> : tab === 1 ? <>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} className={classes.tabSecLeft}>
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Patient Name" data={renderPatientName()} />
                                                            </Grid>

                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Patient Gender" data={claimAllDetails?.patientGender} />
                                                            </Grid>
                                                            <hr />
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Patient City" data={claimAllDetails?.patientCity} />
                                                            </Grid>
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Patient State" data={claimAllDetails?.patientState} />
                                                            </Grid>
                                                            <hr />
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Zip" data={claimAllDetails?.patientZip} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid className={classes.tabSecBorder}></Grid>
                                                        <Grid item xs={6} className={classes.tabSecRight}>
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Patient MRN" data={claimAllDetails?.patientMrn} />
                                                            </Grid>

                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Patient DOB" data={asDate(claimAllDetails?.patientDob)} />
                                                            </Grid>
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Patient Age" data={claimAllDetails?.age} />
                                                            </Grid>
                                                            <hr />
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Physician Name" data={renderPhysicianName()} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} className={classes.tabSecLeft}>
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Claim Analysis Version" data={claimAllDetails?.version} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid className={classes.tabSecBorder}></Grid>
                                                        <Grid item xs={6} className={classes.tabSecRight}>
                                                            <Grid className='d-flex justify-content-between'>
                                                                <CustomLabel label="Contract Version" data={claimAllDetails?.contract?.version} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                }
                                            </Grid>

                                        </Box>
                                    </Collapse>
                                </BCard.Body>
                            </BCard>
                        </Grid>

                        <Grid sm={5} md={5} lg={5} xl={4} item>
                            <BCard className={classes.sectionCard}>
                                <BCard.Header as="h5" className={classes.sectionHeader}>
                                    Claim Stats
                                </BCard.Header>
                                <BCard.Body className='p-0'>
                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <Box className={classes.box} marginTop={1} marginBottom={1}>
                                            <Grid container className='justify-content-between'>
                                                <Grid item sm={6} className='pt-0'>
                                                    <SummaryTile isVertical={true} title="Claim Total" helpText="Total charges associated with current claim" value={claimAllDetails?.chargedAmount} color="warning" cardPadding='2px 10px' />
                                                </Grid>
                                                {!isFromModel && <Grid item sm={6} className='pt-0'>
                                                    <SummaryTile isVertical={true} title="Payment" helpText="Amount paid (cash) by the current payer according to the ERA" value={claimAllDetails?.claimPaidamount} color="success" cardPadding='2px 10px' />
                                                </Grid>}
                                            </Grid>
                                            <Grid container className='justify-content-between'>
                                                {isFromModel && claimAllDetails?.totalExpectedPayNames?.length > 0 && claimAllDetails?.totalExpectedPayValues?.length > 0 &&
                                                    <>
                                                        <Grid item sm={12} className='pt-0'>
                                                            <b className={`kt-font-success ${classes.totalExpectedPayLabel}`}>{`Total Expected Pay:`}</b>
                                                        </Grid>

                                                        {claimAllDetails?.totalExpectedPayNames?.map((pay, index) => (
                                                            <Grid item sm={6} className='pt-0'>
                                                                <SummaryTile isVertical={true} title={pay.split('-')[0] || ''} helpText="Total amount expected to be paid by the current payer (including Patient Resp.) Calculated by SlicedHealth" value={claimAllDetails?.totalExpectedPayValues[index] || ''} color="info" cardPadding='2px 10px' />
                                                            </Grid>
                                                        ))}
                                                    </>
                                                }
                                                {!isFromModel && <Grid item sm={6} className='pt-0'>
                                                    <SummaryTile isVertical={true} title="Total Expected Pay" helpText="Total amount expected to be paid by the current payer (including Patient Resp.) Calculated by SlicedHealth" value={claimAllDetails?.expectedPay} color="info" cardPadding='2px 10px' />
                                                </Grid>}
                                                {!isFromModel && <Grid item sm={6} className='pt-0'>
                                                    <SummaryTile isVertical={true} title="Patient Responsibility" helpText="Amount owed by the patient according to the ERA" value={claimAllDetails?.patientResponsibility} color="success" cardPadding='2px 0px 2px 10px' />
                                                </Grid>}

                                            </Grid>
                                            {!isFromModel && <Grid container className='justify-content-between'>
                                                <Grid item sm={6} className='pb-0'>
                                                    <SummaryTile isVertical={true} title="Total Variance" helpText="Difference in Expected Pay and Total allowable.  Calculated by SlicedHealth" value={claimAllDetails?.variance} color="danger" cardPadding='2px 10px' />
                                                </Grid>
                                                <Grid item sm={6} className='pt-0'>
                                                    <SummaryTile isVertical={true} title="Other Allowable" helpText="Amount adjusted by the payer that affects the allowable according to the ERA (CAS codes set in configuration screen)" value={claimAllDetails?.otherAllowable} color="success" cardPadding='2px 10px' />
                                                </Grid>
                                            </Grid>}
                                            {!isFromModel && <Grid container className='justify-content-between'>
                                                <Grid item sm={6} className='pb-0'>
                                                    {(recoveredAmountFlag === true) && (
                                                        <SummaryTile isVertical={true} title="Recovered Amount" helpText="Differences in variance between previous ERA and most recent ERA" value={claimAllDetails?.recoveredAmount} color="danger" cardPadding='2px 10px' />
                                                    )}
                                                </Grid>
                                                <Grid item sm={6} className='pt-0'>
                                                    <SummaryTile isVertical={true} title="Total Allowable" helpText="Total reimbursement from current payer according to the ERA. (Payment + Patient Resp. + Other Allowable)" value={claimAllDetails?.actualPay} color="success" cardPadding='2px 10px' />
                                                </Grid>

                                            </Grid>}
                                        </Box>
                                    </Collapse>
                                </BCard.Body>
                            </BCard>
                        </Grid>
                    </Grid >
                </Grid >
            </Loadable>

            <AdvancedTrace sections={sections} isTraceOpen={isTraceOpen} setTraceOpen={setTraceOpen} title={claimAllDetails?.claimnumber} />
            <ClaimHistory
                open={isHistoryOpen}
                handleClose={handleCloseHistory}
            />
            <ArchivedClaimDetails />
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="xs"
                open={isProcessClaimResponseDialogOpen}
                onClose={handleProcessClaimDialogClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            Claim Processing ({processClaimResponse?.claimNumber})
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={handleProcessClaimDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid>
                        <CustomLabel label='Claim Number' data={processClaimResponse?.claimNumber} />
                    </Grid>
                    <Grid>
                        <CustomLabel label='State' data={<b className='kt-font-danger'>{processClaimResponse?.processingState}</b>} />
                    </Grid>
                    <Grid>
                        <CustomLabel label='Message' data={processClaimResponse?.error} />
                    </Grid>
                </DialogContent>
            </Dialog>
            <ConfirmationDialog handleNextClaim={handleNextClaim} handlePreviousClaim={handlePreviousClaim} handleClose={handleDialogClose} />
        </Grid>
    )
}

export default withRouter(BasicDetailsSection);
