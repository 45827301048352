import { Box, Collapse, IconButton, makeStyles, Radio, TableCell, TableRow, Tooltip } from '@material-ui/core';
import React from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReorderIcon from "@material-ui/icons/Reorder";

import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { RowCheckbox } from './rowCheckbox';
import RowColumn from './RowColumn';

const useStyles = makeStyles(theme => ({
    IconButton: {
        padding: 0,
        marginLeft: theme.spacing(1),
    },
    radio: {
        padding: theme.spacing(0.4),
    },
    tr: {
        "& td": {
            '@media (max-width: 1440px)': {
                padding: "6px 5px 6px 5px"
            },
            '@media (max-width: 1280px)': {
                padding: "3px 5px 3px 5px"
            },
            '@media (max-width: 1024px)': {
                padding: "6px 5px 6px 5px"
            }
        },
        background:'white'
    },
    fixedCell: {
        position: 'sticky', left: 0, backgroundColor: "#fff"
    },
    strikeThrough: {
        textDecoration: 'line-through'
    },
    noXScroll: {
        overflowX: 'unset !important' as any,
        '& div': {
            overflowX: 'unset !important',
        }
    }
}));

function Row({ index, rowData, fields,
    pageName, hasCheckbox, shouldRenderLazy = undefined,
    isCollapsible, collapsibleItem, expandedRow, handleExpanded,
    onRadioSelect, hasRadio, selectedRadio, isDnDEnabled,
    handleClickColumn, hoverData, hasActions, renderActionColumn, isGray,isSuperAdmin
}) {
    const classes = useStyles();
    
    if (
        isSuperAdmin &&
        (window.location.host === "dev.slicedhealth.com" ||
            window.location.host.includes("localhost:"))
    ) {
        console.log("$ Row Render");
    }

    const SortableIcon = SortableHandle(({ tabIndex } : {
        tabIndex?: number
    }) => (
        <div tabIndex={tabIndex}>
            <Tooltip title="Drag to set precidence of rule">
                <IconButton
                    color="default"
                    size="small" onClick={() => handleExpanded(index)}
                >
                    <ReorderIcon />

                </IconButton>
            </Tooltip>
        </div>
    ));

    const SortableRow = SortableElement(({ rowChild }) => (
        <TableRow className={`${classes.tr} ${isGray && classes.strikeThrough}`}>
            <TableCell align="left" style={{ width: 20 }}>
                {(!rowData.hideDragAndDrop) &&  ( <SortableIcon /> )} 
            </TableCell>
            {rowChild}
        </TableRow>
    ));

    const DraggableComponent = () => (rowProps) => {
        const intIndex = parseInt(index);
        return (
            isDnDEnabled ? 
                <SortableRow key={`row-${intIndex}`} index={intIndex} rowChild={rowProps.children} /> :
                <TableRow component={'tr'} id={`row-${rowData.id}`} hover={true} className={`${classes.tr} ${isGray && classes.strikeThrough}`} key={rowData.id}>
                    {rowProps.children}
                </TableRow>
        );
    };

    const renderColumns = () => {
        return <>
            {isCollapsible && (
                <TableCell style={{ width: 20 }}>
                    {!rowData.hideCollapsible && (
                        <IconButton
                            color="primary"
                            size="small" onClick={() => handleExpanded(index)}
                        >
                            {expandedRow === (index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </TableCell>
            )}
            {(hasCheckbox) && (
                <TableCell className={classes.fixedCell}>
                    <RowCheckbox rowDataId={rowData.id} pageName={pageName} disabledCheckbox={rowData.disabledCheckbox} />
                </TableCell>
            )}
            {(hasRadio) && (
                <TableCell>
                    <Radio
                        className={classes.radio}
                        onChange={onRadioSelect}
                        value={rowData.id}
                        checked={selectedRadio}
                        inputProps={{ 'aria-labelledby': rowData.id }}
                    />
                </TableCell>
            )}

            {fields.map((field, _index) => (
                !field?.options.hideFromGrid && <RowColumn
                    key={`column-${_index}`}
                    field={field}
                    rowData={rowData}
                    handleClickColumn={handleClickColumn}
                    hoverData={hoverData}
                    index={index}
                />
            ))}

            {hasActions && <TableCell>
                <div className={`d-flex ${classes.noXScroll}`}>
                    {renderActionColumn && renderActionColumn(rowData, index)}
                </div>
            </TableCell>}
        </>
    }

    return (
        <>
            {shouldRenderLazy ? <>
                {renderColumns()}
            </> :
                <TableRow component={DraggableComponent()} className={`${classes.tr} ${isGray && classes.strikeThrough}`} key={rowData.id} hover={true}>
                    {renderColumns()}
                </TableRow>
            }
            {(isCollapsible && !rowData.hideCollapsible) && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={expandedRow === (index)} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                {collapsibleItem(rowData)}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default React.memo(Row);