import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { Delete as DeleteIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    button: {
        padding: '0.25rem'
    },
}));

export default function DeleteConfirmation({ handleDelete, tooltip, buttonColor = undefined, customIcon, iconSize = undefined, isAllowedToDelete = true } :{
    handleDelete: () => void,
    tooltip?: string,
    buttonColor?: "default" | "inherit" | "primary" | "secondary",
    customIcon?: JSX.Element,
    iconSize?: "small" | "medium",
    isAllowedToDelete?: boolean
}) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleConfirmDelete = () => {
        handleClose();
        handleDelete();
    }

    return (
        <>
            <IconButton
                color={buttonColor ?? "primary"}
                className={classes.button}
                aria-label="Delete"
                onClick={handleOpen}
                size={iconSize ?? "medium"}
            >
                <Tooltip title={tooltip ?? "Delete"} >
                    {customIcon ?? <DeleteIcon />}
                </Tooltip>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { isAllowedToDelete ? "Are you sure you want to delete?" : "Please select records to delete" }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        disabled={!isAllowedToDelete}
                        onClick={handleConfirmDelete}
                        color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
