import { createSelector } from 'reselect';
import { selectIsSuperAdmin, selectIsPartner } from './session';
import { groupBy } from 'lodash';

export const selectDashboardUrl = (state) => state.dashboards.url;

export const selectDashboardFieldValues = (state) => state.dashboards.fieldValues;

export const selectDashboardList = (state) => state.dashboards.data;

export const selectDashboardListByClient = (state) => state.dashboards.iframeData;

export const selectDashboardTinyListByClient = (state) => state.dashboards.tinyList;

export const selectSelectedClient = (state) => state.dashboards.selectedClient;

export const selectGetFieldValue = (state) => state.dashboards.getFieldValue;

export const selectDashboadUrlPending = createSelector(
    (state) => state.dashboards,
    (dashboards) => dashboards.getUrl['pending']
);

export const selectDashboards = createSelector(
    selectDashboardList,
    (dashboard) => dashboard,
);

export const selectDashboardFilters = createSelector(
    (state) => state.dashboards.filters,
    (filters) => filters,
);

export const selectDashboardsByClient = createSelector(
    selectDashboardListByClient,
    (dashboard) => dashboard,
);

export const selectDashboardTinyByClient = createSelector(
    selectDashboardTinyListByClient,
    (dashboards) => dashboards,
)

export const selectDashboardMenus = createSelector(
    selectDashboardsByClient,
    selectIsSuperAdmin,
    selectIsPartner,
    selectSelectedClient,
    (dashboards, isSuperAdmin, selectIsPartner, selectedClient) => {
        if (isSuperAdmin || selectIsPartner) {
            let activeDashbaords = dashboards.filter(dashbaord => dashbaord.active);
            // if(selectedClient){
            //     activeDashbaords = activeDashbaords.filter(dashbaord => (dashbaord.clientId === selectedClient.clientId));
            // }

            const allDashboards = groupBy(activeDashbaords, dashboard => dashboard.clientName);
            const clients = Object.keys(allDashboards);

            if (clients && clients.length > 0) {
                return allDashboards[clients[0]].map((dashboard) => {
                    return {
                        title: dashboard.name,
                        page: `dashboard/${dashboard.id}`,
                        description: clients[0]
                    }
                });
            } else {
                return [];
            }

            // return clients.map((client) => {
            //     return {
            //         title: client,
            //         bullet: "dot",
            //         icon: "flaticon2-analytics-2",
            //         submenu: allDashboards[client].map((dashboard) => {
            //             return {
            //                 title: dashboard.name,
            //                 page: `dashboard/${dashboard.id}`,
            //                 description: client
            //             }
            //         }),
            //     }
            // });
        } else {
            return dashboards.map((dashboard) => {
                return {
                    title: dashboard.name,
                    icon: "flaticon2-analytics-2",
                    page: `dashboard/${dashboard.id}`
                }
            });
        }
    }
);

export const createDashboardSelector = (selectDashboardId) => createSelector(
    selectDashboardId,
    selectDashboards,
    (dashboardId, dashboards) => dashboards.find((dashboard) => dashboard.id === parseInt(dashboardId))
);

export const createDashboardUrlSelector = (selectDashboardId) => createSelector(
    selectDashboardId,
    selectDashboardUrl,
    (dashboardId, urlObject) => {
        return urlObject.id === parseInt(dashboardId) ? urlObject.url : undefined;
    }
);

export const createDashboardUrlObjectSelector = (selectDashboardId) => createSelector(
    selectDashboardId,
    selectDashboardUrl,
    (dashboardId, urlObject) => {
        return urlObject.id === parseInt(dashboardId) ? urlObject : {};
    }
);

export const createDashboardFieldValueSelector = (dashboardId, filterId) => createSelector(
    dashboardId,
    filterId,
    selectDashboardFieldValues,
    (dashboardId, filterId, fieldValues) => {
        return fieldValues[`${dashboardId}-${filterId}`];
    }
);

export const createDashboardFieldLoadingSelector = (dashboardId, filterId) => createSelector(
    dashboardId,
    filterId,
    selectGetFieldValue,
    (dashboardId, filterId, getFieldValue) => {
        const fieldValue = getFieldValue[`getFieldValue-${dashboardId}-${filterId}`];
        if (fieldValue) {
            return fieldValue.pending;
        }
        return false;
    }
);