import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import PayerScoreCardPageComponent from './PayerScoreCardPage';

import { createKPIDataField, createStringDataField } from '../../util/format';
import { getPayerContractsTiny, getPayersScoreCard } from '../../store/actions/payers';
import { set } from '../../store/actions/pages';
import { createSortedDataSelector } from '../../util/selector';

const DEFAULT_SORT = [{ id: 'displayName', orderBy: 'asc' }];


const selectFields = createSelector(
    () => [
        createStringDataField('displayName', 'Display Name', { sortable: true }),
        createStringDataField('contracts', 'Contract Name', { sortable: false, hideFromFilter: true, truncate: true }),
        createKPIDataField('errorRate', 'Error Rate', {
            sortable: true,
            returnWholeRow: true,
        }),
        createKPIDataField('timeToPay', 'Time to Pay', {
            sortable: true,
            returnWholeRow: true,
        }),
        createKPIDataField('payerYield', 'Payer Yield', {
            sortable: true,
            returnWholeRow: true,
        }),
        // createKPIDataField('denialRate', 'Denial Rates', {
        //     sortable: true,
        //     returnWholeRow: true,
        // }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectPayerScoreCardData = createSelector(
    (state) => state.payers.scoreCardList,
    (state) => state.pages['payers-scorecard-landing'].filter,
    (data, filters) => {
        if (filters.length === 0) {
            return data
        }
        return data.filter(score => filters.some(filter => score.displayName === filter.displayName));
    }
);

const selectSort = createSelector(
    (state) => state.pages['payers-scorecard-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectSortedDataSelector = createSortedDataSelector(selectFilteredFields, selectSort, selectPayerScoreCardData)

export const PayerScoreCardPage = connect(
    () => {
        return (state, props) => ({
            data: selectSortedDataSelector(state),
            fields: selectFields(state),
            payersOptions: state.payers.payerContracts,

            getScoreCardPending: state.payers.getPayersScoreCard.pending,

            order: state.pages['payers-scorecard-landing'].order,
            filter: state.pages['payers-scorecard-landing'].filter,
            sort: selectSort(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getPayerContractsTiny: getPayerContractsTiny,
            getPayersScoreCard: getPayersScoreCard,
            setOrder: (value) => set('payers-scorecard-landing', 'order', value),
            setSort: (value) => set('payers-scorecard-landing', 'sort', value),
            setFilter: (value) => set(`payers-scorecard-landing`, 'filter', value),
        }, dispatch)
    })
)(injectIntl(PayerScoreCardPageComponent));