import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import ShoppableComponent from './ShoppablePage.js';
import { getCounts, updateStatus, exportShoppable, setEnableDialog } from '../../../store/actions/shoppables';


import { createStringDataField, createCurrencyDataField, createIconDataField, createTimestampField } from '../../../util/format';
import { shoppableLanding, set, resetPage } from '../../../store/actions/pages';
import { StringEquators, integerEquators, dateEquators } from '../../../store/constants/pages';
import { selectFacilities } from '../../../store/selectors/pricers';
import { getFacilitiesV2 } from '../../../store/actions/pricers';
import { getSubDomain } from '../../../util/domain';
import { getByName, reset } from '../../../store/actions/search.js';
import { getPaginationFilter } from '../../../util/pagination';

const selectFields = createSelector(
    () => [
        createStringDataField('code', 'Code', { sortable: true, equators: StringEquators }),
        createStringDataField('codeType.name', 'Code Type', { sortable: true, equators: StringEquators }),
        createStringDataField('type', 'Type', { sortable: true, equators: StringEquators }),
        createStringDataField('chargeCode', 'Charge Code', { sortable: true, equators: StringEquators }),
        createStringDataField('revCode', 'Rev Code', { sortable: true, equators: StringEquators }),
        createStringDataField('description', 'Description', { sortable: true, equators: StringEquators }),
        createStringDataField('service', 'Service', { sortable: true, equators: StringEquators }),
        createCurrencyDataField('rate', 'Charge Amount', { sortable: true, equators: integerEquators, width: 230 }),
        createStringDataField('updatedBy', 'Updated By', { sortable: true, equators: StringEquators }),
        createTimestampField('updatedAt', 'Updated At', { sortable: true, equators: dateEquators }),
        createIconDataField('active', 'Active', { sortable: true, hideFromFilter: true, icon: 'fas fa-check-circle' }),
    ]
);

const selectShoppableData = createSelector(
    (state) => state.pages['shoppable-landing'].list,
    (data) => {
        return data.map(e => {
            if (e.enabled && e.status === '') {
                return {
                    ...e,
                    status: 'Shoppable'
                }
            }
            return e;
        });
    }
);

const selectCheckboxStatus = createSelector(
    selectShoppableData,
    (shoppableList) => {
        const enabledShoppable = shoppableList.filter(shoppable => shoppable.enabled).length;
        if (shoppableList.length === enabledShoppable) {
            return true;
        }
        return false;
    }
)

const selectCustomFilter = createSelector(
    (state) => state.pages['shoppable-landing'],
    (table) => {
        return table.customFilter;
    }
);

const selectFilter = createSelector(
    (state) => state.pages['shoppable-landing'],
    selectFields,
    (table, fields) => {
        return getPaginationFilter(fields, table.filter, null, [], table.customFilter);
    }
);

const selectSort = createSelector(
    (state) => state.pages['shoppable-landing'],
    (table) => {
        if (table.sort.length === 0) {
            return [];
        }
        return table.sort;
    },
);

const selectPage = createSelector(
    (state) => state.pages['shoppable-landing'],
    (table) => {
        return table.page;
    },
);

export const ShoppablePage = connect(
    () => {
        return (state, props) => ({
            shoppableListPending: state.shoppables.list.pending,
            updateStatusPending: state.shoppables.updateStatus.pending,

            shoppableData: selectShoppableData(state),
            shoppableFields: selectFields(state),

            checkboxStatus: selectCheckboxStatus(state),

            counts: state.shoppables.shoppableCounts,
            facilities: selectFacilities(state),
            subDomain: getSubDomain(),
            customFilter: selectCustomFilter(state),

            filter: selectFilter(state, props),
            sort: selectSort(state, props),
            page: selectPage(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            shoppableLanding: shoppableLanding,
            updateStatus: updateStatus,
            getCounts: getCounts,
            pricersGetFacilities: getFacilitiesV2,
            searchGetByName: getByName,
            searchReset: reset,
            setCustomFilter: (value) => set('shoppable-landing', 'customFilter', value),
            exportShoppable: exportShoppable,
            resetPagedTable: resetPage,
            setEnableDialog: setEnableDialog
        }, dispatch)
    })
)(injectIntl(ShoppableComponent));