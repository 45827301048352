import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DirectoryDependencyDialogComponent from './DirectoryDependencyDialog';
import { deleteFeeScheduleDirectory, getFeeScheduleDirectoryDependencies, resetDirectoryDependencies, setFeeScheduleDirectoryDependencyDialog } from '../../../store/actions/feeScheduleV2';

export const DirectoryDependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.feeScheduleV2.directoryDependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.open) {
                    return true;
                }
                return false;
            }
        );

        const selectDirectoryId = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.directoryId) {
                    return dependencyDialog?.directoryId;
                }
                return null;
            }
        );

        const selectDirectoryName = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.directoryName) {
                    return dependencyDialog?.directoryName
                }
                return ''
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            directoryId: selectDirectoryId(state, props),
            directoryDependencies: state.feeScheduleV2.directoryDependencies?.records ?? [],
            directoryDependencyPending: state.feeScheduleV2.getFeeScheduleDirectoryDependencies.pending,
            directoryDeletePending: state.feeScheduleV2.deleteFeeScheduleDirectory.pending,
            directoryName: selectDirectoryName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setFeeScheduleDirectoryDependencyDialog: setFeeScheduleDirectoryDependencyDialog,
            getFeeScheduleDirectoryDependencies: getFeeScheduleDirectoryDependencies,
            deleteFeeScheduleDirectory: deleteFeeScheduleDirectory,
            resetDirectoryDependencies: resetDirectoryDependencies
        }, dispatch)
    })
)(injectIntl(DirectoryDependencyDialogComponent));