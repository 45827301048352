import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import APIKeyEditPageComponent from './APIKeyEditPage';
import { getUsersByRole } from '../../../../store/actions/users';
import { selectSelectedClient } from '../../../../store/selectors/clients';
import { selectUsersByRole } from '../../../../store/selectors/users';
import { createAPIKey,getTinyList } from '../../../../store/actions/apikey';

export const APIKeyEditPage = connect(
    () => {
        const selectInitialValues = createSelector(
            () => {
                return {
                    userId: null,
                    duration: 30
                }
            }
        );

        return (state, props) => ({
            initialValues: selectInitialValues(state, props),
            clientId: selectSelectedClient(state),
            users: selectUsersByRole(state, props),
            tinyList:state.apikey.tinyList,
            createPending: state.apikey.create.pending,
            getUsersPending: state.users.getUsersByRole.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getUsersByRole: getUsersByRole,
            createAPIKey: createAPIKey,
            getTinyList:getTinyList
        }, dispatch)
    })
)(injectIntl(APIKeyEditPageComponent));