import React from "react";
import ReactGA from "react-ga";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import * as clientActions from "../actions/clients";
import * as dashboardActions from "../actions/dashboards";
import * as searchActions from '../actions/search';
import * as actions from "../actions/session";
import * as settingsAction from "../actions/settings";
import * as usersActions from "../actions/users";

import * as CLIENT_API from "../api/clients";
import * as DASHBOARD_API from "../api/dashboards";
import * as SEARCH_API from "../api/search";
import * as API from "../api/session";
import * as USERS_API from "../api/users";

import { reset as clientsReset } from "./clients";
import { reset as dashboardDataSourcesReset } from "./dashboardDataSources";
import { reset as dashboardFiltersReset } from "./dashboardFilters";
import { reset as dashboardReset } from "./dashboards";
import { reset as dashboardTypesReset } from "./dashboardTypes";
import { reset as rolesReset } from "./roles";
import { reset as usersReset } from "./users";

import { decodeEmail } from "../../util";
import { fromLocalStorage, toLocalStorage } from "../../util/storage";
import { ENV_PRODUCTION } from "../constants/env";
import { ROLE_PARTNER_ADMIN, ROLE_PARTNER_USER, ROLE_SUPERADMIN } from "../constants/roles";

import { faro } from "../../util/faro";

import { ToastMessage } from "../../common";
import {
    clearRefreshTimer,
    startRefreshTimer
} from "../../util/sessionRefresh";
import { errorToastify, removeAllToasts } from "../../util/toast";
import { pagesNames } from "../constants/pages";

function* login(action) {
    const { username, password, rememberMe } = action.payload;
    try {
        yield put({ type: actions.SESSION_LOGIN_PENDING });
        const payload = yield call(API.login, username, password);
        const { token, id, role } = payload;

        yield toLocalStorage("authToken", token);
        const user = {
            id,
            username,
            role
        };
        yield put({ type: actions.SESSION_LOGIN_FULFILLED, payload });
        // HACK - in some cases, the last location is not getting cleared
        // And if the user login via same browser, then it goes to loop.
        yield toLocalStorage("slicedHealth-lastLocation", []);
        yield toLocalStorage("slicedHealth-lastLocation_expiresIn", 0);
        yield toLocalStorage("rememberMe", rememberMe);

        if (rememberMe) {
            yield toLocalStorage("username", username);
        } else {
            yield toLocalStorage("username", "");
        }

        yield put({
            type: actions.SESSION_CONSTRUCT,
            payload: { user, authToken: token }
        });

        faro?.api.setUser({
            username,
            id : String( id ),
            email : payload.email
        });

        yield put({
            type: actions.SESSION_CONSTRUCT_FROM_LOGIN,
            payload: { constructFromLogin: true }
        });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.SESSION_LOGIN_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error, true);
    }
}

function* construct(action) {
    const authToken = fromLocalStorage("authToken", null);

    if (!authToken) {
        yield put({ type: actions.SESSION_LOGOUT, payload: { manual: false } });
        return;
    }

    try {
        // calling settings at login
        yield put({ type: settingsAction.SETTINGS_LIST });

        yield put({ type: actions.SESSION_CONSTRUCT_PENDING });
        const user = yield call(API.getCurrentUser);
        yield put({ type: actions.SESSION_USER_OBJECT, payload: { user } });

        faro?.api.setUser({
            id : String( user.id ),
            email : user.email, 
            username : user.username
        });

        const roles = yield call(USERS_API.getUserRoles);
        yield put({
            type: usersActions.USERS_ROLES_LIST_FULFILLED,
            payload: roles
        });

        // For superadmin and partner role, we need to show the dashboards based without RBAC
        // for each clients, so if the clientId is of superadmin, then
        // get the list of all the dashboards.
        const { clientId, role, preferredDashboardClientId, id } = user;
        const isSuperadminOrParter =
            role === ROLE_SUPERADMIN || role === ROLE_PARTNER_ADMIN;

        const fetchClientId = isSuperadminOrParter || role === ROLE_PARTNER_USER
            ? preferredDashboardClientId
            : clientId;

        const dashboards = yield call(
            DASHBOARD_API.listDashboardsByClientId,
            fetchClientId,
            isSuperadminOrParter
        );
        yield put({
            type: dashboardActions.DASHBOARD_LIST_BY_CLIENT_ID_FULFILLED,
            payload: dashboards
        });

        if (role !== ROLE_SUPERADMIN) {
            // calling client Products for general users
            yield put({
                type: clientActions.CLIENTS_GET_PRODUCTS,
                payload: { clientId: fetchClientId }
            });
        } else {
            yield put({
                type: clientActions.CLIENTS_GET_PRODUCTS_FULFILLED,
                payload: { products: true }
            });
        }

        if (role === ROLE_SUPERADMIN || role === ROLE_PARTNER_ADMIN || role === ROLE_PARTNER_USER) {
            yield put({
                type: dashboardActions.DASHBOARD_SWITCH_LIST_BY_CLIENT_ID,
                payload: { clientId: preferredDashboardClientId }
            });
        }

        // Set pages data in local storage
        const pagesData = fromLocalStorage(`${id}-slicedhealth-table-data-v2`);
        if (!pagesData) {
            let tempPagesData = {};
            for (const key in pagesNames) {
                tempPagesData[key] = {};
            }
            toLocalStorage(`${id}-slicedhealth-table-data-v2`, tempPagesData);
        }

        // Get the list of clients in case of Admin and Partner
        if (role === ROLE_SUPERADMIN || role === ROLE_PARTNER_ADMIN || role === ROLE_PARTNER_USER) {
            const payload = yield call(CLIENT_API.getClientsTiny);
            yield put({
                type: clientActions.CLIENTS_LIST_TINY_FULFILLED,
                payload
            });
        }

        // Get the list of Search Criteria Types
        const searchCriteriaTypes = yield call(SEARCH_API.getSearchCriteriaTypes);
        yield put({
            type: searchActions.SEARCH_CRITERIA_TYPES_FULFILLED,
            payload: searchCriteriaTypes
        });

        yield put({ type: settingsAction.RELEASE_VERSION });

        // Register for GA
        if (process.env.NODE_ENV === ENV_PRODUCTION) {
            ReactGA.set({
                userId: `${user.clientId} - ${decodeEmail(user.username)}`
            });
        }
        yield put({ type: actions.SESSION_CONSTRUCT_FULFILLED });
        startRefreshTimer(user?.lastLoggedIn);
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.SESSION_CONSTRUCT_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error, true);
    }
}

export function* refreshToken(action) {
    try {
        const authToken = fromLocalStorage("authToken", null);
        if (authToken) {
            yield put({ type: actions.SESSION_REFRESH_TOKEN_PENDING });
            const payload = yield call(API.refreshToken, authToken);
            const { token } = payload;

            yield toLocalStorage("authToken", token);
            toLocalStorage('tokenGenerationTime', new Date());
            yield put({ type: actions.SESSION_REFRESH_TOKEN_FULFILLED });
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.SESSION_REFRESH_TOKEN_REJECTED,
            payload: errorMessage
        });
    }
}

export function* reset() {
    // Clear other stuff from store.
    yield toLocalStorage("authToken", null);

    try {
        yield all([
            call(clientsReset),
            call(usersReset),
            call(rolesReset),
            call(dashboardReset),
            call(dashboardDataSourcesReset),
            call(dashboardFiltersReset),
            call(dashboardTypesReset)
        ]);
    } catch (error) {
        throw error;
    }
}

function* deconstruct() {
    //TODO clear up the session.
    yield call(reset);
    clearRefreshTimer();
}

function* logout(action) {
    //const { manual } = action.payload;
    removeAllToasts();
    yield put({ type: actions.SESSION_DECONSTRUCT });
    faro?.api.resetUser();
}

function* acceptEula(action) {
    try {
        yield put({ type: actions.SESSION_ACCEPT_EULA_PENDING });
        const { callback } = action.payload;
        const user = yield call(API.acceptEula);
        yield put({ type: actions.SESSION_ACCEPT_EULA_FULFILLED, user });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.SESSION_ACCEPT_EULA_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* changePassword(action) {
    const { oldPassword, newPassword, callback } = action.payload;
    try {
        yield put({ type: actions.SESSION_CHANGE_PASSWORD_PENDING });
        const payload = yield call(
            API.changePassword,
            oldPassword,
            newPassword
        );
        yield put({ type: actions.SESSION_CHANGE_PASSWORD_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.SESSION_CHANGE_PASSWORD_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

export function* sessionErrorHandling(error, hideToast) {
    if (error && error.response) {
        const { response } = error;
        // setting error in session reducer for toast
        const { error: errorMessage, innerException, errorDetails } = (error &&
            error.payload) || {
            error: "",
            errorDetails: "",
            innerException: ""
        };
        if (!hideToast && errorMessage && response.status !== 401) {
            errorToastify(
                <ToastMessage
                    errorMessage={errorMessage}
                    innerException={innerException}
                    errorDetails={errorDetails}
                />
            );
        }

        if (response.status === 401) {
            const constructPending = yield select(
                state => state.session.construct.pending
            );
            if (constructPending) {
                yield put({
                    type: actions.SESSION_CONSTRUCT_REJECTED,
                    payload: error
                });
            }
            yield put({
                type: actions.SESSION_LOGOUT,
                payload: { manual: false }
            });
            return;
        }
        return response.status;
    }
}

function* forcedResetPassword(action) {
    const { oldPassword, newPassword, callback } = action.payload;
    try {
        yield put({ type: actions.SESSION_FORCED_RESET_PASSWORD_PENDING });
        const payload = yield call(
            API.changePassword,
            oldPassword,
            newPassword
        );
        yield put({
            type: actions.SESSION_FORCED_RESET_PASSWORD_FULFILLED,
            payload
        });
        yield put({ type: actions.SESSION_CONSTRUCT, payload: {} });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.SESSION_FORCED_RESET_PASSWORD_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.SESSION_LOGIN, login);
    yield takeEvery(actions.SESSION_CONSTRUCT, construct);
    yield takeEvery(actions.SESSION_DECONSTRUCT, deconstruct);
    yield takeEvery(actions.SESSION_LOGOUT, logout);
    yield takeEvery(actions.SESSION_ACCEPT_EULA, acceptEula);
    yield takeEvery(actions.SESSION_CHANGE_PASSWORD, changePassword);
    yield takeEvery(actions.SESSION_REFRESH_TOKEN, refreshToken);
    yield takeEvery(actions.SESSION_FORCED_RESET_PASSWORD, forcedResetPassword);
}
