export const PRICERS_LOOKUP = 'PRICERS_LOOKUP';
export const PRICERS_LOOKUP_PENDING = 'PRICERS_LOOKUP_PENDING';
export const PRICERS_LOOKUP_FULFILLED = 'PRICERS_LOOKUP_FULFILLED';
export const PRICERS_LOOKUP_REJECTED = 'PRICERS_LOOKUP_REJECTED';

export const PRICERS_GET_CHARGES = 'PRICERS_GET_CHARGES';
export const PRICERS_GET_CHARGES_PENDING = 'PRICERS_GET_CHARGES_PENDING';
export const PRICERS_GET_CHARGES_FULFILLED = 'PRICERS_GET_CHARGES_FULFILLED';
export const PRICERS_GET_CHARGES_REJECTED = 'PRICERS_GET_CHARGES_REJECTED';

export const PRICERS_GET_ALLOWABLES = 'PRICERS_GET_ALLOWABLES';
export const PRICERS_GET_ALLOWABLES_PENDING = 'PRICERS_GET_ALLOWABLES_PENDING';
export const PRICERS_GET_ALLOWABLES_FULFILLED = 'PRICERS_GET_ALLOWABLES_FULFILLED';
export const PRICERS_GET_ALLOWABLES_REJECTED = 'PRICERS_GET_ALLOWABLES_REJECTED';

export const PRICERS_LOOKUP_V2 = 'PRICERS_LOOKUP_V2';
export const PRICERS_LOOKUP_V2_PENDING = 'PRICERS_LOOKUP_V2_PENDING';
export const PRICERS_LOOKUP_V2_FULFILLED = 'PRICERS_LOOKUP_V2_FULFILLED';
export const PRICERS_LOOKUP_V2_REJECTED = 'PRICERS_LOOKUP_V2_REJECTED';

export const PRICERS_GET_FACILITIES_V2 = 'PRICERS_GET_FACILITIES_V2';
export const PRICERS_GET_FACILITIES_V2_PENDING = 'PRICERS_GET_FACILITIES_V2_PENDING';
export const PRICERS_GET_FACILITIES_V2_FULFILLED = 'PRICERS_GET_FACILITIES_V2_FULFILLED';
export const PRICERS_GET_FACILITIES_V2_REJECTED = 'PRICERS_GET_FACILITIES_V2_REJECTED';

export const PRICERS_GET_DEPARTMENTS_V2 = 'PRICERS_GET_DEPARTMENTS_V2';
export const PRICERS_GET_DEPARTMENTS_V2_PENDING = 'PRICERS_GET_DEPARTMENTS_V2_PENDING';
export const PRICERS_GET_DEPARTMENTS_V2_FULFILLED = 'PRICERS_GET_DEPARTMENTS_V2_FULFILLED';
export const PRICERS_GET_DEPARTMENTS_V2_REJECTED = 'PRICERS_GET_DEPARTMENTS_V2_REJECTED';

export const PRICERS_GET_NEGOTIATED_RATES_V2 = 'PRICERS_GET_NEGOTIATED_RATES_V2';
export const PRICERS_GET_NEGOTIATED_RATES_V2_PENDING = 'PRICERS_GET_NEGOTIATED_RATES_V2_PENDING';
export const PRICERS_GET_NEGOTIATED_RATES_V2_FULFILLED = 'PRICERS_GET_NEGOTIATED_RATES_V2_FULFILLED';
export const PRICERS_GET_NEGOTIATED_RATES_V2_REJECTED = 'PRICERS_GET_NEGOTIATED_RATES_V2_REJECTED';

export const PRICERS_GET_ALOWABLES_V2 = 'PRICERS_GET_ALOWABLES_V2';
export const PRICERS_GET_ALOWABLES_V2_PENDING = 'PRICERS_GET_ALOWABLES_V2_PENDING';
export const PRICERS_GET_ALOWABLES_V2_FULFILLED = 'PRICERS_GET_ALOWABLES_V2_FULFILLED';
export const PRICERS_GET_ALOWABLES_V2_REJECTED = 'PRICERS_GET_ALOWABLES_V2_REJECTED';

export const PRICERS_GET_SELF_PAY_PERCENTAGE = 'PRICERS_GET_SELF_PAY_PERCENTAGE';
export const PRICERS_GET_SELF_PAY_PERCENTAGE_PENDING = 'PRICERS_GET_SELF_PAY_PERCENTAGE_PENDING';
export const PRICERS_GET_SELF_PAY_PERCENTAGE_FULFILLED = 'PRICERS_GET_SELF_PAY_PERCENTAGE_FULFILLED';
export const PRICERS_GET_SELF_PAY_PERCENTAGE_REJECTED = 'PRICERS_GET_SELF_PAY_PERCENTAGE_REJECTED';

export const PRICERS_EXPORT_CSV = 'PRICERS_EXPORT_CSV';
export const PRICERS_EXPORT_CSV_PENDING = 'PRICERS_EXPORT_CSV_PENDING';
export const PRICERS_EXPORT_CSV_FULFILLED = 'PRICERS_EXPORT_CSV_FULFILLED';
export const PRICERS_EXPORT_CSV_REJECTED = 'PRICERS_EXPORT_CSV_REJECTED';

export const PRICERS_GET_OOP = 'PRICERS_GET_OOP';
export const PRICERS_GET_OOP_PENDING = 'PRICERS_GET_OOP_PENDING';
export const PRICERS_GET_OOP_FULFILLED = 'PRICERS_GET_OOP_FULFILLED';
export const PRICERS_GET_OOP_REJECTED = 'PRICERS_GET_OOP_REJECTED';

export const PRICERS_ADD_BOOKING = 'PRICERS_ADD_BOOKING';
export const PRICERS_ADD_BOOKING_PENDING = 'PRICERS_ADD_BOOKING_PENDING';
export const PRICERS_ADD_BOOKING_FULFILLED = 'PRICERS_ADD_BOOKING_FULFILLED';
export const PRICERS_ADD_BOOKING_REJECTED = 'PRICERS_ADD_BOOKING_REJECTED';

export const PRICERS_ERROR_RESET = 'PRICERS_ERROR_RESET';
export const PRICERS_RESET = 'PRICERS_RESET';
export const PRICERS_OOP_RESET = 'PRICERS_OOP_RESET';

export const PRICER_GET_CHARGE_MASTER_STATS = 'PRICER_GET_CHARGE_MASTER_STATS';
export const PRICER_GET_CHARGE_MASTER_STATS_PENDING = 'PRICER_GET_CHARGE_MASTER_STATS_PENDING';
export const PRICER_GET_CHARGE_MASTER_STATS_FULFILLED = 'PRICER_GET_CHARGE_MASTER_STATS_FULFILLED';
export const PRICER_GET_CHARGE_MASTER_STATS_REJECTED = 'PRICER_GET_CHARGE_MASTER_STATS_REJECTED';

export const PRICER_GET_SHOPPABLES_STATS = 'PRICER_GET_SHOPPABLES_STATS';
export const PRICER_GET_SHOPPABLES_STATS_PENDING = 'PRICER_GET_SHOPPABLES_STATS_PENDING';
export const PRICER_GET_SHOPPABLES_STATS_FULFILLED = 'PRICER_GET_SHOPPABLES_STATS_FULFILLED';
export const PRICER_GET_SHOPPABLES_STATS_REJECTED = 'PRICER_GET_SHOPPABLES_STATS_REJECTED';

export const PRICER_UPDATE_SHOPPABLES_CONFIG = 'PRICER_UPDATE_SHOPPABLES_CONFIG';
export const PRICER_UPDATE_SHOPPABLES_CONFIG_PENDING = 'PRICER_UPDATE_SHOPPABLES_CONFIG_PENDING';
export const PRICER_UPDATE_SHOPPABLES_CONFIG_FULFILLED = 'PRICER_UPDATE_SHOPPABLES_CONFIG_FULFILLED';
export const PRICER_UPDATE_SHOPPABLES_CONFIG_REJECTED = 'PRICER_UPDATE_SHOPPABLES_CONFIG_REJECTED';

export const PRICER_CONFIG_GET_CONTRACTS = 'PRICER_CONFIG_GET_CONTRACTS';
export const PRICER_CONFIG_GET_CONTRACTS_PENDING = 'PRICER_CONFIG_GET_CONTRACTS_PENDING';
export const PRICER_CONFIG_GET_CONTRACTS_FULFILLED = 'PRICER_CONFIG_GET_CONTRACTS_FULFILLED';
export const PRICER_CONFIG_GET_CONTRACTS_REJECTED = 'PRICER_CONFIG_GET_CONTRACTS_REJECTED';

export const PRICER_CONFIG_PROCESS_CONTRACTS = 'PRICER_CONFIG_PROCESS_CONTRACTS';
export const PRICER_CONFIG_PROCESS_CONTRACTS_PENDING = 'PRICER_CONFIG_PROCESS_CONTRACTS_PENDING';
export const PRICER_CONFIG_PROCESS_CONTRACTS_FULFILLED = 'PRICER_CONFIG_PROCESS_CONTRACTS_FULFILLED';
export const PRICER_CONFIG_PROCESS_CONTRACTS_REJECTED = 'PRICER_CONFIG_PROCESS_CONTRACTS_REJECTED';

export const PRICER_CONFIG_GET_RATES = 'PRICER_CONFIG_GET_RATES';
export const PRICER_CONFIG_GET_RATES_PENDING = 'PRICER_CONFIG_GET_RATES_PENDING';
export const PRICER_CONFIG_GET_RATES_FULFILLED = 'PRICER_CONFIG_GET_RATES_FULFILLED';
export const PRICER_CONFIG_GET_RATES_REJECTED = 'PRICER_CONFIG_GET_RATES_REJECTED';

export const PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE = 'PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE';
export const PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_PENDING = 'PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_PENDING';
export const PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_FULFILLED = 'PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_FULFILLED';
export const PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_REJECTED = 'PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_REJECTED';

export const PRICER_CONFIG_CLEANUP_PROCESS = 'PRICER_CONFIG_CLEANUP_PROCESS';
export const PRICER_CONFIG_CLEANUP_PROCESS_PENDING = 'PRICER_CONFIG_CLEANUP_PROCESS_PENDING';
export const PRICER_CONFIG_CLEANUP_PROCESS_FULFILLED = 'PRICER_CONFIG_CLEANUP_PROCESS_FULFILLED';
export const PRICER_CONFIG_CLEANUP_PROCESS_REJECTED = 'PRICER_CONFIG_CLEANUP_PROCESS_REJECTED';

export const PRICER_CONFIG_PUBLISH_MRF = 'PRICER_CONFIG_PUBLISH_MRF';
export const PRICER_CONFIG_PUBLISH_MRF_PENDING = 'PRICER_CONFIG_PUBLISH_MRF_PENDING';
export const PRICER_CONFIG_PUBLISH_MRF_FULFILLED = 'PRICER_CONFIG_PUBLISH_MRF_FULFILLED';
export const PRICER_CONFIG_PUBLISH_MRF_REJECTED = 'PRICER_CONFIG_PUBLISH_MRF_REJECTED';

export const PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF = 'PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF';
export const PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_PENDING = 'PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_PENDING';
export const PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_FULFILLED = 'PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_FULFILLED';
export const PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_REJECTED = 'PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_REJECTED';

export const PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF = 'PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF';
export const PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_PENDING = 'PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_PENDING';
export const PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_FULFILLED = 'PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_FULFILLED';
export const PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_REJECTED = 'PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_REJECTED';

export const PRICER_CONFIG_SET_FROM_SHOPPABLES = 'PRICER_CONFIG_SET_FROM_SHOPPABLES';

export const PRICER_CONFIG_CLEAR_RATES = 'PRICER_CONFIG_CLEAR_RATES';

export const lookup = (keyword, callback) => ({
    type: PRICERS_LOOKUP,
    payload: { keyword, callback },
});

export const getCharges = (chargePayload, callback) => ({
    type: PRICERS_GET_CHARGES,
    payload: { chargePayload, callback },
});

export const getAllowables = (chargeCode, callback) => ({
    type: PRICERS_GET_ALLOWABLES,
    payload: { chargeCode, callback },
});

export const lookupV2 = (keyword, callback) => ({
    type: PRICERS_LOOKUP_V2,
    payload: { keyword, callback },
});

export const getFacilitiesV2 = (brandId, callback) => ({
    type: PRICERS_GET_FACILITIES_V2,
    payload: { brandId, callback },
});

export const getDepartmentsV2 = (departmentPayload, callback) => ({
    type: PRICERS_GET_DEPARTMENTS_V2,
    payload: { departmentPayload, callback },
});

export const getNegotiatedRatesV2 = (negotiatedRatesPaylaod, callback) => ({
    type: PRICERS_GET_NEGOTIATED_RATES_V2,
    payload: { negotiatedRatesPaylaod, callback },
});

export const getAllowablesV2 = (allowablePayload, callback) => ({
    type: PRICERS_GET_ALOWABLES_V2,
    payload: { allowablePayload, callback },
});

export const getPayPercentage = (brandId, callback) => ({
    type: PRICERS_GET_SELF_PAY_PERCENTAGE,
    payload: { brandId, callback },
});

export const exportCsv = (callback) => ({
    type: PRICERS_EXPORT_CSV,
    payload: { callback }
});

export const getOop = (oopPayload, callback) => ({
    type: PRICERS_GET_OOP,
    payload: { oopPayload, callback }
});

export const addBooking = (bookingPayload, callback) => ({
    type: PRICERS_ADD_BOOKING,
    payload: { bookingPayload, callback },
});

export const errorReset = () => ({
    type: PRICERS_ERROR_RESET,
});

export const resetOOP = () => ({
    type: PRICERS_OOP_RESET,
});

export const resetPricerFilter = () => ({
    type: PRICERS_RESET,
});

export const getChargeMasterStats = () => ({
    type: PRICER_GET_CHARGE_MASTER_STATS
});

export const getShoppablesStats = () => ({
    type: PRICER_GET_SHOPPABLES_STATS
});

export const updateShoopableConfiguration = (callback) => ({
    type: PRICER_UPDATE_SHOPPABLES_CONFIG,
    payload: { callback }
});

export const getPricerConfigContracts = () => ({
    type: PRICER_CONFIG_GET_CONTRACTS
});

export const processContracts = (options, callback) => ({
    type: PRICER_CONFIG_PROCESS_CONTRACTS,
    payload: { options, callback }
});

export const getConfigNegotiatedRates = (options, callback) => ({
    type: PRICER_CONFIG_GET_RATES,
    payload: { options, callback }
});

export const clearRates = () => ({
    type: PRICER_CONFIG_CLEAR_RATES
});

export const getNegotiatedRatesState = (callback) => ({
    type: PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE,
    payload: { callback }
});

export const cleanupAndProcess = (callback) => ({
    type: PRICER_CONFIG_CLEANUP_PROCESS,
    payload: { callback }
});

export const setConfigFromShoppables = (pricerConfigFromShoppables) => ({
    type: PRICER_CONFIG_SET_FROM_SHOPPABLES,
    payload: { pricerConfigFromShoppables }
});

export const publishMRFs = (callback) => ({
    type: PRICER_CONFIG_PUBLISH_MRF,
    payload: { callback }
});

export const getNegotiatedRatesSignOff = () => ({
    type: PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF,
});

export const negotiatedRatesSignOff = (callback) => ({
    type: PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF,
    payload: {
        callback
    }
});
