import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { set } from '../../../../store/actions/pages';

import { clearRates, getConfigNegotiatedRates } from '../../../../store/actions/pricers';
import { integerEquators } from '../../../../store/constants/pages';
import { createExceptionHoverField, createNumberDataField, createStringFieldFromObjectMapping } from '../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import BookingDialogComponent from './StepFour';

const DEFAULT_SORT = [{ id: 'displayName', direction: 'asc' }];

export const selectPricerConfigRates = (state) => state.pricers.rates ?? [];

// Create Object for from contracts array for column mapping
export const selectPricerConfigContractsObj = (state) => {
    const contracts = state.pricers.contracts ?? [];
    return contracts.reduce((a, v) => ({ ...a, [v.id]: v.displayName }), {})
}

const selectFields = createSelector(selectPricerConfigContractsObj,
    (contractsObj) => [
        createStringFieldFromObjectMapping('payerContractId', 'Contract', contractsObj, { hideFromFilter: true }),
        createNumberDataField('totalNegotiatedRates', 'Negotiated Rates', { sortable: true, equators: integerEquators, whiteSpace: 'break-spaces' }),
        createExceptionHoverField('success', 'Status', true, { hideFromFilter: true, sortable: true, returnWholeRow: true, }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`pricer-config-rates-landing`] ? state.pages[`pricer-config-rates-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`pricer-config-rates-landing`] ? state.pages[`pricer-config-rates-landing`].filter : null,
    selectSort,
    selectPricerConfigRates,
);

const selectSelectedItems = createSelector(
    (state) => state.pages['pricer-config-rates-landing'].selected,
    (data) => {
        return data
    }
);

export const StepFour = connect(
    () => {

        return (state, props) => ({

            order: state.pages['pricer-config-rates-landing'] ? state.pages['pricer-config-rates-landing'].order : null,
            filter: state.pages['pricer-config-rates-landing'] ? state.pages['pricer-config-rates-landing'].filter : null,
            sort: selectSort(state, props),

            ratesFields: selectFields(state, props),
            ratesData: selectFilteredData(state, props),
            getRatesPending: state.pricers.getConfigRates.pending,

            pricerConfigFromShoppables: state.pricers.pricerConfigFromShoppables
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setFilter: (value) => set(`pricer-config-rates-landing`, 'filter', value),
            setOrder: (value) => set(`pricer-config-rates-landing`, 'order', value),
            setSort: (value) => set(`pricer-config-rates-landing`, 'sort', value),

            getConfigNegotiatedRates: getConfigNegotiatedRates,
            clearRates: clearRates
        }, dispatch)
    })
)(injectIntl(BookingDialogComponent));