import { Button, makeStyles, Menu } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: 26,
        width: 26,
        textDecoration: 'none'
    }
}));


function EllipsisMenu({ renderMenuItems } :{
    renderMenuItems: React.ReactNode[]
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                className={classes.button}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                ...
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        width: 80,
                        minWidth: 80,
                        marginTop: 40,
                        marginLeft: 50
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {renderMenuItems}
            </Menu>
        </div>
    )
}
export default EllipsisMenu
