import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/feeScheduleV2';
import * as API from '../api/feeScheduleV2';
import { sessionErrorHandling } from './session';

function* getFeeSchedules(action) {
    try {
        const { directoryId } = action.payload;
        yield put({ type: actions.FEE_SCHEDULES_GET_PENDING });
        const payload = yield call(API.getFeeSchedules, directoryId);
        yield put({ type: actions.FEE_SCHEDULES_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULES_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* createDirectory(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.FEE_SCHEDULES_CREATE_DIRECTORY_PENDING });
        const payload = yield call(API.createDirectory, options);
        yield put({ type: actions.FEE_SCHEDULES_CREATE_DIRECTORY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULES_CREATE_DIRECTORY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateDirectory(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.FEE_SCHEDULES_UPDATE_DIRECTORY_PENDING });
        const payload = yield call(API.updateDirectory, options);
        yield put({ type: actions.FEE_SCHEDULES_UPDATE_DIRECTORY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULES_UPDATE_DIRECTORY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* moveDirectoryOrFeeSchedule(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.FEE_SCHEDULES_MOVE_PENDING });
        const payload = yield call(API.moveDirectoryOrFeeSchedule, options);
        yield put({ type: actions.FEE_SCHEDULES_MOVE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULES_MOVE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHierarchy(action) {
    try {
        yield put({ type: actions.FEE_SCHEDULES_HIERARCHY_PENDING });
        const payload = yield call(API.getHeirarchy);
        yield put({ type: actions.FEE_SCHEDULES_HIERARCHY_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULES_HIERARCHY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeeScheduleDirectoryDependencies(action) {
    try {
        const { directoryId } = action.payload;
        yield put({ type: actions.FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getFeeScheduleDirectoryDependencies, directoryId);
        yield put({ type: actions.FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteFeeScheduleDirectory(action) {
    try {
        yield put({ type: actions.FEE_SCHEDULES_DIRECTORY_DELETE_PENDING });
        const {
            directoryId,
            callback,
        } = action.payload;
        yield call(API.deleteFeeScheduleDirectory, directoryId);
        yield put({ type: actions.FEE_SCHEDULES_DIRECTORY_DELETE_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULES_DIRECTORY_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* feeScheduleSearch(action) {
    try {
        const { options } = action.payload;
        yield put({ type: actions.FEE_SCHEDULES_SEARCH_PENDING });
        const payload = yield call(API.searchFeeSchedules, options);
        yield put({ type: actions.FEE_SCHEDULES_SEARCH_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULES_SEARCH_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeeSchedulesFromClient(action) {
    try {
        const { targetClient } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_SEARCH_FROM_CLIENT_PENDING });
        const payload = yield call(API.searchFeeSchedulesFromClient,targetClient, targetClient?.targetClient);
        yield put({ type: actions.FEE_SCHEDULE_SEARCH_FROM_CLIENT_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_SEARCH_FROM_CLIENT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}
function* getFeeSchedulesLabelsFromTargetClient(action) {
    try {
        const { targetClient,feeScheduleId,callback } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_PENDING });
        const payload = yield call(API.getFeeSchedulesLabelsFromTargetClient,targetClient,feeScheduleId);
        yield put({ type: actions.FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_FULFILLED, payload });
        if (callback) {
            callback(payload,feeScheduleId);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}
function* validateFeeSchedulesLabelsFromTargetClient(action) {

    try {
        const {targetClient,feeScheduleId,labelId,index,callback } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_LABELS_VALIDATE_PENDING,payload: { index: index } });
        const payload = yield call(API.validateFeeSchedulesLabelsFromTargetClient,targetClient,feeScheduleId,labelId);
        yield put({ type: actions.FEE_SCHEDULE_LABELS_VALIDATE_FULFILLED, payload: { error: payload, index: index }
        });
        if (callback) {
            callback(payload,feeScheduleId);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_LABELS_VALIDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.FEE_SCHEDULES_GET, getFeeSchedules);
    yield takeEvery(actions.FEE_SCHEDULES_CREATE_DIRECTORY, createDirectory);
    yield takeEvery(actions.FEE_SCHEDULES_UPDATE_DIRECTORY, updateDirectory);
    yield takeEvery(actions.FEE_SCHEDULES_MOVE, moveDirectoryOrFeeSchedule);
    yield takeEvery(actions.FEE_SCHEDULES_HIERARCHY, getHierarchy);
    yield takeEvery(actions.FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES, getFeeScheduleDirectoryDependencies);
    yield takeEvery(actions.FEE_SCHEDULES_DIRECTORY_DELETE, deleteFeeScheduleDirectory);
    yield takeEvery(actions.FEE_SCHEDULES_SEARCH, feeScheduleSearch);
    yield takeEvery(actions.FEE_SCHEDULE_SEARCH_FROM_CLIENT, getFeeSchedulesFromClient);
    yield takeEvery(actions.FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET, getFeeSchedulesLabelsFromTargetClient);
    yield takeEvery(actions.FEE_SCHEDULE_LABELS_VALIDATE, validateFeeSchedulesLabelsFromTargetClient);

}