import { FieldArray } from "formik";
import React from "react";

import {
    Button, FormControl, FormHelperText,
    Grid,
    IconButton, Input, InputAdornment, InputLabel,
    makeStyles, MenuItem, Select, TextField
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";
import { PopoverLink } from "../../../../common";
import { RULE_CRITERIA_DATE, RULE_CRITERIA_INT, RULE_CRITERIA_STRING } from "../../../../store/constants/contract";
import { alphabets, dateEquators, FIELD_TYPE_DATE, integerEquators, StringEquators } from "../../../../store/constants/pages";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl: {
        width: '85%',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    autoComplete: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    input: {
        marginTop: theme.spacing(4),
        //marginLeft: theme.spacing(2),
        width: '90%',
    },
    helpClass: {
        position: 'relative',
        top: 10,
        marginLeft: -12,
    },
}));

export default function ContractSelectionCriteriaFieldArray({
    values, handleBlur, handleChange, touched, errors, contractCriteria, setFieldValue
}) {
    const classes = useStyles();

    function getHelperText(key, index) {
        if (errors.contractSelectionCriteriaXref) {
            if (errors.contractSelectionCriteriaXref[index]) {
                return errors.contractSelectionCriteriaXref[index][key];
            }
            return false;
        }
        return false;
    }

    function getError(key, index) {
        if (touched.contractSelectionCriteriaXref) {
            if (touched.contractSelectionCriteriaXref[index]) {
                return touched.contractSelectionCriteriaXref[index][key];
            }
            return false;
        }
        return false;
    }

    function handleCriteriaChangeHandler(index, criteriaObj) {
        setFieldValue(`contractSelectionCriteriaXref.${index}.contractSelectionCriteriaId`, criteriaObj);
        setFieldValue(`contractSelectionCriteriaXref.${index}.type`, criteriaObj?.fieldType); // criteria Type i.e. INT, DATE, STRING
        setFieldValue(`contractSelectionCriteriaXref.${index}.equator`, '');
        setFieldValue(`contractSelectionCriteriaXref.${index}.value`, '');
    }

    function handleEquatorChangeHandler(e, index) {
        setFieldValue(`contractSelectionCriteriaXref.${index}.equator`, e.target.value);
        if (e.target.value === 'alpha_between' || e.target.value === 'in_between') {
            setFieldValue(`contractSelectionCriteriaXref.${index}.isBetween`, true);
        } else {
            setFieldValue(`contractSelectionCriteriaXref.${index}.isBetween`, false);
        }
    }

    function getEquators(fieldType) {
        switch (fieldType) {
            case RULE_CRITERIA_STRING:
                return StringEquators.equatorArr
            case RULE_CRITERIA_DATE:
                return dateEquators.equatorArr
            case RULE_CRITERIA_INT:
                return integerEquators.equatorArr
            default:
                return StringEquators.equatorArr
        }
    }

    function handleClearFields() {
        setFieldValue(`contractSelectionCriteriaXref`, [{
            contractSelectionCriteriaId: null,
            equator: '',
            value: ''
        }]);
    }

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`fas fa-question-circle text-info`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    return (
        <FieldArray
            name="contractSelectionCriteriaXref"
            render={({ remove, insert }) => (
                <Grid container>
                    <Grid item xs={12}>
                        {values.contractSelectionCriteriaXref.map((selectionCriteria, index) =>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        className={classes.autoComplete}
                                        value={selectionCriteria.contractSelectionCriteriaId}
                                        disableClearable={false}
                                        onChange={(e, value) => { handleCriteriaChangeHandler(index, value) }}
                                        getOptionSelected={(option, value) => option === value}
                                        options={contractCriteria}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                name={`contractSelectionCriteriaXref.${index}.contractSelectionCriteriaId`}
                                                label="Criteria"
                                                margin="normal" />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor={`contractSelectionCriteriaXref.${index}.equator`}>Equator</InputLabel>
                                        <Select
                                            required
                                            value={selectionCriteria?.equator}
                                            onChange={e => handleEquatorChangeHandler(e, index)}
                                            disabled={!selectionCriteria?.contractSelectionCriteriaId}
                                            input={<Input id={`contractSelectionCriteriaXref.${index}.equator`} />}
                                            inputProps={{
                                                name: `contractSelectionCriteriaXref.${index}.equator`,
                                                id: `contractSelectionCriteriaXref.${index}.equator`,
                                            }}
                                            error={Boolean(getHelperText('equator', index) && getError('equator', index))}
                                        >
                                            {getEquators(selectionCriteria?.type).map(equator => (
                                                <MenuItem
                                                    key={equator.value}
                                                    value={equator.value}
                                                >
                                                    {equator.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error>{getHelperText('equator', index) && getError('equator', index)}</FormHelperText>

                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    {selectionCriteria?.equator === 'alpha_between' || selectionCriteria?.equator === 'in_between' ?
                                        <Grid container>
                                            {selectionCriteria?.type === 'int' ?
                                                <>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            type='number'
                                                            name={`contractSelectionCriteriaXref.${index}.startsAt`}
                                                            className={classes.input}
                                                            placeholder="Starts at"
                                                            color="secondary"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={selectionCriteria.startsAt}
                                                            helperText={Boolean(getHelperText('startsAt', index) && getError('startsAt', index))}
                                                            error={Boolean(getHelperText('startsAt', index) && getError('startsAt', index))}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            type='number'
                                                            name={`contractSelectionCriteriaXref.${index}.endsAt`}
                                                            className={classes.input}
                                                            placeholder="Ends at"
                                                            color="secondary"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={selectionCriteria.endsAt}
                                                            error={Boolean(getHelperText('endsAt', index) && getError('endsAt', index))}
                                                            helperText={Boolean(getHelperText('endsAt', index) && getError('endsAt', index))}

                                                        />
                                                    </Grid>
                                                </>
                                                : selectionCriteria?.type === FIELD_TYPE_DATE ?
                                                    <>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                type='date'
                                                                name={`contractSelectionCriteriaXref.${index}.startsAt`}
                                                                className={classes.input}
                                                                margin="normal"
                                                                placeholder="Starts at"
                                                                color="secondary"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={selectionCriteria.startsAt}
                                                                helperText={Boolean(getHelperText('startsAt', index) && getError('startsAt', index))}
                                                                error={Boolean(getHelperText('startsAt', index) && getError('startsAt', index))}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                type='date'
                                                                name={`contractSelectionCriteriaXref.${index}.endsAt`}
                                                                className={classes.input}
                                                                margin="normal"
                                                                placeholder="Ends at"
                                                                color="secondary"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={selectionCriteria.endsAt}
                                                                helperText={Boolean(getHelperText('endsAt', index) && getError('endsAt', index))}
                                                                error={Boolean(getHelperText('endsAt', index) && getError('endsAt', index))}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </> :
                                                    <>
                                                        <Grid item xs={6}>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel htmlFor="startsAt">Starts At</InputLabel>
                                                                <Select
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={selectionCriteria.startsAt}
                                                                    error={Boolean(getHelperText('startsAt', index) && getError('startsAt', index))}
                                                                    inputProps={{
                                                                        name: `contractSelectionCriteriaXref.${index}.startsAt`,
                                                                        id: "startsAt"
                                                                    }}
                                                                >
                                                                    {alphabets.map((item, index) => (
                                                                        <MenuItem
                                                                            key={`${index}-${item}`}
                                                                            value={item.toLowerCase()}
                                                                        >
                                                                            {item}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <FormHelperText error>{getHelperText('startsAt', index) && getError('startsAt', index)}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel htmlFor="endsAt">Ends At</InputLabel>
                                                                <Select
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={selectionCriteria.endsAt}
                                                                    error={Boolean(getHelperText('endsAt', index) && getError('endsAt', index))}
                                                                    inputProps={{
                                                                        name: `contractSelectionCriteriaXref.${index}.endsAt`,
                                                                        id: "endsAt"
                                                                    }}
                                                                >
                                                                    {alphabets.map((item, index) => (
                                                                        <MenuItem
                                                                            key={`${index}-${item}`}
                                                                            value={item.toLowerCase()}
                                                                        >
                                                                            {item}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <FormHelperText error>{getHelperText('endsAt', index) && getError('endsAt', index)}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                            }
                                        </Grid>
                                        :
                                        <TextField
                                            label="Value"
                                            name={`contractSelectionCriteriaXref.${index}.value`}
                                            className={classes.textField}
                                            margin="normal"
                                            disabled={selectionCriteria?.equator === ''}
                                            type={selectionCriteria?.type === RULE_CRITERIA_INT ? 'number' : selectionCriteria.type === RULE_CRITERIA_DATE ? 'date' : 'text'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={selectionCriteria?.value}
                                            InputLabelProps={selectionCriteria.type === RULE_CRITERIA_DATE ?
                                                {
                                                    shrink: true,
                                                } : {

                                                }
                                            }
                                            helperText={getError('value', index) ? getHelperText('value', index) : ''}
                                            error={Boolean(getHelperText('value', index) && getError('value', index))}

                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <span>
                                                        {hoverColumn(selectionCriteria?.type === RULE_CRITERIA_INT ?
                                                            'Only single numeric value is allowed' :
                                                            'Add single string value or use comma separation for multiple values'
                                                        )}
                                                    </span>
                                                </InputAdornment>
                                            }}
                                        />
                                    }
                                </Grid>
                                {index === 0 && (
                                    <Grid item xs={3}>
                                        <Grid container>
                                            <IconButton
                                                edge="start"
                                                color="primary"
                                                aria-label="Add"
                                                onClick={() => insert(values.contractSelectionCriteriaXref.length, {
                                                    contractSelectionCriteriaId: null,
                                                    equator: '',
                                                    value: ''
                                                })}
                                                className={classes.iconButton}>
                                                <AddCircleIcon />
                                            </IconButton>
                                            <Button
                                                size="small"
                                                onClick={handleClearFields}
                                                className={classes.clearButton}
                                                startIcon={<DeleteIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}

                                {index !== 0 && (
                                    <Grid item xs={1}>
                                        {values.contractSelectionCriteriaXref.length > 1 && (
                                            <IconButton
                                                edge="start"
                                                aria-label="Delete"
                                                onClick={() => remove(index)}
                                                className={classes.iconButton}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        />
    );
}
