import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/search';
import * as pagesAction from '../actions/pages';
import * as API from '../api/search';
import { sessionErrorHandling } from './session';

function* get(action) {
    try {
        yield put({ type: actions.SEARCH_CRITERIAS_GET_BY_NAME_PENDING });
        const { pageName, callback } = action.payload;
        const payload = yield call(API.getCriteriaByPageName, pageName);
        yield put({ type: actions.SEARCH_CRITERIAS_GET_BY_NAME_FULFILLED, payload });
        yield put({ type: pagesAction.PAGES_SET, payload: { page: pageName.name, key: 'isSearchApiCompleted', value: true } });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SEARCH_CRITERIAS_GET_BY_NAME_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.SEARCH_CRITERIAS_CREATE_PENDING });
        const {
            searchCriteria, callback,
        } = action.payload;

        const newCriteria = yield call(API.createSearchCriteria, searchCriteria);
        yield put({ type: actions.SEARCH_CRITERIAS_CREATE_FULFILLED, newCriteria });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SEARCH_CRITERIAS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* list(action) {
    try {
        yield put({ type: actions.SEARCH_CRITERIAS_LIST_PENDING });
        const payload = yield call(API.getSearchCriteriaList);
        yield put({ type: actions.SEARCH_CRITERIAS_LIST_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SEARCH_CRITERIAS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteCriteria(action) {
    try {
        yield put({ type: actions.SEARCH_CRITERIAS_DELETE_PENDING });
        const { id, callback } = action.payload;
        const payload = yield call(API.deleteSearchCriteriaList, id);
        yield put({ type: actions.SEARCH_CRITERIAS_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SEARCH_CRITERIAS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}
function* update(action) {
    try {
        yield put({ type: actions.SEARCH_CRITERIAS_UPDATE_PENDING });
        const {
            searchCriteria, callback,
        } = action.payload;

        const newCriteria = yield call(API.upateSearchCriteria, searchCriteria);
        yield put({ type: actions.SEARCH_CRITERIAS_UPDATE_FULFILLED, newCriteria });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SEARCH_CRITERIAS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getSearchCriteriaTypes(action) {
    try {
        yield put({ type: actions.SEARCH_CRITERIA_TYPES_PENDING });
        const payload = yield call(API.getSearchCriteriaTypes);
        yield put({ type: actions.SEARCH_CRITERIA_TYPES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SEARCH_CRITERIA_TYPES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.SEARCH_CRITERIAS_GET_BY_NAME, get);
    yield takeEvery(actions.SEARCH_CRITERIAS_CREATE, create);
    yield takeEvery(actions.SEARCH_CRITERIAS_LIST, list);
    yield takeEvery(actions.SEARCH_CRITERIAS_DELETE, deleteCriteria);
    yield takeEvery(actions.SEARCH_CRITERIAS_UPDATE, update);
    yield takeEvery(actions.SEARCH_CRITERIA_TYPES, getSearchCriteriaTypes);
}