import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import ClaimSelectionComponent from './ClaimSelection';
import { createCurrencyDataField, createStringDataField } from '../../../../../util/format';
import { StringEquators, integerEquators } from '../../../../../store/constants/pages';
import { tiny } from '../../../../../store/actions/contracts';
import { selectTiny } from '../../../../../store/selectors/contracts';
import { searchClaimsForModeling } from '../../../../../store/actions/modeling';
import { modelClaimSelectionLanding } from '../../../../../store/actions/pages';
import { setV2 } from '../../../../../store/actions/pagesV2';

export const ClaimSelection = connect(
    () => {

        const selectFields = createSelector(
            () => [
                createStringDataField('claimnumber', 'Claim Number', { sortable: true, equators: StringEquators }),
                createCurrencyDataField('claimamount', 'Charge Amount', { equators: integerEquators }),
                createStringDataField('payername', 'Payer name', { equators: StringEquators }),
            ]
        );

        const selectInitialValues = createSelector(
            () => {
                return {
                    modelName: '',
                    baseContract: '',
                    modelContract: ''
                }
            }
        );

        const selectClaimSelectionData = createSelector(
            (state) => state.pages['model-claim-selection-landing'].list,
            (state) => state.modeling.claims,
            (data, response) => {
                if (data.length === 0) {
                    return response?.results?.map(item => ({ ...item, id: item.claimnumber })) ?? [];
                }
                return data.map(item => ({ ...item, id: item.claimnumber }))
            }
        );

        const selectSortedTinyContracts = createSelector(selectTiny,
            (contracts) => {
                return contracts.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        const selectModel = createSelector(
            (state) => state.modeling.model,
            (state, props) => props.modelId,
            (model, modelId) => {
                if (model && modelId && model.id === parseInt(modelId)) {
                    return model;
                }
                return null;
            }
        );

        return (state, props) => ({
            fields: selectFields(state, props),
            claimSelectionData: selectClaimSelectionData(state, props),
            initialValues: selectInitialValues(state, props),
            tinyContracts: selectSortedTinyContracts(state),
            filter: state.pages['model-claim-selection-landing'].filter,
            sort: state.pages['model-claim-selection-landing'].sort,
            page: state.pages['model-claim-selection-landing'].page,
            estimatePending: state.modeling.modelEstimate.pending,
            analyzeEstimatePending: state.modeling.analyzeModelEstimate.pending,
            model: selectModel(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getContractsTiny: tiny,
            searchClaimsForModeling: searchClaimsForModeling,
            modelClaimSelectionLanding: modelClaimSelectionLanding,
            setSelected: (value) => setV2('model-claim-selection-landing', 'selected', value),
        }, dispatch)
    })
)(injectIntl(ClaimSelectionComponent));