export const parseThemeMetadata = (metadata) => {
    try {
        if(metadata) {
            const parsed = JSON.parse(metadata);
            return parsed;
        }
        return '';
    }
    catch {
        return '';
    }
}

export const parseFooterLinks = (links) => {
    try {
        if(links) {
            const parsed = JSON.parse(links);
            if(parsed.length > 0) {
                return parsed;
            }
            return []; 
        }
        return [];
    }
    catch {
        return [];
    }
}