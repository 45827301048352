import { createReducer } from '../../util';
import * as actions from '../actions/rules';
import { StatusState } from './common';

const addObjectIfNotExist = (state, rule) => {
    const data = state.data;
    const { id } = rule;

    const ruleExist = data.find(x => x.id === id);
    if (!ruleExist) {
        return {
            data: [...state.data, rule]
        }
    } else {
        const tempData = data.map(x => {
            if (x.id === id) {
                return { ...rule }
            }
            return x
        })
        return {
            data: tempData
        }
    }
};

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    types: [],
    gpcis: [],
    methodTypes: [],
    payMethods: [],
    selectedGroups: [],
    carveOutCodesDescriptions: [],
    paySources: [],
    multiprocPrecedences: [],
    criteria: [],
    rulesByContractId: [],
    cloneRuleDialog: null, // {open:false, ruleId, contractId, ruleName, contractedServiceId}
    ruleStatus: true,// default true to displayed enabled contracts only, false will show all rules
    list: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    getMethodTypes: {
        ...initialStatusState,
    },
    getTypes: {
        ...initialStatusState,
    },
    getGpcis: {
        ...initialStatusState,
    },
    getPayMethods: {
        ...initialStatusState,
    },
    getCarveOutCodeDescription: {
        ...initialStatusState,
    },
    getPaySources: {
        ...initialStatusState,
    },
    getMultiprocPrecedences: {
        ...initialStatusState,
    },
    disableRule: {
        ...initialStatusState,
    },
    enableRule: {
        ...initialStatusState,
    },
    changePrecedence: {
        ...initialStatusState,
    },
    validateCodes: {
        ...initialStatusState,
    },
    getCriteria: {
        ...initialStatusState,
    },
    getRulesByContractId: {
        ...initialStatusState,
    },
    cloneRule: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {

    [actions.RULES_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.RULES_LIST_FULFILLED]: (state, rules) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: rules,
    }),
    [actions.RULES_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.RULES_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.RULES_GET_FULFILLED]: (state, { rule, groupIds }) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, rule),
        selectedGroups: groupIds,
    }),
    [actions.RULES_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.RULES_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.RULES_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.RULES_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.RULES_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_METHOD_TYPES_PENDING]: (state) => ({
        ...state,
        getMethodTypes: {
            pending: true,
        },
    }),
    [actions.RULES_GET_METHOD_TYPES_FULFILLED]: (state, methodTypes) => ({
        ...state,
        getMethodTypes: {
            ...initialStatusState,
        },
        methodTypes,
    }),
    [actions.RULES_GET_METHOD_TYPES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getMethodTypes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_TYPES_PENDING]: (state) => ({
        ...state,
        getTypes: {
            pending: true,
        },
    }),
    [actions.RULES_GET_TYPES_FULFILLED]: (state, types) => ({
        ...state,
        getTypes: {
            ...initialStatusState,
        },
        types,
    }),
    [actions.RULES_GET_TYPES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getTypes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_GPCIS_PENDING]: (state) => ({
        ...state,
        getGpcis: {
            pending: true,
        },
    }),
    [actions.RULES_GET_GPCIS_FULFILLED]: (state, gpcis) => ({
        ...state,
        getGpcis: {
            ...initialStatusState,
        },
        gpcis,
    }),
    [actions.RULES_GET_GPCIS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getGpcis: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_PAYMETHODS_PENDING]: (state) => ({
        ...state,
        getPaymethods: {
            pending: true,
        },
    }),
    [actions.RULES_GET_PAYMETHODS_FULFILLED]: (state, payMethods) => ({
        ...state,
        getPayMethods: {
            ...initialStatusState,
        },
        payMethods,
    }),
    [actions.RULES_GET_PAYMETHODS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPaymethods: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_CARVEOUT_DESCRIPTION_PENDING]: (state) => ({
        ...state,
        getCarveOutCodeDescription: {
            pending: true,
        },
        carveOutCodesDescriptions: []
    }),
    [actions.RULES_GET_CARVEOUT_DESCRIPTION_FULFILLED]: (state, carveOutCodesDescriptions) => ({
        ...state,
        getCarveOutCodeDescription: {
            ...initialStatusState,
        },
        carveOutCodesDescriptions: carveOutCodesDescriptions,
    }),
    [actions.RULES_GET_CARVEOUT_DESCRIPTION_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCarveOutCodeDescription: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_PAYSOURCES_PENDING]: (state) => ({
        ...state,
        getPaySources: {
            pending: true,
        },
    }),
    [actions.RULES_GET_PAYSOURCES_FULFILLED]: (state, paySources) => ({
        ...state,
        getPaySources: {
            ...initialStatusState,
        },
        paySources,
    }),
    [actions.RULES_GET_PAYSOURCES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPaySources: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_RESET]: () => ({ ...initialState }),
    [actions.RULES_ERROR_RESET]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        }
    }),
    [actions.RULES_GET_MULTIPROC_PRECEDENCES_PENDING]: (state) => ({
        ...state,
        getMultiprocPrecedences: {
            pending: true,
        },
    }),
    [actions.RULES_GET_MULTIPROC_PRECEDENCES_FULFILLED]: (state, multiprocPrecedences) => ({
        ...state,
        getMultiprocPrecedences: {
            ...initialStatusState,
        },
        multiprocPrecedences,
    }),
    [actions.RULES_GET_MULTIPROC_PRECEDENCES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getMultiprocPrecedences: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.RULES_DISABLE_PENDING]: (state) => ({
        ...state,
        disableRule: {
            pending: true,
        },
    }),
    [actions.RULES_DISABLE_FULFILLED]: (state) => ({
        ...state,
        disableRule: {
            ...initialStatusState,
        },
    }),
    [actions.RULES_DISABLE_REJECTED]: (state, errorMessage) => ({
        ...state,
        disableRule: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.RULES_ENABLE_PENDING]: (state) => ({
        ...state,
        enableRule: {
            pending: true,
        },
    }),
    [actions.RULES_ENABLE_FULFILLED]: (state) => ({
        ...state,
        enableRule: {
            ...initialStatusState,
        },
    }),
    [actions.RULES_ENABLE_REJECTED]: (state, errorMessage) => ({
        ...state,
        enableRule: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.RULES_CHANGE_PRECEDENCE_PENDING]: (state) => ({
        ...state,
        changePrecedence: {
            pending: true,
        },
    }),
    [actions.RULES_CHANGE_PRECEDENCE_FULFILLED]: (state) => ({
        ...state,
        changePrecedence: {
            ...initialStatusState,
        },
    }),
    [actions.RULES_CHANGE_PRECEDENCE_REJECTED]: (state, errorMessage) => ({
        ...state,
        changePrecedence: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_ENABLE_DISABLE_RESET]: (state) => ({
        ...state,
        enableRule: {
            ...initialStatusState,
        },
        disableRule: {
            ...initialStatusState,
        }
    }),
    [actions.RULES_CODES_VALIDATE_PENDING]: (state) => ({
        ...state,
        validateCodes: {
            pending: true,
        },
    }),
    [actions.RULES_CODES_VALIDATE_FULFILLED]: (state) => ({
        ...state,
        validateCodes: {
            ...initialStatusState,
        }
    }),
    [actions.RULES_CODES_VALIDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        validateCodes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_CRITERIA_PENDING]: (state) => ({
        ...state,
        getCriteria: {
            pending: true,
        },
    }),
    [actions.RULES_GET_CRITERIA_FULFILLED]: (state, criteria) => ({
        ...state,
        getCriteria: {
            ...initialStatusState,
        },
        criteria
    }),
    [actions.RULES_GET_CRITERIA_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCriteria: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_BY_CONTRACT_ID_PENDING]: (state) => ({
        ...state,
        getRulesByContractId: {
            pending: true,
            hasApiResponded: false,
        },
    }),
    [actions.RULES_GET_BY_CONTRACT_ID_FULFILLED]: (state, rulesByContractId) => ({
        ...state,
        getRulesByContractId: {
            ...initialStatusState,
            hasApiResponded: true,
        },
        rulesByContractId
    }),
    [actions.RULES_GET_BY_CONTRACT_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        getRulesByContractId: {
            ...initialStatusState,
            hasApiResponded: true,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_CLONE_PENDING]: (state) => ({
        ...state,
        cloneRule: {
            pending: true,
            hasApiResponded: false,
        },
    }),
    [actions.RULES_CLONE_FULFILLED]: (state) => ({
        ...state,
        cloneRule: {
            ...initialStatusState,
            hasApiResponded: true,
        }
    }),
    [actions.RULES_CLONE_REJECTED]: (state, errorMessage) => ({
        ...state,
        cloneRule: {
            ...initialStatusState,
            hasApiResponded: true,
            error: true,
            errorMessage,
        },
    }),
    [actions.RULES_GET_BY_CONTRACT_ID_RESET]: (state) => ({
        ...state,
        rulesByContractId: [],
        getRulesByContractId: {
            ...initialStatusState,
            hasApiResponded: false,
        },
    }),
    [actions.RULES_SET_CLONE_DIALOG]: (state, { cloneRuleDialog }) => ({
        ...state,
        cloneRuleDialog
    }),
    [actions.RULES_SET_RULE_STATUS]: (state, { ruleStatus }) => ({
        ...state,
        ruleStatus
    }),
    [actions.RULES_CODES_VALIDATE_RESET]: (state) => ({
        ...state,
        validateCodes: {
            ...initialStatusState,
        },
    }),
});