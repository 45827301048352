import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { create, update } from '../../../../store/actions/pricerPayers';
import PayerDialogComponent from './PayerDialog';

export const PayerDialog = connect(
    () => {
        const selectPayerContracts = (state, props) => props.payerContracts;
        const selectPayerInfoEditDialog = (state, props) => state.pricerPayers.payerInfoEditDialog;


        const selectPayer = createSelector(
            selectPayerInfoEditDialog,
            (payerInfoEditDialog) => {
                if (payerInfoEditDialog && payerInfoEditDialog?.payer) {
                    return payerInfoEditDialog?.payer
                }
                return null
            }
        )

        const selectMode = createSelector(
            selectPayer,
            (payer) => {
                return payer ? 'edit' : 'create';
            }
        )

        const selectOpen = createSelector(
            selectPayerInfoEditDialog,
            (payerInfoEditDialog) => {
                if (payerInfoEditDialog && payerInfoEditDialog?.open) {
                    return true
                }
                return false
            }
        )


        const selectInitialValues = createSelector(
            selectMode,
            selectPayer,
            (mode, payer) => {
                if (mode === 'edit' && payer) {

                    let payload = {
                        id: payer.id,
                        displayName: payer.displayName,
                        enabled: !payer.disabled,
                    }

                    return payload;
                }

                return {
                    displayName: '',
                    enabled: false,
                }
            }
        )

        return (state, props) => ({
            //create payer
            payerCreatePending: state.pricerPayers.create.pending,
            // update payer
            payerUpdatePending: state.pricerPayers.update.pending,
            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            open: selectOpen(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payerCreate: create,
            payerUpdate: update,
        }, dispatch)
    })
)(injectIntl(PayerDialogComponent));