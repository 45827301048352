export const CHARGE_MASTER_UPLOAD = 'CHARGE_MASTER_UPLOAD';
export const CHARGE_MASTER_UPLOAD_PENDING = 'CHARGE_MASTER_UPLOAD_PENDING';
export const CHARGE_MASTER_UPLOAD_FULFILLED = 'CHARGE_MASTER_UPLOAD_FULFILLED';
export const CHARGE_MASTER_UPLOAD_REJECTED = 'CHARGE_MASTER_UPLOAD_REJECTED';

export const CHARGE_MASTER_LIST = 'CHARGE_MASTER_LIST';
export const CHARGE_MASTER_LIST_PENDING = 'CHARGE_MASTER_LIST_PENDING';
export const CHARGE_MASTER_LIST_FULFILLED = 'CHARGE_MASTER_LIST_FULFILLED';
export const CHARGE_MASTER_LIST_REJECTED = 'CHARGE_MASTER_LIST_REJECTED';

export const CHARGE_MASTER_CREATE = 'CHARGE_MASTER_CREATE';
export const CHARGE_MASTER_CREATE_PENDING = 'CHARGE_MASTER_CREATE_PENDING';
export const CHARGE_MASTER_CREATE_FULFILLED = 'CHARGE_MASTER_CREATE_FULFILLED';
export const CHARGE_MASTER_CREATE_REJECTED = 'CHARGE_MASTER_CREATE_REJECTED';

export const CHARGE_MASTER_UPDATE = 'CHARGE_MASTER_UPDATE';
export const CHARGE_MASTER_UPDATE_PENDING = 'CHARGE_MASTER_UPDATE_PENDING';
export const CHARGE_MASTER_UPDATE_FULFILLED = 'CHARGE_MASTER_UPDATE_FULFILLED';
export const CHARGE_MASTER_UPDATE_REJECTED = 'CHARGE_MASTER_UPDATE_REJECTED';

export const CHARGE_MASTER_DOWNLOAD_TEMPLATE = 'CHARGE_MASTER_DOWNLOAD_TEMPLATE';
export const CHARGE_MASTER_DOWNLOAD_TEMPLATE_PENDING = 'CHARGE_MASTER_DOWNLOAD_TEMPLATE_PENDING';
export const CHARGE_MASTER_DOWNLOAD_TEMPLATE_FULFILLED = 'CHARGE_MASTER_DOWNLOAD_TEMPLATE_FULFILLED';
export const CHARGE_MASTER_DOWNLOAD_TEMPLATE_REJECTED = 'CHARGE_MASTER_DOWNLOAD_TEMPLATE_REJECTED';

export const CHARGE_MASTER_ERROR_RESET = 'CHARGE_MASTER_ERROR_RESET';
export const CHARGE_MASTER_RESET = 'CHARGE_MASTER_RESET';

export const CHARGE_MASTER_SET_EDIT_DIALOG = 'CHARGE_MASTER_SET_EDIT_DIALOG';



export const uploadChargeMaster = (file, callback, errorCallback) => ({
    type: CHARGE_MASTER_UPLOAD,
    payload: { file, callback, errorCallback },
});

export const list = () => ({
    type: CHARGE_MASTER_LIST,
});

export const download = (callback) => ({
    type: CHARGE_MASTER_DOWNLOAD_TEMPLATE,
    payload: { callback }
});

export const create = (charge, callback) => ({
    type: CHARGE_MASTER_CREATE,
    payload: { charge, callback },
});

export const update = (id, charge, callback) => ({
    type: CHARGE_MASTER_UPDATE,
    payload: { id, charge, callback },
});

export const errorReset = (...errorKeys) => ({
    type: CHARGE_MASTER_ERROR_RESET,
    payload: { errorKeys },
});

export const setChargeMasterEditDialog = (chargeMasterEditDialog) => ({
    type: CHARGE_MASTER_SET_EDIT_DIALOG,
    payload: { chargeMasterEditDialog }
});

