import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { acknowledge } from '../../../store/actions/claimAnalysis';
import AcknowledgeDialogComponent from './AcknowledgeDialog';
import { setV2 } from '../../../store/actions/pagesV2';

export const AcknowledgeDialog = connect(
    () => {
        const selectClaimNumber = (state, props) => props.claimNumber;
        const selectBulkClaimIds = (state, props) => state.pagesV2['claimAnalysis-landing'].selected;
        const selectSingleClaimId = (state, props) => state.claimAnalysis.selectedClaims;
        const selectIsBulkAcknowledge = (state, props) => state.claimAnalysis.isBulkAcknowledge;

        const selectClaimIds = createSelector(
            selectIsBulkAcknowledge,
            selectBulkClaimIds,
            selectSingleClaimId,
            (isBulkAcknowledge, bulkIds, singleId) => {
                if (isBulkAcknowledge) {
                    return bulkIds
                }
                return singleId
            }
        )

        const selectInitialValues = createSelector(
            selectClaimIds,
            (claimIds) => {
                return {
                    analysisId: claimIds,
                    acknowledge: true,
                    comment: '',
                }
            }
        )

        return (state, props) => ({
            acknowledgeClaimPending: state.claimAnalysis.acknowledge.pending,

            initialValues: selectInitialValues(state, props),
            claimNumber: selectClaimNumber(state, props),
            open: state.claimAnalysis.openAcknowledgeDialog,
            selectedClaims: selectClaimIds(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            acknowledgeClaim: acknowledge,
            setSelected: (value) => setV2('claimAnalysis-landing', 'selected', value),
        }, dispatch)
    })
)(injectIntl(AcknowledgeDialogComponent));