export const OPPS_CREATE = 'OPPS_CREATE';
export const OPPS_CREATE_PENDING = 'OPPS_CREATE_PENDING';
export const OPPS_CREATE_FULFILLED = 'OPPS_CREATE_FULFILLED';
export const OPPS_CREATE_REJECTED = 'OPPS_CREATE_REJECTED';

export const OPPS_GET = 'OPPS_GET';
export const OPPS_GET_PENDING = 'OPPS_GET_PENDING';
export const OPPS_GET_FULFILLED = 'OPPS_GET_FULFILLED';
export const OPPS_GET_REJECTED = 'OPPS_GET_REJECTED';

export const OPPS_UPDATE = 'OPPS_UPDATE';
export const OPPS_UPDATE_PENDING = 'OPPS_UPDATE_PENDING';
export const OPPS_UPDATE_FULFILLED = 'OPPS_UPDATE_FULFILLED';
export const OPPS_UPDATE_REJECTED = 'OPPS_UPDATE_REJECTED';

export const OPPS_DELETE = 'OPPS_DELETE';
export const OPPS_DELETE_PENDING = 'OPPS_DELETE_PENDING';
export const OPPS_DELETE_FULFILLED = 'OPPS_DELETE_FULFILLED';
export const OPPS_DELETE_REJECTED = 'OPPS_DELETE_REJECTED';

export const OPPS_GET_TINY = 'OPPS_GET_TINY';
export const OPPS_GET_TINY_PENDING = 'OPPS_GET_TINY_PENDING';
export const OPPS_GET_TINY_FULFILLED = 'OPPS_GET_TINY_FULFILLED';
export const OPPS_GET_TINY_REJECTED = 'OPPS_GET_TINY_REJECTED';

export const OPPS_CREATE_UPDATE_RESET = 'OPPS_CREATE_UPDATE_RESET';

export const OPPS_GET_TINY_BY_CONTRACT = 'OPPS_GET_TINY_BY_CONTRACT';
export const OPPS_GET_TINY_BY_CONTRACT_PENDING = 'OPPS_GET_TINY_BY_CONTRACT_PENDING';
export const OPPS_GET_TINY_BY_CONTRACT_FULFILLED = 'OPPS_GET_TINY_BY_CONTRACT_FULFILLED';
export const OPPS_GET_TINY_BY_CONTRACT_REJECTED = 'OPPS_GET_TINY_BY_CONTRACT_REJECTED';

export const OPPS_GET_TINY_BY_TARGET_CLIENT = 'OPPS_GET_TINY_BY_TARGET_CLIENT';
export const OPPS_GET_TINY_BY_TARGET_CLIENT_PENDING = 'OPPS_GET_TINY_BY_TARGET_CLIENT_PENDING';
export const OPPS_GET_TINY_BY_TARGET_CLIENT_FULFILLED = 'OPPS_GET_TINY_BY_TARGET_CLIENT_FULFILLED';
export const OPPS_GET_TINY_BY_TARGET_CLIENT_REJECTED = 'OPPS_GET_TINY_BY_TARGET_CLIENT_REJECTED';

export const OPPS_DELETE_DEPENDENCIES = 'OPPS_DELETE_DEPENDENCIES';
export const OPPS_DELETE_DEPENDENCIES_PENDING = 'OPPS_DELETE_DEPENDENCIES_PENDING';
export const OPPS_DELETE_DEPENDENCIES_FULFILLED = 'OPPS_DELETE_DEPENDENCIES_FULFILLED';
export const OPPS_DELETE_DEPENDENCIES_REJECTED = 'OPPS_DELETE_DEPENDENCIES_REJECTED';

export const OPPS_SET_DEPENDENCY_DIALOG = 'OPPS_SET_DEPENDENCY_DIALOG';
export const OPPS_RESET_DEPENDENCIES = 'OPPS_RESET_DEPENDENCIES';

export const createOPPS = (values, callback, shouldReloadGrid) => ({
    type: OPPS_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const editOPPS = (values, callback, shouldReloadGrid) => ({
    type: OPPS_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const deleteOPPS = (oppsId, callback) => ({
    type: OPPS_DELETE,
    payload: { oppsId, callback }
});

export const createEditReset = () => ({
    type: OPPS_CREATE_UPDATE_RESET
});

export const getOPPS = (oppsId) => ({
    type: OPPS_GET,
    payload: { oppsId }
});

export const getOPPSTiny = () => ({
    type: OPPS_GET_TINY
});

export const getOPPSTinyByContract = (contractId) => ({
    type: OPPS_GET_TINY_BY_CONTRACT,
    payload: { contractId }
});

export const getOPPSTinyByTargetClient = (targetClientId) => ({
    type: OPPS_GET_TINY_BY_TARGET_CLIENT,
    payload: { targetClientId }
});

export const getOPPSDeleteDependencies = (id) => ({
    type: OPPS_DELETE_DEPENDENCIES,
    payload: { id }
});

export const setDependencyDialog = (dependencyDialog) => ({
    type: OPPS_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});

export const resetDependencies = () => ({
    type: OPPS_RESET_DEPENDENCIES,
});