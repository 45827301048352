import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { createOPPS, editOPPS, getOPPS } from '../../../store/actions/opps';
import { asIsoDate } from '../../../util/date';
import OPPSEditPageComponent from './OPPSEditPage';

const selectMode = (state, props) => props.match.params.id === 'new' ? 'create' : 'edit';
const selectOPPSId = (state, props) => props.match.params.id;
const selectDRG = (state) => state.opps.opps;

export const OPPSEditPage = injectIntl(
    connect(() => {
        const selectInitialValues = createSelector(
            selectMode,
            selectDRG,
            (mode, opps) => {
                if (mode === 'edit' && opps) {
                    return {
                        name: opps.name,
                        wageIndex: opps.wageIndex,
                        outpatientCostToChargeRatio: opps.outpatientCostToChargeRatio,
                        implantCostToChargeRatio: opps.implantCostToChargeRatio,
                        outlierMultiple: opps.outlierMultiple,
                        opFixedDollarThreshold: opps.opFixedDollarThreshold,
                        effectiveFrom: asIsoDate(opps.effectiveFrom),
                        effectiveTill: asIsoDate(opps.effectiveTill),
                    }
                }
                return {
                    name: '',
                    wageIndex: 1,
                    outpatientCostToChargeRatio: 0,
                    implantCostToChargeRatio: 0,
                    outlierMultiple: 0,
                    opFixedDollarThreshold: 0,
                    effectiveFrom: '',
                    effectiveTill: ''
                }
            }
        )
        return (state, props) => ({
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            createPending: state.opps.create.pending,
            editPending: state.opps.update.pending,
            getPending: state.opps.get.pending,
            oppsId: selectOPPSId(state, props)
        })
    },
        (dispatch) => ({
            actions: bindActionCreators({
                createOPPS: createOPPS,
                getOPPS: getOPPS,
                editOPPS: editOPPS
            }, dispatch)
        })
    )(OPPSEditPageComponent));