import { toast } from "react-toastify"

export const errorToastify = (child) => {
    toast.error(child, {
        closeOnClick: false,
        position: "top-center",
        theme: "light",
        autoClose: 60000,
        pauseOnFocusLoss: false
    })
}

export const removeAllToasts = () => {
    toast.dismiss()
}