import { createSelector } from "reselect";

export const selectCodeServices = (state) => state.services.data;
export const selectCodeServicesTiny = (state) => state.services.tiny;
export const selectServiceTypes = (state) => state.services.serviceTypes;
export const selectServiceDescriptions = (state) => state.services.serviceDescriptions;

export const createServicSelector = (selectServiceId) => createSelector(
    selectServiceId,
    selectCodeServices,
    (serviceId, services) => services.find((contract) => contract.id === parseInt(serviceId))
);