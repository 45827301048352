import { SESSION_REFRESH_TOKEN } from "../store/actions/session";
import storeCofiguration from "../store";
import { fromLocalStorage, toLocalStorage } from "./storage";

let refreshInterval = null;

export const startRefreshTimer = (lastLoggedIn) => {
    const tokenGenerationTime = fromLocalStorage('tokenGenerationTime');
    console.log('refresh interval started..');

    if (tokenGenerationTime) {
    } else {
        toLocalStorage('tokenGenerationTime', new Date(`${lastLoggedIn}z`));
    }
    refreshInterval = setInterval(refreshTokenAction, 10000);
}

export const clearRefreshTimer = () => {
    toLocalStorage('tokenGenerationTime', null);
    clearInterval(refreshInterval);
}

const refreshTokenAction = () => {
    const tokenGenerationTime = fromLocalStorage('tokenGenerationTime');

    const diff = Math.abs(new Date() - new Date(tokenGenerationTime));
    const minDiff = Math.floor((diff / 1000) / 60);
    const refreshMins = new Date(parseInt(process.env.REACT_APP_REFRESH_TOKEN_TIME)).getMinutes();
    if (minDiff >= refreshMins) {
        const { store } = storeCofiguration;
        console.log('dispatching refresh token action.');
        store.dispatch({
            type: SESSION_REFRESH_TOKEN
        });
    }

}                                             