import {
    Box, Button, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, Input, InputLabel, makeStyles, MenuItem,
    Select, Switch, TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card } from "react-bootstrap";
import { connect } from 'react-redux';
import { Loadable } from '../loadable';


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        position:'absolute',
        bottom:"8px",
        right:"240px"
    },
    criteriaCard:{
        marginTop:'15px',
        position:"static",
        width:"100%"
    },
    saveBtn:{
        background:'#196540 !important',
        color:'white'
    },
    dialogHeaderContainer:{
        display:'flex',
        justifyContent:'space-between'
    },
    dialogCloseBtn:{
        height: "20px",
        width: "20px",
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: "10px"
    },
    staticPosition:{
        position:"static"
    }
}));


const initialValue = {
    criteriaName: '',
    searchCriteriaTypeId: '',
    isDefault: false,
    isPublic: false,
    id: undefined
}

function SaveCriteriaDialog({ intl, open, handleClose, handleSaveSearch, createPending, updatePending, criteria, searchAction, searchCriteriaTypes }) {
    const [initialValues, setInitialValues] = useState(initialValue);
    const classes = useStyles();

    useEffect(() => {
        if (criteria && criteria.id) {
            setInitialValues({
                criteriaName: criteria.criteriaName,
                searchCriteriaTypeId: criteria.searchCriteriaTypeId,
                isDefault: criteria.isDefault,
                isPublic: criteria.isPublic,
                id: criteria.id,
            });
        } else {
            setInitialValues(initialValue);
        }
    }, [criteria]);

    const closeDialog = () => {
        //setInitialValues(initialValue);
        handleClose();
    }

    const title = searchAction === 'Save' ? 'Update' : 'New';

    return (
        <Card className={classes.criteriaCard}>
            <Loadable loading={createPending || updatePending} parentClassName={classes.staticPosition}>
                <Box className={classes.dialogHeaderContainer}>
                <DialogTitle>{`${title} Criteria`}</DialogTitle>

                <IconButton className={classes.dialogCloseBtn} onClick={closeDialog}><CloseIcon /></IconButton>
                </Box>
                <DialogContent>
                <Loadable parentClassName={classes.staticPosition}>
                <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors : any = {};
                            if (!values.criteriaName) {
                                errors.criteriaName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.searchCriteriaTypeId) {
                                errors.searchCriteriaTypeId = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            if(values.isDefault) //If criteria is clear & User chooses a new defaultt we want to apply the new default
                            {
                                localStorage.removeItem("isCriteriaCleared")
                            }
                            let payload = {
                                criteriaName: values.criteriaName,
                                searchCriteriaTypeId: values.searchCriteriaTypeId,
                                isDefault: values.isDefault,
                                isPublic: values.isPublic,
                                id: undefined
                            }
                            if (criteria && criteria.id) {
                                payload = {
                                    ...payload,
                                    id: values.id,
                                }
                                handleSaveSearch(payload, closeDialog, criteria);
                            }
                            else {
                                handleSaveSearch(payload, closeDialog, criteria);
                            }
                        }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <Grid container spacing={1}>

                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        required
                                        label="Criteria Name"
                                        name="criteriaName"
                                        margin="dense"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.criteriaName}
                                        helperText={touched.criteriaName && errors.criteriaName}
                                        error={Boolean(touched.criteriaName && errors.criteriaName)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl fullWidth error={Boolean(touched.searchCriteriaTypeId && errors.searchCriteriaTypeId)}>
                                    <InputLabel htmlFor="searchCriteriaTypeId">Criteria Type *</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        value={values.searchCriteriaTypeId}
                                        name="searchCriteriaTypeId"
                                        onChange={handleChange}
                                        error={Boolean(touched.searchCriteriaTypeId && errors.searchCriteriaTypeId)}
                                        input={<Input id="searchCriteriaTypeId" />}
                                        inputProps={{
                                            name: "searchCriteriaTypeId",
                                            id: "searchCriteriaTypeId"
                                        }}
                                        >
                                        {searchCriteriaTypes.map(({ id, name }) => (
                                            <MenuItem
                                                key={name}
                                                value={id}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>{touched.searchCriteriaTypeId && errors.searchCriteriaTypeId}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="isDefault"
                                                color="primary"
                                                checked={values.isDefault}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="ml-0"
                                                value={values.isDefault}
                                            />
                                        }
                                        label="Is Default?"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="isPublic"
                                                color="primary"
                                                checked={values.isPublic}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="ml-0"
                                                value={values.isPublic}
                                            />
                                        }
                                        label="Is Public?"
                                    />
                                </Grid>
                                <hr />
                                {/* <Grid container justifyContent="flex-end"> */}
                                    {/* <Button
                                        onClick={closeDialog}
                                        variant="contained"
                                        color="default"
                                        className={classes.button}>
                                        Cancel
                                    </Button> */}
                                    <Button
                                        variant="contained"
                                        className={`${classes.button} ${classes.saveBtn} ml-3`}
                                        
                                        type="submit"
                                    >
                                     {searchAction==="Save"?   "Update Criteria":"Save Criteria"}
                                    </Button>
                                {/* </Grid> */}
                            </Grid>
                        </form>
                    )}
                    </Formik>    
                </Loadable>
                </DialogContent>
            </Loadable>
        </Card>
    )
}

const mapStateToProps = ({ search }) => ({
    searchCriteriaTypes: search.types,
    createPending: search.create.pending,
    updatePending: search.update.pending,
});
export default connect(mapStateToProps)(SaveCriteriaDialog);