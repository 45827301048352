import { Grid } from '@material-ui/core';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { PagedTable } from '../../../common';
import { Portlet, PortletBody, PortletHeader } from '../../../partials/content/Portlet';

const DEFAULT_SORT = [{ id: 'executedAt', direction: 'asc' }];

function FileExecution(props) {
    const [refresh, setRefresh] = useState(Promise.resolve());

    const {
        fields, fileExecutionsData,
        actions: {
            fileExecutionsLanding,
        }
    } = props;

    function handleLoad(sort, filter, pagination) {
        fileExecutionsLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    return (
        <>
            <Helmet title="File Executions" />
            <Portlet>
                <PortletHeader
                    title="File Executions"
                    name="file-executions-landing"
                    handleRefresh={handleRefresh}
                // showSearchFilters={true}

                />
                <PortletBody>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="file-executions-landing"
                                    title="File Executions"
                                    fields={fields}
                                    data={fileExecutionsData}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    criteriasSaveEnabled={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
        </>
    )
}

export default FileExecution