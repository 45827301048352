import React, { useState } from 'react'
import { Button, FormControl, FormGroup, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, Typography, makeStyles } from '@material-ui/core'
import { CustomLabel, DateRangeControl, Loadable, PopoverLink } from '../../../../../common';
import { Formik } from 'formik';
import { MODEL_CLAIM_SELECTION_TYPE_ADVANCE, MODEL_CLAIM_SELECTION_TYPE_BASIC } from '../../../../../store/constants/modeling';
import { StringEquators } from '../../../../../store/constants/pages';
import { Card } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
    container: {
        marginLeft: theme.spacing(2),
    },
    autoComplete: {
        width: '90%',
    },
    formControl: {
        width: '90%',
        marginTop: theme.spacing(2),
    },
    textField: {
        width: '90%',
    },
    buttonGrid: {
        textAlign: 'right',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '93%'
    },
    claimSelectionTypelabel: {
        maxWidth: "160px"
    }
}));


function ClaimSearch(props) {
    const { intl, initialValues, mode, model, getModelPending,
        initSearchPending, handleInitSearch, actions:
        { }
    } = props;

    const classes = useStyles();

    const handleSelectionTypeChangeHandler = (e, setFieldValue) => {
        const value = e.target.checked ? MODEL_CLAIM_SELECTION_TYPE_ADVANCE : MODEL_CLAIM_SELECTION_TYPE_BASIC;
        setFieldValue('claimSelectionType', value);
    }

    const setDates = (setFieldValue) => (beginDate, endDate, isDateFilterActive) => {
        if (isDateFilterActive) {
            setFieldValue('fromServiceDate', beginDate);
            setFieldValue('toServiceDate', endDate);
        } else {
            setFieldValue('fromServiceDate', '');
            setFieldValue('toServiceDate', '');
        }
    }

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-info`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    return (
        <>
            <Loadable loading={initSearchPending || getModelPending}>
                {mode === 'edit' && model &&
                    <Card variant="outlined" className='mb-3'>
                        <Card.Header>
                            <h5 className='m-0'>Model Details</h5>
                        </Card.Header>
                        <Card.Body>
                            <Grid className='p-3'>
                                <Grid container>
                                    <Grid xs={4}><CustomLabel label='Model Name' data={model?.name} /> </Grid>
                                    <Grid xs={4}><CustomLabel label='Contract 1' data={model?.contractModelXref[0]?.contract?.name} /> </Grid>
                                    <Grid xs={4}><CustomLabel label='Contract 2' data={model?.contractModelXref[1]?.contract?.name} /> </Grid>
                                </Grid>
                            </Grid>
                        </Card.Body>
                    </Card>
                }
                <Card variant="outlined">
                    <Card.Header>
                        <h5 className='m-0'>Claim Search</h5>
                    </Card.Header>
                    <Card.Body>
                        <Grid container>
                            <Grid item xs={10}>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    validate={values => {
                                        const errors = {};
                                        if (values.claimSelectionType === MODEL_CLAIM_SELECTION_TYPE_BASIC) {
                                            if (!values.claimNumber) {
                                                errors.claimNumber = intl.formatMessage({
                                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                                });
                                            }
                                        } else {
                                            if (!values.revCode && !values.cpt && !values.payerName
                                                && !values.payerType && !values.fromServiceDate && !values.toServiceDate) {
                                                errors.advanceSearchValidation = 'You need to select at least one criteria before search'
                                            }
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setStatus, setSubmitting }) => {
                                        handleInitSearch(values);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                    }) => (
                                        <>

                                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                                <div className={classes.root}>

                                                    <Grid container className='pt-4'>
                                                        <Grid item xs={4} className='pt-5'>
                                                            <FormGroup>
                                                                <Typography component="div">
                                                                    <Grid component="label" className={classes.claimSelectionTypelabel} container alignItems="center" spacing={0}>
                                                                        <Grid item>Basic</Grid>
                                                                        <Grid item>
                                                                            <Switch
                                                                                checked={values.claimSelectionType === MODEL_CLAIM_SELECTION_TYPE_ADVANCE}
                                                                                onChange={e => handleSelectionTypeChangeHandler(e, setFieldValue)}
                                                                                name="claimSelectionType" />
                                                                        </Grid>
                                                                        <Grid item>Advance</Grid>
                                                                    </Grid>
                                                                </Typography>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item xs={8} className='pt-2'>
                                                            <Grid container>
                                                                {values.claimSelectionType === MODEL_CLAIM_SELECTION_TYPE_BASIC ?
                                                                    <>
                                                                        <Grid item xs={12} md={6} >
                                                                            <FormControl className={classes.formControl}>
                                                                                <InputLabel htmlFor="equator">Select Equator</InputLabel>
                                                                                <Select
                                                                                    onBlur={handleBlur}
                                                                                    onChange={handleChange}
                                                                                    value={values.equator}
                                                                                    error={Boolean(touched.equator && errors.equator)}
                                                                                    inputProps={{
                                                                                        name: "equator",
                                                                                        id: "equator"
                                                                                    }}
                                                                                >
                                                                                    {StringEquators.equatorArr.map(({ value, label }) => (
                                                                                        <MenuItem
                                                                                            key={label}
                                                                                            value={value}
                                                                                        >
                                                                                            {label}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={6}>
                                                                            <TextField
                                                                                label="Claim Number"
                                                                                name="claimNumber"
                                                                                className={classes.textField}
                                                                                margin="normal"
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                value={values.claimNumber}
                                                                                helperText={touched.claimNumber && errors.claimNumber}
                                                                                error={Boolean(touched.claimNumber && errors.claimNumber)}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">
                                                                                        <span>
                                                                                            {hoverColumn('Add single string value or use comma separation for multiple values')}
                                                                                        </span>
                                                                                    </InputAdornment>
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Grid item xs={12} md={6}>
                                                                            <TextField
                                                                                label="Rev Code"
                                                                                name="revCode"
                                                                                className={classes.textField}
                                                                                margin="normal"
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                value={values.revCode}
                                                                                helperText={touched.revCode && errors.revCode}
                                                                                error={Boolean(touched.revCode && errors.revCode)}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">
                                                                                        <span>
                                                                                            {hoverColumn('Add single string value or use comma separation for multiple values')}
                                                                                        </span>
                                                                                    </InputAdornment>
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={6}>
                                                                            <TextField
                                                                                label="CPT"
                                                                                name="cpt"
                                                                                className={classes.textField}
                                                                                margin="normal"
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                value={values.cpt}
                                                                                helperText={touched.cpt && errors.cpt}
                                                                                error={Boolean(touched.cpt && errors.cpt)}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">
                                                                                        <span>
                                                                                            {hoverColumn('Add single string value or use comma separation for multiple values')}
                                                                                        </span>
                                                                                    </InputAdornment>
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={6}>
                                                                            <TextField
                                                                                label="Payer name"
                                                                                name="payerName"
                                                                                className={classes.textField}
                                                                                margin="normal"
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                value={values.payerName}
                                                                                helperText={touched.payerName && errors.payerName}
                                                                                error={Boolean(touched.payerName && errors.payerName)}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">
                                                                                        <span>
                                                                                            {hoverColumn('Add single string value or use comma separation for multiple values')}
                                                                                        </span>
                                                                                    </InputAdornment>
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={6}>
                                                                            <TextField
                                                                                label="Payer Type"
                                                                                name="payerType"
                                                                                className={classes.textField}
                                                                                margin="normal"
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                value={values.payerType}
                                                                                helperText={touched.payerType && errors.payerType}
                                                                                error={Boolean(touched.payerType && errors.payerType)}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">
                                                                                        <span>
                                                                                            {hoverColumn('Add single string value or use comma separation for multiple values')}
                                                                                        </span>
                                                                                    </InputAdornment>
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={6} xs={12}>
                                                                            <DateRangeControl
                                                                                filterName="Service Date"
                                                                                setDates={setDates(setFieldValue)}
                                                                                style={{ marginLeft: '0px', marginTop: '16px', width: '90%' }}
                                                                                defaultControlEnabled={false}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {(touched.revCode || touched.cpt) && <FormHelperText className='pt-2' error>{errors.advanceSearchValidation}</FormHelperText>}
                                                                        </Grid>
                                                                    </>
                                                                }
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Grid className={classes.buttonGrid}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            type="submit"
                                                                        >
                                                                            Search
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Card.Body>
                </Card>
            </Loadable>
        </>
    )
}

export default ClaimSearch

