import React, { useState } from 'react'

function EllipsisText({ textArr = [] }) {
    const [isExpanded, setExpanded] = useState(false);
    return (
        <span>
            {isExpanded ?
                <>
                    {textArr.join(', ')}
                    <span className='text-primary cursor-pointer' onClick={() => setExpanded(false)} color='primary'>
                        {' '}   Show less
                    </span>
                </> :
                <>
                    {textArr.slice(0, 5).join(', ')}
                    {textArr.length > 5 && <span className='text-primary cursor-pointer' onClick={() => setExpanded(true)} color='primary'>
                        {' '}  ... Show more
                    </span>
                    }
                </>
            }
        </span>
    )
}

export default EllipsisText
