export const FEE_SCHEDULE_DELETE = 'FEE_SCHEDULE_DELETE';
export const FEE_SCHEDULE_DELETE_PENDING = 'FEE_SCHEDULE_DELETE_PENDING';
export const FEE_SCHEDULE_DELETE_FULFILLED = 'FEE_SCHEDULE_DELETE_FULFILLED';
export const FEE_SCHEDULE_DELETE_REJECTED = 'FEE_SCHEDULE_DELETE_REJECTED';

export const FEE_SCHEDULE_DELETE_DEPENDENCIES = 'FEE_SCHEDULE_DELETE_DEPENDENCIES';
export const FEE_SCHEDULE_DELETE_DEPENDENCIES_PENDING = 'FEE_SCHEDULE_DELETE_DEPENDENCIES_PENDING';
export const FEE_SCHEDULE_DELETE_DEPENDENCIES_FULFILLED = 'FEE_SCHEDULE_DELETE_DEPENDENCIES_FULFILLED';
export const FEE_SCHEDULE_DELETE_DEPENDENCIES_REJECTED = 'FEE_SCHEDULE_DELETE_DEPENDENCIES_REJECTED';

export const FEE_SCHEDULE_ITEMS_CREATE = 'FEE_SCHEDULE_ITEMS_CREATE';
export const FEE_SCHEDULE_ITEMS_CREATE_PENDING = 'FEE_SCHEDULE_ITEMS_CREATE_PENDING';
export const FEE_SCHEDULE_ITEMS_CREATE_FULFILLED = 'FEE_SCHEDULE_ITEMS_CREATE_FULFILLED';
export const FEE_SCHEDULE_ITEMS_CREATE_REJECTED = 'FEE_SCHEDULE_ITEMS_CREATE_REJECTED';

export const FEE_SCHEDULE_ITEMS_UPDATE = 'FEE_SCHEDULE_ITEMS_UPDATE';
export const FEE_SCHEDULE_ITEMS_UPDATE_PENDING = 'FEE_SCHEDULE_ITEMS_UPDATE_PENDING';
export const FEE_SCHEDULE_ITEMS_UPDATE_FULFILLED = 'FEE_SCHEDULE_ITEMS_UPDATE_FULFILLED';
export const FEE_SCHEDULE_ITEMS_UPDATE_REJECTED = 'FEE_SCHEDULE_ITEMS_UPDATE_REJECTED';

export const FEE_SCHEDULE_ITEMS_DELETE = 'FEE_SCHEDULE_ITEMS_DELETE';
export const FEE_SCHEDULE_ITEMS_DELETE_PENDING = 'FEE_SCHEDULE_ITEMS_DELETE_PENDING';
export const FEE_SCHEDULE_ITEMS_DELETE_FULFILLED = 'FEE_SCHEDULE_ITEMS_DELETE_FULFILLED';
export const FEE_SCHEDULE_ITEMS_DELETE_REJECTED = 'FEE_SCHEDULE_ITEMS_DELETE_REJECTED';

export const FEE_SCHEDULE_ITEMS_STATUS = 'FEE_SCHEDULE_ITEMS_STATUS';
export const FEE_SCHEDULE_ITEMS_STATUS_PENDING = 'FEE_SCHEDULE_ITEMS_STATUS_PENDING';
export const FEE_SCHEDULE_ITEMS_STATUS_FULFILLED = 'FEE_SCHEDULE_ITEMS_STATUS_FULFILLED';
export const FEE_SCHEDULE_ITEMS_STATUS_REJECTED = 'FEE_SCHEDULE_ITEMS_STATUS_REJECTED';

export const FEE_SCHEDULE_ITEMS_CODE_TYPES = 'FEE_SCHEDULE_ITEMS_CODE_TYPES';
export const FEE_SCHEDULE_ITEMS_CODE_TYPES_PENDING = 'FEE_SCHEDULE_ITEMS_CODE_TYPES_PENDING';
export const FEE_SCHEDULE_ITEMS_CODE_TYPES_FULFILLED = 'FEE_SCHEDULE_ITEMS_CODE_TYPES_FULFILLED';
export const FEE_SCHEDULE_ITEMS_CODE_TYPES_REJECTED = 'FEE_SCHEDULE_ITEMS_STATUS_REJECTED';

export const FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE = 'FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE';
export const FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_PENDING = 'FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_PENDING';
export const FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_FULFILLED = 'FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_FULFILLED';
export const FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_REJECTED = 'FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATEECTED';

export const FEE_SCHEDULE_ITEMS_UPLOAD_FILE = 'FEE_SCHEDULE_ITEMS_UPLOAD_FILE';
export const FEE_SCHEDULE_ITEMS_UPLOAD_FILE_PENDING = 'FEE_SCHEDULE_ITEMS_UPLOAD_FILE_PENDING';
export const FEE_SCHEDULE_ITEMS_UPLOAD_FILE_FULFILLED = 'FEE_SCHEDULE_ITEMS_UPLOAD_FILE_FULFILLED';
export const FEE_SCHEDULE_ITEMS_UPLOAD_FILE_REJECTED = 'FEE_SCHEDULE_ITEMS_UPLOAD_FILE_REJECTED';

export const FEE_SCHEDULE_GET_FROM_CLIENT = 'FEE_SCHEDULE_GET_FROM_CLIENT';
export const FEE_SCHEDULE_GET_FROM_CLIENT_PENDING = 'FEE_SCHEDULE_GET_FROM_CLIENT_PENDING';
export const FEE_SCHEDULE_GET_FROM_CLIENT_FULFILLED = 'FEE_SCHEDULE_GET_FROM_CLIENT_FULFILLED';
export const FEE_SCHEDULE_GET_FROM_CLIENT_REJECTED = 'FEE_SCHEDULE_GET_FROM_CLIENT_REJECTED';

export const FEE_SCHEDULE_ITEM = 'FEE_SCHEDULE_ITEM';
export const FEE_SCHEDULE_ITEM_PENDING = 'FEE_SCHEDULE_ITEM_PENDING';
export const FEE_SCHEDULE_ITEM_FULFILLED = 'FEE_SCHEDULE_ITEM_FULFILLED';
export const FEE_SCHEDULE_ITEM_REJECTED = 'FEE_SCHEDULE_ITEM_REJECTED';

export const FEE_SCHEDULE_ITEM_LABEL_RATE = 'FEE_SCHEDULE_ITEM_LABEL_RATE';
export const FEE_SCHEDULE_ITEM_LABEL_RATE_PENDING = 'FEE_SCHEDULE_ITEM_LABEL_RATE_PENDING';
export const FEE_SCHEDULE_ITEM_LABEL_RATE_FULFILLED = 'FEE_SCHEDULE_ITEM_LABEL_RATE_FULFILLED';
export const FEE_SCHEDULE_ITEM_LABEL_RATE_REJECTED = 'FEE_SCHEDULE_ITEM_LABEL_RATE_REJECTED';

export const FEE_SCHEDULE_ITEMS_IMPORT_EXPORT_RESET = 'FEE_SCHEDULE_ITEMS_IMPORT_EXPORT_RESET';

export const FEE_SCHEDULE_ITEMS_ERROR_RESET = 'FEE_SCHEDULE_ITEMS_ERROR_RESET';
export const FEE_SCHEDULE_ITEMS_RESET = 'FEE_SCHEDULE_ITEMS_RESET';
export const FEE_SCHEDULE_SET_ENABLE_DIALOG = 'FEE_SCHEDULE_SET_ENABLE_DIALOG';
export const FEE_SCHEDULE_SET_RATE_DIALOG = 'FEE_SCHEDULE_SET_RATE_DIALOG';
export const FEE_SCHEDULE_SET_DEPENDENCY_DIALOG = 'FEE_SCHEDULE_SET_DEPENDENCY_DIALOG';
export const FEE_SCHEDULE_SET_EDIT_DIALOG = 'FEE_SCHEDULE_SET_EDIT_DIALOG';
export const FEE_SCHEDULE_LABEL_SET_DEPENDENCY_DIALOG = 'FEE_SCHEDULE_LABEL_SET_DEPENDENCY_DIALOG';
export const FEE_SCHEDULE_LABEL_RATE_SET_DEPENDENCY_DIALOG = 'FEE_SCHEDULE_LABEL_RATE_SET_DEPENDENCY_DIALOG';
export const FEE_SCHEDULE_LABEL_IMPORT_SET_DEPENDENCY_DIALOG = 'FEE_SCHEDULE_LABEL_IMPORT_SET_DEPENDENCY_DIALOG';

export const FEE_SCHEDULES_ITEMS_RESET='FEE_SCHEDULES_ITEMS_RESET'

export const getCodeTypes = () => ({
    type: FEE_SCHEDULE_ITEMS_CODE_TYPES,
});

export const create = (feeScheduleItem, callback, shouldReloadGrid) => ({
    type: FEE_SCHEDULE_ITEMS_CREATE,
    payload: { feeScheduleItem, callback, shouldReloadGrid },
});

export const update = (feeScheduleItem, callback, shouldReloadGrid) => ({
    type: FEE_SCHEDULE_ITEMS_UPDATE,
    payload: { feeScheduleItem, callback, shouldReloadGrid },
});

export const updateStatus = (payload, callback) => {
    return {
        type: FEE_SCHEDULE_ITEMS_STATUS,
        payload: { payload, callback },
    }
};

export const errorReset = () => ({
    type: FEE_SCHEDULE_ITEMS_ERROR_RESET,
});

export const downloadTemplate = (feeScheduleId, callback) => ({
    type: FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE,
    payload: { feeScheduleId, callback }
});

export const uploadFile = (feeScheduleId, ratesCSV, callback) => ({
    type: FEE_SCHEDULE_ITEMS_UPLOAD_FILE,
    payload: { feeScheduleId, ratesCSV, callback }
});

export const importExportReset = () => ({
    type: FEE_SCHEDULE_ITEMS_IMPORT_EXPORT_RESET
});

export const setEnableDialog = (enableDialog) => ({
    type: FEE_SCHEDULE_SET_ENABLE_DIALOG,
    payload: { enableDialog }
});

export const setRateDialog = (rateDialog) => ({
    type: FEE_SCHEDULE_SET_RATE_DIALOG,
    payload: { rateDialog }
});

export const deleteFeeSchedule = (feeScheduleId, callback) => ({
    type: FEE_SCHEDULE_DELETE,
    payload: { feeScheduleId, callback }
});

export const getFeeScheduleFromClient = (targetClient) => ({
    type: FEE_SCHEDULE_GET_FROM_CLIENT,
    payload: { targetClient }
});

export const getFeeScheduleDependencies = (feeScheduleId) => ({
    type: FEE_SCHEDULE_DELETE_DEPENDENCIES,
    payload: { feeScheduleId }
});

export const setFeeScheduleDepdendencyDialog = (dependencyDialog) => ({
    type: FEE_SCHEDULE_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});
export const deleteFeeScheduleItems = (feeScheduleItemsIds, callback) => ({
    type: FEE_SCHEDULE_ITEMS_DELETE,
    payload: { feeScheduleItemsIds, callback },
});

export const setFeeScheduleEditDialog = (feeScheduleDialog) => ({
    type: FEE_SCHEDULE_SET_EDIT_DIALOG,
    payload: { feeScheduleDialog }
});

export const resetFeeScheduleItems = () => ({
    type: FEE_SCHEDULES_ITEMS_RESET,
});

export const setFeeScheduleLabelDepdendencyDialog = (dependencyLabelDialog) => ({
    type: FEE_SCHEDULE_LABEL_SET_DEPENDENCY_DIALOG,
    payload: { dependencyLabelDialog }
});

export const setFeeScheduleLabelRateDepdendencyDialog = (dependencyLabelRateDialog) => ({
    type: FEE_SCHEDULE_LABEL_RATE_SET_DEPENDENCY_DIALOG,
    payload: { dependencyLabelRateDialog }
});

export const setFeeScheduleImportLabelDepdendencyDialog = (dependencyImportLabelDialog) => ({
    type: FEE_SCHEDULE_LABEL_IMPORT_SET_DEPENDENCY_DIALOG,
    payload: { dependencyImportLabelDialog }
});

export const getFeescheduleItem = (feeScheduleItemId) => ({
    type: FEE_SCHEDULE_ITEM,
    payload: { feeScheduleItemId },
});

export const getFeescheduleRateLabel = (feeScheduleId, labelId, refId, setFieldValue, callback) => ({
    type: FEE_SCHEDULE_ITEM_LABEL_RATE,
    payload: { feeScheduleId, labelId, refId, setFieldValue, callback },
});