import React, { useState, useEffect } from 'react';
import { getStartDate, getEndDate } from '../../../util/date';

import {
    FormControl, Select, MenuItem,
    makeStyles, TextField, InputLabel,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    column: {
        flexBasis: '20%',
        margin: theme.spacing(2),
    },
    textField: {
        width: 200,
        margin: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(2),
        width: 200,
    },
}));

export default function DateControl(props) {
    const classes = useStyles();
    const { setFilter, fiscalYear, startDateParam, endDateParam } = props;

    const [values, setValues] = useState({
        relativeFilter: 'ThisYear',
        startdate: getStartDate('ThisYear'),
        enddate: getEndDate('ThisYear'),
    });

    const handleRelativeFilterChange = (event) => {
        setValues(prevState => {
            return {
                ...prevState,
                relativeFilter: event.target.value,
                startdate: getStartDate(event.target.value, fiscalYear),
                enddate: getEndDate(event.target.value, fiscalYear),
            }
        });
    };

    const handleChange = (event) => {
        event.persist();
        setValues(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    useEffect(() => {
        setFilter(startDateParam, values.startdate);
    }, [values.startdate, setFilter, startDateParam]);

    useEffect(() => {
        setFilter(endDateParam, values.enddate);
    }, [values.enddate, setFilter, endDateParam]);

    return (
        <>
            <div className="column">
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="relativeFilter">Select Filter</InputLabel>
                    <Select
                        value={values.relativeFilter}
                        onChange={handleRelativeFilterChange}
                        inputProps={{
                            name: 'relativeFilter',
                            id: 'relativeFilter',
                        }}
                    >
                        <MenuItem value="ThisMonth">This Month</MenuItem>
                        <MenuItem value="LastMonth">Last Month</MenuItem>
                        <MenuItem value="Yesterday">Yesterday</MenuItem>
                        <MenuItem value="Last7Days">Last 7 Days</MenuItem>
                        <MenuItem value="Last30Days">Last 30 Days</MenuItem>
                        <MenuItem value="Last90Days">Last 90 Days</MenuItem>
                        <MenuItem value="Last6Months">Last 6 Months</MenuItem>
                        <MenuItem value="Last12Months">Last 12 Months</MenuItem>
                        <MenuItem value="ThisQuarter">This Quarter</MenuItem>
                        <MenuItem value="LastQuarter">Last Quarter</MenuItem>
                        <MenuItem value="ThisYear">This Year</MenuItem>
                        <MenuItem value="LastYear">Last Year</MenuItem>
                        <MenuItem value="ThisFiscalYear">Current Fiscal Year</MenuItem>
                        <MenuItem value="LastFiscalYear">Previous Fiscal Year</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="column">
                <TextField
                    type="date"
                    label="Start Date"
                    name="startdate"
                    className={classes.textField}
                    margin="normal"
                    onChange={handleChange}
                    value={values.startdate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className="column">
                <TextField
                    type="date"
                    label="End Date"
                    name="enddate"
                    className={classes.textField}
                    margin="normal"
                    onChange={handleChange}
                    value={values.enddate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
        </>
    )
}