import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/appId';
import * as API from '../api/appId';
import { sessionErrorHandling } from './session';


function* getAppId() {
    try {
        yield put({ type: actions.APP_ID_GET_PENDING });
        const payload = yield call(API.getAppIdList);
        yield put({ type: actions.APP_ID_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.APP_ID_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


function* create(action) {
    try {
        const { values, callback } = action.payload;
        yield put({ type: actions.APP_ID_CREATE_PENDING });
        const payload = yield call(API.createAPPId, values);
        yield put({ type: actions.APP_ID_CREATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.APP_ID_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


function* getValue(action) {
    try {
        console.log('The payload is',action.payload)
        const { id, callback } = action.payload;
        yield put({ type: actions.APP_ID_KEY_VALUE_PENDING });
        const payload = yield call(API.getAppKeyById, id);
        yield put({ type: actions.APP_ID_KEY_VALUE_FULFILLED, payload: { id: id, key: payload } });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.APP_ID_KEY_VALUE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {

    yield takeEvery(actions.APP_ID_GET, getAppId);
    yield takeEvery(actions.APP_ID_CREATE, create);
    yield takeEvery(actions.APP_ID_KEY_VALUE, getValue);


}