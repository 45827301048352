export const DRG_CREATE = 'DRG_CREATE';
export const DRG_CREATE_PENDING = 'DRG_CREATE_PENDING';
export const DRG_CREATE_FULFILLED = 'DRG_CREATE_FULFILLED';
export const DRG_CREATE_REJECTED = 'DRG_CREATE_REJECTED';

export const DRG_GET = 'DRG_GET';
export const DRG_GET_PENDING = 'DRG_GET_PENDING';
export const DRG_GET_FULFILLED = 'DRG_GET_FULFILLED';
export const DRG_GET_REJECTED = 'DRG_GET_REJECTED';

export const DRG_UPDATE = 'DRG_UPDATE';
export const DRG_UPDATE_PENDING = 'DRG_UPDATE_PENDING';
export const DRG_UPDATE_FULFILLED = 'DRG_UPDATE_FULFILLED';
export const DRG_UPDATE_REJECTED = 'DRG_UPDATE_REJECTED';

export const DRG_DELETE = 'DRG_DELETE';
export const DRG_DELETE_PENDING = 'DRG_DELETE_PENDING';
export const DRG_DELETE_FULFILLED = 'DRG_DELETE_FULFILLED';
export const DRG_DELETE_REJECTED = 'DRG_DELETE_REJECTED';

export const DRG_GET_TINY = 'DRG_GET_TINY';
export const DRG_GET_TINY_PENDING = 'DRG_GET_TINY_PENDING';
export const DRG_GET_TINY_FULFILLED = 'DRG_GET_TINY_FULFILLED';
export const DRG_GET_TINY_REJECTED = 'DRG_GET_TINY_REJECTED';

export const DRG_CREATE_UPDATE_RESET = 'DRG_CREATE_UPDATE_RESET';

export const IPPS_GET_TINY_BY_CONTRACT = 'IPPS_GET_TINY_BY_CONTRACT';
export const IPPS_GET_TINY_BY_CONTRACT_PENDING = 'IPPS_GET_TINY_BY_CONTRACT_PENDING';
export const IPPS_GET_TINY_BY_CONTRACT_FULFILLED = 'IPPS_GET_TINY_BY_CONTRACT_FULFILLED';
export const IPPS_GET_TINY_BY_CONTRACT_REJECTED = 'IPPS_GET_TINY_BY_CONTRACT_REJECTED';

export const IPPS_GET_TINY_BY_TARGET_CLIENT = 'IPPS_GET_TINY_BY_TARGET_CLIENT';
export const IPPS_GET_TINY_BY_TARGET_CLIENT_PENDING = 'IPPS_GET_TINY_BY_TARGET_CLIENT_PENDING';
export const IPPS_GET_TINY_BY_TARGET_CLIENT_FULFILLED = 'IPPS_GET_TINY_BY_TARGET_CLIENT_FULFILLED';
export const IPPS_GET_TINY_BY_TARGET_CLIENT_REJECTED = 'IPPS_GET_TINY_BY_TARGET_CLIENT_REJECTED';

export const IPPS_DELETE_DEPENDENCIES = 'IPPS_DELETE_DEPENDENCIES';
export const IPPS_DELETE_DEPENDENCIES_PENDING = 'IPPS_DELETE_DEPENDENCIES_PENDING';
export const IPPS_DELETE_DEPENDENCIES_FULFILLED = 'IPPS_DELETE_DEPENDENCIES_FULFILLED';
export const IPPS_DELETE_DEPENDENCIES_REJECTED = 'IPPS_DELETE_DEPENDENCIES_REJECTED';

export const IPPS_SET_DEPENDENCY_DIALOG = 'IPPS_SET_DEPENDENCY_DIALOG';
export const IPPS_RESET_DEPENDENCIES = 'IPPS_RESET_DEPENDENCIES';

export const createDRG = (values, callback, shouldReloadGrid) => ({
    type: DRG_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const editDRG = (values, callback, shouldReloadGrid) => ({
    type: DRG_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const deleteDRG = (drgId, callback) => ({
    type: DRG_DELETE,
    payload: { drgId, callback }
});

export const createEditReset = () => ({
    type: DRG_CREATE_UPDATE_RESET
});

export const getDRG = (drgId) => ({
    type: DRG_GET,
    payload: { drgId }
});

export const getDRGTiny = () => ({
    type: DRG_GET_TINY
});

export const getIPPSTinyByContract = (contractId) => ({
    type: IPPS_GET_TINY_BY_CONTRACT,
    payload: { contractId }
});

export const getIPPSTinyByTargetClient = (targetClientId) => ({
    type: IPPS_GET_TINY_BY_TARGET_CLIENT,
    payload: { targetClientId }
});

export const getIPPSDeleteDependencies = (id) => ({
    type: IPPS_DELETE_DEPENDENCIES,
    payload: { id }
});

export const setDependencyDialog = (dependencyDialog) => ({
    type: IPPS_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});

export const resetDependencies = () => ({
    type: IPPS_RESET_DEPENDENCIES,
});