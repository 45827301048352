import React, { useEffect, useState } from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import { DataTable, DataFilter, TablePaginationActions } from '../../../common';
import { IconButton, makeStyles, TablePagination, Tooltip } from '@material-ui/core';
import Helmet from 'react-helmet';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Edit as EditIcon } from '@material-ui/icons';
import ManageAccountsIcon from '@material-ui/icons/Settings';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  textField: {
    width: 400,
  },
}));

export default function ClientsPage({
  intl,
  clientsFilteredData, fields, sort, clientListPending, history,
  order, filter, actions: { setSort, setOrder, clientList, setFilter }
}) {
  const classes = useStyles();
  const search = useLocation().search;
  const persist = new URLSearchParams(search).get('persist');

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (!persist) {
      setFilter('');
    }
    clientList();
  }, [clientList]);

  const handleSearchInput = (value) => {
    setFilter(value);
  }

  const handleAddClient = () => {
    history.push('/admin/clients/edit/new');
  }

  const handleClientEdit = (client) => {
    history.push(`/admin/clients/edit/${client.clientId}`);
  }

  const handleClientView = (client) => {
    history.push(`/admin/clients/view/${client.clientId}`);
  }

  const handleClientConfiguration = (client) => {
    history.push(`/admin/clients/configuration/${client.clientId}`);

  }

  function clientsAction(rowData) {
    return <div className='d-flex'>
      <IconButton
        color="primary"
        className='p-1'
        aria-label="View"
        onClick={() => handleClientView(rowData)}
      >
        <Tooltip title="View">
          <PageviewIcon />
        </Tooltip>
      </IconButton>
      <IconButton
        color="primary"
        className='p-1'
        aria-label="Edit"
        onClick={() => handleClientEdit(rowData)}
      >
        <Tooltip title="Edit">
          <EditIcon />
        </Tooltip>
      </IconButton>
      <IconButton
        color="primary"
        className='p-1'
        aria-label="Edit"
        onClick={() => handleClientConfiguration(rowData)}
      >
        <Tooltip title="Configuration">
          <ManageAccountsIcon />
        </Tooltip>
      </IconButton>

    </div>
  }

  function handleRefresh() {
    clientList();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hoverData = (client) => {
    const hoverColumns = new Map();
    const productFlagsColumn = [
      {
        label: "Dashbaords",
        data: client.products?.hasDashboardAccess ? 'Yes' : 'No',
      },
      {
        label: "Contract Management",
        data: client.products?.hasCMSAccess ? 'Yes' : 'No',
      },
      {
        label: "Claim Estimation",
        data: client.products?.hasEstimatorAccess ? 'Yes' : 'No',
      },
      {
        label: "Price Transparency",
        data: client.products?.hasPricerAccess ? 'Yes' : 'No',
      },
      {
        label: "Vendor Management",
        data: client.products?.hasVendorManagementAccess ? 'Yes' : 'No',
      }
    ];

    hoverColumns.set('hasDashboardAccess', productFlagsColumn);

    return hoverColumns;
  }

  return (
    <>
      <Helmet title="Client Management" />
      <Portlet>
        <PortletHeader
          title="Clients"
          toolbar={
            <PortletHeaderToolbar>
              <DataFilter
                onChange={handleSearchInput}
                placeholder="Search"
                cssClass={classes.textField}
                filter={filter}
              />
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <DataTable
            fields={fields}
            data={clientsFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            onAdd={handleAddClient}
            order={order}
            onOrder={setOrder}
            sort={sort}
            onSort={setSort}
            loading={clientListPending}
            name="Client"
            handleRefresh={handleRefresh}
            showPagination={true}
            hoverData={hoverData}
            footerPagination={() => (
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                colSpan={fields.length + 2}
                count={clientsFilteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}

            hasActions={true}
            renderActionColumn={clientsAction}
          />
        </PortletBody>
      </Portlet>
    </>
  );
}
