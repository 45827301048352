export const selectForgotPasswordPending = (state) => state.forgotpassword.forgotPassword.pending;

export const selectForgotPasswordError = (state) => state.forgotpassword.forgotPassword.error;

export const selectForgotPasswordErrorMessage = (state) => state.forgotpassword.forgotPassword.errorMessage;

export const selectResetPasswordPending = (state) => state.forgotpassword.resetPassword.pending;

export const selectResetPasswordError = (state) => state.forgotpassword.resetPassword.error;

export const selectResetPasswordErrorMessage = (state) => state.forgotpassword.resetPassword.errorMessage;

export const selectVerifyTokenError = (state) => state.forgotpassword.verifyToken.error;

export const selectVerifyTokenErrorMessage = (state) => state.forgotpassword.verifyToken.errorMessage;

export const selectToken = (state) => state.forgotpassword.verifyToken.token;