import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import PartnersEditPageComponent from './PartnersEditPage.js';
import { create, update, get } from '../../../../store/actions/partners';

import { selectClientListTiny } from "../../../../store/selectors/clients";
import { createPartnerSelector } from "../../../../store/selectors/partners";
import { selectIsSuperAdmin } from "../../../../store/selectors/session";

export const PartnersEditPage = connect(
    () => {
        const selectPartnerId = (state, props) => props.match.params.partnerId;

        const selectMode = (state, props) => props.match.params.partnerId === 'new' ? 'create' : 'edit';

        const selectPartner = createPartnerSelector(selectPartnerId);

        const selectPartners = createSelector(
            selectMode,
            selectClientListTiny,
            (mode, clients) => {
                if (mode === 'edit') {
                    return clients.filter(x => x.name !== 'SYSTEM').sort((a, b) => a.name.localeCompare(b.name))
                }
                return clients.filter(x => x.name !== 'SYSTEM' && x.active).sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        const selectInitialValues = createSelector(
            selectMode,
            selectPartner,
            (mode, partner) => {
                if (mode === 'edit' && partner) {
                    return {
                        id: partner.id,
                        name: partner.name,
                        description: partner.description,
                        clientIds: partner.clientIds,
                        active: partner.active,
                    }
                }

                return {
                    name: "",
                    description: "",
                    clientIds: [],
                    active: false,
                }
            }
        )

        return (state, props) => ({

            // Loaders
            partnersCreatePending: state.partners.create.pending,
            partnersUpdatePending: state.partners.update.pending,
            partnersGetPending: state.partners.get.pending,
            dashboardListTinyPending: state.dashboards.tiny.pending,

            isSuperAdmin: selectIsSuperAdmin(state),
            partners: selectPartners(state, props),

            // edit
            partner: selectPartner(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            partnerId: selectPartnerId(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            partnerCreate: create,
            partnerUpdate: update,
            partnerGet: get,
        }, dispatch)
    })
)(injectIntl(PartnersEditPageComponent));