import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import CommentsFormComponent from './CommentsForm';
import { createSelector } from 'reselect';
import { selectSettings } from '../../../../store/selectors/settings';
import { DEFAULT_FOLLOWUP_DATE_ID } from '../../../../store/constants/settings';
import { addNDaysToDate } from '../../../../util/date';
import { bindActionCreators } from 'redux';
import { acknowledge, getClaimComments,setClaimComments } from '../../../../store/actions/claimAnalysis';

export const CommentsForm = connect(
    () => {

        const selectDetailsFromComments = (state) => state.claimAnalysis.comments;

        const selectComments = createSelector(selectDetailsFromComments,
            (details) => {
                if (details) {
                    return details?.comments ?? []
                }
                return []
            }
        );

        const selectFollowUpFlag = createSelector(selectDetailsFromComments,
            (details) => {
                if (details) {
                    return details?.flag ?? false
                }
                return false
            }
        );

        const selectAcknowledged = createSelector(selectDetailsFromComments,
            (details) => {
                if (details) {
                    return details?.acknowledged ?? false
                }
                return false
            }
        );

        const selectFollowUpAlert = createSelector(selectDetailsFromComments,
            (details) => {
                if (details) {
                    return details?.followupAlert ?? null
                }
                return null
            }
        );

        const selectAcknowledgementDate = createSelector(selectDetailsFromComments,
            (details) => {
                if (details) {
                    return details?.acknowledgementDate ?? null
                }
                return null
            }
        );

        const selectDefaultFollowUpDate = createSelector(selectSettings,
            (settings) => {
                const followUpDaysSetting = settings.find(setting => setting.id === DEFAULT_FOLLOWUP_DATE_ID);
                return addNDaysToDate(followUpDaysSetting ? parseInt(followUpDaysSetting.value) : 0)
            }
        );

        return (state, props) => ({
            comments: selectComments(state),
            followUpFlag: selectFollowUpFlag(state),
            claimAcknowledged: selectAcknowledged(state),
            claimFollowUpAlert: selectFollowUpAlert(state),
            claimAcknowledgementDate: selectAcknowledgementDate(state),
            defaultFollowupDate: selectDefaultFollowUpDate(state, props),
            acknowledgeClaimPending: state.claimAnalysis.acknowledge.pending,
            getCommentsPending: state.claimAnalysis.getComments.pending,
            comment: state.claimAnalysis.newComment,

        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            acknowledgeClaim: acknowledge,
            getClaimComments: getClaimComments,
            setComment:setClaimComments
        }, dispatch)
    })
)(injectIntl(CommentsFormComponent));