import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Loadable } from '../../../../../common';
import { DataTile } from '../../../../../common/widgets/DataTile';

const useStyles = makeStyles(theme => ({
    tile: {
        flex: '20%',
        '& > div': {
            height: '100%',
        }
    }
}));

export default function ModelResultsStatistics(props) {
    const {
        getModelStatsPending,
        modelStats,
    } = props;
    const classes = useStyles();
    return (
        <Loadable loading={getModelStatsPending}>
            <Grid container spacing={2}>
                <Grid item className={classes.tile}>
                    <DataTile title="Total Claims" value={modelStats?.totalClaims??0} isTwoDecimal={false} color="primary" />
                </Grid>
                <Grid item className={classes.tile}>
                    <DataTile title="Total Charges" value={modelStats?.totalCharges??0} isTwoDecimal={true} color="info" />
                </Grid>
                <Grid item className={classes.tile}>
                    <DataTile title={modelStats?.totalContract1ExpectedPayment?.key??'Total Contract 1'} value={modelStats?.totalContract1ExpectedPayment?.value??0} isTwoDecimal={true} color="success" />
                </Grid>
                <Grid item className={classes.tile}>
                    <DataTile title={modelStats?.totalContract2ExpectedPayment?.key??'Total Contract 2'} value={modelStats?.totalContract2ExpectedPayment?.value??0} isTwoDecimal={true} color="success" />
                </Grid>
                <Grid item className={classes.tile}>
                    <DataTile title="Total Difference" value={modelStats?.totalDifference??0} isTwoDecimal={true} color="danger" />
                </Grid>
            </Grid>
        </Loadable>
    );
}