import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ContractModelingPageComponent from './ContractModelingPage';
import { contractModelingLanding } from '../../../store/actions/pages';
import { createIconDataField, createListField, createProgressBarField, createStringDataField, createTimestampField } from '../../../util/format';
import { StringEquators, dateEquators } from '../../../store/constants/pages';
import { createSelector } from 'reselect';
import { lockModel, setContractModelEditDialog, unlockModel,stopExecution,setStopExecutionDialog } from '../../../store/actions/modeling';
import { selectSessionRole } from '../../../store/selectors/session';
import { set } from '../../../store/actions/pages';
import { tiny } from '../../../store/actions/contracts';
import { getByName } from '../../../store/actions/search';
import { selectCanAccessClaimAudit } from '../../../store/selectors/access';

export const ContractModelingPage = connect(
    () => {
        const selectFields = createSelector(
            () => {
                return [
                    createStringDataField('name', 'Name', { sortable: true, equators: StringEquators }),
                    createStringDataField('owner', 'Owner', { sortable: true, equators: StringEquators }),
                    createListField('tinyContracts', 'Contracts', { returnWholeRow: true, hideFromFilter: true }),
                    createStringDataField('updatedBy', 'Updated By', { sortable: true, equators: StringEquators }),
                    // createTimestampField('updatedAt', 'Updated At', { sortable: true, equators: dateEquators }),
                    createProgressBarField('processedClaims', 'Progress', 'processedClaims', 'totalClaims', 'Claims'),
                    createStringDataField('modelAnalysisExecution.executionType', 'Execution Type', { sortable: false, equators: StringEquators, hideFromFilter: true }),
                    createStringDataField('modelAnalysisExecution.executedBy', 'Executed By', { sortable: false, equators: StringEquators, hideFromFilter: true }),
                    createTimestampField('executedAt', 'Executed At', { sortable: false, equators: dateEquators, hideFromFilter: true }),
                    createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
                    // createUploadsStatusField('state', 'Status', 'exception', { equators: StringEquators, returnWholeRow: true, hideFromFilter: true })
                ]
            }
        );

        const selectModelsData = createSelector(
            (state) => state.pages['model-claim-landing'].list,
            (data) => {
                return data.map(item => {
                    return {
                        ...item,
                        tinyContracts: item.contractModelXref?.map(con => con.name), // looping this to list contract names in the grid
                        executedAt: item.modelAnalysisExecution?.executedAt,
                        state: item.modelAnalysisExecution?.state,
                        processedClaims: item.modelAnalysisExecution?.processedClaims,
                        totalClaims: item.modelAnalysisExecution?.totalClaims,
                        exception: item.modelAnalysisExecution?.exception
                    }
                })
            }
        );

        return (state, props) => ({
            fields: selectFields(state),
            modelsData: selectModelsData(state),
            modelLockPending: state.modeling.lockModel.pending,
            modelUnlockPending: state.modeling.unlockModel.pending,
            userRole: selectSessionRole(state),
            stopPending:state.executions.stop.pending,
            canAnalyzeModel:selectCanAccessClaimAudit(state)

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            contractModelingLanding: contractModelingLanding,
            lockModel: lockModel,
            unlockModel: unlockModel,
            setList: (value) => set('model-claim-landing', 'list', value),
            setContractModelEditDialog: setContractModelEditDialog,
            getContractsTiny: tiny,
            searchGetByName: getByName,
            stopExecution:stopExecution,
            setStopExecutionDialog:setStopExecutionDialog,
        }, dispatch)
    })
)(injectIntl(ContractModelingPageComponent));