import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Loadable } from '../../../common';
import { DataTile } from '../../../common/widgets/DataTile';

const useStyles = makeStyles(theme => ({
    tile: {
        flex: ({ recoveredAmountFlag }) => recoveredAmountFlag ? '20%' : '25%',
        '& > div': {
            height: '100%',
        }
    }
}));

export default function ClaimStatistics(props) {
    const {
        getStatsPending,
        stats: {
            totalCharges,
            estimatedPayment,
            actualPayment,
            totalVariance,
            totalRecoveredAmount
        },
        recoveredAmountFlag,
    } = props;
    const classes = useStyles({ recoveredAmountFlag });
    return (
        <Loadable loading={getStatsPending}>
            <Grid container spacing={2}>
                <Grid item className={classes.tile}>
                    <DataTile title="Total Charges" value={totalCharges} isTwoDecimal={true} color="info" />
                </Grid>
                <Grid item className={classes.tile}>
                    <DataTile title="Total Estimated Payments" value={estimatedPayment} isTwoDecimal={true} color="success" />
                </Grid>
                <Grid item className={classes.tile}>
                    <DataTile title="Total Allowables" value={actualPayment} isTwoDecimal={true} color="success" />
                </Grid>
                <Grid item className={classes.tile}>
                    <DataTile title="Total Variances" value={totalVariance} isTwoDecimal={true} color="danger" />
                </Grid>
                {(recoveredAmountFlag === true) && (
                    <Grid item className={classes.tile}>
                        <DataTile title="Total Recovered Amount" value={totalRecoveredAmount} isTwoDecimal={true} color="danger" />
                    </Grid>
                )}
            </Grid>
        </Loadable>
    );
}