import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DeleteItemsComponent from './DeleteItems';
import { deleteFeeScheduleItems } from '../../../../../../../store/actions/feeScheduleItems';
import { setV2 } from '../../../../../../../store/actions/pagesV2';

export const DeleteItems = connect(
    () => {
        const selectFeeScheduleItemsIds = (state, props) => state.pagesV2['feeschedule-items-landing'].selected;

        return (state, props) => ({
            selectedItems: selectFeeScheduleItemsIds(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            deleteFeeScheduleItems: deleteFeeScheduleItems,
            setSelected: (value) => setV2('feeschedule-items-landing', 'selected', value)
        }, dispatch)
    })
)(injectIntl(DeleteItemsComponent));