import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Checkbox, Typography } from '@material-ui/core';
import { Loadable } from '../../../../../common';

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: theme.spacing(0.5),
  },
}));

export default function GroupsTreeView(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState([]);

  const { ruleId, groupsTree, contractGetTreeGroupPending, handleGroupSelect, selectedGroups,
    actions: { contractGetTreeGroups } } = props;

  const editCheckbox = typeof handleGroupSelect !== 'undefined';

  function loadTreeData(level, name, parentId) {
    const treePayload = {
      ruleId: ruleId,
      level: level || 0,
      nodeName: name || '',
      parentId: parentId || null,
    }
    contractGetTreeGroups(treePayload);
  }

  useEffect(() => {
    loadTreeData();
  }, []);

  const handleExpanded = (nodeId, expandedNodes) => {
    setExpanded(expandedNodes);
  }

  const handleNodeSelect = (event, nodeId) => {
    const isExpanded = expanded.filter(x => x === nodeId);

    // if node is already expanded, while closing don't call the api.
    if (isExpanded.length <= 0) {
      const params = nodeId.split('#%');

      const parentId = parseInt(params[0]);
      const currentNodeLevel = parseInt(params[1]);
      const name = params[2];

      // increase the level for next set of data.
      loadTreeData(currentNodeLevel + 1, name, parentId);
    }
  }

  const createGroupsTree = (groups, level) => {
    const elements = [];
    const currentLevel = level || 0;
    groups.forEach((v, k) => {
      const { id, selected, children } = v;
      const name = v[`l${currentLevel}Description`];
      const editEnabled = typeof handleGroupSelect !== 'undefined';
      let checked = selected;
      
      if (editEnabled) {
        checked = selectedGroups.indexOf(id) !== -1;
      }

      const label = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            id={`checkbox-${k}`}
            className={classes.checkbox}
            checked={checked}
            disabled={typeof handleGroupSelect === 'undefined'}
            onChange={(event, checked) => handleGroupSelect(event, checked, id)}
            onClick={e => (e.stopPropagation())}
            color="primary"
          />
          <Typography variant="subtitle1">{name}</Typography>
        </div>
      );
      elements.push(
        children && children.length > 0 ? (
          <TreeItem key={id} nodeId={`${id}#%${currentLevel}#%${name}`} label={label} >
            {createGroupsTree(children, currentLevel + 1)}
          </TreeItem >
        ) : (
          <TreeItem key={id} nodeId={`${id}#%${currentLevel}#%${name}`} label={label} >
            <div key={id} />
          </TreeItem >
        )
      );
    });

    return elements;
  }

  return (
    <Loadable loading={contractGetTreeGroupPending}>
      <TreeView
        onNodeToggle={(nodeId, nodeExpanded) => handleExpanded(nodeId, nodeExpanded)}
        onNodeSelect={(event, nodeId) => handleNodeSelect(event, nodeId)}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {groupsTree.length !== 0 ? createGroupsTree(groupsTree) : <h6>No groups found</h6>}
      </TreeView>
    </Loadable>
  );
}
