import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import RulePageTopFilterComponent from './RulePageTopFilter';
import { setRuleStatus } from '../../../../../../store/actions/rules';

export const RulePageTopFilter = connect(
    () => {

        return (state, props) => ({
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setRuleStatus: setRuleStatus
        }, dispatch)
    })
)(injectIntl(RulePageTopFilterComponent));