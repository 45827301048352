import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import PartnersPageComponent from './PartnersPage.js';
import { list, deletePartner, activatePartner, deactivatePartner, setDependencyDialog } from '../../../store/actions/partners';
import { set } from '../../../store/actions/pages';
import { selectPartners } from '../../../store/selectors/partners';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { createStringDataField, createClientListField, createDateField, createIconDataField } from '../../../util/format';
import { selectClientListTiny } from "../../../store/selectors/clients";

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectClientList = createSelector(
    selectClientListTiny,
    (clients) => {
        return clients.filter(x => x.name !== 'SYSTEM');
    }
);

const selectFields = createSelector(
    selectClientList,
    (clients) => [
        createStringDataField('name', 'Name', { sortable: true }),
        createStringDataField('description', 'Description', { truncate: true, sortable: true }),
        createStringDataField('updatedBy', 'Updated By', { sortable: true }),
        createDateField('updatedOn', 'Updated At', { sortable: true }),
        createClientListField('clientIds', 'Client Name', { sortable: true, clients: clients }),
        // createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['partners-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['partners-landing'].filter,
    selectSort,
    selectPartners,
);

export const PartnersPage = injectIntl(
    connect(
        () => {
            return (state) => ({
                order: state.pages['partners-landing'].order,
                filter: state.pages['partners-landing'].filter,
                sort: selectSort(state),
                partners: selectPartners(state),
                partnersFilteredData: selectFilteredData(state),
                fields: selectFields(state),
                partnerListPending: state.partners.list.pending,
                partnerDeletePending: state.partners.delete.pending,
                partnerActivatePending: state.partners.activate.pending,
                partnerDeactivatePending: state.partners.deactivate.pending,
            })
        },
        (dispatch) => ({
            actions: bindActionCreators({
                partnetsList: list,
                partnerDelete: deletePartner,
                setFilter: (value) => set('partners-landing', 'filter', value),
                setOrder: (value) => set('partners-landing', 'order', value),
                setSort: (value) => set('partners-landing', 'sort', value),
                activatePartner: activatePartner,
                deactivatePartner: deactivatePartner,
                setDependencyDialog: setDependencyDialog
            }, dispatch)
        })
    )(PartnersPageComponent));