import React from 'react';
import {
    Button, Dialog, DialogContent, DialogTitle,
    TextField, makeStyles, Grid, Switch, FormControlLabel,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { Formik } from "formik";
import { Loadable } from '../../../common';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    commentText: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
}));

export default function AcknowledgeDialog(props) {
    const {
        open, handleClose, mode, intl, initialValues, acknowledgeClaimPending, selectedClaims = [],
        actions: { acknowledgeClaim, setSelected }
    } = props;
    const classes = useStyles();
    let shouldReloadGrid = false;

    function handleCallback(reload) {
        setSelected([]);
        handleClose(reload);
    }

    return (
        <Dialog fullWidth open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`Acknowledge Claim (${selectedClaims.length})`}
                {/* {claimNumber && (
                    <Chip size="small" label={` # ${claimNumber}`} color="primary" variant="outlined" />
                )} */}
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={acknowledgeClaimPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            if (!values.comment) {
                                errors.comment = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values) => {
                            shouldReloadGrid = true;
                            let payload = {
                                analysisId: values.analysisId,
                                comment: values.comment,
                                acknowledge: values.acknowledge
                            }
                            acknowledgeClaim(payload, handleCallback, shouldReloadGrid);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>

                                <div className={classes.root}>
                                    <Grid container>
                                        {selectedClaims && selectedClaims.length < 1 && (
                                            <Grid item xs={12}>
                                                <Alert severity="error">Please select atleast 1 claim to acknowledge.</Alert>
                                            </Grid>
                                        )}

                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                required
                                                label="Comment"
                                                name="comment"
                                                multiline
                                                rows={4}
                                                className={classes.commentText}
                                                value={values.comment}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.comment && errors.comment)}
                                                helperText={touched.comment && errors.comment}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="acknowledge"
                                                        color="primary"
                                                        checked={values.acknowledge}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={classes.switch}
                                                        value={values.acknowledge}
                                                    />
                                                }
                                                label="Acknowledge"
                                            />
                                        </Grid>
                                        <Grid container justify="flex-end">
                                            <Grid item xs={2}>
                                                <Button
                                                    onClick={() => handleClose(false)}
                                                    variant="contained"
                                                    color="default"
                                                    className={classes.button}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    type="submit"
                                                    disabled={acknowledgeClaimPending || selectedClaims.length === 0}
                                                >
                                                    {(acknowledgeClaimPending) ? 'Saving..' : 'Save'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}