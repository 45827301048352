export const DASHBOARD_DATA_SOURCES_GET = 'DASHBOARD_DATA_SOURCES_GET';
export const DASHBOARD_DATA_SOURCES_GET_PENDING = 'DASHBOARD_DATA_SOURCES_GET_PENDING';
export const DASHBOARD_DATA_SOURCES_GET_FULFILLED = 'DASHBOARD_DATA_SOURCES_GET_FULFILLED';
export const DASHBOARD_DATA_SOURCES_GET_REJECTED = 'DASHBOARD_DATA_SOURCES_GET_REJECTED';

export const DASHBOARD_DATA_SOURCES_CREATE = 'DASHBOARD_DATA_SOURCES_CREATE';
export const DASHBOARD_DATA_SOURCES_CREATE_PENDING = 'DASHBOARD_DATA_SOURCES_CREATE_PENDING';
export const DASHBOARD_DATA_SOURCES_CREATE_FULFILLED = 'DASHBOARD_DATA_SOURCES_CREATE_FULFILLED';
export const DASHBOARD_DATA_SOURCES_CREATE_REJECTED = 'DASHBOARD_DATA_SOURCES_CREATE_REJECTED';

export const DASHBOARD_DATA_SOURCES_UPDATE = 'DASHBOARD_DATA_SOURCES_UPDATE';
export const DASHBOARD_DATA_SOURCES_UPDATE_PENDING = 'DASHBOARD_DATA_SOURCES_UPDATE_PENDING';
export const DASHBOARD_DATA_SOURCES_UPDATE_FULFILLED = 'DASHBOARD_DATA_SOURCES_UPDATE_FULFILLED';
export const DASHBOARD_DATA_SOURCES_UPDATE_REJECTED = 'DASHBOARD_DATA_SOURCES_UPDATE_REJECTED';

export const DASHBOARD_DATA_SOURCES_LIST = 'DASHBOARD_DATA_SOURCES_LIST';
export const DASHBOARD_DATA_SOURCES_LIST_PENDING = 'DASHBOARD_DATA_SOURCES_LIST_PENDING';
export const DASHBOARD_DATA_SOURCES_LIST_FULFILLED = 'DASHBOARD_DATA_SOURCES_LIST_FULFILLED';
export const DASHBOARD_DATA_SOURCES_LIST_REJECTED = 'DASHBOARD_DATA_SOURCES_LIST_REJECTED';

export const DASHBOARD_DATA_SOURCES_DELETE = 'DASHBOARD_DATA_SOURCES_DELETE';
export const DASHBOARD_DATA_SOURCES_DELETE_PENDING = 'DASHBOARD_DATA_SOURCES_DELETE_PENDING';
export const DASHBOARD_DATA_SOURCES_DELETE_FULFILLED = 'DASHBOARD_DATA_SOURCES_DELETE_FULFILLED';
export const DASHBOARD_DATA_SOURCES_DELETE_REJECTED = 'DASHBOARD_DATA_SOURCES_GET_URL_REJECTED';

export const DASHBOARD_DATA_SOURCES_ERROR_RESET = 'DASHBOARD_DATA_SOURCES_ERROR_RESET';
export const DASHBOARD_DATA_SOURCES_RESET = 'DASHBOARD_DATA_SOURCES_RESET';

export const DASHBOARD_DATA_SOURCES_FIELDS_GET = 'DASHBOARD_DATA_SOURCES_FIELDS_GET';
export const DASHBOARD_DATA_SOURCES_FIELDS_GET_PENDING = 'DASHBOARD_DATA_SOURCES_FIELDS_GET_PENDING';
export const DASHBOARD_DATA_SOURCES_FIELDS_GET_FULFILLED = 'DASHBOARD_DATA_SOURCES_FIELDS_GET_FULFILLED';
export const DASHBOARD_DATA_SOURCES_FIELDS_GET_REJECTED = 'DASHBOARD_DATA_SOURCES_FIELDS_GET_REJECTED';

export const list = () => ({
    type: DASHBOARD_DATA_SOURCES_LIST,
});

export const get = (dashboardDataSourceId, callback) => ({
    type: DASHBOARD_DATA_SOURCES_GET,
    payload: { dashboardDataSourceId, callback },
});

export const create = (dashboardDataSource, callback) => ({
    type: DASHBOARD_DATA_SOURCES_CREATE,
    payload: { dashboardDataSource, callback },
});

export const update = (id, dashboardDataSource, callback) => ({
    type: DASHBOARD_DATA_SOURCES_UPDATE,
    payload: { id, dashboardDataSource, callback },
});

export const deleteDashboardDataSource = (id, callback) => ({
    type: DASHBOARD_DATA_SOURCES_DELETE,
    payload: { id, callback },
});

export const getFields = (dashboardDataSourceId, callback) => ({
    type: DASHBOARD_DATA_SOURCES_FIELDS_GET,
    payload: { dashboardDataSourceId, callback },
});

export const errorReset = () => ({
    type: DASHBOARD_DATA_SOURCES_ERROR_RESET,
});