export const API_KEY_CREATE = 'API_KEY_CREATE';
export const API_KEY_CREATE_PENDING = 'API_KEY_CREATE_PENDING';
export const API_KEY_CREATE_FULFILLED = 'API_KEY_CREATE_FULFILLED';
export const API_KEY_CREATE_REJECTED = 'API_KEY_CREATE_REJECTED';

export const API_KEYS_GET = 'API_KEYS_GET';
export const API_KEYS_GET_PENDING = 'API_KEYS_GET_PENDING';
export const API_KEYS_GET_FULFILLED = 'API_KEYS_GET_FULFILLED';
export const API_KEYS_GET_REJECTED = 'API_KEYS_GET_REJECTED';

export const API_KEY_GET_VALUE = 'API_KEY_GET_VALUE';
export const API_KEY_GET_VALUE_PENDING = 'API_KEY_GET_VALUE_PENDING';
export const API_KEY_GET_VALUE_FULFILLED = 'API_KEY_GET_VALUE_FULFILLED';
export const API_KEY_GET_VALUE_REJECTED = 'API_KEY_GET_VALUE_REJECTED';

export const API_KEY_ACTIVATE = 'API_KEY_ACTIVATE';
export const API_KEY_ACTIVATE_PENDING = 'API_KEY_ACTIVATE_PENDING';
export const API_KEY_ACTIVATE_FULFILLED = 'API_KEY_ACTIVATE_FULFILLED';
export const API_KEY_ACTIVATE_REJECTED = 'API_KEY_ACTIVATE_REJECTED';

export const API_KEY_DEACTIVATE = 'API_KEY_DEACTIVATE';
export const API_KEY_DEACTIVATE_PENDING = 'API_KEY_DEACTIVATE_PENDING';
export const API_KEY_DEACTIVATE_FULFILLED = 'API_KEY_DEACTIVATE_FULFILLED';
export const API_KEY_DEACTIVATE_REJECTED = 'API_KEY_DEACTIVATE_REJECTED';

export const API_KEY_RESET_ACTUAL_KEY = 'API_KEY_RESET_ACTUAL_KEY';
export const API_KEY_RESET_LIST = 'API_KEY_RESET_LIST'

export const API_KEY_GET_TINY_LIST = 'API_KEY_GET_TINY_LIST';
export const API_KEY_GET_TINY_LIST_PENDING = 'API_KEY_GET_TINY_LIST_PENDING';
export const API_KEY_GET_TINY_LIST_FULFILLED = 'API_KEY_GET_TINY_LIST_FULFILLED';
export const API_KEY_GET_TINY_LIST_REJECTED = 'API_KEY_GET_TINY_LIST_REJECTED';

export const createAPIKey = (values, callback) => ({
    type: API_KEY_CREATE,
    payload: { values, callback }
});

export const getAPIKeyValue = (apikeyId, callback) => ({
    type: API_KEY_GET_VALUE,
    payload: { apikeyId, callback }
});

export const getAPIKeys = () => ({
    type: API_KEYS_GET
});

export const resetActualKey = () => ({
    type: API_KEY_RESET_ACTUAL_KEY
});

export const deactivateAPIKey = (apiKeyId, callback) => ({
    type: API_KEY_DEACTIVATE,
    payload: { apiKeyId, callback }
});

export const activateAPIKey = (apiKeyId, callback) => ({
    type: API_KEY_ACTIVATE,
    payload: { apiKeyId, callback }
});

export const resetAPIKeysList = () => ({
    type: API_KEY_RESET_LIST
});

export const getTinyList = () => ({
    type: API_KEY_GET_TINY_LIST,
});