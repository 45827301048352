import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, TextField, FormControl, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import { getEndDate, getStartDate } from '../../util/date';
// import AdjustIcon from '@materialui/icons/Adjust';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '200px',
        marginTop: 2,
    },
    icon: {
        fontSize: '10px',
        color: '#C8C8C8',
    },
    container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default function SearchTitle({ intl, title, searchFilters = [], handleRefresh, rawFilters,
    name, shouldNotSetDefault, isSearchApiCompleted, selectedFilter, pending, tableFilters,
    showSearchCriteriaDropdown = true,userSession,
    actions: { setMulti }
}) {
    const [values, setValues] = useState({ criteria: null });
    const [isFirstTime, setFirstTime] = useState(true);
    const classes = useStyles();

    function handleChange(event, value) {
        setValues({
            criteria: value,
        });
        if (value != null) {
            localStorage.removeItem("isCriteriaCleared")
        }   
    }

    function sortByUserId(arr, userId) {
        return arr.sort((a, b) => {
            if (a.userId === userId && b.userId !== userId) return -1;
            if (a.userId !== userId && b.userId === userId) return 1;
            return 0; // Leave the order unchanged for other items
        });
     }

     const compareChangesinSelectedFilters = (tableFilters,values) => {
        const {selectedFilter}=tableFilters
        const {criteria}=values

        return (
            selectedFilter?.id === criteria?.id &&
            selectedFilter?.criteriaName === criteria?.criteriaName &&
            selectedFilter?.searchCriteriaType?.name ===
                criteria?.searchCriteriaType?.name &&
            selectedFilter?.isDefault === criteria?.isDefault &&
            selectedFilter?.isPublic === criteria?.isPublic
        ); 

     }

    // ****** HACK This is to set criteria based top filters *******
    function setClaimCriteriaFilter(fil, customFilter, criterias) {
        if (
            compareChangesinSelectedFilters(tableFilters,values)||
            localStorage.getItem("isCriteriaCleared")
        ) {
            return;
        }
        let modifiedCustomFilters = customFilter.filter(item => item.type !== 'DATE' && item.type !== 'SIGNIFICANCE');

        const dateObj = customFilter.find(item => item.type === 'DATE')
        if (dateObj) {
            if (dateObj.relativeFilter === 'custom') {
                modifiedCustomFilters = [...modifiedCustomFilters, ...dateObj.dates.map(item => ({ ...item, field: dateObj.field, relativeFilter: dateObj.relativeFilter }))]
            } else {
                const startdate = getStartDate(dateObj.relativeFilter);
                const enddate = getEndDate(dateObj.relativeFilter);

                modifiedCustomFilters = [
                    ...modifiedCustomFilters,
                    {
                        field: dateObj.field,
                        equator: 'greater_than_or_equals_to',
                        value: startdate,
                        relativeFilter: dateObj.relativeFilter
                    },
                    {
                        field: dateObj.field,
                        equator: 'less_than_or_equals_to',
                        value: enddate,
                        relativeFilter: dateObj.relativeFilter
                    }
                ];
            }
        }

        const significanceObj = customFilter.find(item => item.type === 'SIGNIFICANCE');

        if (isFirstTime) {
            if (rawFilters.length === 0) {
                setMulti({
                    filter: fil,
                    customFilter: modifiedCustomFilters,
                    selectedFilter: criterias,
                    waitForCriteriaFilter: false,
                    list: [],
                    isCustomFilterSetEmpty: modifiedCustomFilters.length === 0 ? true : false,
                    significance: significanceObj ? significanceObj.value : true
                });
            } else {
                setMulti({
                    filter: rawFilters,
                    selectedFilter: criterias,
                    waitForCriteriaFilter: false,
                    list: []
                });
            }
        } else {
            setMulti({
                filter: fil,
                selectedFilter: criterias,
                customFilter: modifiedCustomFilters,
                waitForCriteriaFilter: false,
                isCustomFilterSetEmpty: modifiedCustomFilters.length === 0 ? true : false,
                list: [],
                significance: significanceObj ? significanceObj.value : true
            });
        }

        if (!isFirstTime) {
            handleRefresh();
        }

        setFirstTime(false);
    }

    function setClaimFilter(fil, _cf, criterias) {
        if (tableFilters?.selectedFilter?.criteriaName === values?.criteria?.criteriaName) {
            return
        }
        if (isFirstTime) {
            if (rawFilters.length === 0) {
                setMulti({
                    filter: [],
                    selectedFilter: criterias,
                    waitForCriteriaFilter: false,
                    customFilter: [],
                    list: []
                });
            } else {
                setMulti({
                    filter: rawFilters,
                    selectedFilter: criterias,
                    waitForCriteriaFilter: false,
                    customFilter: [],
                    list: []
                });
                // setValues({
                //     criteria: null,
                // });
            }
        } else {
            setMulti({
                filter: fil,
                selectedFilter: criterias,
                customFilter: [],
                waitForCriteriaFilter: false,
                list: []
            });
        }

        if (!isFirstTime) {
            handleRefresh();
        }

        setFirstTime(false);
    }

    useEffect(() => {
        if (isSearchApiCompleted) {
            if (values.criteria !== null && !shouldNotSetDefault) {
                const filters = JSON.parse(values.criteria.criteria);
                const hasNewCriteria = filters.find(fil => fil.customFilter === true);
                if (hasNewCriteria) {
                    setClaimCriteriaFilter(filters.filter(item => !item.customFilter), filters.filter(item => item.customFilter), values.criteria);
                } else {
                    setClaimFilter(filters.filter(item => !item.customFilter), filters.filter(item => item.customFilter), values.criteria);
                }
            } else {
                setMulti({
                    waitForCriteriaFilter: false,
                });
                setFirstTime(false)
            }
        }

    }, [values, isSearchApiCompleted]);

    useEffect(() => {
        if(values?.criteria!==null)
        {
        localStorage.removeItem("isCriteriaCleared")
        }
    }, [values]);

    useEffect(() => {
        if (
            localStorage.getItem("isCriteriaCleared")

        ) { return }
        const sortedFilters= sortByUserId(searchFilters,userSession)
        const defaultValue = sortedFilters.find(x => x.isDefault && x.name === name);

        if (defaultValue && !values.criteria && !shouldNotSetDefault) {

            if (!tableFilters?.selectedFilter) {
                setValues({
                    criteria: defaultValue,
                });
            }
            else {
                setValues({
                    criteria: tableFilters?.selectedFilter,
                });
            }
        }
        if (tableFilters?.selectedFilter) {
            const value = sortedFilters.find(x => x.id === tableFilters?.selectedFilter?.id);
            if (value) {
                setValues({
                    criteria: value,
                });
            }

        }
    }, [searchFilters]);

    useEffect(() => {
        if (selectedFilter === null) {
            
            setValues({
                criteria: null,
            });
        }
    }, [selectedFilter]);


    return (
        <>
        {showSearchCriteriaDropdown &&
        <Grid container>
            <Grid item xs={10}>
                <Grid container className={classes.container}>
                    {/* <Grid item xs={12}>{title}</Grid> */}
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                disabled={pending}
                                disableClearable
                                onChange={handleChange}
                                value={values.criteria}
                                options={searchFilters}
                                className={classes.formControl}
                                getOptionLabel={(option) => option.criteriaName || ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        name="criteria"
                                        label='Default Search Criteria'
                                    />
                                )}
                                renderOption={(option) => (
                                    <Grid container>
                                        <Grid item xs={11}>
                                            {option.criteriaName}
                                        </Grid>
                                        {option.isPublic && (
                                            <Grid item xs={1}>
                                                <Tooltip title="Public">
                                                    <i className={classNames("fa fa-user-friends", classes.icon)}></i>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        }
        </>
    );
}
