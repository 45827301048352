import { createReducer } from '../../util';
import * as actions from '../actions/forgotPassword';
import { StatusState } from './common';


const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    forgotPassword: {
        ...initialStatusState,
    },
    resetPassword: {
        ...initialStatusState,
    },
    verifyToken: {
        ...initialStatusState,
        token: '',
    }
};


export default createReducer(initialState, {

    [actions.FORGOT_PASSWORD_PENDING]: (state) => ({
        ...state,
        forgotPassword: {
            ...initialStatusState,
            pending: true,
        },
    }),

    [actions.FORGOT_PASSWORD_FULFILLED]: (state) => ({
        ...state,
        forgotPassword: {
            ...initialStatusState,
        },
    }),

    [actions.FORGOT_PASSWORD_REJECTED]: (state, errorMessage) => ({
        ...state,
        forgotPassword: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.RESET_PASSWORD_PENDING]: (state) => ({
        ...state,
        resetPassword: {
            ...initialStatusState,
            pending: true,
        },
    }),

    [actions.RESET_PASSWORD_FULFILLED]: (state) => ({
        ...state,
        resetPassword: {
            ...initialStatusState,
        },
    }),

    [actions.RESET_PASSWORD_REJECTED]: (state, errorMessage) => ({
        ...state,
        resetPassword: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.VERIFY_TOKEN_PENDING]: (state) => ({
        ...state,
        verifyToken: {
            ...initialStatusState,
            pending: true,
            token: '',
        },
    }),

    [actions.VERIFY_TOKEN_FULFILLED]: (state, token) => ({
        ...state,
        verifyToken: {
            ...initialStatusState,
            token: token
        },
    }),

    [actions.VERIFY_TOKEN_REJECTED]: (state, errorMessage) => ({
        ...state,
        verifyToken: {
            ...initialStatusState,
            error: true,
            errorMessage: errorMessage,
            token: '',
        },
    }),
});