import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ArchivedClaimDetailsComponent from './ArchivedClaimDetails';
import { selectArchivedClaim } from '../../../../store/selectors/claimAnalysis';
import { setArchivedClaim, setDrilldown } from '../../../../store/actions/claimAnalysis';
import { createCurrencyDataField, createNumberDataField, createSecondLevelField, createStringDataField } from '../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import { set } from '../../../../store/actions/pages';

const DEFAULT_SORT = [{ id: 'revcode', orderBy: 'asc' }];

const selectArchiveClaimPaymentHistory = createSelector(selectArchivedClaim,
    (archivedClaim) => {
        return archivedClaim?.estimatedPaymentDetails || [];
    });

const selectFields = createSelector(
    (state) => [
        createSecondLevelField('rule', 'Rule Name', 'name', { onHover: true }),
        createStringDataField('revcode', 'RevCode', { sortable: true, onHover: true }),
        createStringDataField('pxcode', 'PxCode', { sortable: true, onHover: true }),
        createNumberDataField('units', 'Qty/Units', { sortable: true }),
        createCurrencyDataField('chargedAmount', 'Charged Amount', { sortable: true, calculateTotal: true }),
        createCurrencyDataField('expectedPay', 'Expected Pay', { sortable: true, calculateTotal: true }),
        createCurrencyDataField('actualPay', 'Allowable', { sortable: true, calculateTotal: true }),
        createCurrencyDataField('variance', 'Variance', { sortable: true, calculateTotal: true }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`claim-archived-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`claim-archived-landing`].filter,
    selectSort,
    selectArchiveClaimPaymentHistory,
);

const selectArchiveClaimOpen = createSelector(
    selectArchivedClaim,
    (archivedClaim) => {
        return archivedClaim ? true : false
    }
);

const selectDRGCode = createSelector(
    selectArchiveClaimPaymentHistory,
    (estimatedPaymentDetailsHist) => {
        return estimatedPaymentDetailsHist.length > 0 ? estimatedPaymentDetailsHist[0]?.drg : ''
    }
);

const selectDRGDescription = createSelector(
    selectArchiveClaimPaymentHistory,
    (estimatedPaymentDetailsHist) => {
        return estimatedPaymentDetailsHist.length > 0 ? estimatedPaymentDetailsHist[0]?.drgDescription : ''
    }
);

const selectPrincipalDx = createSelector(
    selectArchiveClaimPaymentHistory,
    (estimatedPaymentDetailsHist) => {
        return estimatedPaymentDetailsHist.length > 0 ? estimatedPaymentDetailsHist[0]?.principalDx : ''
    }
);

const selectPrincipalDxDescription = createSelector(
    selectArchiveClaimPaymentHistory,
    (estimatedPaymentDetailsHist) => {
        return estimatedPaymentDetailsHist.length > 0 ? estimatedPaymentDetailsHist[0]?.principalDxDescription : ''
    }
);

const selectTotalExpectedPay = createSelector(
    selectArchiveClaimPaymentHistory,
    (historyData) => {
        return historyData?.reduce((acc, curr) => acc + curr.expectedPay, 0) || 0;
    }
);

const selectTotalVariance = createSelector(
    selectArchiveClaimPaymentHistory,
    (historyData) => {
        return historyData?.reduce((acc, curr) => acc + curr.variance, 0) || 0;
    }
);

export const ArchivedClaimDetails = connect(
    () => {
        return (state, props) => ({
            drg: selectDRGCode(state, props),
            drgDescription: selectDRGDescription(state, props),
            principalDx: selectPrincipalDx(state),
            principalDxDescription: selectPrincipalDxDescription(state),
            archivedClaim: selectArchivedClaim(state, props),
            open: selectArchiveClaimOpen(state, props),
            claimAnalysisData: state.pages['claimAnalysis-landing'].list,
            totalExpectedPay: selectTotalExpectedPay(state, props),
            totalVariance: selectTotalVariance(state, props),

            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            order: state.pages[`claim-archived-landing`].order,
            filter: state.pages[`claim-archived-landing`].filter,
            sort: selectSort(state, props),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setArchivedClaim: setArchivedClaim,
            setFilter: (value) => set(`claim-archived-landing`, 'filter', value),
            setOrder: (value) => set(`claim-archived-landing`, 'order', value),
            setSort: (value) => set(`claim-archived-landing`, 'sort', value),
        }, dispatch)
    })
)(injectIntl(ArchivedClaimDetailsComponent));