import {
    METHOD_GET,
    METHOD_POST,
    METHOD_DELETE,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../headers';
import { apiHandleResponse } from '../../util/api';

const listDashboardTypes = () => {
    let endpoint = `${SERVER_URL}/api/v1/dashboard/type`;
    return fetch(endpoint, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getDashboardType = (dashboardTypeId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/type/${dashboardTypeId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createDashboardType = (dashboardType) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/type`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(dashboardType)
    }).then(apiHandleResponse);
};

const updateDashboardType = (dashboardType) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/type`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(dashboardType)
    }).then(apiHandleResponse);
};

const deleteDashboardType = (dashboardTypeId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/type/${dashboardTypeId}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
};

export {
    listDashboardTypes,
    getDashboardType,
    createDashboardType,
    updateDashboardType,
    deleteDashboardType,
};