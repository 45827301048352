import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import {set } from '../../../../store/actions/pages';
import { createStringDataField } from '../../../../util/format';
import {codeServiceDescriptionLanding } from '../../../../store/actions/pages';
import CodesDialogComponent from './CodesDialog';
import { StringEquators } from '../../../../store/constants/pages';

const DEFAULT_SORT = [{ id: 'code', orderBy: 'asc' }];

const selectFields = createSelector(
    (state) => [
        createStringDataField('code', 'Code', { sortable: true, equators: StringEquators}),
        createStringDataField('description', 'Description', { sortable: true, hideFromFilter: true}),
        createStringDataField('type', 'Type', { sortable: true, hideFromFilter: true}),
    ]
);

const selectServiceId = (state, props) => props.serviceId;
const selectedField = (state, props) => props.selectedField;

const selectSort = createSelector(
    (state, props) => state.pages[`code-service-description-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectCodeServiceDescriptionData = createSelector(
    (state) => state.pages['code-service-description-landing'].list,
    (data) => data
);

const selectCodeServiceDescriptionTotalCount = createSelector(
    (state) => state.pages['code-service-description-landing'].page?.totalRecords,
    (data) => data
);

export const CodesDialog = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            descriptionData: selectCodeServiceDescriptionData(state),
            serviceId: selectServiceId(state, props),
            selectedField: selectedField(state, props),
            totalCount: selectCodeServiceDescriptionTotalCount(state, props),
            codeServiceDescriptionListPending: state.services.getCodeDescription.pending,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getCodeServiceDescription: codeServiceDescriptionLanding,
            setFilter: (value) => set(`code-service-description-landing`, 'filter', value),
        }, dispatch)
    })
)(injectIntl(CodesDialogComponent));