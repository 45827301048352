import React, { useEffect } from "react";
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { DataTable, Loadable } from '../../../../common';
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../../partials/content/Portlet";
import PageviewIcon from '@material-ui/icons/Pageview';

const useStyles = makeStyles(theme => {
    return {
        portlet: {
            'box-shadow': 'none',
            "& .kt-portlet__head": {
                borderColor: theme.palette.secondary.main,
            }
        },
        portletSection: {
            'box-shadow': 'none',
            "& .kt-portlet__head": {
                borderColor: theme.palette.secondary.main,
                paddingLeft: 0
            }
        }
    }
});

export default function DocumentView(props) {
    const { fields, filteredData, order, sort, contractId, contractListPending, isEditView, vendorListPending,
        actions: { setSort, setOrder, getDocumentList, getDocument }
    } = props;
    const classes = useStyles();

    useEffect(() => {
        getDocumentList(contractId);
    }, [getDocumentList, contractId]);

    const download = (file, documentName, metadata) => {
        let documentType = ''
        if (metadata) {
            const md = JSON.parse(metadata);
            documentType = md.type;
        }
        const myNewFile = new File([file], documentName, { type: documentType });
        const broswerWindow = window.open();
        broswerWindow.location.href = window.URL.createObjectURL(myNewFile);
    }

    const handleView = (document) => {
        const { documentName, id, metadata } = document;
        getDocument(id, documentName, metadata, download);
    }

    function documentActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleView(rowData)}
            >
                <Tooltip title="View">
                    <PageviewIcon />
                </Tooltip>
            </IconButton>
        </div>
    }

    return (
        <Loadable loading={contractListPending || vendorListPending}>
            <Portlet className={classes.portletSection}>
                {!isEditView && (
                    <PortletHeader
                        className=""
                        title="Documents"
                        showTitle={true}
                        toolbar={<></>}
                    />
                )}
                <PortletBody className="pl-0 pb-0">
                    <DataTable
                        fields={fields}
                        data={filteredData}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}

                        hasActions={true}
                        renderActionColumn={documentActions}
                    />
                </PortletBody>
            </Portlet>
        </Loadable>
    );
}