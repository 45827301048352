import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { getTreeGroups } from '../../../../../store/actions/contracts';
import { selectGroupsTree } from "../../../../../store/selectors/contracts";
import GroupsTreeViewComponent from './GroupsTreeView';
import { createSelector } from 'reselect';

export const GroupsTreeView = connect(
    () => {
        const selectRuleId = (state, props) => props.ruleId;
        
        const selectGroupsData = createSelector(
            selectGroupsTree,
            (state, props) => props.selected,
            (data, selected) => {
                if(selected) {
                    const m = data.filter(x => x.selected);
                    return m;
                }
                return data;
            }
        )

        return (state, props) => ({
            contractGetTreeGroupPending: state.contracts.getGroupsTree.pending,
            groupsTree: selectGroupsData(state, props),
            ruleId: selectRuleId(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            contractGetTreeGroups: getTreeGroups,
        }, dispatch)
    })
)(injectIntl(GroupsTreeViewComponent));