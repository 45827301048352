export const DASHBOARD_FILTERS_GET = 'DASHBOARD_FILTERS_GET';
export const DASHBOARD_FILTERS_GET_PENDING = 'DASHBOARD_FILTERS_GET_PENDING';
export const DASHBOARD_FILTERS_GET_FULFILLED = 'DASHBOARD_FILTERS_GET_FULFILLED';
export const DASHBOARD_FILTERS_GET_REJECTED = 'DASHBOARD_FILTERS_GET_REJECTED';

export const DASHBOARD_FILTERS_CREATE = 'DASHBOARD_FILTERS_CREATE';
export const DASHBOARD_FILTERS_CREATE_PENDING = 'DASHBOARD_FILTERS_CREATE_PENDING';
export const DASHBOARD_FILTERS_CREATE_FULFILLED = 'DASHBOARD_FILTERS_CREATE_FULFILLED';
export const DASHBOARD_FILTERS_CREATE_REJECTED = 'DASHBOARD_FILTERS_CREATE_REJECTED';

export const DASHBOARD_FILTERS_UPDATE = 'DASHBOARD_FILTERS_UPDATE';
export const DASHBOARD_FILTERS_UPDATE_PENDING = 'DASHBOARD_FILTERS_UPDATE_PENDING';
export const DASHBOARD_FILTERS_UPDATE_FULFILLED = 'DASHBOARD_FILTERS_UPDATE_FULFILLED';
export const DASHBOARD_FILTERS_UPDATE_REJECTED = 'DASHBOARD_FILTERS_UPDATE_REJECTED';

export const DASHBOARD_FILTERS_LIST = 'DASHBOARD_FILTERS_LIST';
export const DASHBOARD_FILTERS_LIST_PENDING = 'DASHBOARD_FILTERS_LIST_PENDING';
export const DASHBOARD_FILTERS_LIST_FULFILLED = 'DASHBOARD_FILTERS_LIST_FULFILLED';
export const DASHBOARD_FILTERS_LIST_REJECTED = 'DASHBOARD_FILTERS_LIST_REJECTED';

export const DASHBOARD_FILTERS_LIST_TINY = 'DASHBOARD_FILTERS_LIST_TINY';
export const DASHBOARD_FILTERS_LIST_TINY_PENDING = 'DASHBOARD_FILTERS_LIST_TINY_PENDING';
export const DASHBOARD_FILTERS_LIST_TINY_FULFILLED = 'DASHBOARD_FILTERS_LIST_TINY_FULFILLED';
export const DASHBOARD_FILTERS_LIST_TINY_REJECTED = 'DASHBOARD_FILTERS_LIST_TINY_REJECTED';

export const DASHBOARD_FILTERS_DELETE = 'DASHBOARD_FILTERS_DELETE';
export const DASHBOARD_FILTERS_DELETE_PENDING = 'DASHBOARD_FILTERS_DELETE_PENDING';
export const DASHBOARD_FILTERS_DELETE_FULFILLED = 'DASHBOARD_FILTERS_DELETE_FULFILLED';
export const DASHBOARD_FILTERS_DELETE_REJECTED = 'DASHBOARD_FILTERS_GET_URL_REJECTED';

export const DASHBOARD_FILTERS_ERROR_RESET = 'DASHBOARD_FILTERS_ERROR_RESET';
export const DASHBOARD_FILTERS_RESET = 'DASHBOARD_FILTERS_RESET';

export const list = () => ({
    type: DASHBOARD_FILTERS_LIST,
});

export const listTiny = () => ({
    type: DASHBOARD_FILTERS_LIST_TINY,
});

export const get = (dashboardFilterId, callback) => ({
    type: DASHBOARD_FILTERS_GET,
    payload: { dashboardFilterId, callback },
});

export const create = (dashboardFilter, callback) => ({
    type: DASHBOARD_FILTERS_CREATE,
    payload: { dashboardFilter, callback },
});

export const update = (id, dashboardFilter, callback) => ({
    type: DASHBOARD_FILTERS_UPDATE,
    payload: { id, dashboardFilter, callback },
});

export const deleteDashboardFilter = (id, callback) => ({
    type: DASHBOARD_FILTERS_DELETE,
    payload: { id, callback },
});

export const errorReset = () => ({
    type: DASHBOARD_FILTERS_ERROR_RESET,
});
