import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/eapg';
import * as API from '../api/eapg';
import { sessionErrorHandling } from './session';

function* create(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.EAPG_CREATE_PENDING });
        const payload = yield call(API.createEAPG, values);
        yield put({ type: actions.EAPG_CREATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.EAPG_UPDATE_PENDING });
        const payload = yield call(API.editEAPG, values);
        yield put({ type: actions.EAPG_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteEAPG(action) {
    try {
        const { eapgId, callback } = action.payload;
        yield put({ type: actions.EAPG_DELETE_PENDING });
        const payload = yield call(API.deleteEAPG, eapgId);
        yield put({ type: actions.EAPG_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getEAPG(action) {
    try {
        const { eapgId } = action.payload;
        yield put({ type: actions.EAPG_GET_PENDING });
        const payload = yield call(API.getEAPG, eapgId);
        yield put({ type: actions.EAPG_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getEAPGTiny(action) {
    try {
        yield put({ type: actions.EAPG_GET_TINY_PENDING });
        const payload = yield call(API.getEAPGListTiny);
        yield put({ type: actions.EAPG_GET_TINY_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_GET_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getEAPGTinyByContract(action) {
    try {
        const { contractId } = action.payload;
        yield put({ type: actions.EAPG_GET_TINY_BY_CONTRACT_PENDING });
        const payload = yield call(API.getEAPGListTinyByContract, contractId);
        yield put({ type: actions.EAPG_GET_TINY_BY_CONTRACT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_GET_TINY_BY_CONTRACT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getEAPGTinyByTargetClient(action) {
    try {
        const { targetClientId } = action.payload;
        yield put({ type: actions.EAPG_GET_TINY_BY_TARGET_CLIENT_PENDING });
        const payload = yield call(API.getEAPGListByTargetClient, targetClientId);
        yield put({ type: actions.EAPG_GET_TINY_BY_TARGET_CLIENT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_GET_TINY_BY_TARGET_CLIENT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getEAPGDependencies(action) {
    try {
        const { id } = action.payload;
        yield put({ type: actions.EAPG_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getEAPGDependency, id);
        yield put({ type: actions.EAPG_DELETE_DEPENDENCIES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getEAPGVersions() {
    try {
        yield put({ type: actions.EAPG_VERSIONS_PENDING });
        const payload = yield call(API.getEAPGVersions);
        yield put({ type: actions.EAPG_VERSIONS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EAPG_VERSIONS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.EAPG_CREATE, create);
    yield takeEvery(actions.EAPG_UPDATE, update);
    yield takeEvery(actions.EAPG_DELETE, deleteEAPG);
    yield takeEvery(actions.EAPG_GET, getEAPG);
    yield takeEvery(actions.EAPG_GET_TINY, getEAPGTiny);
    yield takeEvery(actions.EAPG_GET_TINY_BY_CONTRACT, getEAPGTinyByContract);
    yield takeEvery(actions.EAPG_GET_TINY_BY_TARGET_CLIENT, getEAPGTinyByTargetClient);
    yield takeEvery(actions.EAPG_DELETE_DEPENDENCIES, getEAPGDependencies);
    yield takeEvery(actions.EAPG_VERSIONS, getEAPGVersions);
}