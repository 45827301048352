import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import DataTableComponent from './DataTable';
import { setMulti, set } from '../../store/actions/pages';
import { selectSessionUserId,selectIsSuperAdmin } from '../../store/selectors/session';
import { setV2 } from '../../store/actions/pagesV2';


export const DataTable = (connect(
    () => {

        return (state, props) => ({
            sessionUserId: selectSessionUserId(state),
            isSuperAdmin:selectIsSuperAdmin(state)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setMulti: setMulti,
            setFilterColumnDialogOpen: (value) => setV2(props.pageName, 'isColumnDialogOpen', value),
            setReorderDialogOpen: (value) => setV2(props.pageName, 'isReorderDialogOpen', value),
            setCheckboxSelected: (value) => setV2(props.pageName, 'selected', value),
        }, dispatch)
    })
)(injectIntl(DataTableComponent))); 