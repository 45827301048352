import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { deleteFeeSchedule, getFeeScheduleDependencies, setFeeScheduleDepdendencyDialog,resetFeeScheduleItems } from '../../../store/actions/feeScheduleItems';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.feeScheduleItems.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectFeeScheduletId = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.feeScheduleId) {
                    return claimDependencyDialog?.feeScheduleId
                }
                return null
            }
        );

        const selectFeeScheduleName = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.feeScheduleName) {
                    return claimDependencyDialog?.feeScheduleName
                }
                return ''
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            feeScheduleId: selectFeeScheduletId(state, props),
            feeScheduleDependencies: state.feeScheduleItems.dependencies,
            feeScheduleDependencyPending: state.feeScheduleItems.getFeeScheduleDependencies.pending,
            feeScheduleDeletePending: state.feeScheduleItems.deleteFeeSchedule.pending,
            feeScheduleName: selectFeeScheduleName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setFeeScheduleDepdendencyDialog: setFeeScheduleDepdendencyDialog,
            getFeeScheduleDependencies: getFeeScheduleDependencies,
            deleteFeeSchedule: deleteFeeSchedule,
            resetFeeScheduleItems:resetFeeScheduleItems
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));