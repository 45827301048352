import { createReducer } from '../../util';
import * as actions from '../actions/controlRoom';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    jobStatistics: [],
    s3Listners: [],
    dataSetList: [],
    redshiftHealth: {
        status: '',
        additonalInformation: null,
        error: null
    },
    rdsHealth: {
        status: '',
        additonalInformation: null,
        error: null
    },
    ecsHealth: {
        status: '',
        additonalInformation: null,
        error: null
    },
    networkHealth: {
        status: '',
        additonalInformation: null,
        error: null
    },
    sqsHealth: {
        status: '',
        additonalInformation: null,
        error: null
    },
    s3Health: {
        status: '',
        additonalInformation: null,
        error: null
    },
    cloudwatchHealth: {
        status: '',
        additonalInformation: null,
        error: null
    },
    cloudfrontHealth: {
        status: '',
        additonalInformation: null,
        error: null
    },
    secretManagerHealth: {
        status: '',
        additonalInformation: null,
        error: null
    },

    getJobStatistics: {
        ...initialStatusState,
    },
    getS3Listners: {
        ...initialStatusState,
    },
    getRedshiftHealth: {
        ...initialStatusState,
    },
    getRDSHealth: {
        ...initialStatusState,
    },
    getECSHealth: {
        ...initialStatusState,
    },
    getNetworkHealth: {
        ...initialStatusState,
    },
    getSQSHealth: {
        ...initialStatusState,
    },
    getS3Health: {
        ...initialStatusState,
    },
    getCloudwatchHealth: {
        ...initialStatusState,
    },
    getCloudfrontHealth: {
        ...initialStatusState,
    },
    getSecretManagerHealth: {
        ...initialStatusState,
    },
    getQuickSightsDataSetList: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {
    [actions.JOB_STATISTICS_LIST_PENDING]: (state) => ({
        ...state,
        getJobStatistics: {
            pending: true,
        },
    }),
    [actions.JOB_STATISTICS_LIST_FULFILLED]: (state, jobStatistics) => ({
        ...state,
        getJobStatistics: {
            ...initialStatusState,
        },
        jobStatistics,
    }),
    [actions.JOB_STATISTICS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        getJobStatistics: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.S3_LISTNERS_LIST_PENDING]: (state) => ({
        ...state,
        getS3Listners: {
            pending: true,
        },
    }),
    [actions.S3_LISTNERS_LIST_FULFILLED]: (state, s3Listners) => ({
        ...state,
        getS3Listners: {
            ...initialStatusState,
        },
        s3Listners,
    }),
    [actions.S3_LISTNERS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        getS3Listners: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_REDSHIFT_PENDING]: (state) => ({
        ...state,
        getRedshiftHealth: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_REDSHIFT_FULFILLED]: (state, redshiftHealth) => ({
        ...state,
        getRedshiftHealth: {
            ...initialStatusState,
        },
        redshiftHealth,
    }),
    [actions.GET_HEALTH_REPORT_REDSHIFT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getRedshiftHealth: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_RDS_PENDING]: (state) => ({
        ...state,
        getRDSHealth: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_RDS_FULFILLED]: (state, rdsHealth) => ({
        ...state,
        getRDSHealth: {
            ...initialStatusState,
        },
        rdsHealth,
    }),
    [actions.GET_HEALTH_REPORT_RDS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getRDSHealth: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_ECS_PENDING]: (state) => ({
        ...state,
        getECSHealth: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_ECS_FULFILLED]: (state, ecsHealth) => ({
        ...state,
        getECSHealth: {
            ...initialStatusState,
        },
        ecsHealth,
    }),
    [actions.GET_HEALTH_REPORT_ECS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getECSHealth: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_NETWORK_PENDING]: (state) => ({
        ...state,
        getNetworkHealth: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_NETWORK_FULFILLED]: (state, networkHealth) => ({
        ...state,
        getNetworkHealth: {
            ...initialStatusState,
        },
        networkHealth,
    }),
    [actions.GET_HEALTH_REPORT_NETWORK_REJECTED]: (state, errorMessage) => ({
        ...state,
        getNetworkHealth: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_SQS_PENDING]: (state) => ({
        ...state,
        getSQSHealth: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_SQS_FULFILLED]: (state, sqsHealth) => ({
        ...state,
        getSQSHealth: {
            ...initialStatusState,
        },
        sqsHealth,
    }),
    [actions.GET_HEALTH_REPORT_SQS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getSQSHealth: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_S3_PENDING]: (state) => ({
        ...state,
        getS3Health: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_S3_FULFILLED]: (state, s3Health) => ({
        ...state,
        getS3Health: {
            ...initialStatusState,
        },
        s3Health,
    }),
    [actions.GET_HEALTH_REPORT_S3_REJECTED]: (state, errorMessage) => ({
        ...state,
        getS3Health: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_CLOUDWATCH_PENDING]: (state) => ({
        ...state,
        getCloudwatchHealth: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_CLOUDWATCH_FULFILLED]: (state, cloudwatchHealth) => ({
        ...state,
        getCloudwatchHealth: {
            ...initialStatusState,
        },
        cloudwatchHealth,
    }),
    [actions.GET_HEALTH_REPORT_CLOUDWATCH_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCloudwatchHealth: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_CLOUDFRONT_PENDING]: (state) => ({
        ...state,
        getCloudfrontHealth: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_CLOUDFRONT_FULFILLED]: (state, cloudfrontHealth) => ({
        ...state,
        getCloudfrontHealth: {
            ...initialStatusState,
        },
        cloudfrontHealth,
    }),
    [actions.GET_HEALTH_REPORT_CLOUDFRONT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCloudfrontHealth: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_HEALTH_REPORT_SECRET_MANAGER_PENDING]: (state) => ({
        ...state,
        getSecretManagerHealth: {
            pending: true,
        },
    }),
    [actions.GET_HEALTH_REPORT_SECRET_MANAGER_FULFILLED]: (state, secretManagerHealth) => ({
        ...state,
        getSecretManagerHealth: {
            ...initialStatusState,
        },
        secretManagerHealth,
    }),
    [actions.GET_HEALTH_REPORT_SECRET_MANAGER_REJECTED]: (state, errorMessage) => ({
        ...state,
        getSecretManagerHealth: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.GET_QUICK_SIGHTS_DATASET_LIST_PENDING]: (state) => ({
        ...state,
        getQuickSightsDataSetList: {
            pending: true,
        },
    }),
    [actions.GET_QUICK_SIGHTS_DATASET_LIST_FULFILLED]: (state, dataSetList) => ({
        ...state,
        getQuickSightsDataSetList: {
            ...initialStatusState,
        },
        dataSetList,
    }),
    [actions.QUICK_SIGHTS_DATASET_LIST_RESET]: (state) => ({
        ...state,
        dataSetList: [],
    }),
    [actions.GET_QUICK_SIGHTS_DATASET_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        getQuickSightsDataSetList: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});
