import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/npiRoles';
import * as API from '../api/npiRoles';
import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.NPI_ROLES_LANDING_PENDING });
        const payload = yield call(API.getNPIRoles);
        yield put({ type: actions.NPI_ROLES_LANDING_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.NPI_ROLES_LANDING_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.NPI_ROLES_CREATE_PENDING });
        const payload = yield call(API.createNPIRole, values);
        yield put({ type: actions.NPI_ROLES_CREATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.NPI_ROLES_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.NPI_ROLES_UPDATE_PENDING });
        const payload = yield call(API.editNPIRole, values);
        yield put({ type: actions.NPI_ROLES_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.NPI_ROLES_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteNPIRole(action) {
    try {
        const { npiRoleId, callback } = action.payload;
        yield put({ type: actions.NPI_ROLES_DELETE_PENDING });
        const payload = yield call(API.deleteNPIRole, npiRoleId);
        yield put({ type: actions.NPI_ROLES_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.NPI_ROLES_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.NPI_ROLES_LANDING, list);
    yield takeEvery(actions.NPI_ROLES_CREATE, create);
    yield takeEvery(actions.NPI_ROLES_UPDATE, update);
    yield takeEvery(actions.NPI_ROLES_DELETE, deleteNPIRole);
}