import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    makeStyles,
    Grid,
    FormGroup,
    Typography,
    Switch,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText
    
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";


import { Formik } from "formik";
import { Loadable } from "../../../../common";


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    },
    textField: {
        width: "100%"
    },
    root: {
        flexGrow: 1
    },
    autoComplete: {
        position: "relative",
        top: "16px"
    },
    toggle:{
        marginTop:'10px',
        marginBottom:'10px'
    },
    formControlDropdown: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
    autoComplete: {
        position: "relative",
        top: "16px"
    },
    toggle:{
        marginTop:'10px',
        marginBottom:'10px'
    }
}));

export default function ChargeMasterDialog(props) {
    const {
        open,
        handleRefresh,
        mode,
        intl,
        initialValues,
        drugMeasurementTypes,
        facilities,
        chargeMasterCreatePending,
        chargeMasterUpdatePending,
        getFacilitiesPending,
        actions: {
            chargeMasterCreate,
            chargeMasterUpdate,
            setChargeMasterEditDialog,
            getFacilities
        }
    } = props;
    const classes = useStyles();

    useEffect(() => {
        getFacilities();
    }, [getFacilities]);

    function handleClose() {
        setChargeMasterEditDialog(null);

    }

    function handleCallback() {
        handleClose();
        handleRefresh();
    }

    const resetNdcValues = (setFieldValue) => {     
        setFieldValue('ndc','')
        setFieldValue('drugTypeOfMeasurement',null)
        setFieldValue('drugUnitOfMeasurement','') 
}

    const handleStatusToggle = (e,setFieldValue) => {
        setFieldValue('showNdc',e.target.checked)
        if(e.target.checked===false)
        {
            resetNdcValues(setFieldValue)
        
        }
    }

    


    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {mode === "edit"
                    ? "Edit Charge Master Item"
                    : "Add Charge Master Item"}
            </DialogTitle>
            <DialogContent dividers>
                <Loadable
                    loading={
                        chargeMasterCreatePending || chargeMasterUpdatePending || getFacilitiesPending
                    }
                >
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};

                            if (mode === 'create' && !values.facilityName) {
                                errors.facilityName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.code) {
                                errors.code = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.description) {
                                errors.description = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.amount) {
                                errors.amount = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.revenueCode && !values.cpT_HCPCS && !values.drgCode) {
                                errors.revenueCode =
                                    "Either CPT, Rev Code or DRG Code is required";
                            }
                            const isDrugFieldFilled =
                                values.drugTypeOfMeasurement ||
                                values.drugUnitOfMeasurement ||
                                values.ndc;
                            if (isDrugFieldFilled) {
                                if (!values.drugTypeOfMeasurement) {
                                    errors.drugTypeOfMeasurement = intl.formatMessage(
                                        {
                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                        }
                                    );
                                }
                                if (!values.drugUnitOfMeasurement) {
                                    errors.drugUnitOfMeasurement = intl.formatMessage(
                                        {
                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                        }
                                    );
                                }
                                if (!values.ndc) {
                                    errors.ndc = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                            }

                            if (values.ndc.length>15) {
                                errors.ndc = "NDC should not exceed 15 characters.";
    
                            }
                            if(String(values.drugUnitOfMeasurement).length>10)
                            {
                                errors.drugUnitOfMeasurement = "Drug Unit of Measurement should not be Greater than 10 Digits.";

                            }
    

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            let payload = {
                                code: values.code,
                                departmentName: values.departmentName,
                                description: values.description,
                                gl: values.gl,
                                amount: values.amount,
                                revenueCode: values.revenueCode,
                                cpT_HCPCS: values.cpT_HCPCS,
                                drgCode: values.drgCode,
                                mod1: values.mod1,
                                mod2: values.mod2,
                                mod3: values.mod3,
                                mod4: values.mod4,
                                ndc: values?.ndc,
                                drugTypeOfMeasurement:
                                    values?.drugTypeOfMeasurement,
                                drugUnitOfMeasurement:
                                    values?.drugUnitOfMeasurement,
                                facilityName: values?.facilityName
                            };
                            if (mode === "create") {
                                chargeMasterCreate(payload, handleCallback);
                            } else {
                                payload = {
                                    ...payload,
                                    id: values.id
                                };
                                chargeMasterUpdate(
                                    values.id,
                                    payload,
                                    handleCallback
                                );
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                                className={classes.container}
                            >
                                <div className={classes.root}>
                                    <Grid
                                        container
                                        spacing={3}
                                        className="px-2"
                                    >
                                        <Grid item xs={12} md={6} >
                                            <FormControl className={classes.formControlDropdown}>
                                                <InputLabel htmlFor="contractStateId">Facility</InputLabel>
                                                <Select
                                                required
                                                disabled={mode === "edit" ? true : false}
                                                value={values.facilityName}
                                                onChange={handleChange}
                                                error={Boolean(touched.facilityName && errors.facilityName)}
                                                input={<Input id="facilityName" />}
                                                inputProps={{
                                                    name: "facilityName",
                                                    id: "facilityName"
                                                }}
                                                >
                                                {facilities.map(facility => (
                                                    <MenuItem
                                                    key={facility.name}
                                                    value={facility.name}
                                                    >
                                                    {facility.name}
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText error>{touched.facilityName && errors.facilityName}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                label="Code"
                                                name="code"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.code}
                                                helperText={
                                                    touched.code && errors.code
                                                }
                                                error={Boolean(
                                                    touched.code && errors.code
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Department"
                                                name="departmentName"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.departmentName}
                                                helperText={
                                                    touched.departmentName &&
                                                    errors.departmentName
                                                }
                                                error={Boolean(
                                                    touched.departmentName &&
                                                        errors.departmentName
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                label="Description"
                                                name="description"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                                helperText={
                                                    touched.description &&
                                                    errors.description
                                                }
                                                error={Boolean(
                                                    touched.description &&
                                                        errors.description
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="GL"
                                                name="gl"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.gl}
                                                helperText={
                                                    touched.gl && errors.gl
                                                }
                                                error={Boolean(
                                                    touched.gl && errors.gl
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                type="number"
                                                required
                                                label="Amount"
                                                name="amount"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.amount}
                                                helperText={
                                                    touched.amount &&
                                                    errors.amount
                                                }
                                                error={Boolean(
                                                    touched.amount &&
                                                        errors.amount
                                                )}
                                            />
                                        </Grid>

                                       
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                            required={
                                                values.cpT_HCPCS.length ===
                                                0 && values.revenueCode.length === 0
                                            }
                                                label="Drg Code"
                                                name="drgCode"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.drgCode}
                                                error={Boolean(
                                                    touched.revenueCode &&
                                                        errors.revenueCode
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required={
                                                    values.cpT_HCPCS.length ===
                                                    0 && !values.drgCode
                                                }
                                                label="Revenue Code"
                                                name="revenueCode"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.revenueCode}
                                                error={Boolean(
                                                    touched.revenueCode &&
                                                        errors.revenueCode
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required={
                                                    values.revenueCode
                                                        .length === 0 && !values.drgCode
                                                }
                                                label="CPT/HCPCS"
                                                name="cpT_HCPCS"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.cpT_HCPCS}
                                                helperText={
                                                    touched.revenueCode &&
                                                    errors.revenueCode
                                                }
                                                error={Boolean(
                                                    touched.revenueCode &&
                                                        errors.revenueCode
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Mod 1"
                                                name="mod1"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.mod1}
                                                error={Boolean(
                                                    touched.mod1 && errors.mod1
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Mod 2"
                                                name="mod2"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.mod2}
                                                error={Boolean(
                                                    touched.mod2 && errors.mod2
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Mod 3"
                                                name="mod3"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.mod3}
                                                error={Boolean(
                                                    touched.mod3 && errors.mod3
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Mod 4"
                                                name="mod4"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.mod4}
                                                error={Boolean(
                                                    touched.mod4 && errors.mod4
                                                )}
                                            />
                                        </Grid>


                                        <Grid md={12} spacing={2} container alignItems="center" className={`pl-2 ${classes.toggle}`}>
                                                    <FormGroup>
                                                        <Typography component="div">
                                                            <Grid component="label" className='mb-0' container alignItems="center" spacing={0}>
                                                                <Grid item>
                                                                    <Switch
                                                                        name="active"
                                                                        color="primary"
                                                                        checked={values?.showNdc}
                                                                        onChange={(e)=>handleStatusToggle(e,setFieldValue)}
                                                                        onBlur={handleBlur}
                                                                        className={classes.switch}
                                                                    />
                                                                </Grid>
                                                                <Grid item>NDC</Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </FormGroup>
                                                </Grid>
                                                {values?.showNdc &&         
                                                <>
                                                <Grid item xs={12} md={6}>
                                                
                                                    <Autocomplete
                                                        className={classes.autoComplete}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue("drugTypeOfMeasurement", newValue);
                                                        }}
                                                        value={values.drugTypeOfMeasurement || null}
                                                        name="drugTypeOfMeasurement"
                                                        options={drugMeasurementTypes}
                                                        getOptionLabel={(option) => option || ''}
                                                        // getOptionSelected={(option, value) => option === value}
                                                        getOptionSelected={(option, value) => option === value}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Drug Type Of Measurement"
                                                                error={Boolean(touched.drugTypeOfMeasurement && errors.drugTypeOfMeasurement)}
                                                                helperText={touched.drugTypeOfMeasurement && errors.drugTypeOfMeasurement}
                                                            />
                                                        )}
                                                    />



                                            
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        type="number"
                                                        label="Drug Unit of Measurement"
                                                        name="drugUnitOfMeasurement"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values.drugUnitOfMeasurement
                                                        }
                                                        error={Boolean(
                                                            touched.drugUnitOfMeasurement &&
                                                                errors.drugUnitOfMeasurement
                                                        )}
                                                        helperText={
                                                            touched.drugUnitOfMeasurement &&
                                                            errors.drugUnitOfMeasurement
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        label="NDC"
                                                        name="ndc"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.ndc}
                                                        error={Boolean(
                                                            touched.ndc && errors.ndc
                                                        )}
                                                        helperText={
                                                            touched.ndc && errors.ndc
                                                        }
                                                    />
                                                </Grid>
                                                </>
                                                }
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Button
                                            onClick={handleClose}
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={`ml-3 ${classes.button}`}
                                            type="submit"
                                            disabled={
                                                chargeMasterCreatePending ||
                                                chargeMasterUpdatePending
                                            }
                                        >
                                            {chargeMasterCreatePending ||
                                            chargeMasterUpdatePending
                                                ? "Saving"
                                                : "Save"}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    );
}
