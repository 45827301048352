import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/services';
import * as API from '../api/services';
import { sessionErrorHandling } from './session';

function* listCodeServices() {
    try {
        yield put({ type: actions.CODE_SERVICES_LIST_PENDING });
        const payload = yield call(API.list);
        yield put({ type: actions.CODE_SERVICES_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* listCodeServicesTiny() {
    try {
        yield put({ type: actions.CODE_SERVICES_TINY_PENDING });
        const payload = yield call(API.tiny);
        yield put({ type: actions.CODE_SERVICES_TINY_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.CODE_SERVICES_CREATE_PENDING });
        const {
            service,
            callback,
        } = action.payload;

        const newService = yield call(API.create, service);
        yield put({ type: actions.CODE_SERVICES_CREATE_FULFILLED, newService });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.CODE_SERVICES_UPDATE_PENDING });
        const {
            service,
            callback
        } = action.payload;

        yield call(API.update, service);
        yield put({ type: actions.CODE_SERVICES_UPDATE_FULFILLED });

        if (callback) {
            callback(service.id);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.CODE_SERVICES_GET_PENDING });
        const { serviceId } = action.payload;
        const payload = yield call(API.get, serviceId);
        yield put({ type: actions.CODE_SERVICES_GET_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteService(action) {
    try {
        yield put({ type: actions.CODE_SERVICES_DELETE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deleteService, id);
        yield put({ type: actions.CODE_SERVICES_DELETE_FULFILLED, payload: { id } });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getServiceType() {
    try {
        yield put({ type: actions.CODE_SERVICES_TYPE_PENDING });
        const payload = yield call(API.getServiceType);
        yield put({ type: actions.CODE_SERVICES_TYPE_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_TYPE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getServiceDescription(action) {
    try {
        yield put({ type: actions.CODE_SERVICES_GET_DESCRIPTION_PENDING });
        const { serviceId } = action.payload;
        const payload = yield call(API.getDescription, serviceId);
        yield put({ type: actions.CODE_SERVICES_GET_DESCRIPTION_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_GET_DESCRIPTION_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getServicesFromTargetClient(action) {
    try {
        const { targetClient } = action.payload;
        yield put({ type: actions.CODE_SERVICES_GET_BY_TARGET_CLIENT_PENDING });
        const { serviceId } = action.payload;
        const payload = yield call(API.getCodeServicesFromTargetClient, targetClient);
        yield put({ type: actions.CODE_SERVICES_GET_BY_TARGET_CLIENT_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_GET_BY_TARGET_CLIENT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getCodeServiceDependencies(action) {
    try {
        yield put({ type: actions.CODE_SERVICES_GET_DELETE_DEPENDECIES_PENDING });
        const { serviceId } = action.payload;
        const payload = yield call(API.getCodeServiceDependencies, serviceId);
        yield put({ type: actions.CODE_SERVICES_GET_DELETE_DEPENDECIES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CODE_SERVICES_GET_DELETE_DEPENDECIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.CODE_SERVICES_LIST, listCodeServices);
    yield takeEvery(actions.CODE_SERVICES_TINY, listCodeServicesTiny);
    yield takeEvery(actions.CODE_SERVICES_CREATE, create);
    yield takeEvery(actions.CODE_SERVICES_UPDATE, update);
    yield takeEvery(actions.CODE_SERVICES_GET, get);
    yield takeEvery(actions.CODE_SERVICES_DELETE, deleteService);
    yield takeEvery(actions.CODE_SERVICES_TYPE, getServiceType);
    yield takeEvery(actions.CODE_SERVICES_GET_DESCRIPTION, getServiceDescription);
    yield takeEvery(actions.CODE_SERVICES_GET_BY_TARGET_CLIENT, getServicesFromTargetClient);
    yield takeEvery(actions.CODE_SERVICES_GET_DELETE_DEPENDECIES, getCodeServiceDependencies);
}