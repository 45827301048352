import {
    METHOD_GET,
    METHOD_PUT,
    METHOD_POST,
    SERVER_URL,
    METHOD_DELETE,
} from '../constants/api';
import { headersAuth, headersAuthReceiveOctet, headersAuthSendReceiveJson } from '../headers';
import { apiHandleOctetResponseWithFileName, apiHandleResponse } from '../../util/api';

const getUploadsData = (options) => {
    return fetch(`${SERVER_URL}/api/v1/files/metadata`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const uploadFiles = (options) => {
    return fetch(`${SERVER_URL}/api/v1/files`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: options,
    }).then(apiHandleResponse);
};

const downloadTemplate = (type) => {
    return fetch(`${SERVER_URL}/api/v1/files/template/${type}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveOctet(),
    }).then(apiHandleOctetResponseWithFileName);
};

const getFileExecutions = (options) => {
    return fetch(`${SERVER_URL}/api/v1/files/executions`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

export {
    getUploadsData,
    uploadFiles,
    downloadTemplate,
    getFileExecutions
};