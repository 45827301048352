import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { getFeeschedule } from '../../../../../../../store/actions/contracts';
import { create, getCodeTypes, getFeescheduleItem, setFeeScheduleLabelRateDepdendencyDialog, update } from '../../../../../../../store/actions/feeScheduleItems';
import { modifiersLanding } from '../../../../../../../store/actions/modifiers';
import { selectFeeScheduleTypes } from '../../../../../../../store/selectors/feeScheduleItems';
import { asIsoDate } from '../../../../../../../util/date';
import FeeScheduleItemDialogComponent from './FeeScheduleItemDialog';

export const FeeScheduleItemDialog = connect(
    () => {
        // const selectFeeScheduleId = (state, props) => props.feeScheduleId;
        const selectFeeScheduleItem = (state) => state.feeScheduleItems.feeScheduleItem;

        const selectModifiers = (state) => state.modifiers.data;

        
        const selectFeeScheduleId = (state, props) => props.match.params?.feeScheduleId;
        const selectFeeScheduleItemId = (state, props) => props.match.params?.feeScheduleItemId;

        const selectMode = createSelector(
            selectFeeScheduleItemId,
            (itemId) => {
                return itemId !== 'new' ?'edit' :'create';
            }
        )

        const formattedModifiers = (val, modifiers) => {
            return modifiers.find(item => item.modifier === val) ?? null;
        }

        const selectFeeScheduleLabelToRateXRef = createSelector(
            (state) => state.contracts.feeSchedule,
            (feeSchedule: any) => {
                const labels = [];
                for (let index = 0; index <= 10; index++) {
                    let label = index === 0 ? `Rate` : `Rate ${index}`;
                    if(feeSchedule && feeSchedule.feescheduleLabelToRateXRef && feeSchedule.feescheduleLabelToRateXRef.length > 0) {
                        label = feeSchedule.feescheduleLabelToRateXRef.find(x=> x.rateColumn === `Rate${index}`)?.label ?? label;
                    }
                    labels.push({ label: label, rate: (index === 0 ? `rate` : `rate${index}`) });
                }
                return labels;
            }
        );

        // const selectRateDialogOpen = createSelector(
        //     state => state.feeScheduleItems.rateDialog,
        //     (rateDialog) => {
        //         return rateDialog?.open || false;
        //     }
        // );

        // const selectFeeScheduleItem = createSelector(
        //     state => state.feeScheduleItems.rateDialog,
        //     (rateDialog) => {
        //         return rateDialog?.feeScheduleItem
        //     }
        // );

        // const selectMode = createSelector(
        //     state => state.feeScheduleItems.rateDialog,
        //     (rateDialog) => {
        //         return rateDialog?.mode ?? 'create';
        //     }
        // );

        const selectInitialValues = createSelector(
            selectFeeScheduleLabelToRateXRef,
            selectMode,
            selectFeeScheduleId,
            selectFeeScheduleItem,
            selectModifiers,
            (rateLabels, mode, feeScheduleId, feeScheduleItem, modifiers) => {
                if (mode === 'edit' && feeScheduleItem) {
                    const payload = {
                        id: feeScheduleItem.id,
                        code: feeScheduleItem.code || '',
                        paidCode: feeScheduleItem.paidCode || '',
                        codeTypeId: feeScheduleItem.codeTypeId || '',
                        paidCodeTypeId: feeScheduleItem.paidCodeTypeId || '',
                        feeScheduleId: parseInt(feeScheduleId),
                        active: feeScheduleItem.active,
                        description: feeScheduleItem.description || '',
                        effectiveDate: feeScheduleItem.effectiveDate ? asIsoDate(feeScheduleItem.effectiveDate) : '',
                        expirationDate: feeScheduleItem.expirationDate ? asIsoDate(feeScheduleItem.expirationDate) : '',
                        grouping: feeScheduleItem.grouping || '',
                        // rate: feeScheduleItem.rate,
                        codeModifier1: formattedModifiers(feeScheduleItem.codeModifier1, modifiers),
                        codeModifier2: formattedModifiers(feeScheduleItem.codeModifier2, modifiers),
                        codeModifier3: formattedModifiers(feeScheduleItem.codeModifier3, modifiers),
                        codeModifier4: formattedModifiers(feeScheduleItem.codeModifier4, modifiers),
                        additionalRates: [],
                    }
                    
                    for (let index = 0; index <= 10; index++) {
                        if(index === 0 && feeScheduleItem?.[`rate`] !== null && feeScheduleItem?.[`rate`] !== undefined) {
                            payload.additionalRates.push(
                                {
                                    label: rateLabels?.find(x=> x.rate === `rate`).label ?? '', 
                                    rate: feeScheduleItem?.[`rate`]
                                }
                            )
                        }
                        else if(feeScheduleItem?.[`rate${index}`] !== null && feeScheduleItem?.[`rate${index}`] !== undefined) {
                            payload.additionalRates.push(
                                {
                                    label: rateLabels?.find(x=> x.rate === `rate${index}`).label ?? '', 
                                    rate: feeScheduleItem?.[`rate${index}`]
                                }
                            )
                        }
                    }

                    if(payload.additionalRates.length === 0) {
                        payload.additionalRates.push(
                            {
                                label: rateLabels?.find(x=> x.rate === `rate`).label ?? '', 
                                rate: ''
                            }
                        )
                    }
                    return payload;
                }

                return {
                    code: '',
                    paidCode: '',
                    codeTypeId: '',
                    paidCodeTypeId: '',
                    feeScheduleId: parseInt(feeScheduleId),
                    active: true,
                    description: '',
                    effectiveDate: '',
                    expirationDate: '',
                    grouping: '',
                    // rate: '',
                    codeModifier1: null,
                    codeModifier2: null,
                    codeModifier3: null,
                    codeModifier4: null,
                    additionalRates: [
                        {
                            label: rateLabels?.find(x=> x.rate === `rate`).label ?? '', 
                            rate: null
                        }
                    ],
                }
            }
        )

        const selectFeeScheduleObj = createSelector(
            (state, props) => state.contracts.feeSchedule,
            (feeSchedule: any) => {
                return feeSchedule?.length > 0 ? feeSchedule : null;
            }
        );

        return (state, props) => ({
            feeScheduleId: selectFeeScheduleId(state, props),
            feeScheduleItemId: selectFeeScheduleItemId(state, props),
            feeSchedule: selectFeeScheduleObj(state, props),
            feeScheduleItemGetPending: state.feeScheduleItems.getFeeScheduleItem.pending,
            //create feeScheduleItem
            feeScheduleItemCreatePending: state.feeScheduleItems.create.pending,
            // update feeScheduleItem
            feeScheduleItemUpdatePending: state.feeScheduleItems.update.pending,
            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            types: selectFeeScheduleTypes(state),
            modifiers: selectModifiers(state),
            RateLabels: selectFeeScheduleLabelToRateXRef(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            feeScheduleItemCreate: create,
            feeScheduleItemUpdate: update,
            feescheduleItemGetCodeTypes: getCodeTypes,
            modifiersLanding: modifiersLanding,
            getFeescheduleItem: getFeescheduleItem,
            setFeeScheduleLabelRateDepdendencyDialog: setFeeScheduleLabelRateDepdendencyDialog,
            getFeeschedule: getFeeschedule
        }, dispatch)
    })
)(injectIntl(FeeScheduleItemDialogComponent));