import React, { useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Grid, IconButton } from '@material-ui/core';

function APIKeyShowComponent({ value, actualKey,actualAppKey,isAppId, actions: { getAPIKeyValue, resetActualKey,getAppIdValue,resetActualAppIdKey } }) {
    const [isCopied, setCopied] = useState(false);

    const handleViewAPIKey = () => {

        if(isAppId)
        {
            getAppIdValue(value.id, handleCallback)
            return
        }

        getAPIKeyValue(value.id, handleCallback);
    };

    const handleCallback = (payload) => {
    }

    const handleHideKey = () => {
        if(isAppId)
        {
            resetActualAppIdKey()
            return
        }
        resetActualKey();
    }

    const handleCopyIcon = () => {
        const apiKey = document.getElementById('actual-key').textContent;
        navigator.clipboard.writeText(apiKey);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }
    return (
        <>
            {((!isAppId && actualKey) || (actualAppKey && isAppId)) ?
                <Grid container alignItems='center'>
                    <span id='actual-key' >{isAppId ? actualAppKey: actualKey}</span>
                    <IconButton
                        className='p-1 ml-2'
                        aria-label="toggle api key visible"
                        onClick={handleCopyIcon}
                        color={isCopied ? 'secondary' : 'primary'}
                    >
                        <CopyIcon />
                    </IconButton>
                    <IconButton
                        className='p-1 ml-2'
                        aria-label="toggle api key visible"
                        onClick={handleHideKey}
                    >
                        <VisibilityOff />
                    </IconButton>
                </Grid> :
                <Grid container alignItems='center'>
                    <span> ***************************************** </span>
                    <IconButton
                        className='p-1 ml-2'
                        onClick={handleViewAPIKey}
                        aria-label="toggle api key visible"
                    >
                        <Visibility />
                    </IconButton>
                </Grid>
            }
        </>
    )
}

export default APIKeyShowComponent
