import { Dialog, DialogContent, DialogTitle, Grid, IconButton, TablePagination } from '@material-ui/core'
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from 'react';
import { DataTable, TablePaginationActions } from '../../../../common';
import Draggable from 'react-draggable';

function ClaimStatusLogs(props) {
    const { open, analysisId, fields, filteredData, order, sort, claimStatusLogsPending, 
        actions: { getClaimStatusLogs, setOrder, setSort, setClaimStatusHistoryDialog } } = props;

    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    function handleLoad(sort, filter, pagination) {
        getClaimStatusLogs(analysisId, pagination, sort, filter);
    }

    useEffect(() => {
        if(open && analysisId) {
            getClaimStatusLogs(analysisId);
        }
    }, [open, analysisId, getClaimStatusLogs]);

    const handleRefresh = () => {
        getClaimStatusLogs(analysisId);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleClose = () => {
        setClaimStatusHistoryDialog(false);
    }

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="claim-status_logs-dialog"
            >
                <DialogTitle style={{ cursor: 'move' }} id="claim-status_logs-dialog">
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            Claim Status History
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <DataTable
                        fields={fields}
                        data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        loading={claimStatusLogsPending}
                        handleRefresh={handleRefresh}
                        showPagination={true}
                        footerPagination={() => (
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                            colSpan={fields.length + 2}
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                            }}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                        )}

                        hasActions={false}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ClaimStatusLogs;