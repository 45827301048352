import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    makeStyles,
    FormControl,
    Chip,
    TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { Loadable } from "../../../common";

const useStyles = makeStyles(theme => ({
    textField: {
        width: "85%"
    },
    formControlAuto: {
        width: "85%"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    dropZone: {
        minHeight: 150,
        border: "none",
        backgroundColor: "#EAEAEA",
        borderRadius: "20px"
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: "100%"
    }
}));
function UpdateClaimStatusDialog(props) {
    const {
        intl,
        open,
        handleClose,
        selectedItems,
        initialValues,
        claimStatusList,
        isUpdateStatusPending,
        handleRefresh,
        actions: { updateClaimStatuses,setSelected }
    } = props;
    const classes = useStyles();

const updateStatusCallback = (params) => {
    setSelected([])
    handleClose()
    handleRefresh()

}
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="h6">Claim Status</Typography>
                    </Grid>
                    <Grid item xs={2} className="text-right">
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {selectedItems?.length === 0 && (
                    <DialogContentText>
                        No Claims Selected, please select atleast one claim to
                        update status
                    </DialogContentText>
                )}

                {selectedItems?.length > 0 && (
                    <Loadable loading={isUpdateStatusPending}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validate={values => {
                                const errors : any = {};

                                if (!values.status) {
                                    errors.status = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }


                                return errors
                            }}
                            onSubmit={(
                                values,
                                {  }
                            ) => {

                                const payload={ids:selectedItems,value:values?.status?.value}
                                updateClaimStatuses(payload,updateStatusCallback)
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                               
                                handleSubmit,
                                setFieldValue,
                            
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormControl
                                                className={
                                                    classes.formControlAuto
                                                }
                                            >
                                                <Autocomplete
                                                    onChange={(e, value) =>
                                                        setFieldValue(
                                                            "status",
                                                            value
                                                        )
                                                    }
                                                    getOptionSelected={(option, value) => option.name === value.name}

                                                    value={values.status}
                                                    options={claimStatusList}
                                                    
                                                    getOptionLabel={option =>
                                                        option.name || ""
                                                    }
                                               
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Status"
                                                            error={Boolean(
                                                                touched.status &&
                                                                    errors.status
                                                            )}
                                                            helperText={
                                                                touched.status &&
                                                                errors.status
                                                            }
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            className="justify-content-end pt-2"
                                            style={{ marginTop: "20px" }}
                                        >
                                            <Button
                                                type="button"
                                                onClick={handleClose}
                                                variant="contained"
                                                className="mr-3"
                                                color="default"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Loadable>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default UpdateClaimStatusDialog;
