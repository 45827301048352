import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import HealthCheckPageComponent from './HealthCheckPage';
import { getHealthOfCloudfront, getHealthOfCloudwatch, getHealthOfECS, getHealthOfNetwork, getHealthOfRDS, getHealthOfRedshift, getHealthOfS3, getHealthOfSecretManager, getHealthOfSQS } from '../../../store/actions/controlRoom';

export const HealthCheckPage = connect(
    () => {
        return (state, props) => ({
            redshiftHealth: state.controlRoom.redshiftHealth,
            redShiftHealthPending: state.controlRoom.getRedshiftHealth.pending,

            rdsHealth: state.controlRoom.rdsHealth,
            rdsHealthPending: state.controlRoom.getRDSHealth.pending,

            ecsHealth: state.controlRoom.ecsHealth,
            ecsHealthPending: state.controlRoom.getECSHealth.pending,

            networkHealth: state.controlRoom.networkHealth,
            networkHealthPending: state.controlRoom.getNetworkHealth.pending,

            sqsHealth: state.controlRoom.sqsHealth,
            sqsHealthPending: state.controlRoom.getSQSHealth.pending,

            s3Health: state.controlRoom.s3Health,
            s3HealthPending: state.controlRoom.getS3Health.pending,

            cloudfrontHealth: state.controlRoom.cloudfrontHealth,
            cloudfrontHealthPending: state.controlRoom.getCloudfrontHealth.pending,

            cloudwatchHealth: state.controlRoom.cloudwatchHealth,
            cloudwatchHealthPending: state.controlRoom.getCloudwatchHealth.pending,

            secretManagerHealth: state.controlRoom.secretManagerHealth,
            secretManagerHealthPending: state.controlRoom.getSecretManagerHealth.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getHealthOfRedshift: getHealthOfRedshift,
            getHealthOfRDS: getHealthOfRDS,
            getHealthOfECS: getHealthOfECS,
            getHealthOfNetwork: getHealthOfNetwork,
            getHealthOfSQS: getHealthOfSQS,
            getHealthOfS3: getHealthOfS3,
            getHealthOfCloudwatch: getHealthOfCloudwatch,
            getHealthOfCloudfront: getHealthOfCloudfront,
            getHealthOfSecretManager: getHealthOfSecretManager
        }, dispatch)
    })
)(injectIntl(HealthCheckPageComponent));