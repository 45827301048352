import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import PublishMRFComponent from './PublishMRF';
import { exportCsv, getNegotiatedRatesSignOff, publishMRFs } from '../../../../store/actions/pricers';
import { createSelector } from 'reselect';
import { selectIsSuperAdmin } from '../../../../store/selectors/session';
import { selectBrand } from '../../../../store/selectors/brand';
import { get } from '../../../../store/actions/brand';
import { selectSignOff } from '../../../../store/selectors/pricers';

export const PublishMRF = connect(
    () => {


        const selectMRFRes = createSelector(
            state => state.pricers.publishMRFsRes,
            (mrfs) => {
                if (mrfs) {
                    let arr = []
                    for (const prop in mrfs) {
                        arr.push({
                            file: prop,
                            status: mrfs[prop]
                        })
                    }
                    return arr;
                }
                return [];
            }
        )

        return (state, props) => ({
            mrfs: selectMRFRes(state),
            isSuperAdmin: selectIsSuperAdmin(state),
            publishMRFloading: state.pricers.publishMRFs.pending,
            downloadMRFloading: state.pricers.exportCsv.pending,
            getNegotiatedRatesSignOffPending: state.pricers.getNegotiatedRatesSignOff.pending,
            signOff: selectSignOff(state),
            clientBrand: selectBrand(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            publishMRFs: publishMRFs,
            getNegotiatedRatesSignOff: getNegotiatedRatesSignOff,
            exportCsv: exportCsv,
            getBrand: get,
        }, dispatch)
    })
)(injectIntl(PublishMRFComponent));