import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getNPIRoles = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/npiroles`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createNPIRole = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/npirole`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const editNPIRole = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/npirole`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteNPIRole = (npiRoleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/npirole/${npiRoleId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getNPIRoles,
    createNPIRole,
    editNPIRole,
    deleteNPIRole
};