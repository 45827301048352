import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { selectIsSuperAdmin } from "../../../store/selectors/session";
import { selectClientListTiny, selectSelectedClient, selectSelectedClientSchema } from "../../../store/selectors/clients";
import { listTiny } from '../../../store/actions/clients';

import CopyContractDialogComponent from './CopyContractDialog';
import { copyContract, setCopyContractName, copyContractReset, setCopyContractDialog } from '../../../store/actions/contracts';
import { getRulesByContractId, resetRulesByContractId } from '../../../store/actions/rules';
import { getIPPSTinyByContract, getIPPSTinyByTargetClient } from '../../../store/actions/drg';
import { getOPPSTinyByContract, getOPPSTinyByTargetClient } from '../../../store/actions/opps';
import { getFeeScheduleFromClient } from '../../../store/actions/feeScheduleItems';
import { getServicesFromTargetClient } from '../../../store/actions/services';
import { getFacilitiesByTargetClient } from '../../../store/actions/facilities';
import {getFeeScheduleLabelsFromTargetClient,validateLabelsFromTargetClient} from '../../../store/actions/feeScheduleV2';

export const CopyContractDialog = connect(
    () => {
        const selectCopyContractName = (state) => state.contracts.copyContractName;
        const selectCopyContractDialog = (state, props) => state.contracts.copyContractDialog;
        const selectContractIPPSConfigs = (state) => state.drg.ippsByContract;
        const selectTargetClientIPPSConfigs = (state) => state.drg.ippsByTargetClient;
        const selectContractOPPSConfigs = (state) => state.opps.oppsByContract;
        const selectTargetClientOPPSConfigs = (state) => state.opps.oppsByTargetClient;

        const selectCopyContractDialogOpen = createSelector(
            selectCopyContractDialog,
            (dialog) => {
                if(dialog && dialog?.open) {
                    return true;
                }
                return false;
            }
        );

        const selectContractId = createSelector(
            selectCopyContractDialog,
            (dialog) => {
                if(dialog && dialog?.existingContractId) {
                    return dialog.existingContractId;
                }
                return null;
            }
        );

        const selectExistingContractName = createSelector(
            selectCopyContractDialog,
            (dialog) => {
                if(dialog && dialog?.existingContractName) {
                    return dialog.existingContractName;
                }
                return null;
            }
        );

        const selectExistingContractStateId = createSelector(
            selectCopyContractDialog,
            (dialog) => {
                if(dialog && dialog?.existingContractStateId) {
                    return dialog.existingContractStateId;
                }
                return null;
            }
        );

        const selectFeeScheduleRules = createSelector(
            state => state.rules.rulesByContractId,
            (rulesByContractId) => {
                return rulesByContractId.filter(item => item.feeScheduleId !== null)
            }
        )

        const selectCodeServicesRules = createSelector(
            state => state.rules.rulesByContractId,
            (rulesByContractId) => {
                return rulesByContractId.filter(item => item.codeServiceId !== null)
            }
        )

        const selectHasPPSConfiguations = createSelector(
            selectContractIPPSConfigs,
            selectContractOPPSConfigs,
            (ipps, opps) => {
                if(ipps && opps && ipps.length > 0 && opps.length > 0) {
                    return true;
                }
                return false;
            }
        );

        const selectInitialValues = createSelector(
            selectExistingContractName,
            (existingContractName) => {
                return {
                    newContractName: `Copy - ${existingContractName}`,
                    targetFacilities: [],
                    feeScheduleMapping: [],
                    rateLabelMapping:[],
                    codeServiceMapping: [],
                    ippsConfigMapping: [],
                    oppsConfigMapping: []
                }
            }
        )

        return (state, props) => ({
            open: selectCopyContractDialogOpen(state, props),
            copyContractPending: state.contracts.copyContract.pending,

            clients: selectClientListTiny(state),
            selectedClient: selectSelectedClient(state),
            selectedClientSchema: selectSelectedClientSchema(state),
            isSuperAdmin: selectIsSuperAdmin(state),
            contractId: selectContractId(state, props),
            contractName: selectExistingContractName(state, props),
            stateId: selectExistingContractStateId(state, props),
            initialValues: selectInitialValues(state, props),
            feeScheduleRules: selectFeeScheduleRules(state),
            codeServiceRules: selectCodeServicesRules(state),

            getFeeSchdulePending: state.feeScheduleItems.getFeeScheduleFromClient.pending,
            getRulesPending: state.rules.getRulesByContractId.pending,
            hasRulesApiResponded: state.rules.getRulesByContractId.hasApiResponded,
            getCodeServicePending: state.services.getCodeServicesFromTargetClient.pending,
            facilitiesFromTargetClientPending: state.facilities.getFacilitiesFromTargetClient.pending,

            feeSchedulesFromClient: state.feeScheduleItems.feeSchedulesFromClient,
            codeServicesFromClient: state.services.codeServicesFromTargetClient,
            facilitiesFromTargetClient: state.facilities.facilitiesFromTargetClient,

            hasPPSConfiguations: selectHasPPSConfiguations(state),
            IPPSConfigs: selectContractIPPSConfigs(state),
            OPPSConfigs: selectContractOPPSConfigs(state),
            IPPSFromTargetClient: selectTargetClientIPPSConfigs(state),
            OPPSFromTargetClient: selectTargetClientOPPSConfigs(state),
            getIPPSTinyByContractPending: state.drg.getIPPSTinyByContract.pending,
            getOPPSTinyByContractPending: state.opps.getOPPSTinyByContract.pending,
            getFeeScheduleLabelsFromTargetClientsPending: state.feeScheduleV2.getFeeScheduleLabelsFromTargetClients.pending,
            feeScheduleLabelsValidate:state.feeScheduleV2.feeScheduleLabelsValidate



        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            clientListTiny: listTiny,
            copyContract: copyContract,
            getRulesByContractId: getRulesByContractId,
            getFeeScheduleFromClient: getFeeScheduleFromClient,
            resetRulesByContractId: resetRulesByContractId,
            getServicesFromTargetClient: getServicesFromTargetClient,
            setCopyContractName: setCopyContractName,
            getFacilitiesByTargetClient: getFacilitiesByTargetClient,
            copyContractReset: copyContractReset,
            setCopyContractDialog: setCopyContractDialog,
            getIPPSTinyByContract: getIPPSTinyByContract,
            getIPPSTinyByTargetClient: getIPPSTinyByTargetClient,
            getOPPSTinyByContract: getOPPSTinyByContract,
            getOPPSTinyByTargetClient: getOPPSTinyByTargetClient,
            getFeeScheduleLabelsFromTargetClient:getFeeScheduleLabelsFromTargetClient,
            validateLabelsFromTargetClient:validateLabelsFromTargetClient

        }, dispatch)
    })
)(injectIntl(CopyContractDialogComponent));