export const CONTRACTS_GET_DEFAULT = 'CONTRACTS_GET_DEFAULT';
export const CONTRACTS_GET_DEFAULT_PENDING = 'CONTRACTS_GET_DEFAULT_PENDING';
export const CONTRACTS_GET_DEFAULT_FULFILLED = 'CONTRACTS_GET_DEFAULT_FULFILLED';
export const CONTRACTS_GET_DEFAULT_REJECTED = 'CONTRACTS_GET_DEFAULT_REJECTED';

export const RULE_NAME_CHECK = 'RULE_NAME_CHECK';
export const RULE_NAME_CHECK_PENDING = 'RULE_NAME_CHECK_PENDING';
export const RULE_NAME_CHECK_FULFILLED = 'RULE_NAME_CHECK_FULFILLED';
export const RULE_NAME_CHECK_REJECTED = 'RULE_NAME_CHECK_REJECTED';

export const CONTRACTS_GET = 'CONTRACTS_GET';
export const CONTRACTS_GET_PENDING = 'CONTRACTS_GET_PENDING';
export const CONTRACTS_GET_FULFILLED = 'CONTRACTS_GET_FULFILLED';
export const CONTRACTS_GET_REJECTED = 'CONTRACTS_GET_REJECTED';

export const CONTRACTS_CREATE = 'CONTRACTS_CREATE';
export const CONTRACTS_CREATE_PENDING = 'CONTRACTS_CREATE_PENDING';
export const CONTRACTS_CREATE_FULFILLED = 'CONTRACTS_CREATE_FULFILLED';
export const CONTRACTS_CREATE_REJECTED = 'CONTRACTS_CREATE_REJECTED';

export const CONTRACTS_EDIT_RESET = 'CONTRACTS_EDIT_RESET';

export const CONTRACTS_UPDATE = 'CONTRACTS_UPDATE';
export const CONTRACTS_UPDATE_PENDING = 'CONTRACTS_UPDATE_PENDING';
export const CONTRACTS_UPDATE_FULFILLED = 'CONTRACTS_UPDATE_FULFILLED';
export const CONTRACTS_UPDATE_REJECTED = 'CONTRACTS_UPDATE_REJECTED';

export const CONTRACTS_DOCUMENTS_LIST = 'CONTRACTS_DOCUMENTS_LIST';
export const CONTRACTS_DOCUMENTS_LIST_PENDING = 'CONTRACTS_DOCUMENTS_LIST_PENDING';
export const CONTRACTS_DOCUMENTS_LIST_FULFILLED = 'CONTRACTS_DOCUMENTS_LIST_FULFILLED';
export const CONTRACTS_DOCUMENTS_LIST_REJECTED = 'CONTRACTS_DOCUMENTS_LIST_REJECTED';

export const CONTRACTS_DOCUMENT_GET = 'CONTRACTS_DOCUMENT_GET';
export const CONTRACTS_DOCUMENT_GET_PENDING = 'CONTRACTS_DOCUMENT_GET_PENDING';
export const CONTRACTS_DOCUMENT_GET_FULFILLED = 'CONTRACTS_DOCUMENT_GET_FULFILLED';
export const CONTRACTS_DOCUMENT_GET_REJECTED = 'CONTRACTS_DOCUMENT_GET_REJECTED';

export const CONTRACTS_UPLOAD = 'CONTRACTS_UPLOAD';
export const CONTRACTS_UPLOAD_PENDING = 'CONTRACTS_UPLOAD_PENDING';
export const CONTRACTS_UPLOAD_FULFILLED = 'CONTRACTS_UPLOAD_FULFILLED';
export const CONTRACTS_UPLOAD_REJECTED = 'CONTRACTS_UPLOAD_REJECTED';

export const CONTRACTS_SET_LANDING_FILTER = 'CONTRACTS_SET_LANDING_FILTER';

export const CONTRACTS_RULES_LIST = 'CONTRACTS_RULES_LIST';
export const CONTRACTS_RULES_LIST_PENDING = 'CONTRACTS_RULES_LIST_PENDING';
export const CONTRACTS_RULES_LIST_FULFILLED = 'CONTRACTS_RULES_LIST_FULFILLED';
export const CONTRACTS_RULES_LIST_REJECTED = 'CONTRACTS_RULES_LIST_REJECTED';

export const CONTRACTS_FEE_SCHEDULE_LIST = 'CONTRACTS_FEE_SCHEDULE_LIST';
export const CONTRACTS_FEE_SCHEDULE_LIST_PENDING = 'CONTRACTS_FEE_SCHEDULE_LIST_PENDING';
export const CONTRACTS_FEE_SCHEDULE_LIST_FULFILLED = 'CONTRACTS_FEE_SCHEDULE_LIST_FULFILLED';
export const CONTRACTS_FEE_SCHEDULE_LIST_REJECTED = 'CONTRACTS_FEE_SCHEDULE_LIST_REJECTED';

export const CONTRACTS_FEE_SCHEDULE = 'CONTRACTS_FEE_SCHEDULE';
export const CONTRACTS_FEE_SCHEDULE_PENDING = 'CONTRACTS_FEE_SCHEDULE_PENDING';
export const CONTRACTS_FEE_SCHEDULE_FULFILLED = 'CONTRACTS_FEE_SCHEDULE_FULFILLED';
export const CONTRACTS_FEE_SCHEDULE_REJECTED = 'CONTRACTS_FEE_SCHEDULE_REJECTED';

export const CONTRACTS_FEE_SCHEDULE_LABELS_TINY = 'CONTRACTS_FEE_SCHEDULE_LABELS_TINY';
export const CONTRACTS_FEE_SCHEDULE_LABELS_TINY_PENDING = 'CONTRACTS_FEE_SCHEDULE_LABELS_TINY_PENDING';
export const CONTRACTS_FEE_SCHEDULE_LABELS_TINY_FULFILLED = 'CONTRACTS_FEE_SCHEDULE_LABELS_TINY_FULFILLED';
export const CONTRACTS_FEE_SCHEDULE_LABELS_TINY_REJECTED = 'CONTRACTS_FEE_SCHEDULE_LABELS_TINY_REJECTED';

export const CONTRACTS_GET_GROUPS = 'CONTRACTS_GET_GROUPS';
export const CONTRACTS_GET_GROUPS_PENDING = 'CONTRACTS_GET_GROUPS_PENDING';
export const CONTRACTS_GET_GROUPS_FULFILLED = 'CONTRACTS_GET_GROUPS_FULFILLED';
export const CONTRACTS_GET_GROUPS_REJECTED = 'CONTRACTS_GET_GROUPS_REJECTED';
export const CONTRACTS_GROUPS_RESET = 'CONTRACTS_GROUPS_RESET';

export const CONTRACTS_GROUPS_TREE = 'CONTRACTS_GROUPS_TREE';
export const CONTRACTS_GROUPS_TREE_PENDING = 'CONTRACTS_GROUPS_TREE_PENDING';
export const CONTRACTS_GROUPS_TREE_FULFILLED = 'CONTRACTS_GROUPS_TREE_FULFILLED';
export const CONTRACTS_GROUPS_TREE_REJECTED = 'CONTRACTS_GROUPS_TREE_REJECTED';

export const CONTRACTS_GET_ALERT_USERS = 'CONTRACTS_GET_ALERT_USERS';
export const CONTRACTS_GET_ALERT_USERS_PENDING = 'CONTRACTS_GET_ALERT_USERS_PENDING';
export const CONTRACTS_GET_ALERT_USERS_FULFILLED = 'CONTRACTS_GET_ALERT_USERS_FULFILLED';
export const CONTRACTS_GET_ALERT_USERS_REJECTED = 'CONTRACTS_GET_ALERT_USERS_REJECTED';

export const CONTRACTS_LIST_TINY = 'CONTRACTS_LIST_TINY';
export const CONTRACTS_LIST_TINY_PENDING = 'CONTRACTS_LIST_TINY_PENDING';
export const CONTRACTS_LIST_TINY_FULFILLED = 'CONTRACTS_LIST_TINY_FULFILLED';
export const CONTRACTS_LIST_TINY_REJECTED = 'CONTRACTS_LIST_TINY_REJECTED';

export const CONTRACTS_PAYMENT_DETAILS = 'CONTRACTS_PAYMENT_DETAILS';
export const CONTRACTS_PAYMENT_DETAILS_PENDING = 'CONTRACTS_PAYMENT_DETAILS_PENDING';
export const CONTRACTS_PAYMENT_DETAILS_FULFILLED = 'CONTRACTS_PAYMENT_DETAILS_FULFILLED';
export const CONTRACTS_PAYMENT_DETAILS_REJECTED = 'CONTRACTS_PAYMENT_DETAILS_REJECTED';

export const CONTRACTS_RULE_DELETE = 'CONTRACTS_RULE_DELETE';
export const CONTRACTS_RULE_DELETE_PENDING = 'CONTRACTS_RULE_DELETE_PENDING';
export const CONTRACTS_RULE_DELETE_FULFILLED = 'CONTRACTS_RULE_DELETE_FULFILLED';
export const CONTRACTS_RULE_DELETE_REJECTED = 'CONTRACTS_RULE_DELETE_REJECTED';

export const CONTRACTS_RULE_RESET = 'CONTRACTS_RULE_RESET';

export const CONTRACTS_FEE_SCHEDULE_TYPES = 'CONTRACTS_FEE_SCHEDULE_TYPES';
export const CONTRACTS_FEE_SCHEDULE_TYPES_PENDING = 'CONTRACTS_FEE_SCHEDULE_TYPES_PENDING';
export const CONTRACTS_FEE_SCHEDULE_TYPES_FULFILLED = 'CONTRACTS_FEE_SCHEDULE_TYPES_FULFILLED';
export const CONTRACTS_FEE_SCHEDULE_TYPES_REJECTED = 'CONTRACTS_FEE_SCHEDULE_TYPES_REJECTED';

export const CONTRACTS_FEE_SCHEDULE_CREATE = 'CONTRACTS_FEE_SCHEDULE_CREATE';
export const CONTRACTS_CREATE__FEE_SCHEDULE_PENDING = 'CONTRACTS_CREATE__FEE_SCHEDULE_PENDING';
export const CONTRACTS_CREATE_FEE_SCHEDULE_FULFILLED = 'CONTRACTS_CREATE_FEE_SCHEDULE_FULFILLED';
export const CONTRACTS_CREATE_FEE_SCHEDULE_REJECTED = 'CONTRACTS_CREATE_FEE_SCHEDULE_REJECTED';

export const CONTRACTS_FEE_SCHEDULE_UPDATE = 'CONTRACTS_FEE_SCHEDULE_UPDATE';
export const CONTRACTS_UPDATE__FEE_SCHEDULE_PENDING = 'CONTRACTS_UPDATE__FEE_SCHEDULE_PENDING';
export const CONTRACTS_UPDATE_FEE_SCHEDULE_FULFILLED = 'CONTRACTS_UPDATE_FEE_SCHEDULE_FULFILLED';
export const CONTRACTS_UPDATE_FEE_SCHEDULE_REJECTED = 'CONTRACTS_UPDATE_FEE_SCHEDULE_REJECTED';

export const CONTRACTS_FEE_SCHEDULE_EDIT_RESET = 'CONTRACTS_FEE_SCHEDULE_EDIT_RESET';

export const CONTRACTS_GET_FEE_SCHEDULE_CODES = 'CONTRACTS_GET_FEE_SCHEDULE_CODES';
export const CONTRACTS_GET_FEE_SCHEDULE_CODES_PENDING = 'CONTRACTS_GET_FEE_SCHEDULE_CODES_PENDING';
export const CONTRACTS_GET_FEE_SCHEDULE_CODES_FULFILLED = 'CONTRACTS_GET_FEE_SCHEDULE_CODES_FULFILLED';
export const CONTRACTS_GET_FEE_SCHEDULE_CODES_REJECTED = 'CONTRACTS_GET_FEE_SCHEDULE_CODES_REJECTED';

export const COPY_CONTRACTS = 'COPY_CONTRACTS';
export const COPY_CONTRACTS_PENDING = 'COPY_CONTRACTS_PENDING';
export const COPY_CONTRACTS_FULFILLED = 'COPY_CONTRACTS_FULFILLED';
export const COPY_CONTRACTS_REJECTED = 'COPY_CONTRACTS_REJECTED';
export const COPY_CONTRACTS_RESET = 'COPY_CONTRACTS_RESET';

export const DELETE_CONTRACT = 'DELETE_CONTRACT';
export const DELETE_CONTRACT_PENDING = 'DELETE_CONTRACT_PENDING';
export const DELETE_CONTRACT_FULFILLED = 'DELETE_CONTRACT_FULFILLED';
export const DELETE_CONTRACT_REJECTED = 'DELETE_CONTRACT_REJECTED';
export const DELETE_CONTRACT_RESET = 'DELETE_CONTRACT_RESET';

export const CONTRACTS_RESET_FEE_SCHEDULE = 'CONTRACTS_RESET_FEE_SCHEDULE';

export const CONTRACTS_SERVICES_TAB_UPDATE = 'CONTRACTS_SERVICES_TAB_UPDATE';

export const CONTRACTS_GET_CRITERIA = 'CONTRACTS_GET_CRITERIA';
export const CONTRACTS_GET_CRITERIA_PENDING = 'CONTRACTS_GET_CRITERIA_PENDING';
export const CONTRACTS_GET_CRITERIA_FULFILLED = 'CONTRACTS_GET_CRITERIA_FULFILLED';
export const CONTRACTS_GET_CRITERIA_REJECTED = 'CONTRACTS_GET_CRITERIA_REJECTED';

// export const CONTRACTS_GET_CODE_SERVICES = 'CONTRACTS_GET_CODE_SERVICES';
// export const CONTRACTS_GET_CODE_SERVICES_PENDING = 'CONTRACTS_GET_CODE_SERVICES_PENDING';
// export const CONTRACTS_GET_CODE_SERVICES_FULFILLED = 'CONTRACTS_GET_CODE_SERVICES_FULFILLED';
// export const CONTRACTS_GET_CODE_SERVICES_REJECTED = 'CONTRACTS_GET_CODE_SERVICES_REJECTED';

export const CONTRACTS_GET_STATES = 'CONTRACTS_GET_STATES';
export const CONTRACTS_GET_STATES_PENDING = 'CONTRACTS_GET_STATES_PENDING';
export const CONTRACTS_GET_STATES_FULFILLED = 'CONTRACTS_GET_STATES_FULFILLED';
export const CONTRACTS_GET_STATES_REJECTED = 'CONTRACTS_GET_STATES_REJECTED';

export const CONTRACTS_UPDATE_STATE = 'CONTRACTS_UPDATE_STATE';
export const CONTRACTS_UPDATE_STATE_PENDING = 'CONTRACTS_UPDATE_STATE_PENDING';
export const CONTRACTS_UPDATE_STATE_FULFILLED = 'CONTRACTS_UPDATE_STATE_FULFILLED';
export const CONTRACTS_UPDATE_STATE_REJECTED = 'CONTRACTS_UPDATE_STATE_REJECTED';

export const CONTRACTS_GET_CASCODES = 'CONTRACTS_GET_CASCODES';
export const CONTRACTS_GET_CASCODES_PENDING = 'CONTRACTS_GET_CASCODES_PENDING';
export const CONTRACTS_GET_CASCODES_FULFILLED = 'CONTRACTS_GET_CASCODES_FULFILLED';
export const CONTRACTS_GET_CASCODES_REJECTED = 'CONTRACTS_GET_CASCODES_REJECTED';

export const CONTRACTS_GET_CONTRACTED_SERVICES = 'CONTRACTS_GET_CONTRACTED_SERVICES';
export const CONTRACTS_GET_CONTRACTED_SERVICES_PENDING = 'CONTRACTS_GET_CONTRACTED_SERVICES_PENDING';
export const CONTRACTS_GET_CONTRACTED_SERVICES_FULFILLED = 'CONTRACTS_GET_CONTRACTED_SERVICES_FULFILLED';
export const CONTRACTS_GET_CONTRACTED_SERVICES_REJECTED = 'CONTRACTS_GET_CONTRACTED_SERVICES_REJECTED';

export const CONTRACTS_GET_DELETE_DEPENDENCY = 'CONTRACTS_GET_DELETE_DEPENDENCY';
export const CONTRACTS_GET_DELETE_DEPENDENCY_PENDING = 'CONTRACTS_GET_DELETE_DEPENDENCY_PENDING';
export const CONTRACTS_GET_DELETE_DEPENDENCY_FULFILLED = 'CONTRACTS_GET_DELETE_DEPENDENCY_FULFILLED';
export const CONTRACTS_GET_DELETE_DEPENDENCY_REJECTED = 'CONTRACTS_GET_DELETE_DEPENDENCY_REJECTED';

export const CONTRACTS_GET_TINY_BY_STATE = 'CONTRACTS_GET_TINY_BY_STATE';
export const CONTRACTS_GET_TINY_BY_STATE_PENDING = 'CONTRACTS_GET_TINY_BY_STATE_PENDING';
export const CONTRACTS_GET_TINY_BY_STATE_FULFILLED = 'CONTRACTS_GET_TINY_BY_STATE_FULFILLED';
export const CONTRACTS_GET_TINY_BY_STATE_REJECTED = 'CONTRACTS_GET_TINY_BY_STATE_REJECTED';

export const CONTRACT_SET_DEPENDENCY_DIALOG = 'CONTRACT_SET_DEPENDENCY_DIALOG';

export const CONTRACT_SET_STATE_DIALOG = 'CONTRACT_SET_STATE_DIALOG';

export const CONTRACT_SET_COPY_CONTRACT_NAME = 'CONTRACT_SET_COPY_CONTRACT_NAME';
export const COPY_CONTRACT_SET_DIALOG = 'COPY_CONTRACT_SET_DIALOG';

export const getDefaults = (callback) => ({
    type: CONTRACTS_GET_DEFAULT,
    payload: { callback },
});

export const nameValidity = (bucketName, index) => ({
    type: RULE_NAME_CHECK,
    payload: { bucketName, index },
});

export const get = (contractId) => ({
    type: CONTRACTS_GET,
    payload: { contractId },
});

export const create = (contract, callback) => ({
    type: CONTRACTS_CREATE,
    payload: { contract, callback },
});

export const update = (id, contract, callback) => ({
    type: CONTRACTS_UPDATE,
    payload: { id, contract, callback },
});

export const copyContract = (options, callback) => ({
    type: COPY_CONTRACTS,
    payload: { options, callback },
});

export const getDocumentList = (contractId) => ({
    type: CONTRACTS_DOCUMENTS_LIST,
    payload: { contractId },
});

export const upload = (file, documentObj, callback) => ({
    type: CONTRACTS_UPLOAD,
    payload: { file, documentObj, callback },
});

export const getDocument = (documentId, documentName, metadata, callback) => ({
    type: CONTRACTS_DOCUMENT_GET,
    payload: { documentId, documentName, metadata, callback },
});

export const setContractFilter = (isPayer) => ({
    type: CONTRACTS_SET_LANDING_FILTER,
    payload: { isPayer },
});

export const getRules = (contractServiceId) => ({
    type: CONTRACTS_RULES_LIST,
    payload: { contractServiceId },
});

export const getFeeschedule = (feeScheduleId) => ({
    type: CONTRACTS_FEE_SCHEDULE,
    payload: { feeScheduleId },
});

export const listFeeSchedule = () => ({
    type: CONTRACTS_FEE_SCHEDULE_LIST,
});

export const feeScheduleLabelsTiny = (feeScheduleId, callback, feescheduleLabelToRateXRefId, setFieldValue) => ({
    type: CONTRACTS_FEE_SCHEDULE_LABELS_TINY,
    payload: { feeScheduleId, callback, feescheduleLabelToRateXRefId, setFieldValue },
});

export const getGroups = (ruleId) => ({
    type: CONTRACTS_GET_GROUPS,
    payload: { ruleId },
});

export const resetGroups = () => ({
    type: CONTRACTS_GROUPS_RESET,
});

export const getTreeGroups = (treePayload, callback) => ({
    type: CONTRACTS_GROUPS_TREE,
    payload: { treePayload, callback },
});

export const getAlertUsers = (clientId, callback) => ({
    type: CONTRACTS_GET_ALERT_USERS,
    payload: { clientId, callback },
});

export const tiny = (callback) => ({
    type: CONTRACTS_LIST_TINY,
    payload: { callback },
});

export const paymentDetails = (payment, callback) => ({
    type: CONTRACTS_PAYMENT_DETAILS,
    payload: { payment, callback },
});

export const deleteRule = (ruleId, callback) => ({
    type: CONTRACTS_RULE_DELETE,
    payload: { ruleId, callback },
});

export const getFeeScheduleTypes = () => ({
    type: CONTRACTS_FEE_SCHEDULE_TYPES
});

export const createFeeSchedule = (feeSchedule, callback) => ({
    type: CONTRACTS_FEE_SCHEDULE_CREATE,
    payload: { feeSchedule, callback },
});

export const updateFeeSchedule = (feeSchedule, callback) => ({
    type: CONTRACTS_FEE_SCHEDULE_UPDATE,
    payload: { feeSchedule, callback },
});

export const resetFeeSchedule = () => ({
    type: CONTRACTS_RESET_FEE_SCHEDULE
});

export const updateServiceTab = (servicesTab) => ({
    type: CONTRACTS_SERVICES_TAB_UPDATE,
    payload: {
        servicesTab
    }
});
export const getFeeScheduleCodes = (options) => ({
    type: CONTRACTS_GET_FEE_SCHEDULE_CODES,
    payload: { options }
});

// export const getInpatientServices = (callback) => ({
//     type: CONTRACTS_GET_CODE_SERVICES,
//     payload: { callback },
// });

export const copyContractReset = () => ({
    type: COPY_CONTRACTS_RESET
});

export const feeScheduleEditReset = () => ({
    type: CONTRACTS_FEE_SCHEDULE_EDIT_RESET
});

export const contractEditReset = () => ({
    type: CONTRACTS_EDIT_RESET
});

export const deleteContract = (contractId, callback) => ({
    type: DELETE_CONTRACT,
    payload: { contractId, callback }
});

export const ruleReset = () => ({
    type: CONTRACTS_RULE_RESET
});

export const deleteReset = () => ({
    type: DELETE_CONTRACT_RESET
});

export const getContractCriteria = () => ({
    type: CONTRACTS_GET_CRITERIA
});

export const getStates = () => ({
    type: CONTRACTS_GET_STATES
});

export const updateContractState = (options, callback) => ({
    type: CONTRACTS_UPDATE_STATE,
    payload: { options, callback }
});

export const setStateDialog = (options) => ({
    type: CONTRACT_SET_STATE_DIALOG,
    payload: options
});

export const getCascodes = () => ({
    type: CONTRACTS_GET_CASCODES
});

export const setCopyContractName = (copyContractName) => ({
    type: CONTRACT_SET_COPY_CONTRACT_NAME,
    payload: { copyContractName }
});

export const getContractedServices = (contractId) => ({
    type: CONTRACTS_GET_CONTRACTED_SERVICES,
    payload: { contractId }
});

export const getContractDependencies = (contractId, callback) => ({
    type: CONTRACTS_GET_DELETE_DEPENDENCY,
    payload: { contractId, callback }
});

export const setContractDependencyDialog = (dependencyDialog) => ({
    type: CONTRACT_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});

export const getTinyContractsByState = (state) => ({
    type: CONTRACTS_GET_TINY_BY_STATE,
    payload: { state }
});

export const setCopyContractDialog = (copyContractDialog) => ({
    type: COPY_CONTRACT_SET_DIALOG,
    payload: { copyContractDialog }
});