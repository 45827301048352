import {
    Button, Dialog, DialogContent, DialogTitle,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Loadable } from '../../../common';

const useStyles = makeStyles(theme => ({
    formControlDropdown: {
        margin: theme.spacing(1),
        width: '95%'
    },
}));

export default function ContractStateDialog(props) {
    const {
        open, contractId, handleClose, contractStateUpdatePending, contractStates, contractStateId,
        actions: { updateContractState }
    } = props;

    const [stateId, setStateId] = useState('');
    const classes = useStyles();

    useEffect(() => {
        if (contractStateId) {
            setStateId(contractStateId);
        }
    }, [contractStateId]);

    const handleStateChange = (e) => {
        setStateId(e.target.value);
    }

    const handleSubmit = () => {
        const payload = {
            contractId: contractId,
            contractStateId: stateId
        };

        updateContractState(payload, submitCallback);
    }

    const submitCallback = () => {
        handleClose(true);
    }

    return (
        <Dialog fullWidth open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="copy-contract-title">
                Contract State
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={contractStateUpdatePending}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControlDropdown}>
                                <InputLabel htmlFor="contractStateId">Contract State</InputLabel>
                                <Select
                                    required
                                    value={stateId}
                                    onChange={handleStateChange}
                                    input={<Input id="contractStateId" />}
                                    inputProps={{
                                        name: "contractStateId",
                                        id: "contractStateId"
                                    }}
                                >
                                    {contractStates.map(state => (
                                        <MenuItem
                                            key={state.id}
                                            value={state.id}
                                        >
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid className='pt-4 justify-content-end' container>
                        <Grid item xs={2}>
                            <Button
                                onClick={() => handleClose(false)}
                                variant="contained"
                                color="default"
                                type='button'
                                className={classes.button}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSubmit}
                                disabled={contractStateUpdatePending}
                            >
                                {(contractStateUpdatePending) ? 'Saving..' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Loadable>
            </DialogContent>
        </Dialog >
    )
}