import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import NPIRolesComponent from './NPIRolesPage';

import { createStringDataField } from '../../util/format';
import { StringEquators } from '../../store/constants/pages';
import { getByName } from '../../store/actions/search.js';
import { deleteNPIRole, npiRolesLanding } from '../../store/actions/npiRoles';
import { set } from '../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../util/selector';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'desc' }];


const selectFields = createSelector(
    () => [

        createStringDataField('name', 'Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('npis', 'NPIs', { sortable: true, equators: StringEquators, wordBreak: 'break-all' }),
        createStringDataField('description', 'Description', { sortable: true, equators: StringEquators }),
    ]
);

export const selectNPIRolesList = (state) => state.npiRoles.data;

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`npiRoles-landing`] ? state.pages[`npiRoles-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`npiRoles-landing`] ? state.pages[`npiRoles-landing`].filter : null,
    selectSort,
    selectNPIRolesList,
);



export const NPIRolesPage = connect(
    () => {
        return (state, props) => ({
            order: state.pages['npiRoles-landing'] ? state.pages['npiRoles-landing'].order : null,
            filter: state.pages['npiRoles-landing'] ? state.pages['npiRoles-landing'].filter : null,
            sort: selectSort(state, props),

            npiFields: selectFields(state, props),
            npiRolesData: selectFilteredData(state, props),

            getNPIRolesPending: state.npiRoles.list.pending,

            deleteNPIRolesPending: state.npiRoles.delete.pending,
            deleteNPIRolesError: state.npiRoles.delete.error,
            deleteNPIRolesErrorMessage: state.npiRoles.delete.errorMessage,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            searchGetByName: getByName,
            npiRolesLanding: npiRolesLanding,
            setFilter: (value) => set(`npiRoles-landing`, 'filter', value),
            setOrder: (value) => set(`npiRoles-landing`, 'order', value),
            setSort: (value) => set(`npiRoles-landing`, 'sort', value),
            deleteNPIRole: deleteNPIRole
        }, dispatch)
    })
)(injectIntl(NPIRolesComponent));