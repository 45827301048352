import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set } from '../../../../../../store/actions/pages';
import { createStringDataField } from '../../../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../../../util/selector';
import { getcarveoutDescriptions } from '../../../../../../store/actions/rules';
import CarveoutCodesDialogComponent from './CarveoutCodesDialog';
import { selectCarveoutDescriptions } from '../../../../../../store/selectors/rules';

const DEFAULT_SORT = [{ id: 'code', orderBy: 'asc' }];

const selectFields = createSelector(
    (state) => [
        createStringDataField('code', 'Code', { sortable: true }),
        createStringDataField('description', 'Description', { sortable: true }),
        createStringDataField('type', 'Type', { sortable: true }),
    ]
);

const selectRuleId = (state, props) => props.ruleId;

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectDescriptions = createSelector(
    selectCarveoutDescriptions,
    (codes) => {
        return codes;
    }
)

const selectSort = createSelector(
    (state, props) => state.pages[`rules-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`rules-landing`].filter,
    selectSort,
    selectDescriptions,
);

export const CodesCarveoutCodesDialog = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            order: state.pages[`rules-landing`].order,
            filter: state.pages[`rules-landing`].filter,
            sort: selectSort(state, props),
            ruleId: selectRuleId(state, props),
            codeServiceDescriptionListPending: state.rules.getCarveOutCodeDescription.pending,
            title: "Carveout Codes"
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getcarveoutDescriptions: getcarveoutDescriptions,
            setFilter: (value) => set(`rules-landing`, 'filter', value),
            setOrder: (value) => set(`rules-landing`, 'order', value),
            setSort: (value) => set(`rules-landing`, 'sort', value),
        }, dispatch)
    })
)(injectIntl(CarveoutCodesDialogComponent));