import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { FormGroup, Grid, Switch, Tooltip, Typography,Button,styled } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';

import { useSelector } from "react-redux";
import { RootState } from '../../../store/reducers';


function ContractsTopFilter({ intl, pending, customFilter, handleRefresh, actions: { setMulti,setOpenAdvanceFiltersDialog } }) {
    const [isFinalized, setFinalized] = useState(true);
    const filter = useSelector( ( state : RootState ) => state.pages['contracts-landing']?.filter || []);

    useEffect(() => {
        setTimeout(() => {
            if (isFinalized) {
                setMulti({
                    list: [],
                    customFilter: [{
                        field: 'isPayer',
                        equator: 'equals',
                        value: true,
                    },
                    {
                        field: "contractState.name",
                        equator: "equals",
                        value: "Finalized",
                    }]
                });
            } else {
                setMulti({
                    list: [],
                    customFilter: [{
                        field: 'isPayer',
                        equator: 'equals',
                        value: true,
                    }]
                });
            }
            handleRefresh();
        }, 200);

    }, [isFinalized, setMulti])

    const handleStateChange = (event) => {
        setFinalized(event.target.checked);
    }
    const openAdvancedFiltersPanel = () => {
        setOpenAdvanceFiltersDialog(true)
    }

    return (
        <Grid container spacing={1}>
            <Grid item>
                <FormGroup>
                    <Tooltip title='Toggle to view finalized claims or all claims'>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={0} className='mb-0'>
                                <Grid item>All</Grid>
                                <Grid item>
                                    <Switch
                                        disabled={pending}
                                        checked={isFinalized}
                                        onChange={handleStateChange}
                                        name="userActive" />
                                </Grid>
                                <Grid item>Finalized</Grid>
                            </Grid>
                        </Typography>
                    </Tooltip>

                </FormGroup>
            </Grid>

            <Grid item>
            <Button
          onClick={() =>setOpenAdvanceFiltersDialog(true)}

          variant="contained" color='primary'
          startIcon={

            !filter.length ? <FilterListIcon /> : <></>
          }
          endIcon={
            filter.length ? <> ({<NumberChip>{filter.length}</NumberChip>})</> : <></>

          }
        >Advance Filters</Button>                </Grid>
        </Grid>
    )
}
const NumberChip = styled('div')({
    fontSize: '12px !important',
    color: 'white',
    padding: '0 2.5px 0 2.5px',
    position:'relative',
    bottom:"1px"
  })
  

export default ContractsTopFilter


