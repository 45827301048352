import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/shoppables';
import * as pages_ACTIONS from '../actions/pages';
import * as API from '../api/shoppables';
import { sessionErrorHandling } from './session';

function* getList(action) {
    try {
        yield put({ type: actions.SHOPPABLE_PENDING });
        const payload = yield call(API.getShoppables);
        yield put({ type: actions.SHOPPABLE_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SHOPPABLE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateStatus(action) {
    try {
        yield put({ type: actions.SHOPPABLE_UPDATE_STATUS_PENDING });
        const {
            id,
            enabled,
            callback
        } = action.payload;
        yield call(API.updateShoppableStatus, id, enabled);
        yield put({ type: actions.SHOPPABLE_UPDATE_STATUS_FULFILLED, payload: { id: id, enabled: enabled } });
        yield put({ type: pages_ACTIONS.SHOPPABLE_UPDATE_STATUS_FULFILLED, payload: { id: id, enabled: enabled } });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SHOPPABLE_UPDATE_STATUS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* selectDeselectAll(action) {
    try {
        yield put({ type: actions.SHOPPABLE_SELECT_ALL_PENDING });
        const {
            payload,
            from,
            to,
            callback,
        } = action.payload;
        yield call(API.selectDeselectAll, payload);
        yield put({ type: actions.SHOPPABLE_SELECT_ALL_FULFILLED });
        if (callback) {
            callback(from, to);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SHOPPABLE_SELECT_ALL_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getCounts(action) {
    try {
        yield put({ type: actions.SHOPPABLE_COUNTS_PENDING });
        const { facilityId } = action.payload;
        const payload = yield call(API.getCounts, facilityId);
        yield put({ type: actions.SHOPPABLE_COUNTS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SHOPPABLE_COUNTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* exportShoppables(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.SHOPPABLE_EXPORT_PENDING });
        const payload = yield call(API.exportShoppables, options);
        yield put({ type: actions.SHOPPABLE_EXPORT_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SHOPPABLE_EXPORT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.SHOPPABLE_RESET });
}

export default function* () {
    yield takeEvery(actions.SHOPPABLE_LIST, getList);
    yield takeEvery(actions.SHOPPABLE_UPDATE_STATUS, updateStatus);
    yield takeEvery(actions.SHOPPABLE_SELECT_ALL, selectDeselectAll);
    yield takeEvery(actions.SHOPPABLE_COUNTS, getCounts);
    yield takeEvery(actions.SHOPPABLE_EXPORT, exportShoppables);
}