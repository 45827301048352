import React from 'react';
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { Button, TextField, makeStyles } from "@material-ui/core";
import { Loadable, Container } from '../../../common'

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
}));

export default function ForgotPasswordComponent(props) {
    const { intl, forgotPasswordPending, forgotPasswordError, forgotPasswordErrorMessage, actions: { forgotPassword } } = props;
    const classes = useStyles();

    const handleCallback = () => {
        props.history.push('/auth/forgot-password-confirmation');
    }
    return (
        <Container>
            <Loadable loading={forgotPasswordPending}>
                <div className="kt-login__signin">
                    <div className="kt-login__head">
                        <div className="kt-login__title">
                            <h3>
                                {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                                <FormattedMessage id="AUTH.FORGOT.TITLE" />
                            </h3>
                        </div>
                    </div>

                    <Formik
                        initialValues={{
                            username: "",
                        }}
                        validate={values => {
                            const errors = {};

                            if (!values.username) {
                                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                                errors.username = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            return errors;
                        }}
                        onSubmit={(values) => {
                            forgotPassword(values.username, handleCallback);
                        }}
                    >
                        {({
                            values,
                            status,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                                <form
                                    noValidate={true}
                                    autoComplete="off"
                                    className="kt-form"
                                    onSubmit={handleSubmit}
                                >
                                    {forgotPasswordError && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{forgotPasswordErrorMessage}</div>
                                        </div>
                                    )}
                                    <div className="input-group">
                                        <TextField
                                            type="username"
                                            label="Username"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="username"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.username}
                                            helperText={touched.username && errors.username}
                                            error={Boolean(touched.username && errors.username)}
                                        />
                                    </div>
                                    <div className="kt-login__actions">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            type="submit"
                                            disabled={forgotPasswordPending}
                                            size="large"
                                        >
                                            {forgotPasswordPending ? 'Loading...' : 'Reset Password'}
                                        </Button>
                                    </div>
                                </form>
                            )}
                    </Formik>
                </div>
            </Loadable>
        </Container>
    );
}