import { SERVER_URL, METHOD_GET,METHOD_POST } from "../constants/api";
import { headersAuthSendReceiveJson } from "../headers";
import { apiHandleResponse } from "../../util/api";

const createAPPId = (options) => {
    return fetch(`${SERVER_URL}/api/v1/application`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};


const getAppIdList = options => {
    return fetch(`${SERVER_URL}/api/v1/application/list`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson()
    }).then(apiHandleResponse);
};

const getAppKeyById = (id) => {
    return fetch(`${SERVER_URL}/api/v1/application/appid/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};



export { getAppIdList,getAppKeyById,createAPPId };
