import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/statistics';
import * as API from '../api/statistics';
import { sessionErrorHandling } from './session';

function* get(action) {
    try {
        yield put({ type: actions.STATISTICS_GET_PENDING });
        const { filters: { frequency, clientId, state } } = action.payload;

        // if clientFilter not passed, it will bring data for all clients.
        const clientFilter = clientId === 'all' ? undefined : clientId;
        const stateFilter = state === 'all' ? undefined : state;
        const payload = yield call(API.getStatistics, frequency, clientFilter, stateFilter);
        yield put({ type: actions.STATISTICS_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.STATISTICS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getRemitsAndPayersStatistics(action) {
    try {
        yield put({ type: actions.STATISTICS_GET_REMITS_AND_PAYERS_PENDING });
        const { frequency,schema } = action.payload;
        const payload = yield call(API.getRemitsAndPayersStatistics, frequency, schema);
        yield put({ type: actions.STATISTICS_GET_REMITS_AND_PAYERS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.STATISTICS_GET_REMITS_AND_PAYERS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFiles(action) {
    try {
        yield put({ type: actions.STATISTICS_GET_FILES_PENDING });
        const { callback } = action.payload;
        const payload = yield call(API.getFiles);
        yield put({ type: actions.STATISTICS_GET_FILES_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.STATISTICS_GET_FILES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.STATISTICS_RESET });
}

export default function* () {
    yield takeEvery(actions.STATISTICS_GET, get);
    yield takeEvery(actions.STATISTICS_GET_FILES, getFiles);
    yield takeEvery(actions.STATISTICS_GET_REMITS_AND_PAYERS, getRemitsAndPayersStatistics);

}