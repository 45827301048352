import React from "react";
import { FieldArray, getIn } from "formik";
import { TextMaskCustom } from '../../../../common';


import {
    Button,
    makeStyles,
    TextField,
    Grid,
    IconButton,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { Card } from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
    },
    portlet: {
        'box-shadow': 'none',
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
}));


export default function ContactFieldArray(props) {
    const {
        vendorContacts, handleBlur, handleChange, touched, errors, mode, contactTemplate
    } = props;

    const classes = useStyles();
    const isEdit = mode === 'edit';

    const hasError = (index, name) => {
        return Boolean(getIn(touched, `vendorContacts.${index}.${name}`) &&
            getIn(errors, `vendorContacts.${index}.${name}`));
    }

    const getHelperText = (index, name) => {
        return getIn(touched, `vendorContacts.${index}.${name}`) &&
            getIn(errors, `vendorContacts.${index}.${name}`);
    }

    return (
        <FieldArray
            name="vendorContacts"
            render={({ remove, push }) => (
                <Grid container>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <Card.Header>
                                <Grid container className="align-items-center">
                                    <Grid xs={10} item>
                                        <h5 className="mb-0">{mode === 'create' ? "Vendor Contacts" : "Vendor Contacts"}</h5>
                                    </Grid>
                                    <Grid xs={2} className="text-right" item>
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => push({ ...contactTemplate })}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card.Header>
                            <Card.Body>
                                {vendorContacts.map((contact, index) => {
                                    return (
                                        <Grid item xs={12} key={index}>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        required
                                                        label="Name"
                                                        name={`vendorContacts.${index}.name`}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={contact.name}
                                                        helperText={getHelperText(index, 'name')}
                                                        error={hasError(index, 'name')}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        label="Title"
                                                        name={`vendorContacts.${index}.title`}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={contact.title}
                                                        helperText={getHelperText(index, 'title')}
                                                        error={hasError(index, 'title')}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        required
                                                        label="Email"
                                                        name={`vendorContacts.${index}.emails`}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={contact.emails}
                                                        helperText={getHelperText(index, 'emails')}
                                                        error={hasError(index, 'emails')}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Phone"
                                                        name={`vendorContacts.${index}.phone`}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={contact.phone}
                                                        helperText={getHelperText(index, 'phone')}
                                                        error={hasError(index, 'phone')}
                                                        InputProps={{
                                                            inputComponent: TextMaskCustom,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {vendorContacts.length > 0 && (
                                                        <IconButton
                                                            edge="start"
                                                            color="secondary"
                                                            aria-label="Delete"
                                                            onClick={() => remove(index)}
                                                            className={classes.iconButton}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                                {(vendorContacts && vendorContacts.length < 1) && (
                                    <Grid item className="pt-1">
                                        No contacts found!
                                    </Grid>
                                )}
                            </Card.Body>
                        </Card>

                    </Grid>
                </Grid>
            )}
        />
    );
}
