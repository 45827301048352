import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import UnauthorizedPageComponent from './UnauthorizedPage';
import { selectSessionUser } from '../../../store/selectors/session';

export const UnauthorizedPage = connect(

    () => {
        return (state, props) => ({
            user: selectSessionUser(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(UnauthorizedPageComponent));