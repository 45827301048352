import { Grid, Tooltip } from '@material-ui/core';
import React from 'react'
import { DeleteConfirmation } from '../../../../../../../common/DeleteConfirmation';

function DeleteItems(props) {
    const { selectedItems, handleRefresh,
        actions: {
            deleteFeeScheduleItems,
            setSelected
        }
    } = props;

    const handleFeScheduleItemsDelete = () => {
        if (selectedItems && selectedItems.length > 0) {
            deleteFeeScheduleItems(selectedItems, handleCallBack);
        }
    }

    const handleCallBack = () => {
        setSelected([]);
        handleRefresh();
    }

    return (
        <Grid item>
            <Tooltip title="Delete Fee Schedule Rate">
                <DeleteConfirmation isAllowedToDelete={(selectedItems.length === 0) ? false : true} handleDelete={handleFeScheduleItemsDelete} buttonColor={'default'} iconSize={'small'} />
            </Tooltip>
        </Grid>
    )
}

export default DeleteItems
