import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import ErrorDetailsComponent from './ErrorDetails';
import { selectSessionRole } from '../../store/selectors/session';

export const ErrorDetails = (connect(
    () => {
        return (state, props) => ({
            userRole: selectSessionRole(state),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(ErrorDetailsComponent))); 