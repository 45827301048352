import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Interweave } from 'interweave';
import Draggable from 'react-draggable';

interface TraceDialogProps {
    open: boolean;
    handleClose: () => void;
    traces: string;
    title?: string;
}

function TraceDialog({ open, handleClose, traces, title }: TraceDialogProps) {
    function PaperComponent(props) {
        return (
            <Draggable
                handle="#basic-trace-dialog"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <Dialog
            fullWidth
            scroll="paper"
            maxWidth="md"
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="basic-trace-dialog"
        >
            <DialogTitle style={{ cursor: 'move' }} id="basic-trace-dialog">
                <Grid container alignItems='center'>
                    <Grid item xs={11}>
                        {title ?? 'Analysis Traces'}
                    </Grid>
                    <Grid item xs={1} className='text-right'>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Interweave content={traces ?? 'N/A'} />
            </DialogContent>
        </Dialog>
    )
}

export default TraceDialog