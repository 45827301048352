import { createSelector } from "reselect";
import { RECOVERED_AMOUNT_ID } from "../constants/settings";
import { selectSelectedClient, selectClientListTiny } from "./clients";

export const selectSettings = (state, props) => state.settings.data;
export const selectRelease = (state, props) => state.settings.release;
export const selectReleaseNotes = (state, props) => state.settings.releaseNotes;

export const selectRecoveredAmountFlag = createSelector(
    selectSettings,
    (settings) => {
        const recoveredAmountFlag = settings.find(setting => setting.id === RECOVERED_AMOUNT_ID);
        return recoveredAmountFlag && recoveredAmountFlag.value && recoveredAmountFlag.value.toLowerCase() === 'true' ? true : false;
    }
);