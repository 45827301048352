import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    Box,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
    makeStyles,
    InputAdornment,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Tooltip,
    Chip
} from "@material-ui/core";
import { Loadable, TableLevelAction } from "../../../common";
import CloseIcon from "@material-ui/icons/Close";
import HeaderColumn from "../../../common/dataTable/HeaderColumn";
import SearchIcon from "@material-ui/icons/Search";

import ClearIcon from '@material-ui/icons/Clear';




const useStyles = makeStyles(theme => {
    return {
        searchBox: {
            float: "left",
            position: "absolute",
            left: 0,
            width: "100%",
            background: "#fff",
            borderRadius: "2px",
            border: "1px #bdbbbb solid",
            overflow: "hidden",
            zIndex: 999,
            minHeight: "100px",
            padding: "10px"
        },
        root: {
            height: 240,
            flexGrow: 1,
            maxWidth: 400
        },
        selectionTableRow: {
            cursor: "pointer"
        },
        folderDiv: {
            cursor: "pointer",
            textDecoration: "underline"
        },
        backBox: {
            width: "100%",
            marginBottom: "20px",
            textAlign: "left"
        },
        selectionRow: {
            cursor: "pointer"
        },
        chipBox: {
            paddingTop: '8px'
        },
        clearIcon:{
            fontSize:'14px',
            marginLeft:'5px',
        }
    };
});

function FeeScheduleSelectionComponent({data = [],
    intl,
    fields,
    sort,
    pendingTargetClient,
    clientData,
    pending,
    handleSetFeeSchedule,
    defaultValue,
    value,
    targetClient,
    actions: { feeScheduleSearch, setSort,feeScheduleSearchFromClient }}) {

    const [isOpen, setOpen] = useState(false);
    const [searchText, setSearchText] = useState({text:"",clearAble:false});
    const [directoryId, setDirectoryId] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        if (isOpen) {

            if(targetClient)
            {
            
                feeScheduleSearchFromClient({
                    targetClient:targetClient,
                    keyword: searchText.text,
                    directoryId: directoryId.length === 0
                    ? null
                    : directoryId[directoryId.length - 1]
                })
                
                return 
            }
            
            feeScheduleSearch({
                keyword: searchText.text,
                directoryId:
                    directoryId.length === 0
                        ? null
                        : directoryId[directoryId.length - 1]
            });
        }
    }, [feeScheduleSearch, isOpen]);

    const handleClick = () => {
        setSearchText({text:"",clearAble:false});
        setOpen(true);
    };

    const handleDialogClose = () => {
        setDirectoryId([])
        setOpen(false);
        setSearchText({text:"",clearAble:false});
    };

    function handleSubmit() {

        if (searchText.text.length >= 3 || searchText.text.length === 0) {

            setSearchText({...searchText,clearAble:searchText.text.length>=3 ? true:false});

            if(targetClient)
            {
                feeScheduleSearchFromClient({
                    targetClient:targetClient,
                    keyword: searchText.text,
                    directoryId: directoryId.length === 0
                    ? null
                    : directoryId[directoryId.length - 1]
                })
    
                return 

            }

            feeScheduleSearch({
                keyword: searchText.text,
                directoryId:
                    directoryId.length === 0
                        ? null
                        : directoryId[directoryId.length - 1]
            });
        }
    }

    function handleRowClick(rowData) {
        handleSetFeeSchedule(rowData);
        setSearchText({text:rowData.name,clearAble:false});

        handleDialogClose();
    }

    const handleRefresh = () => {
        setSearchText({text:'',clearAble:false});
        if(targetClient)
        {
        
            feeScheduleSearchFromClient({
                targetClient:targetClient,
                keyword: "",
                directoryId: directoryId[directoryId.length - 1]
            })

            return 
        }

        feeScheduleSearch({
            keyword: "",
            directoryId: directoryId[directoryId.length - 1]
        }); 
    };

    const handleSort = ({ id, direction, fieldType }) => {
        if (!setSort) {
            return;
        }

        const sorts = [...sort];
        const updatedSort = { id, fieldType, direction: "asc" };
        const oldSort = sorts.find(sort => {
            return sort.id === id;
        });

        // sort exists, toggle sort
        if (oldSort && oldSort.id === id) {
            const index = sorts.indexOf(oldSort);
            updatedSort.direction =
                oldSort.direction === "asc" ? "desc" : "asc";
            sorts[index] = updatedSort;
        }
        // new sort
        else {
            updatedSort.direction = "desc";
            sorts.splice(0, sorts.length);
            sorts.push(updatedSort);
        }
        setSort(sorts);
    };


    const { id: columnOrder, direction } =
        (sort && sort.length > 0 && sort[0]) || {};

    const handleRedirectToDirectory = dId => {
        setDirectoryId([...directoryId, dId]);

        if(targetClient)
        {
        
            feeScheduleSearchFromClient({
                targetClient:targetClient,
                keyword: "",
                directoryId:dId
            })
            return 
        }
        
        feeScheduleSearch({
            keyword: "",
            directoryId: dId
        });
    };

    const getData = () => {
        return targetClient ? clientData : data;
    };

    const renderSearchTextField = () => {
        return (
            <>
                <TextField
                    fullWidth
                    label="Search"
                    value={searchText.text}
                    onChange={event => setSearchText({...searchText, text:event.target.value})}
                    // variant='outlined'
                    name="searchTextField"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSubmit}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </>
        );
    };

    const handleGoBack = () => {
        let backPaths = directoryId.slice(0, directoryId.length - 1);
        setDirectoryId([...backPaths]);

        setSearchText({text:'',clearAble:false})

        if(targetClient)
        {
        
            feeScheduleSearchFromClient({
                targetClient:targetClient,
                keyword: "",
                directoryId:backPaths[backPaths.length - 1]
            })
            return 
        }
        

        feeScheduleSearch({
            keyword: "",
            directoryId: backPaths[backPaths.length - 1]
        });
    };
    


    return (
        <>
            <ClickAwayListener onClickAway={handleDialogClose}>
                <div className={classes.chipBox}>
                    {defaultValue ? (
                        <Chip style={{maxWidth: '480px'}}
                            label={
                                searchText.text != "" && !isOpen
                                    ? (searchText.text.length > 50 ? `${searchText.text.substring(0, 50)}...` : searchText.text)
                                    : (value.length > 50 ? `${value.substring(0, 50)}...` : value)
                                    || (defaultValue.length > 50 ? `${defaultValue.substring(0, 50)}...` : defaultValue)
                                    || "Search Fee Schedules"
                            }
                            title={
                                searchText.text != "" && !isOpen
                                    ? searchText.text : value
                                    || defaultValue || "Search Fee Schedules"
                            }
                            onClick={handleClick}
                        />
                    ) : (
                        <IconButton
                            className="p-1"
                            aria-label="Search"
                            onClick={handleClick}
                        >
                            <Tooltip title="Search">
                                <SearchIcon />
                            </Tooltip>
                        </IconButton>
                    )}

                    {isOpen ? (
                        <Dialog
                            maxWidth="md"
                            fullWidth
                            open={isOpen}
                            onClose={handleDialogClose}
                        >
                            <Loadable loading={pending || pendingTargetClient}>
                                <DialogTitle>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <Typography variant="h6">
                                                Fee Schedules Search
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {renderSearchTextField()}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            className="text-right"
                                        >
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleDialogClose}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent dividers>
                                    {/* display data */}
                                    {/* {data.map(item => <div> {item.name} -- {item.isDirectory ? 'Folder' : 'Fee schedule'} </div>)} */}

                                    <Grid spacing={1} container>
                                        <Grid item xs={12}>
                                            <TableLevelAction
                                                handleRefresh={handleRefresh}
                                                name={""}
                                                loading={false}
                                                actionLevelGrid={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                className={`${classes.backBox}`}
                                            >
                                                {directoryId.length != 0 && !searchText.clearAble && (
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleGoBack}
                                                    >
                                                        Go Back
                                                    </Button>
                                                )}

                                                {searchText.clearAble  && (
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleRefresh}
                                                    >
                                                        Clear Search <ClearIcon className={`${classes.clearIcon}`} />
                                                    </Button>
                                                )}
                                            </Box>
                                            <Table stickyHeader={true}>
                                                <TableHead>
                                                    <TableRow
                                                        className={`fee-schedule-thr`}
                                                    >
                                                        {fields?.map(
                                                            (field, index) =>
                                                                !field?.options
                                                                    .hideFromGrid && (
                                                                    <HeaderColumn
                                                                        key={`column-${index}`}
                                                                        field={
                                                                            field
                                                                        }
                                                                        onSort={
                                                                            handleSort
                                                                        }
                                                                        direction={
                                                                            direction
                                                                        }
                                                                        columnOrder={
                                                                            columnOrder
                                                                        }
                                                                        colSpan={
                                                                            index ===
                                                                                0 &&
                                                                            4
                                                                        }
                                                                    />
                                                                )
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {getData()?.length === 0 &&
                                                        getData()?.length === 0 && (
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell
                                                                    colSpan={5}
                                                                >
                                                                    No records
                                                                    found!
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    {getData()?.map(item => {
                                                        return (
                                                            item ?.isDirectory &&
                                                            <TableRow className="fee-schedule-row">
                                                                <TableCell
                                                                    id={`folder-${item.id}`}
                                                                    colSpan={4}
                                                                >
                                                                    <div
                                                                        id={`folder-${item.id}`}
                                                                        className={
                                                                            classes.folderDiv
                                                                        }
                                                                        onClick={() =>
                                                                            handleRedirectToDirectory(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            id={`folder-${item.id}`}
                                                                            className="p-1 mr-2"
                                                                        >
                                                                            <i
                                                                                id={`folder-${item.id}`}
                                                                                style={{
                                                                                    color:
                                                                                        "#FFCA35"
                                                                                }}
                                                                                className="fas fa-folder"
                                                                            ></i>
                                                                        </IconButton>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="pl-4">
                                                                    {item.path}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {getData()?.map(item => {
                                                        return (
                                                            !item?.isDirectory &&
                                                            <TableRow
                                                                className={`fee-schedule-row ${classes.selectionRow}`}
                                                                onClick={() =>
                                                                    handleRowClick(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <TableCell
                                                                    className="pl-4"
                                                                    colSpan={4}
                                                                >
                                                                    {item.name}
                                                                </TableCell>
                                                                <TableCell className="pl-4">
                                                                    {item.path}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className="p-4">
                                    <Button
                                        variant="contained"
                                        onClick={handleDialogClose}
                                        color="default"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Loadable>
                        </Dialog>
                    ) : null}
                </div>
            </ClickAwayListener>
        </>
    );
}

export default FeeScheduleSelectionComponent;