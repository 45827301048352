import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { CustomLabel, Loadable } from '../../../common';
import CloseIcon from '@material-ui/icons/Close';
import { EllipsisText } from '../../../common/EllipsisText';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DependencyDialog(props) {
    const { open, planId, payerPlanDependency, payerPlanDependencyPending, deletePayerPlanPending, canDelete,
        handleRefresh, actions: { setPayerPlanDependencyDialog, getPayerPlanDependency, deletePayerPlan } } = props;

    const classes = useStyles();

    useEffect(() => {
        if (open) {
            getPayerPlanDependency(planId);
        }
    }, [open, planId, getPayerPlanDependency]);

    const handleDialogClose = () => {
        setPayerPlanDependencyDialog(null);
    }

    const handleDeleteCallback = () => {
        setPayerPlanDependencyDialog(null);
        handleRefresh();
    }

    const handleDelete = () => {
        deletePayerPlan(planId, handleDeleteCallback);
    }


    return (
        <Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={payerPlanDependencyPending || deletePayerPlanPending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Dependencies
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    <Grid>
                        {payerPlanDependency.length > 0 ?
                            <Typography>{canDelete ? 'Are you sure to delete claim payers with following dependencies?' : 'Following dependencies are found:'}</Typography> :
                            <Typography>No dependencies found. Click to delete</Typography>
                        }
                    </Grid>
                    {payerPlanDependency.length > 0 && <Grid className='pt-2'>
                        <ol className='lower-alpha'>
                            {payerPlanDependency?.map((item, index) =>
                                <li key={`key-${index}`}>
                                    <b>{item.name} </b>
                                    <Grid>
                                        <ul>
                                            {item?.dependents?.map((dep, pcIndex) => <li key={`contract-key-${pcIndex}`}>
                                                <CustomLabel label={`${dep.dependentType}(${dep.count.toString()})`} data={
                                                    <EllipsisText textArr={dep.names} />
                                                } />
                                            </li>)}
                                        </ul>
                                    </Grid>
                                </li>
                            )}
                        </ol>
                    </Grid>}
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose} color="default">
                        Cancel
                    </Button>
                    {canDelete &&
                        <Button
                            onClick={handleDelete}
                            variant="contained"
                            disabled={payerPlanDependencyPending || deletePayerPlanPending}
                            color="primary"
                        >
                            Delete
                        </Button>
                    }
                </DialogActions>
            </Loadable >
        </Dialog>
    )
}

export default DependencyDialog
