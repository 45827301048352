import React, { useState } from 'react'
import ErrorIcon from '@material-ui/icons/Error';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    IconButton: {
        padding: 6
    },
    iconColor: {
        color: '#fd397a'
    }
}));

function ExecutionErrorDialog({ executionError }) {

    const classes = useStyles();
    const [isErrorDialogOpen, setErrorDialog] = useState(false);

    const handleDialogOpen = () => {
        setErrorDialog(true);
    }

    const handleDialogClose = () => {
        setErrorDialog(false);
    }

    return (
        <>
            <IconButton
                className={classes.IconButton}
                aria-label="Execution Error"
                onClick={handleDialogOpen}
            >
                <Tooltip title='Execution Error'>
                    <ErrorIcon className={classes.iconColor} />
                </Tooltip>
            </IconButton>
            <Dialog fullWidth open={isErrorDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    Execution Error
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        {executionError}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="default">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ExecutionErrorDialog