import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { deleteDRG, getIPPSDeleteDependencies, setDependencyDialog, resetDependencies } from '../../../store/actions/drg';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.drg.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (oppsDependencyDialog) => {
                if (oppsDependencyDialog && oppsDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectIPPSId = createSelector(
            selectDependencyDialog,
            (oppsDependencyDialog) => {
                if (oppsDependencyDialog && oppsDependencyDialog?.ippsId) {
                    return oppsDependencyDialog?.ippsId
                }
                return null
            }
        );

        const selectIPPSName = createSelector(
            selectDependencyDialog,
            (oppsDependencyDialog) => {
                if (oppsDependencyDialog && oppsDependencyDialog?.name) {
                    return oppsDependencyDialog?.name
                }
                return ''
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            ippsId: selectIPPSId(state, props),
            dependencies: state.drg.dependencies?.records ?? [],
            canDelete: state.drg.dependencies?.canDelete ?? false,
            getDependenciesPending: state.drg.getIPPSDependencies.pending,
            deletePendng: state.drg.delete.pending,
            oppsName: selectIPPSName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getIPPSDeleteDependencies: getIPPSDeleteDependencies,
            deleteDRG: deleteDRG,
            setDependencyDialog: setDependencyDialog,
            resetDependencies: resetDependencies
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));