import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { flag } from '../../../store/actions/claimAnalysis';
import FlagDialogComponent from './FlagDialog';
import { setV2 } from '../../../store/actions/pagesV2';

export const FlagDialog = connect(
    () => {
        const selectClaimNumber = (state, props) => props.claimNumber;

        const selectDefaultFlag = (state, props) => state.claimAnalysis.flagToggle;
        const selectIsBulkFlagged = (state, props) => state.claimAnalysis.isBulkFlagged;
        const selectSingleClaimId = (state, props) => state.claimAnalysis.selectedClaims;
        const selectBulkClaimIds = (state, props) => state.pagesV2['claimAnalysis-landing'].selected;

        // const selectFollowUpDays = createSelector(selectSettings,
        //     (settings) => {
        //         const followUpDaysSetting = settings.find(setting => setting.id === DEFAULT_FOLLOWUP_DATE_ID);
        //         return followUpDaysSetting ? parseInt(followUpDaysSetting.value) : 0
        //     }
        // );

        const selectClaimIds = createSelector(
            selectIsBulkFlagged,
            selectBulkClaimIds,
            selectSingleClaimId,
            (isBulkFlagged, bulkIds, singleId) => {
                if (isBulkFlagged) {
                    return bulkIds
                }
                return singleId
            }
        )

        const selectInitialValues = createSelector(
            selectClaimIds,
            selectDefaultFlag,
            (claimIds, defaultToggle) => {
                return {
                    analysisId: claimIds,
                    flag: defaultToggle,
                    followupAlert: '', //date field
                    setFollowUpDate: true // toggle for adding date field
                }
            }
        );

        return (state, props) => ({
            flagClaimPending: state.claimAnalysis.flag.pending,

            initialValues: selectInitialValues(state, props),
            claimNumber: selectClaimNumber(state, props),
            open: state.claimAnalysis.openFlagDialog,
            isBulkFlagged: selectIsBulkFlagged(state),
            selectedClaims: selectClaimIds(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            flagClaims: flag,
            setSelected: (value) => setV2('claimAnalysis-landing', 'selected', value),
        }, dispatch)
    })
)(injectIntl(FlagDialogComponent));