import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { createPayerPlan, editPayerPlan, setPayerPlanDialog } from '../../../store/actions/payers';
import PayerPlanDialogComponent from './PayerPlanDialog';

export const PayerPlanDialog = connect(
    () => {

        const selectPayerPlanDialog = (state, props) => state.payers.payerPlanDialog;
        
        const selectPayerPlanDialogOpen = createSelector(
            selectPayerPlanDialog,
            (editDialog) => {
                if (editDialog && editDialog?.open) {
                    return true;
                }
                return false;
            }
        );

        const selectPayerPlan = createSelector(
            selectPayerPlanDialog,
            (editDialog) => {
                if (editDialog && editDialog?.payerPlan) {
                    return editDialog?.payerPlan;
                }
                return null;
            }
        );

        const selectMode = createSelector(
            selectPayerPlanDialog,
            (editDialog) => {
                if (editDialog && editDialog?.mode) {
                    return editDialog?.mode;
                }
                return 'create';
            }
        );

        const selectInitialValues = createSelector(
            selectMode,
            selectPayerPlan,
            (mode, payerPlan) => {
                if (mode === 'edit' && payerPlan) {
                    const payload = {
                        id: payerPlan.id,
                        name: payerPlan.name,
                        isMedicare: payerPlan.isMedicare ?? false,
                    }

                    return payload;
                }

                return {
                    name: '',
                    isMedicare: false,
                }
            }
        )

        return (state, props) => ({
            open: selectPayerPlanDialogOpen(state, props),
            payerPlanCreatePending: state.payers.createPayerPlan.pending,
            payerPlanUpdatePending: state.payers.updatePayerPlan.pending,
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payerPlanCreate: createPayerPlan,
            payerPlanUpdate: editPayerPlan,
            setPayerPlanDialog: setPayerPlanDialog,
        }, dispatch)
    })
)(injectIntl(PayerPlanDialogComponent));