import {
    IconButton,
    makeStyles,
    Tooltip
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import EditIcon from '@material-ui/icons/Edit';
import { DataTable } from '../../common';
import { DeleteConfirmation } from '../../common/DeleteConfirmation';
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";
import { NPIRoleEditDialog } from './NPIRoleEditDialog';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function NPIRolesComponent(props) {
    const { npiFields, npiRolesData, order, filter, sort, getNPIRolesPending, deleteNPIRolesPending,
        actions: {
            npiRolesLanding, deleteNPIRole,
            setOrder, setFilter, setSort
        }
    } = props;

    const [isEditOpen, setEditOpen] = useState(false);
    const [selectedNPIRole, setSelectedNPIRole] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        npiRolesLanding();
    }, [npiRolesLanding]);

    const handleRefresh = () => {
        npiRolesLanding();
        setSelectedNPIRole(null);
    }

    const handleEditClose = (shouldReload) => {
        setEditOpen(false);
        setSelectedNPIRole(null);
        if (shouldReload) {
            handleRefresh();
        }
    }

    const handleAddNPIRole = () => {
        setEditOpen(true);
    }

    const handleEditNPIRole = (rowData) => {
        setEditOpen(true);
        setSelectedNPIRole(rowData);
    }

    const handleDeleteNPIRole = (rowData) => {
        deleteNPIRole(rowData.id, handleRefresh)
    }


    function payerInfoActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleEditNPIRole(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
            <DeleteConfirmation handleDelete={() => handleDeleteNPIRole(rowData)} />

        </div>
    }

    return (
        <>
            <Helmet title="NPI Roles" />
            <Portlet>
                <PortletHeader
                    title="NPI Roles"
                    // showSearchFilters={true}
                    handleRefresh={handleRefresh}
                    name="npiRoles-landing"
                    toolbar={<></>}
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <DataTable
                                name="npiRoles-landing"
                                fields={npiFields}
                                data={npiRolesData}
                                handleRefresh={handleRefresh}
                                defaultSort={DEFAULT_SORT}
                                onAdd={handleAddNPIRole}
                                // tableLevelActions={tableLevelActions}
                                loading={getNPIRolesPending || deleteNPIRolesPending}
                                title="npiroles"
                                order={order}
                                onOrder={setOrder}
                                sort={sort}
                                onSort={setSort}
                                stickyHeader={true}

                                hasActions={true}
                                renderActionColumn={payerInfoActions}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <NPIRoleEditDialog open={isEditOpen} handleClose={handleEditClose} npiRole={selectedNPIRole} />
        </>
    );
}