import React, { useEffect } from 'react'
import { Loadable } from '../../../common';
import { Formik } from 'formik';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../partials/content/Portlet';
import { Button, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, TextField, makeStyles, FormControl } from '@material-ui/core';
import { Link } from "react-router-dom";
import { BackButton } from '../../../common/BackButton';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '90%',
    },
    formControl: {
        width: '90%',
        marginTop: theme.spacing(2),
    },
}));

function EAPGEditPage({ intl, initialValues, history, mode, createPending, editPending, getPending,
    eapgId, eapgVersions, eapgVersionsPending, actions: { createEAPG, getEAPG, editEAPG, getEAPGVersions } } ) {

    const classes = useStyles();

    useEffect(() => {
        getEAPGVersions();
    }, [getEAPGVersions]);

    useEffect(() => {
        if (mode === 'edit') {
            getEAPG(eapgId)
        }
    }, [getEAPG, mode, eapgId]);

    const handleCallback = () => {
        history.push('/cms/eapg');
    }

    return (
        <Loadable loading={createPending || editPending || getPending || eapgVersionsPending}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors : Record<string,string> = {};

                    if (values.name === '') {
                        errors.name = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.significantProcedureRate === '') {
                        errors.significantProcedureRate = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.ancillaryRate === '') {
                        errors.ancillaryRate = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.effectiveFrom === '') {
                        errors.effectiveFrom = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.effectiveTill === '') {
                        errors.effectiveTill = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (!values.eapgVersionId) {
                        errors.eapgVersionId = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    return errors;
                }}
                onSubmit={(values) => {
                    let payload = {
                        name: values.name,
                        significantProcedureRate: values.significantProcedureRate,
                        ancillaryRate: values.ancillaryRate,
                        effectiveFrom: values.effectiveFrom,
                        effectiveTill: values.effectiveTill,
                        eapgVersionId: values.eapgVersionId,
                        id: undefined as number
                    }
                    if (mode === 'create') {
                        createEAPG(payload, handleCallback);
                    } else {
                        payload = {
                            ...payload,
                            id: parseInt(eapgId)
                        }
                        editEAPG(payload, handleCallback);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    dirty
                }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <Portlet>
                            <PortletHeader
                                title='EAPG Configuration'
                                toolbar={
                                    <PortletHeaderToolbar>
                                        <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/cms/eapg" />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className='ml-3'
                                            type="submit"
                                            disabled={createPending || editPending || (mode === 'edit' && !dirty)}
                                        >
                                            Save
                                        </Button>
                                    </PortletHeaderToolbar>
                                }
                            />
                            <PortletBody>
                                <div className="root">
                                    <Grid container>
                                        <Grid xs={8}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        required
                                                        label="Name"
                                                        name="name"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        helperText={touched.name && errors.name}
                                                        error={Boolean(touched.name && errors.name)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <FormControl required className={classes.formControl}>
                                                        <InputLabel htmlFor="eapgVersionId">EAPG Version</InputLabel>
                                                        <Select
                                                        value={values.eapgVersionId}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.eapgVersionId && errors.eapgVersionId)}
                                                        input={<Input id="eapgVersionId" />}
                                                        inputProps={{
                                                            name: "eapgVersionId",
                                                            id: "eapgVersionId"
                                                        }}
                                                        >
                                                        {eapgVersions.map(eapgVersion => (
                                                            <MenuItem
                                                            key={eapgVersion.id}
                                                            value={eapgVersion.id}
                                                            >
                                                            {eapgVersion.version}
                                                            </MenuItem>
                                                        ))}
                                                        </Select>
                                                        <FormHelperText error>{touched.eapgVersionId && errors.eapgVersionId}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Significant Procedure Rate"
                                                        name="significantProcedureRate"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.significantProcedureRate}
                                                        helperText={touched.significantProcedureRate && errors.significantProcedureRate}
                                                        error={Boolean(touched.significantProcedureRate && errors.significantProcedureRate)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Ancillary Rate"
                                                        name="ancillaryRate"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.ancillaryRate}
                                                        helperText={touched.ancillaryRate && errors.ancillaryRate}
                                                        error={Boolean(touched.ancillaryRate && errors.ancillaryRate)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        required
                                                        type="date"
                                                        label="Effective From"
                                                        name="effectiveFrom"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.effectiveFrom}
                                                        helperText={touched.effectiveFrom && errors.effectiveFrom}
                                                        error={Boolean(touched.effectiveFrom && errors.effectiveFrom)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        required
                                                        type="date"
                                                        label="Effective Till"
                                                        name="effectiveTill"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.effectiveTill}
                                                        helperText={touched.effectiveTill && errors.effectiveTill}
                                                        error={Boolean(touched.effectiveTill && errors.effectiveTill)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </form>
                )}
            </Formik>
        </Loadable>
    )
}

export default EAPGEditPage
