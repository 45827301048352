import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import CheckboxComponent from './RowCheckbox';
import { createSelector } from 'reselect';
import { setV2 } from '../../../store/actions/pagesV2';

export const RowCheckbox = (connect(
    () => {

        const selectTable = createSelector(
            (state, props) => props.pageName,
            (state) => state.pagesV2,
            (name, pages) => pages[name]
        );

        const selectSelected = createSelector(
            selectTable,
            (table) => table.selected,
        );

        return (state, props) => ({
            selected: selectSelected(state, props)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2(props.pageName, 'selected', value),
        }, dispatch)
    })
)(injectIntl(CheckboxComponent))); 