export const ROLE_SUPERADMIN = 'super_admin';
export const ROLE_ADMIN = 'administrator';
export const ROLE_USER = 'user';
export const ROLE_DATABASE = 'database';
export const ROLE_QUICKSIGHT = 'quicksight';
export const ROLE_PARTNER_ADMIN = 'partner_admin';
export const ROLE_PARTNER_USER = 'partner_user';
export const ROLE_API = 'api';

export const SUPERADMIN_CLIENT_ID = -1;
export const PARTNER_CLIENT_ID = -2;