import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { moveDirectoryOrFeeSchedule, setMoveDialog } from '../../../store/actions/feeScheduleV2';
import { setV2 } from '../../../store/actions/pagesV2';
import MoveItemsDialogComponent from './MoveItemsDialog';

export const MoveItemsDialog = connect(
    () => {

        const selectDialog = state => state.feeScheduleV2.moveDialog;

        const selectOpen = createSelector(
            selectDialog,
            (dialog) => {
                if (dialog && dialog.open) {
                    return dialog.open
                }
                return false
            }
        );

        const selectHierarchy = (state) => state.feeScheduleV2.hierarchy;

        const selectFeeScheduleIds = (state, props) => state.pagesV2['feeSchedules-landing'].selected;

        return (state, props) => ({
            open: selectOpen(state),
            selectedItems: selectFeeScheduleIds(state),
            hierarchy: selectHierarchy(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setMoveDialog: setMoveDialog,
            moveDirectoryOrFeeSchedule: moveDirectoryOrFeeSchedule,
            resetSelectedFeeSchedule: () => setV2('feeSchedules-landing', 'selected', [])
        }, dispatch)
    })
)(injectIntl(MoveItemsDialogComponent));