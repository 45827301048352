import { Grid, makeStyles, TablePagination } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { DataFilter, DataTable, TablePaginationActions } from '../../../common';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../partials/content/Portlet';

const useStyles = makeStyles(theme => ({
    textField: {
        width: 400,
    },
}));

const DEFAULT_SORT = [{ id: 'executedAt', direction: 'asc' }];

function SeListnersPage(props) {
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    const classes = useStyles();

    const {
        fields, s3ListnersData, sort, order, getS3ListnersPending, filter,
        actions: {
            getS3Listners, setFilter,
            setSort, setOrder
        }
    } = props;

    useEffect(() => {
        getS3Listners();
    }, [getS3Listners]);

    const handleRefresh = () => {
        getS3Listners();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    return (
        <>
            <Helmet title="S3 Listners" />
            <Portlet>
                <PortletHeader
                    title="S3 Listners"
                    name="s3-listners-landing"
                    toolbar={
                        <PortletHeaderToolbar>
                            <DataFilter
                                onChange={handleSearchInput}
                                placeholder="Search"
                                cssClass={classes.textField}
                                filter={filter}
                            />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <Grid container>
                        <Grid item xs={12}>
                            <DataTable
                                name="s3-listners-landing"
                                fields={fields}
                                data={s3ListnersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                handleRefresh={handleRefresh}
                                defaultSort={DEFAULT_SORT}
                                // tableLevelActions={tableLevelActions}
                                loading={getS3ListnersPending}
                                title="modifiers"
                                order={order}
                                onOrder={setOrder}
                                sort={sort}
                                onSort={setSort}
                                stickyHeader={true}
                                showPagination={true}
                                footerPagination={() => (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                        colSpan={fields.length + 2}
                                        count={s3ListnersData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet>
        </>
    )
}

export default SeListnersPage