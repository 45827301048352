export default {
  header: {
    self: {},
    items: [
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "Administration",
        root: true,
        icon: "flaticon2-gear",
        translate: "MENU.ADMINISTRATION",
        bullet: "dot",
        submenu: [
          {
            title: "Clients",
            icon: "flaticon-rotate",
            page: "admin/clients",
          },
          {
            title: "Users",
            icon: "flaticon-users",
            page: "admin/users",
          },
        ],
      }
    ]
  }
};
