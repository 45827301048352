import React, { useEffect, useRef, useState } from 'react';
import { Card as BCard } from 'react-bootstrap';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, makeStyles } from '@material-ui/core';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';

const useStyles = makeStyles(theme => ({
    traceDialogContent: {
        maxHeight: '450px'
    },
    traceCardHeaderPadding: {
        paddingLeft: "9px"
    },
    traceCardUlPadding: {
        paddingLeft: "33px",
        paddingRight: '10px',
        textAlign: 'justify'
    },
    traceResults: {
        position: 'absolute',
        zIndex: '-10',
        left:'2500px'
    }
}));

function AdvancedTrace({ sections, setTraceOpen, isTraceOpen, title }) {
    const [traceCollapseIndex, setTraceCollapseIndex] = useState(0);
    const [lastActiveSecIndex, setLastActiveSecIndex] = useState(0);
    const [isCopied, setIsCopied] = useState(false);

    const classes = useStyles();
    const hiddenDivRef = useRef();

    // handle scrolling of collapse of each section
    useEffect(() => {
        const targetSectionId = traceCollapseIndex === null ? lastActiveSecIndex : traceCollapseIndex;
        const elem = document.getElementById(`section-key-${targetSectionId}`);
        if (elem) {
            elem.focus();
        }
    }, [traceCollapseIndex, lastActiveSecIndex]);

    function handleSetCollpaseIndex(index) {
        if (traceCollapseIndex === index) {
            setTraceCollapseIndex(null);
        } else {
            setTraceCollapseIndex(index);
            setLastActiveSecIndex(index);
        }
    }

    function handleStyleForPrint() {
        document.getElementById('logo-img-print').style.display = 'block';
        document.getElementById('results-div').classList.add('mw-100');
        handlePrint();
        document.getElementById('logo-img-print').style.display = 'none';
        document.getElementById('results-div').classList.remove('mw-100');
    }

    const handlePrint = useReactToPrint({
        content: () => hiddenDivRef.current,
    });

    function copyDivToClipboard() {

        navigator.clipboard
            .writeText(hiddenDivRef.current.innerText)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2500);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    function PaperComponent(props) {
        return (
            <Draggable
                handle="#advanced-trace-dialog"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="md"
                open={isTraceOpen}
                onClose={() => setTraceOpen(false)}
                PaperComponent={PaperComponent}
                aria-labelledby="advanced-trace-dialog"
            >
                <DialogTitle style={{ cursor: 'move' }} id="advanced-trace-dialog">
                    <Grid container alignItems='center'>
                        <Grid item xs={6}>
                            Trace Information
                        </Grid>
                        <Grid item xs={6} className='text-right'>
                            <Grid className='text-right'>
                                <Button
                                    onClick={copyDivToClipboard}
                                    className='mr-2'
                                    variant="outlined"
                                    color={isCopied ? "secondary" : "primary"}
                                    startIcon={<i className='fas fa-copy'></i>}
                                    endIcon={isCopied ? <i className='fas fa-check-circle text-success'></i> : null}
                                >
                                    {isCopied ? 'Copied' : 'Copy'}
                                </Button>
                                <Button
                                    onClick={handleStyleForPrint}
                                    className='mr-2'
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                >
                                    Print
                                </Button>
                                <IconButton aria-label="close" onClick={() => setTraceOpen(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent id='trace-dialog-content' dividers className={classes.traceDialogContent} >
                    <Grid container spacing={2}>
                        {sections.map((section, index) =>
                            <Grid tabIndex="-1" id={`section-key-${index}`} key={`section-key-${index}`} item md={12}>
                                <BCard variant="outlined">
                                    <BCard.Header as="h5" className={classes.traceCardHeaderPadding}>
                                        <IconButton
                                            color="primary"
                                            size="small" onClick={() => handleSetCollpaseIndex(index)}
                                            className='mr-1'
                                        >
                                            {traceCollapseIndex === (index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                        {section.Name}
                                    </BCard.Header>
                                    <BCard.Body className='px-0'>
                                        <Collapse in={traceCollapseIndex === (index)} timeout="auto" unmountOnExit>
                                            <Box marginTop={1} marginBottom={1}>
                                                <ul className={classes.traceCardUlPadding}>
                                                    {section?.Descriptions.map((desc, dIndex) => <li key={`desc-section-key-${dIndex}`}>{desc} </li>)}
                                                </ul>
                                            </Box>
                                        </Collapse>
                                    </BCard.Body>
                                </BCard>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>

            </Dialog>
            {isTraceOpen && <div className={classes.traceResults}>

                <div id='results-div'  ref={hiddenDivRef}>
                    <div id='logo-img-print' style={{ display: 'none' }} className="kt-login__logo text-center my-5">
                        <a href="/#">
                            <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-light.v3.png?v=4")} />
                        </a>
                    </div>
                    <div className='text-center my-4'>
                        <h3>Trace ({title})</h3>
                    </div>
                    <div className='container px-5'>
                        {sections.map((section, index) => {
                            return <div key={`section-key-${index}`} className='mt-4'>
                                <h5>{section.Name}</h5>
                                <ul className={classes.traceCardUlPadding}>
                                    {section?.Descriptions.map((desc, dIndex) => <li key={`desc-section-key-${dIndex}`}>{desc} </li>)}
                                </ul>
                            </div>
                        })}
                    </div>
                </div>

            </div>}
        </>
    )
}

export default AdvancedTrace


