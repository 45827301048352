import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/controlRoom';
import * as API from '../api/controlRoom';
import { sessionErrorHandling } from './session';

function* getJobStatistics() {
    try {
        yield put({ type: actions.JOB_STATISTICS_LIST_PENDING });
        const payload = yield call(API.getJobStatistics);
        yield put({ type: actions.JOB_STATISTICS_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.JOB_STATISTICS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getS3Listners() {
    try {
        yield put({ type: actions.S3_LISTNERS_LIST_PENDING });
        const payload = yield call(API.getS3Listners);
        yield put({ type: actions.S3_LISTNERS_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.S3_LISTNERS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfRedshift() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_REDSHIFT_PENDING });
        const payload = yield call(API.getHealthOfRedshift);
        yield put({ type: actions.GET_HEALTH_REPORT_REDSHIFT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_REDSHIFT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfRDS() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_RDS_PENDING });
        const payload = yield call(API.getHealthOfRDS);
        yield put({ type: actions.GET_HEALTH_REPORT_RDS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_RDS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfECS() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_ECS_PENDING });
        const payload = yield call(API.getHealthOfECS);
        yield put({ type: actions.GET_HEALTH_REPORT_ECS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_ECS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfNetwork() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_NETWORK_PENDING });
        const payload = yield call(API.getHealthOfNetwork);
        yield put({ type: actions.GET_HEALTH_REPORT_NETWORK_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_NETWORK_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfSQS() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_SQS_PENDING });
        const payload = yield call(API.getHealthOfSQS);
        yield put({ type: actions.GET_HEALTH_REPORT_SQS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_SQS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfS3() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_S3_PENDING });
        const payload = yield call(API.getHealthOfS3);
        yield put({ type: actions.GET_HEALTH_REPORT_S3_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_S3_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfCloudwatch() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_CLOUDWATCH_PENDING });
        const payload = yield call(API.getHealthOfCloudwatch);
        yield put({ type: actions.GET_HEALTH_REPORT_CLOUDWATCH_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_CLOUDWATCH_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfCloudfront() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_CLOUDFRONT_PENDING });
        const payload = yield call(API.getHealthOfCloudfront);
        yield put({ type: actions.GET_HEALTH_REPORT_CLOUDFRONT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_CLOUDFRONT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getHealthOfSecretManager() {
    try {
        yield put({ type: actions.GET_HEALTH_REPORT_SECRET_MANAGER_PENDING });
        const payload = yield call(API.getHealthOfSecretManager);
        yield put({ type: actions.GET_HEALTH_REPORT_SECRET_MANAGER_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_HEALTH_REPORT_SECRET_MANAGER_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getQuickSightsDataSetList() {
    try {
        yield put({ type: actions.GET_QUICK_SIGHTS_DATASET_LIST_PENDING });
        const payload = yield call(API.getQuickSightsDataSetList);
        yield put({ type: actions.GET_QUICK_SIGHTS_DATASET_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.GET_QUICK_SIGHTS_DATASET_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.JOB_STATISTICS_LIST, getJobStatistics);
    yield takeEvery(actions.S3_LISTNERS_LIST, getS3Listners);
    yield takeEvery(actions.GET_HEALTH_REPORT_REDSHIFT, getHealthOfRedshift);
    yield takeEvery(actions.GET_HEALTH_REPORT_RDS, getHealthOfRDS);
    yield takeEvery(actions.GET_HEALTH_REPORT_ECS, getHealthOfECS);
    yield takeEvery(actions.GET_HEALTH_REPORT_NETWORK, getHealthOfNetwork);
    yield takeEvery(actions.GET_HEALTH_REPORT_SQS, getHealthOfSQS);
    yield takeEvery(actions.GET_HEALTH_REPORT_S3, getHealthOfS3);
    yield takeEvery(actions.GET_HEALTH_REPORT_CLOUDWATCH, getHealthOfCloudwatch);
    yield takeEvery(actions.GET_HEALTH_REPORT_CLOUDFRONT, getHealthOfCloudfront);
    yield takeEvery(actions.GET_HEALTH_REPORT_SECRET_MANAGER, getHealthOfSecretManager);
    yield takeEvery(actions.GET_QUICK_SIGHTS_DATASET_LIST, getQuickSightsDataSetList);
}