export const PARTNERS_LIST = 'PARTNERS_LIST';
export const PARTNERS_LIST_PENDING = 'PARTNERS_LIST_PENDING';
export const PARTNERS_LIST_FULFILLED = 'PARTNERS_LIST_FULFILLED';
export const PARTNERS_LIST_REJECTED = 'PARTNERS_LIST_REJECTED';

export const PARTNERS_GET = 'PARTNERS_GET';
export const PARTNERS_GET_PENDING = 'PARTNERS_GET_PENDING';
export const PARTNERS_GET_FULFILLED = 'PARTNERS_GET_FULFILLED';
export const PARTNERS_GET_REJECTED = 'PARTNERS_GET_REJECTED';

export const PARTNERS_CREATE = 'PARTNERS_CREATE';
export const PARTNERS_CREATE_PENDING = 'PARTNERS_CREATE_PENDING';
export const PARTNERS_CREATE_FULFILLED = 'PARTNERS_CREATE_FULFILLED';
export const PARTNERS_CREATE_REJECTED = 'PARTNERS_CREATE_REJECTED';

export const PARTNERS_UPDATE = 'PARTNERS_UPDATE';
export const PARTNERS_UPDATE_PENDING = 'PARTNERS_UPDATE_PENDING';
export const PARTNERS_UPDATE_FULFILLED = 'PARTNERS_UPDATE_FULFILLED';
export const PARTNERS_UPDATE_REJECTED = 'PARTNERS_UPDATE_REJECTED';

export const PARTNERS_DELETE = 'PARTNERS_DELETE';
export const PARTNERS_DELETE_PENDING = 'PARTNERS_DELETE_PENDING';
export const PARTNERS_DELETE_FULFILLED = 'PARTNERS_DELETE_FULFILLED';
export const PARTNERS_DELETE_REJECTED = 'PARTNERS_DELETE_REJECTED';

export const PARTNERS_ACTIVATE = 'PARTNERS_ACTIVATE';
export const PARTNERS_ACTIVATE_PENDING = 'PARTNERS_ACTIVATE_PENDING';
export const PARTNERS_ACTIVATE_FULFILLED = 'PARTNERS_ACTIVATE_FULFILLED';
export const PARTNERS_ACTIVATE_REJECTED = 'PARTNERS_ACTIVATE_REJECTED';

export const PARTNERS_DEACTIVATE = 'PARTNERS_DEACTIVATE';
export const PARTNERS_DEACTIVATE_PENDING = 'PARTNERS_DEACTIVATE_PENDING';
export const PARTNERS_DEACTIVATE_FULFILLED = 'PARTNERS_DEACTIVATE_FULFILLED';
export const PARTNERS_DEACTIVATE_REJECTED = 'PARTNERS_DEACTIVATE_REJECTED';

export const PARTNERS_GET_TINY = 'PARTNERS_GET_TINY';
export const PARTNERS_GET_TINY_PENDING = 'PARTNERS_GET_TINY_PENDING';
export const PARTNERS_GET_TINY_FULFILLED = 'PARTNERS_GET_TINY_FULFILLED';
export const PARTNERS_GET_TINY_REJECTED = 'PARTNERS_GET_TINY_REJECTED';

export const PARTNERS_GET_DEPENDENCY = 'PARTNERS_GET_DEPENDENCY';
export const PARTNERS_GET_DEPENDENCY_PENDING = 'PARTNERS_GET_DEPENDENCY_PENDING';
export const PARTNERS_GET_DEPENDENCY_FULFILLED = 'PARTNERS_GET_DEPENDENCY_FULFILLED';
export const PARTNERS_GET_DEPENDENCY_REJECTED = 'PARTNERS_GET_DEPENDENCY_REJECTED';

export const PARTNERS_GET_PRODUCTS = 'PARTNERS_GET_PRODUCTS';
export const PARTNERS_GET_PRODUCTS_PENDING = 'PARTNERS_GET_PRODUCTS_PENDING';
export const PARTNERS_GET_PRODUCTS_FULFILLED = 'PARTNERS_GET_PRODUCTS_FULFILLED';
export const PARTNERS_GET_PRODUCTS_REJECTED = 'PARTNERS_GET_PRODUCTS_REJECTED';

export const PARTNERS_RESET_PRODUCTS = 'PARTNERS_RESET_PRODUCTS';

export const PARTNERS_SET_DEPENDENCY_DIALOG = 'PARTNERS_SET_DEPENDENCY_DIALOG';

export const PARTNERS_ERROR_RESET = 'PARTNERS_ERROR_RESET';
export const PARTNERS_RESET = 'PARTNERS_RESET';

export const list = () => ({
    type: PARTNERS_LIST,
});

export const get = (partnerId, callback) => ({
    type: PARTNERS_GET,
    payload: { partnerId, callback },
});

export const create = (partner, callback) => ({
    type: PARTNERS_CREATE,
    payload: { partner, callback },
});

export const update = (id, partner, callback) => ({
    type: PARTNERS_UPDATE,
    payload: { id, partner, callback },
});

export const deletePartner = (id, callback) => ({
    type: PARTNERS_DELETE,
    payload: { id, callback },
})

export const errorReset = (...errorKeys) => ({
    type: PARTNERS_ERROR_RESET,
    payload: { errorKeys },
});

export const activatePartner = (id, callback) => ({
    type: PARTNERS_ACTIVATE,
    payload: { id, callback },
});

export const deactivatePartner = (id, callback) => ({
    type: PARTNERS_DEACTIVATE,
    payload: { id, callback },
});

export const getPartnersTiny = () => ({
    type: PARTNERS_GET_TINY,
});

export const setDependencyDialog = (partnerDependencyDialog) => ({
    type: PARTNERS_SET_DEPENDENCY_DIALOG,
    payload: { partnerDependencyDialog }
});

export const getPartnerDependency = (partnerId) => ({
    type: PARTNERS_GET_DEPENDENCY,
    payload: { partnerId }
});

export const getPartnersClientsProducts = (partnerId) => ({
    type: PARTNERS_GET_PRODUCTS,
    payload: { partnerId }
});

export const resetPartnerProducts = () => ({
    type: PARTNERS_RESET_PRODUCTS
});