export const CONTRACTS_FAMILY_CREATE = 'CONTRACTS_FAMILY_CREATE';
export const CONTRACTS_FAMILY_CREATE_PENDING = 'CONTRACTS_FAMILY_CREATE_PENDING';
export const CONTRACTS_FAMILY_CREATE_FULFILLED = 'CONTRACTS_FAMILY_CREATE_FULFILLED';
export const CONTRACTS_FAMILY_CREATE_REJECTED = 'CONTRACTS_FAMILY_CREATE_REJECTED';

export const CONTRACTS_FAMILY_GET = 'CONTRACTS_FAMILY_GET';
export const CONTRACTS_FAMILY_GET_PENDING = 'CONTRACTS_FAMILY_GET_PENDING';
export const CONTRACTS_FAMILY_GET_FULFILLED = 'CONTRACTS_FAMILY_GET_FULFILLED';
export const CONTRACTS_FAMILY_GET_REJECTED = 'CONTRACTS_FAMILY_GET_REJECTED';


export const CONTRACT_CHANGE_PRECEDENCE = 'CONTRACT_CHANGE_PRECEDENCE';
export const CONTRACT_CHANGE_PRECEDENCE_PENDING = 'CONTRACT_CHANGE_PRECEDENCE_PENDING';
export const CONTRACT_CHANGE_PRECEDENCE_FULFILLED = 'CONTRACT_CHANGE_PRECEDENCE_FULFILLED';
export const CONTRACT_CHANGE_PRECEDENCE_REJECTED = 'CONTRACT_CHANGE_PRECEDENCE_FULFILLED';

export const ACTIVATE_CONTRACT_FAMILY = 'ACTIVATE_CONTRACT_FAMILY';
export const ACTIVATE_CONTRACT_FAMILY_PENDING = 'ACTIVATE_CONTRACT_FAMILY_PENDING';
export const ACTIVATE_CONTRACT_FAMILY_FULFILLED = 'ACTIVATE_CONTRACT_FAMILY_FULFILLED';
export const ACTIVATE_CONTRACT_FAMILY_REJECTED = 'ACTIVATE_CONTRACT_FAMILY_REJECTED';


export const DEACTIVATE_CONTRACT_FAMILY = 'DEACTIVATE_CONTRACT_FAMILY';
export const DEACTIVATE_CONTRACT_FAMILY_PENDING = 'DEACTIVATE_CONTRACT_FAMILY_PENDING';
export const DEACTIVATE_CONTRACT_FAMILY_FULFILLED = 'DEACTIVATE_CONTRACT_FAMILY_FULFILLED';
export const DEACTIVATE_CONTRACT_FAMILY_REJECTED = 'DEACTIVATE_CONTRACT_FAMILY_REJECTED';

export const CONTRACT_FAMILY_UPDATE = 'CONTRACT_FAMILY_UPDATE';
export const CONTRACT_FAMILY_UPDATE_PENDING = 'CONTRACT_FAMILY_UPDATE_PENDING';
export const CONTRACT_FAMILY_UPDATE_FULFILLED = 'CONTRACT_FAMILY_UPDATE_FULFILLED';
export const CONTRACT_FAMILY_UPDATE_REJECTED = 'CONTRACT_FAMILY_UPDATE_REJECTED';

export const DELETE_CONTRACT_FAMILY = 'DELETE_CONTRACT_FAMILY';
export const DELETE_CONTRACT_FAMILY_PENDING = 'DELETE_CONTRACT_FAMILY_PENDING';
export const DELETE_CONTRACT_FAMILY_FULFILLED = 'DELETE_CONTRACT_FAMILY_FULFILLED';
export const DELETE_CONTRACT_FAMILY_REJECTED = 'DELETE_CONTRACT_FAMILY_REJECTED';



export const changePrecedence = (options, callback) => ({
    type: CONTRACT_CHANGE_PRECEDENCE,
    payload: { options, callback }
});



export const createContractFamily = (contractFamily, callback) => (  {
    type: CONTRACTS_FAMILY_CREATE,
    payload: { contractFamily, callback },
});

export const get = (familyId) => ({
    type: CONTRACTS_FAMILY_GET,
    payload: { familyId },
});

export const activateContractFamily = (id,callback) => ({
    type: ACTIVATE_CONTRACT_FAMILY,
    payload: { id,callback },
});


export const deactivateContractFamily = (id,callback) => ({
    type: DEACTIVATE_CONTRACT_FAMILY,
    payload: { id,callback },
});


export const update = (data, callback) => ({
    type: CONTRACT_FAMILY_UPDATE,
    payload: { data, callback },
});

export const deleteContractFamily = (familyId, callback) => ({
    type: DELETE_CONTRACT_FAMILY,
    payload: { familyId, callback }
});