import React, { useEffect, useState } from 'react';
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";
import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { PagedTable } from '../../../common/pagedTable';
import Helmet from 'react-helmet';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import { EnableDialog } from './EnableDialog';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

const DEFAULT_SORT = [{ id: 'percentage', direction: 'desc' }];

const useStyles = makeStyles(theme => ({
    // root: {
    //     display: 'flex',
    //     overflowX: 'auto',
    //     flexDirection: 'column',
    // },
    container: {
        flexGrow: 1,
    },
    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function AssociatedPage(props) {
    const { selectAllStatusPending, updateStatusPending, data, fields, refreshChargesPending,
        actions: {
            associatedLanding,
            searchGetByName,
            searchReset,
            resetPagedTable,
            setEnableDialog,
            refreshAssociatedCharges
        }
    } = props;

    useEffect(() => {
        const pageName = {
            name: 'associated-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name)
        }
    }, [searchGetByName]);

    const [refresh, setRefresh] = useState(Promise.resolve());
    const classes = useStyles();

    function handleLoad(sort, filter, pagination) {
        associatedLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
        setEnableDialog(null);
    }

    function handlePayerConfirmation(isEnable) {
        setEnableDialog({ open: true, isEnableClicked: isEnable })
    }

    function associatedChargesCallback() {
        handleRefresh();
    }

    function handleRefreshAssociatedCharges() {
        refreshAssociatedCharges({}, associatedChargesCallback);
    }

    function tableLevelActions() {
        return (
            <>
                <Grid item>
                    <Tooltip title="Refresh Associated Charges">
                        <IconButton aria-label="enable" size='small' onClick={handleRefreshAssociatedCharges}>
                            <PlayCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Enable">
                        <IconButton aria-label="enable" size='small' onClick={() => handlePayerConfirmation(true)}>
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Disable">
                        <IconButton aria-label="disable" size='small' onClick={() => handlePayerConfirmation(false)}>
                            <i className={classNames("fa fa-solid fa-ban", classes.icon)}></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Helmet title="Associated Charges" />
            <Portlet className={classes.root}>
                <PortletHeader title="Associated Charges"
                    name="associated-landing"
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    handleRefresh={handleRefresh}
                />
                <PortletBody>
                    <div className={classes.container}>
                        <Grid container>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="associated-landing"
                                    fields={fields}
                                    data={data}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    tableLevelActions={tableLevelActions}
                                    hasCheckbox={true}
                                    showSelectAll={true}
                                    timeFilterField={true}
                                    loading={updateStatusPending || selectAllStatusPending || refreshChargesPending}
                                    criteriasSaveEnabled={true}
                                    showSearchFilters={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
            <EnableDialog handleClose={handleRefresh} />
        </>
    );
}