import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import ModifiersComponent from './ModifiersPage';

import { createStringDataField } from '../../util/format';
import { StringEquators } from '../../store/constants/pages';
import { getByName } from '../../store/actions/search.js';
import { set } from '../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../util/selector';
import { deleteModifier, modifierReset, modifiersLanding } from '../../store/actions/modifiers';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'desc' }];


const selectFields = createSelector(
    () => [

        createStringDataField('modifier', 'Modifier', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('modfierType', 'Modfier Type', { sortable: true, equators: StringEquators }),
        createStringDataField('description', 'Description', { sortable: true, equators: StringEquators }),
    ]
);

export const selectModifiersList = (state) => state.modifiers.data;

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`modifiers-landing`] ? state.pages[`modifiers-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`modifiers-landing`] ? state.pages[`modifiers-landing`].filter : null,
    selectSort,
    selectModifiersList,
);

export const ModifiersPage = connect(
    () => {
        return (state, props) => ({
            order: state.pages['modifiers-landing'] ? state.pages['modifiers-landing'].order : null,
            filter: state.pages['modifiers-landing'] ? state.pages['modifiers-landing'].filter : null,
            sort: selectSort(state, props),

            modifierFields: selectFields(state, props),
            ModifiersData: selectFilteredData(state, props),

            getModifiersPending: state.modifiers.list.pending,

            deleteModifiersPending: state.modifiers.delete.pending,
            deleteModifiersError: state.modifiers.delete.error,
            deleteModifiersErrorMessage: state.modifiers.delete.errorMessage,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            searchGetByName: getByName,
            modifiersLanding: modifiersLanding,
            setFilter: (value) => set(`modifiers-landing`, 'filter', value),
            setOrder: (value) => set(`modifiers-landing`, 'order', value),
            setSort: (value) => set(`modifiers-landing`, 'sort', value),
            deleteModifier: deleteModifier,
            modifierReset: modifierReset
        }, dispatch)
    })
)(injectIntl(ModifiersComponent));