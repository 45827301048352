import { createReducer } from '../../util';
import * as actions from '../actions/search';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    types: [],
    list: {
        ...initialStatusState,
    },
    getByName: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getCriteriaTypes: {
        ...initialStatusState,
    },
};

export default createReducer(initialState, {
    [actions.SEARCH_CRITERIAS_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.SEARCH_CRITERIAS_LIST_FULFILLED]: (state, list) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: list,
    }),
    [actions.SEARCH_CRITERIAS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SEARCH_CRITERIAS_GET_BY_NAME_PENDING]: (state) => ({
        ...state,
        data: [],
        getByName: {
            pending: true,
        },
    }),
    [actions.SEARCH_CRITERIAS_GET_BY_NAME_FULFILLED]: (state, criterias) => ({
        ...state,
        getByName: {
            ...initialStatusState,
        },
        data: criterias,
    }),
    [actions.SEARCH_CRITERIAS_GET_BY_NAME_REJECTED]: (state, errorMessage) => ({
        ...state,
        getByName: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SEARCH_CRITERIAS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.SEARCH_CRITERIAS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.SEARCH_CRITERIAS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SEARCH_CRITERIAS_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.SEARCH_CRITERIAS_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        }
    }),
    [actions.SEARCH_CRITERIAS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SEARCH_CRITERIAS_RESET_DELETE_STATE]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        }
    }),
    [actions.SEARCH_CRITERIAS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.SEARCH_CRITERIAS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.SEARCH_CRITERIAS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SEARCH_CRITERIAS_RESET]: (state) => ({
        ...state,
        data: [],
    }),
    [actions.SEARCH_CRITERIA_TYPES_PENDING]: (state) => ({
        ...state,
        getCriteriaTypes: {
            pending: true,
        },
    }),
    [actions.SEARCH_CRITERIA_TYPES_FULFILLED]: (state, types) => ({
        ...state,
        getCriteriaTypes: {
            ...initialStatusState,
        },
        types,
    }),
    [actions.SEARCH_CRITERIA_TYPES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCriteriaTypes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});
