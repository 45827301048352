import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { rulesFromCodeServiceLanding, set } from '../../../../store/actions/pages';
import { createStringDataField } from '../../../../util/format';
import RulesDialogComponent from './RulesDialog';
import { StringEquators } from '../../../../store/constants/pages';
import { setRulesDialog } from '../../../../store/actions/services';

const selectFields = createSelector(
    (state) => [
        createStringDataField('name', 'Name', { sortable: true, equators: StringEquators, onClick: true, }),
        createStringDataField('description', 'Description', { sortable: true, hideFromFilter: true }),
        createStringDataField('contractName', 'Contract Name', { sortable: true, hideFromFilter: true }),
    ]
);

const selectCodeServiceDescriptionData = createSelector(
    (state) => state.pages['code-service-rules-landing'].list,
    (data) => data
);

const selectCodeServiceDescriptionTotalCount = createSelector(
    (state) => state.pages['code-service-rules-landing'].page?.totalRecords,
    (data) => data
);

const selectOpen = createSelector(
    state => state.services.rulesDialog,
    (rulesDialog) => {
        return rulesDialog?.open || false;
    }
);

const selectServiceId = createSelector(
    state => state.services.rulesDialog,
    (rulesDialog) => {
        if (rulesDialog && rulesDialog?.serviceId) {
            return rulesDialog?.serviceId
        }
        return null;
    }
);

const selectServiceName = createSelector(
    state => state.services.rulesDialog,
    (rulesDialog) => {
        return rulesDialog?.serviceName ?? ''
    }
);



export const RulesDialog = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            descriptionData: selectCodeServiceDescriptionData(state),
            serviceId: selectServiceId(state, props),
            open: selectOpen(state, props),
            totalCount: selectCodeServiceDescriptionTotalCount(state, props),
            serviceName: selectServiceName(state)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            rulesFromCodeServiceLanding: rulesFromCodeServiceLanding,
            setFilter: (value) => set(`code-service-rules-landing`, 'filter', value),
            setRulesDialog: setRulesDialog
        }, dispatch)
    })
)(injectIntl(RulesDialogComponent));