import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getDrgList = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/opps/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getOPPS = (oppsId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/opps/${oppsId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createOPPS = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/opps`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const editOPPS = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/opps`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteOPPS = (drgId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/opps/${drgId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getOPPSListTiny = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/opps/list/tiny`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getOPPSListTinyByContract = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/opps/list/tiny/contract/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getOPPSListByTargetClient = (targetClient) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/opps/list/tiny/client/${targetClient}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getOPPSDependency = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/opps/dependencies/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getDrgList,
    createOPPS,
    editOPPS,
    deleteOPPS,
    getOPPS,
    getOPPSListTiny,
    getOPPSListTinyByContract,
    getOPPSListByTargetClient,
    getOPPSDependency
};