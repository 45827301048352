import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ServiceAdjustmentsComponent from './ServiceAdjustments';
import { selectClaimServiceAdjustments, selectClaimAdjustmentsData } from '../../../../store/selectors/claimAnalysis';
import { createCurrencyDataField, createTooltipIconField, createNumberDataField, createStringDataField } from '../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import { set } from '../../../../store/actions/pages';
import { getClaimServiceAdjustments, setClaimServiceAdjustments } from '../../../../store/actions/claimAnalysis';

const DEFAULT_SORT = [{ id: 'groupCode', orderBy: 'asc' }];

const selectFields = createSelector(
    (state) => [
        createStringDataField('groupCode', 'Group Code', { sortable: true, onHover: true }),
        createStringDataField('reasonCode', 'Reason Code', { sortable: true }),
        createStringDataField('reasonShortDescription', 'Reason Description', { sortable: true, onHover: true, titleSubstring: true }),
        createCurrencyDataField('adjustmentAmount', 'Amount', { sortable: true }),
        createNumberDataField('adjustmentQuantity', 'Quantity', { sortable: true }),
        createTooltipIconField('isActionable', 'Actionable', { iconClass: 'fas fa-check-circle', sortable: true, returnWholeRow: true, checkedTooltip: 'Actionable', uncheckedTooltip: 'Unactionable' }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectClaimServiceAdjustmentOpen = createSelector(
    selectClaimServiceAdjustments,
    (adjustment) => {
        return adjustment?.open || false;
    }
);

const selectClaimAdjustmentId = createSelector(
    selectClaimServiceAdjustments,
    (adjustment) => {
        return adjustment?.id || null;
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`claim-service-adjustments-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectServiceAdjustmentsData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`claim-service-adjustments-landing`].filter,
    selectSort,
    selectClaimAdjustmentsData
);

export const ServiceAdjustments = connect(
    () => {
        return (state, props) => ({
            open: selectClaimServiceAdjustmentOpen(state, props),
            paymentId: selectClaimAdjustmentId(state, props),
            fields: selectFields(state),
            filteredData: selectServiceAdjustmentsData(state),
            order: state.pages[`claim-service-adjustments-landing`].order,
            filter: state.pages[`claim-service-adjustments-landing`].filter,
            sort: selectSort(state, props),
            getAdjusmentsPending: state.claimAnalysis.getClaimServiceAdjustments.pending
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setFilter: (value) => set(`claim-service-adjustments-landing`, 'filter', value),
            setOrder: (value) => set(`claim-service-adjustments-landing`, 'order', value),
            setSort: (value) => set(`claim-service-adjustments-landing`, 'sort', value),
            setClaimServiceAdjustments: setClaimServiceAdjustments,
            getClaimServiceAdjustments: getClaimServiceAdjustments
        }, dispatch)
    })
)(injectIntl(ServiceAdjustmentsComponent));