import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import ContentEditable from "react-contenteditable";
const useStyles = makeStyles(theme => ({
    wordBreak: {
        wordBreak: 'break-all'
    },
    contentEditable: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        padding: '10px 10px',
        outline: 'none',
        '&:focus': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: '1px solid black',
        },
        '&:focus-visible': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: '1px solid black',
        },
        '&:hover': {
            backgroundColor: '#EBECF0'
        }
    },
    dialogContent: {
        margin: '0px 10px 0px 10px',
        padding: '10px 0',
    }
}));

function CodesEditDialog({ open, codes, handleClose, handleCodesChange, label } : {
    open: boolean,
    codes: string,
    handleClose: () => void,
    handleCodesChange: (codes: string) => void,
    label?: string
}) {
    const [isContentEdited, setContentEdited] = useState(false);
    const [text, setText] = useState('');
    const classes = useStyles();

    const handleBoxClick = () => {
        setContentEdited(true);
    }

    const handleContentEdit = (e) => {
        setText(e.currentTarget.textContent);
    }

    useEffect(() => {
        if (open) {
            setText(codes);
        }
    }, [codes, open]);

    const handleSaveText = () => {
        handleCloseDialog();
        handleCodesChange(text);
    }

    const handleCloseDialog = () => {
        handleClose();
        setText('');
        setContentEdited(false);
    }

    return (
        <div>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="md"
                open={open} onClose={handleCloseDialog}>
                <DialogTitle>
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            {label ?? 'Codes'}
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={handleCloseDialog}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    {/* <div
                        onClick={handleBoxClick}
                        className={classes.contentEditable}
                        contentEditable='true'
                        onInput={handleContentEdit}
                    >
                        {codes}
                    </div> */}
                    <ContentEditable
                        onClick={handleBoxClick}
                        className={classes.contentEditable}
                        html={text}
                        disabled={false}
                        onChange={handleContentEdit}
                    />
                </DialogContent>
                {isContentEdited &&
                    <DialogActions className='p-4'>
                        <Button
                            variant="contained"
                            onClick={handleCloseDialog}
                            color="default"
                            type='button'>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSaveText}
                            variant="contained"
                            color='primary'
                            type='button'
                        >
                            Save
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </div>
    )
}

export default CodesEditDialog