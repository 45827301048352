import { createReducer } from '../../util';
import * as actions from '../actions/dashboardFilters';
import { StatusState } from './common';

const addObjectIfNotExist = (state, dashboardFilter) => {
    const data = state.data;
    const { id } = dashboardFilter;

    const dashboardFilterExist = data.find(x => x.id === id);
    if (!dashboardFilterExist) {
        return {
            data: [...state.data, dashboardFilter]
        }
    }
};

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    tinyList: [],
    list: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    tiny: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {

    [actions.DASHBOARD_FILTERS_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_FILTERS_LIST_FULFILLED]: (state, dashboards) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: dashboards,
    }),
    [actions.DASHBOARD_FILTERS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_FILTERS_LIST_TINY_PENDING]: (state) => ({
        ...state,
        tiny: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_FILTERS_LIST_TINY_FULFILLED]: (state, filters) => ({
        ...state,
        tiny: {
            ...initialStatusState,
        },
        tinyList: [
            ...filters,
        ]
    }),
    [actions.DASHBOARD_FILTERS_LIST_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        tiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_FILTERS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_FILTERS_GET_FULFILLED]: (state, dashboard) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, dashboard),
    }),
    [actions.DASHBOARD_FILTERS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_FILTERS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_FILTERS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.DASHBOARD_FILTERS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_FILTERS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_FILTERS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.DASHBOARD_FILTERS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_FILTERS_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_FILTERS_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.DASHBOARD_FILTERS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),    
    [actions.DASHBOARD_FILTERS_RESET]: () => ({ ...initialState }),
    [actions.DASHBOARD_FILTERS_ERROR_RESET]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        }
    }),
});