
export const MODIFIERS_LANDING = 'MODIFIERS_LANDING';
export const MODIFIERS_LANDING_PENDING = 'MODIFIERS_LANDING_PENDING';
export const MODIFIERS_LANDING_FULFILLED = 'MODIFIERS_LANDING_FULFILLED';
export const MODIFIERS_LANDING_REJECTED = 'MODIFIERS_LANDING_REJECTED';

export const MODIFIERS_CREATE = 'MODIFIERS_CREATE';
export const MODIFIERS_CREATE_PENDING = 'MODIFIERS_CREATE_PENDING';
export const MODIFIERS_CREATE_FULFILLED = 'MODIFIERS_CREATE_FULFILLED';
export const MODIFIERS_CREATE_REJECTED = 'MODIFIERS_CREATE_REJECTED';

export const MODIFIERS_UPDATE = 'MODIFIERS_UPDATE';
export const MODIFIERS_UPDATE_PENDING = 'MODIFIERS_UPDATE_PENDING';
export const MODIFIERS_UPDATE_FULFILLED = 'MODIFIERS_UPDATE_FULFILLED';
export const MODIFIERS_UPDATE_REJECTED = 'MODIFIERS_UPDATE_REJECTED';

export const MODIFIERS_DELETE = 'MODIFIERS_DELETE';
export const MODIFIERS_DELETE_PENDING = 'MODIFIERS_DELETE_PENDING';
export const MODIFIERS_DELETE_FULFILLED = 'MODIFIERS_DELETE_FULFILLED';
export const MODIFIERS_DELETE_REJECTED = 'MODIFIERS_DELETE_REJECTED';

export const MODIFIERS_PAYMETHODS_GET = 'MODIFIERS_PAYMETHODS_GET';
export const MODIFIERS_PAYMETHODS_GET_PENDING = 'MODIFIERS_PAYMETHODS_GET_PENDING';
export const MODIFIERS_PAYMETHODS_GET_FULFILLED = 'MODIFIERS_PAYMETHODS_GET_FULFILLED';
export const MODIFIERS_PAYMETHODS_GET_REJECTED = 'MODIFIERS_PAYMETHODS_GET_REJECTED';

export const MODIFIERS_CREATE_UPDATE_RESET = 'MODIFIERS_CREATE_UPDATE_RESET';
export const MODIFIERS_RESET = 'MODIFIERS_RESET';

export const modifiersLanding = () => ({
    type: MODIFIERS_LANDING
});

export const createModifier = (values, callback, shouldReloadGrid) => ({
    type: MODIFIERS_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const updateModifier = (values, callback, shouldReloadGrid) => ({
    type: MODIFIERS_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const deleteModifier = (modifierId, callback) => ({
    type: MODIFIERS_DELETE,
    payload: { modifierId, callback }
});

export const createEditReset = () => ({
    type: MODIFIERS_CREATE_UPDATE_RESET
});

export const modifierReset = () => ({
    type: MODIFIERS_RESET
});

export const getModifiersPayMethods = () => ({
    type: MODIFIERS_PAYMETHODS_GET
})