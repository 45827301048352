import React from 'react';
import { FormControl } from "react-bootstrap";

export function DataFilter({ onChange, placeholder, value, cssClass, filter } : {
    onChange: any,
    placeholder: string,
    value?: string,
    cssClass?: string,
    filter?: string
}) {
    const handleChange = (event) => {
        const value = event.target.value;
        if (onChange) {
            onChange(value || '');
        }
    }

    return (
        <>        
            <FormControl
                placeholder={placeholder}
                value={value || filter}
                onChange={handleChange}
                autoComplete="off"
                className={cssClass}
            />
        </>
    )
}