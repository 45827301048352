import React, { useEffect } from 'react';
import {
    Dialog, DialogContent, DialogTitle, makeStyles, Grid,
    IconButton,
    Typography,
    TextField,
    Button,
    TextareaAutosize,
    FormLabel
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { CustomLabel, Loadable } from '../../../common';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    textArea: {
        width: '100%',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        outline: 'none',
        '&:focus': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
        },
        '&:focus-visible': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
        }
    }
}));

export default function NPIRoleEditDialog(props) {
    const { open, handleClose, initialValues, intl, mode,
        createNPIRolePending, updateNPIRolePending,
        actions: { createNPIRole, updateNPIRole, createEditReset }
    } = props;

    const classes = useStyles();

    useEffect(() => {
        if (open) {
        }
        return function cleanup() {
            createEditReset();
        };
    }, [open]);


    return (
        <Dialog fullWidth open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">{mode === 'edit' ? 'Edit' : 'Add'} NPI Role </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={createNPIRolePending || updateNPIRolePending}>
                    <Grid container>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validate={values => {
                                const errors = {};

                                if (!values.name) {
                                    errors.name = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!values.npis) {
                                    errors.npis = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                let shouldReloadGrid = true
                                let payload = {
                                    name: values.name,
                                    description: values.description,
                                    npis: values.npis
                                }
                                if (mode === 'create') {
                                    createNPIRole(payload, handleClose, shouldReloadGrid);
                                }
                                else {
                                    payload = {
                                        ...payload,
                                        id: values.id,
                                    }
                                    updateNPIRole(payload, handleClose, shouldReloadGrid);
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                    <div className={classes.root}>
                                        <Grid container>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    required
                                                    label="Name"
                                                    name="name"
                                                    className={classes.textService}
                                                    margin="normal"
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    multiline
                                                    rows="3"
                                                    label="Description"
                                                    name="description"
                                                    className={classes.textService}
                                                    margin="normal"
                                                    value={values.description}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.description && errors.description)}
                                                    helperText={touched.description && errors.description}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <FormLabel className="mt-4" id="npi-textarea">NPIs*</FormLabel>
                                                <TextareaAutosize
                                                    multiline
                                                    rows="3"
                                                    required
                                                    // label="NPIs"
                                                    name="npis"
                                                    className={classes.textArea}
                                                    margin="normal"
                                                    value={values.npis}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.npis && errors.npis)}
                                                // helperText="e.g. 123454431,123232114,234343454"
                                                // placeholder='NPIs*'
                                                />
                                                <p className='MuiFormHelperText-root Mui-disabled'>e.g. 1234544311,1232321142,2343434543</p>
                                            </Grid>
                                        </Grid>
                                        <hr />
                                        <Grid container justify="flex-end">
                                            <Grid item xs={2}>
                                                <Button
                                                    onClick={() => handleClose(false)}
                                                    variant="contained"
                                                    color="default"
                                                    className={classes.button}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    type="submit"
                                                >
                                                    {(createNPIRolePending || updateNPIRolePending) ? 'Saving Changes' : 'Save'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}