import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import {
    createStringDataField, createCurrencyDataField, createNumberDataField
} from '../../../util/format';
import EstimateHistoryDetailsComponent from './EstimateHistoryDetailsPage';
import { set } from '../../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import {getEstimateById } from '../../../store/actions/claimAnalysis';
import { list } from '../../../store/actions/facilities';
import {getBrandingDetails} from '../../../store/actions/clients';


const selectFacilitiesData = (state) => state.facilities.data;


const selectActiveFacilities = createSelector(
    selectFacilitiesData,
    (facilityData)=> facilityData?.filter((item)=>item.active)
);

export const EstimateHistoryDetails = connect(
    () => {

        const DEFAULT_SORT = [{ id: 'ruleName', orderBy: 'asc' }];
        const selectEstimatorHistoryDetailsDialog = (state, props) => state.claimAnalysis.estimatorHistoryDetailsDialog;

        const selectEstimatorId = (state, props) => props.match.params.estimatorId;
        const selectOutPocket = (state, props) => props.match.params?.op;


        const selectEstimatorHistoryDetailsResult =(state, props) => state.claimAnalysis.estimateById

        const selectEstimatorHistoryDetailsOpen = createSelector(
            selectEstimatorHistoryDetailsDialog,
            (estimatorHistoryDetailsDialog) => {
                if (estimatorHistoryDetailsDialog && estimatorHistoryDetailsDialog?.open) {
                    return true;
                }
                return false;
            }
        );

        const selectChargeEstimationHistory = createSelector(
            selectEstimatorHistoryDetailsResult,
            (result) => {

                
                if (result ) {
                    return result?.chargeEstimations.map(item => ({ ...item, perUnitChargeAmount: parseInt(item.chargeAmount) / parseInt(item.units) }))
                }
                return [];
            }
        );

        const selectFields = createSelector(
            () => [
                createStringDataField('rule.name', 'Rule', { sortable: true,secondLevel: 'name' }),
                createStringDataField('revCode', 'Revenue Code', { sortable: true, onHover: true }),
                createStringDataField('pxCode', 'Procedure Code', { sortable: true, onHover: true }),
                createNumberDataField('units', 'Units', { sortable: true, }),
                createCurrencyDataField('chargeAmount', 'Per Unit Charge Amount', { sortable: true, calculateTotal: true }),
                createCurrencyDataField('chargeAmount', 'Total Charge Amount', { sortable: true, calculateTotal: true }),
                createCurrencyDataField('estimatedPayment', 'Allowable', { sortable: true, calculateTotal: true }),
            ]
        );

        const selectFilteredFields = createSelector(
            selectFields,
            (fields) => {
                return fields.map(field => field.id);
            }
        );

        const selectSort = createSelector(
            (state) => state.pages['estimator-history-details-landing'].sort,
            (sort) => sort || DEFAULT_SORT,
        );

        const selectFilteredData = createFilteredSortedDataSelector(
            selectFilteredFields,
            (state) => state.pages['estimator-history-details-landing'].filter,
            selectSort,
            selectChargeEstimationHistory,
        );

        const selectAdvancedTraceSections = createSelector(
            selectEstimatorHistoryDetailsResult,
            (result) => {
                if (result && result.advanceTrace && JSON.parse(result?.advanceTrace)) {
                    return JSON.parse(result?.advanceTrace)?.Sections ?? [];
                }
                return [];
            },
        );

        return (state, props) => ({
            result: selectEstimatorHistoryDetailsResult(state, props),
            open: selectEstimatorHistoryDetailsOpen(state, props),
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            sort: selectSort(state),
            order: state.pages['estimator-history-details-landing'].order,
            filter: state.pages['estimator-history-details-landing'].filter,
            sections: selectAdvancedTraceSections(state, props),
            data:state.claimAnalysis.estimateById,
            getEstimatePending: state.claimAnalysis.getEstimateById.pending,
            estimatorId:selectEstimatorId(state,props),
            outPocket:selectOutPocket(state,props),
            getFacilitiesPending:state.facilities.list.pending,
            facilitiesData:selectActiveFacilities(state,props),
            getBrandingDetailsPending:state.clients.getBrandingDetails.pending,
            brandingDetails:state.clients.brandingDetails

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setFilter: (value) => set('estimator-history-details-landing', 'filter', value),
            setOrder: (value) => set('estimator-history-details-landing', 'order', value),
            setSort: (value) => set('estimator-history-details-landing', 'sort', value),
            getEstimateById: getEstimateById,
            getFacilities:list,
            getBrandingDetails:getBrandingDetails

        }, dispatch)
    })
)(injectIntl(EstimateHistoryDetailsComponent));