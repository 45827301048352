import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ChangeContractComponentPage from './ChangeContractComponent';
import { selectTiny } from '../../../../store/selectors/contracts';
import { getClaimAllDetails, overrideContract,removeOverriddenContract } from '../../../../store/actions/claimAnalysis';
import { createSelector } from 'reselect';
import { CONTRACT_STATE_DRAFT, CONTRACT_STATE_FINALIZED } from '../../../../store/constants/contract';
import { selectSessionHasClaimProcessorAccess } from '../../../../store/selectors/session';

export const ChangeContractComponent = connect(
    () => {

        const selectDetailsFromComments = (state) => state.claimAnalysis.claimAllDetails;
        const selectContract = createSelector(selectDetailsFromComments,
            (details) => {
                if (details) {
                    return details?.contract ?? ''
                }
                return ''
            }
        );

        const selectFilteredContracts = createSelector(selectTiny,
            (contracts) => {
                return contracts.filter(item => item.state === CONTRACT_STATE_DRAFT || item.state === CONTRACT_STATE_FINALIZED).sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        return (state, props) => ({
            currentContract: selectContract(state),
            tinyContracts: selectFilteredContracts(state),
            overrideContractPending: state.claimAnalysis.overrideContract.pending,
            deleteOverrideContractPending: state.claimAnalysis.removeOverriddenContract.pending,

            hasClaimProcessorAccess: selectSessionHasClaimProcessorAccess(state),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            overrideContract: overrideContract,
            getClaimAllDetails: getClaimAllDetails,
            removeOverriddenContract:removeOverriddenContract
        }, dispatch)
    })
)(injectIntl(ChangeContractComponentPage));