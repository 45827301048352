import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/chargeMaster';
import * as API from '../api/chargeMaster';
import { sessionErrorHandling } from './session';

function* upload(action) {
    try {
        const { file, callback } = action.payload;
        yield put({ type: actions.CHARGE_MASTER_UPLOAD_PENDING });
        const payload = yield call(API.uploadChargeMaster, file);
        yield put({ type: actions.CHARGE_MASTER_UPLOAD_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { errorCallback } = action.payload;
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CHARGE_MASTER_UPLOAD_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
        if (errorCallback) {
            errorCallback();
        }
    }
}

function* list() {
    try {
        yield put({ type: actions.CHARGE_MASTER_LIST_PENDING });
        const payload = yield call(API.getCharges);
        yield put({ type: actions.CHARGE_MASTER_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CHARGE_MASTER_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.CHARGE_MASTER_CREATE_PENDING });
        const {
            charge,
            callback
        } = action.payload;

        const payload = {
            ...charge,
        }
        const newCharge = yield call(API.createChargeMaster, payload);
        yield put({ type: actions.CHARGE_MASTER_CREATE_FULFILLED, newCharge });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CHARGE_MASTER_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.CHARGE_MASTER_UPDATE_PENDING });
        const {
            charge,
            callback
        } = action.payload;

        const updatedCharge = yield call(API.updateChargeMaster, charge);
        yield put({ type: actions.CHARGE_MASTER_UPDATE_FULFILLED, updatedCharge });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CHARGE_MASTER_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* download(action) {
    try {
        const { callback } = action.payload;
        yield put({ type: actions.CHARGE_MASTER_DOWNLOAD_TEMPLATE_PENDING });
        const payload = yield call(API.downloadTemplate);
        yield put({ type: actions.CHARGE_MASTER_DOWNLOAD_TEMPLATE_FULFILLED });

        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CHARGE_MASTER_DOWNLOAD_TEMPLATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

// export function *reset() {
//     yield put({type: actions.ROLES_RESET});
// }

export default function* () {
    yield takeEvery(actions.CHARGE_MASTER_UPLOAD, upload);
    yield takeEvery(actions.CHARGE_MASTER_LIST, list);
    yield takeEvery(actions.CHARGE_MASTER_CREATE, create);
    yield takeEvery(actions.CHARGE_MASTER_UPDATE, update);
    yield takeEvery(actions.CHARGE_MASTER_DOWNLOAD_TEMPLATE, download);
}