import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getFeeSchedules = (directoryId) => {
    let api = `${SERVER_URL}/api/v2/cms/feeschedule/list`;
    if (directoryId !== 'root') {
        api = `${SERVER_URL}/api/v2/cms/feeschedule/list/${directoryId}`
    }

    return fetch(api, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getHeirarchy = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/directory/hierarchy`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createDirectory = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/directory`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updateDirectory = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/directory`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const moveDirectoryOrFeeSchedule = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feescheduleanddirectory/move`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getFeeScheduleDirectoryDependencies = (directoryId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/directory/dependencies/${directoryId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const deleteFeeScheduleDirectory = (directoryId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/directory/${directoryId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const searchFeeSchedules = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/search`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};
const searchFeeSchedulesFromClient = (options, targetClient) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/search/${targetClient}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getFeeSchedulesLabelsFromTargetClient = (targetClient,feeScheduleId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/labels/${targetClient}/${feeScheduleId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};
const validateFeeSchedulesLabelsFromTargetClient = (targetClient,feeScheduleId,labelId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/rate/validate/${targetClient}/${feeScheduleId}/${labelId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getFeeSchedules,
    createDirectory,
    updateDirectory,
    moveDirectoryOrFeeSchedule,
    getHeirarchy,
    getFeeScheduleDirectoryDependencies,
    deleteFeeScheduleDirectory,
    searchFeeSchedules,
    searchFeeSchedulesFromClient,
    getFeeSchedulesLabelsFromTargetClient,
    validateFeeSchedulesLabelsFromTargetClient
};