import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import APIKeyShowComp from './APIKeyShowComponent';
import { getAPIKeyValue, resetActualKey } from '../../../../store/actions/apikey';
import { getAppIdValue, resetActualAppIdKey } from '../../../../store/actions/appId';

import { selectActualAPIKeyObject } from '../../../../store/selectors/apikey';

import { selectActualAPPIdKeyObject } from '../../../../store/selectors/appId';





const selectAPIKey = createSelector(
    (state, props) =>selectActualAPIKeyObject(state), 
    // selectActualAPIKeyObject,
    (state, props) => props.value,
    (actualKey, parentObj) => {
        if (actualKey && parentObj && actualKey.id === parentObj.id) {
            return actualKey.key;
        }
        return false;
    }
);


const selectAPPKey = createSelector(
    (state, props) =>selectActualAPPIdKeyObject(state), 
    // selectActualAPIKeyObject,
    (state, props) => props.value,
    (actualKey, parentObj) => {
        if (actualKey && parentObj && actualKey.id === parentObj.id) {
            return actualKey.key;
        }
        return false;
    }
);

export const APIKeyShowComponent = injectIntl(
    connect(
        (state, props : { isAppId : any }) => ({
            actualKey: selectAPIKey(state, props),
            actualAppKey: selectAPPKey(state, props),
            isAppId:props?.isAppId

        }),
        (dispatch, props) => ({
            actions: bindActionCreators({
                getAPIKeyValue:getAPIKeyValue,
                resetActualKey:resetActualKey,
                getAppIdValue:getAppIdValue,
                resetActualAppIdKey:resetActualAppIdKey,
            }, dispatch)
        })
    )(APIKeyShowComp));