import { Link, makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react'
import { useState } from 'react';
import { Helmet } from 'react-helmet'
import { Portlet, PortletBody, PortletHeader } from '../../../partials/content/Portlet'

import { StepFour } from './stepFour';
import { StepOne } from './stepOne';
import { StepThree } from './stepThree';
import { StepTwo } from './stepTwo';
import { PublishMRF } from './PublishMRF';

const useStyles = makeStyles(theme => ({
    link: {
        fontSize: '13px',
        marginLeft: 10
    }
}));

function PricerSetup() {

    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0);
    const [shouldCallNegotiatedAPI, setShouldCallNegotiatedAPI] = useState(true);

    const steps = getSteps();


    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep===2 ? prevActiveStep+2: prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep =>prevActiveStep===4 ? prevActiveStep-2: prevActiveStep - 1);
    }

    function handleCallNegotiatedAPI(bool) {
        setShouldCallNegotiatedAPI(bool);
    }

    function handleRedirectToNegotiatedRates() {
        setActiveStep(4);
    }

    function handleRedirectToShoppables() {
        setActiveStep(1);
    }

    function getSteps() {
        let steps = [
            {
                label: 'Charge Master',
                button: 'Next'
            },
            {
                label: 'Shoppables',
                button: 'Next'
            },
            {
                label: 'Payer Contract',
                button: 'Next'
            },
            {
                label: 'Negotiated Rates',
                button: 'Next'
            }
        ];

        return steps;
    }

    function step1() {
        return (
            <StepOne
                activeStep={activeStep}
                handleNext={handleNext}
            />
        )
    }

    function step2() {
        return (
            <StepTwo
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                handleRedirectToNegotiatedRates={handleRedirectToNegotiatedRates}
            />
        );
    }

    function step3() {
        return (
            <StepThree
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                handleCallNegotiatedAPI={handleCallNegotiatedAPI}

            />
        )
    }

    function step4() {
        return (
            <StepFour
                activeStep={activeStep}
                shouldCallNegotiatedAPI={shouldCallNegotiatedAPI}
                handleBack={handleBack}
                handleCallNegotiatedAPI={handleCallNegotiatedAPI}
                handleRedirectToShoppables={handleRedirectToShoppables}
            />
        )
    }

    function getStepContent(stepIndex) {
        switch (true) {
            case stepIndex === 0:
                return step1();
            case stepIndex === 1:
                return step2();
            case stepIndex === 2:
                return step3();
            case stepIndex === 4:
                return step4();
            default:
                return 'Unknown stepIndex';
        }
    }

    function renderLink() {
        return <Link
            className={classes.link}
            component="button"
            variant="body2"
            onClick={() => {
                window.open('https://dev-pricer.slicedhealth.com')
            }}
        >
            https://dev-pricer.slicedhealth.com
        </Link>
    }

    return (
        <>
            <Helmet title="Pricer Setup" />
            <Portlet>
                <PortletHeader
                    title={<>
                        Pricer Setup
                        {/* Access Pricer at: {renderLink()} */}
                    </>}
                    toolbar={<></>}
                />
                <PortletBody>
                    <PublishMRF />
                    <div className="row">
                        <div className="col-md-12">
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map(({ label }) => (
                                    <Step key={label}>
                                        <StepLabel><h4>{label}</h4></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <div className='col-md-10'>
                            {getStepContent(activeStep)}
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
        </>
    )
}

export default PricerSetup