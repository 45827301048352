import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, makeStyles, TextField, Tooltip } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { CustomLabel, Loadable, PopoverLink } from '../../../../common';
import { DeleteOverriddenConfirmationDialog } from '../../ClaimAnalysisOverrideContractDialog';

import { useEffect } from 'react';
import { asDate } from '../../../../util/date';

const useStyles = makeStyles(theme => ({
    autoComplete: {
        width: '100%',
        "& .MuiFormControl-marginNormal": {
            marginTop: 0
        },
    },
    box:{
        display: 'inline-block',
    }

}));

function ChangeContractComponent({ claimAnalysis,isContractOverriden, currentContract, handleReprocessClaim, tinyContracts, overrideContractPending, hasClaimProcessorAccess,deleteOverrideContractPending, reprocessCallback,
    setShouldReloadGrid, actions: { overrideContract, getClaimAllDetails,removeOverriddenContract } }) {
    const classes = useStyles();

    const [isEdit, setEdit] = useState(false);
    const [isConfirmDialogOpen, setDialogOpen] = useState(false);
    const [confirmOverrideDialog, setConfirmOverrideDialog] = useState(false);
    const [contract, setContract] = useState(null);

    useEffect(() => {
        setContract(tinyContracts.find(item => item.id === currentContract?.id) ?? null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContract]);

    const handleChangeMode = (bool) => {
        setEdit(bool);
    }

    const overrideCallback = (response) => {
        getClaimAllDetails(claimAnalysis?.id);
        
        setDialogOpen(false);
        setContract(tinyContracts.find(item => item.id === currentContract?.id) ?? null);
        setEdit(false);

        if(response && response?.reprocessResponse) {
            reprocessCallback([response.reprocessResponse]);
        }
        setShouldReloadGrid(true); //refresh claim grid to update contract name
    }

    const handleContractChange = (value) => {
        setContract(value);
        setDialogOpen(true);
    }

    const handleConfirmDialogClose = () => {
        setDialogOpen(false);
        setContract(tinyContracts.find(item => item.id === currentContract?.id) ?? null);
        setEdit(false);
    }

    const handleConfirmOverride = () => {
        const payload = {
            claimNumber: claimAnalysis?.claimnumber,
            analysisId: claimAnalysis?.id,
            contractId: contract?.id,
            reprocess: true
        }
        overrideContract(payload, overrideCallback);
    }

    const deleteOverriddenCallback = () => { 

        getClaimAllDetails(claimAnalysis?.id);
        setContract(tinyContracts.find(item => item.id === currentContract?.id) ?? null);
        setConfirmOverrideDialog(false)
     }

    const handleRemoveOverride = () => {

        const payload = {
            claimNumber: claimAnalysis?.claimnumber,
            analysisId: claimAnalysis?.id,
            reprocess: true
        }
        removeOverriddenContract(payload, deleteOverriddenCallback);
      }

      const handleOpenOverrideContractDialog = () => { setConfirmOverrideDialog(true) }


    return (
        <>
            {isEdit ? <Grid container alignItems='center'>
                <Grid item xs={7}>
                    <Autocomplete
                        disableClearable
                        className={classes.autoComplete}
                        value={contract}
                        onChange={(e, value) => handleContractChange(value)}
                        getOptionSelected={(option, value) => option === value}
                        options={tinyContracts}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) =>
                            <TextField {...params}
                                required
                                name={`contract`}
                                label="Contract"
                                margin="normal"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title="Cancel Contract Overriding">
                        <IconButton className='ml-2' aria-label="process" size='small' onClick={handleConfirmDialogClose}>
                            <CloseIcon style={{ fontSize: '20px' }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid> :
                <>
                    <CustomLabel label="Contract Name"
                        data={
                            <PopoverLink
                                columnData={currentContract?.name}
                                hoverData={[
                                    {
                                        label: "Name",
                                        data: currentContract?.name,
                                    }, {
                                        label: "Effective Date",
                                        data: asDate(currentContract?.effectiveDate),
                                    }, {
                                        label: "Termination Date",
                                        data: asDate(currentContract?.terminationDate),
                                    }, {
                                        type: "LINK",
                                        label: "More Details",
                                        data: `/cms/contract/view/${currentContract?.id}`,
                                    }
                                ]}
                                title={currentContract?.name}
                                customClasses='aligned-content'
                        
                        
                                />
                        }
                        type="claim-contract"
                    />

                    {currentContract && 
                    <Box className={classes.box}>
                        <Tooltip title="Override Contract">
                            <IconButton
                                className='ml-2 p-1'
                                aria-label="Edit"
                                onClick={() => handleChangeMode(true)}
                            >
                                <EditIcon style={{ fontSize: '22px' }} />
                            </IconButton>
                        </Tooltip>

                        {hasClaimProcessorAccess &&
                            <Tooltip title="Reprocess Claim">
                                <IconButton className='ml-1' aria-label="process" size='small' onClick={handleReprocessClaim}>
                                    <PlayCircleOutlineIcon style={{ fontSize: '24px' }} />
                                </IconButton>
                            </Tooltip>
                        }



                       {isContractOverriden && 

                       <Tooltip title="Remove Overridden Contract and Reprocess" onClick={handleOpenOverrideContractDialog}>
                        <IconButton className='ml-2' aria-label="process" size='small' >
                            <HighlightOffIcon style={{ fontSize: '24px' }} />
                        </IconButton>
                        </Tooltip>}

                    </Box>}
                </>
            }
            <Dialog open={isConfirmDialogOpen}>
                <Loadable loading={overrideContractPending}>
                    <DialogTitle>
                        Override Contract
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please confirm to override contract and reprocess the claim "{claimAnalysis?.claimnumber}"
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button type='button' onClick={handleConfirmDialogClose} color="default">
                            Cancel
                        </Button>
                        <Button
                            type='button'
                            onClick={handleConfirmOverride}
                            color="primary"
                        >
                            Override
                        </Button>
                    </DialogActions>
                </Loadable>
            </Dialog>
            <DeleteOverriddenConfirmationDialog open={confirmOverrideDialog} removeOverriddenContract={handleRemoveOverride} isLoading={deleteOverrideContractPending} handleClose={()=>setConfirmOverrideDialog(false)}/>
        </>
    )
}

export default ChangeContractComponent