import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { createEditReset, setDependencyDialog } from '../../store/actions/drg';
import { drgLanding } from '../../store/actions/pages';
import { dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import { createColumnsSumField, createNumberDataField, createPlainDateField, createStringDataField } from '../../util/format';
import DRGPageComponent from './PpsPage';

const selectFields = createSelector(
    () => [

        createStringDataField('name', 'Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createNumberDataField('otherFixedLossOutlinerTheshold', 'Fixed Loss Outlier Threshold', { sortable: true, equators: integerEquators }),
        createNumberDataField('otherMarginalCostFactor', 'Marginal Cost Factor', { sortable: true, equators: integerEquators }),
        createColumnsSumField('opLaborRalted', 'Generic Base Rate', ['opLaborRalted', 'opNonlaborRalted', 'capFederalCapitalRate'], { hideFromFilter: true, returnWholeRow: true }),
        createNumberDataField('opDshOperatingAdjFactor', 'DSH Operating Adjustment', { sortable: true, equators: integerEquators }),
        createNumberDataField('opCostOfChargeRatio', 'Cost to Charge Ratio', { sortable: true, equators: integerEquators }),
        createPlainDateField('effectiveFrom', 'Effective From', { sortable: true, equators: dateEquators }),
        createPlainDateField('effectiveTo', 'Effective To', { sortable: true, equators: dateEquators }),

    ]
);

const selectFilteredData = createSelector(
    (state) => state.pages['drg-landing'].list,
    (data) => {
        return data
    }
);

export const DRGPage = injectIntl(
    connect(
        (state, props) => ({
            data: selectFilteredData(state),
            fields: selectFields(state as never),
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                drgLanding: drgLanding,
                createEditReset: createEditReset,
                setDependencyDialog: setDependencyDialog
            }, dispatch)
        })
    )(DRGPageComponent));