export const SEARCH_BY_ID = 4;
export const SEARCH_PERIOD_ID = 5;
export const DEFAULT_FOLLOWUP_DATE_ID = 6;
export const DEFAULT_VARIANCE_STATUS_ID = 8;
export const DEFAULT_VARIANCE_THRESHOLD_ID = 1;
export const DEFAULT_VARIANCE_PERCENTAGE_THRESHOLD_ID = 3;
export const DEFAULT_CONTRACT_STATE_ID = 9;
export const DEFAULT_REMIT_PAYER_MAPPING_ID = 10;
export const DEFAULT_REMIT_PAYER_MAPPING_V2_ID = 12;
export const RECOVERED_AMOUNT_ID = 13;

export const SETTING_DATA_TYPE_DECIMAL = 'Decimal';
export const SETTING_DATA_TYPE_STRING = 'String';
export const SETTING_DATA_TYPE_DATE = 'Date';