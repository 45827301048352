import { createSelector } from "reselect";

// const search = (array, keyword) => {
//     const regExp = new RegExp(keyword, "gi");
//     const check = obj => {
//         if (obj !== null && typeof obj === "object") { return Object.values(obj).some(check) }
//         if (Array.isArray(obj)) { return obj.some(check) }
//         return (typeof obj === "string" || typeof obj === "number") && regExp.test(obj);
//     }
//     return array.filter(check);
// }

export const createFilteredDataSelector = (selectFields, selectFilter, selectData) => {
    return createSelector(
        selectFields,
        selectFilter,
        selectData,
        (fields, filter, data) => {
            if (!data) {
                return [];
            }
            if (!filter || !filter.length || !data.length) {
                return data;
            }

            //return search(data, filter);
            //return data.filter(o => o.percentage >= filter);
            return data.filter(o =>
                fields.some(k => (String(o[k]).toLowerCase().trim()).includes(filter.toLowerCase().trim())));
        }
    )
}



export const createSortedDataSelector = (selectFields, selectSort, selectData) => {
    return createSelector(
        selectFields,
        selectSort,
        selectData,
        (fields, sort, data) => {
            if (!data) {
                return [];
            }
            if (data.length < 2 || (!fields || !fields.length) || (!sort || !sort.length)) {
                return data;
            }
            const { id, direction, fieldType } = sort[0];
            if (fieldType === 'Date') {
                return data.sort(function (a, b) {
                    let sortA = new Date(a[id]);
                    let sortB = new Date(b[id]);
                    let comparison = 0;
                    if (sortA > sortB) {
                        comparison = 1;
                    } else if (sortA < sortB) {
                        comparison = -1;
                    }
                    if (direction === 'desc') {
                        return comparison * -1;
                    }
                    return comparison;
                })
            } else {
                return data.sort(function (a, b) {
                    const val1 = getDeepValue(a, id);
                    const val2 = getDeepValue(b, id);
                    let sortA;
                    let sortB;
                    if (typeof (val1) === 'number') {
                        sortA = val1;
                        sortB = val2;
                    } else {
                        sortA = String(val1).toUpperCase();
                        sortB = String(val2).toUpperCase();
                    }

                    let comparison = 0;
                    if (sortA > sortB) {
                        comparison = 1;
                    } else if (sortA < sortB) {
                        comparison = -1;
                    }
                    if (direction === 'desc') {
                        return comparison * -1;
                    }
                    return comparison;
                });
            }

        }
    )
}

export const createFilteredSortedDataSelector = (selectFields, selectFilter, selectSort, selectData) => {
    const selectFilteredData = createFilteredDataSelector(selectFields, selectFilter, selectData);
    return createSortedDataSelector(selectFields, selectSort, selectFilteredData);
};

const getDeepValue = (obj, path) =>
    path
        .replace(/\[|\]\.?/g, '.')
        .split('.')
        .filter(s => s)
        .reduce((acc, val) => acc && acc[val], obj);