import { createSelector } from 'reselect';

export const selectAllowablesList = (state) => state.allowables.allowablesList;

export const selectAllowables = createSelector(
    selectAllowablesList,
    (allowables) => {
        return allowables
    }
);

export const selectCheckboxStatus = createSelector(
    selectAllowables,
    (allowablesList) => {
        const enabledAllowables = allowablesList.filter(allowable => allowable.enabled).length;
        if (allowablesList.length === enabledAllowables) {
            return true;
        }
        return false;
    }
)