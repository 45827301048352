import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/dashboardDataSources';
import * as API from '../api/dashboardDataSources';
import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_LIST_PENDING });
        const payload = yield call(API.listDashboardDataSources);
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_GET_PENDING });
        const { dashboardDataSourceId, callback } = action.payload;
        const payload = yield call(API.getDashboardDataSource, dashboardDataSourceId);
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_CREATE_PENDING });
        const {
            dashboardDataSource, callback,
        } = action.payload;

        const newDashboardDataSource = yield call(API.createDashboardDataSource, dashboardDataSource);
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_CREATE_FULFILLED, newDashboardDataSource });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_UPDATE_PENDING });
        const {
            dashboardDataSource,
            callback
        } = action.payload;

        const newDashboardDataSource = yield call(API.updateDashboardDataSource, dashboardDataSource);
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_UPDATE_FULFILLED, newDashboardDataSource });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteDashboardDataSource(action) {
    try {
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_DELETE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deleteDashboardDataSource, id);
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_DELETE_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFields(action) {
    try {
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_FIELDS_GET_PENDING });
        const { dashboardDataSourceId, callback } = action.payload;
        const payload = yield call(API.getDashboardDataSourceFields, dashboardDataSourceId);
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_FIELDS_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_DATA_SOURCES_FIELDS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.DASHBOARD_DATA_SOURCES_RESET });
}

export default function*() {
    yield takeEvery(actions.DASHBOARD_DATA_SOURCES_LIST, list);
    yield takeEvery(actions.DASHBOARD_DATA_SOURCES_GET, get);
    yield takeEvery(actions.DASHBOARD_DATA_SOURCES_CREATE, create);
    yield takeEvery(actions.DASHBOARD_DATA_SOURCES_UPDATE, update);
    yield takeEvery(actions.DASHBOARD_DATA_SOURCES_DELETE, deleteDashboardDataSource);
    yield takeEvery(actions.DASHBOARD_DATA_SOURCES_FIELDS_GET, getFields);
}