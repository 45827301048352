import React, { useEffect, useState } from "react";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { IconButton, makeStyles, TablePagination, Tooltip } from "@material-ui/core";
import { DataTable, DataFilter, TablePaginationActions } from '../../../common';
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
    textField: {
        width: 400,
    },
}));

export default function APIKeysPage(props) {
    const classes = useStyles();

    const {
        apiKeysData, fields, sort, getAPIKeysPending, getAPIKeyValuePending, deactivateAPIKeyPending, activateAPIKeyPending,
        order, filter, actions: { setSort, setOrder, setFilter, getAPIKeys, deactivateAPIKey, activateAPIKey, resetAPIKeysList }
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getAPIKeys();
        return () => {
            resetAPIKeysList();
        }
    }, [getAPIKeys, resetAPIKeysList]);

    const handleCallBack = () => {
        handleRefresh();
    }

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleAddKey = () => {
        props.history.push('/admin/api-key-management/edit/new');
    }

    const handleActiveChange = (rowData) => {
        if (rowData.active) {
            deactivateAPIKey(rowData.id, handleCallBack);
        } else {
            activateAPIKey(rowData.id, handleCallBack);
        }
    }

    function handleRefresh() {
        resetAPIKeysList();
        getAPIKeys();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function usersActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className={classes.IconButton}
                aria-label="Active"
                onClick={() => handleActiveChange(rowData)}
            >
                <Tooltip title={rowData.active ? 'Deactivate' : 'Activate'}>
                    {rowData.active ? (
                        <i className="fas fa-check-circle text-success"></i>
                    ) : (
                        <i className="fas fa-check-circle text-secondary"></i>
                    )}
                </Tooltip>
            </IconButton>
        </div>
    }

    return (
        <>
            <Helmet title="API Keys" />
            <Portlet>
                <PortletHeader
                    title="API Keys"
                    toolbar={
                        <PortletHeaderToolbar>
                            <DataFilter
                                onChange={handleSearchInput}
                                placeholder="Search"
                                cssClass={classes.textField}
                                filter={filter}
                            />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <DataTable
                        fields={fields}
                        data={apiKeysData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        onAdd={handleAddKey}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        loading={getAPIKeysPending || getAPIKeyValuePending || deactivateAPIKeyPending || activateAPIKeyPending}
                        name="API Keys"
                        handleRefresh={handleRefresh}
                        showPagination={true}
                        footerPagination={() => (
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                colSpan={fields.length + 2}
                                count={apiKeysData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        )}

                        hasActions={true}
                        renderActionColumn={usersActions}
                    />
                </PortletBody>
            </Portlet>
        </>
    );
}

