import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set } from '../../store/actions/pages';
import { activateFacility, createFacilityReset, deactivateFacility, list, reset, setFacilityDependencyDialog } from '../../store/actions/facilities';
import { createStringDataField, createIconDataField } from '../../util/format';
import { createFilteredSortedDataSelector } from '../../util/selector';
import { selectFacilities } from "../../store/selectors/facilities";
import FacilitiesComponent from './FacilitiesPage';
import { selectSessionRole } from '../../store/selectors/session';
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from '../../store/constants/roles';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    selectSessionRole,
    (userRole) => {
        if (Boolean(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN)) {
            return [
                createStringDataField('name', 'Name', { sortable: true }),
                createStringDataField('description', 'Description'),
                createStringDataField('address', 'Address'),
                createStringDataField('phone', 'Phone'),
                createStringDataField('ein', 'EIN'),
                createStringDataField('facilityNPI', 'Facility NPI'),
                createIconDataField('enableForPricer', 'Enable For Pricer', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
                
            ];
        }
        return [
            createStringDataField('name', 'Name', { sortable: true }),
            createStringDataField('description', 'Description'),
            createStringDataField('address', 'Address'),
            createStringDataField('phone', 'Phone'),
            createStringDataField('ein', 'EIN'),
            createStringDataField('facilityNPI', 'Facility NPI'),
            createIconDataField('enableForPricer', 'Enable For Pricer', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
            createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle' }),
            
        ];
    }
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`facilities-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`facilities-landing`].filter,
    selectSort,
    selectFacilities,
);


export const FacilitiesPage = connect(
    () => {
        return (state, props) => ({
            facilityPending: state.facilities.list.pending,
            facilityActivatePending: state.facilities.activate.pending,
            facilityDeactivatePending: state.facilities.deactivate.pending,
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            order: state.pages[`facilities-landing`].order,
            filter: state.pages[`facilities-landing`].filter,
            sort: selectSort(state, props),
            userRole: selectSessionRole(state)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getFacilities: list,
            setFilter: (value) => set(`facilities-landing`, 'filter', value),
            setOrder: (value) => set(`facilities-landing`, 'order', value),
            setSort: (value) => set(`facilities-landing`, 'sort', value),
            setList: (value) => set(`facilities-landing`, 'data', value),
            createFacilityReset: createFacilityReset,
            facilityReset: reset,
            setFacilityDependencyDialog: setFacilityDependencyDialog,
            activateFacility: activateFacility,
            deactivateFacility: deactivateFacility
        }, dispatch)
    })
)(injectIntl(FacilitiesComponent));