import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import PocketCostDialogComponent from './PocketCostDialog';
import { calculateOutOfPocketCost } from '../../../../store/actions/claimAnalysis';

export const PocketCostDialog = connect(
    () => {
        const selectInitialValues = createSelector(
            () => {
                return {
                    copay: '',
                    remainingDeductible: '',
                    coInsurancePercent: '',
                    maxOutOfPocketExpense: '',
                    maxOutOfPocket: ''
                }
            }
        )

        return (state, props) => ({
            initialValues: selectInitialValues(state, props),
            calculateOOPPending: state.claimAnalysis.calculateOOP.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            calculateOutOfPocketCost: calculateOutOfPocketCost
        }, dispatch)
    })
)(injectIntl(PocketCostDialogComponent));