import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/settings';
import * as API from '../api/settings';
import { sessionErrorHandling } from './session';

function* listSettings() {
    try {
        yield put({ type: actions.SETTINGS_LIST_PENDING });
        const payload = yield call(API.list);
        yield put({ type: actions.SETTINGS_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SETTINGS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}



function* update(action) {
    try {
        yield put({ type: actions.SETTINGS_UPDATE_PENDING });
        const {
            id, value, parentId, 
            callback
        } = action.payload;

        yield call(API.update, {
            id, value, parentId
        });
        yield put({ type: actions.SETTINGS_UPDATE_FULFILLED });

        if (callback) {
            callback(true);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.SETTINGS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getReleaseVersion(action) {
    try {
        yield put({ type: actions.RELEASE_VERSION_PENDING });

        const payload = yield call(API.getReleaseVersion);
        yield put({ type: actions.RELEASE_VERSION_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RELEASE_VERSION_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getReleaseNotes(action) {
    try {
        yield put({ type: actions.RELEASE_NOTES_PENDING });

        const payload = yield call(API.getReleaseNotes);
        yield put({ type: actions.RELEASE_NOTES_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RELEASE_NOTES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


export default function* () {
    yield takeEvery(actions.SETTINGS_LIST, listSettings);
    yield takeEvery(actions.SETTINGS_UPDATE, update);
    yield takeEvery(actions.RELEASE_VERSION, getReleaseVersion);
    yield takeEvery(actions.RELEASE_NOTES, getReleaseNotes);
}