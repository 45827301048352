import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Tooltip, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import { Formik } from "formik";
import React from 'react';
import { Loadable, PopoverLink } from '../../../../common';
import { getEndDate, getStartDate } from '../../../../util/date';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    autoComplete: {
        width: '90%',
    },
    textField: {
        width: '90%',
    },
    root: {
        flexGrow: 1,
    },
    icon: {
        fontSize: '10px',
        color: '#C8C8C8',
    },
    helpIcon: {
        color: 'red',
        padding: 0,
        minWidth: 'auto',
        marginTop: '40px',
        width: '10%',
    }
}));

export default function ContractModelDialog(props) {
    const {
        open, handleRefresh, mode, intl, initialValues, modelEstimatePending,
        analyzeModelEstimatePending, searchFiltersPending, contractsPending, tinyContracts, searchFilters,
        actions: { modelEstimate, analyzeModelEstimate, setContractModelEditDialog }
    } = props;
    const classes = useStyles();

    function handleClose() {
        setContractModelEditDialog(null);
    }

    function handleCallback() {
        handleClose();
        handleRefresh();
    }

    function GetInputRequestByCriteria(criteria) {
        let criterias = JSON.parse(criteria);
        let searchCriteriaFilters = [];
        let significance = true;
        criterias.forEach((filter)=>{
            if(filter.type && filter.type === 'DATE' && filter.dates && filter.dates.length > 1) {
                if(filter.relativeFilter && filter.relativeFilter !== 'custom') {
                    filter.dates[0].value = getStartDate(filter.relativeFilter, false);
                    filter.dates[1].value = getEndDate(filter.relativeFilter, false);
                }
                filter.dates.forEach((dateFilter) => { 
                    searchCriteriaFilters.push({
                        operator: 0,
                        filters: [{
                            equator: dateFilter.equator,
                            id: filter.field,
                            value: dateFilter.value
                        }],
                    });
                });
            }
            else if(filter.type && filter.type === 'SIGNIFICANCE') {
                significance = filter.value;
            }
            else if(!filter.customFilter || (filter.customFilter === true && filter.value !== 'both')) {
                searchCriteriaFilters.push({
                    operator: 0,
                    filters: [{
                        equator: filter.equator,
                        id: filter.field,
                        value: filter.value
                    }],
                })
            }
        });
        return {
            inputRequest: {
                filter: { operator: 0, operands: searchCriteriaFilters },
                page: { currentPage: 1, recordsPerPage: 10000 },
                sort: [{ id: "variance", direction: 1 }]
            },
            significance: significance
        }
    }

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-primary`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    return (
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === 'edit' ? 'Edit Contract Model' : 'Add Contract Model'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={modelEstimatePending || analyzeModelEstimatePending || searchFiltersPending || contractsPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};

                            if (!values.modelName) {
                                errors.modelName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.baseContract) {
                                errors.baseContract = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.modelContract) {
                                errors.modelContract = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (values.modelContract && values.baseContract && values.baseContract.id === values.modelContract.id) {
                                errors.modelContract = 'Contract 2 cannot have the same value as Contract 1.';
                            }

                            if (!values.searchCriteria) {
                                errors.searchCriteria = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            let { inputRequest, significance } = GetInputRequestByCriteria(values.searchCriteria.criteria);
                            let payload = {
                                name: values.modelName,
                                contractIds: [values.baseContract.id, values.modelContract.id],
                                searchCriteriaId: values.searchCriteria.id,
                                inputRequest: inputRequest,
                                showSignificantVariance: significance
                            }

                            if (mode === 'create') {
                                modelEstimate(payload, handleCallback);
                            }
                            else {
                                payload = {
                                    ...payload,
                                    modelId: values.id,
                                }
                                analyzeModelEstimate(payload, handleCallback);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Model Name"
                                                name="modelName"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.modelName}
                                                helperText={touched.modelName && errors.modelName}
                                                error={Boolean(touched.modelName && errors.modelName)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                disabled={ mode === 'edit' ? true : false }
                                                className={classes.autoComplete}
                                                value={values.baseContract}
                                                disableClearable={false}
                                                onChange={(e, value) => setFieldValue("baseContract", value)}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                name={`baseContract`}
                                                options={tinyContracts}
                                                getOptionLabel={(option) => option.name}
                                                onBlur={handleBlur}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        name={`baseContract`}
                                                        label="Contract 1"
                                                        error={Boolean(touched.baseContract && errors.baseContract)}
                                                        helperText={touched.baseContract && errors.baseContract}
                                                        margin="normal" />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                disabled={ mode === 'edit' ? true : false }
                                                className={classes.autoComplete}
                                                value={values.modelContract}
                                                disableClearable={false}
                                                onChange={(e, value) => setFieldValue("modelContract", value)}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                name={`modelContract`}
                                                options={tinyContracts}
                                                getOptionLabel={(option) => option.name}
                                                onBlur={handleBlur}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        name={`modelContract`}
                                                        label="Contract 2"
                                                        error={Boolean(touched.modelContract && errors.modelContract)}
                                                        helperText={touched.modelContract && errors.modelContract}
                                                        margin="normal" />
                                                }
                                            />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <Autocomplete
                                                // disabled={pending}
                                                disableClearable={false}
                                                onChange={(e, value) => setFieldValue("searchCriteria", value)}
                                                value={values.searchCriteria}
                                                name={`searchCriteria`}
                                                options={searchFilters.filter(x=> x.searchCriteriaTypeId === 3)}
                                                className={classes.autoComplete}
                                                getOptionLabel={(option) => option.criteriaName}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        name="searchCriteria"
                                                        label='Search Criteria'
                                                        error={Boolean(touched.searchCriteria && errors.searchCriteria)}
                                                        helperText={touched.searchCriteria && errors.searchCriteria}
                                                        margin="normal"
                                                        
                                                    />
                                                )}
                                                renderOption={(option) => (
                                                    <Grid container>
                                                        <Grid item xs={11}>
                                                            {option.criteriaName}
                                                        </Grid>
                                                        {option.isPublic && (
                                                            <Grid item xs={1}>
                                                                <Tooltip title="Public">
                                                                    <i className={classNames("fa fa-user-friends", classes.icon)}></i>
                                                                </Tooltip>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                )}
                                            />
                                            <span className={classes.helpClass}> {hoverColumn('Search criteria is sourced from the claim analysis page.')} </span>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Button
                                            onClick={handleClose}
                                            variant="contained"
                                            color="default"
                                            className={classes.button}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={`ml-3 ${classes.button}`}
                                            type="submit"
                                            disabled={modelEstimatePending || analyzeModelEstimatePending}
                                        >
                                            {(modelEstimatePending || analyzeModelEstimatePending) ? 'Analyzing' : 'Analyze'}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}