import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/apikey';
import * as API from '../api/apikey';
import { sessionErrorHandling } from './session';

function* getAPIKeys(action) {
    try {
        yield put({ type: actions.API_KEYS_GET_PENDING });
        const payload = yield call(API.getAPIKeys);
        yield put({ type: actions.API_KEYS_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.API_KEYS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        const { values, callback } = action.payload;
        yield put({ type: actions.API_KEY_CREATE_PENDING });
        const payload = yield call(API.createAPIKey, values);
        yield put({ type: actions.API_KEY_CREATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.API_KEY_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getValue(action) {
    try {
        const { apikeyId, callback } = action.payload;
        yield put({ type: actions.API_KEY_GET_VALUE_PENDING });
        const payload = yield call(API.getAPIKeyById, apikeyId);
        yield put({ type: actions.API_KEY_GET_VALUE_FULFILLED, payload: { id: apikeyId, key: payload } });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.API_KEY_GET_VALUE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deactivate(action) {
    try {
        const { apiKeyId, callback } = action.payload;
        yield put({ type: actions.API_KEY_DEACTIVATE_PENDING });
        const payload = yield call(API.deactivateAPIKey, apiKeyId);
        yield put({ type: actions.API_KEY_DEACTIVATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.API_KEY_DEACTIVATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* activate(action) {
    try {
        const { apiKeyId, callback } = action.payload;
        yield put({ type: actions.API_KEY_ACTIVATE_PENDING });
        const payload = yield call(API.activateAPIKey, apiKeyId);
        yield put({ type: actions.API_KEY_ACTIVATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.API_KEY_ACTIVATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getTinyList(action) {
    try {
        yield put({ type: actions.API_KEY_GET_TINY_LIST_PENDING });
        const payload = yield call(API.getTinyList);
        yield put({ type: actions.API_KEY_GET_TINY_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.API_KEY_GET_TINY_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.API_KEYS_GET, getAPIKeys);
    yield takeEvery(actions.API_KEY_CREATE, create);
    yield takeEvery(actions.API_KEY_DEACTIVATE, deactivate);
    yield takeEvery(actions.API_KEY_ACTIVATE, activate);
    yield takeEvery(actions.API_KEY_GET_VALUE, getValue);
    yield takeEvery(actions.API_KEY_GET_TINY_LIST, getTinyList);

}