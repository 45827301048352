import React from 'react';
import {
    Button, Dialog, DialogContent, DialogTitle, TextField, makeStyles, Grid, Switch,
    FormControlLabel
} from '@material-ui/core';

import { Formik } from "formik";
import { Loadable } from '../../../../common';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    checkboxlabel: {
        marginLeft: 0
    },
    switch: {
        margin: theme.spacing(1),
    },
}));

export default function PayerDialog(props) {
    const {
        open, handleClose, mode, intl, initialValues, payerCreatePending,
        payerUpdatePending, payerContracts,
        actions: { payerCreate, payerUpdate }
    } = props;
    const classes = useStyles();
    let shouldReloadGrid = false;

    function handleCallback(reload) {
        handleClose(reload);
    }

    return (
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === 'edit' ? 'Edit Payer' : 'Add Payer'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={payerCreatePending || payerUpdatePending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            if (!values.displayName) {
                                errors.displayName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            shouldReloadGrid = true;
                            let payload = {
                                enabled: values.enabled,
                                displayName: values.displayName,
                            }
                            if (mode === 'create') {
                                payerCreate(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                payload = {
                                    ...payload,
                                    id: values.id,
                                }
                                payerUpdate(payload, handleCallback, shouldReloadGrid);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                type="text"
                                                label="Display Name"
                                                name="displayName"
                                                className={classes.displayName}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.displayName}
                                                helperText={touched.displayName && errors.displayName}
                                                error={Boolean(touched.displayName && errors.displayName)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid className="mt-2" item xs={12} md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="enabled"
                                                        checked={values.enabled}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={classes.switch}
                                                        value={values.enabled}
                                                    />
                                                }
                                                label="Enable"
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container justify="flex-end">
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={handleClose}
                                                variant="contained"
                                                color="default"
                                                className={classes.button}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={payerCreatePending || payerUpdatePending}
                                            >
                                                {(payerCreatePending || payerUpdatePending) ? 'Saving Changes' : 'Save'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}