
export function arrayToTree(list, rootNode = { id: 'root', name: 'Roles and Permissions' }) {
    const addChild = (node, child) => ((node.children ?? (node.children = [])).push(child), child);

    for (const { id, action, resource, active } of list) {
        const path = resource.split('.');
        const node = path.reduce(
            (currentNode, targetNodeId) =>
                currentNode.children?.find(({ id }) => id === targetNodeId) ??
                addChild(currentNode, { id: targetNodeId, name: targetNodeId, active: active }),
            rootNode
        );
        addChild(node, { id: String(id), name: action, active: active });
    }

    return rootNode;
};

let result = [];
export function getParent(nodes) {
    if (Array.isArray(nodes.children)) {
        result.push(nodes.id);
        nodes.children.map((child) => getParent(child))
        return result;
    }
    return result;
}

export function findParents(itemId, hierarchy) {
    const parents = [];
    const findParentsRecursive = (currentItem, path = []) => {
        if (currentItem.parentId === itemId) {
            parents.push(...path);
            return;
        }
        if (currentItem.child && currentItem.child.length > 0) {
            for (const child of currentItem.child) {
                findParentsRecursive(child, [...path, { id: currentItem.parentId, name: currentItem.parentName }]);
            }
        }
    };

    for (const node of hierarchy) {
        findParentsRecursive(node);
    }

    return parents;
}

export function findNodeInTree(itemId, hierarchy) {
    for (const node of hierarchy) {
        if (node.parentId === itemId) {
            return node;
        }
        if (node.child && node.child.length > 0) {
            const result = findNodeInTree(itemId, node.child);
            if (result) {
                return result;
            }
        }
    }
    return null;
}
