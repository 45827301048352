import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { setEstimatorHistoryDetailsDialog } from '../../store/actions/claimAnalysis';
import { tiny } from '../../store/actions/contracts';
import { estimatorHistoryLanding, resetPage,set } from '../../store/actions/pages';
import { getByName, reset } from '../../store/actions/search';
import { dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import { createCurrencyDataField, createExceptionHoverField, createNumberDataField, createPlainDateField, createStringDataField, createStringFieldFromMultiKeys } from '../../util/format';
import { selectContractsTiny } from '../ClaimAnalysisPage';
import EstimateHistoryPageComponent from './EstimateHistoryPage';

const selectFields = createSelector(
    selectContractsTiny,
    (tinyContracts) => {
        return [
            createNumberDataField('id', 'Estimation Id', { sortable: true, equators: integerEquators }),
            createStringFieldFromMultiKeys(['patientFname', 'patientLname'], 'Patient Name', { hideFromFilter: true, returnWholeRow: true }),
            createStringDataField('patientFname', 'Patient First Name', { hideFromGrid: true, equators: StringEquators }),
            createStringDataField('patientLname', 'Patient Last Name', { hideFromGrid: true, equators: StringEquators }),
            createStringDataField('claimType', 'Claim Type', { sortable: true, equators: StringEquators }),
            createStringDataField('contractName', 'Contract Name', { isDropdown: true, dropdownValues: tinyContracts.map(contract => contract.name), sortable: true, equators: StringEquators }),
            createCurrencyDataField('claimAmount', 'Charge Amount', { sortable: true, equators: integerEquators }),
            createCurrencyDataField('estimatedClaimAmount', 'Allowable', { sortable: true, equators: integerEquators }),
            createPlainDateField('admitionDate', 'Service Date', { sortable: true, equators: dateEquators }),
            createStringDataField('executedBy', 'Executed By', { sortable: true, equators: StringEquators }),
            createPlainDateField('executedAt', 'Executed At', { sortable: true, equators: dateEquators }),
            createExceptionHoverField('success', 'Status', true, { hideFromFilter: true, sortable: true, returnWholeRow: true, }),
        ]
    }
);

const selectHistoryData = createSelector(
    (state) => state.pages['estimator-history-landing'].list,
    (data) => {
        return data
    }
);

export const EstimateHistory = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            historyData: selectHistoryData(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            estimatorHistoryLanding: estimatorHistoryLanding,
            searchGetByName: getByName,
            searchReset: reset,
            resetPagedTable: resetPage,
            getTinyContracts: tiny,
            setEstimatorHistoryDetailsDialog: setEstimatorHistoryDetailsDialog,
            resetCriteriaWaiting: (pageName) => set(pageName, 'waitForCriteriaFilter', false),

        }, dispatch)
    })
)(injectIntl(EstimateHistoryPageComponent));