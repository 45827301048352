import {
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    METHOD_DELETE,
    SERVER_URL,
} from '../constants/api';
import { headersAuthSendReceiveJson } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

const getCriteriaByPageName = (pageName) => {
    return fetch(`${SERVER_URL}/api/v1/cms/searchcriteria/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(pageName),
    }).then(apiHandleResponse);
};

const createSearchCriteria = (searchCriteria) => {
    return fetch(`${SERVER_URL}/api/v1/cms/searchcriteria`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(searchCriteria)
    }).then(apiHandleResponse);
};

const getSearchCriteriaList = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/searchcriteria/list`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
}

const deleteSearchCriteriaList = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/searchcriteria/${id}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
}
const upateSearchCriteria = (searchCriteria) => {
    return fetch(`${SERVER_URL}/api/v1/cms/searchcriteria`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(searchCriteria)
    }).then(apiHandleResponse);
};

const getSearchCriteriaTypes = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/searchcriteria/types`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getCriteriaByPageName,
    createSearchCriteria,
    getSearchCriteriaList,
    deleteSearchCriteriaList,
    upateSearchCriteria,
    getSearchCriteriaTypes,
};