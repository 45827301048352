import { FormControl, Input, InputLabel, makeStyles, MenuItem, Select, TablePagination } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DataTable, Loadable } from '../../../common';
import { DeleteConfirmation } from "../../../common/DeleteConfirmation";
import { TablePaginationActions } from '../../../common/tablePaginationActions';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";

const CLAIM_ANALYSIS="claimAnalysis-landing"

const useStyles = makeStyles(theme => {
    return {
        underline: {
            textDecoration: 'underline',
        },
        textField: {
            width: 400,
        },
        button: {
            margin: theme.spacing(1),
        },
        formControl: {
            width: 300,
            margin: '0'
        }
    }
});

export default function CriteriaSettings(props) {
    const { searchPending, searchDeletePending, fields, filteredData, order, sort, clientId, filter, searchOptions,
        actions: { getSearchCriterias, setFilter, setSort, setOrder, deleteCriteria, resetDeleteState, getUsersTiny,resetSearchCriteria }
    } = props;
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = useState(0);
    const [shouldFilterChange, setShouldFilterChange] = useState(true);

    useEffect(() => {
        getUsersTiny(clientId);
    }, [getUsersTiny, clientId]);

    useEffect(() => {
        if (searchOptions.length > 0 && shouldFilterChange) {
            setFilter(searchOptions[0].id);
            setShouldFilterChange(false);
        }
    }, [searchOptions]);

    useEffect(() => {
        getSearchCriterias();
        return () => {
            setFilter('');
            resetDeleteState('');
        }
    }, [getSearchCriterias, setFilter]);

    const filterChangeHandler = (event) => {
        setFilter(event.target.value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCallBack = (searchCriteria) => {

        let filters = sessionStorage.getItem("pages");
        filters = JSON.parse(filters);

        //If selected filter is removed then reset the localstorage and reduxstorage
        if (filters[CLAIM_ANALYSIS] && filters[CLAIM_ANALYSIS]?.selectedFilter?.id === searchCriteria?.id) {
            delete filters[CLAIM_ANALYSIS];
            sessionStorage.setItem("pages", JSON.stringify({ ...filters }));
            resetSearchCriteria()
        }
        getSearchCriterias();

    }

    const handleDeleteCriteria = (searchCriteria) => {
        if (searchCriteria) {
            deleteCriteria(searchCriteria.id, () =>
                handleCallBack(searchCriteria)
            );
        }
    }

    const handleRefresh = () => {
        getSearchCriterias();
    }

    function criteriaActions(rowData) {
        return <div className='d-flex'>
            <DeleteConfirmation handleDelete={() => handleDeleteCriteria(rowData)} />
        </div>
    }

    return (
        <>
            <Helmet title="Criteria" />
            <Loadable loading={searchPending || searchDeletePending}>
                <Portlet className={classes.portlet}>
                    <PortletHeader
                        title="Search Criteria"
                        toolbar={
                            <PortletHeaderToolbar>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="filter">Select page</InputLabel>
                                    <Select
                                        value={filter}
                                        onChange={filterChangeHandler}
                                        input={<Input id="filter" />}
                                        inputProps={{
                                            name: "filter",
                                            id: "filter"
                                        }}
                                    >
                                        {searchOptions.map((module) =>
                                            <MenuItem
                                                key={module.id}
                                                value={module.id}
                                            >
                                                {module.name}
                                            </MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody>
                        <DataTable
                            size="medium"
                            fields={fields}
                            data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            order={order}
                            onOrder={setOrder}
                            sort={sort}
                            onSort={setSort}
                            showPagination={true}
                            footerPagination={() => (
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                    colSpan={fields.length + 2}
                                    count={filteredData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            )}
                            stickyHeader={true}
                            name="criteria settings"
                            handleRefresh={handleRefresh}

                            hasActions={true}
                            renderActionColumn={criteriaActions}
                        />
                    </PortletBody>
                </Portlet>
            </Loadable>
        </>
    );
}