import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/forgotPassword';
import * as API from '../api/forgotPassword';


function* forgotPassword(action) {
    const { username, callback } = action.payload;
    try {
        yield put({ type: actions.FORGOT_PASSWORD_PENDING });
        yield call(API.forgotPassword, username);
        yield put({ type: actions.FORGOT_PASSWORD_FULFILLED });


        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FORGOT_PASSWORD_REJECTED, payload: errorMessage });
    }
}

function* resetPassword(action) {
    const { newPassword, token, callback } = action.payload;
    try {
        yield put({ type: actions.RESET_PASSWORD_PENDING });
        yield call(API.resetPassword, newPassword, token);
        yield put({ type: actions.RESET_PASSWORD_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.RESET_PASSWORD_REJECTED, payload: errorMessage });

        if (callback) {
            callback();
        }
    }
}

function* verifyToken(action) {
    const { tokenToverify, callback } = action.payload;
    try {
        yield put({ type: actions.VERIFY_TOKEN_PENDING });
        const token = yield call(API.verifyToken, tokenToverify);
        yield put({ type: actions.VERIFY_TOKEN_FULFILLED, payload: token });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VERIFY_TOKEN_REJECTED, payload: errorMessage });
        if (callback) {
            callback();
        }
    }
}

export default function* () {
    yield takeEvery(actions.FORGOT_PASSWORD, forgotPassword);
    yield takeEvery(actions.RESET_PASSWORD, resetPassword);
    yield takeEvery(actions.VERIFY_TOKEN, verifyToken);
}