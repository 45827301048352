export const PRICER_PAYERS_LIST = 'PRICER_PAYERS_LIST';
export const PRICER_PAYERS_PENDING = 'PRICER_PAYERS_PENDING';
export const PRICER_PAYERS_FULFILLED = 'PRICER_PAYERS_FULFILLED';
export const PRICER_PAYERS_REJECTED = 'PRICER_PAYERS_REJECTED';

export const PRICER_PAYERS_ERROR_RESET = 'PRICER_PAYERS_ERROR_RESET';
export const PRICER_PAYERS_RESET = 'PRICER_PAYERS_RESET';

export const PRICER_PAYERS_UPDATE_STATUS = 'PRICER_PAYERS_UPDATE_STATUS';
export const PRICER_PAYERS_UPDATE_STATUS_PENDING = 'PRICER_PAYERS_UPDATE_STATUS_PENDING';
export const PRICER_PAYERS_UPDATE_STATUS_FULFILLED = 'PRICER_PAYERS_UPDATE_STATUS_FULFILLED';
export const PRICER_PAYERS_UPDATE_STATUS_REJECTED = 'PRICER_PAYERS_UPDATE_STATUS_REJECTED';

export const PRICER_PAYER_CREATE = 'PRICER_PAYER_CREATE';
export const PRICER_PAYER_CREATE_PENDING = 'PRICER_PAYER_CREATE_PENDING';
export const PRICER_PAYER_CREATE_FULFILLED = 'PRICER_PAYER_CREATE_FULFILLED';
export const PRICER_PAYER_CREATE_REJECTED = 'PRICER_PAYER_CREATE_REJECTED';

export const PRICER_PAYER_UPDATE = 'PRICER_PAYER_UPDATE';
export const PRICER_PAYER_UPDATE_PENDING = 'PRICER_PAYER_UPDATE_PENDING';
export const PRICER_PAYER_UPDATE_FULFILLED = 'PRICER_PAYER_UPDATE_FULFILLED';
export const PRICER_PAYER_UPDATE_REJECTED = 'PRICER_PAYER_UPDATE_REJECTED';

export const PRICER_PAYER_CONTRACT_CREATE = 'PRICER_PAYER_CONTRACT_CREATE';
export const PRICER_PAYER_CONTRACT_CREATE_PENDING = 'PRICER_PAYER_CONTRACT_CREATE_PENDING';
export const PRICER_PAYER_CONTRACT_CREATE_FULFILLED = 'PRICER_PAYER_CONTRACT_CREATE_FULFILLED';
export const PRICER_PAYER_CONTRACT_CREATE_REJECTED = 'PRICER_PAYER_CONTRACT_CREATE_REJECTED';

export const PRICER_PAYER_CONTRACT_UPDATE = 'PRICER_PAYER_CONTRACT_UPDATE';
export const PRICER_PAYER_CONTRACT_UPDATE_PENDING = 'PRICER_PAYER_CONTRACT_UPDATE_PENDING';
export const PRICER_PAYER_CONTRACT_UPDATE_FULFILLED = 'PRICER_PAYER_CONTRACT_UPDATE_FULFILLED';
export const PRICER_PAYER_CONTRACT_UPDATE_REJECTED = 'PRICER_PAYER_CONTRACT_UPDATE_REJECTED';

export const PRICER_PAYER_SELECT_ALL = 'PRICER_PAYER_SELECT_ALL';
export const PRICER_PAYER_SELECT_ALL_PENDING = 'PRICER_PAYER_SELECT_ALL_PENDING';
export const PRICER_PAYER_SELECT_ALL_FULFILLED = 'PRICER_PAYER_SELECT_ALL_FULFILLED';
export const PRICER_PAYER_SELECT_ALL_REJECTED = 'PRICER_PAYER_SELECT_ALL_REJECTED';

export const PRICER_PAYER_GET_PAYER_CONTRACTS_TINY = 'PRICER_PAYER_GET_PAYER_CONTRACTS_TINY';
export const PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_PENDING = 'PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_PENDING';
export const PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_FULFILLED = 'PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_FULFILLED';
export const PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_REJECTED = 'PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_REJECTED';

export const PAYER_INFO_SET_EDIT_DIALOG = 'PAYER_INFO_SET_EDIT_DIALOG';
export const PAYER_INFO_SET_ENABLE_DIALOG = 'PAYER_INFO_SET_ENABLE_DIALOG';

export const getPayers = () => {
    return {
        type: PRICER_PAYERS_LIST
    }
};

export const updatePayerStatus = (id, enabled) => {
    return {
        type: PRICER_PAYERS_UPDATE_STATUS,
        payload: { id, enabled },
    }
};

export const create = (payer, callback, shouldReloadGrid) => ({
    type: PRICER_PAYER_CREATE,
    payload: { payer, callback, shouldReloadGrid },
});

export const update = (payer, callback, shouldReloadGrid) => ({
    type: PRICER_PAYER_UPDATE,
    payload: { payer, callback, shouldReloadGrid },
});

export const createPayerContract = (payerContract, callback, shouldReloadGrid) => ({
    type: PRICER_PAYER_CONTRACT_CREATE,
    payload: { payerContract, callback, shouldReloadGrid },
});

export const updatePayerContract = (payerContract, callback, shouldReloadGrid) => ({
    type: PRICER_PAYER_CONTRACT_UPDATE,
    payload: { payerContract, callback, shouldReloadGrid },
});

export const errorReset = (...errorKeys) => ({
    type: PRICER_PAYERS_ERROR_RESET,
    payload: { errorKeys },
});

export const selectDeselectAll = (payload, callback) => ({
    type: PRICER_PAYER_SELECT_ALL,
    payload: { payload, callback },
});

export const getPayerContractsTiny = () => ({
    type: PRICER_PAYER_GET_PAYER_CONTRACTS_TINY,
});

export const setPayerInfoEditDialog = (payerInfoEditDialog) => ({
    type: PAYER_INFO_SET_EDIT_DIALOG,
    payload: { payerInfoEditDialog }
});

export const setPayerInfoEnableDialog = (infoEnableDialog) => ({
    type: PAYER_INFO_SET_ENABLE_DIALOG,
    payload: { infoEnableDialog }
});