import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { CustomLabel, Loadable } from '../../../common';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DependencyDialog(props) {
    const { open,isLoading,handleDialogClose,removeOverriddenContract,handleClose} = props;

    const classes = useStyles();



    return (
        <Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={isLoading}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Are you Sure ?
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                        <Typography>Are you sure you want to remove this Overridden Contract?</Typography> 
                                       
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={removeOverriddenContract}
                        variant="contained"
                        disabled={isLoading}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Loadable >
        </Dialog>
    )
}

export default DependencyDialog
