import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set } from '../../../store/actions/pages';
import { list, reset } from '../../../store/actions/roles';
import { createStringDataField } from '../../../util/format';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { selectRoles } from "../../../store/selectors/roles";
import RolesComponent from './RolesPage';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    (state) => [
        createStringDataField('name', 'Name', { sortable: true }),
        createStringDataField('description', 'Description'),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`roles-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`roles-landing`].filter,
    selectSort,
    selectRoles,
);

export const RolesPage = connect(
    () => {
        return (state, props) => ({
            rolePending: state.roles.list.pending,
            // roleDeletePending: state.roles.delete.pending,
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            order: state.pages[`roles-landing`].order,
            filter: state.pages[`roles-landing`].filter,
            sort: selectSort(state, props),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getRoles: list,
            setFilter: (value) => set(`roles-landing`, 'filter', value),
            setOrder: (value) => set(`roles-landing`, 'order', value),
            setSort: (value) => set(`roles-landing`, 'sort', value),
            roleReset: reset,
        }, dispatch)
    })
)(injectIntl(RolesComponent));