import React, { useEffect, useState } from "react";
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { DataTable, DataFilter } from '../../../common';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { TablePagination } from '@material-ui/core';
import { Loadable } from '../../../common';
import { TablePaginationActions } from '../../../common/tablePaginationActions';
import { Edit as EditIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => {
    return {
        underline: {
            textDecoration: 'underline',
        },
        textField: {
            width: 400,
        },
        button: {
            margin: theme.spacing(1),
        },
    }
});

export default function RolesPage(props) {
    const { rolePending, roleDeletePending, fields, filteredData, order, sort,
        actions: { getRoles, setFilter, setSort, setOrder, roleReset }
    } = props;
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getRoles();

        // return function cleanup(){
        //     roleReset();
        // }
    }, [getRoles]);

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleAddRole() {
      props.history.push('/admin/roles/edit/new');
    }

    const handleRoleEdit = (role) => {
      props.history.push(`/admin/roles/edit/${role.id}`);
    }

    function roleActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleRoleEdit(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
        </div>
    }

    function handleRefresh() {
        getRoles()
    }

    return (
        <>
            <Loadable loading={rolePending || roleDeletePending}>
                <Portlet className={classes.portlet}>
                    <PortletHeader
                        title="Roles"
                        toolbar={
                            <PortletHeaderToolbar>
                                <DataFilter
                                    onChange={handleSearchInput}
                                    placeholder="Search"
                                    cssClass={classes.textField}
                                />
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody>
                        <DataTable
                            size="medium"
                            fields={fields}
                            data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            order={order}
                            onOrder={setOrder}
                            sort={sort}
                            onSort={setSort}
                            stickyHeader={true}
                            showPagination={true}
                            footerPagination={() => (
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                    colSpan={fields.length + 2}
                                    count={filteredData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            )}
                            onAdd={handleAddRole}
                            name="Role"
                            handleRefresh={handleRefresh}

                            hasActions={true}
                            renderActionColumn={roleActions}
                        />
                    </PortletBody>
                </Portlet>
            </Loadable>
        </>
    );
}