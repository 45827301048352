import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ClientsPageComponent from './ClientsPage';
import { list } from '../../../store/actions/clients';
import { selectClients } from '../../../store/selectors/clients';
import { set } from '../../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { createStringDataField, createPlainDateField, createIconDataField, createClientProductFlags } from '../../../util/format';
import { RootState } from '../../../store/reducers/index.js';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Client Name', { sortable: true }),
        createStringDataField('displayName', 'Display Name', { sortable: true }),
        createStringDataField('licenseNumber', 'License Number', { sortable: true }),
        createPlainDateField('goLive', 'Go Live'),
        createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createClientProductFlags('hasDashboardAccess', 'Products', {
            sortable: false, onHover: true, returnWholeRow: true, customPopoverTitle: "Products"
        }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['clients-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['clients-landing'].filter,
    selectSort,
    selectClients,
);

export const ClientsPage = injectIntl(
    connect(
        (state : RootState ) => ({
            fields: selectFields( state as never ),
            clientsFilteredData: selectFilteredData(state),
            sort: selectSort(state),
            clientListPending: state.clients.list.pending,
            order: state.pages['clients-landing'].order,
            filter: state.pages['clients-landing'].filter,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                clientList: list,
                setFilter: (value) => set('clients-landing', 'filter', value),
                setOrder: (value) => set('clients-landing', 'order', value),
                setSort: (value) => set('clients-landing', 'sort', value),
            }, dispatch)
        })
    )(ClientsPageComponent));