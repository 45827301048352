import { createReducer } from '../../util';
import * as actions from '../actions/appId';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {   
    list: [],
    actualKey: null,// { id:'',key:'' }

    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    getValueById:{
        ...initialStatusState,

    }
    
};

export default createReducer(initialState, {

    [actions.APP_ID_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.APP_ID_GET_FULFILLED]: (state,list) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        list
    }),
    [actions.APP_ID_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.APP_ID_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.APP_ID_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
    }),
    [actions.APP_ID_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.APP_ID_KEY_VALUE_PENDING]: (state) => ({
        ...state,
        getValueById: {
            pending: true,
        },
    }),
    [actions.APP_ID_KEY_VALUE_FULFILLED]: (state, actualKey) => ({
        ...state,
        getValueById: {
            ...initialStatusState,
        },
        actualKey
    }),
    [actions.APP_ID_KEY_VALUE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getValueById: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.APP_ID_RESET_ACTUAL_KEY]: (state) => ({
        ...state,
        actualKey: null
    }),

    [actions.APP_ID_RESET_LIST]: (state) => ({
        ...state,
        list: null
    }),

});
