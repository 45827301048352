import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { getFeeschedule } from '../../../../../store/actions/contracts';
import FeeScheduleComponent from './FeeSchedule';

import {getHierarchy } from '../../../../../store/actions/feeScheduleV2';

export const selectFeeSchedule = (state) => state.contracts.feeSchedule;

export const FeeScheduleView = (connect(
    () => {
        const selectFeeScheduleId = (state, props) => props.match.params.feeScheduleId;
        return (state, props) => ({
            feeScheduleId: selectFeeScheduleId(state, props),
            feeSchedule: selectFeeSchedule(state),
            //feeSchedulePending: state.contracts.feeScheduleList.pending,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getFeeschedule: getFeeschedule,
            getHierarchy
        }, dispatch)
    })
)(injectIntl(FeeScheduleComponent)));