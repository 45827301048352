import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import EstimationsResultPageComponent from './EstimationsResultPage';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { set } from '../../../store/actions/pages';
import { createCurrencyDataField, createNumberDataField, createSecondLevelField, createStringDataField } from '../../../util/format';
import { getEstimateById } from '../../../store/actions/claimAnalysis';

export const EstimationsResultPage = connect(
    () => {

        const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

        const selectEstimateById = (state, props) => state.claimAnalysis?.estimateById;
        const selectEstimatorId = (state, props) => props.match.params.estimatorId;


        const selectClaimChargeData = createSelector(
            (state) => state.claimAnalysis?.estimateById?.chargeEstimations,
            (chargeEstimationHistory) => {
                if (chargeEstimationHistory) {
                    return chargeEstimationHistory.map(item => ({ ...item, perUnitChargeAmount: parseInt(item.chargeAmount) / parseInt(item.units) }))
                }
                return []
            }
        );

        const selectFields = createSelector(
            () => [
                createSecondLevelField('rule', 'Rule', 'name', { sortable: true, onHover: true }),
                createStringDataField('revCode', 'Revenue Code', { sortable: true, onHover: true }),
                createStringDataField('pxCode', 'Procedure Code', { sortable: true, onHover: true }),
                createNumberDataField('units', 'Units', { sortable: true, }),
                createCurrencyDataField('perUnitChargeAmount', 'Per Unit Charge Amount', { sortable: true, calculateTotal: true }),
                createCurrencyDataField('chargeAmount', 'Total Charge Amount', { sortable: true, calculateTotal: true }),
                createCurrencyDataField('estimatedPayment', 'Allowable', { sortable: true, calculateTotal: true }),
            ]
        );

        const selectFilteredFields = createSelector(
            selectFields,
            (fields) => {
                return fields.map(field => field.id);
            }
        );

        const selectSort = createSelector(
            (state) => state.pages['claim-estimation-result-landing'].sort,
            (sort) => sort || DEFAULT_SORT,
        );

        const selectFilteredData = createFilteredSortedDataSelector(
            selectFilteredFields,
            (state) => state.pages['claim-estimation-result-landing'].filter,
            selectSort,
            selectClaimChargeData,
        );

        const selectInitialValues = createSelector(
            () => {
                return {
                    coPay: '',
                    remainingDeductible: '',
                    coInsurancePercentage: '',
                    maxOutOfPocketExpense: '',
                    maxOutOfPocket: ''
                }

            }
        )

        const selectAdvancedTraceSections = createSelector(
            selectEstimateById,
            (result) => {
                if (result && result?.advanceTrace && JSON.parse(result.advanceTrace)) {
                    return JSON.parse(result.advanceTrace)?.Sections ?? []
                }
                return []
            },
        );

        return (state, props) => ({
            estimateById: selectEstimateById(state, props),
            estimationId: selectEstimatorId(state, props),
            getEstimatePending: state.claimAnalysis.getEstimateById.pending,

            fields: selectFields(state),
            filteredData: selectFilteredData(state),
            sort: selectSort(state),
            order: state.pages['claim-estimation-result-landing'].order,
            filter: state.pages['claim-estimation-result-landing'].filter,

            initialValues: selectInitialValues(state, props),
            sections: selectAdvancedTraceSections(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setFilter: (value) => set('claim-estimation-result-landing', 'filter', value),
            setOrder: (value) => set('claim-estimation-result-landing', 'order', value),
            setSort: (value) => set('claim-estimation-result-landing', 'sort', value),
            getEstimateById: getEstimateById
        }, dispatch)
    })
)(injectIntl(EstimationsResultPageComponent));