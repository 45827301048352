import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DeleteOverriddenContractDialogComponent from './ClaimAnalysisOverrideContractDialog';

export const DeleteOverriddenConfirmationDialog = connect(
    () => {
       
        return (state, props) => ({
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({         
        }, dispatch)
    })
)(injectIntl(DeleteOverriddenContractDialogComponent));