import { createReducer } from '../../util';
import * as actions from '../actions/chargeMaster';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    chargeMasterEditDialog: null,
     //{open:true,charge:obj, mode: 'edit'}
     //{open:true,charge:obj, mode: 'edit'}
    upload: {
        ...initialStatusState,
    },
    list: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    download: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {
    [actions.CHARGE_MASTER_UPLOAD_PENDING]: (state) => ({
        ...state,
        upload: {
            pending: true,
        },
    }),
    [actions.CHARGE_MASTER_UPLOAD_FULFILLED]: (state) => ({
        ...state,
        upload: {
            ...initialStatusState,
        },
    }),
    [actions.CHARGE_MASTER_UPLOAD_REJECTED]: (state, errorMessage) => ({
        ...state,
        upload: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CHARGE_MASTER_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.CHARGE_MASTER_LIST_FULFILLED]: (state, charges) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: charges,
    }),
    [actions.CHARGE_MASTER_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CHARGE_MASTER_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.CHARGE_MASTER_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.CHARGE_MASTER_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CHARGE_MASTER_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.CHARGE_MASTER_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.CHARGE_MASTER_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CHARGE_MASTER_DOWNLOAD_TEMPLATE_PENDING]: (state) => ({
        ...state,
        download: {
            pending: true,
        },
    }),
    [actions.CHARGE_MASTER_DOWNLOAD_TEMPLATE_FULFILLED]: (state) => ({
        ...state,
        download: {
            ...initialStatusState,
        },
    }),
    [actions.CHARGE_MASTER_DOWNLOAD_TEMPLATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        download: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    //[actions.ROLES_RESET]: () => ({...initialState}),

    [actions.CHARGE_MASTER_SET_EDIT_DIALOG]: (state, { chargeMasterEditDialog }) => ({
        ...state,
        chargeMasterEditDialog
    }),

});
