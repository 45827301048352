import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem, Select,
    Switch,
    TextField,
    makeStyles
} from '@material-ui/core';
import React, { useEffect } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from "formik";
import { Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Loadable, PopoverLink } from '../../../../../../../common';
import { BackButton } from '../../../../../../../common/BackButton';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../../../../../partials/content/Portlet';
import { DependencyDialog } from "./DependencyDialog";
import { getBackPath } from '../../../../../../../router/RouterHelpers';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '90%',
    },
    autoComplete: {
        width: '90%',
    },
    textArea: {
        width: '96%',
    },
    checkboxlabel: {
        marginLeft: 0
    },
    switch: {
        margin: theme.spacing(1),
        marginTop: '2rem'
    },
    formControl: {
        width: '90%',
        marginTop: theme.spacing(2),
    },
    portlet: {
        'box-shadow': 'none',
        marginBottom: 0,
        "& .kt-portlet__head": {
            borderColor: theme.palette.secondary.main,
            // marginLeft: theme.spacing(3.5),
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        pointerEvents: 'auto',
        width: 300,
    },
    underline: {
        textDecoration: 'underline',
    },
    additionalRatesDiv: {
        width: '97%',
        // maxHeight: '240px',
        // overflow: 'auto'
    },
    helpIcon: {
        color: 'red',
        padding: 0,
        minWidth: 'auto',
        width: '10%',
        fontSize: '16px',
        marginRight: '10px'
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

export default function FeeScheduleItemDialog({
    feeScheduleId, feeScheduleItemId, feeSchedule, open, handleClose, mode, intl, initialValues, feeScheduleItemCreatePending,
    feeScheduleItemUpdatePending, types, modifiers, RateLabels, feeScheduleItemGetPending, history,
    actions: { feeScheduleItemCreate, feeScheduleItemUpdate, feescheduleItemGetCodeTypes, 
        modifiersLanding, getFeescheduleItem, setFeeScheduleLabelRateDepdendencyDialog, getFeeschedule }
}) {
    const currentLocation = useLocation().pathname;
    const classes = useStyles();
    let shouldReloadGrid = false;

    useEffect(() => {
        if(feeSchedule === null) {
            getFeeschedule(feeScheduleId);
        }
    }, [feeSchedule]);

    useEffect(() => {
        if(mode === 'edit') {
            getFeescheduleItem(feeScheduleItemId); 
        }
    }, [feeScheduleItemId]);

    useEffect(() => {
        modifiersLanding();
    }, [modifiersLanding]);

    useEffect(() => {
        feescheduleItemGetCodeTypes();
    }, [feescheduleItemGetCodeTypes]);

    

    function handleCallback(reload, dependencyResponse) {
        if(dependencyResponse && !dependencyResponse.canDelete && dependencyResponse.records.length > 0) {
            setFeeScheduleLabelRateDepdendencyDialog({ open: true, dependencies: dependencyResponse.records });
        }
        else {
            getBackPath(currentLocation, { history, defaultRoute: `/cms/feeschedules/view/${feeScheduleId}` });
            // handleClose(reload);
        }
    }

    function handlePaidCodeType(event, setFieldValue, fieldName) {
        setFieldValue(fieldName, event.target.value);
    }

    const handleAddRateField = (additionalRates, setFieldValue) => {
        if (additionalRates.length < 10) {
            setFieldValue("additionalRates", [...additionalRates, {label:RateLabels?.find(x=> x.rate === `rate`).label ?? '', rate:''}]);
        }
    };
    
    const handleRateLabelChange = (selectedLabel, index, values, setFieldValue) => {
        const newRates = [...values.additionalRates];
        newRates[index] = {...newRates[index], label: selectedLabel};
        setFieldValue('additionalRates', newRates);
    };

    const handleRateChange = (index, newValue, values, setFieldValue) => {
        const newRates = [...values.additionalRates];
        newRates[index] = {...newRates[index], rate: newValue};
        setFieldValue('additionalRates', newRates);
    };

    const handleDeleteRate = (index, values, setFieldValue) => {
        const filteredLabels = values.additionalRates.filter((_, i) => i !== index);
        setFieldValue('additionalRates', filteredLabels);
    };

    function handleClearFields(setFieldValue) {
        setFieldValue(`additionalRates`, [
            {
                label: RateLabels?.find(x=> x.rate === `rate`).label ?? '', 
                rate: null
            }
        ]);
    }

    const getFilteredLabels = (currentIndex, values) => {
        const selectedLabels = values.additionalRates.map(rate => rate.label);
        return RateLabels.filter(({ label }) => !selectedLabels.includes(label) || selectedLabels[currentIndex] === label);
    };

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-primary`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    return (
        <Loadable loading={feeScheduleItemGetPending || feeScheduleItemCreatePending || feeScheduleItemUpdatePending}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors: { 
                        code?: string; 
                        description?: string; 
                        effectiveDate?: string; 
                        expirationDate?: string; 
                        codeTypeId?: string; 
                        additionalRates?: any; 
                    } = {};
                    if (!values.code) {
                        errors.code = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!values.description) {
                        errors.description = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    // if (!values.rate) {
                    //     errors.rate = intl.formatMessage({
                    //         id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    //     });
                    // }

                    if (!values.effectiveDate && values.expirationDate) {
                        errors.effectiveDate = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (!values.expirationDate && values.effectiveDate) {
                        errors.expirationDate = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.expirationDate < values.effectiveDate) {
                        errors.expirationDate = 'Expiration Date cannot be before effective date';
                    }

                    if (!values.codeTypeId) {
                        errors.codeTypeId = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if(values.additionalRates && values.additionalRates.length > 0 && values.additionalRates.filter(x=> x.label && x.rate).length === 0) {
                        if(!errors.additionalRates) { errors.additionalRates = []; }
                        errors.additionalRates[0] = 'Please enter at least one rate.';
                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    shouldReloadGrid = true;
                    let payload = {
                        id: undefined,
                        code: values?.code?.trim(),
                        paidCode: values?.paidCode?.trim(),
                        codeTypeId: values.codeTypeId,
                        paidCodeTypeId: values.paidCodeTypeId || null,
                        feeScheduleId: values.feeScheduleId,
                        active: values.active,
                        description: values.description,
                        effectiveDate: values.effectiveDate,
                        expirationDate: values.expirationDate,
                        grouping: values.grouping,
                        // rate: values.rate,
                        codeModifier1: values.codeModifier1?.modifier ?? '',
                        codeModifier2: values.codeModifier2?.modifier ?? '',
                        codeModifier3: values.codeModifier3?.modifier ?? '',
                        codeModifier4: values.codeModifier4?.modifier ?? '',
                    }

                    if (values?.additionalRates.length > 0 && values?.additionalRates.filter(x => x.rate !== null && x.rate !== '').length > 0) {
                        const rates = {};
                        RateLabels.forEach(({ label, rate }, index) => {
                            const additionalRate = values.additionalRates.find(x => x.label === label)?.rate ?? null;
                            if (additionalRate) {
                                rates[rate.toLowerCase()] = parseFloat(additionalRate);
                            }
                        });
                        payload = {
                            ...payload,
                            ...rates
                        };
                    }
                    
                    if (mode === 'create') {
                        feeScheduleItemCreate(payload, handleCallback, shouldReloadGrid);
                    }
                    else {
                        payload = {
                            ...payload,
                            id: values.id,
                        }
                        feeScheduleItemUpdate(payload, handleCallback, shouldReloadGrid);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    dirty
                }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                        <Portlet>

                            <PortletHeader
                            toolbar={
                                <PortletHeaderToolbar className='w-100'>
                                    <Grid container alignItems="center">
                                        <Grid md={12} container className="justify-content-end">
                                            <BackButton 
                                                text={dirty ? "Cancel" : "Back"} 
                                                defaultRoute={`/cms/feeschedules/view/${feeScheduleId}`} 
                                            />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className='ml-3'
                                                type="submit"
                                                disabled={!dirty}
                                            >
                                            Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </PortletHeaderToolbar>
                            }
                            />

                            <PortletBody>
                        
                                <div className="root">
                                    <Grid xs={12} md={9}>
                                        <Grid container>
                                            <Grid item xs={6} md={6}>
                                                <TextField
                                                    required
                                                    label="Code"
                                                    name="code"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.code}
                                                    helperText={touched.code && errors.code}
                                                    error={Boolean(touched.code && errors.code)}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <FormControl className={classes.formControl} error={Boolean(touched.codeTypeId && errors.codeTypeId)}>
                                                    <InputLabel htmlFor="codeType">CodeType</InputLabel>
                                                    <Select
                                                        required
                                                        value={values.codeTypeId}
                                                        onChange={(event) => handlePaidCodeType(event, setFieldValue, "codeTypeId")}
                                                        inputProps={{
                                                            name: "codeType",
                                                            id: "codeType",
                                                        }}
                                                    >
                                                        {types.map(({ id, name }) => (
                                                            <MenuItem
                                                                key={name}
                                                                value={id}
                                                            >
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                    {Boolean(touched.codeTypeId && errors.codeTypeId) && (
                                                        <FormHelperText error>Please select a Code Type</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel htmlFor="codeType">Paid CodeType</InputLabel>
                                                    <Select
                                                        value={values.paidCodeTypeId}
                                                        onChange={(event) => handlePaidCodeType(event, setFieldValue, "paidCodeTypeId")}
                                                        inputProps={{
                                                            name: "codeType",
                                                            id: "codeType",
                                                        }}
                                                    >
                                                        {types.map(({ id, name }) => (
                                                            <MenuItem
                                                                key={name}
                                                                value={id}
                                                            >
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField
                                                    label="Paid Code"
                                                    name="paidCode"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.paidCode}
                                                    helperText={touched.paidCode && errors.paidCode}
                                                    error={Boolean(touched.paidCode && errors.paidCode)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="date"
                                                    label="Effective Date"
                                                    name="effectiveDate"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.effectiveDate}
                                                    helperText={touched.effectiveDate && errors.effectiveDate}
                                                    error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="date"
                                                    label="Expiration Date"
                                                    name="expirationDate"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.expirationDate}
                                                    helperText={touched.expirationDate && errors.expirationDate}
                                                    error={Boolean(touched.expirationDate && errors.expirationDate)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField
                                                    label="Grouping"
                                                    name="grouping"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.grouping}
                                                    helperText={touched.grouping && errors.grouping}
                                                    error={Boolean(touched.grouping && errors.grouping)}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <Autocomplete
                                                    className={classes.autoComplete}
                                                    value={values.codeModifier1}
                                                    disableClearable={false}
                                                    onChange={(e, value) => setFieldValue("codeModifier1", value)}
                                                    getOptionSelected={(option, value) => option === value}
                                                    options={modifiers}
                                                    getOptionLabel={(option) => `${option.modifier} - ${option.modfierType}`}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`codeModifier1`}
                                                            label="Code Modifier 1"
                                                            error={Boolean(touched.codeModifier1 && errors.codeModifier1)}
                                                            helperText={touched.codeModifier1 && errors.codeModifier1}
                                                            margin="normal" />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <Autocomplete
                                                    className={classes.autoComplete}
                                                    value={values.codeModifier2}
                                                    disableClearable={false}
                                                    onChange={(e, value) => setFieldValue("codeModifier2", value)}
                                                    getOptionSelected={(option, value) => option === value}
                                                    options={modifiers}
                                                    getOptionLabel={(option) => `${option.modifier} - ${option.modfierType}`}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`codeModifier2`}
                                                            label="Code Modifier 2"
                                                            error={Boolean(touched.codeModifier2 && errors.codeModifier2)}
                                                            helperText={touched.codeModifier2 && errors.codeModifier2}
                                                            margin="normal" />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <Autocomplete
                                                    className={classes.autoComplete}
                                                    value={values.codeModifier3}
                                                    disableClearable={false}
                                                    onChange={(e, value) => setFieldValue("codeModifier3", value)}
                                                    getOptionSelected={(option, value) => option === value}
                                                    options={modifiers}
                                                    getOptionLabel={(option) => `${option.modifier} - ${option.modfierType}`}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`codeModifier3`}
                                                            label="Code Modifier 3"
                                                            error={Boolean(touched.codeModifier3 && errors.codeModifier3)}
                                                            helperText={touched.codeModifier3 && errors.codeModifier3}
                                                            margin="normal" />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <Autocomplete
                                                    className={classes.autoComplete}
                                                    value={values.codeModifier4}
                                                    disableClearable={false}
                                                    onChange={(e, value) => setFieldValue("codeModifier4", value)}
                                                    getOptionSelected={(option, value) => option === value}
                                                    options={modifiers}
                                                    getOptionLabel={(option) => `${option.modifier} - ${option.modfierType}`}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`codeModifier4`}
                                                            label="Code Modifier 4"
                                                            error={Boolean(touched.codeModifier4 && errors.codeModifier4)}
                                                            helperText={touched.codeModifier4 && errors.codeModifier4}
                                                            margin="normal" />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name="active"
                                                            color="primary"
                                                            checked={values.active}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="ml-0"
                                                            value={values.active}
                                                        />
                                                    }
                                                    label="Enable"
                                                    className={classes.switch}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    required
                                                    label="Description"
                                                    name="description"
                                                    className={classes.textArea}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.description}
                                                    helperText={touched.description && errors.description}
                                                    error={Boolean(touched.description && errors.description)}
                                                />
                                            </Grid>

                                            {/* <Grid item xs={6} md={6}>
                                                <TextField
                                                    type="number"
                                                    required
                                                    label="Rate"
                                                    name="rate"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.rate}
                                                    helperText={touched.rate && errors.rate}
                                                    error={Boolean(touched.rate && errors.rate)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                disabled={values.additionalRates.length >= 10 ? true : false}
                                                                edge="end"
                                                                aria-label="Add Additional Rates"
                                                                color="primary"
                                                                onClick={() => handleAddRateField(values.additionalRates, setFieldValue)}
                                                                >
                                                                    <AddCircleIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                            ),
                                                        }}
                                                />
                                            </Grid> */}
                                            {values.additionalRates.length > 0 && (
                                                <Grid item xs={12} md={12} >
                                                    <Card className={`col-md-12 mt-3 p-0 ${classes.additionalRatesDiv}`} >
                                                        <Card.Header>
                                                            <Grid container className="align-items-center">
                                                                <Grid item xs={11}>
                                                                    <h5 className="mb-0">Rates </h5>
                                                                </Grid>
                                                                <Grid item xs={1} className='text-right'>
                                                                <span> {hoverColumn('if the field is left blank, the base rate will be applied. A value of 0 will set the rate to $0.00.')} </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Card.Header>
                                                        <Card.Body>
                                                        <Grid container spacing={2}>
                                                            {values.additionalRates.map((additionalRate, index) => (
                                                                <React.Fragment key={index}>
                                                                    <Grid item xs={5} md={5}>
                                                                        <FormControl className={classes.formControl}>
                                                                            <InputLabel htmlFor="ratelabel">Label</InputLabel>
                                                                            <Select
                                                                                value={additionalRate.label || ''}
                                                                                onChange={(event) => handleRateLabelChange(event.target.value, index, values, setFieldValue)}
                                                                                inputProps={{
                                                                                    name: "label",
                                                                                    id: "label",
                                                                                }}
                                                                            >
                                                                                {getFilteredLabels(index, values).map(({ label }) => (
                                                                                    <MenuItem
                                                                                        key={label}
                                                                                        value={label}
                                                                                    >
                                                                                        {label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={5} md={5}>
                                                                        <TextField
                                                                            type="number"
                                                                            label='Rate'
                                                                            margin="normal"
                                                                            value={additionalRate?.rate === 0 ? 0 : additionalRate.rate || ''}
                                                                            className={classes.textField}
                                                                            onChange={(e: any) => e.target.value >= 0 ? handleRateChange(index, e.target.value, values, setFieldValue) : handleRateChange(index, '', values, setFieldValue)}
                                                                            fullWidth
                                                                            helperText={touched.additionalRates && errors.additionalRates && errors.additionalRates[index]}
                                                                            error={Boolean(touched.additionalRates && errors.additionalRates && errors.additionalRates[index])}
                                                                        />
                                                                    </Grid>
                                                                    {index === 0 && (
                                                                        <Grid item xs={3} md={2}>
                                                                            <Grid container>
                                                                                <IconButton
                                                                                    edge="start"
                                                                                    color="primary"
                                                                                    aria-label="Add"
                                                                                    onClick={() => handleAddRateField(values.additionalRates, setFieldValue)}
                                                                                    className={classes.iconButton}>
                                                                                    <AddCircleIcon />
                                                                                </IconButton>
                                                                                <Button
                                                                                    size="small"
                                                                                    onClick={() => handleClearFields(setFieldValue)}
                                                                                    className={classes.clearButton}
                                                                                    startIcon={<DeleteIcon />}
                                                                                >
                                                                                    Clear All
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}

                                                                    {index !== 0 && (
                                                                        <Grid item xs={1}>
                                                                            {values.additionalRates.length > 1 && (
                                                                                <IconButton
                                                                                    edge="start"
                                                                                    aria-label="Delete"
                                                                                    onClick={() => handleDeleteRate(index, values, setFieldValue)}
                                                                                    className={classes.iconButton}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            )}
                                                                        </Grid>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                        </Grid>
                                                        </Card.Body>
                                                    </Card>
                                                </Grid>
                                            )}
                                            
                                        </Grid>
                                    </Grid>
                                    {/* <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Button
                                            onClick={handleClose}
                                            variant="contained"
                                            color="default"
                                            className={classes.button}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={`${classes.button} ml-3`}
                                            type="submit"
                                            disabled={feeScheduleItemCreatePending || feeScheduleItemUpdatePending}
                                        >
                                            {(feeScheduleItemCreatePending || feeScheduleItemUpdatePending) ? 'Saving..' : 'Save'}
                                        </Button>
                                    </Grid> */}
                                </div>
                            </PortletBody>
                        </Portlet>
                        <DependencyDialog />
                    </form>
                )}
            </Formik>
        </Loadable>
    )
}