import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { updateClaimStatuses } from '../../../store/actions/claimAnalysis';
import UpdateStatusComponent from './UpdateStatusDialog';
import { setV2 } from '../../../store/actions/pagesV2';


const selectClaimIds = (state, props) => state.pagesV2['claimAnalysis-landing'].selected;

export const UpdateStatusDialog = connect(
    () => {
        const selectInitialValues = createSelector(
            () => {
                return {
                    status: null,
                
                }
            }
        )

        return (state, props) => ({
            open: state.claimAnalysis.updateClaimStatusDialogOpen,
            selectedItems: selectClaimIds(state, props),
            initialValues: selectInitialValues(state),
            isUpdateStatusPending: state.claimAnalysis.updateClaimStatus.pending,



      
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            updateClaimStatuses,
            setSelected: (value) => setV2('claimAnalysis-landing', 'selected', value),

        }, dispatch)
    })
)(injectIntl(UpdateStatusComponent));