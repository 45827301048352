import {
    METHOD_GET,
    METHOD_PUT,
    METHOD_POST,
    SERVER_URL,
    METHOD_DELETE,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

const list = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservices`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const tiny = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservices/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const create = (service) => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservice`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(service)
    }).then(apiHandleResponse);
};

const update = (service) => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservice`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(service)
    }).then(apiHandleResponse);
};

const get = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservice/${id}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const deleteService = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservice/${id}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
}

const getServiceType = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/service/types`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getDescription = (options, serviceId = 435, codeType = 'primary') => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservice/descriptions/${codeType}/${serviceId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getCodeServicesFromTargetClient = (targetClient) => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservices/${targetClient}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getRulesFromCodeServiceId = (options, codeServiceId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservice/${codeServiceId}/rules`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getCodeServiceDependencies = (codeServiceId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/codeservice/dependencies/${codeServiceId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    list,
    create,
    update,
    get,
    deleteService,
    getServiceType,
    tiny,
    getDescription,
    getCodeServicesFromTargetClient,
    getRulesFromCodeServiceId,
    getCodeServiceDependencies
};