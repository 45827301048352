import { createReducer } from '../../util';
import * as actions from '../actions/eapg';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    eapg: null,
    tiny: [],
    eapgByContract: [],
    eapgByTargetClient: [],
    dependencyDialog: null,
    dependencies: [],
    eapgVersions: [],
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getEAPGTiny: {
        ...initialStatusState,
    },
    getEAPGTinyByContract: {
        ...initialStatusState,
    },
    getEAPGTinyByTargetClient: {
        ...initialStatusState,
    },
    getEAPGDependencies: {
        ...initialStatusState
    },
    getEAPGVersions: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {

    [actions.EAPG_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.EAPG_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
    }),
    [actions.EAPG_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.EAPG_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.EAPG_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
    }),
    [actions.EAPG_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.EAPG_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.EAPG_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.EAPG_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.EAPG_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.EAPG_GET_FULFILLED]: (state, eapg) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        eapg
    }),
    [actions.EAPG_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.EAPG_GET_TINY_PENDING]: (state) => ({
        ...state,
        getEAPGTiny: {
            pending: true,
        },
    }),
    [actions.EAPG_GET_TINY_FULFILLED]: (state, tiny) => ({
        ...state,
        getEAPGTiny: {
            ...initialStatusState,
        },
        tiny
    }),
    [actions.EAPG_GET_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getEAPGTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.EAPG_CREATE_UPDATE_RESET]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
        create: {
            ...initialStatusState,
        },
    }),
    [actions.EAPG_GET_TINY_BY_CONTRACT_PENDING]: (state) => ({
        ...state,
        getEAPGTinyByContract: {
            pending: true,
        },
    }),
    [actions.EAPG_GET_TINY_BY_CONTRACT_FULFILLED]: (state, eapgByContract) => ({
        ...state,
        getEAPGTinyByContract: {
            ...initialStatusState,
        },
        eapgByContract
    }),
    [actions.EAPG_GET_TINY_BY_CONTRACT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getEAPGTinyByContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.EAPG_GET_TINY_BY_TARGET_CLIENT_PENDING]: (state) => ({
        ...state,
        getEAPGTinyByTargetClient: {
            pending: true,
        },
    }),
    [actions.EAPG_GET_TINY_BY_TARGET_CLIENT_FULFILLED]: (state, eapgByTargetClient) => ({
        ...state,
        getEAPGTinyByTargetClient: {
            ...initialStatusState,
        },
        eapgByTargetClient
    }),
    [actions.EAPG_GET_TINY_BY_TARGET_CLIENT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getEAPGTinyByTargetClient: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.EAPG_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),

    [actions.EAPG_RESET_DEPENDENCIES]: (state) => ({
        ...state,
        dependencies: []
    }),

    [actions.EAPG_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getEAPGDependencies: {
            pending: true,
        },
    }),
    [actions.EAPG_DELETE_DEPENDENCIES_FULFILLED]: (state, dependencies) => ({
        ...state,
        getEAPGDependencies: {
            ...initialStatusState,
        },
        dependencies
    }),
    [actions.EAPG_DELETE_DEPENDENCIES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getEAPGDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.EAPG_VERSIONS_PENDING]: (state) => ({
        ...state,
        getEAPGVersions: {
            pending: true,
        },
        eapgVersions: [],
    }),
    [actions.EAPG_VERSIONS_FULFILLED]: (state, eapgVersions) => ({
        ...state,
        getEAPGVersions: {
            ...initialStatusState,
        },
        eapgVersions,
    }),
    [actions.EAPG_VERSIONS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getEAPGVersions: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});
