import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

import ClientsViewComponent from './ClientsViewComponent';
import {get } from '../../../../store/actions/clients';
import { createClientSelector } from '../../../../store/selectors/clients';



export const ClientsViewPage = connect(
    () => {
        const selectClientId = (state, props) => props.match.params.clientId;

        const selectClient = createClientSelector(selectClientId);

        return (state, props) => ({
            client: selectClient(state, props),
            clientId: selectClientId(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            clientGet: get,
        }, dispatch)
    })
)(injectIntl(ClientsViewComponent));