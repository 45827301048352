import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import {
    createStringDataField, createBucketStatusListField,
    createBucketNameListField
} from '../../../util/format';
import { StringEquators } from '../../../store/constants/pages';
import S3ListnersPageComponent from './S3ListnersPage';
import { getS3Listners } from '../../../store/actions/controlRoom';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { set } from '../../../store/actions/pages';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'desc' }];

const selectFields = createSelector(
    () => {
        return [
            createStringDataField('clientName', 'Client', { sortable: true, equators: StringEquators }),
            createBucketNameListField('bucketListeners', 'Bucket', { hideFromFilter: true, sortable: true }),
            createBucketStatusListField('bucketListeners', 'Enabled', { hideFromFilter: true, sortable: true }),
            //createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        ]
    }
);

export const selectS3Listners = (state) => state.controlRoom.s3Listners;

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`s3-listners-landing`] ? state.pages[`s3-listners-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`s3-listners-landing`] ? state.pages[`s3-listners-landing`].filter : null,
    selectSort,
    selectS3Listners,
);
export const S3ListnersPage = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            s3ListnersData: selectFilteredData(state),
            order: state.pages['s3-listners-landing'] ? state.pages['s3-listners-landing'].order : null,
            filter: state.pages['s3-listners-landing'] ? state.pages['s3-listners-landing'].filter : null,
            sort: selectSort(state, props),

            getS3ListnersPending: state.controlRoom.getS3Listners.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getS3Listners: getS3Listners,
            setFilter: (value) => set(`s3-listners-landing`, 'filter', value),
            setOrder: (value) => set(`s3-listners-landing`, 'order', value),
            setSort: (value) => set(`s3-listners-landing`, 'sort', value),
        }, dispatch)
    })
)(injectIntl(S3ListnersPageComponent));