import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import EnableDialogComponent from './EnableDialog';
import { setV2 } from '../../../../store/actions/pagesV2';
import { selectDeselectAll, setEnableDialog } from '../../../../store/actions/shoppables';

export const EnableDialog = connect(
    () => {
        const selectClaimIds = (state, props) => state.pagesV2['shoppable-landing'].selected;
        const selectEnableDialog = (state) => state.shoppables.enableDialog;

        const selectOpen = createSelector(
            selectEnableDialog,
            (enableDialog) => {
                if (enableDialog && enableDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectEnabledClick = createSelector(
            selectEnableDialog,
            (enableDialog) => {
                if (enableDialog && enableDialog?.isEnableClicked) {
                    return true
                }
                return false
            }
        )

        return (state, props) => ({
            open: selectOpen(state),
            isEnableClicked: selectEnabledClick(state),
            selectedItems: selectClaimIds(state, props),
            selectAllStatusPending: state.shoppables.all.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('shoppable-landing', 'selected', value),
            setEnableDialog: setEnableDialog,
            selectDeselectAll: selectDeselectAll
        }, dispatch)
    })
)(injectIntl(EnableDialogComponent));