import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,

    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import { Formik } from "formik";
import React from "react";
import { Loadable } from "../../../../common";

const useStyles = makeStyles(theme => ({
    textField: {
        width: "85%"
    },
    formControlAuto: {
        width: "85%"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    dropZone: {
        minHeight: 150,
        border: "none",
        backgroundColor: "#EAEAEA",
        borderRadius: "20px"
    },
    formControl: {
        width: "100%"
    },
    mt:{
        marginTop:'15px'
    },
    padding:{
        paddingTop:0
    }
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ActiveFacilitiesDialog(props) {
    const {
        intl,
        open,
        handleClose,
        initialValues,
        facilities,
        getBrandingDetails,
        getBrandingDetailsPending,
        handlePrint,
        actions: {}
    } = props;
    const classes = useStyles();


    const brandingDetailsCallback = () => {
        handleClose()
        handlePrint()
        
    }


    

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            Select Facility  
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers className={classes.padding}>
                <Loadable loading={getBrandingDetailsPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};

                            if (!values.facility) {
                                errors.facility = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                           getBrandingDetails(values?.facility?.id,brandingDetailsCallback)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                                className={classes.container}
                            >
                                <Grid container>
                                   
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.formControlAuto}
                                        >
                                        <Autocomplete
                                            required
                                            className={classes.autoComplete}
                                            value={values.facility}
                                            disableClearable={false}
                                            onChange={(e, value) => setFieldValue("facility", value)}
                                            getOptionSelected={(option, value) => option === value}
                                            name={`facility`}x
                                            options={facilities}
                                            getOptionLabel={(option) => `${option.name}`}
                                            renderInput={(params) =>
                                            <TextField {...params}
                                                name={`facility`}
                                                label="Facility*"
                                                error={Boolean(touched.facility && errors.facility)}
                                                helperText={touched.facility && errors.facility}
                                                margin="normal" />
                                            }
                                        />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        container
                                        className={`justify-content-end pt-2 ${classes.mt}`}
                                        
                                    >
                                        <Button
                                            type="button"
                                            onClick={handleClose}
                                            variant="contained"
                                            className="mr-3"
                                            color="default"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Submit  
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    );
}

export default ActiveFacilitiesDialog;
