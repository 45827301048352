import React, { useEffect } from 'react';
import {
    Dialog, DialogContent, DialogTitle, makeStyles, Grid,
    IconButton,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';

import { Loadable } from '../../../common';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { MODIFIER_TYPES } from '../../../store/constants/modifiers';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        width: '100%'
    }
}));

export default function ModifierEditDialog(props) {
    const { open, handleClose, initialValues, intl, mode,
        createModifierPending, updateModifierPending,
        actions: { createModifier, updateModifier, createEditReset }
    } = props;

    const classes = useStyles();

    useEffect(() => {
        if (open) {
        }
        return function cleanup() {
            createEditReset();
        };
    }, [open, createEditReset]);


    return (
        <Dialog fullWidth open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">{mode === 'edit' ? 'Edit' : 'Add'} Modifier </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={createModifierPending || updateModifierPending}>
                    <Grid>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validate={values => {
                                const errors = {};

                                if (!values.modifier) {
                                    errors.modifier = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (mode === 'create' && !values.modifierType) {
                                    errors.modifierType = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                let shouldReloadGrid = true
                                let payload = {
                                    modifier: values.modifier,
                                    description: values.description,
                                }
                                if (mode === 'create') {
                                    payload = {
                                        ...payload,
                                        modifierType: values.modifierType
                                    }
                                    createModifier(payload, handleClose, shouldReloadGrid);
                                }
                                else {
                                    payload = {
                                        ...payload,
                                        id: values.id,
                                    }
                                    updateModifier(payload, handleClose, shouldReloadGrid);
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                    <div className={classes.root}>
                                        <Grid container>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    required
                                                    label="Modifier Name"
                                                    name="modifier"
                                                    className={classes.textService}
                                                    margin="normal"
                                                    value={values.modifier}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.modifier && errors.modifier)}
                                                    helperText={touched.modifier && errors.modifier}
                                                />
                                            </Grid>
                                            {mode === 'create' &&
                                                <Grid item xs={12}>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel required htmlFor="role">Modifier Type</InputLabel>
                                                        <Select
                                                            required
                                                            value={values.modifierType}
                                                            onChange={handleChange}
                                                            error={Boolean(touched.modifierType && errors.modifierType)}
                                                            input={<Input id="modifierType" />}
                                                            inputProps={{
                                                                name: "modifierType",
                                                                id: "modifierType"
                                                            }}
                                                        >
                                                            {MODIFIER_TYPES.map(type => (
                                                                <MenuItem
                                                                    key={type}
                                                                    value={type}
                                                                >
                                                                    {type}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText error>{touched.modifierType && errors.modifierType}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            }
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    minRows={3}
                                                    multiline
                                                    label="Description"
                                                    name="description"
                                                    className={classes.textService}
                                                    margin="normal"
                                                    value={values.description}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.description && errors.description)}
                                                    helperText={touched.description && errors.description}
                                                />
                                            </Grid>
                                        </Grid>
                                        <hr />
                                        <Grid container justify="flex-end">
                                            <Button
                                                onClick={() => handleClose(false)}
                                                variant="contained"
                                                color="default"
                                                className={classes.button}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={`${classes.button} ml-3`}
                                                type="submit"
                                            >
                                                {(createModifierPending || updateModifierPending) ? 'Saving Changes' : 'Save'}
                                            </Button>
                                        </Grid>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}