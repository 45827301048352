import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ModifierEditDialogComponent from './ModifiersEditPage';
import { createEditReset, createModifier, updateModifier } from '../../../store/actions/modifiers';

export const ModifierEditDialog = connect(
    () => {
        const selectMode = (state, props) => {
            return props.modifier ? 'edit' : 'create';
        }

        const selectModifier = (state, props) => props.modifier;

        const selectInitialValues = createSelector(
            selectMode,
            selectModifier,
            (mode, savedModifier,) => {
                if (mode === 'edit' && savedModifier) {
                    let payload = {
                        id: savedModifier.id,
                        modifier: savedModifier.modifier,
                        description: savedModifier.description,
                        modifierType: savedModifier.modifierType
                    }
                    return payload;
                }

                return {
                    name: '',
                    description: '',
                    modifierType: ''
                }
            }
        )

        return (state, props) => ({
            mode: selectMode(state, props),
            initialValues: selectInitialValues(state, props),
            createModifierPending: state.modifiers.create.pending,
            updateModifierPending: state.modifiers.update.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            createModifier: createModifier,
            updateModifier: updateModifier,
            createEditReset: createEditReset
        }, dispatch)
    })
)(injectIntl(ModifierEditDialogComponent));