import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { deleteClaimByContractId, deleteClaimResetByContractReset } from '../../store/actions/claimAnalysis';
import { deleteReset, getStates, setContractDependencyDialog, setCopyContractDialog, setStateDialog, tiny } from '../../store/actions/contracts';
import { list as getFacilities } from "../../store/actions/facilities";
import { contractsLanding, resetPage } from '../../store/actions/pages';
import { getByName, reset } from '../../store/actions/search';
import { dateEquators, StringEquators } from '../../store/constants/pages';
import { selectContractStates } from '../../store/selectors/contracts';
import { createContractStateField, createDateField, createPlainDateField, createStringDataField, createStringFieldFromArrayMapping } from '../../util/format';
import ContractsPageComponent from './ContractsPage';

export const selectFacilityList = (state) => state.facilities.data;
export const selectContractsTiny = (state) => state.contracts.tiny;


const selectFields = createSelector(
    selectFacilityList,
    selectContractStates,
    selectContractsTiny,
    (facilitiesArray, contractStates,tinyContracts) => {
        return [
            createStringDataField('name', 'Contract Name', {
                sortable: true, equators: StringEquators,
                isDropdown: true, dropdownValues: tinyContracts?.map(contract => contract.name) || [],isMultiSelection:true
            }),
            createPlainDateField('effectiveDate', 'Effective Date', { sortable: true, equators: dateEquators }),
            createPlainDateField('terminationDate', 'Termination Date', { sortable: true, equators: dateEquators }),
            createDateField('finalizedDate', 'Finalized Date', { sortable: true, equators: dateEquators }),
            // createBooleanPayerDataField('isPayer', 'Type', { hideFromFilter: true }),
            createStringFieldFromArrayMapping('contractFacilityXref', 'Facilities', facilitiesArray, 'facilityId', { hideFromFilter: true, truncate: true }),
            createContractStateField('contractState.name', 'State', {
                secondLevel: 'name', sortable: true, equators: StringEquators, isStrictDropdown: true, hideFromFilter: true,
                dropdownValues: contractStates.map(item => ({ label: item.name, value: item.name }))
            })
        ]
    }
);

const selectContractsData = createSelector(
    (state) => state.pages['contracts-landing'].list,
    (data) => {
        return data
    }
);

export const ContractsPage = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            contractsData: selectContractsData(state),

            claimDeletePending: state.claimAnalysis.deleteByContract.pending,
            claimDeleteError: state.claimAnalysis.deleteByContract.error,
            claimDeleteErrorMessage: state.claimAnalysis.deleteByContract.errorMessage,
            states: selectContractStates(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            contractsLanding: contractsLanding,
            getFacilities: getFacilities,
            searchGetByName: getByName,
            searchReset: reset,
            deleteReset: deleteReset,
            getTinyContracts: tiny,
            deleteClaimByContractId: deleteClaimByContractId,
            deleteClaimReset: deleteClaimResetByContractReset,
            resetPagedTable: resetPage,
            getStates: getStates,
            setStateDialog: setStateDialog,
            setContractDependencyDialog: setContractDependencyDialog,
            setCopyContractDialog: setCopyContractDialog 
        }, dispatch)
    })
)(injectIntl(ContractsPageComponent));