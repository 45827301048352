import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import UploadDialogComponent from './ContractFamilyDialog';
import { createContractFamily } from '../../../store/actions/contractFamily';
import { tiny } from '../../../store/actions/contracts';


const selectContractsData = createSelector(
    (state) => state.pages['contracts-landing'].list,
    (data) => {
        return data
    }
);

export const ContractsFamilyDialog = connect(

    () => {
        const selectInitialValues = createSelector(
            () => {
                return {
                    contracts: [],
                    name: '',
                    description: ''
                }
            }
        )
        return (state, props) => ({
            initialValues: selectInitialValues(state),
            uploadFilePending: state.contractsFamily.create.pending,
            contractsData: selectContractsData(state),
            tinyContracts:state.contracts.tiny


        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getTinyContracts: tiny,
            createContractFamily: createContractFamily,
        }, dispatch)
    })
)(injectIntl(UploadDialogComponent));