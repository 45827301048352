import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import EnableDialogComponent from './EnableDialog';
import { setEnableDialog, updateStatus } from '../../../../../../../store/actions/feeScheduleItems';
import { setV2 } from '../../../../../../../store/actions/pagesV2';

export const EnableDialog = connect(
    () => {
        const selectClaimIds = (state, props) => state.pagesV2['feeschedule-items-landing'].selected;
        const selectEnableDialog = (state) => state.feeScheduleItems.enableDialog;

        const selectOpen = createSelector(
            selectEnableDialog,
            (enableDialog) => {
                if (enableDialog && enableDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectEnabledClick = createSelector(
            selectEnableDialog,
            (enableDialog) => {
                if (enableDialog && enableDialog?.isEnableClicked) {
                    return true
                }
                return false
            }
        )

        return (state, props) => ({
            open: selectOpen(state),
            isEnableClicked: selectEnabledClick(state),
            selectedItems: selectClaimIds(state, props),
            updateStatusPending: state.feeScheduleItems.updateStatus.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('feeschedule-items-landing', 'selected', value),
            setEnableDialog: setEnableDialog,
            feeScheduleItemUpdateStatus: updateStatus,
        }, dispatch)
    })
)(injectIntl(EnableDialogComponent));