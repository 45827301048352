import { createReducer } from '../../util';
import * as actions from '../actions/allowables';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    allowablesList: [],
    list: {
        ...initialStatusState,
    },
    updateStatus: {
        ...initialStatusState,
    },
    all: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {
    [actions.ALLOWABLES_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.ALLOWABLES_FULFILLED]: (state, allowablesList) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        allowablesList,
    }),
    [actions.ALLOWABLES_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.ALLOWABLES_UPDATE_STATUS_PENDING]: (state) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            pending: true,
        },
    }),
    [actions.ALLOWABLES_UPDATE_STATUS_FULFILLED]: (state, payload) => ({
        ...state,
        allowablesList: state.allowablesList.map(
            (allowable, i) => allowable.id === payload.id ? {...allowable, enabled: payload.enabled } : allowable //update allowable status on the base of ID
        ),
        updateStatus: {
            ...initialStatusState,
        },
    }),
    [actions.ALLOWABLES_UPDATE_STATUS_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.ALLOWABLES_SELECT_ALL_PENDING]: (state) => ({
        ...state,
        all: {
            pending: true,
        },
    }),
    [actions.ALLOWABLES_SELECT_ALL_FULFILLED]: (state) => ({
        ...state,
        all: {
            ...initialStatusState,
        }
    }),
    [actions.ALLOWABLES_SELECT_ALL_REJECTED]: (state, errorMessage) => ({
        ...state,
        all: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.ALLOWABLES_RESET]: () => ({...initialState }),
});