import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import ForgotPasswordComponent from './ForgotPassword.js';
import { forgotPassword } from '../../../store/actions/forgotPassword';
import { selectForgotPasswordPending, selectForgotPasswordError, selectForgotPasswordErrorMessage } from '../../../store/selectors/forgotPassword';

export const ForgotPasswordPage = injectIntl(
    connect(
        (state) => ({
            forgotPasswordPending: selectForgotPasswordPending(state),
            forgotPasswordError: selectForgotPasswordError(state),
            forgotPasswordErrorMessage: selectForgotPasswordErrorMessage(state),
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                forgotPassword: forgotPassword,
            }, dispatch)
        })
    )(ForgotPasswordComponent));