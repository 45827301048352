import {
    METHOD_PUT,
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
} from '../constants/api';
import {
    headersAuthSendReceiveJson, headersAuthReceiveJson
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getPayers = (options) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/payers/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updatePayerStatus = (id, enabled) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/payer/status`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify({ id, enabled }),
    }).then(apiHandleResponse);
};

const createPayer = (payer) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/payer`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payer)
    }).then(apiHandleResponse);
};

const updatePayer = (payer) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/payer`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payer)
    }).then(apiHandleResponse);
};

const createPayerContract = (payerContract) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/cms/payercontract`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payerContract)
    }).then(apiHandleResponse);
};

const updatePayerContract = (payerContract) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/cms/payercontract`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payerContract)
    }).then(apiHandleResponse);
};

const selectDeselectAll = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/payer/status/all`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload)
    }).then(apiHandleResponse);
};

const listPayerContracts = (options) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/cms/payercontracts`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getPayerContractsTiny = () => {
    return fetch(`${SERVER_URL}/api/v1/pricer/cms/payercontracts/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getPayers,
    updatePayerStatus,
    createPayer,
    updatePayer,
    selectDeselectAll,
    listPayerContracts,
    createPayerContract,
    updatePayerContract,
    getPayerContractsTiny,
};