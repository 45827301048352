import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/vendors';
import * as API from '../api/vendors';
import { sessionErrorHandling } from './session';

function* getVendorContract(action) {
    try {
        yield put({ type: actions.VENDORS_CONTRACT_GET_PENDING });
        const { contractId } = action.payload;
        const payload = yield call(API.getVendorContract, contractId);
        yield put({ type: actions.VENDORS_CONTRACT_GET_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_CONTRACT_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getVendorDepartments(action) {
    try {
        yield put({ type: actions.VENDORS_GET_DEPARTMENTS_PENDING });
        const { options } = action.payload;
        const payload = yield call(API.getInfoLookup, options);
        yield put({ type: actions.VENDORS_GET_DEPARTMENTS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_GET_DEPARTMENTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getVendorLocations(action) {
    try {
        yield put({ type: actions.VENDORS_GET_LOCATIONS_PENDING });
        const { options } = action.payload;
        const payload = yield call(API.getInfoLookup, options);
        yield put({ type: actions.VENDORS_GET_LOCATIONS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_GET_LOCATIONS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getVendorResourceTypes(action) {
    try {
        yield put({ type: actions.VENDORS_GET_RESOURCE_TYPES_PENDING });
        const { options } = action.payload;
        const payload = yield call(API.getInfoLookup, options);
        yield put({ type: actions.VENDORS_GET_RESOURCE_TYPES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_GET_RESOURCE_TYPES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getVendorGLCode(action) {
    try {
        yield put({ type: actions.VENDORS_GET_GL_CODE_PENDING });
        const { options } = action.payload;
        const payload = yield call(API.getInfoLookup, options);
        yield put({ type: actions.VENDORS_GET_GL_CODE_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_GET_GL_CODE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* vendorCreate(action) {
    try {
        yield put({ type: actions.VENDORS_CONTRACT_CREATE_PENDING });
        const {
            contract,
            callback,
        } = action.payload;

        const newContract = yield call(API.createVendorContract, contract);
        yield put({ type: actions.VENDORS_CONTRACT_CREATE_FULFILLED, newContract });

        if (callback) {
            callback(newContract.id);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_CONTRACT_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* vendorUpdate(action) {
    try {
        yield put({ type: actions.VENDORS_CONTRACT_UPDATE_PENDING });
        const {
            contract,
            callback
        } = action.payload;

        yield call(API.updateVendorContract, contract);
        yield put({ type: actions.VENDORS_CONTRACT_UPDATE_FULFILLED });

        if (callback) {
            callback(contract.id);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_CONTRACT_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* vendorUpload(action) {
    try {
        const { file, documentObj, callback } = action.payload;
        yield put({ type: actions.VENDORS_CONTRACT_UPLOAD_PENDING });
        const payload = yield call(API.uploadVendorDocument, file, documentObj);
        yield put({ type: actions.VENDORS_CONTRACT_UPLOAD_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_CONTRACT_UPLOAD_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getVendorDocument(action) {
    try {
        const { documentId, documentName, metadata, callback } = action.payload;
        yield put({ type: actions.VENDORS_CONTRACT_DOCUMENT_GET_PENDING });
        const payload = yield call(API.getVendorDocument, documentId);
        yield put({ type: actions.VENDORS_CONTRACT_DOCUMENT_GET_FULFILLED, payload });

        if (callback) {
            callback(payload, documentName, metadata);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_CONTRACT_DOCUMENT_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getVendorDocumentList(action) {
    try {
        const { contractId } = action.payload;
        yield put({ type: actions.VENDORS_CONTRACT_DOCUMENTS_LIST_PENDING });
        const payload = yield call(API.getVendorDocumentList, contractId);
        yield put({ type: actions.VENDORS_CONTRACT_DOCUMENTS_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_CONTRACT_DOCUMENTS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteVendor(action) {
    try {
        yield put({ type: actions.VENDORS_CONTRACT_DELETE_PENDING });
        const { id, callback } = action.payload;
        const payload = yield call(API.deleteVendor, id);
        yield put({ type: actions.VENDORS_CONTRACT_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.VENDORS_CONTRACT_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

// export function* reset() {
//     yield put({ type: actions.CLIENTS_RESET });
// }

export default function* () {
    yield takeEvery(actions.VENDORS_GET_DEPARTMENTS, getVendorDepartments);
    yield takeEvery(actions.VENDORS_GET_LOCATIONS, getVendorLocations);
    yield takeEvery(actions.VENDORS_GET_RESOURCE_TYPES, getVendorResourceTypes);
    yield takeEvery(actions.VENDORS_GET_GL_CODE, getVendorGLCode);

    yield takeEvery(actions.VENDORS_CONTRACT_GET, getVendorContract);
    yield takeEvery(actions.VENDORS_CONTRACT_DOCUMENTS_LIST, getVendorDocumentList);
    yield takeEvery(actions.VENDORS_CONTRACT_CREATE, vendorCreate);
    yield takeEvery(actions.VENDORS_CONTRACT_UPDATE, vendorUpdate);
    yield takeEvery(actions.VENDORS_CONTRACT_UPLOAD, vendorUpload);
    yield takeEvery(actions.VENDORS_CONTRACT_DOCUMENT_GET, getVendorDocument);
    yield takeEvery(actions.VENDORS_CONTRACT_DELETE, deleteVendor);

}