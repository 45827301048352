import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles, Grid,
    DialogActions, Button, Typography, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DataTable, DataFilter } from '../../../../../../common';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        height: 250,
        overflow: 'scroll',
    }
}));

export default function CarveoutCodesDialog(props) {
    const {
        open, handleClose, filteredData, fields, order, sort,
        title, ruleId, codeServiceDescriptionListPending,
        actions: { setSort, setOrder, setFilter, getcarveoutDescriptions }
    } = props;

    const classes = useStyles();

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    useEffect(() => {
        if (ruleId) {
            getcarveoutDescriptions(ruleId);
        }
    }, [ruleId]);


    return (
        <Dialog
            fullWidth
            scroll="paper"
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">{`${title} (${filteredData.length})`}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container justify="flex-end">
                            <DataFilter
                                onChange={handleSearchInput}
                                placeholder="Search"
                                cssClass={classes.textField}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.content}>
                    <DataTable
                        fields={fields}
                        data={filteredData}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        loading={codeServiceDescriptionListPending}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose(false)}
                    variant="contained"
                    color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}