import {
    METHOD_PUT,
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_DELETE,
} from '../constants/api';
import {
    headersAuthSendReceiveJson, headersAuthReceiveJson, headersAuthReceiveOctet, headersAuth
} from '../headers';
import { apiHandleOctetResponseWithFileName, apiHandleResponse } from '../../util/api';

const getCodeTypes = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/feescheduleItem/types`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createFeeScheduleItem = (feeScheduleItem) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/rate`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(feeScheduleItem)
    }).then(apiHandleResponse);
};

const updateFeeScheduleItem = (feeScheduleItem) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/rate`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(feeScheduleItem)
    }).then(apiHandleResponse);
};

const deleteFeeScheduleItems = (feeScheduleItemsIds) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/rates/delete`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(feeScheduleItemsIds),
    }).then(apiHandleResponse);
};

const updateFeeScheduleItemStatus = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/rate/status`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload)
    }).then(apiHandleResponse);
};

const importFeeScheduleRates = (payload) => {
    const { feeScheduleId, ratesCSV } = payload;
    const formData = new FormData();
    formData.append('ratesCSV', ratesCSV);

    return fetch(`${SERVER_URL}/api/v2/cms/import/feeschedulerates/${feeScheduleId}`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: formData,
    }).then(apiHandleResponse);
};

const exportFeeScheduleRates = (feeScheduleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/export/feeschedulerates/${feeScheduleId}`, {
        method: METHOD_POST,
        headers: headersAuthReceiveOctet(),
    }).then(apiHandleOctetResponseWithFileName);
};

const deleteFeeSchedule = (feeScheduleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/${feeScheduleId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getFeeScheduleOfClient = (targetClient) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/list/${targetClient}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getFeeScheduleDependencies = (feeScheduleId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/dependencies/${feeScheduleId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getFeescheduleItem = (feeScheduleItemId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/rate/${feeScheduleItemId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getFeescheduleRateLabel = (feeScheduleId, labelId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/rate/validate/${feeScheduleId}/${labelId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    createFeeScheduleItem,
    updateFeeScheduleItem,
    deleteFeeScheduleItems,
    updateFeeScheduleItemStatus,
    getCodeTypes,
    importFeeScheduleRates,
    exportFeeScheduleRates,
    deleteFeeSchedule,
    getFeeScheduleOfClient,
    getFeeScheduleDependencies,
    getFeescheduleItem,
    getFeescheduleRateLabel
};