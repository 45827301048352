import React, { useEffect } from "react";
import { Formik } from "formik";
import { Loadable } from '../../../common';
import { Dialog, DialogTitle, Typography, IconButton, DialogContent } from '@material-ui/core';

import {
    Button, makeStyles, TextField, Grid,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1)
    },
}));

export default function DirectoryEditDialog(props) {

    const {
        initialValues, parentId, directoryId, intl, open, createPending, updatePending, mode, handleRefresh,
        getPending,
        actions: { setDirectoryEditDialog, createDirectory, updateDirectory } } = props;

    const classes = useStyles();
    const isEdit = mode === 'edit';


    // useEffect(() => {
    //     if (isEdit && open) {
    //         getFeeschedule(directoryId);
    //     }
    // }, [open, directoryId, getFeeschedule, isEdit]);

    const handleEditCallback = (id) => {
        handleDialogClose();
        handleRefresh();
    }

    const handleDialogClose = () => {
        setDirectoryEditDialog(null);
    }

    return (
        <Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={createPending || updatePending || getPending}>
                <DialogTitle>
                    <Grid container alignItems="center">
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Folder
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className="text-right">
                            <IconButton className="p-2" aria-label="close" onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};

                            if (!values.name) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }

                        }
                        onSubmit={(values) => {
                            let payload = {
                                name: values.name,
                            }
                            if (parentId !== 'root') {
                                payload = { ...payload, parentId }
                            }
                            if (mode === 'create') {
                                createDirectory(payload, handleEditCallback);
                            }
                            else {
                                payload = {
                                    ...payload,
                                    id: directoryId,
                                }
                                updateDirectory(payload, handleEditCallback);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            dirty
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <div className="root">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                label="Name"
                                                name="name"
                                                className={classes.textField}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                helperText={touched.name && errors.name}
                                                error={Boolean(touched.name && errors.name)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid className="d-flex justify-content-end">
                                        <Button variant="contained" onClick={handleDialogClose} color="default">
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className='ml-3'
                                            type="submit"
                                            disabled={createPending || updatePending || (!dirty && isEdit)}
                                        >
                                            {(createPending || updatePending) ? 'Saving..' : 'Save'}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Loadable >
        </Dialog>

    );
}