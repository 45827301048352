import { createSelector } from 'reselect';

export const selectDashboardTypeList = (state) => state.dashboardTypes.data;
export const selectDashboardTypeFieldsList = (state) => state.dashboardTypes.fields;

export const selectDataTypes = createSelector(
    () => (
        ['String', 'Integer', 'Number', 'Datetime']
    )
);

export const selectValueTypes = createSelector(
    () => (
        ['Single', 'Multiple']
    )
);

export const selectDashboardTypes = createSelector(
    selectDashboardTypeList,
    (dashboardTypes) => dashboardTypes,
);

export const createDashboardTypeSelector = (selectDashboardTypeId) => createSelector(
    selectDashboardTypeId,
    selectDashboardTypes,
    (dashboardTypeId, dashboardTypes) => dashboardTypes.find((dashboardType) => dashboardType.id === parseInt(dashboardTypeId))
);

export const selectDashboardTypeFields = createSelector(
    selectDashboardTypeFieldsList,
    (dashboardTypeFields) => dashboardTypeFields,
);