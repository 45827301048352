/**
 * Utility method to create a reducer from a map,
 * with initial state
 * @param initialState
 * @param reducerMap
 * @returns {Function}
 */

export * from './faro'

interface Action<P = any> {
    type: string;
    payload: P;
}

interface ReducerMap<S> {
    [key: string]: (state: S, payload: any) => S;
}

export function createReducer<S>(initialState: S, reducerMap: ReducerMap<S>) {
    return (state: S = initialState, action: Action): S => {
        const reducer = reducerMap[action.type];

        return reducer
            ? reducer(state, action.payload)
            : state;
    };
}

/**
 * function to return the username from email address
 * @param email 
 * @returns username
 */
export function decodeEmail(email: string) {
    try {
        return email.replace(/@[^@]+$/, '');
    }
    catch {
        return email;
    }
}

export function extractMultipleValues(value: string) {
    if (value) {
        return value.split('\n').join(',');
    }
    return '';
}

export function extractCommentData(data: string) {
    if (data) {
        const splitedString = data.split("\n");
        const comments = splitedString.map(comment => {
            const splitedComment = comment.split("]");
            return {
                name: splitedComment[0]?.replace("[", ""),
                date: splitedComment[1]?.replace("[", ""),
                text: splitedComment[2]
            }
        });
        return comments;
    }
    return [];
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function reorderArray(array: any[], oldIndex: any, newIndex: number) {
    if (newIndex >= array.length) {
        newIndex = array.length - 1;
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
}

export function prependZero(num: string | number) {
    return ('0' + num).slice(-2);
} 