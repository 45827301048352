import { createReducer } from '../../util';
import * as actions from '../actions/dashboards';
import { StatusState } from './common';

const addObjectIfNotExist = (state, dashboard) => {
    const data = state.data;
    const { dashboardId } = dashboard;

    const dashboardExist = data.find(x => x.dashboardId === dashboardId);
    if (!dashboardExist) {
        return {
            data: [...state.data, dashboard]
        }
    }
};

const initialStatusState : StatusState  = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    selectedClient: null, //this is for super admin to get list of dashboards of selected client
    selectedClientState: {
        ...initialStatusState
    },
    data: [], // this is for listing dashboards for admin to edit.
    iframeData: [], // this is for listing dashboards based on clientdid
    url: {},
    filters: {},
    fieldValues: {}, // array to fill the fieldValues
    tinyList: [],
    list: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    getUrl: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getFieldValue: {
        ...initialStatusState,
    },
    tiny: {
        ...initialStatusState,
    },
};

export default createReducer(initialState, {

    [actions.DASHBOARD_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_LIST_FULFILLED]: (state, dashboards) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: dashboards,
    }),
    [actions.DASHBOARD_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_LIST_TINY_PENDING]: (state) => ({
        ...state,
        tiny: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_LIST_TINY_FULFILLED]: (state, dashboards) => ({
        ...state,
        tiny: {
            ...initialStatusState,
        },
        tinyList: [
            ...dashboards,
        ]
    }),
    [actions.DASHBOARD_LIST_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        tiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_GET_FULFILLED]: (state, dashboard) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, dashboard),
    }),
    [actions.DASHBOARD_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_GET_URL_PENDING]: (state) => ({
        ...state,
        getUrl: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_GET_URL_FULFILLED]: (state, dashboardObj) => ({
        ...state,
        getUrl: {
            ...initialStatusState,
        },
        url: dashboardObj,
    }),
    [actions.DASHBOARD_GET_URL_REJECTED]: (state, errorMessage) => ({
        ...state,
        getUrl: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.DASHBOARD_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.DASHBOARD_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.DASHBOARD_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_LIST_BY_CLIENT_ID_PENDING]: (state) => ({
        ...state,
        listByClientId: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_LIST_BY_CLIENT_ID_FULFILLED]: (state, dashboards) => ({
        ...state,
        listByClientId: {
            ...initialStatusState,
        },
        iframeData: dashboards,
    }),
    [actions.DASHBOARD_SWITCH_LIST_BY_CLIENT_ID]: (state, dashboards) => ({
        ...state,
        selectedClient: dashboards.clientId,
    }),
    [actions.DASHBOARD_LIST_BY_CLIENT_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        listByClientId: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_GET_FIELD_VALUES_PENDING]: (state, payload) => ({
        ...state,
        getFieldValue: {
            ...state.getFieldValue,
            [`getFieldValue-${payload}`]: {
                pending: true,
            },
        }
    }),
    [actions.DASHBOARD_GET_FIELD_VALUES_FULFILLED]: (state, { payload, key }) => ({
        ...state,
        getFieldValue: {
            ...state.getFieldValue,
            [`getFieldValue-${key}`]: {
                ...initialStatusState,
            },
        },
        fieldValues: {
            ...state.fieldValues,
            [key]: payload,
        }
    }),
    [actions.DASHBOARD_GET_FIELD_VALUES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFieldValue: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_SET_FILTERS]: (state, { key, value }) => ({
        ...state,
        filters: {
            ...state.filters,
            [key]: value,
        }
    }),
    [actions.DASHBOARD_RESET]: () => ({ ...initialState }),
    [actions.DASHBOARD_URL_RESET]: (state) => ({
        ...state,
        url: {},
        filters: {},
    }),
    [actions.DASHBOARD_TINY_RESET]: (state) => ({
        ...state,
        tinyList: [],
    }),
});