import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { setDependencyDialog, deletePartner, getPartnerDependency } from '../../../../store/actions/partners';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.partners.partnerDependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (partnerDependencyDialog) => {
                if (partnerDependencyDialog && partnerDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectPartnerId = createSelector(
            selectDependencyDialog,
            (partnerDependencyDialog) => {
                if (partnerDependencyDialog && partnerDependencyDialog?.partnerId) {
                    return partnerDependencyDialog?.partnerId
                }
                return null
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            partnerId: selectPartnerId(state, props),
            dependency: state.partners.dependency?.records ?? [],
            canDelete: state.partners.dependency?.canDelete,
            partnerDependencyPending: state.partners.getDependencies.pending,
            deletePartnerPending: state.partners.delete.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setDependencyDialog: setDependencyDialog,
            getPartnerDependency: getPartnerDependency,
            deletePartner: deletePartner
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));