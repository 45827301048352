import { createReducer } from '../../util';
import * as actions from '../actions/drg';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    drg: null,
    tiny: [],
    ippsByContract: [],
    ippsByTargetClient: [],
    dependencyDialog: null,
    dependencies: [],
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getDRGTiny: {
        ...initialStatusState,
    },
    getIPPSTinyByContract: {
        ...initialStatusState,
    },
    getIPPSTinyByTargetClient: {
        ...initialStatusState,
    },
    getIPPSDependencies: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {

    [actions.DRG_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.DRG_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
    }),
    [actions.DRG_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.DRG_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.DRG_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
    }),
    [actions.DRG_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.DRG_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.DRG_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.DRG_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.DRG_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.DRG_GET_FULFILLED]: (state, drg) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        drg
    }),
    [actions.DRG_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.DRG_GET_TINY_PENDING]: (state) => ({
        ...state,
        getDRGTiny: {
            pending: true,
        },
    }),
    [actions.DRG_GET_TINY_FULFILLED]: (state, tiny) => ({
        ...state,
        getDRGTiny: {
            ...initialStatusState,
        },
        tiny
    }),
    [actions.DRG_GET_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDRGTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DRG_CREATE_UPDATE_RESET]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
        create: {
            ...initialStatusState,
        },
    }),
    [actions.IPPS_GET_TINY_BY_CONTRACT_PENDING]: (state) => ({
        ...state,
        getIPPSTinyByContract: {
            pending: true,
        },
    }),
    [actions.IPPS_GET_TINY_BY_CONTRACT_FULFILLED]: (state, ippsByContract) => ({
        ...state,
        getIPPSTinyByContract: {
            ...initialStatusState,
        },
        ippsByContract
    }),
    [actions.IPPS_GET_TINY_BY_CONTRACT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getIPPSTinyByContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.IPPS_GET_TINY_BY_TARGET_CLIENT_PENDING]: (state) => ({
        ...state,
        getIPPSTinyByTargetClient: {
            pending: true,
        },
    }),
    [actions.IPPS_GET_TINY_BY_TARGET_CLIENT_FULFILLED]: (state, ippsByTargetClient) => ({
        ...state,
        getIPPSTinyByTargetClient: {
            ...initialStatusState,
        },
        ippsByTargetClient
    }),
    [actions.IPPS_GET_TINY_BY_TARGET_CLIENT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getIPPSTinyByTargetClient: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.IPPS_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),

    [actions.IPPS_RESET_DEPENDENCIES]: (state) => ({
        ...state,
        dependencies: []
    }),

    [actions.IPPS_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getIPPSDependencies: {
            pending: true,
        },
    }),
    [actions.IPPS_DELETE_DEPENDENCIES_FULFILLED]: (state, dependencies) => ({
        ...state,
        getIPPSDependencies: {
            ...initialStatusState,
        },
        dependencies
    }),
    [actions.IPPS_DELETE_DEPENDENCIES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getIPPSDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});
