import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import PayerContractAnalysisDialogComponent from './PayerContractAnalysisDialog';
import { claimExecute, claimExecuteCount } from '../../../store/actions/executions';

export const PayerContractAnalysisDialog = connect(
    () => {
        const selectPayerContract = (state, props) => props.payerContract;

        const selectInitialValues = createSelector(
            selectPayerContract,
            (payerContract) => {
                if (payerContract) {
                    const payload = {
                        payerContractId: payerContract.id,
                        startServiceDate: '',
                        endServiceDate: '',
                        startRemitDate: '',
                        endRemitDate: '',
                        dateType: '',
                        visitType: 'all',
                        equator: '',
                        startsAt: '',
                        endsAt: '',
                        svcRevenuecode: '',
                        drg: '',
                        pxcode: '',
                        noListed: false,
                        patientlastName: '',
                        claimAmount: ''
                    }
                    return payload;
                }

                return {
                    payerContractId: 0,
                    startServiceDate: '',
                    endServiceDate: '',
                    startRemitDate: '',
                    endRemitDate: '',
                    dateType: '',
                    visitType: 'all',
                    equator: '',
                    startsAt: '',
                    endsAt: '',
                    svcRevenuecode: null,
                    drg: null,
                    pxcode: '',
                    noListed: false,
                    patientlastName: '',
                    claimAmount: ''
                }
            }
        )

        return (state, props) => ({
            //get count
            claimAnalysisCountPending: state.executions.getExecuteCount.pending,

            //exeute
            claimAnalysisExecutePending: state.executions.claimExecute.pending,

            // edit
            initialValues: selectInitialValues(state, props),
            payerContract: selectPayerContract(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            claimExecuteCount: claimExecuteCount,
            claimExecute: claimExecute,
        }, dispatch)
    })
)(injectIntl(PayerContractAnalysisDialogComponent));