import React, { useState, useEffect } from 'react';
import {
    makeStyles, Tooltip, IconButton,
} from '@material-ui/core';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { PagedTable } from '../../common';
import EditIcon from '@material-ui/icons/Edit';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { DependencyDialog } from './DependencyDialog';

const DEFAULT_SORT = [];

const useStyles = makeStyles((theme) => ({
}));

export default function EAPGPage(props) {
    const { fields, data, history,
        actions: {
            eapgLanding, setDependencyDialog,
            createEditReset
        }
    } = props;

    const [refresh, setRefresh] = useState(Promise.resolve());

    const classes = useStyles();

    function handleLoad(sort, filter, pagination) {
        eapgLanding(pagination, sort, filter);
    }

    useEffect(() => {
        return () => {
            createEditReset();
        }
    }, [createEditReset]);

    const handleRefresh = () => {
        setRefresh(Promise.resolve());
    }

    const handleAddEAPG = () => {
        history.push('/cms/eapg/edit/new');
    }

    const handleEditEAPG = (rowData) => {
        history.push(`/cms/eapg/edit/${rowData.id}`);
    }

    const handleDeleteEAPG = (rowData) => {
        setDependencyDialog(
            {
                eapgId: rowData.id,
                open: true,
                name: rowData.name
            }
        )
    }

    function eapgActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleEditEAPG(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
            <IconButton
                onClick={() => handleDeleteEAPG(rowData)}
                color={"primary"}
                className='p-1'
                aria-label="Delete"
            >
                <Tooltip title={"Delete EAPG"} >
                    <DeleteIcon />
                </Tooltip>
            </IconButton>
        </div>
    }

    return (
        <>
            <Helmet title="EAPG Configuration" />
            <Portlet>
                <PortletHeader
                    title="EAPG Configuration"
                    handleRefresh={handleRefresh}
                    name="eapg-landing"
                    showSearchFilters={false}
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="eapg-landing"
                                title="eapg"
                                fields={fields}
                                data={data}
                                onLoad={handleLoad}
                                onAdd={handleAddEAPG}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                criteriasSaveEnabled={false}
                                hasActions={true}
                                renderActionColumn={eapgActions}
                                showSearchFilters={false}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <DependencyDialog handleRefresh={handleRefresh} />
        </>
    );
}
