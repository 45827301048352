import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { createEAPG, editEAPG, getEAPG, getEAPGVersions } from '../../../store/actions/eapg';
import { asIsoDate } from '../../../util/date';
import EAPGEditPageComponent from './EAPGEditPage';

const selectMode = (state, props) => props.match.params.id === 'new' ? 'create' : 'edit';
const selectEAPGId = (state, props) => props.match.params.id;
const selectEAPG = (state) => state.eapg.eapg;

export const EAPGEditPage = injectIntl(
    connect(() => {

        const selectEAPGVersions = (state) => state.eapg.eapgVersions;

        const selectInitialValues = createSelector(
            selectMode,
            selectEAPG,
            (mode, eapg) => {
                if (mode === 'edit' && eapg) {
                    return {
                        name: eapg.name,
                        significantProcedureRate: eapg.significantProcedureRate,
                        ancillaryRate: eapg.ancillaryRate,
                        effectiveFrom: asIsoDate(eapg.effectiveFrom),
                        effectiveTill: asIsoDate(eapg.effectiveTill),
                        eapgVersionId: eapg.eapgVersionId
                    }
                }
                return {
                    name: '',
                    significantProcedureRate: 1,
                    ancillaryRate: 1,
                    effectiveFrom: '',
                    effectiveTill: '',
                    eapgVersionId: ''
                }
            }
        )
        return (state, props) => ({
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            createPending: state.eapg.create.pending,
            editPending: state.eapg.update.pending,
            getPending: state.eapg.get.pending,
            eapgId: selectEAPGId(state, props),
            eapgVersions: selectEAPGVersions(state),
            eapgVersionsPending: state.eapg.getEAPGVersions.pending
        })
    },
        (dispatch) => ({
            actions: bindActionCreators({
                createEAPG: createEAPG,
                getEAPG: getEAPG,
                editEAPG: editEAPG,
                getEAPGVersions: getEAPGVersions
            }, dispatch)
        })
    )(EAPGEditPageComponent));