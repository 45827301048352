export const ASSOCIATED_LIST = 'ASSOCIATED_LIST';
export const ASSOCIATED_PENDING = 'ASSOCIATED_PENDING';
export const ASSOCIATED_FULFILLED = 'ASSOCIATED_FULFILLED';
export const ASSOCIATED_REJECTED = 'ASSOCIATED_REJECTED';

export const ASSOCIATED_ERROR_RESET = 'ASSOCIATED_ERROR_RESET';
export const ASSOCIATED_RESET = 'ASSOCIATED_RESET';

export const ASSOCIATED_UPDATE_STATUS = 'ASSOCIATED_UPDATE_STATUS';
export const ASSOCIATED_UPDATE_STATUS_PENDING = 'ASSOCIATED_UPDATE_STATUS_PENDING';
export const ASSOCIATED_UPDATE_STATUS_FULFILLED = 'ASSOCIATED_UPDATE_STATUS_FULFILLED';
export const ASSOCIATED_UPDATE_STATUS_REJECTED = 'ASSOCIATED_UPDATE_STATUS_REJECTED';

export const ASSOCIATED_SELECT_ALL = 'ASSOCIATED_SELECT_ALL';
export const ASSOCIATED_SELECT_ALL_PENDING = 'ASSOCIATED_SELECT_ALL_PENDING';
export const ASSOCIATED_SELECT_ALL_FULFILLED = 'ASSOCIATED_SELECT_ALL_FULFILLED';
export const ASSOCIATED_SELECT_ALL_REJECTED = 'ASSOCIATED_SELECT_ALL_REJECTED';

export const ASSOCIATED_REFRESH = 'ASSOCIATED_REFRESH';
export const ASSOCIATED_REFRESH_PENDING = 'ASSOCIATED_REFRESH_PENDING';
export const ASSOCIATED_REFRESH_FULFILLED = 'ASSOCIATED_REFRESH_FULFILLED';
export const ASSOCIATED_REFRESH_REJECTED = 'ASSOCIATED_REFRESH_REJECTED';

export const ASSOCIATED_SET_ENABLE_DIALOG = 'ASSOCIATED_SET_ENABLE_DIALOG';

export const getList = (from, to) => {
    return {
        type: ASSOCIATED_LIST,
        payload: { from, to },
    }
};

export const updateStatus = (id, enabled) => {
    return {
        type: ASSOCIATED_UPDATE_STATUS,
        payload: { id, enabled },
    }
};

export const errorReset = (...errorKeys) => ({
    type: ASSOCIATED_ERROR_RESET,
    payload: { errorKeys },
});

export const selectDeselectAll = (payload, callback) => ({
    type: ASSOCIATED_SELECT_ALL,
    payload: { payload, callback },
});

export const setEnableDialog = (enableDialog) => ({
    type: ASSOCIATED_SET_ENABLE_DIALOG,
    payload: { enableDialog }
});

export const refreshAssociatedCharges = (options, callback) => ({
    type: ASSOCIATED_REFRESH,
    payload: { options, callback }
});