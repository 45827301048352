export const ROLES_LIST = 'ROLES_LIST';
export const ROLES_LIST_PENDING = 'ROLES_LIST_PENDING';
export const ROLES_LIST_FULFILLED = 'ROLES_LIST_FULFILLED';
export const ROLES_LIST_REJECTED = 'ROLES_LIST_REJECTED';

// export const ROLES_GET = 'ROLES_GET';
// export const ROLES_GET_PENDING = 'ROLES_GET_PENDING';
// export const ROLES_GET_FULFILLED = 'ROLES_GET_FULFILLED';
// export const ROLES_GET_REJECTED = 'ROLES_GET_REJECTED';

export const ROLES_PERMISSIONS_GET = 'ROLES_PERMISSIONS_GET';
export const ROLES_PERMISSIONS_GET_PENDING = 'ROLES_PERMISSIONS_GET_PENDING';
export const ROLES_PERMISSIONS_GET_FULFILLED = 'ROLES_PERMISSIONS_GET_FULFILLED';
export const ROLES_PERMISSIONS_GET_REJECTED = 'ROLES_PERMISSIONS_GET_REJECTED';

export const ROLES_PERMISSION_ROLE_ID = 'ROLES_PERMISSION_ROLE_ID';
export const ROLES_PERMISSION_ROLE_ID_PENDING = 'ROLES_PERMISSION_ROLE_ID_PENDING';
export const ROLES_PERMISSION_ROLE_ID_FULFILLED = 'ROLES_PERMISSION_ROLE_ID_FULFILLED';
export const ROLES_PERMISSION_ROLE_ID_REJECTED = 'ROLES_PERMISSION_ROLE_ID_REJECTED';

export const ROLES_PERMISSION_CREATE = 'ROLES_PERMISSION_CREATE';
export const ROLES_PERMISSION_CREATE_PENDING = 'ROLES_PERMISSION_CREATE_PENDING';
export const ROLES_PERMISSION_CREATE_FULFILLED = 'ROLES_PERMISSION_CREATE_FULFILLED';
export const ROLES_PERMISSION_CREATE_REJECTED = 'ROLES_PERMISSION_CREATE_REJECTED';

export const ROLES_PERMISSION_UPDATE = 'ROLES_PERMISSION_UPDATE';
export const ROLES_PERMISSION_UPDATE_PENDING = 'ROLES_PERMISSION_UPDATE_PENDING';
export const ROLES_PERMISSION_UPDATE_FULFILLED = 'ROLES_PERMISSION_UPDATE_FULFILLED';
export const ROLES_PERMISSION_UPDATE_REJECTED = 'ROLES_PERMISSION_UPDATE_REJECTED';

export const ROLES_ERROR_RESET = 'ROLES_ERROR_RESET';
export const ROLES_RESET = 'ROLES_RESET';

export const list = () => ({
    type: ROLES_LIST,
});

// list of permissions.
export const getPermission = (callback) => ({
    type: ROLES_PERMISSIONS_GET,
    payload: { callback },
});

export const getPermissionByRoleId = (roleId, callback) => ({
    type: ROLES_PERMISSION_ROLE_ID,
    payload: { roleId, callback },
});

export const reset = () => ({
    type: ROLES_RESET
});

export const errorReset = (...errorKeys) => ({
    type: ROLES_ERROR_RESET,
    payload: { errorKeys },
});

export const updatePermission = (options, callback) => ({
    type: ROLES_PERMISSION_UPDATE,
    payload: { options, callback }
});

export const createPermission = (options, callback) => ({
    type: ROLES_PERMISSION_CREATE,
    payload: { options, callback }
});