import React, { useState, useEffect } from 'react';
import {
    makeStyles, Tooltip, IconButton, Grid, Tabs, AppBar, Tab
} from '@material-ui/core';
import Helmet from 'react-helmet';
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";
import { PagedTable } from '../../../common';
import classNames from 'classnames';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { TrimStatsPage } from '../StatisticsPage/TrimStatsPage';
import { PayerStatisticsPage } from '../../PayerStatisticsPage';
import { ClaimsAndRemitsPage } from '../StatisticsPage/Claims&RemitsPage';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    tabs: {
        borderBottom: '1px solid #ebedf2' 
    },
    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function StatisticsTabPage(props) {

    const [tab, setTab] = React.useState(0);
    const classes = useStyles();

    function a11yProps(index) {
        return {
            id: `vertical-stats-tab-${index}`,
            'aria-controls': `vertical-stats-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Helmet title="Stats" />
            <Portlet>
                <PortletBody>
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        aria-label="Stats Tab"
                        className={classes.tabs}
                        >
                        <Tab label="Files" {...a11yProps(0)} />
                        <Tab label="Claim Payers" {...a11yProps(1)} />
                        <Tab label="Remit Payers" {...a11yProps(2)} />
                        <Tab label="CLAIMS & REMITS" {...a11yProps(3)} />
                    </Tabs>
                    {tab === 0 &&
                        <>
                            <TrimStatsPage />
                        </>
                    }
                    {(tab === 1 || tab === 2) &&
                        <>
                            <PayerStatisticsPage tab={tab} />
                        </>
                    }
                    {tab === 3 &&
                        <>
                            <ClaimsAndRemitsPage />
                        </>
                    }
                </PortletBody>
            </Portlet>
        </>
    );
}