import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set } from '../../../store/actions/pages';
import { list, deleteService, setRulesDialog, setCodeServiceDependencyDialog } from '../../../store/actions/services';
import { createStringDataField, createSecondLevelField } from '../../../util/format';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { selectCodeServices } from "../../../store/selectors/services";
import CodeServicesComponent from './CodeServices';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    (state) => [
        createStringDataField('name', 'Service Name', { sortable: true }),
        createStringDataField('serviceType', 'Service Type', { sortable: true }),
        createSecondLevelField('primaryRuleMethod', 'Primary CodeType', 'name'),
        createStringDataField('primaryCodes', 'Primary Codes', { onClick: true, width: 50 }),
        createSecondLevelField('qualifierRuleMethod', 'Qualifier CodeType', 'name'),
        createStringDataField('qualifierCodes', 'Qualifier Codes', { onClick: true, width: 50 }),
        createStringDataField('description', 'Description'),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`code-services-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`code-services-landing`].filter,
    selectSort,
    selectCodeServices,
);

export const CodeServicesPage = connect(
    () => {
        return (state, props) => ({
            codeServicePending: state.services.get.pending,
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            order: state.pages[`code-services-landing`].order,
            filter: state.pages[`code-services-landing`].filter,
            sort: selectSort(state, props),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getCodeServices: list,
            setFilter: (value) => set(`code-services-landing`, 'filter', value),
            setOrder: (value) => set(`code-services-landing`, 'order', value),
            setSort: (value) => set(`code-services-landing`, 'sort', value),
            setRulesDialog: setRulesDialog,
            setCodeServiceDependencyDialog: setCodeServiceDependencyDialog
        }, dispatch)
    })
)(injectIntl(CodeServicesComponent));