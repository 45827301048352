import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import FilterColumnsComponent from './FilterColumns';

export const FilterColumns = (connect(
    () => {

        const selectOpen = (state, props) => state.pagesV2[props.pageName]?.isColumnDialogOpen ?? false

        return (state, props) => ({
            open: selectOpen(state, props)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(FilterColumnsComponent))); 