import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import ModelResultsDetailsPageComponent from './ModelResultsDetailsPage';
import { bindActionCreators } from 'redux';
import { tiny } from '../../../../../store/actions/contracts';
import { getModelAllDetails, modelAnalysisLanding } from '../../../../../store/actions/modeling';


const selectModelAnalysisId1 = (state, props) => props.match.params.modelAnalysisId1;
const selectModelAnalysisId2 = (state, props) => props.match.params.modelAnalysisId2;

export const ModelResultsDetailsPage = connect(
    () => {

        return (state, props) => ({
            claimIds: `${selectModelAnalysisId1(state, props)}/${selectModelAnalysisId2(state, props)}`,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getModelAllDetails: getModelAllDetails,
            modelAnalysisLanding: modelAnalysisLanding
        }, dispatch)
    })
)(injectIntl(ModelResultsDetailsPageComponent));