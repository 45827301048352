import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import HeaderComponent from './Header.js';
import { createSelector } from 'reselect';

export const Header = (connect(
    () => {

        const selectTable = createSelector(
            (state, props) => props.pageName,
            (state) => state.pagesV2,
            (name, pages) => pages[name]
        );

        const selectSelected = createSelector(
            selectTable,
            (table) => table?.selected,
        );

        const isSelectAllChecked = createSelector(
            selectSelected,
            (state, props) => props.data,
            (selectedData, totalData) => {
                if (selectedData?.length === totalData.length) {
                    return true;
                }
                return false;
            }
        )

        return (state, props) => ({
            selectAllChecked: isSelectAllChecked(state, props)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(HeaderComponent))); 