import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { getPayerContractDependency, setPayerContractDependencyDialog, deleteRemitPayer, deleteBulk } from '../../../store/actions/payers';
import { setV2 } from '../../../store/actions/pagesV2';

export const DependencyDialog = connect(
    () => {
        const selectClaimIds = (state, props) => state.pagesV2['payers-contracts-landing'].selected;
        const selectDependencyDialog = (state) => state.payers.payerContractDependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (payerContractDependencyDialog) => {
                if (payerContractDependencyDialog && payerContractDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            selectedItems: selectClaimIds(state, props),
            payerContractsDependency: state.payers.payerContractsDependency.records ?? [],
            canDelete: state.payers.payerContractsDependency.canDelete,
            payerContractDependencyPending: state.payers.getPayerContractDependency.pending,
            deletePayerContractsPending: state.payers.deleteBulk.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('payers-contracts-landing', 'selected', value),
            setPayerContractDependencyDialog: setPayerContractDependencyDialog,
            getPayerContractDependency: getPayerContractDependency,
            deleteBulk: deleteBulk
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));