export const PAGES_SET = 'PAGES_SET';
export const PAGES_SET_MULTIPLE = 'PAGES_SET_MULTIPLE';

export const CONTRACTS_LANDING = 'CONTRACTS_LANDING';
export const CONTRACTS_LANDING_PENDING = 'CONTRACTS_LANDING_PENDING';
export const CONTRACTS_LANDING_FULFILLED = 'CONTRACTS_LANDING_FULFILLED';
export const CONTRACTS_LANDING_REJECTED = 'CONTRACTS_LANDING_REJECTED';

export const CONTRACTS_FAMILY_LANDING = 'CONTRACTS_FAMILY_LANDING';
export const CONTRACTS_FAMILY_LANDING_PENDING = 'CONTRACTS_FAMILY_LANDING_PENDING';
export const CONTRACTS_FAMILY_LANDING_FULFILLED = 'CONTRACTS_FAMILY_LANDING_FULFILLED';
export const CONTRACTS_FAMILY_LANDING_REJECTED = 'CONTRACTS_FAMILY_LANDING_REJECTED';

export const CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING = 'CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING';
export const CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_PENDING = 'CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_PENDING';
export const CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_FULFILLED = 'CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_FULFILLED';
export const CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_REJECTED = 'CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_REJECTED';

export const CONTRACTS_CONTRACTED_RATES_LANDING = 'CONTRACTS_CONTRACTED_RATES_LANDING';
export const CONTRACTS_CONTRACTED_RATES_LANDING_PENDING = 'CONTRACTS_CONTRACTED_RATES_LANDING_PENDING';
export const CONTRACTS_CONTRACTED_RATES_LANDING_FULFILLED = 'CONTRACTS_CONTRACTED_RATES_LANDING_FULFILLED';
export const CONTRACTS_CONTRACTED_RATES_LANDING_REJECTED = 'CONTRACTS_CONTRACTED_RATES_LANDING_REJECTED';

export const PAYMENTS_LANDING = 'PAYMENTS_LANDING';
export const PAYMENTS_LANDING_PENDING = 'PAYMENTS_LANDING_PENDING';
export const PAYMENTS_LANDING_FULFILLED = 'PAYMENTS_LANDING_FULFILLED';
export const PAYMENTS_LANDING_REJECTED = 'PAYMENTS_LANDING_REJECTED';

export const PAYERS_CONTRACTS_LANDING = 'PAYERS_CONTRACTS_LANDING';
export const PAYERS_CONTRACTS_LANDING_PENDING = 'PAYERS_CONTRACTS_LANDING_PENDING';
export const PAYERS_CONTRACTS_LANDING_FULFILLED = 'PAYERS_CONTRACTS_LANDING_FULFILLED';
export const PAYERS_CONTRACTS_LANDING_REJECTED = 'PAYERS_CONTRACTS_LANDING_REJECTED';

export const PAYERS_LANDING = 'PAYERS_LANDING';
export const PAYERS_LANDING_PENDING = 'PAYERS_LANDING_PENDING';
export const PAYERS_LANDING_FULFILLED = 'PAYERS_LANDING_FULFILLED';
export const PAYERS_LANDING_REJECTED = 'PAYERS_LANDING_REJECTED';

export const CLAIM_ANAYLYSIS_LANDING = "CLAIM_ANAYLYSIS_LANDING";
export const CLAIM_ANAYLYSIS_LANDING_PENDING = "CLAIM_ANAYLYSIS_LANDING_PENDING";
export const CLAIM_ANAYLYSIS_LANDING_FULFILLED = "CLAIM_ANAYLYSIS_LANDING_FULFILLED";
export const CLAIM_ANAYLYSIS_LANDING_REJECTED = "CLAIM_ANAYLYSIS_LANDING_REJECTED";

export const EXECUTIONS_LANDING = 'EXECUTIONS_LANDING';
export const EXECUTIONS_LANDING_PENDING = 'EXECUTIONS_LANDING_PENDING';
export const EXECUTIONS_LANDING_FULFILLED = 'EXECUTIONS_LANDING_FULFILLED';
export const EXECUTIONS_LANDING_REJECTED = 'EXECUTIONS_LANDING_REJECTED';

export const CHARGE_MASTER_LANDING = 'CHARGE_MASTER_LANDING';
export const CHARGE_MASTER_LANDING_PENDING = 'CHARGE_MASTER_LANDING_PENDING';
export const CHARGE_MASTER_LANDING_FULFILLED = 'CHARGE_MASTER_LANDING_FULFILLED';
export const CHARGE_MASTER_LANDING_REJECTED = 'CHARGE_MASTER_LANDING_REJECTED';

export const SHOPPABLE_LANDING = 'SHOPPABLE_LANDING';
export const SHOPPABLE_LANDING_PENDING = 'SHOPPABLE_LANDING_PENDING';
export const SHOPPABLE_LANDING_FULFILLED = 'SHOPPABLE_LANDING_FULFILLED';
export const SHOPPABLE_LANDING_REJECTED = 'SHOPPABLE_LANDING_REJECTED';
export const SHOPPABLE_UPDATE_STATUS_FULFILLED = 'SHOPPABLE_UPDATE_STATUS_FULFILLED';

export const ASSOCIATED_LANDING = 'ASSOCIATED_LANDING';
export const ASSOCIATED_LANDING_PENDING = 'ASSOCIATED_LANDING_PENDING';
export const ASSOCIATED_LANDING_FULFILLED = 'ASSOCIATED_LANDING_FULFILLED';
export const ASSOCIATED_LANDING_REJECTED = 'ASSOCIATED_LANDING_REJECTED';
export const ASSOCIATED_UPDATE_STATUS_FULFILLED = 'ASSOCIATED_UPDATE_STATUS_FULFILLED';

export const ALLOWABLE_LANDING = 'ALLOWABLE_LANDING';
export const ALLOWABLE_LANDING_PENDING = 'ALLOWABLE_LANDING_PENDING';
export const ALLOWABLE_LANDING_FULFILLED = 'ALLOWABLE_LANDING_FULFILLED';
export const ALLOWABLE_LANDING_REJECTED = 'ALLOWABLE_LANDING_REJECTED';
export const ALLOWABLE_UPDATE_STATUS_FULFILLED = 'ALLOWABLE_UPDATE_STATUS_FULFILLED';

export const PRICER_PAYERS_LANDING = 'PRICER_PAYERS_LANDING';
export const PRICER_PAYERS_LANDING_PENDING = 'PRICER_PAYERS_LANDING_PENDING';
export const PRICER_PAYERS_LANDING_FULFILLED = 'PRICER_PAYERS_LANDING_FULFILLED';
export const PRICER_PAYERS_LANDING_REJECTED = 'PRICER_PAYERS_LANDING_REJECTED';
export const PRICER_PAYERS_UPDATE_STATUS_FULFILLED = 'PRICER_PAYERS_UPDATE_STATUS_FULFILLED';

export const VENDOR_CONTRACTS_LANDING = 'VENDOR_CONTRACTS_LANDING';
export const VENDOR_CONTRACTS_LANDING_PENDING = 'VENDOR_CONTRACTS_LANDING_PENDING';
export const VENDOR_CONTRACTS_LANDING_FULFILLED = 'VENDOR_CONTRACTS_LANDING_FULFILLED';
export const VENDOR_CONTRACTS_LANDING_REJECTED = 'VENDOR_CONTRACTS_LANDING_REJECTED';

export const CODE_SERVICE_DESCRIPTION_LANDING = 'CODE_SERVICE_DESCRIPTION_LANDING';
export const CODE_SERVICE_DESCRIPTION_LANDING_PENDING = 'CODE_SERVICE_DESCRIPTION_LANDING_PENDING';
export const CODE_SERVICE_DESCRIPTION_LANDING_FULFILLED = 'CODE_SERVICE_DESCRIPTION_LANDING_FULFILLED';
export const CODE_SERVICE_DESCRIPTION_LANDING_REJECTED = 'CODE_SERVICE_DESCRIPTION_LANDING_REJECTED';

export const UPLOADS_LANDING = 'UPLOADS_LANDING';
export const UPLOADS_LANDING_PENDING = 'UPLOADS_LANDING_PENDING';
export const UPLOADS_LANDING_FULFILLED = 'UPLOADS_LANDING_FULFILLED';
export const UPLOADS_LANDING_REJECTED = 'UPLOADS_LANDING_REJECTED';

export const FILE_EXECUTIONS_LANDING = 'FILE_EXECUTIONS_LANDING';
export const FILE_EXECUTIONS_LANDING_PENDING = 'FILE_EXECUTIONS_LANDING_PENDING';
export const FILE_EXECUTIONS_LANDING_FULFILLED = 'FILE_EXECUTIONS_LANDING_FULFILLED';
export const FILE_EXECUTIONS_LANDING_REJECTED = 'FILE_EXECUTIONS_LANDING_REJECTED';

export const ESTIMATOR_HISTORY_LANDING = 'ESTIMATOR_HISTORY_LANDING';
export const ESTIMATOR_HISTORY_LANDING_PENDING = 'ESTIMATOR_HISTORY_LANDING_PENDING';
export const ESTIMATOR_HISTORY_LANDING_FULFILLED = 'ESTIMATOR_HISTORY_LANDING_FULFILLED';
export const ESTIMATOR_HISTORY_LANDING_REJECTED = 'ESTIMATOR_HISTORY_LANDING_REJECTED';

export const CLAIM_AUDITS_LANDING = 'CLAIM_AUDITS_LANDING';
export const CLAIM_AUDITS_LANDING_PENDING = 'CLAIM_AUDITS_LANDING_PENDING';
export const CLAIM_AUDITS_LANDING_FULFILLED = 'CLAIM_AUDITS_LANDING_FULFILLED';
export const CLAIM_AUDITS_LANDING_REJECTED = 'CLAIM_AUDITS_LANDING_REJECTED';

export const MODEL_CLAIM_SELECTION_LANDING = 'MODEL_CLAIM_SELECTION_LANDING';
export const MODEL_CLAIM_SELECTION_LANDING_PENDING = 'MODEL_CLAIM_SELECTION_LANDING_PENDING';
export const MODEL_CLAIM_SELECTION_LANDING_FULFILLED = 'MODEL_CLAIM_SELECTION_LANDING_FULFILLED';
export const MODEL_CLAIM_SELECTION_LANDING_REJECTED = 'MODEL_CLAIM_SELECTION_LANDING_REJECTED';
export const MODEL_CLAIM_SELECTION_LANDING_INIT_FULFILLED = 'MODEL_CLAIM_SELECTION_LANDING_INIT_FULFILLED';

export const CONTRACT_MODELING_LANDING = 'CONTRACT_MODELING_LANDING';
export const CONTRACT_MODELING_LANDING_PENDING = 'CONTRACT_MODELING_LANDING_PENDING';
export const CONTRACT_MODELING_LANDING_FULFILLED = 'CONTRACT_MODELING_LANDING_FULFILLED';
export const CONTRACT_MODELING_LANDING_REJECTED = 'CONTRACT_MODELING_LANDING_REJECTED';

export const MODELING_EXECUTIONS_LANDING = 'MODELING_EXECUTIONS_LANDING';
export const MODELING_EXECUTIONS_LANDING_PENDING = 'MODELING_EXECUTIONS_LANDING_PENDING';
export const MODELING_EXECUTIONS_LANDING_FULFILLED = 'MODELING_EXECUTIONS_LANDING_FULFILLED';
export const MODELING_EXECUTIONS_LANDING_REJECTED = 'MODELING_EXECUTIONS_LANDING_REJECTED';

export const MODELING_EXECUTIONS_RESULT_LANDING = 'MODELING_EXECUTIONS_RESULT_LANDING';
export const MODELING_EXECUTIONS_RESULT_LANDING_PENDING = 'MODELING_EXECUTIONS_RESULT_LANDING_PENDING';
export const MODELING_EXECUTIONS_RESULT_LANDING_FULFILLED = 'MODELING_EXECUTIONS_RESULT_LANDING_FULFILLED';
export const MODELING_EXECUTIONS_RESULT_LANDING_REJECTED = 'MODELING_EXECUTIONS_RESULT_LANDING_REJECTED';

export const REMIT_PAYERS_LANDING = 'REMIT_PAYERS_LANDING';
export const REMIT_PAYERS_LANDING_PENDING = 'REMIT_PAYERS_LANDING_PENDING';
export const REMIT_PAYERS_LANDING_FULFILLED = 'REMIT_PAYERS_LANDING_FULFILLED';
export const REMIT_PAYERS_LANDING_REJECTED = 'REMIT_PAYERS_LANDING_REJECTED';

export const CODE_SERVICE_GET_RULES_LANDING = 'CODE_SERVICE_GET_RULES_LANDING';
export const CODE_SERVICE_GET_RULES_LANDING_PENDING = 'CODE_SERVICE_GET_RULES_LANDING_PENDING';
export const CODE_SERVICE_GET_RULES_LANDING_FULFILLED = 'CODE_SERVICE_GET_RULES_LANDING_FULFILLED';
export const CODE_SERVICE_GET_RULES_LANDING_REJECTED = 'CODE_SERVICE_GET_RULES_LANDING_REJECTED';

export const DRG_LANDING = 'DRG_LANDING';
export const DRG_LANDING_PENDING = 'DRG_LANDING_PENDING';
export const DRG_LANDING_FULFILLED = 'DRG_LANDING_FULFILLED';
export const DRG_LANDING_REJECTED = 'DRG_LANDING_REJECTED';

export const OPPS_LANDING = 'OPPS_LANDING';
export const OPPS_LANDING_PENDING = 'OPPS_LANDING_PENDING';
export const OPPS_LANDING_FULFILLED = 'OPPS_LANDING_FULFILLED';
export const OPPS_LANDING_REJECTED = 'OPPS_LANDING_REJECTED';

export const EAPG_LANDING = 'EAPG_LANDING';
export const EAPG_LANDING_PENDING = 'EAPG_LANDING_PENDING';
export const EAPG_LANDING_FULFILLED = 'EAPG_LANDING_FULFILLED';
export const EAPG_LANDING_REJECTED = 'EAPG_LANDING_REJECTED';

export const FEE_SCHEDULES_SELECTION_LANDING = 'FEE_SCHEDULES_SELECTION_LANDING';
export const FEE_SCHEDULES_SELECTION_LANDING_PENDING = 'FEE_SCHEDULES_SELECTION_LANDING_PENDING';
export const FEE_SCHEDULES_SELECTION_LANDING_FULFILLED = 'FEE_SCHEDULES_SELECTION_LANDING_FULFILLED';
export const FEE_SCHEDULES_SELECTION_LANDING_REJECTED = 'FEE_SCHEDULES_SELECTION_LANDING_REJECTED';
export const APP_ID_LANDING = 'APP_ID_LANDING';
export const APP_ID_LANDING_PENDING = 'APP_ID_LANDING_PENDING';
export const APP_ID_LANDING_FULFILLED = 'APP_ID_LANDING_FULFILLED';
export const APP_ID_LANDING_REJECTED = 'APP_ID_LANDING_REJECTED';

export const ADVANCED_FILTERS_DIALOG_STATE = 'ADVANCED_FILTERS_DIALOG_STATE';

export const PAYERS_STATS_LANDING = 'PAYERS_STATS_LANDING';
export const PAYERS_STATS_LANDING_PENDING = 'PAYERS_STATS_LANDING_PENDING';
export const PAYERS_STATS_LANDING_FULFILLED = 'PAYERS_STATS_LANDING_FULFILLED';
export const PAYERS_STATS_LANDING_REJECTED = 'PAYERS_STATS_LANDING_REJECTED';

export const REMIT_PAYERS_STATS_LANDING = 'REMIT_PAYERS_STATS_LANDING';
export const REMIT_PAYERS_STATS_LANDING_PENDING = 'REMIT_PAYERS_STATS_LANDING_PENDING';
export const REMIT_PAYERS_STATS_LANDING_FULFILLED = 'REMIT_PAYERS_STATS_LANDING_FULFILLED';
export const REMIT_PAYERS_STATS_LANDING_REJECTED = 'REMIT_PAYERS_STATS_LANDING_REJECTED';


export const RESET_PAGE_FILTER = 'RESET_PAGE_FILTER';
export const RESET_PAGE = 'RESET_PAGED_TABLE';

export const set = (page, key, value) => ({
    type: PAGES_SET,
    payload: { page, key, value },
});

export const contractsLanding = (pagination, sort, filter) => {
    return {
        type: CONTRACTS_LANDING,
        payload: { pagination, sort, filter },
    }
}

export const feeScheduleItemsLanding = (options) => {
    return {
        type: CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING,
        payload: { options },
    }
}

export const contractedRatesLanding = (pagination, sort, filter) => {
    return {
        type: CONTRACTS_CONTRACTED_RATES_LANDING,
        payload: { pagination, sort, filter },
    }
}

export const paymentsLanding = (pagination, sort, filter) => {
    return {
        type: PAYMENTS_LANDING,
        payload: { pagination, sort, filter },
    }
}

export const payerContractsLanding = (pagination, sort, filter) => {
    return {
        type: PAYERS_CONTRACTS_LANDING,
        payload: { pagination, sort, filter },
    }
}

export const payersLanding = (pagination, sort, filter) => {
    return {
        type: PAYERS_LANDING,
        payload: { pagination, sort, filter },
    }
}

export const claimAnalysisLanding = (pagination, sort, filter, significance) => {
    return {
        type: CLAIM_ANAYLYSIS_LANDING,
        payload: { pagination, sort, filter, significance },
    }
}

export const executionsLanding = (pagination, sort, filter) => {
    return {
        type: EXECUTIONS_LANDING,
        payload: { pagination, sort, filter },
    }
}

export const chargeMasterLanding = (pagination, sort, filter) => ({
    type: CHARGE_MASTER_LANDING,
    payload: { pagination, sort, filter },
});

export const shoppableLanding = (pagination, sort, filter) => {
    return {
        type: SHOPPABLE_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const associatedLanding = (pagination, sort, filter) => {
    return {
        type: ASSOCIATED_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const allowableLanding = (pagination, sort, filter) => {
    return {
        type: ALLOWABLE_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const pricerPayersLanding = (pagination, sort, filter) => {
    return {
        type: PRICER_PAYERS_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const resetPageFilter = () => ({
    type: RESET_PAGE_FILTER,
});

export const vendorContractsLanding = (pagination, sort, filter) => {
    return {
        type: VENDOR_CONTRACTS_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const codeServiceDescriptionLanding = (pagination, sort, filter, serviceId, codeType) => {
    return {
        type: CODE_SERVICE_DESCRIPTION_LANDING,
        payload: { pagination, sort, filter, serviceId, codeType },
    }
};

export const resetPage = (pageName) => {
    return {
        type: RESET_PAGE,
        payload: { pageName },
    }
};

export const uploadsLanding = (pagination, sort, filter) => {
    return {
        type: UPLOADS_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const fileExecutionsLanding = (pagination, sort, filter) => {
    return {
        type: FILE_EXECUTIONS_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const estimatorHistoryLanding = (pagination, sort, filter) => {
    return {
        type: ESTIMATOR_HISTORY_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const claimAuditsLanding = (pagination, sort, filter) => {
    return {
        type: CLAIM_AUDITS_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const setMulti = (page, obj) => ({
    type: PAGES_SET_MULTIPLE,
    payload: { page, obj },
});

export const modelClaimSelectionLanding = (pagination, sort, filter) => {
    return {
        type: MODEL_CLAIM_SELECTION_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const contractModelingLanding = (pagination, sort, filter) => {
    return {
        type: CONTRACT_MODELING_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const modelExecutionsLanding = (pagination, sort, filter) => {
    return {
        type: MODELING_EXECUTIONS_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const modelExecutionsResultLanding = (pagination, sort, filter, modelId) => {
    return {
        type: MODELING_EXECUTIONS_RESULT_LANDING,
        payload: { pagination, sort, filter, modelId },
    }
};

export const drgLanding = (pagination, sort, filter) => {
    return {
        type: DRG_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const remitPayersLanding = (pagination, sort, filter) => {
    return {
        type: REMIT_PAYERS_LANDING,
        payload: { pagination, sort, filter },
    }
};

export const rulesFromCodeServiceLanding = (pagination, sort, filter, serviceId) => {
    return {
        type: CODE_SERVICE_GET_RULES_LANDING,
        payload: { pagination, sort, filter, serviceId }
    }
};

export const oppsLanding = (pagination, sort, filter) => {
    return {
        type: OPPS_LANDING,
        payload: { pagination, sort, filter }
    }
};

export const eapgLanding = (pagination, sort, filter) => {
    return {
        type: EAPG_LANDING,
        payload: { pagination, sort, filter }
    }
};

export const contractsFamilyLanding = (pagination, sort, filter) => {

    return {
        type: CONTRACTS_FAMILY_LANDING,
        payload: { pagination, sort, filter },
    }
}

export const appIdLanding = (pagination, sort, filter) => {
    return {
        type: APP_ID_LANDING,
        payload: { pagination, sort, filter }
    }
};

export const setOpenAdvanceFiltersDialog = (open) => ({
    type: ADVANCED_FILTERS_DIALOG_STATE,
    payload: { open }
});
export const payersStatsLanding = (pagination, sort, filter) => {
    return {
        type: PAYERS_STATS_LANDING,
        payload: { pagination, sort, filter },
    }
}

export const remitPayersStatsLanding = (pagination, sort, filter) => {
    return {
        type: REMIT_PAYERS_STATS_LANDING,
        payload: { pagination, sort, filter },
    }
}
