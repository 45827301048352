export const USERS_LIST = 'USERS_LIST';
export const USERS_LIST_PENDING = 'USERS_LIST_PENDING';
export const USERS_LIST_FULFILLED = 'USERS_LIST_FULFILLED';
export const USERS_LIST_REJECTED = 'USERS_LIST_REJECTED';

export const USERS_LIST_TINY = 'USERS_LIST_TINY';
export const USERS_LIST_TINY_PENDING = 'USERS_LIST_TINY_PENDING';
export const USERS_LIST_TINY_FULFILLED = 'USERS_LIST_TINY_FULFILLED';
export const USERS_LIST_TINY_REJECTED = 'USERS_LIST_TINY_REJECTED';

export const USERS_LIST_TINY_WITHOUT_CLIENT = 'USERS_LIST_TINY_WITHOUT_CLIENT';
export const USERS_LIST_TINY_WITHOUT_CLIENT_PENDING = 'USERS_LIST_TINY_WITHOUT_CLIENT_PENDING';
export const USERS_LIST_TINY_WITHOUT_CLIENT_FULFILLED = 'USERS_LIST_TINY_WITHOUT_CLIENT_FULFILLED';
export const USERS_LIST_TINY_WITHOUT_CLIENT_REJECTED = 'USERS_LIST_TINY_WITHOUT_CLIENT_REJECTED';

export const USERS_GET = 'USERS_GET';
export const USERS_GET_PENDING = 'USERS_GET_PENDING';
export const USERS_GET_FULFILLED = 'USERS_GET_FULFILLED';
export const USERS_GET_REJECTED = 'USERS_GET_REJECTED';

export const USERS_CREATE = 'USERS_CREATE';
export const USERS_CREATE_PENDING = 'USERS_CREATE_PENDING';
export const USERS_CREATE_FULFILLED = 'USERS_CREATE_FULFILLED';
export const USERS_CREATE_REJECTED = 'USERS_CREATE_REJECTED';

export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_UPDATE_PENDING = 'USERS_UPDATE_PENDING';
export const USERS_UPDATE_FULFILLED = 'USERS_UPDATE_FULFILLED';
export const USERS_UPDATE_REJECTED = 'USERS_UPDATE_REJECTED';

export const USERS_DELETE = 'USERS_DELETE';
export const USERS_DELETE_PENDING = 'USERS_DELETE_PENDING';
export const USERS_DELETE_FULFILLED = 'USERS_DELETE_FULFILLED';
export const USERS_DELETE_REJECTED = 'USERS_DELETE_REJECTED';

export const USERS_DEACTIVATE = 'USERS_DEACTIVATE';
export const USERS_DEACTIVATE_PENDING = 'USERS_DEACTIVATE_PENDING';
export const USERS_DEACTIVATE_FULFILLED = 'USERS_DEACTIVATE_FULFILLED';
export const USERS_DEACTIVATE_REJECTED = 'USERS_DEACTIVATE_REJECTED';

export const USERS_ACTIVATE = 'USERS_ACTIVATE';
export const USERS_ACTIVATE_PENDING = 'USERS_ACTIVATE_PENDING';
export const USERS_ACTIVATE_FULFILLED = 'USERS_ACTIVATE_FULFILLED';
export const USERS_ACTIVATE_REJECTED = 'USERS_ACTIVATE_REJECTED';

export const USERS_ROLES_LIST = 'USERS_ROLES_LIST';
export const USERS_ROLES_LIST_PENDING = 'USERS_ROLES_LIST_PENDING';
export const USERS_ROLES_LIST_FULFILLED = 'USERS_ROLES_LIST_FULFILLED';
export const USERS_ROLES_LIST_REJECTED = 'USERS_ROLES_LIST_REJECTED';

export const USERS_TINY_BY_ROLE = 'USERS_TINY_BY_ROLE';
export const USERS_TINY_BY_ROLE_PENDING = 'USERS_TINY_BY_ROLE_PENDING';
export const USERS_TINY_BY_ROLE_FULFILLED = 'USERS_TINY_BY_ROLE_FULFILLED';
export const USERS_TINY_BY_ROLE_REJECTED = 'USERS_TINY_BY_ROLE_REJECTED';

export const USERS_ERROR_RESET = 'USERS_ERROR_RESET';
export const USERS_RESET = 'USERS_RESET';

export const list = () => ({
    type: USERS_LIST,
});

export const listTiny = (clientId) => ({
    type: USERS_LIST_TINY,
    payload: { clientId },
});

export const listTinyWithoutClient = () => ({
    type: USERS_LIST_TINY_WITHOUT_CLIENT
})

export const get = (userId, callback) => ({
    type: USERS_GET,
    payload: { userId, callback },
});

export const create = (user, callback) => ({
    type: USERS_CREATE,
    payload: { user, callback },
});

export const update = (id, user, callback) => ({
    type: USERS_UPDATE,
    payload: { id, user, callback },
});

export const deleteUser = (id, callback) => ({
    type: USERS_DELETE,
    payload: { id, callback },
});

export const errorReset = (...errorKeys) => ({
    type: USERS_ERROR_RESET,
    payload: { errorKeys },
});

export const deactivateUser = (userId, callback) => ({
    type: USERS_DEACTIVATE,
    payload: { userId, callback },
});

export const activateUser = (userId, callback) => ({
    type: USERS_ACTIVATE,
    payload: { userId, callback },
});

export const getUsersByRole = (role) => ({
    type: USERS_TINY_BY_ROLE,
    payload: { role }
});