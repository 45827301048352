import React from 'react';
import { ErrorDetails, Message } from "../../common";

export const renderErrorMessage = (hasError, errorMessage, extraDetails = undefined) => {
    if (!hasError) {
        return;
    }

    return (
        <Message theme="error">
            {errorMessage}.
            {extraDetails && <ErrorDetails extraDetails={extraDetails} />}
        </Message>
    )
}