import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import RuleNPIRoleFieldArrayComponent from './RuleNPIRoleFieldArray';
import { npiRolesLanding } from '../../../../store/actions/npiRoles';


export const RuleNPIRoleFieldArray = connect(
    () => {

        return (state, props) => ({
            npiRoles: state.npiRoles.data
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            npiRolesLanding: npiRolesLanding
        }, dispatch)
    })
)(injectIntl(RuleNPIRoleFieldArrayComponent));



