import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET, METHOD_PUT,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuth, headersAuthReceiveOctet, headersAuthSendReceiveJson } from '../../store/headers';
import { apiHandleResponse, apiHandleOctetResponse, apiHandleOctetResponseWithFileName } from '../../util/api';

const uploadChargeMaster = (file) => {
    const formData = new FormData();
    formData.append('chargeCsvFile', file);
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/upload`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: formData,
    }).then(apiHandleResponse);
};

const getCharges = () => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getChargesList = (options) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const createChargeMaster = (charge) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(charge)
    }).then(apiHandleResponse);
};

const updateChargeMaster = (charge) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(charge)
    }).then(apiHandleResponse);
};

const downloadTemplate = () => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/template`, {
        method: METHOD_GET,
        headers: headersAuthReceiveOctet(),
    }).then(apiHandleOctetResponseWithFileName);
};

export {
    uploadChargeMaster,
    getCharges,
    downloadTemplate,
    createChargeMaster,
    updateChargeMaster,
    getChargesList,
};