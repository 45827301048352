import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { deleteService, getCodeServiceDependencies, setCodeServiceDependencyDialog } from '../../../../store/actions/services';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.services.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (serviceDependencyDialog) => {
                if (serviceDependencyDialog && serviceDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectFeeScheduletId = createSelector(
            selectDependencyDialog,
            (serviceDependencyDialog) => {
                if (serviceDependencyDialog && serviceDependencyDialog?.serviceId) {
                    return serviceDependencyDialog?.serviceId
                }
                return null
            }
        );

        const selectFeeScheduleName = createSelector(
            selectDependencyDialog,
            (serviceDependencyDialog) => {
                if (serviceDependencyDialog && serviceDependencyDialog?.serviceName) {
                    return serviceDependencyDialog?.serviceName
                }
                return ''
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            serviceId: selectFeeScheduletId(state, props),
            codeServiceDependencies: state.services.dependencies,
            codeServiceDependencyPending: state.services.getCodeServiceDependencies.pending,
            codeServiceDeletePending: state.services.delete.pending,
            serviceName: selectFeeScheduleName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setCodeServiceDependencyDialog: setCodeServiceDependencyDialog,
            getCodeServiceDependencies: getCodeServiceDependencies,
            deleteService: deleteService
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));