
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import RichTextEditorComponent from './RichTextEditor';

export const RichTextEditor = connect(
    () => {
        return (state, props) => ({
            comments: props.data,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(RichTextEditorComponent));