import { createReducer } from '../../util';
import * as actions from '../actions/feeScheduleItems';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
    innerException: '',
    errorDetails: ''
};

const initialState = {
    types: [],
    enableDialog: null, //{ open:true,isEnableClicked:true }
    rateDialog: null, // {open:true,feeScheduleItem:{},mode:'edit'}
    feeSchedulesFromClient: [],
    dependencyDialog: null, //{open:true,feeScheduleId:null}
    feeScheduleDialog: null, //{open:true,mode:'edit', type:'Folder|Item', id:''}
    dependencies: [],
    dependencyLabelDialog: null, //{open:true,dependencies:null}
    dependencyLabelRateDialog: null, //{open:true,dependencies:null}
    dependencyImportLabelDialog: null, //{open:true,dependencies:null}
    feeScheduleItem: {},
    isRateExist: false,
    updateStatus: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    deleteFeeScheduleItems: {
        ...initialStatusState
    },
    getCodeTypes: {
        ...initialStatusState,
    },
    uploadFile: {
        ...initialStatusState,
    },
    downloadTemplate: {
        ...initialStatusState,
    },
    deleteFeeSchedule: {
        ...initialStatusState
    },
    getFeeScheduleFromClient: {
        ...initialStatusState
    },
    getFeeScheduleDependencies: {
        ...initialStatusState
    },
    getFeeScheduleItem: {
        ...initialStatusState
    },
    getFeeScheduleLabelRate: {
        ...initialStatusState
    },
};

export default createReducer(initialState, {
    [actions.FEE_SCHEDULE_ITEMS_STATUS_PENDING]: (state) => ({
        ...state,
        updateStatus: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_STATUS_FULFILLED]: (state) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_STATUS_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.FEE_SCHEDULE_ITEMS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.FEE_SCHEDULE_ITEMS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_DELETE_PENDING]: (state) => ({
        ...state,
        deleteFeeScheduleItems: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_DELETE_FULFILLED]: (state, types) => ({
        ...state,
        deleteFeeScheduleItems: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_DELETE_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        deleteFeeScheduleItems: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_CODE_TYPES_PENDING]: (state) => ({
        ...state,
        getCodeTypes: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_CODE_TYPES_FULFILLED]: (state, types) => ({
        ...state,
        getCodeTypes: {
            ...initialStatusState,
        },
        types,
    }),
    [actions.FEE_SCHEDULE_ITEMS_CODE_TYPES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCodeTypes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_PENDING]: (state) => ({
        ...state,
        downloadTemplate: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_FULFILLED]: (state, types) => ({
        ...state,
        downloadTemplate: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        downloadTemplate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULE_ITEMS_UPLOAD_FILE_PENDING]: (state) => ({
        ...state,
        uploadFile: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_UPLOAD_FILE_FULFILLED]: (state, types) => ({
        ...state,
        uploadFile: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULE_ITEMS_UPLOAD_FILE_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        uploadFile: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),

    [actions.FEE_SCHEDULE_DELETE_PENDING]: (state) => ({
        ...state,
        deleteFeeSchedule: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_DELETE_FULFILLED]: (state, types) => ({
        ...state,
        deleteFeeSchedule: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULE_DELETE_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        deleteFeeSchedule: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),

    [actions.FEE_SCHEDULE_GET_FROM_CLIENT_PENDING]: (state) => ({
        ...state,
        getFeeScheduleFromClient: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_GET_FROM_CLIENT_FULFILLED]: (state, feeSchedulesFromClient) => ({
        ...state,
        getFeeScheduleFromClient: {
            ...initialStatusState,
        },
        feeSchedulesFromClient
    }),
    [actions.FEE_SCHEDULE_GET_FROM_CLIENT_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        getFeeScheduleFromClient: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),

    [actions.FEE_SCHEDULE_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getFeeScheduleDependencies: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_DELETE_DEPENDENCIES_FULFILLED]: (state, dependencies) => ({
        ...state,
        getFeeScheduleDependencies: {
            ...initialStatusState,
        },
        dependencies
    }),
    [actions.FEE_SCHEDULE_DELETE_DEPENDENCIES_REJECTED]: (state, { errorMessage }) => ({
        ...state,
        getFeeScheduleDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULES_ITEMS_RESET]: (state) => ({
        ...state,
        dependencies: []
    }),

    [actions.FEE_SCHEDULE_ITEMS_IMPORT_EXPORT_RESET]: (state) => ({
        ...state,
        uploadFile: {
            ...initialStatusState,
        },
        downloadTemplate: {
            ...initialStatusState,
        },
    }),

    [actions.FEE_SCHEDULE_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),

    [actions.FEE_SCHEDULE_SET_ENABLE_DIALOG]: (state, { enableDialog }) => ({
        ...state,
        enableDialog
    }),
    [actions.FEE_SCHEDULE_SET_RATE_DIALOG]: (state, { rateDialog }) => ({
        ...state,
        rateDialog
    }),
    [actions.FEE_SCHEDULE_SET_EDIT_DIALOG]: (state, { feeScheduleDialog }) => ({
        ...state,
        feeScheduleDialog
    }),

    [actions.FEE_SCHEDULE_LABEL_SET_DEPENDENCY_DIALOG]: (state, { dependencyLabelDialog }) => ({
        ...state,
        dependencyLabelDialog
    }),

    [actions.FEE_SCHEDULE_LABEL_RATE_SET_DEPENDENCY_DIALOG]: (state, { dependencyLabelRateDialog }) => ({
        ...state,
        dependencyLabelRateDialog
    }),

    [actions.FEE_SCHEDULE_LABEL_IMPORT_SET_DEPENDENCY_DIALOG]: (state, { dependencyImportLabelDialog }) => ({
        ...state,
        dependencyImportLabelDialog
    }),

    [actions.FEE_SCHEDULE_ITEM_PENDING]: (state) => ({
        ...state,
        getFeeScheduleItem: {
            pending: true,
        },
        feeScheduleItem: {}
    }),
    [actions.FEE_SCHEDULE_ITEM_FULFILLED]: (state, feeScheduleItem) => ({
        ...state,
        getFeeScheduleItem: {
            ...initialStatusState,
        },
        feeScheduleItem: feeScheduleItem,
    }),
    [actions.FEE_SCHEDULE_ITEM_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFeeScheduleItem: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULE_ITEM_LABEL_RATE_PENDING]: (state) => ({
        ...state,
        getFeeScheduleLabelRate: {
            pending: true,
        },
        isRateExist: false
    }),
    [actions.FEE_SCHEDULE_ITEM_LABEL_RATE_FULFILLED]: (state, isRateExist) => ({
        ...state,
        getFeeScheduleLabelRate: {
            ...initialStatusState,
        },
        isRateExist: isRateExist,
    }),
    [actions.FEE_SCHEDULE_ITEM_LABEL_RATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFeeScheduleLabelRate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULE_ITEMS_RESET]: () => ({ ...initialState }),

    [actions.FEE_SCHEDULE_ITEMS_ERROR_RESET]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        }
    }),
});