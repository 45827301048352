
export const NPI_ROLES_LANDING = 'NPI_ROLES_LANDING';
export const NPI_ROLES_LANDING_PENDING = 'NPI_ROLES_LANDING_PENDING';
export const NPI_ROLES_LANDING_FULFILLED = 'NPI_ROLES_LANDING_FULFILLED';
export const NPI_ROLES_LANDING_REJECTED = 'NPI_ROLES_LANDING_REJECTED';

export const NPI_ROLES_CREATE = 'NPI_ROLES_CREATE';
export const NPI_ROLES_CREATE_PENDING = 'NPI_ROLES_CREATE_PENDING';
export const NPI_ROLES_CREATE_FULFILLED = 'NPI_ROLES_CREATE_FULFILLED';
export const NPI_ROLES_CREATE_REJECTED = 'NPI_ROLES_CREATE_REJECTED';

export const NPI_ROLES_UPDATE = 'NPI_ROLES_UPDATE';
export const NPI_ROLES_UPDATE_PENDING = 'NPI_ROLES_UPDATE_PENDING';
export const NPI_ROLES_UPDATE_FULFILLED = 'NPI_ROLES_UPDATE_FULFILLED';
export const NPI_ROLES_UPDATE_REJECTED = 'NPI_ROLES_UPDATE_REJECTED';

export const NPI_ROLES_DELETE = 'NPI_ROLES_DELETE';
export const NPI_ROLES_DELETE_PENDING = 'NPI_ROLES_DELETE_PENDING';
export const NPI_ROLES_DELETE_FULFILLED = 'NPI_ROLES_DELETE_FULFILLED';
export const NPI_ROLES_DELETE_REJECTED = 'NPI_ROLES_DELETE_REJECTED';

export const NPI_ROLES_CREATE_UPDATE_RESET = 'NPI_ROLES_CREATE_UPDATE_RESET';

export const npiRolesLanding = () => ({
    type: NPI_ROLES_LANDING
});

export const createNPIRole = (values, callback, shouldReloadGrid) => ({
    type: NPI_ROLES_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const updateNPIRole = (values, callback, shouldReloadGrid) => ({
    type: NPI_ROLES_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const deleteNPIRole = (npiRoleId, callback) => ({
    type: NPI_ROLES_DELETE,
    payload: { npiRoleId, callback }
});

export const createEditReset = () => ({
    type: NPI_ROLES_CREATE_UPDATE_RESET
});

