import {
    Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, makeStyles, Switch, TextField
} from '@material-ui/core';
import { Formik } from "formik";
import React from 'react';
import { Loadable } from '../../../common';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    switch: {
        margin: theme.spacing(1),
    },
}));

export default function PayerPlanDialog({
    open, handleRefresh, mode, intl, initialValues, payerPlanCreatePending,
    payerPlanUpdatePending, 
    actions: { payerPlanCreate, payerPlanUpdate, setPayerPlanDialog }
}) {
    const classes = useStyles();
    let shouldReloadGrid = false;

    function handleCallback(reload) {
        handleClose(reload);
    }

    function handleClose(reload) {
        setPayerPlanDialog(null);
        if (shouldReloadGrid === true) {
            handleRefresh();
        }
    }

    return (
        <Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === 'edit' ? 'Edit Payer Plan' : 'Add Payer Plan'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={payerPlanCreatePending || payerPlanUpdatePending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors: { name?: string } = {};
                            if (!values.name) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            shouldReloadGrid = true;
                            let payload: { id?: any; name: any; isMedicare: any } = {
                                name: values.name,
                                isMedicare: values.isMedicare,
                            }
                            
                            if (mode === 'create') {
                                payerPlanCreate(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                payload.id = values.id;
                                payerPlanUpdate(payload, handleCallback, shouldReloadGrid);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                required
                                                label="Name"
                                                name="name"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                helperText={touched.name && errors.name}
                                                error={Boolean(touched.name && errors.name)}
                                            />
                                        </Grid>
                                        <Grid className="mt-3" item xs={8}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="isMedicare"
                                                        color="primary"
                                                        checked={values.isMedicare}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="ml-0"
                                                        value={values.isMedicare}
                                                    />
                                                }
                                                label="Medicare"
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Button
                                                onClick={handleClose}
                                                variant="contained"
                                                color="default"
                                                className={classes.button}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={payerPlanCreatePending || payerPlanUpdatePending}
                                            >
                                                {(payerPlanCreatePending || payerPlanUpdatePending) ? 'Saving' : 'Save'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}