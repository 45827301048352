export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_FILES_PENDING = 'UPLOAD_FILES_PENDING';
export const UPLOAD_FILES_FULFILLED = 'UPLOAD_FILES_FULFILLED';
export const UPLOAD_FILES_REJECTED = 'UPLOAD_FILES_REJECTED';

export const UPLOAD_DOWNLOAD_TEMPLATE = 'UPLOAD_DOWNLOAD_TEMPLATE';
export const UPLOAD_DOWNLOAD_TEMPLATE_PENDING = 'UPLOAD_DOWNLOAD_TEMPLATE_PENDING';
export const UPLOAD_DOWNLOAD_TEMPLATE_FULFILLED = 'UPLOAD_DOWNLOAD_TEMPLATE_FULFILLED';
export const UPLOAD_DOWNLOAD_TEMPLATE_REJECTED = 'UPLOAD_DOWNLOAD_TEMPLATE_REJECTED';

export const uploadFiles = (options, callback) => ({
    type: UPLOAD_FILES,
    payload: {
        options, callback
    }
});

export const downloadTemplate = (type, callback) => ({
    type: UPLOAD_DOWNLOAD_TEMPLATE,
    payload: {
        type, callback
    }
});
