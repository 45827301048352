import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/roles';
import * as API from '../api/roles';
import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.ROLES_LIST_PENDING });
        const payload = yield call(API.getRoles);
        yield put({ type: actions.ROLES_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ROLES_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPermission(action) {
    try {
        yield put({ type: actions.ROLES_PERMISSIONS_GET_PENDING });
        const { callback } = action.payload;
        const payload = yield call(API.getPermission);
        yield put({ type: actions.ROLES_PERMISSIONS_GET_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ROLES_PERMISSIONS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPermissionById(action) {
    try {
        yield put({ type: actions.ROLES_PERMISSION_ROLE_ID_PENDING });
        const { roleId, callback } = action.payload;
        const payload = yield call(API.getPermissionById, roleId);
        yield put({ type: actions.ROLES_PERMISSION_ROLE_ID_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ROLES_PERMISSION_ROLE_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updatePermission(action) {
    try {
        yield put({ type: actions.ROLES_PERMISSION_UPDATE_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.updatePermission, options);
        yield put({ type: actions.ROLES_PERMISSION_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ROLES_PERMISSION_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* createPermission(action) {
    try {
        yield put({ type: actions.ROLES_PERMISSION_CREATE_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.createPermission, options);
        yield put({ type: actions.ROLES_PERMISSION_CREATE_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ROLES_PERMISSION_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.ROLES_RESET });
}

export default function* () {
    yield takeEvery(actions.ROLES_LIST, list);
    yield takeEvery(actions.ROLES_PERMISSIONS_GET, getPermission);
    yield takeEvery(actions.ROLES_PERMISSION_ROLE_ID, getPermissionById);
    yield takeEvery(actions.ROLES_PERMISSION_UPDATE, updatePermission);
    yield takeEvery(actions.ROLES_PERMISSION_CREATE, createPermission);
}