import React from 'react';
import { useIdleTimer } from 'react-idle-timer/legacy';

function ReactIdleTimer({ onIdleHandler }) {

    const timeout = parseInt(process.env.REACT_APP_IDLE_LOGOUT_TIME);

    const {
    } = useIdleTimer({
        timeout,
        throttle: 0.95 * timeout,
        onIdle: onIdleHandler,
        // onActive: () => console.log("Active"),
        // onAction: () => console.log("Action"),
        crossTab: true,
        syncTimers: 500,
        emitOnAllTabs: true
    });

    return (
        <>

        </>
    )
}

export default ReactIdleTimer
