import { createReducer } from '../../util';
import * as actions from '../actions/clients';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    clientById: null,
    tinyList: [],
    parserList: [],
    timezones: [],
    products: null,
    productsForUserEdit: null,
    clientConfiguration: null,
    clientAccountConfiguration: null,
    branding: null,
    brandingDetails: null,
    list: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    tiny: {
        ...initialStatusState,
    },
    bucketValidity: {}, // Keyvalue pair for the bucket validation
    ftpUserValidity: {}, // Keyvalue pair for the ftp user validation
    mergedClaims:{},
    deactivate: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState
    },
    activate: {
        ...initialStatusState,
    },
    getConfiguration: {
        ...initialStatusState,
    },
    getAccountConfiguration: {
        ...initialStatusState,
    },
    createConfiguration: {
        ...initialStatusState,
    },

    createAccountConfiguration: {
        ...initialStatusState,
    },

    addClaims: {
        ...initialStatusState,
    },
    updateConfiguration: {
        ...initialStatusState,
    },
    updateAccountConfiguration: {
        ...initialStatusState,
    },
    deleteConfiguration: {
        ...initialStatusState,
    },
    getTimeszones: {
        ...initialStatusState
    },
    getProducts: {
        ...initialStatusState
    },
    getProductsForUserEdit: {
        ...initialStatusState
    },
    getBranding: {
        ...initialStatusState,
    },
    getBrandingDetails: {
        ...initialStatusState,
    },
    createBranding: {
        ...initialStatusState,
    },
    updateBranding: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {
    [actions.CLIENTS_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.CLIENTS_LIST_FULFILLED]: (state, clients) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: clients,
    }),
    [actions.CLIENTS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLIENTS_LIST_TINY_PENDING]: (state) => ({
        ...state,
        tiny: {
            pending: true,
        },
    }),
    [actions.CLIENTS_LIST_TINY_FULFILLED]: (state, clients) => ({
        ...state,
        tiny: {
            ...initialStatusState,
        },
        tinyList: [
            ...clients,
        ]
    }),
    [actions.CLIENTS_LIST_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        tiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLIENTS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.CLIENTS_GET_FULFILLED]: (state, client) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        clientById: client,
    }),
    [actions.CLIENTS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLIENTS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.CLIENTS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.CLIENTS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLIENTS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.CLIENTS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.CLIENTS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PARSERS_GET_FULFILLED]: (state, parsers) => ({
        ...state,
        parserList: [
            ...parsers,
        ]
    }),
    [actions.BUCKET_CHECK_PENDING]: (state, payload) => {
        return {
            ...state,
            bucketValidity: {
                ...state.bucketValidity,
                [`pending-${payload.index}`]: true,
            }
        };
    },
    [actions.BUCKET_CHECK_FULFILLED]: (state, { index, error }) => {
        return {
            ...state,
            bucketValidity: {
                ...state.bucketValidity,
                [`pending-${index}`]: false,
                [`done-${index}`]: error,
            }
        };
    },
    [actions.FTPUSER_CHECK_PENDING]: (state, payload) => {
        return {
            ...state,
            ftpUserValidity: {
                ...state.ftpUserValidity,
                [`pending-${payload.index}`]: true,
            }
        };
    },
    [actions.FTPUSER_CHECK_FULFILLED]: (state, { index, error }) => {
        return {
            ...state,
            ftpUserValidity: {
                ...state.ftpUserValidity,
                [`pending-${index}`]: false,
                [`done-${index}`]: error,
            }
        };
    },

    [actions.CLIENTS_DEACTIVATE_PENDING]: (state) => ({
        ...state,
        deactivate: {
            pending: true,
        },
    }),
    [actions.CLIENTS_DEACTIVATE_FULFILLED]: (state) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
        }
    }),
    [actions.CLIENTS_DEACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deactivate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLIENTS_ACTIVATE_PENDING]: (state) => ({
        ...state,
        activate: {
            pending: true,
        },
    }),
    [actions.CLIENTS_ACTIVATE_FULFILLED]: (state) => ({
        ...state,
        activate: {
            ...initialStatusState,
        }
    }),
    [actions.CLIENTS_ACTIVATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        activate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLIENTS_CONFIGURATION_CREATE_PENDING]: (state) => ({
        ...state,
        createConfiguration: {
            pending: true,
        },
    }),
    [actions.CLIENTS_CONFIGURATION_CREATE_FULFILLED]: (state, clients) => ({
        ...state,
        createConfiguration: {
            ...initialStatusState,
        },
    }),
    [actions.CLIENTS_CONFIGURATION_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        createConfiguration: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLIENTS_ACCOUNT_CONFIGURATION_CREATE_PENDING]: (state) => ({
        ...state,
        createAccountConfiguration: {
            pending: true,
        },
    }),
    [actions.CLIENTS_ACCOUNT_CONFIGURATION_CREATE_FULFILLED]: (state, clients) => ({
        ...state,
        createAccountConfiguration: {
            ...initialStatusState,
        },
    }),
    [actions.CLIENTS_ACCOUNT_CONFIGURATION_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        createAccountConfiguration: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),



    [actions.CLIENTS_ACCOUNT_ADD_CLAIMS_PENDING]: (state) => ({
        ...state,
        addClaims: {
            pending: true,
        },
    }),
    [actions.CLIENTS_ACCOUNT_ADD_CLAIMS_FULFILLED]: (state, mergedClaims) => ({
        ...state,
        addClaims: {
            ...initialStatusState,
        },
        mergedClaims
        
    }),
    [actions.CLIENTS_ACCOUNT_ADD_CLAIMS_REJECTED]: (state, errorMessage) => ({
        ...state,
        addClaims: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),



    [actions.CLIENTS_CONFIGURATION_UPDATE_PENDING]: (state) => ({
        ...state,
        updateConfiguration: {
            pending: true,
        },
    }),
    [actions.CLIENTS_CONFIGURATION_UPDATE_FULFILLED]: (state, clients) => ({
        ...state,
        updateConfiguration: {
            ...initialStatusState,
        },
    }),
    [actions.CLIENTS_CONFIGURATION_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateConfiguration: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_PENDING]: (state) => ({
        ...state,
        updateAccountConfiguration: {
            pending: true,
        },
    }),
    [actions.CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_FULFILLED]: (state, clients) => ({
        ...state,
        updateAccountConfiguration: {
            ...initialStatusState,
        },
    }),
    [actions.CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateAccountConfiguration: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.CLIENTS_CONFIGURATION_GET_PENDING]: (state) => ({
        ...state,
        getConfiguration: {
            pending: true,
        },
    }),
    [actions.CLIENTS_CONFIGURATION_GET_FULFILLED]: (state, clientConfiguration) => ({
        ...state,
        getConfiguration: {
            ...initialStatusState,
        },
        clientConfiguration
    }),
    [actions.CLIENTS_CONFIGURATION_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getConfiguration: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.CLIENTS_ACCOUNT_CONFIGURATION_GET_PENDING]: (state) => ({
        ...state,
        getAccountConfiguration: {
            pending: true,
        },
    }),
    [actions.CLIENTS_ACCOUNT_CONFIGURATION_GET_FULFILLED]: (state, clientAccountConfiguration) => ({
        ...state,
        getAccountConfiguration: {
            ...initialStatusState,
        },
        clientAccountConfiguration
    }),
    [actions.CLIENTS_ACCOUNT_CONFIGURATION_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getAccountConfiguration: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.CLIENTS_CONFIGURATION_DELETE_PENDING]: (state) => ({
        ...state,
        deleteConfiguration: {
            pending: true,
        },
    }),
    [actions.CLIENTS_CONFIGURATION_DELETE_FULFILLED]: (state, clients) => ({
        ...state,
        deleteConfiguration: {
            ...initialStatusState,
        },
    }),
    [actions.CLIENTS_CONFIGURATION_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteConfiguration: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLIENTS_GET_TIMEZONES_PENDING]: (state) => ({
        ...state,
        getTimeszones: {
            pending: true,
        },
    }),
    [actions.CLIENTS_GET_TIMEZONES_FULFILLED]: (state, timezones) => ({
        ...state,
        getTimeszones: {
            ...initialStatusState,
        },
        timezones
    }),
    [actions.CLIENTS_GET_TIMEZONES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getTimeszones: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLIENTS_GET_PRODUCTS_PENDING]: (state) => ({
        ...state,
        getProducts: {
            pending: true,
        },
    }),
    [actions.CLIENTS_GET_PRODUCTS_FULFILLED]: (state, products) => ({
        ...state,
        getProducts: {
            ...initialStatusState,
        },
        products
    }),
    [actions.CLIENTS_GET_PRODUCTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getProducts: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLIENTS_GET_PRODUCTS_USER_EDIT_PENDING]: (state) => ({
        ...state,
        getProductsForUserEdit: {
            pending: true,
        },
    }),
    [actions.CLIENTS_GET_PRODUCTS_USER_EDIT_FULFILLED]: (state, productsForUserEdit) => ({
        ...state,
        getProductsForUserEdit: {
            ...initialStatusState,
        },
        productsForUserEdit
    }),
    [actions.CLIENTS_GET_PRODUCTS_USER_EDIT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getProductsForUserEdit: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLIENTS_GET_PRODUCTS_USER_EDIT_RESET]: (state) => ({
        ...state,
        productsForUserEdit: null
    }),

    [actions.CLIENTS_CONFIGURATION_RESET]: (state) => ({
        ...state,
        clientConfiguration: null
    }),

    [actions.CLIENTS_ACCOUNT_CONFIGURATION_RESET]: (state) => ({
        ...state,
        clientAccountConfiguration: null
    }),

    [actions.CLIENTS_ADDED_CLAIMS_RESET]: (state) => ({
        ...state,
        mergedClaims: {}
    }),

    [actions.RESET_BUCKETS]: (state) => {
        return {
            ...state,
            create: {
                ...initialStatusState,
            },
            update: {
                ...initialStatusState,
            },
            bucketValidity: {},
            ftpUserValidity: {}
        };
    },
    [actions.CLIENTS_RESET]: () => ({ ...initialState }),

    [actions.CLIENTS_BRANDING_GET_PENDING]: (state) => ({
        ...state,
        getBranding: {
            pending: true,
        },
    }),
    [actions.CLIENTS_BRANDING_GET_FULFILLED]: (state, branding) => ({
        ...state,
        getBranding: {
            ...initialStatusState,
        },
        branding: branding,
    }),
    [actions.CLIENTS_BRANDING_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getBranding: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLIENTS_BRANDING_CREATE_PENDING]: (state) => ({
        ...state,
        createBranding: {
            pending: true,
        },
    }),
    [actions.CLIENTS_BRANDING_CREATE_FULFILLED]: (state) => ({
        ...state,
        createBranding: {
            ...initialStatusState,
        }
    }),
    [actions.CLIENTS_BRANDING_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        createBranding: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLIENTS_BRANDING_UPDATE_PENDING]: (state) => ({
        ...state,
        updateBranding: {
            pending: true,
        },
    }),
    [actions.CLIENTS_BRANDING_UPDATE_FULFILLED]: (state) => ({
        ...state,
        updateBranding: {
            ...initialStatusState,
        }
    }),
    [actions.CLIENTS_BRANDING_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateBranding: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLIENTS_BRANDING_DETAILS_GET_PENDING]: (state) => ({
        ...state,
        getBrandingDetails: {
            pending: true,
        },
    }),
    [actions.CLIENTS_BRANDING_DETAILS_GET_FULFILLED]: (state, brandingDetails) => ({
        ...state,
        getBrandingDetails: {
            ...initialStatusState,
        },
        brandingDetails: brandingDetails,
    }),
    [actions.CLIENTS_BRANDING_DETAILS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getBrandingDetails: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});