export const SHOPPABLE_LIST = 'SHOPPABLE_LIST';
export const SHOPPABLE_PENDING = 'SHOPPABLE_PENDING';
export const SHOPPABLE_FULFILLED = 'SHOPPABLE_FULFILLED';
export const SHOPPABLE_REJECTED = 'SHOPPABLE_REJECTED';

export const SHOPPABLE_ERROR_RESET = 'SHOPPABLE_ERROR_RESET';
export const SHOPPABLE_RESET = 'SHOPPABLE_RESET';

export const SHOPPABLE_UPDATE_STATUS = 'SHOPPABLE_UPDATE_STATUS';
export const SHOPPABLE_UPDATE_STATUS_PENDING = 'SHOPPABLE_UPDATE_STATUS_PENDING';
export const SHOPPABLE_UPDATE_STATUS_FULFILLED = 'SHOPPABLE_UPDATE_STATUS_FULFILLED';
export const SHOPPABLE_UPDATE_STATUS_REJECTED = 'SHOPPABLE_UPDATE_STATUS_REJECTED';

export const SHOPPABLE_SELECT_ALL = 'SHOPPABLE_SELECT_ALL';
export const SHOPPABLE_SELECT_ALL_PENDING = 'SHOPPABLE_SELECT_ALL_PENDING';
export const SHOPPABLE_SELECT_ALL_FULFILLED = 'SHOPPABLE_SELECT_ALL_FULFILLED';
export const SHOPPABLE_SELECT_ALL_REJECTED = 'SHOPPABLE_SELECT_ALL_REJECTED';

export const SHOPPABLE_COUNTS = 'SHOPPABLE_COUNTS';
export const SHOPPABLE_COUNTS_PENDING = 'SHOPPABLE_COUNTS_PENDING';
export const SHOPPABLE_COUNTS_FULFILLED = 'SHOPPABLE_COUNTS_FULFILLED';
export const SHOPPABLE_COUNTS_REJECTED = 'SHOPPABLE_COUNTS_REJECTED';

export const SHOPPABLE_EXPORT = 'SHOPPABLE_EXPORT';
export const SHOPPABLE_EXPORT_PENDING = 'SHOPPABLE_EXPORT_PENDING';
export const SHOPPABLE_EXPORT_FULFILLED = 'SHOPPABLE_EXPORT_FULFILLED';
export const SHOPPABLE_EXPORT_REJECTED = 'SHOPPABLE_EXPORT_REJECTED';

export const SHOPPABLE_SET_ENABLE_DIALOG = 'SHOPPABLE_SET_ENABLE_DIALOG';

export const getList = () => {
    return {
        type: SHOPPABLE_LIST
    }
};

export const updateStatus = (id, enabled, callback) => {
    return {
        type: SHOPPABLE_UPDATE_STATUS,
        payload: { id, enabled, callback },
    }
};

export const errorReset = (...errorKeys) => ({
    type: SHOPPABLE_ERROR_RESET,
    payload: { errorKeys },
});

export const selectDeselectAll = (payload, callback) => ({
    type: SHOPPABLE_SELECT_ALL,
    payload: { payload, callback },
});

export const getCounts = (facilityId) => ({
    type: SHOPPABLE_COUNTS,
    payload: { facilityId },
});

export const exportShoppable = (options, callback) => ({
    type: SHOPPABLE_EXPORT,
    payload: {
        options, callback
    }
});

export const setEnableDialog = (enableDialog) => ({
    type: SHOPPABLE_SET_ENABLE_DIALOG,
    payload: { enableDialog }
})