export const DASHBOARD_GET = 'DASHBOARD_GET';
export const DASHBOARD_GET_PENDING = 'DASHBOARD_GET_PENDING';
export const DASHBOARD_GET_FULFILLED = 'DASHBOARD_GET_FULFILLED';
export const DASHBOARD_GET_REJECTED = 'DASHBOARD_GET_REJECTED';

export const DASHBOARD_GET_URL = 'DASHBOARD_GET_URL';
export const DASHBOARD_GET_URL_PENDING = 'DASHBOARD_GET_URL_PENDING';
export const DASHBOARD_GET_URL_FULFILLED = 'DASHBOARD_GET_URL_FULFILLED';
export const DASHBOARD_GET_URL_REJECTED = 'DASHBOARD_GET_URL_REJECTED';

export const DASHBOARD_CREATE = 'DASHBOARD_CREATE';
export const DASHBOARD_CREATE_PENDING = 'DASHBOARD_CREATE_PENDING';
export const DASHBOARD_CREATE_FULFILLED = 'DASHBOARD_CREATE_FULFILLED';
export const DASHBOARD_CREATE_REJECTED = 'DASHBOARD_CREATE_REJECTED';

export const DASHBOARD_UPDATE = 'DASHBOARD_UPDATE';
export const DASHBOARD_UPDATE_PENDING = 'DASHBOARD_UPDATE_PENDING';
export const DASHBOARD_UPDATE_FULFILLED = 'DASHBOARD_UPDATE_FULFILLED';
export const DASHBOARD_UPDATE_REJECTED = 'DASHBOARD_UPDATE_REJECTED';

export const DASHBOARD_LIST = 'DASHBOARD_LIST';
export const DASHBOARD_LIST_PENDING = 'DASHBOARD_LIST_PENDING';
export const DASHBOARD_LIST_FULFILLED = 'DASHBOARD_LIST_FULFILLED';
export const DASHBOARD_LIST_REJECTED = 'DASHBOARD_LIST_REJECTED';

export const DASHBOARD_LIST_TINY = 'DASHBOARD_LIST_TINY';
export const DASHBOARD_LIST_TINY_PENDING = 'DASHBOARD_LIST_TINY_PENDING';
export const DASHBOARD_LIST_TINY_FULFILLED = 'DASHBOARD_LIST_TINY_FULFILLED';
export const DASHBOARD_LIST_TINY_REJECTED = 'DASHBOARD_LIST_TINY_REJECTED';

export const DASHBOARD_LIST_BY_CLIENT_ID = 'DASHBOARD_LIST_BY_CLIENT_ID';
export const DASHBOARD_LIST_BY_CLIENT_ID_PENDING = 'DASHBOARD_LIST_BY_CLIENT_ID_PENDING';
export const DASHBOARD_LIST_BY_CLIENT_ID_FULFILLED = 'DASHBOARD_LIST_BY_CLIENT_ID_FULFILLED';
export const DASHBOARD_LIST_BY_CLIENT_ID_REJECTED = 'DASHBOARD_LIST_BY_CLIENT_ID_REJECTED';

export const DASHBOARD_DELETE = 'DASHBOARD_DELETE';
export const DASHBOARD_DELETE_PENDING = 'DASHBOARD_DELETE_PENDING';
export const DASHBOARD_DELETE_FULFILLED = 'DASHBOARD_DELETE_FULFILLED';
export const DASHBOARD_DELETE_REJECTED = 'DASHBOARD_DELETE_REJECTED';

export const DASHBOARD_GET_FIELD_VALUES = 'DASHBOARD_GET_FIELD_VALUES';
export const DASHBOARD_GET_FIELD_VALUES_PENDING = 'DASHBOARD_GET_FIELD_VALUES_PENDING';
export const DASHBOARD_GET_FIELD_VALUES_FULFILLED = 'DASHBOARD_GET_FIELD_VALUES_FULFILLED';
export const DASHBOARD_GET_FIELD_VALUES_REJECTED = 'DASHBOARD_GET_FIELD_VALUES_REJECTED';

export const DASHBOARD_SWITCH_LIST_BY_CLIENT_ID = 'DASHBOARD_SWITCH_LIST_BY_CLIENT_ID';
export const DASHBOARD_SET_FILTERS = 'DASHBOARD_SET_FILTERS';

export const DASHBOARD_ERROR_RESET = 'DASHBOARD_ERROR_RESET';
export const DASHBOARD_RESET = 'DASHBOARD_RESET';
export const DASHBOARD_URL_RESET = 'DASHBOARD_URL_RESET';
export const DASHBOARD_TINY_RESET = 'DASHBOARD_TINY_RESET';

export const list = () => ({
    type: DASHBOARD_LIST,
});

// This is to display list in the side navigation
export const listByClientId = (clientId) => ({
    type: DASHBOARD_LIST_BY_CLIENT_ID,
    payload: { clientId },
});

// This is to get all the dashboards for displaying on the users page for assignment.
export const listTinyByClientId = (clientId, callback) => ({
    type: DASHBOARD_LIST_TINY,
    payload: { clientId, callback },
});

export const get = (dashboardId, callback) => ({
    type: DASHBOARD_GET,
    payload: { dashboardId, callback },
});

export const getUrl = (qs_Id) => ({
    type: DASHBOARD_GET_URL,
    payload: { qs_Id },
});

export const create = (dashboard, callback) => ({
    type: DASHBOARD_CREATE,
    payload: { dashboard, callback },
});

export const update = (id, dashboard, callback) => ({
    type: DASHBOARD_UPDATE,
    payload: { id, dashboard, callback },
});

export const deleteDashboard = (id, callback) => ({
    type: DASHBOARD_DELETE,
    payload: { id, callback },
})

export const errorReset = (...errorKeys) => ({
    type: DASHBOARD_ERROR_RESET,
    payload: { errorKeys },
});

export const switchClient = (clientId, callback) => ({
    type: DASHBOARD_SWITCH_LIST_BY_CLIENT_ID,
    payload: { clientId, callback },
});

export const urlReset = () => ({
    type: DASHBOARD_URL_RESET
});

export const tinyReset = () => ({
    type: DASHBOARD_TINY_RESET
});

export const setFilter = (key, value) => ({
    type: DASHBOARD_SET_FILTERS,
    payload: { key, value },
});

export const getFieldName = (payload) => ({
    type: DASHBOARD_GET_FIELD_VALUES,
    payload,
});