import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import RuleModifierMultiProcedureFieldArrayComponent from './RuleModifierMultiProcedureFieldArray';

export const RuleModifierMultiProcedureFieldArrayDialog = connect(
    () => {

        const selectProcedurePercentage = (state, props) => props.selectedModifierMultiProcedurePercentage;

        const selectInitialValues = createSelector(
            selectProcedurePercentage,
            (procPercentage) => {
                if (procPercentage) {
                    const payload = {
                        percentageArray: procPercentage
                    }

                    return payload;
                }

                return {
                    percentageArray: [{
                        occurance: '',
                        value: ''
                    }]
                }
            }
        )

        return (state, props) => ({
            initialValues: selectInitialValues(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(RuleModifierMultiProcedureFieldArrayComponent));
