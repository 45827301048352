import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import ContractModelDialogComponent from './ContractModelDialog';
import { getModelById, analyzeModelEstimate, modelEstimate, setContractModelEditDialog } from '../../../../store/actions/modeling';
import { selectTiny } from '../../../../store/selectors/contracts';
import { selectSearches } from '../../../../store/selectors/search';


export const ContractModelDialog = connect(
    () => {
        const selectContractModelEditDialog = (state, props) => state.modeling.contractModelEditDialog;

        const selectContractModelEditDialogOpen = createSelector(
            selectContractModelEditDialog,
            (editDialog) => {
                if(editDialog && editDialog?.open) {
                    return true;
                }
                return false;
            }
        );
        
        const selectContractModel = createSelector(
            selectContractModelEditDialog,
            (editDialog) => {
                if(editDialog && editDialog?.model) {
                    return editDialog?.model;
                }
                return null;
            }
        );

        const selectMode = createSelector(
            selectContractModelEditDialog,
            (editDialog) => {
                if(editDialog && editDialog?.mode) {
                    return editDialog?.mode;
                }
                return 'create';
            }
        );

        const selectInitialValues = createSelector(
            selectMode,
            selectContractModel,
            (mode, model) => {
                if (mode === 'edit' && model && model.contractModelXref) {
                    const payload = {
                        id: model.id,
                        modelName: model.name,
                        baseContract: model.contractModelXref[0],
                        modelContract: model.contractModelXref[1],
                        searchCriteria: model.searchCriteria,
                    }

                    return payload;
                }

                return {
                    modelName: '',
                    baseContract: null,
                    modelContract: null,
                    searchCriteria: null,
                }
            }
        );
        
        const selectSortedTinyContracts = createSelector(selectTiny,
            (contracts) => {
                return contracts.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        return (state, props) => ({
            open: selectContractModelEditDialogOpen(state, props),
            modelEstimatePending: state.modeling.modelEstimate.pending,
            analyzeModelEstimatePending: state.modeling.analyzeModelEstimate.pending,
            searchFiltersPending: state.search.getByName.pending,
            contractsPending: state.contracts.tiny.pending,
            contractModel: selectContractModel(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            tinyContracts: selectSortedTinyContracts(state),
            searchFilters: selectSearches(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            modelEstimate: modelEstimate,
            analyzeModelEstimate: analyzeModelEstimate,
            setContractModelEditDialog: setContractModelEditDialog
        }, dispatch)
    })
)(injectIntl(ContractModelDialogComponent));