import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DynamicDropdownControlComponent from './DynamicDropdownControl.js';
import { getFieldName } from '../../../store/actions/dashboards';
import { createDashboardFieldValueSelector, createDashboardFieldLoadingSelector } from '../../../store/selectors/dashboards';

export const DynamicDropdownControl = connect(
    () => {
        const selectDashboardId = (state, props) => props.dashboardId;
        const selectFilterId = (state, props) => props.filterId;

        const selectFieldValues = createDashboardFieldValueSelector(selectDashboardId, selectFilterId);
        const selectFieldLoading = createDashboardFieldLoadingSelector(selectDashboardId, selectFilterId);

        return (state, props) => ({
            fieldValues: selectFieldValues(state, props),
            loading: selectFieldLoading(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            dashboardGetFieldName: getFieldName,
        }, dispatch)
    })
)(DynamicDropdownControlComponent);