import * as yup from 'yup';
import { ALPHABETS_WITH_SPACE_REGEX, EMAIL_REGEX } from '../constants/pages';


yup.addMethod(yup.string, "invalidEmails", function (errorMessage) {
    return this.test(`invalid-email`, errorMessage, function (value) {
        const { path, createError } = this;

        if (value) {
            const emails = value.replace(/\s/g, "").split(",");
            for (let i = 0; i < emails.length; i++) {
                if (!emails[i].match(EMAIL_REGEX)) {
                    return createError({ path, message: errorMessage });
                }
            }
        }
        return true;
    })
});

export const contractValidationSchema = yup.object().shape({
    name: yup.string().required("Required field"),
    // .matches(ALPHABETS_WITH_SPACE_REGEX, "Only allows alphabetical"),
    vendorContacts: yup.array().of(
        yup.object().shape({
            name: yup.string().required("Required field"),
            emails: yup.string().required("Required field")
                .invalidEmails('One or more email is not valid')
        })
    ),
    // vendorContacts: yup.array().when('isPayer', {
    //     is: false,
    //     then: yup.array().of(
    //         yup.object().shape({
    //             name: yup.string().required("Required field"),
    //             emails: yup.string().required("Required field")
    //                 .invalidEmails('One or more email is not valid')
    //         })
    //     ),
    // })    
});

export const feeScheduleValidationSchema = yup.object().shape({
    name: yup.string().required("Required field"),
    // .matches(ALPHABETS_WITH_SPACE_REGEX, "Only allows alphabetical"),
    feeScheduleTypeId: yup.number().required('Required field'),
    expirationDate: yup.string().required('Required field'),
    effectiveDate: yup.string().required('Required field'),
});