import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { create, update, get, errorReset } from '../../../../store/actions/dashboardFilters';
import { list, getFields } from '../../../../store/actions/dashboardDataSources';

import { createDashboardFilterSelector, selectDataTypes, selectValueTypes } from "../../../../store/selectors/dashboardFilters";
import { selectDashboardDataSources, selectDashboardDataSourceFields } from "../../../../store/selectors/dashboardDataSources";

import DashboardFiltersEditPageComponent from './DashboardFiltersEditPage';
import {
    VALUE_TYPE_SINGLE,
    PARAMETER_TYPE_STATIC
} from '../../../../store/constants/filters';

export const DashboardFiltersEditPage = connect(
    () => {
        const selectDashboardFilterId = (state, props) => props.match.params.dashboardFilterId;

        const selectMode = (state, props) => props.match.params.dashboardFilterId === 'new' ? 'create' : 'edit';

        const selectDashboardFilter = createDashboardFilterSelector(selectDashboardFilterId);

        const selectInitialValues = createSelector(
            selectMode,
            selectDashboardFilter,
            (mode, dashboardFilter) => {
                if (mode === 'edit' && dashboardFilter) {
                    let payload = {
                        id: dashboardFilter.id,
                        name: dashboardFilter.name,
                        datatype: dashboardFilter.datatype,
                        description: dashboardFilter.description,
                        metadata: dashboardFilter.metadata,
                        valuetype: dashboardFilter.valuetype,
                        type: dashboardFilter.type,
                        displayName: dashboardFilter.displayName,
                        parameterName1: dashboardFilter.parameterName1,
                        parameterName2: dashboardFilter.parameterName2,
                    }

                    if (dashboardFilter.valuetype === VALUE_TYPE_SINGLE) {
                        if (dashboardFilter.type === PARAMETER_TYPE_STATIC) {
                            payload = {
                                ...payload,
                                datavalueSingle: dashboardFilter.datavalue,
                            }
                        } else {
                            payload = {
                                ...payload,
                                dataSourceId: dashboardFilter.datasourceId,
                                fieldName: dashboardFilter.fieldName,
                            }
                        }
                    } else {
                        payload = {
                            ...payload,
                            datavalueMultiple: dashboardFilter.datavalue && dashboardFilter.datavalue.replace(/,/g, '\n'),
                        }
                    }

                    return payload;
                }

                return {
                    name: '',
                    datatype: 'String',
                    datavalueSingle: '',
                    datavalueMultiple: '',
                    description: '',
                    metadata: '',
                    valuetype: 'Single',
                    dataSourceId: '',
                    fieldName: '',
                    type: 'Static',
                    displayName: '',
                    parameterName1: '',
                    parameterName2: '',
                }
            }
        )

        return (state, props) => ({
            //create dashboard
            dashboardFiltersCreatePending: state.dashboardFilters.create.pending,

            // update dashboard
            dashboardFiltersUpdatePending: state.dashboardFilters.update.pending,
            dashboardFiltersGetPending: state.dashboardFilters.get.pending,

            // datasources
            dashboardDataSourceListPending: state.dashboardDataSources.list.pending,
            dashboardDataSourceGetFieldsPending: state.dashboardDataSources.getFields.pending,
            dataSources: selectDashboardDataSources(state),
            dataSourceFields: selectDashboardDataSourceFields(state),

            dataTypes: selectDataTypes(state),
            valueTypes: selectValueTypes(state),

            // edit
            dashboardFilter: selectDashboardFilter(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            dashboardFilterId: selectDashboardFilterId(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            dashboardFilterCreate: create,
            dashboardFilterUpdate: update,
            dashboardFilterGet: get,
            dashboardDataSourcesList: list,
            dashboardDataSourcesGetFields: getFields,
            dashboardFilterErrorReset: errorReset,
        }, dispatch)
    })
)(injectIntl(DashboardFiltersEditPageComponent));