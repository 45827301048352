import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/dashboardFilters';
import * as API from '../api/dashboardFilters';

import * as dataSourceActions from '../actions/dashboardDataSources';
import * as dataSourceAPI from '../api/dashboardDataSources';

import { VALUE_TYPE_SINGLE, PARAMETER_TYPE_DYNAMIC } from '../constants/filters';

import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.DASHBOARD_FILTERS_LIST_PENDING });
        const payload = yield call(API.listDashboardFilters);
        yield put({ type: actions.DASHBOARD_FILTERS_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_FILTERS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* listTiny() {
    try {
        yield put({ type: actions.DASHBOARD_FILTERS_LIST_TINY_PENDING });
        const payload = yield call(API.getDashboardFiltersTiny);
        yield put({ type: actions.DASHBOARD_FILTERS_LIST_TINY_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_FILTERS_LIST_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.DASHBOARD_FILTERS_GET_PENDING });
        const { dashboardFilterId, callback } = action.payload;
        const payload = yield call(API.getDashboardFilter, dashboardFilterId);

        const dataSources = yield call(dataSourceAPI.listDashboardDataSources);
        yield put({ type: dataSourceActions.DASHBOARD_DATA_SOURCES_LIST_FULFILLED, payload: dataSources });

        if (payload.valuetype === VALUE_TYPE_SINGLE && payload.type === PARAMETER_TYPE_DYNAMIC) {
            const dataFields = yield call(dataSourceAPI.getDashboardDataSourceFields, payload.datasourceId);
            yield put({ type: dataSourceActions.DASHBOARD_DATA_SOURCES_FIELDS_GET_FULFILLED, payload: dataFields });
        }

        yield put({ type: actions.DASHBOARD_FILTERS_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_FILTERS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.DASHBOARD_FILTERS_CREATE_PENDING });
        const {
            dashboardFilter, callback,
        } = action.payload;

        const newDashboardFilter = yield call(API.createDashboardFilter, dashboardFilter);
        yield put({ type: actions.DASHBOARD_FILTERS_CREATE_FULFILLED, newDashboardFilter });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_FILTERS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.DASHBOARD_FILTERS_UPDATE_PENDING });
        const {
            dashboardFilter,
            callback
        } = action.payload;

        const newDashboardFilter = yield call(API.updateDashboardFilter, dashboardFilter);
        yield put({ type: actions.DASHBOARD_FILTERS_UPDATE_FULFILLED, newDashboardFilter });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_FILTERS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteDashboardFilter(action) {
    try {
        yield put({ type: actions.DASHBOARD_FILTERS_DELETE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deleteDashboardFilter, id);
        yield put({ type: actions.DASHBOARD_FILTERS_DELETE_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_FILTERS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.DASHBOARD_FILTERS_RESET });
}

export default function* () {
    yield takeEvery(actions.DASHBOARD_FILTERS_LIST, list);
    yield takeEvery(actions.DASHBOARD_FILTERS_LIST_TINY, listTiny);
    yield takeEvery(actions.DASHBOARD_FILTERS_GET, get);
    yield takeEvery(actions.DASHBOARD_FILTERS_CREATE, create);
    yield takeEvery(actions.DASHBOARD_FILTERS_UPDATE, update);
    yield takeEvery(actions.DASHBOARD_FILTERS_DELETE, deleteDashboardFilter);
}