import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/dashboardTypes';
import * as API from '../api/dashboardTypes';

import * as filterActions from '../actions/dashboardFilters';
import * as filterAPI from '../api/dashboardFilters';

import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.DASHBOARD_TYPES_LIST_PENDING });
        const payload = yield call(API.listDashboardTypes);
        yield put({ type: actions.DASHBOARD_TYPES_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_TYPES_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.DASHBOARD_TYPES_GET_PENDING });
        const { dashboardTypeId, callback } = action.payload;
        const payload = yield call(API.getDashboardType, dashboardTypeId);

        const filters = yield call(filterAPI.getDashboardFiltersTiny);
        yield put({ type: filterActions.DASHBOARD_FILTERS_LIST_TINY_FULFILLED, payload: filters });

        yield put({ type: actions.DASHBOARD_TYPES_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_TYPES_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.DASHBOARD_TYPES_CREATE_PENDING });
        const {
            dashboardType, callback,
        } = action.payload;

        const newDashboardType = yield call(API.createDashboardType, dashboardType);
        yield put({ type: actions.DASHBOARD_TYPES_CREATE_FULFILLED, newDashboardType });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_TYPES_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.DASHBOARD_TYPES_UPDATE_PENDING });
        const {
            dashboardType,
            callback
        } = action.payload;

        const newDashboardType = yield call(API.updateDashboardType, dashboardType);
        yield put({ type: actions.DASHBOARD_TYPES_UPDATE_FULFILLED, newDashboardType });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_TYPES_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteDashboardType(action) {
    try {
        yield put({ type: actions.DASHBOARD_TYPES_DELETE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deleteDashboardType, id);
        yield put({ type: actions.DASHBOARD_TYPES_DELETE_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_TYPES_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.DASHBOARD_TYPES_RESET });
}

export default function*() {
    yield takeEvery(actions.DASHBOARD_TYPES_LIST, list);
    yield takeEvery(actions.DASHBOARD_TYPES_GET, get);
    yield takeEvery(actions.DASHBOARD_TYPES_CREATE, create);
    yield takeEvery(actions.DASHBOARD_TYPES_UPDATE, update);
    yield takeEvery(actions.DASHBOARD_TYPES_DELETE, deleteDashboardType);
}