import React, { useState, useEffect } from 'react';
import {
    makeStyles, Tooltip, IconButton, Grid,
} from '@material-ui/core';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { PayerDialog } from './PayerDialog';
import { PagedTable } from '../../common';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import { PayerImport } from './PayerImport';
import { EnableDialog } from './EnableDialog';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { DependencyDialog } from './DependencyDialog';
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from '../../store/constants/roles';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function PayerInfoComponent(props) {
    const {
        payerData, payerFields,
        payerContracts, userRole,
        actions: {
            payersLanding,
            payersGetPayerContractsTiny,
            searchGetByName,
            searchReset,
            payerEditReset,
            resetPagedTable,
            setPayerInfoEditDialog,
            setPayerInfoEnableDialog,
            setList,
            setClaimDependencyDialog,
            setSelectedItems,
        }
    } = props;

    useEffect(() => {
        payersGetPayerContractsTiny();
    }, [payersGetPayerContractsTiny]);

    useEffect(() => {
        const pageName = {
            name: 'payerinfo-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
            setSelectedItems([]);
        }
    }, [searchGetByName]);

    const [refresh, setRefresh] = useState(Promise.resolve());

    const [isImportDialogOpen, setImportDialog] = useState(false);

    const classes = useStyles();

    // useEffect(() => {
    //     getPayers();
    // }, [getPayers]);


    const handlePayerEdit = (payer) => {
        setPayerInfoEditDialog({ open: true, payer: payer, bulk: false });
    };

    // const handleCheckbox = (selectedRows, payer) => {
    //     updatePayerStatus(payer.id, !payer.active, handleRefresh);
    // };

    const handleAddPayer = () => {
        setPayerInfoEditDialog({ open: true, payer: null, bulk: false });
    }

    const handleClose = (shouldReloadGrid) => {
        payerEditReset();
        setPayerInfoEditDialog(null);
        setPayerInfoEnableDialog(null)
        if (shouldReloadGrid === true) {
            setList([]);
            handleRefresh();
        }
    }

    // const handleSelectAll = () => {
    //     const ids = payerData.map(({ id }) => id);
    //     const payload = {
    //         ids,
    //         enabled: !checkboxStatus,
    //     };
    //     selectDeselectAllPayers(payload, handleRefresh);
    // }


    function handleBulkEdit() {
        setPayerInfoEditDialog({ open: true, payer: null, bulk: true });
    }

    function handleImportPayerInfo() {
        setImportDialog(true);
    }

    function handleImportDialogClose() {
        setImportDialog(false);
        handleRefresh();
    }

    function handleBulkDelete() {
        setClaimDependencyDialog({ open: true });
    }

    function tableLevelActions() {
        return (
            <>
                <Grid item>
                    <Tooltip title="Import Payer Info">
                        <IconButton aria-label="download" size='small'
                            onClick={handleImportPayerInfo}
                        >
                            <i className="fas fa-upload"></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
                {(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN) &&
                    <Grid item>
                        <Tooltip title="Delete Claim Payers">
                            <IconButton aria-label="delete" size='small'
                                onClick={handleBulkDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
                <Grid item>
                    <Tooltip title="Edit">
                        <IconButton aria-label="edit" size='small' onClick={() => handleBulkEdit()}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Enable">
                        <IconButton aria-label="enable" size='small' onClick={() => handlePayerConfirmation(true)}>
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Disable">
                        <IconButton aria-label="disable" size='small' onClick={() => handlePayerConfirmation(false)}>
                            <i className={classNames("fa fa-solid fa-ban", classes.icon)}></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    function handleLoad(sort, filter, pagination) {
        payersLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handlePayerConfirmation(isEnable) {
        setPayerInfoEnableDialog({
            open: true,
            isEnableClicked: isEnable
        })
    }

    function payerInfoActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                aria-label="Edit"
                onClick={() => handlePayerEdit(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
        </div>
    }

    return (
        <>
            <Helmet title="Claim Payers" />
            <Portlet>
                <PortletHeader
                    title="Claim Payers"
                    handleRefresh={handleRefresh}
                    showSearchFilters={SHOW_SEARCH_FILTERS}
                    showSearchFiltersDropdown={false}
                    name="payerinfo-landing"
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="payerinfo-landing"
                                fields={payerFields}
                                data={payerData}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                onAdd={handleAddPayer}
                                tableLevelActions={tableLevelActions}
                                hasCheckbox={true}
                                showSelectAll={true}
                                title="Claim Payer"
                                criteriasSaveEnabled={true}
                                showSearchFilters={SHOW_SEARCH_FILTERS}
                                hasActions={true}
                                renderActionColumn={payerInfoActions}
                            />
                        </div>
                    </div>
                </PortletBody>
                <PayerDialog
                    payerContracts={payerContracts}
                    handleClose={handleClose} />
            </Portlet>
            <EnableDialog handleClose={handleClose} />
            <PayerImport open={isImportDialogOpen} handleClose={handleImportDialogClose} />
            <DependencyDialog handleClose={handleClose} />
        </>
    );
}