import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { setFeeScheduleLabelDepdendencyDialog } from '../../../../store/actions/feeScheduleItems';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.feeScheduleItems.dependencyLabelDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectDependencies = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.dependencies.length > 0) {
                    return dependencyDialog?.dependencies
                }
                return []
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            dependencies: selectDependencies(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setFeeScheduleLabelDepdendencyDialog: setFeeScheduleLabelDepdendencyDialog,
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));