import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { setFeeScheduleDepdendencyDialog, setFeeScheduleEditDialog } from '../../../store/actions/feeScheduleItems';
import { getFeeSchedules, getHierarchy, resetFeeSchedules, setDirectoryEditDialog, setFeeScheduleDirectoryDependencyDialog, setMoveDialog } from '../../../store/actions/feeScheduleV2';
import { set } from '../../../store/actions/pages';
import { setV2 } from '../../../store/actions/pagesV2';
import { StringEquators } from '../../../store/constants/pages';
import { selectSessionRole } from '../../../store/selectors/session';
import { createPlainDateField, createStringDataField, createTooltipIconForNonEmptyField } from '../../../util/format';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import FeeSchedulePageV2Component from './FeeSchedulePageV2';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

export const selectFeeSchedules = state => state.feeScheduleV2.feeSchedules;

const selectDirectoryId = (state, props) => props.match.params.directoryId;

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Name', { sortable: true }),
        createStringDataField('feeScheduleType.name', 'Type', { equators: StringEquators, secondLevel: 'name' }),
        createPlainDateField('effectiveDate', 'Effective Date', { fieldType: 'Date' }),
        createPlainDateField('expirationDate', 'Expiration Date', { fieldType: 'Date' }),
        createTooltipIconForNonEmptyField('hasDates', ' ', { iconClass: 'fas fa-exclamation-circle', iconColor: 'text-warning', sortable: true, equators: StringEquators, whiteSpace: 'break-spaces', errorTooltip: 'It has expiration fee schedule rates' }),
        // createTooltipIconForNonEmptyField('hasRates', ' ', { iconClass: 'fas fa-exclamation-circle', iconColor: 'text-warning', sortable: true, equators: StringEquators, whiteSpace: 'break-spaces', errorTooltip: 'It has no fee schedule rates' }),
    ]
);

const selectFilteredFields = createSelector(
        selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['feeSchedules-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createSelector(
    selectFilteredFields,
    (state) => state.pages['feeSchedules-landing'].filter,
    selectSort,
    selectFeeSchedules,
    (fields, filter, sort, data) => {
        return data
    }
);

const selectFeeScs = createSelector(selectFeeSchedules,
    (data) => {
        return data?.feeSchedules ?? []
    }
);

const selectDirectories = createSelector(selectFeeSchedules,
    (data) => {
        return data?.directories ?? []
    }
);


const filteredFeeSchedules = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`facilities-landing`].filter,
    selectSort,
    selectFeeScs,
);

const filteredDirectories = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`facilities-landing`].filter,
    selectSort,
    selectDirectories,
);


export const FeeSchedulePageV2 = connect(
    () => {
        return (state, props) => ({
            getListPending: state.feeScheduleV2.getFeeSchedule.pending,
            movePending: state.feeScheduleV2.move.pending,
            fields: selectFields(state as never),
            filteredData: selectFilteredData(state as never),
            feeSchedules: filteredFeeSchedules(state),
            directories: filteredDirectories(state),
            order: state.pages['feeSchedules-landing'].order,
            filter: state.pages['feeSchedules-landing'].filter,
            sort: selectSort(state),
            userRole: selectSessionRole(state),
            directoryId: selectDirectoryId(state, props)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setOrder: (value) => set('feeSchedules-landing', 'order', value),
            setSort: (value) => set('feeSchedules-landing', 'sort', value),
            resetSelected: (value) => setV2('feeSchedules-landing', 'selected', []),
            getFeeScheduleList: getFeeSchedules,
            resetFeeSchedules: resetFeeSchedules,
            setFeeScheduleDepdendencyDialog: setFeeScheduleDepdendencyDialog,
            setFeeScheduleEditDialog: setFeeScheduleEditDialog,
            setDirectoryEditDialog: setDirectoryEditDialog,
            setMoveDialog: setMoveDialog,
            setFeeScheduleDirectoryDependencyDialog: setFeeScheduleDirectoryDependencyDialog,
            getHierarchy: getHierarchy
        }, dispatch)
    })
)(injectIntl(FeeSchedulePageV2Component));