import {
    METHOD_POST, METHOD_GET, SERVER_URL
} from '../constants/api';
import { headersSendReceiveJson, headersAuthReceiveJson, headersAuthSendReceiveJson } from '../../store/headers';

import { apiHandleResponse } from '../../util/api';

const login = (username = '', password = '') => {
    return fetch(`${SERVER_URL}/api/v1/user/authenticate`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify({ username, password }),
    }).then(apiHandleResponse);
};

const getCurrentUser = () => {
    return fetch(`${SERVER_URL}/api/v1/user/me`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
}

const acceptEula = () => {
    return fetch(`${SERVER_URL}/api/v1/users/eula/accept`, {
        method: METHOD_POST,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
}

const changePassword = (oldPassword = '', newPassword = '') => {
    return fetch(`${SERVER_URL}/api/v1/user/password`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify({ oldPassword, newPassword }),
    }).then(apiHandleResponse);
};

const refreshToken = (token = '') => {
    return fetch(`${SERVER_URL}/api/v1/authenticate/token/refresh`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify({ token }),
    }).then(apiHandleResponse);
};


export {
    login,
    getCurrentUser,
    acceptEula,
    changePassword,
    refreshToken
};