import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/associated';
import * as API from '../api/associated';
import { sessionErrorHandling } from './session';

function* getList(action) {
    try {
        const {
            from,
            to
        } = action.payload;
        yield put({ type: actions.ASSOCIATED_PENDING });
        const payload = yield call(API.getAssociatedList, from, to);
        yield put({ type: actions.ASSOCIATED_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ASSOCIATED_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateStatus(action) {
    try {
        yield put({ type: actions.ASSOCIATED_UPDATE_STATUS_PENDING });
        const {
            id,
            enabled
        } = action.payload;
        yield call(API.updateAssociatedStatus, id, enabled);
        yield put({ type: actions.ASSOCIATED_UPDATE_STATUS_FULFILLED, payload: { id: id, enabled: enabled } });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ASSOCIATED_UPDATE_STATUS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* selectDeselectAll(action) {
    try {
        yield put({ type: actions.ASSOCIATED_SELECT_ALL_PENDING });
        const {
            payload,
            callback,
        } = action.payload;
        yield call(API.selectDeselectAll, payload);
        yield put({ type: actions.ASSOCIATED_SELECT_ALL_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ASSOCIATED_SELECT_ALL_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* refreshAssociatedCharges(action) {
    try {
        yield put({ type: actions.ASSOCIATED_REFRESH_PENDING });
        const { options, callback } = action.payload;
        yield call(API.refreshAssociatedCharges, options);
        yield put({ type: actions.ASSOCIATED_REFRESH_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ASSOCIATED_REFRESH_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.ASSOCIATED_RESET });
}

export default function* () {
    yield takeEvery(actions.ASSOCIATED_LIST, getList);
    yield takeEvery(actions.ASSOCIATED_UPDATE_STATUS, updateStatus);
    yield takeEvery(actions.ASSOCIATED_SELECT_ALL, selectDeselectAll);
    yield takeEvery(actions.ASSOCIATED_REFRESH, refreshAssociatedCharges);
}