import React, { useRef } from 'react';
import {
    Button, Dialog, DialogContent, DialogTitle,
    TextField, makeStyles, Grid,
    Stepper,
    Step,
    StepLabel,
    DialogActions,
    FormControl,
    Checkbox,
    Typography,
    Chip,
    FormHelperText,
    CircularProgress,Box,InputAdornment
} from '@material-ui/core';
import { Formik } from "formik";
import { Loadable, Message } from '../../../common';
import { orderBy } from "lodash";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useEffect } from 'react';
import { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FeeScheduleSelection } from "../../feeSchedulePage/feeScheduleSelectionComponent";
import { copyContractConstants } from '../../../store/constants/pages';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    formControlAuto: {
        width: '90%',
    },
    field: {
        width: '90%',
    },
    w90: {
        width: '90%'
    },
    root: {
        flexGrow: 1,
    },
    successIcon: {
        color: 'green'
    },
    errorIcon: {
        color: 'red'
    },
    iconsContainer:{
        position:'absolute',
        right:'20px',
        top:'20px'

    }
}));

export default function CopyContractDialog(props) {
    const {
        open, handleRefresh, initialValues, copyContractPending, isSuperAdmin, hasRulesApiResponded, feeSchedulesFromClient, codeServicesFromClient,
        contractId, contractName, stateId, intl, feeScheduleRules, codeServiceRules, getFeeSchdulePending, getRulesPending, getCodeServicePending, facilitiesFromTargetClientPending, facilitiesFromTargetClient,feeScheduleLabelsValidate,
        hasPPSConfiguations, IPPSConfigs, OPPSConfigs, IPPSFromTargetClient, OPPSFromTargetClient, getFeeScheduleLabelsFromTargetClientsPending,
        actions: { copyContract, getRulesByContractId, getFeeScheduleFromClient, resetRulesByContractId, getServicesFromTargetClient, setCopyContractName, getFacilitiesByTargetClient, copyContractReset, setCopyContractDialog,  
            getIPPSTinyByContract, getIPPSTinyByTargetClient, getOPPSTinyByContract, getOPPSTinyByTargetClient,getFeeScheduleLabelsFromTargetClient,
        getIPPSTinyByContractPending, getOPPSTinyByContractPending,validateLabelsFromTargetClient,

        }
    } = props;

    const [targetClient, setTargetClient] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);
    const [feeScheduleLabels, setFeeScheduleLabels] = useState({});

    const formikRef = useRef();

    const classes = useStyles();

    useEffect(() => {
        setSteps([{ id: 1, label: "Basic Details" }])
        if (hasRulesApiResponded && !getIPPSTinyByContractPending && !getOPPSTinyByContractPending) {
            if (feeScheduleRules.length === 0 && codeServiceRules.length === 0 && IPPSConfigs.length === 0 && OPPSConfigs.length === 0) {
                
            } else {                
                formikRef.current.setFieldValue('feeScheduleMapping',
                    feeScheduleRules.map(rule => ({
                        ruleId: rule.id,
                        ruleName: rule.name,
                        feeScheduleName: rule?.feeSchedule?.name,
                        sourceFeeScheduleId: rule?.feeSchedule?.id,
                        targetFeeScheduleId: { id: 0, name: 'Copy Fee Schedule' },
                        newFeeScheduleName: "",
                        feescheduleLabelToRateXRef:rule?.feescheduleLabelToRateXRef
                    }))
                );
                formikRef.current.setFieldValue('rateLabelMapping',
                    [ ...Array(feeScheduleRules?.length).keys()].map(()=>(null))

                );

                formikRef.current.setFieldValue('codeServiceMapping',
                    codeServiceRules.map(rule => ({
                        ruleId: rule.id,
                        ruleName: rule.name,
                        codeServiceName: rule?.codeService?.name,
                        sourceCodeServiceId: rule?.codeService?.id,
                        targetCodeServiceId: { id: 0, name: 'Copy Code Service' },
                        newCodeServiceName: ""
                    }))
                );

                formikRef.current.setFieldValue('ippsConfigMapping',
                    IPPSConfigs.map(config => ({
                        sourceIPPSConfigurationId: config.id,
                        Name: config.name,
                        targetIPPSId: { id: 0, name: 'Copy IPPS' },
                        newIPPSConfigurationName: ""
                    }))
                );
                formikRef.current.setFieldValue('oppsConfigMapping',
                    OPPSConfigs.map(config => ({
                        sourceOPPSConfigurationId: config.id,
                        Name: config.name,
                        targetOPPSId: { id: 0, name: 'Copy OPPS' },
                        newOPPSConfigurationName: ""
                    }))
                );

                // setActiveStep(1);
                if (feeScheduleRules.length > 0) {
                    setSteps(prevSteps => [...prevSteps, { id: 2, label:copyContractConstants.FEESCHEDULE }]);
                }
                if (codeServiceRules.length > 0) {
                    setSteps(prevSteps => [...prevSteps, { id: 3, label: copyContractConstants.CODESERVICE }]);
                }
                if(IPPSConfigs.length > 0 && OPPSConfigs.length > 0) {
                    setSteps(prevSteps => [...prevSteps, { id: 4, label: copyContractConstants.PPSCONFIGURATION }]);
                }
            }
        }
    }, [hasRulesApiResponded, feeScheduleRules, codeServiceRules, IPPSConfigs, OPPSConfigs]);

    useEffect(() => {
        const { actions: { clientListTiny } } = props;
        if (isSuperAdmin) {
            clientListTiny();
        }
        let selectedClient = null;
        selectedClient = props.clients.filter(function (client) { return client.clientId === props.selectedClient });
        setTargetClient(selectedClient[0] ? selectedClient[0] : null);
    }, [props.clients.length]);

    function handleClosing(state) {
        setActiveStep(0);
        resetRulesByContractId();
        setSteps([{ id: 1, label: "Basic Details" }]);
        setCopyContractName("");

        setCopyContractDialog({ 
            open: false, 
            existingContractId: null, 
            existingContractName: '', 
            existingContractStateId: null 
        });
        copyContractReset();
        if(state === true)
            handleRefresh();
        if (targetClient != null && targetClient.clientId !== props.selectedClient) {
            let selectedClient = null;
            selectedClient = props.clients.filter(function (client) { return client.clientId === props.selectedClient });
            setTargetClient(selectedClient[0] ? selectedClient[0] : null);
        }
    }

    function handleCallback() {
        handleClosing(true);
    }

    function handleGetRulesForCrossClient(newClient) {
        resetRulesByContractId();
        setSteps([{ id: 1, label: "Basic Details" }]);

        if (props.selectedClient !== newClient.clientId && newClient.schema) {
            getFacilitiesByTargetClient(newClient.schema);
            getRulesByContractId(contractId);

            getFeeScheduleFromClient(newClient.schema);
            getServicesFromTargetClient(newClient.schema);

            getIPPSTinyByContract(contractId);
            getOPPSTinyByContract(contractId);
            getIPPSTinyByTargetClient(newClient.schema);
            getOPPSTinyByTargetClient(newClient.schema);
        }
    }

    function handleBack() {
        setActiveStep((prevStep) => prevStep - 1);
    }

    function handleNext() {
        setActiveStep((prevStep) => prevStep + 1);
    }

    function handleNextWithValidation(_values) {
        
        if (steps[activeStep]?.label===copyContractConstants.FEESCHEDULE && _values.feeScheduleMapping.length > 0) {
            // custom error trigger for fee schedule
            let hasError = false;
            _values.feeScheduleMapping.forEach((item, index) => {
                if (item.targetFeeScheduleId.id === 0 && (!item.newFeeScheduleName || item.newFeeScheduleName === '')) {
                    formikRef.current.setFieldError(`feeScheduleMapping.${index}.newFeeScheduleName`, 'Required Field');
                    formikRef.current.setFieldTouched(`feeScheduleMapping.${index}.newFeeScheduleName`, true);
                    hasError = true;
                }
            });
            if (!hasError) {
                handleNext();
            }
        } 
        else if (steps[activeStep]?.label===copyContractConstants.CODESERVICE  && _values.codeServiceMapping.length > 0) {
            // custom error trigger for code service
            let hasError = false;
            _values.codeServiceMapping.forEach((item, index) => {
                if (item.targetCodeServiceId.id === 0 && (!item.newCodeServiceName || item.newCodeServiceName === '')) {
                    formikRef.current.setFieldError(`codeServiceMapping.${index}.newCodeServiceName`, 'Required Field');
                    formikRef.current.setFieldTouched(`codeServiceMapping.${index}.newCodeServiceName`, true);
                    hasError = true;
                }
            });
            if (!hasError) {
                handleNext();
            }
        }
        else {
            handleNext();
        }
    }

    function handleTargetFeeScheduleChange(setFieldValue, index, obj) {
        setFieldValue(`feeScheduleMapping.${index}.targetFeeScheduleId`, obj);
        if (obj.id === 0) {
            setFieldValue(`feeScheduleMapping.${index}.newFeeScheduleName`, '');
        }
    }

    function handleTargetRateLabelChange(setFieldValue, index, obj,rule) {
        setFieldValue(`rateLabelMapping.${index}.targetFeeScheduleId`, obj);
    
        validateLabelsFromTargetClient(targetClient.schema,rule.targetFeeScheduleId.id,obj?.id,index)

    }

    function handleNewFeeScheduleChange(setFieldValue, index, val) {
        setFieldValue(`feeScheduleMapping.${index}.newFeeScheduleName`, val);
    }

    function handleTargetCodeServiceChange(setFieldValue, index, obj) {
        setFieldValue(`codeServiceMapping.${index}.targetCodeServiceId`, obj);
        if (obj.id === 0) {
            setFieldValue(`codeServiceMapping.${index}.newCodeServiceName`, '');
        }
    }

    function handleNewCodeServicceeChange(setFieldValue, index, val) {
        setFieldValue(`codeServiceMapping.${index}.newCodeServiceName`, val);
    }

    function handleTargetIPPSChange(setFieldValue, index, obj) {
        setFieldValue(`ippsConfigMapping.${index}.targetIPPSId`, obj);
        if (obj.id === 0) {
            setFieldValue(`ippsConfigMapping.${index}.newIPPSConfigurationName`, '');
        }
    }

    function handleNewIPPSChange(setFieldValue, index, val) {
        setFieldValue(`ippsConfigMapping.${index}.newIPPSConfigurationName`, val);
    }

    function handleTargetOPPSChange(setFieldValue, index, obj) {
        setFieldValue(`oppsConfigMapping.${index}.targetOPPSId`, obj);
        if (obj.id === 0) {
            setFieldValue(`oppsConfigMapping.${index}.newOPPSConfigurationName`, '');
        }
    }

    function handleNewOPPSChange(setFieldValue, index, val) {
        setFieldValue(`oppsConfigMapping.${index}.newOPPSConfigurationName`, val);
    }

    function renderActionButtons(handleSubmit, values) {
        if (feeScheduleRules.length === 0 && codeServiceRules.length === 0 && IPPSConfigs.length === 0 && OPPSConfigs.length === 0) {
            return <>
                <Button
                    onClick={() => handleClosing(false)}
                    variant="contained"
                    color="default"
                    type='button'
                    className={classes.button}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={`ml-2 ${classes.button}`}
                    type="button"
                    onClick={handleSubmit}
                    disabled={copyContractPending}
                >
                    {(copyContractPending) ? 'Saving..' : 'Save'}
                </Button>
            </>
        } else if (activeStep + 1 === steps.length) {
            return <>
                <Button
                    onClick={handleBack}
                    variant="contained"
                    color="default"
                    type='button'
                    className={classes.button}>
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={`ml-2 ${classes.button}`}
                    type="button"
                    onClick={handleSubmit}
                    disabled={copyContractPending}
                >
                    {(copyContractPending) ? 'Saving..' : 'Save'}
                </Button>
            </>
        } else if (activeStep === 0) {
            return <>
                <Button
                    onClick={() => handleClosing(false)}
                    variant="contained"
                    color="default"
                    type='button'
                    className={classes.button}>
                    Cancel
                </Button>
                <Button
                    onClick={handleNext}
                    variant="contained"
                    color="primary"
                    type="button"
                    className={classes.button}>
                    Next
                </Button>
            </>
        }
        else {
            return <>
                <Button
                    onClick={handleBack}
                    variant="contained"
                    color="default"
                    type='button'
                    className={classes.button}>
                    Back
                </Button>
                <Button
                    onClick={() => handleNextWithValidation(values)}
                    variant="contained"
                    color="primary"
                    type="button"
                    className={classes.button}>
                    Next
                </Button>
            </>
        }

    }

    function getFeeScheduleHelperText(touched, key, index) {
        if (touched.feeScheduleMapping) {
            if (touched.feeScheduleMapping[index]) {
                return touched.feeScheduleMapping[index][key];
            }
            return false;
        }
        return false;
    }

    function getRateLabelMappingHelperText(touched, key, index) {
        if (touched.rateLabelMapping) {
            if (touched.rateLabelMapping[index]) {
                return 'Required Field';
            }
            return false;
        }
        return false;
    }

    function getFeeScheduleError(errors, key, index) {
        if (errors.feeScheduleMapError) {
            if (errors.feeScheduleMapError[index]) {
                return errors.feeScheduleMapError[index][key];
            }
            return false;
        }
        return false;
    }
    function getRateLabelError(errors, key, index) {
        if (errors.rateLabelMapError) {
            if (errors.rateLabelMapError[index]) {
                return errors.rateLabelMapError[index][key];
            }
            return false;
        }
        return false;
    }

    function getCodeServiceHelperText(touched, key, index) {
        if (touched.codeServiceMapping) {
            if (touched.codeServiceMapping[index]) {
                return touched.codeServiceMapping[index][key];
            }
            return false;
        }
        return false;
    }

    function getCodeServiceError(errors, key, index) {
        if (errors.codeServiceMapError) {
            if (errors.codeServiceMapError[index]) {
                return errors.codeServiceMapError[index][key];
            }
            return false;
        }
        return false;
    }

    function getIPPSHelperText(touched, key, index) {
        if (touched.ippsConfigMapping) {
            if (touched.ippsConfigMapping[index]) {
                return touched.ippsConfigMapping[index][key];
            }
            return false;
        }
        return false;
    }

    function getIPPSError(errors, key, index) {
        if (errors.ippsMapError) {
            if (errors.ippsMapError[index]) {
                return errors.ippsMapError[index][key];
            }
            return false;
        }
        return false;
    }

    function getOPPSHelperText(touched, key, index) {
        if (touched.oppsConfigMapping) {
            if (touched.oppsConfigMapping[index]) {
                return touched.oppsConfigMapping[index][key];
            }
            return false;
        }
        return false;
    }

    function getOPPSError(errors, key, index) {
        if (errors.oppsMapError) {
            if (errors.oppsMapError[index]) {
                return errors.oppsMapError[index][key];
            }
            return false;
        }
        return false;
    }

    const feeScheduleLabelsCallback = (data,id) => {
        setFeeScheduleLabels({...feeScheduleLabels,[id]:data})        
        
    }
    const fetchItemsBasedOnTargetClient = (rule) => {
        if(rule?.targetFeeScheduleId?.id in feeScheduleLabels===false)
        { 
            getFeeScheduleLabelsFromTargetClient(targetClient.schema,rule.targetFeeScheduleId.id,feeScheduleLabelsCallback)
        }        
        
    }
    // const name = (params) => {
        
    // }

    const filterOptions = createFilterOptions({
        stringify: option => option.name
    });


    return (
        <Dialog maxWidth={steps.length > 1 ? 'lg' : 'md'} fullWidth open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="copy-contract-title">
                Copy Contract
            </DialogTitle>
            <Loadable loading={copyContractPending || getFeeSchdulePending || getRulesPending || getCodeServicePending || facilitiesFromTargetClientPending}>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    innerRef={formikRef}
                    validate={values => {
                        const errors = {};
                        if (!values.newContractName) {
                            errors.newContractName = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        const feeScheduleMapError = values.feeScheduleMapping.map((rule) => {
                            const feeScheduleMapErrorObj = {};
                            let hasError = false;

                            if (rule.targetFeeScheduleId.id === 0 && !rule.newFeeScheduleName) {
                                feeScheduleMapErrorObj['newFeeScheduleName'] = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                                hasError = true;
                            }


                            if (hasError) {
                                return feeScheduleMapErrorObj;
                            }

                            return false;
                        });
                        

                        let availabilityError=false
                        const rateLabelMapError = values.rateLabelMapping.map((rateLabel,index) => {
                            const rateLabelErrorObj = {};
                            let hasError = false;

                            if (values?.feeScheduleMapping[index]?.targetFeeScheduleId.id !== 0 && !rateLabel) {
                                rateLabelErrorObj['targetRateLabel'] = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                                hasError = true;
                            }

                            



                            if (hasError) {
                                return rateLabelErrorObj;
                            }

                            return false;
                        });

                        const filteredFeeArray = feeScheduleMapError.filter(value => Object.keys(value).length > 0);
                        const filteredRatesArray = rateLabelMapError.filter(value => Object.keys(value).length > 0);


                        if (filteredFeeArray.length > 0) {
                            errors['feeScheduleMapError'] = feeScheduleMapError;
                        }


                        if (filteredRatesArray.length > 0) {
                            errors['rateLabelMapError'] = rateLabelMapError;
                        }
                        const codeServiceMapError = values.codeServiceMapping.map((rule) => {
                            const codeServiceMapErrorObj = {};
                            let hasError = false;

                            if (rule.targetCodeServiceId.id === 0 && !rule.newCodeServiceName) {
                                codeServiceMapErrorObj['newCodeServiceName'] = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                                hasError = true;
                            }


                            if (hasError) {
                                return codeServiceMapErrorObj;
                            }

                            return false;
                        });

                        const filteredCodeArray = codeServiceMapError.filter(value => Object.keys(value).length > 0);

                        if (filteredCodeArray.length > 0) {
                            errors['codeServiceMapError'] = codeServiceMapError;
                        }

                        const ippsMapError = values.ippsConfigMapping.map((config) => {
                            const ippsMapErrorObj = {};
                            let hasError = false;

                            if (config.targetIPPSId.id === 0 && !config.newIPPSConfigurationName) {
                                ippsMapErrorObj['newIPPSConfigurationName'] = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                                hasError = true;
                            }

                            if (hasError) {
                                return ippsMapErrorObj;
                            }

                            return false;
                        });
                        const filteredIPPSArray = ippsMapError.filter(value => Object.keys(value).length > 0);

                        if (filteredIPPSArray.length > 0) {
                            errors['ippsMapError'] = ippsMapError;
                        }

                        const oppsMapError = values.oppsConfigMapping.map((config) => {
                            const oppsMapErrorObj = {};
                            let hasError = false;
                            if (config && config.targetOPPSId.id === 0 && !config.newOPPSConfigurationName) {
                                oppsMapErrorObj['newOPPSConfigurationName'] = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                                hasError = true;
                            }

                            if (hasError) {
                                return oppsMapErrorObj;
                            }

                            return false;
                        });
                        const filteredOPPSArray = oppsMapError.filter(value => Object.keys(value).length > 0);

                        if (filteredOPPSArray.length > 0) {
                            errors['oppsMapError'] = oppsMapError;
                        }

                        
                        return errors;
                    }}
                    onSubmit={(values) => {

                        let availibilityError=false
                    
                        values.rateLabelMapping.map((rateLabel,index) => {
                            if(feeScheduleLabelsValidate[`done-${index}`]===true)
                            {availibilityError=true}
                        });
                        if(availibilityError)
                        {
                            return
                        }
                        if (isSuperAdmin && targetClient.clientId !== props.selectedClient) {
                            let payload = {
                                sourceClient: props.selectedClientSchema,
                                targetClient: targetClient.schema,
                                contractId: contractId,
                                newContractStateId: stateId,
                                newContractName: values.newContractName,
                                targetFacilities: values.targetFacilities.map(item => item.id),
                                feeScheduleMapping:values.feeScheduleMapping.map((item,index) => ({ ...item, targetFeeScheduleId: item.targetFeeScheduleId.id,sourceFeeScheduleLabelXrefId:values?.rateLabelMapping[index] ? null:item?.feescheduleLabelToRateXRef?.id,targetFeeScheduleLabelXrefId:values?.rateLabelMapping[index] ?values?.rateLabelMapping[index]?.targetFeeScheduleId?.id  : null })),
                                
                                codeServiceMapping: values.codeServiceMapping.map(item => ({ ...item, targetCodeServiceId: item.targetCodeServiceId.id })),
                                IPPSConfigurationMapping: values.ippsConfigMapping.map(item => ({ ...item, targetIPPSConfigurationId: item.targetIPPSId.id })),
                                OPPSConfigurationMapping: values.oppsConfigMapping.map(item => ({ ...item, targetOPPSConfigurationId: item.targetOPPSId.id }))
                            }

                            copyContract(payload, handleCallback)  
                             //this calls correct API based on payload
                        }
                        else {
                            let payload = {
                                contractId: contractId,
                                newContractName: values.newContractName,
                                newContractStateId: stateId,
                            }
                            copyContract(payload, handleCallback)
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                            <DialogContent dividers>
                                <>
                                    {steps.length > 1 && <>
                                        <Stepper activeStep={activeStep} alternativeLabel>
                                            {steps.map(({ label }) => (
                                                <Step key={label}>
                                                    <StepLabel ><h4 className='font-16'>{label}</h4></StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        <hr /></>
                                    }

                                    <div className={classes.root}>
                                        {activeStep === 0 ? <Grid container className='pb-2 pl-2'>
                                            <Grid className='mt-4' item xs={6} md={6}>
                                                <TextField
                                                    label="Existing contract name"
                                                    name="contractName"
                                                    className={classes.field}
                                                    value={contractName}
                                                    inputProps={
                                                        { readOnly: true, }
                                                    }
                                                />
                                            </Grid>
                                            <Grid className='mt-4' item xs={6} md={6}>
                                                <TextField
                                                    autoFocus
                                                    required
                                                    label="New Contract Name"
                                                    name="newContractName"
                                                    className={classes.field}
                                                    value={values.newContractName}
                                                    onBlur={handleBlur}
                                                    onChange={e => {
                                                        handleChange(e);
                                                        setCopyContractName(e.target.value);
                                                    }}
                                                    error={Boolean(touched.newContractName && errors.newContractName)}
                                                    helperText={touched.newContractName && errors.newContractName}
                                                />
                                            </Grid>
                                            <Grid className='mt-4' item xs={6} md={6}>
                                                <Autocomplete
                                                    onChange={(event, newValue) => {
                                                        if (newValue != null) {
                                                            setTargetClient(newValue);
                                                            handleGetRulesForCrossClient(newValue);
                                                        }
                                                    }}
                                                    className={classes.field}
                                                    value={targetClient}
                                                    options={orderBy(props.clients, ['name'])}
                                                    required={isSuperAdmin}
                                                    hidden={!isSuperAdmin}
                                                    // inputProps={
                                                    //     { required: isSuperAdmin, }
                                                    // }
                                                    getOptionLabel={(option) => option.name ? option.name : ''}
                                                    getOptionSelected={(option, value) => value.name === option.name}
                                                    renderInput={params => (
                                                        <TextField {...params} label="Target Client *"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                                {/* <div className="warning" hidden={!isSuperAdmin || props.selectedClient === targetClient.clientId}>
                                                    <Message theme="warning">
                                                        All referenced Schedules and Services will be copied with the contract to the selected client.
                                                    </Message>
                                                </div> */}
                                            </Grid>
                                            {props.selectedClient !== targetClient.clientId && targetClient.schema &&
                                                <Grid className='mt-4' item xs={6} md={6}>
                                                    <FormControl className={classes.formControlAuto}>
                                                        <Autocomplete
                                                            onChange={(e, value) => setFieldValue("targetFacilities", value)}
                                                            filterOptions={filterOptions}
                                                            value={values.targetFacilities}
                                                            multiple
                                                            options={facilitiesFromTargetClient}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(option) => option.name}
                                                            renderOption={(option, { selected }) => (
                                                                <React.Fragment>
                                                                    <Checkbox
                                                                        icon={icon}
                                                                        checkedIcon={checkedIcon}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                    />
                                                                    {option.name}
                                                                </React.Fragment>
                                                            )}
                                                            renderTags={(tagValue, getTagProps) => {
                                                                const numTags = tagValue?.length;
                                                                return (
                                                                    <Typography variant="caption">
                                                                        {tagValue?.slice(0, 2).map((option, index) => (
                                                                            <Chip
                                                                                size="small"
                                                                                color="secondary"
                                                                                className={classes.chip}
                                                                                key={`key-${index}`}
                                                                                label={option.name}
                                                                                {...getTagProps({ index })}
                                                                            />
                                                                        ))}
                                                                        {numTags > 2 && ` + ${numTags - 2}`}
                                                                    </Typography>
                                                                )
                                                            }
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Facilties"
                                                                    error={Boolean(touched.targetFacilities && errors.targetFacilities)}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText error>{touched.targetFacilities && errors.targetFacilities}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            }
                                        </Grid> :steps[activeStep]?.label===copyContractConstants.FEESCHEDULE ? <>

                                            {values.feeScheduleMapping.map((rule, index) => <Grid container className='pb-4 align-items-center justify-content-center'>
                                                <Grid xs={4} className='px-3'>
                                                    <Grid className={classes.w90}>
                                                        <div><b>{rule.ruleName}</b></div>
                                                        <div><span className='font-12'><i>Fee Schedule</i>: {rule.feeScheduleName}</span> </div>
                                                        <div><span className='font-12'><i>Rate Label</i>: {rule?.feescheduleLabelToRateXRef?.label}</span> </div>

                                                    </Grid>

                                                </Grid>
                                                <Grid xs={4}>
                                                    {/* <Autocomplete
                                                        onChange={(e, value) => handleTargetFeeScheduleChange(setFieldValue, index, value)}
                                                        name={`feeScheduleMapping.${index}.targetFeeScheduleId`}
                                                        className={classes.w90}
                                                        value={rule.targetFeeScheduleId}
                                                        options={[{ id: 0, name: 'Copy Fee Schedule' }, ...feeSchedulesFromClient]}
                                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                                        getOptionSelected={(option, value) => value.name === option.name}
                                                        renderInput={params => (
                                                            <TextField {...params} label="Target Fee Schedule *"
                                                                fullWidth
                                                            />
                                                        )}
                                                        disableClearable
                                                    /> */}


                                                    <FeeScheduleSelection targetClient={targetClient?.schema} defaultValue='Copy Fee Schedule'  handleSetFeeSchedule={(rowData) => handleTargetFeeScheduleChange(setFieldValue, index, rowData)} value={rule.targetFeeScheduleId?.name}/>
                                                </Grid>
                                                
                                                {   rule.targetFeeScheduleId.id !== 0  &&                                      
                                                    <Grid xs={4}>
                                                        <Autocomplete
                                                            onChange={(e, value) => handleTargetRateLabelChange(setFieldValue, index, value,rule)}
                                                            name={`rateLabelMapping.${index}.targetRateLabel`}
                                                            className={classes.w90}
                                                            value={rule.targetRateLabel}
                                                            options={feeScheduleLabels[rule.targetFeeScheduleId.id] || []}
                                                            onOpen={()=> fetchItemsBasedOnTargetClient(rule)
                                                            }

                                                            noOptionsText={
                                                                getFeeScheduleLabelsFromTargetClientsPending ? (
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                                                    <CircularProgress size={10} />
                                                                    </Box>
                                                                ) : (
                                                                'No options'
                                                                )
                                                            }
                                                            getOptionLabel={(option) => option.label ? option.label : ''}
                                                            getOptionSelected={(option, value) => value.name === option.name}
                                                            renderInput={params => (

                                                                <Box style={{position:'relative'}}>
   
                                                                <TextField
                                                                {...params}
                                                                label="Target Rate Label *"
                                                                    helperText={feeScheduleLabelsValidate[`done-${index}`]===true ? 'This rate label has no value ': getRateLabelMappingHelperText(touched, 'targetRateLabel', index) && getRateLabelError(errors, 'targetRateLabel', index)}
                                                                    error={feeScheduleLabelsValidate[`done-${index}`]===true ||  (Boolean(getRateLabelMappingHelperText(touched, 'targetRateLabel', index) && getRateLabelError(errors, 'targetRateLabel', index)))}
                                                                    fullWidth
                                                            />
                                                            {feeScheduleLabelsValidate[`pending-${index}`]===true ? 
                                                                <Box className={`${classes.iconsContainer}`}>
                                                                    <CircularProgress size={10} /> 
                                                                </Box>
                                                                    : 
                                                            feeScheduleLabelsValidate[`pending-${index}`]===false &&
                                                                <Box className={`${classes.iconsContainer}`} >
                                                                    {feeScheduleLabelsValidate[`done-${index}`]   ? 
                                                                    (
                                                                    <CloseIcon className={classes.errorIcon} />    
                                                                    ) : 
                                                                    (
                                                                    <CheckIcon className={classes.successIcon} />
                                                                    )}
                                                                </Box>
                                                            }
                                                                        
                                                            </Box>



                                                            )}
                                                        
                                                            disableClearable
                                                            
                                                        />
                                                    </Grid>}
                                                <Grid item xs={4}>
                                                    {rule.targetFeeScheduleId.id === 0 &&
                                                        <TextField
                                                            required
                                                            label="New Fee Schdule Name"
                                                            name={`feeScheduleMapping.${index}.newFeeScheduleName`}
                                                            className={classes.w90}
                                                            value={rule.newFeeScheduleName}
                                                            onBlur={handleBlur}
                                                            onChange={e => handleNewFeeScheduleChange(setFieldValue, index, e.target.value)}
                                                            helperText={getFeeScheduleHelperText(touched, 'newFeeScheduleName', index) && getFeeScheduleError(errors, 'newFeeScheduleName', index)}
                                                            error={Boolean(getFeeScheduleHelperText(touched, 'newFeeScheduleName', index) && getFeeScheduleError(errors, 'newFeeScheduleName', index))}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>)}
                                            {values.feeScheduleMapping.length === 0 && <Grid className='text-center'> <span>No Fee Schedule available</span> </Grid>}
                                        </> : steps[activeStep]?.label===copyContractConstants.CODESERVICE ? <>
                                            {values.codeServiceMapping.map((rule, index) => <Grid container className='pb-4 align-items-center justify-content-center'>
                                                <Grid xs={4} className='pr-3'>
                                                    <Grid className={classes.w90}>
                                                        <div><b>{rule.ruleName}</b></div>
                                                        <div><span className='font-12'><i>Code Service</i>: {rule.codeServiceName}</span> </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={4}>
                                                    <Autocomplete
                                                        onChange={(e, value) => handleTargetCodeServiceChange(setFieldValue, index, value)}
                                                        name={`codeServiceMapping.${index}.targetCodeServiceId`}
                                                        className={classes.w90}
                                                        value={rule.targetCodeServiceId}
                                                        options={[{ id: 0, name: 'Copy Code Service' }, ...codeServicesFromClient]}
                                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                                        getOptionSelected={(option, value) => value.name === option.name}
                                                        renderInput={params => (
                                                            <TextField {...params} label="Target Code Service *"
                                                                fullWidth
                                                            />
                                                        )}
                                                        disableClearable
                                                    />
                                                </Grid>
                                                <Grid xs={4}>
                                                    {rule.targetCodeServiceId.id === 0 &&
                                                        <TextField
                                                            required
                                                            label="New Code Service Name"
                                                            name={`codeServiceMapping.${index}.newCodeServiceName`}
                                                            className={classes.w90}
                                                            value={rule.newCodeServiceName}
                                                            onBlur={handleBlur}
                                                            onChange={e => handleNewCodeServicceeChange(setFieldValue, index, e.target.value)}
                                                            helperText={getCodeServiceHelperText(touched, 'newCodeServiceName', index) && getCodeServiceError(errors, 'newCodeServiceName', index)}
                                                            error={Boolean(getCodeServiceHelperText(touched, 'newCodeServiceName', index) && getCodeServiceError(errors, 'newCodeServiceName', index))}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>)}
                                            {values.codeServiceMapping.length === 0 && <Grid className='text-center'>
                                                <span>No Code Service available</span></Grid>}
                                        </> : <>
                                        {hasPPSConfiguations &&  values.ippsConfigMapping.map((config, index) => <Grid container className='pb-4 align-items-center justify-content-center'>
                                                <Grid xs={4} className='px-3'>
                                                    <Grid className={classes.w90}>
                                                    <div><b>{`IPPS - ${config.Name}`}</b></div>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={4}>
                                                    <Autocomplete
                                                        onChange={(e, value) => handleTargetIPPSChange(setFieldValue, index, value)}
                                                        name={`ippsConfigMapping.${index}.targetIPPSId`}
                                                        className={classes.w90}
                                                        value={config.targetIPPSId}
                                                        options={[{ id: 0, name: 'Copy IPPS' }, ...IPPSFromTargetClient]}
                                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                                        getOptionSelected={(option, value) => value.name === option.name}
                                                        renderInput={params => (
                                                            <TextField {...params} label="Target IPPS *"
                                                                fullWidth
                                                            />
                                                        )}
                                                        disableClearable
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {config.targetIPPSId.id === 0 &&
                                                        <TextField
                                                            required
                                                            label="New IPPS Name"
                                                            name={`ippsConfigMapping.${index}.newIPPSConfigurationName`}
                                                            className={classes.w90}
                                                            value={config.newIPPSConfigurationName}
                                                            onBlur={handleBlur}
                                                            onChange={e => handleNewIPPSChange(setFieldValue, index, e.target.value)}
                                                            helperText={getIPPSHelperText(touched, 'newIPPSConfigurationName', index) && getIPPSError(errors, 'newIPPSConfigurationName', index)}
                                                            error={Boolean(getIPPSHelperText(touched, 'newIPPSConfigurationName', index) && getIPPSError(errors, 'newIPPSConfigurationName', index))}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>)}
                                            {hasPPSConfiguations &&  values.oppsConfigMapping.map((config, index) => <Grid container className='pb-4 align-items-center justify-content-center'>
                                                <Grid xs={4} className='px-3'>
                                                    <Grid className={classes.w90}>
                                                        <div><b>{`OPPS - ${config.Name}`}</b></div>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={4}>
                                                    <Autocomplete
                                                        onChange={(e, value) => handleTargetOPPSChange(setFieldValue, index, value)}
                                                        name={`oppsConfigMapping.${index}.targetOPPSId`}
                                                        className={classes.w90}
                                                        value={config.targetOPPSId}
                                                        options={[{ id: 0, name: 'Copy OPPS' }, ...OPPSFromTargetClient]}
                                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                                        getOptionSelected={(option, value) => value.name === option.name}
                                                        renderInput={params => (
                                                            <TextField {...params} label="Target OPPS *"
                                                                fullWidth
                                                            />
                                                        )}
                                                        disableClearable
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {config.targetOPPSId.id === 0 &&
                                                        <TextField
                                                            required
                                                            label="New OPPS Name"
                                                            name={`oppsConfigMapping.${index}.newOPPSConfigurationName`}
                                                            className={classes.w90}
                                                            value={config.newOPPSConfigurationName}
                                                            onBlur={handleBlur}
                                                            onChange={e => handleNewOPPSChange(setFieldValue, index, e.target.value)}
                                                            helperText={getOPPSHelperText(touched, 'newOPPSConfigurationName', index) && getOPPSError(errors, 'newOPPSConfigurationName', index)}
                                                            error={Boolean(getOPPSHelperText(touched, 'newOPPSConfigurationName', index) && getOPPSError(errors, 'newOPPSConfigurationName', index))}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>)}
                                        
                                        </>}
                                    </div>
                                </>
                            </DialogContent>
                            <DialogActions>
                                <Grid container className='px-3' justifyContent="flex-end">
                                    {renderActionButtons(handleSubmit, values)}
                                </Grid>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Loadable>
        </Dialog >
    )
}