import {
    METHOD_GET,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson } from '../headers';
import { apiHandleResponse } from '../../util/api';

const getJobStatistics = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/jobstatistics`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getS3Listners = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/listeners`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfRedshift = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/redshift`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfRDS = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/sql`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfECS = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/ecs`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfNetwork = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/network`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfSQS = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/sqs`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfS3 = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/s3`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfCloudwatch = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/cloudwatch`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfCloudfront = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/cloudfront`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getHealthOfSecretManager = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/health/credentialstore`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getQuickSightsDataSetList = () => {
    return fetch(`${SERVER_URL}/api/v1/controlroom/quicksights/datasets/list`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getJobStatistics,
    getS3Listners,
    getHealthOfRedshift,
    getHealthOfRDS,
    getHealthOfECS,
    getHealthOfNetwork,
    getHealthOfSQS,
    getHealthOfS3,
    getHealthOfCloudwatch,
    getHealthOfCloudfront,
    getHealthOfSecretManager,
    getQuickSightsDataSetList
};