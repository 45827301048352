import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/pricers';
import * as API from '../api/pricers';
import { sessionErrorHandling } from './session';

function* lookup(action) {
    try {
        yield put({ type: actions.PRICERS_LOOKUP_PENDING });
        const {
            keyword,
            callback
        } = action.payload;
        const payload = yield call(API.lookup, keyword);
        yield put({ type: actions.PRICERS_LOOKUP_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_LOOKUP_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getCharges(action) {
    try {
        yield put({ type: actions.PRICERS_GET_CHARGES_PENDING });
        const {
            chargePayload,
            callback,
        } = action.payload;
        const payload = yield call(API.getCharges, chargePayload);
        yield put({ type: actions.PRICERS_GET_CHARGES_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_GET_CHARGES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getAllowables(action) {
    try {
        yield put({ type: actions.PRICERS_GET_ALLOWABLES_PENDING });
        const {
            chargeCode,
            callback,
        } = action.payload;
        const payload = yield call(API.getAllowables, chargeCode);
        yield put({ type: actions.PRICERS_GET_ALLOWABLES_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_GET_ALLOWABLES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* lookupV2(action) {
    try {
        yield put({ type: actions.PRICERS_LOOKUP_V2_PENDING });
        const {
            keyword,
            callback
        } = action.payload;
        const payload = yield call(API.lookupV2, keyword);
        yield put({ type: actions.PRICERS_LOOKUP_V2_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_LOOKUP_V2_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFacilities(action) {
    try {
        yield put({ type: actions.PRICERS_GET_FACILITIES_V2_PENDING });
        const { brandId, callback } = action.payload;
        const payload = yield call(API.getFacilities, brandId);
        yield put({ type: actions.PRICERS_GET_FACILITIES_V2_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_GET_FACILITIES_V2_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDepartments(action) {
    try {
        yield put({ type: actions.PRICERS_GET_DEPARTMENTS_V2_PENDING });
        const {
            departmentPayload,
            callback,
        } = action.payload;
        const payload = yield call(API.getDepartments, departmentPayload);
        yield put({ type: actions.PRICERS_GET_DEPARTMENTS_V2_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_GET_DEPARTMENTS_V2_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getNegotiatedRates(action) {
    try {
        yield put({ type: actions.PRICERS_GET_NEGOTIATED_RATES_V2_PENDING });
        const {
            negotiatedRatesPaylaod,
            callback,
        } = action.payload;
        const payload = yield call(API.getNegotiatedRates, negotiatedRatesPaylaod);
        yield put({ type: actions.PRICERS_GET_NEGOTIATED_RATES_V2_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_GET_NEGOTIATED_RATES_V2_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getAllowableRates(action) {
    try {
        yield put({ type: actions.PRICERS_GET_ALOWABLES_V2_PENDING });
        const {
            allowablePayload,
            callback,
        } = action.payload;
        const payload = yield call(API.getAllowablesRates, allowablePayload);
        yield put({ type: actions.PRICERS_GET_ALOWABLES_V2_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_GET_ALOWABLES_V2_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayPercentage(action) {
    try {
        yield put({ type: actions.PRICERS_GET_SELF_PAY_PERCENTAGE_PENDING });
        const { brandId, callback } = action.payload;
        const payload = yield call(API.getPayPercentage, brandId);
        yield put({ type: actions.PRICERS_GET_SELF_PAY_PERCENTAGE_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_GET_SELF_PAY_PERCENTAGE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* exportCsv(action) {
    try {
        const { callback } = action.payload;
        yield put({ type: actions.PRICERS_EXPORT_CSV_PENDING });
        const response = yield call(API.exportCsv);
        const payload = response.payload;
        const filename = response.filename;
        const mrfResponse = response.headers.get('X-MRF');

        yield put({ type: actions.PRICERS_EXPORT_CSV_FULFILLED });

        if (callback) {
            callback(payload, mrfResponse, filename);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_EXPORT_CSV_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


function* getOop(action) {
    try {
        const { oopPayload, callback } = action.payload;
        yield put({ type: actions.PRICERS_GET_OOP_PENDING });
        const payload = yield call(API.getOop, oopPayload);
        yield put({ type: actions.PRICERS_GET_OOP_FULFILLED, payload });

        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_GET_OOP_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* addBooking(action) {
    try {
        yield put({ type: actions.PRICERS_ADD_BOOKING_PENDING });
        const {
            bookingPayload,
            callback
        } = action.payload;
        const payload = yield call(API.addBooking, bookingPayload);
        yield put({ type: actions.PRICERS_ADD_BOOKING_FULFILLED, payload });

        if (callback) {
            callback(true);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICERS_ADD_BOOKING_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getChargeMasterStats(action) {
    try {
        yield put({ type: actions.PRICER_GET_CHARGE_MASTER_STATS_PENDING });

        const payload = yield call(API.getChargeMasterStats);
        yield put({ type: actions.PRICER_GET_CHARGE_MASTER_STATS_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_GET_CHARGE_MASTER_STATS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getShoppablesStats(action) {
    try {
        yield put({ type: actions.PRICER_GET_SHOPPABLES_STATS_PENDING });

        const payload = yield call(API.getShoppablesStats);
        yield put({ type: actions.PRICER_GET_SHOPPABLES_STATS_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_GET_SHOPPABLES_STATS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateShoppablesConfig(action) {
    try {
        const { callback } = action.payload;
        yield put({ type: actions.PRICER_UPDATE_SHOPPABLES_CONFIG_PENDING });

        const payload = yield call(API.updateShoppableConfiguration);
        yield put({ type: actions.PRICER_UPDATE_SHOPPABLES_CONFIG_FULFILLED, payload });
        if (callback) {
            callback();
        }

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_UPDATE_SHOPPABLES_CONFIG_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPricerConfigContracts(action) {
    try {
        yield put({ type: actions.PRICER_CONFIG_GET_CONTRACTS_PENDING });

        const payload = yield call(API.getPricerConfigContracts);
        yield put({ type: actions.PRICER_CONFIG_GET_CONTRACTS_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_CONFIG_GET_CONTRACTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* processContractsForRates(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.PRICER_CONFIG_PROCESS_CONTRACTS_PENDING });

        const payload = yield call(API.processContractsForRates, options);
        yield put({ type: actions.PRICER_CONFIG_PROCESS_CONTRACTS_FULFILLED, payload });
        if (callback) {
            callback();
        }

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_CONFIG_PROCESS_CONTRACTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getConfigNegotiatedRates(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.PRICER_CONFIG_GET_RATES_PENDING });


        const payload = yield call(API.getConfigNegotiatedRates, options);
        yield put({ type: actions.PRICER_CONFIG_GET_RATES_FULFILLED, payload });
        if (callback) {
            callback();
        }

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_CONFIG_GET_RATES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getNegotiatedRatesState(action) {
    try {
        const { callback } = action.payload;
        yield put({ type: actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_PENDING });

        const payload = yield call(API.getNegotiatedRatesState);

        if (callback) {
            callback(payload);
        }
        yield put({ type: actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* cleanupAndProcess(action) {
    try {
        const { callback } = action.payload;
        yield put({ type: actions.PRICER_CONFIG_CLEANUP_PROCESS_PENDING });

        const payload = yield call(API.configCleanupAndProcess);

        if (callback) {
            callback(payload);
        }
        yield put({ type: actions.PRICER_CONFIG_CLEANUP_PROCESS_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_CONFIG_CLEANUP_PROCESS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* publishMRFs(action) {
    try {
        const { callback } = action.payload;
        yield put({ type: actions.PRICER_CONFIG_PUBLISH_MRF_PENDING });

        const payload = yield call(API.publishMRFs);

        if (callback) {
            callback(payload);
        }
        yield put({ type: actions.PRICER_CONFIG_PUBLISH_MRF_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_CONFIG_PUBLISH_MRF_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getNegotiatedRatesSignOff(action) {
    try {
        yield put({ type: actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_PENDING });
        const payload = yield call(API.getNegotiatedRatesSignOff);
        yield put({ type: actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* negotiatedRatesSignOff(action) {
    try {
        yield put({ type: actions.PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_PENDING });
        const { callback } = action.payload;
        const payload = yield call(API.negotiatedRatesSignOff);
        yield put({ type: actions.PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.PRICERS_RESET });
}

export default function* () {
    yield takeEvery(actions.PRICERS_LOOKUP, lookup);
    yield takeEvery(actions.PRICERS_GET_CHARGES, getCharges);
    yield takeEvery(actions.PRICERS_GET_ALLOWABLES, getAllowables);
    //V2
    yield takeEvery(actions.PRICERS_LOOKUP_V2, lookupV2);
    yield takeEvery(actions.PRICERS_GET_FACILITIES_V2, getFacilities);
    yield takeEvery(actions.PRICERS_GET_DEPARTMENTS_V2, getDepartments);
    yield takeEvery(actions.PRICERS_GET_NEGOTIATED_RATES_V2, getNegotiatedRates);
    yield takeEvery(actions.PRICERS_GET_ALOWABLES_V2, getAllowableRates);
    yield takeEvery(actions.PRICERS_GET_SELF_PAY_PERCENTAGE, getPayPercentage);
    yield takeEvery(actions.PRICERS_EXPORT_CSV, exportCsv);
    yield takeEvery(actions.PRICERS_GET_OOP, getOop);
    yield takeEvery(actions.PRICERS_ADD_BOOKING, addBooking);
    yield takeEvery(actions.PRICER_GET_CHARGE_MASTER_STATS, getChargeMasterStats);
    yield takeEvery(actions.PRICER_GET_SHOPPABLES_STATS, getShoppablesStats);
    yield takeEvery(actions.PRICER_UPDATE_SHOPPABLES_CONFIG, updateShoppablesConfig);
    yield takeEvery(actions.PRICER_CONFIG_GET_CONTRACTS, getPricerConfigContracts);
    yield takeEvery(actions.PRICER_CONFIG_PROCESS_CONTRACTS, processContractsForRates);
    yield takeEvery(actions.PRICER_CONFIG_GET_RATES, getConfigNegotiatedRates);
    yield takeEvery(actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE, getNegotiatedRatesState);
    yield takeEvery(actions.PRICER_CONFIG_CLEANUP_PROCESS, cleanupAndProcess);
    yield takeEvery(actions.PRICER_CONFIG_PUBLISH_MRF, publishMRFs);
    yield takeEvery(actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF, getNegotiatedRatesSignOff);
    yield takeEvery(actions.PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF, negotiatedRatesSignOff);
}

