import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { getAlertUsers } from '../../../store/actions/contracts';
import { createContractSelector, selectAlertUsers } from "../../../store/selectors/contracts";
import { selectSessionUserPreferredClientId } from "../../../store/selectors/session";
import VendorViewComponent from './VendorView';
import { createStringDataField } from '../../../util/format';
import { list } from "../../../store/actions/services";
import { list as getFacilities } from "../../../store/actions/facilities";
import { selectFacilityList } from '../../../store/selectors/facilities';
import { getVendorContract } from '../../../store/actions/vendors';
import { createVendorContractSelector } from '../../../store/selectors/vendors';

export const VendorViewPage = connect(
    () => {
        const selectVendorContractId = (state, props) => props.match.params.contractId;
        const selectContract = createVendorContractSelector(selectVendorContractId);

        const selectAlertContacts = createSelector(
            selectAlertUsers,
            selectContract,
            (allUsers, contract) => {
                if (allUsers && allUsers.length > 0 && contract && contract.alertContacts) {
                    return allUsers.filter(user => contract.alertContacts.some(y => y.userId == user.id));
                }
                return [];
            }
        );

        const selectFacilities = createSelector(
            selectFacilityList,
            selectContract,
            (allFacilities, contract) => {
                if (allFacilities && allFacilities.length > 0 && contract && contract.contractFacilityXref) {
                    return allFacilities.filter(facility => contract.contractFacilityXref.some(y => y.facilityId == facility.id));
                }
                return [];
            }
        );

        const selectFields = createSelector(
            () => [
                createStringDataField('name', 'Name'),
                createStringDataField('title', 'Title'),
                createStringDataField('emails', 'Emails'),
                createStringDataField('phone', 'Phone'),
            ]
        );

        return (state, props) => ({
            contractsGetPending: state.vendors.get.pending,
            contractsGetAlertUsersPending: state.contracts.getAlertUsers.pending,
            contract: selectContract(state, props),
            contractId: selectVendorContractId(state, props),
            vendorContactFields: selectFields(),
            clientId: selectSessionUserPreferredClientId(state),
            alertUsers: selectAlertContacts(state, props),
            servicesTab: state.contracts.servicesTab,
            facilityXRef: selectFacilities(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getContract: getVendorContract,
            contractGetAlertUsers: getAlertUsers,
            getConfigs: list,
            getFacilities: getFacilities
        }, dispatch)
    })
)(injectIntl(VendorViewComponent));
