import React, { useEffect } from 'react';
import {
    Button, Dialog, DialogContent, DialogTitle, TextField, makeStyles, Grid, FormControl, Checkbox, Typography, Chip, FormControlLabel, Switch, Tooltip,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { Formik } from "formik";
import { Loadable } from '../../../common';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { PayerPlanDropdown } from '../PayerPlanDropdown';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    checkboxlabel: {
        marginLeft: 0
    },
    switch: {
        margin: theme.spacing(1),
    },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function PayerContractDialog(props) {
    const {
        open, handleClose, mode, intl, initialValues, payerContractCreatePending,
        payerContractUpdatePending, getPayerPlansTinyPending, contractsList,
        actions: { payerContractCreate, payerContractUpdate, contractTiny, getPayerPlansTiny }
    } = props;
    const classes = useStyles();
    let shouldReloadGrid = false;
    useEffect(() => {
        contractTiny();
    }, [contractTiny]);

    useEffect(() => {
        getPayerPlansTiny();
    }, [getPayerPlansTiny]);

    function handleCallback(reload) {
        handleClose(reload);
    }

    const filterOptions = createFilterOptions({
        stringify: option => option.name + ' ' + option.email,
    });

    return (
        <Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === 'edit' ? 'Edit Payer Contract' : 'Add Payer Contract'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={payerContractCreatePending || payerContractUpdatePending || getPayerPlansTinyPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            if (!values.displayName) {
                                errors.displayName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.plan) {
                                errors.plan = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            shouldReloadGrid = true;
                            let payload = {
                                displayName: values.displayName,
                                autoClaimAnalysis: values.autoClaimAnalysis,
                                includeInMachineReadable: values.includeInMachineReadable
                            }
                            if (values.contracts) {
                                payload = {
                                    ...payload,
                                    contractIds: values.contracts.map(contract => contract.id),
                                }
                            }
                            if (values.plan) {
                                payload = {
                                    ...payload,
                                    payerPlanId: values.plan.id,
                                }
                            }
                            if (mode === 'create') {
                                payerContractCreate(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                payload = {
                                    ...payload,
                                    id: values.id,
                                }
                                payerContractUpdate(payload, handleCallback, shouldReloadGrid);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                required
                                                label="Display Name"
                                                name="displayName"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.displayName}
                                                helperText={touched.displayName && errors.displayName}
                                                error={Boolean(touched.displayName && errors.displayName)}
                                            />
                                        </Grid>
                                        <Grid className="mt-2" item xs={12} md={12}>
                                            <Autocomplete
                                                onChange={(e, value) => setFieldValue("contracts", value)}
                                                filterOptions={filterOptions}
                                                value={values.contracts}
                                                multiple
                                                options={contractsList}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </React.Fragment>
                                                )}
                                                renderTags={(tagValue, getTagProps) => {
                                                    const numTags = tagValue.length;
                                                    return (
                                                        <Typography variant="caption">
                                                            {tagValue.slice(0, 2).map((option, index) => (
                                                                <Chip
                                                                    size="small"
                                                                    color="secondary"
                                                                    className={classes.chip}
                                                                    key={index}
                                                                    label={option.name}
                                                                    {...getTagProps({ index })}
                                                                />
                                                            ))}
                                                            {numTags > 2 && ` +${numTags - 2}`}
                                                        </Typography>
                                                    )
                                                }
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Contracts"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid className="mt-2" item xs={12} md={12}>
                                            <PayerPlanDropdown setDropDownFieldValue={setFieldValue} defaultValue={values.plan} touched={touched} errors={errors} />
                                        </Grid>
                                        <Grid className="mt-3" item xs={8}>
                                            <Tooltip
                                                arrow
                                                classes={{ tooltip: classes.tooltip }}
                                                title={<Typography color="inherit">
                                                    Automatically analyze new claims
                                                </Typography>}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name="autoClaimAnalysis"
                                                            color="primary"
                                                            checked={values.autoClaimAnalysis}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="ml-0"
                                                            value={values.autoClaimAnalysis}
                                                        />
                                                    }
                                                    label="Auto Analyze"
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid className="mt-3" item xs={8}>
                                            <Tooltip
                                                arrow
                                                classes={{ tooltip: classes.tooltip }}
                                                title={<Typography color="inherit">
                                                    Include in Machine Readable File
                                                </Typography>}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name="includeInMachineReadable"
                                                            color="primary"
                                                            checked={values.includeInMachineReadable}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="ml-0"
                                                            value={values.includeInMachineReadable}
                                                        />
                                                    }
                                                    label="Include in Machine Readable File"
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Button
                                                onClick={handleClose}
                                                variant="contained"
                                                color="default"
                                                className={classes.button}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={payerContractCreatePending || payerContractUpdatePending}
                                            >
                                                {(payerContractCreatePending || payerContractUpdatePending) ? 'Saving' : 'Save'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}