import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { deleteOPPS, getOPPSDeleteDependencies, resetDependencies, setDependencyDialog } from '../../../store/actions/opps';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.opps.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (oppsDependencyDialog) => {
                if (oppsDependencyDialog && oppsDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectOPPSId = createSelector(
            selectDependencyDialog,
            (oppsDependencyDialog) => {
                if (oppsDependencyDialog && oppsDependencyDialog?.oppsId) {
                    return oppsDependencyDialog?.oppsId
                }
                return null
            }
        );

        const selectOPPSName = createSelector(
            selectDependencyDialog,
            (oppsDependencyDialog) => {
                if (oppsDependencyDialog && oppsDependencyDialog?.name) {
                    return oppsDependencyDialog?.name
                }
                return ''
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            oppsId: selectOPPSId(state, props),
            dependencies: state.opps.dependencies?.records ?? [],
            canDelete: state.opps.dependencies?.canDelete ?? false,
            getDependenciesPending: state.opps.getOPPSDependencies.pending,
            deletePendng: state.opps.delete.pending,
            oppsName: selectOPPSName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getOPPSDeleteDependencies: getOPPSDeleteDependencies,
            deleteOPPS: deleteOPPS,
            setDependencyDialog: setDependencyDialog,
            resetDependencies: resetDependencies
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));