import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import FeeScheduleDraggableComponent from './FeeScheduleDraggable';

export const FeeScheduleDraggable = connect(
    () => {
        const selectFeeScheduleIds = (state, props) => state.pagesV2['feeSchedules-landing'].selected;

        return (state, props) => ({
            selectedFeeScheduleIds: selectFeeScheduleIds(state)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(FeeScheduleDraggableComponent));