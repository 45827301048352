import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Loadable } from '../../../common';
import { Portlet } from '../../../partials/content/Portlet';
import { ReleaseDetailItem } from './ReleaseDetailItem';
import { Link } from 'react-scroll';

const useStyles = makeStyles(theme => {
    return {
        titleDiv: {
            padding: '16px 24px',
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
        },
        underline: {
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '16px'
        },
        mainDiv: {
            padding: '16px 24px',
        },
    }
});

function VersionUpdatePage(props) {
    const {
        getReleaseNotesPending, releases,
        actions: {
            getReleaseNotes
        }
    } = props;

    const classes = useStyles();

    useEffect(() => {
        getReleaseNotes();
    }, [getReleaseNotes]);

    return (
        <Loadable loading={getReleaseNotesPending}>
            <Helmet title="Version Updates" />
            <Portlet>
                <Grid container className={classes.titleDiv}>
                    <Grid xs={12} md={12} xl={12}>
                        <Typography variant="h5" >
                            <b>Release Versions</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.mainDiv}>
                    <Grid xs={12} md={12} xl={12}>
                        <ul>
                            {releases && releases.map((release, index) => (
                                <li className='mt-3'>
                                    <Link className={classes.underline} to={`section-${release.id}`} smooth={true} duration={500}>
                                        <b>Release version - {release.version}</b>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Grid>
                </Grid>
                {releases && releases.map((release, index) => (
                    <ReleaseDetailItem key={index} release={release} />
                ))}
            </Portlet>
        </Loadable>
    )
}

export default VersionUpdatePage