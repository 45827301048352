import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles, Grid,
    DialogActions, Button, Typography, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PagedTable } from '../../../../common';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
    content: {
        maxHeight: 350,
    }
}));

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

export default function RulesDialog(props) {
    const {
        open, fields,
        serviceId, serviceName,
        actions: { rulesFromCodeServiceLanding, setRulesDialog }
    } = props;
    const classes = useStyles();

    function handleLoad(sort, filter, pagination) {
        rulesFromCodeServiceLanding(pagination, sort, filter, serviceId);
    }

    function handleClose() {
        setRulesDialog(null);
    }

    const handleClickColumn = (fieldName, rowData, index) => {
        window.open(`${window.location.origin}/cms/contract/${rowData.contractId}/rule/${rowData.contractServiceId}/edit/${rowData.id}`)
    }

    return (
        <Dialog
            fullWidth
            scroll="paper"
            maxWidth="lg"
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Grid item xs={11}>
                        <Typography variant="h6" className='mb-0'>Rules ({serviceName})</Typography>
                    </Grid>
                    <Grid item xs={1} className='text-right'>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.content}>
                    <PagedTable
                        name="code-service-rules-landing"
                        title="Rules From Code Service"
                        fields={fields}
                        onLoad={handleLoad}
                        defaultSort={DEFAULT_SORT}
                        stickyHeader={true}
                        tableInModal={true}
                        handleClickColumn={handleClickColumn}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12} className='py-2 px-4 text-right'>
                        <Button
                            onClick={() => handleClose(false)}
                            variant="contained"
                            color="default">
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}