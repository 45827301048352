import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import NPIRoleEditDialogComponent from './NPIRoleEditDialog';
import { createEditReset, createNPIRole, updateNPIRole } from '../../../store/actions/npiRoles';

export const NPIRoleEditDialog = connect(
    () => {
        const selectMode = (state, props) => {
            return props.npiRole ? 'edit' : 'create';
        }

        const selectNPIRole = (state, props) => props.npiRole;

        const selectInitialValues = createSelector(
            selectMode,
            selectNPIRole,
            (mode, npiRole,) => {
                if (mode === 'edit' && npiRole) {
                    let payload = {
                        id: npiRole.id,
                        name: npiRole.name,
                        description: npiRole.description,
                        npis: npiRole.npis
                    }
                    return payload;
                }

                return {
                    name: '',
                    description: '',
                    npis: ''
                }
            }
        )

        return (state, props) => ({
            mode: selectMode(state, props),
            initialValues: selectInitialValues(state, props),
            createNPIRolePending: state.npiRoles.create.pending,
            updateNPIRolePending: state.npiRoles.update.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            createNPIRole: createNPIRole,
            updateNPIRole: updateNPIRole,
            createEditReset: createEditReset
        }, dispatch)
    })
)(injectIntl(NPIRoleEditDialogComponent));