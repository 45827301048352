import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import {
    Portlet, PortletBody, PortletHeader
} from "../../partials/content/Portlet";
import { makeStyles, Grid, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,Box } from "@material-ui/core";
import { PagedTable } from '../../common';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Alert from '@material-ui/lab/Alert';
import { errorToastify } from '../../util/toast';

const DEFAULT_SORT = [
    { id: 'id', direction: 'desc' }
];

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    processClaimBtn: {
        "&:hover": {
            backgroundColor: 'whitesmoke', // Change background color on hover
            transform: 'scale(1.1)', // Scale effect
            transition: 'transform 0.2s ease', // Smooth transition
            cursor:'pointer',
            borderRadius: '50%'
        }
      },
      grid:{display:'grid',alignItems:'center'}
}));

export default function ClaimAuditsPage(props) {
    const classes = useStyles();
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [isProcessSuccessDialogOpen, setProcessSuccessDialogOpen] = useState(false);

    const {
        fields,
        auditData,
        filter,
        sort,
        processClaimsPending,
        actions: {
            claimAuditsLanding,
            searchGetByName,
            searchReset,
            processClaimsFromAudit,
            auditErrorReset,
            resetPagedTable,
        }
    } = props;

    useEffect(() => {
        const pageName = {
            name: 'claim-audits-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name)
        }
    }, [searchGetByName, searchReset]);

    function handleLoad(sort, filter, pagination) {
        claimAuditsLanding(pagination, sort, filter);
        errorReset();
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handleProcessClaims() {
        errorReset();
        if (filter === null || filter?.length === 0) {
            // setErrorToast("At least one filter should be selected");
            errorToastify("At least one filter should be selected.")
            return
        }
        if (auditData.length > 1000) {
            errorToastify("Please select more filter to narrow down search results. The results should be lesser than 1000.");
            return
        }
        const payload = {
            page: null,
            filter: filter || null,
            sort: sort || [],
        };
        processClaimsFromAudit(payload, handleProcessCallback);
    }

    function handleProcessCallback() {
        setProcessSuccessDialogOpen(true);
    }

    function errorReset() {
        auditErrorReset();
    }

    function handleProcessDialogClose() {
        setProcessSuccessDialogOpen(false);
    }

    const claimAuditTableLevelActions = () => {
        return (
            <>
                <Grid item className={classes.grid}>
                    <Tooltip title="Process Claims">
                        <Box onClick={handleProcessClaims} className={classes.processClaimBtn}>
                            <PlayCircleOutlineIcon />
                        </Box>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Helmet title="Claim Audits" />
            <Portlet>
                <PortletHeader
                    title="Claim Audits"
                    name="claim-audits-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                />
                <PortletBody>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="claim-audits-landing"
                                    title="Claim Audits"
                                    fields={fields}
                                    data={auditData}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    criteriasSaveEnabled={true}
                                    tableLevelActions={claimAuditTableLevelActions}
                                    tableLevelLoading={processClaimsPending}
                                    // showSearchFilters={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
            <Dialog open={isProcessSuccessDialogOpen} onClose={handleProcessDialogClose}>
                <DialogTitle>
                    Process Claims
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="success">The request is submitted and progress can be monitored on execution logs page.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProcessDialogClose} color="default">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}