import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { bulkUpdateRemitPayer, createRemitPayer, errorResetClear, updateRemitPayer } from '../../../store/actions/payers';
import PayerDialogComponent from './PayerDialog';
import { setV2 } from '../../../store/actions/pagesV2';

export const PayerDialog = connect(
    () => {
        const selectSelectedPayers = (state, props) => state.pagesV2['remit-payers-landing'].selected;
        const selectPayerContracts = (state, props) => props.payerContracts;
        const selectPayerInfoEditDialog = (state, props) => state.payers.remitPayerEditDialog;

        const mapContractObjects = (contractIds, payerContracts) => {
            return contractIds.map(item => payerContracts.find(pc => pc.id === item.payercontractId))
        }

        const selectPayer = createSelector(
            selectPayerInfoEditDialog,
            (remitPayerEditDialog) => {
                if (remitPayerEditDialog && remitPayerEditDialog?.payer) {
                    return remitPayerEditDialog?.payer
                }
                return null
            }
        )

        const selectOpen = createSelector(
            selectPayerInfoEditDialog,
            (remitPayerEditDialog) => {
                if (remitPayerEditDialog && remitPayerEditDialog?.open) {
                    return true
                }
                return false
            }
        )

        const selectBulk = createSelector(
            selectPayerInfoEditDialog,
            (remitPayerEditDialog) => {
                if (remitPayerEditDialog && remitPayerEditDialog?.bulk) {
                    return true
                }
                return false
            }
        )

        const selectMode = createSelector(
            selectBulk, selectPayer,
            (isBulk, payer) => {
                if (isBulk) {
                    return 'edit';
                }
                return payer ? 'edit' : 'create';
            }
        )

        const selectInitialValues = createSelector(
            selectMode,
            selectPayer,
            selectPayerContracts,
            (mode, payer, payerContracts) => {
                if (mode === 'edit' && payer) {
                    let payload = {
                        id: payer.id,
                        active: payer.active,
                        name: payer.name,
                        contractIds: mapContractObjects(payer.remitPayerContractXref, payerContracts)
                    }

                    return payload;
                }

                return {
                    contractIds: [],
                    active: false,
                    name: '',
                    type: '',
                }
            }
        )



        return (state, props) => ({
            //create payer
            payerCreatePending: state.payers.remitPayerCreate.pending,
            // update payer
            payerUpdatePending: state.payers.remitPayerUpdate.pending,
            // update payer
            payerUpdateBulkPending: state.payers.remitPayerBulkEdit.pending,
            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            selectedPayers: selectSelectedPayers(state, props),
            isBulk: selectBulk(state, props),
            open: selectOpen(state, props),
            bulk: selectBulk(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payerCreate: createRemitPayer,
            payerUpdate: updateRemitPayer,
            payerBulkUpdate: bulkUpdateRemitPayer,
            payerErrorReset: errorResetClear,
            setSelected: (value) => setV2('remit-payers-landing', 'selected', value),
        }, dispatch)
    })
)(injectIntl(PayerDialogComponent));