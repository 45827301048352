import React, { ComponentType } from 'react';
import { connect, Selector } from "react-redux";
import { Redirect } from "react-router-dom";

const applyProtected = <T extends Selector<any,any,any>>(Component : ComponentType, selectHasAccess : T = (() => true) as any) => {
    const ProtectedRoute = (props) => {
        const { hasAccess } = props;
        if (!hasAccess) {
            return <Redirect to="/unauthorized" />;
        }
        return (
            <Component {...props} />
        )
    }
    return connect((state, props) => ({
        hasAccess: selectHasAccess(state, props),
    }))(ProtectedRoute);
};

export { applyProtected };

export const applyPreAuthentication = (Component: ComponentType) => applyProtected(Component);