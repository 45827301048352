import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DashboardFiltersPageComponent from './DashboardFiltersPage';
import { list, deleteDashboardFilter } from '../../../store/actions/dashboardFilters';
import { selectDashboardFilters } from '../../../store/selectors/dashboardFilters';
import { set } from '../../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { createStringDataField } from '../../../util/format';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Filter Name', { sortable: true }),
        createStringDataField('datasourceName', 'DataSource', { sortable: true }),
        createStringDataField('fieldName', 'Field', { sortable: true }),
        createStringDataField('datatype', 'Data Type', { sortable: true }),
        createStringDataField('type', 'Parameter Type', { sortable: true }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['dashboard-filters-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['dashboard-filters-landing'].filter,
    selectSort,
    selectDashboardFilters,
);

export const DashboardFiltersPage = injectIntl(
    connect(
        (state, props) => ({
            fields: selectFields(state),
            dashboardsFilteredData: selectFilteredData(state),
            sort: selectSort(state),
            dashboardFilterListPending: state.dashboardFilters.list.pending,
            dashboardFilterDeletePending: state.dashboardFilters.delete.pending,
            order: state.pages['dashboard-filters-landing'].order,
            filter: state.pages['dashboard-filters-landing'].filter,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                dashboardFilterList: list,
                dashboardFilterDelete: deleteDashboardFilter,
                setFilter: (value) => set('dashboard-filters-landing', 'filter', value),
                setOrder: (value) => set('dashboard-filters-landing', 'order', value),
                setSort: (value) => set('dashboard-filters-landing', 'sort', value),
            }, dispatch)
        })
    )(DashboardFiltersPageComponent));