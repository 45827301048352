import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";


import ContractStateDialogComponent from './ContractStateDialog';
import { updateContractState } from '../../../store/actions/contracts';
import { selectContractStates } from '../../../store/selectors/contracts';
import { createSelector } from 'reselect';

export const ContractStateDialog = connect(
    () => {

        const selectOpen = createSelector(
            (state) => state.contracts.stateDialog,
            (stateDialog) => {
                return stateDialog?.open ?? false
            }
        );

        const selectContractId = createSelector(
            (state) => state.contracts.stateDialog,
            (stateDialog) => {
                return stateDialog?.contractId ?? false
            }
        );

        const selectStateId = createSelector(
            (state) => state.contracts.stateDialog,
            (stateDialog) => {
                return stateDialog?.contractStateId ?? false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            contractId: selectContractId(state),
            contractStateId: selectStateId(state),
            contractStateUpdatePending: state.contracts.updateState.pending,
            contractStates: selectContractStates(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            updateContractState: updateContractState
        }, dispatch)
    })
)(injectIntl(ContractStateDialogComponent));