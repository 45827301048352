import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import FeeScheduleBreadCrumbComponent from './FeeScheduleBreadCrumb';
import { createSelector } from 'reselect';
import { findNodeInTree, findParents } from '../../../util/tree';
import { setV2 } from '../../../store/actions/pagesV2';
import { moveDirectoryOrFeeSchedule } from '../../../store/actions/feeScheduleV2';

export const FeeScheduleBreadCrumb = connect(
    () => {
        const selectBreadcrumbItems = createSelector(
            (state, props) => props.directoryId,
            (state) => state.feeScheduleV2.hierarchy,
            (currentDirectory, hierarchy) => {
                return findParents(parseInt(currentDirectory), hierarchy);
            }
        );

        const selectCurrentDirectory = createSelector(
            (state, props) => props.directoryId,
            (state) => state.feeScheduleV2.hierarchy,
            (currentDirectory, hierarchy) => {
                if (currentDirectory === 'root') {
                    return null;
                }
                return findNodeInTree(parseInt(currentDirectory), hierarchy);
            }
        );

        const selectFeeScheduleIds = (state, props) => state.pagesV2['feeSchedules-landing'].selected;

        return (state, props) => ({
            breadcrumbItems: selectBreadcrumbItems(state, props),
            currentDirectory: selectCurrentDirectory(state, props),
            selectedFeeScheduleIds: selectFeeScheduleIds(state)

        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            moveDirectoryOrFeeSchedule: moveDirectoryOrFeeSchedule,
            resetSelectedFeeSchedule: () => setV2('feeSchedules-landing', 'selected', [])
        }, dispatch)
    })
)(injectIntl(FeeScheduleBreadCrumbComponent));