import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import RemitPayersPageComponent from './RemitPayersPage';

import { remitPayersLanding, resetPage, set } from '../../store/actions/pages';
import { getPayerContractsTiny, setRemitDependencyDialog, setRemitPayerEditDialog, setRemitPayerEnableDialog, setRemitPayToleranceMatchDialog } from '../../store/actions/payers';
import { integerEquators, StringEquators } from '../../store/constants/pages';
import { selectPayerContracts } from '../../store/selectors/payers';
import { createCurrencyDataField, createIconDataField, createListField, createNumberDataField, createStringDataField } from '../../util/format';

import { setV2 } from '../../store/actions/pagesV2';
import { getByName, reset } from '../../store/actions/search';
import { selectSessionRole } from '../../store/selectors/session';

const selectFields = createSelector(
    () => [

        createStringDataField('name', 'Payer Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createNumberDataField('totalClaimsCount', 'Total Claims', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('totalClaimAmount', 'Total Claim Amount', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('totalPaidAmount', 'Total Paid Amount', { sortable: true, equators: integerEquators }),
        createListField('contracts', 'Contracts', { hideFromFilter: true, returnWholeRow: true }),
        createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
    ]
);

const selectPayersData = createSelector(
    (state) => state.pages['remit-payers-landing'].list,
    (data) => {
        return data.map(rp => {
            return {
                ...rp,
                contracts: rp?.remitPayerContractXref?.map(item => item.payercontract?.displayName ?? '')
            }
        })
    }
);

export const selectCheckboxStatus = createSelector(
    selectPayersData,
    (payers) => {
        const enabledPayers = payers.filter(payer => payer.active).length;
        if (payers.length === enabledPayers) {
            return true;
        }
        return false;
    }
);


export const RemitPayersPage = connect(
    () => {
        return (state, props) => ({
            payerListPending: state.payers.list.pending,
            payerData: selectPayersData(state),
            payerFields: selectFields(state as never),
            checkboxStatus: selectCheckboxStatus(state),
            payerContracts: selectPayerContracts(state),
            userRole: selectSessionRole(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            remitPayersLanding: remitPayersLanding,
            payersGetPayerContractsTiny: getPayerContractsTiny,
            searchGetByName: getByName,
            searchReset: reset,
            resetPagedTable: resetPage,
            setRemitPayerEnableDialog: setRemitPayerEnableDialog,
            setRemitPayerEditDialog: setRemitPayerEditDialog,
            setRemitPayToleranceMatchDialog: setRemitPayToleranceMatchDialog,
            setList: (value) => set('remit-payers-landing', 'list', value),
            setSelectedItems: (value) => setV2('remit-payers-landing', 'selected', value),
            setRemitDependencyDialog: setRemitDependencyDialog
        }, dispatch)
    })
)(injectIntl(RemitPayersPageComponent));