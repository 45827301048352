import { createReducer } from '../../util';
import * as actions from '../actions/opps';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    opps: null,
    tiny: [],
    oppsByContract: [],
    oppsByTargetClient: [],
    dependencyDialog: null,
    dependencies: [],
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getOPPSTiny: {
        ...initialStatusState,
    },
    getOPPSTinyByContract: {
        ...initialStatusState,
    },
    getOPPSTinyByTargetClient: {
        ...initialStatusState,
    },
    getOPPSDependencies: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {

    [actions.OPPS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.OPPS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
    }),
    [actions.OPPS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.OPPS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.OPPS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
    }),
    [actions.OPPS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.OPPS_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.OPPS_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.OPPS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.OPPS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.OPPS_GET_FULFILLED]: (state, opps) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        opps
    }),
    [actions.OPPS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.OPPS_GET_TINY_PENDING]: (state) => ({
        ...state,
        getOPPSTiny: {
            pending: true,
        },
    }),
    [actions.OPPS_GET_TINY_FULFILLED]: (state, tiny) => ({
        ...state,
        getOPPSTiny: {
            ...initialStatusState,
        },
        tiny
    }),
    [actions.OPPS_GET_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getOPPSTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.OPPS_CREATE_UPDATE_RESET]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
        create: {
            ...initialStatusState,
        },
    }),
    [actions.OPPS_GET_TINY_BY_CONTRACT_PENDING]: (state) => ({
        ...state,
        getOPPSTinyByContract: {
            pending: true,
        },
    }),
    [actions.OPPS_GET_TINY_BY_CONTRACT_FULFILLED]: (state, oppsByContract) => ({
        ...state,
        getOPPSTinyByContract: {
            ...initialStatusState,
        },
        oppsByContract
    }),
    [actions.OPPS_GET_TINY_BY_CONTRACT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getOPPSTinyByContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.OPPS_GET_TINY_BY_TARGET_CLIENT_PENDING]: (state) => ({
        ...state,
        getOPPSTinyByTargetClient: {
            pending: true,
        },
    }),
    [actions.OPPS_GET_TINY_BY_TARGET_CLIENT_FULFILLED]: (state, oppsByTargetClient) => ({
        ...state,
        getOPPSTinyByTargetClient: {
            ...initialStatusState,
        },
        oppsByTargetClient
    }),
    [actions.OPPS_GET_TINY_BY_TARGET_CLIENT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getOPPSTinyByTargetClient: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.OPPS_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),

    [actions.OPPS_RESET_DEPENDENCIES]: (state) => ({
        ...state,
        dependencies: []
    }),

    [actions.OPPS_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getOPPSDependencies: {
            pending: true,
        },
    }),
    [actions.OPPS_DELETE_DEPENDENCIES_FULFILLED]: (state, dependencies) => ({
        ...state,
        getOPPSDependencies: {
            ...initialStatusState,
        },
        dependencies
    }),
    [actions.OPPS_DELETE_DEPENDENCIES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getOPPSDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});
