import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import ClaimSearchComponent from './ClaimSearch';
import { createCurrencyDataField, createStringDataField } from '../../../../../util/format';
import { StringEquators, integerEquators } from '../../../../../store/constants/pages';
import { selectTiny } from '../../../../../store/selectors/contracts';
import { MODEL_CLAIM_SELECTION_TYPE_BASIC } from '../../../../../store/constants/modeling';

export const ClaimSearch = connect(
    () => {

        const selectFields = createSelector(
            () => [
                createStringDataField('claimnumber', 'Claim Number', { sortable: true, equators: StringEquators }),
                createCurrencyDataField('claimamount', 'Charge Amount', { equators: integerEquators }),
                createStringDataField('payername', 'Payer name', { equators: StringEquators }),
            ]
        );

        const selectInitialValues = createSelector(
            () => {
                return {
                    claimSelectionType: MODEL_CLAIM_SELECTION_TYPE_BASIC,
                    //basic
                    claimNumber: '',
                    equator: 'like',
                    //advanced
                    revCode: '',
                    cpt: '',
                    payerName: '',
                    payerType: '',
                    fromServiceDate: '',
                    toServiceDate: ''
                }
            }
        );

        const selectClaimSelectionData = createSelector(
            (state) => state.pages['model-claim-selection-landing'].list,
            (state) => state.modeling.claims,
            (data, response) => {
                if (data.length === 0) {
                    return response?.results?.map(item => ({ ...item, id: item.claimnumber })) ?? [];
                }
                return data.map(item => ({ ...item, id: item.claimnumber }))
            }
        );

        const selectSortedTinyContracts = createSelector(selectTiny,
            (contracts) => {
                return contracts.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        const selectSelectedItems = createSelector(
            (state) => state.pages['model-claim-selection-landing'].selected,
            (data) => {
                return data
            }
        );

        return (state, props) => ({
            fields: selectFields(state, props),
            claimSelectionData: selectClaimSelectionData(state, props),
            initialValues: selectInitialValues(state, props),
            tinyContracts: selectSortedTinyContracts(state),
            initSearchPending: state.modeling.searchClaimsForModeling.pending,
            selectedItems: selectSelectedItems(state),
            getModelPending: state.modeling.getModelById.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(ClaimSearchComponent));