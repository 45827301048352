import React, { useEffect, useState } from "react";
import {
    makeStyles, Grid, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormGroup, Switch, FormControl, TextField, Checkbox, Chip,
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

import { DataTable } from '../../../../common';
import { BackButton } from '../../../../common/BackButton';


import {
    Portlet,
    PortletBody,
    PortletHeader, PortletHeaderToolbar
} from "../../../../partials/content/Portlet";
import { Loadable } from '../../../../common';
import { withRouter } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { TablePagination } from '@material-ui/core';
import { TablePaginationActions } from '../../../../common/tablePaginationActions';
import { reorderArray } from "../../../../util";
import { Card } from "react-bootstrap";
import { Formik } from 'formik';



const useStyles = makeStyles(theme => {
    return {
        textField: {
            width: 400,
        },
        portlet: {
            'box-shadow': 'none',
            marginBottom: 0,
            "& .kt-portlet__head": {
                borderColor: theme.palette.secondary.main,
                // marginLeft: theme.spacing(3.5),
            }
        },
        popover: {
            pointerEvents: 'none',
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        paper: {
            pointerEvents: 'auto',
            width: 300,
        },
        underline: {
            textDecoration: 'underline',
        },
        button: {
            "&:hover": {
                color: 'white',
            },
            marginTop: theme.spacing(.5),
        },
        portletHeader: {
            marginLeft: "0px !important"
        },
        percentdisplay: {
            margin: 5,
            display: 'inline-block',
        },
        label: {
            fontSize: '1rem'
        },
        IconButton: { padding: 6 },
        ruleStatusFormControl: {
            marginLeft: theme.spacing(2),
            width: 200
        },
        formControlAuto: {
            marginTop: theme.spacing(5),
            width: "85%"
        },
    }
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ContractFamilyEditView(props) {
    const { intl, fields,
        getPending, updatePending,
        initialValues,
        tinyContracts,
        familyData,
        familyId,
        activatePending,
        deactivatePending,
        isActive,
        changePrecedencePending,
        actions: { changePrecedence, getFamily, activateContractFamily, updateContractFamily,
            deactivateContractFamily, getTinyContracts }
    } = props;
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [page, setPage] = useState(0);


    const [isConfirmOpen, setConfirmOpen] = useState(false);

    const [tempContracts, setTempContracts] = useState([]);
    const [isContractActive, setIsContractActive] = useState(false)


    useEffect(() => {
        getFamily(familyId)
        getTinyContracts()

    }, [getFamily,getTinyContracts]);

    useEffect(() => {
        if (familyData?.length) {
            setTempContracts([...familyData
            ])
            setIsContractActive(isActive)

        }
    }, [familyData])


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    function handleRefresh() {
        getFamily(familyId)
    }

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    }

    const handleContractFamilyStatusChange = () => {
        handleConfirmClose();
        if (isContractActive) {
            activateContractFamily(familyId, statusCallback)
        } else {
            deactivateContractFamily(familyId, statusCallback)
        }
    }

    const statusCallback = () => {
        handleRefresh();
    }

    const handleDragAndDrop = (prevIndex, newIndex) => {

        const reorderedData = reorderArray(tempContracts, prevIndex, newIndex);

        const tempData = [...reorderedData]
        setTempContracts(tempData);
        handleChangePrecedence(tempData);
    }

    const handleChangePrecedence = (contractsArray) => {
        const familyId = contractsArray[0].contractFamilyId

        const formattedData = contractsArray.map((contract, index) => {
            return {
                contractId: contract.contractId,
                precedence: index
            }
        });
        const payload = {
            familyId,
            "contractPrecedences": [...formattedData]
        }
        changePrecedence(payload, handleRefresh)
    }

    const handleActiveConfirmOpen = () => { setConfirmOpen(true); }

    const handleStatusToggle = (e) => {
        setIsContractActive(e.target.checked);
        handleActiveConfirmOpen();
    }

    const handleCallback = () => {
        props.history.push("/cms/family");
    };


    return (
        <>
            <Loadable loading={getPending || activatePending || deactivatePending || changePrecedencePending || updatePending}>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validate={values => {
                        const errors = {};

                        if (!values.name) {
                            errors.name = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }


                        if (values.name.length>250) {
                            errors.name = "Contract family name should not exceed 250 characters.";

                        }

                        if (values.contracts.length < 2) {
                            errors.contracts = "Select at least two contracts.";
                        }

                        return errors;
                    }}
                    onSubmit={(values) => {

                        const contractFamilyPrecedence = values?.contracts.map((item, index) => ({ contractId: item?.contractId || item?.id, precedence: index }))

                        const payload = {
                            id: familyId,
                            "name": values?.name,
                            "description": values?.description,
                            "active": isContractActive,
                            "contractFamilyXref": contractFamilyPrecedence
                        }

                        updateContractFamily(payload, handleCallback)

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        dirty
                    }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                            <Portlet>
                                <PortletHeader
                                    toolbar={
                                        <PortletHeaderToolbar className='w-100'>
                                            <Grid container alignItems="center">

                                                <Grid md={6} spacing={2} container alignItems="center" className="pl-2">
                                                    <FormGroup>
                                                        <Typography component="div">
                                                            <Grid component="label" className='mb-0' container alignItems="center" spacing={0}>
                                                                <Grid item>Inactive</Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        name="active"
                                                                        color="primary"
                                                                        checked={isContractActive}
                                                                        onChange={handleStatusToggle}
                                                                        onBlur={handleBlur}
                                                                        className={classes.switch}
                                                                        value={values.active}
                                                                    />
                                                                </Grid>
                                                                <Grid item>Active</Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid md={6} container className="justify-content-end">


                                                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/cms/family" />
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className='ml-3'
                                                        type="submit"
                                                        disabled={!dirty}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>

                                            </Grid>
                                        </PortletHeaderToolbar>
                                    }
                                />
                                <PortletBody>
                                    <div className="root">
                                        <Grid container>
                                            <Grid xs={8}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            label="Name"
                                                            name="name"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.name}
                                                            helperText={touched.name && errors.name}
                                                            error={Boolean(touched.name && errors.name)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Description"
                                                            name="description"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values?.description}
                                                            helperText={touched?.description && errors?.description}
                                                            error={Boolean(touched?.description && errors?.description)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <FormControl
                                                            className={classes.formControlAuto}
                                                        >
                                                            <Autocomplete
                                                                onChange={(e, value) =>
                                                                    setFieldValue(
                                                                        "contracts",
                                                                        value
                                                                    )
                                                                }
                                                                value={values.contracts}
                                                                multiple
                                                                options={tinyContracts}
                                                                disableCloseOnSelect
                                                                getOptionLabel={option =>
                                                                    option.name || option?.contractName || ""
                                                                }
                                                                getOptionSelected={(option, value) => option?.name === value?.name}

                                                                renderOption={(
                                                                    option,
                                                                    { selected }
                                                                ) => (
                                                                    <React.Fragment>
                                                                        <Checkbox
                                                                            icon={icon}
                                                                            checkedIcon={
                                                                                checkedIcon
                                                                            }
                                                                            style={{
                                                                                marginRight: 8
                                                                            }}
                                                                            checked={selected}
                                                                        />
                                                                        {option?.name || option?.contractName}
                                                                    </React.Fragment>
                                                                )}
                                                                renderTags={(
                                                                    tagValue,
                                                                    getTagProps
                                                                ) => {
                                                                    const numTags =
                                                                        tagValue.length;
                                                                    return (
                                                                        <Typography variant="caption">
                                                                            {tagValue
                                                                                .slice(0, 2)
                                                                                .map(
                                                                                    (
                                                                                        option,
                                                                                        index
                                                                                    ) => (
                                                                                        <Chip
                                                                                            size="small"
                                                                                            color="secondary"
                                                                                            className={
                                                                                                classes.chip
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            label={
                                                                                                option.name || option.contractName
                                                                                            }
                                                                                            {...getTagProps(
                                                                                                {
                                                                                                    index
                                                                                                }
                                                                                            )}
                                                                                        />
                                                                                    )
                                                                                )}
                                                                            {numTags > 2 &&
                                                                                ` + ${numTags -
                                                                                2}`}
                                                                        </Typography>
                                                                    );
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField

                                                                        {...params}
                                                                        label="Contracts"
                                                                        error={Boolean(touched.contracts && errors.contracts)}
                                                                        helperText={touched.contracts && errors.contracts}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </form>
                    )}
                </Formik>
                <Card className="mt-3" variant="outlined">
                    <Card.Header>
                        <Grid container className="align-items-center">
                            <Grid xs={4} item>
                                <h5 className="mb-0">Contracts</h5>
                            </Grid>

                        </Grid>
                    </Card.Header>
                    <Card.Body>
                        <Grid container>
                            <Grid item xs={12}>
                                <DataTable
                                    fields={fields}
                                    data={familyData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                    showPagination={true}
                                    footerPagination={() => (
                                        <TablePagination
                                            rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                            colSpan={fields.length + 3}
                                            count={familyData?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    )}
                                    isCollapsible={false}
                                    
                                    handleRefresh={handleRefresh}
                                    onDragEnd={handleDragAndDrop}
                                    grayRowKey={'active'}
                                    isDnDEnabled={true}
                                    name='contracts'

                                    hasActions={false}
                                />
                            </Grid>
                        </Grid>
                    </Card.Body>
                </Card>
            </Loadable>

            <Dialog open={isConfirmOpen} onClose={handleConfirmClose}>
                <DialogTitle>
                    {isContractActive ? 'Activate' : 'Deactivate'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {isContractActive ? 'Activate' : 'Deactivate'} this Contract Family?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleContractFamilyStatusChange}
                        color="primary">
                        {isContractActive ? 'Activate' : 'Deactivate'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withRouter(ContractFamilyEditView)
