import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { remitPayerDependency, setRemitDependencyDialog, deleteRemitPayer } from '../../../store/actions/payers';
import { setV2 } from '../../../store/actions/pagesV2';

export const DependencyDialog = connect(
    () => {
        const selectClaimIds = (state, props) => state.pagesV2['remit-payers-landing'].selected;
        const selectDependencyDialog = (state) => state.payers.remitDependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (remitDependencyDialog) => {
                if (remitDependencyDialog && remitDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            selectedItems: selectClaimIds(state, props),
            remitPayerDependencies: state.payers.remitPayersDependency?.records ?? [],
            canDelete: state.payers.remitPayersDependency?.canDelete,
            remitDependencyPending: state.payers.getRemitPayerDependency.pending,
            deleteRemitPayersPending: state.payers.deleteRemitPayers.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('remit-payers-landing', 'selected', value),
            setRemitDependencyDialog: setRemitDependencyDialog,
            remitPayerDependency: remitPayerDependency,
            deleteRemitPayer: deleteRemitPayer
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));