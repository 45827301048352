import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { uploadChargeMaster, list, download, setChargeMasterEditDialog } from '../../../store/actions/chargeMaster';
import { chargeMasterLanding, resetPage, set } from '../../../store/actions/pages';
import { createStringDataField, createCurrencyDataField,createStringFieldFromMultiKeys } from '../../../util/format';
import { getSubDomain } from '../../../util/domain';
import { StringEquators, integerEquators } from '../../../store/constants/pages';


import ChargeMasterPageComponent from './ChargeMasterPage';
import { get } from '../../../store/actions/brand';
import { selectBrandId } from '../../../store/selectors/brand';
import { getByName, reset } from '../../../store/actions/search';

const selectFields = createSelector(
    (state:any) => state.pages['charge-master-landing'],
    () => [
        createStringDataField('facilityName', 'Facility Name', { sortable: true, equators: StringEquators }),
        createStringDataField('departmentName', 'Department Name', { sortable: true, equators: StringEquators }),
        createStringDataField('code', 'Charge Code', { sortable: true, default: true, equators: StringEquators }),
        createStringDataField('revenueCode', 'Rev Code', { sortable: true, equators: StringEquators }),
        createStringDataField('drgCode', 'DRG Code', { sortable: true, equators: StringEquators }),
        createStringDataField('service', 'Service', { sortable: true, equators: StringEquators }),
        createStringDataField('cpT_HCPCS', 'CPT/HPCS', { sortable: true, equators: StringEquators }),
        createStringDataField('description', 'Description', { sortable: true, equators: StringEquators }),
        createStringFieldFromMultiKeys(['mod1', 'mod2', 'mod3', 'mod4'], 'Modifiers', { hideFromFilter: true, returnWholeRow: true,commaSeparated:true }),
        createStringDataField('drugTypeOfMeasurement', 'Drug Type of Measurement', { sortable: true, equators: StringEquators }),
        createStringDataField('drugUnitOfMeasurement', 'Drug Unit of Measurement', { sortable: true, equators: StringEquators }),
        createStringDataField('ndc', 'NDC', { sortable: true, equators: StringEquators }),
        createStringDataField('gl', 'GL', { sortable: true, equators: StringEquators }),
        createCurrencyDataField('amount', 'Charge', { sortable: true, equators: integerEquators }),
    ]
);

const selectChargeMasterData = createSelector(
    (state) => state.pages['charge-master-landing'].list,
    (data) => data
);

export const ChargeMasterPage = connect(
    () => {

        return (state:any, props) => ({
            fields: selectFields(state),
            subDomain: getSubDomain(),
            chargeMasterData: selectChargeMasterData(state),
            chargeMasterUploadPending: state.chargeMaster.upload.pending,
            chargeMasterDownloadTemplatePending: state.chargeMaster.download.pending,
            brandId: selectBrandId(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            chargeMasterUpload: uploadChargeMaster,
            chargeMasterList: list,
            chargeMasterdownloadTemplate: download,
            chargeMasterLanding: chargeMasterLanding,
            getBrand: get,
            searchGetByName: getByName,
            searchReset: reset,
            resetPageTable: resetPage,
            setChargeMasterEditDialog: setChargeMasterEditDialog,
            resetList: () => set('charge-master-landing', 'list', []),
            resetCriteriaWaiting: (pageName) => set(pageName, 'waitForCriteriaFilter', false),
        }, dispatch)
    })
)(injectIntl(ChargeMasterPageComponent));