import { Button, Link } from '@material-ui/core'
import React from 'react'
import { useLocation, withRouter } from 'react-router-dom';
import { getLastFiveBreadCrumbLocations,getLastFiveBreadCrumbLocationsLocalStorage,getBackPath } from '../../router/RouterHelpers';

function BackButton(props) {
    const currentLocation = useLocation().pathname;
    

    return (
        <Button
            onClick={()=>getBackPath(currentLocation,props)}
            variant="contained"
            color="default"
            className=''
            type='button'
        >
            {props.text ? props.text : 'Back'}
        </Button>
    )
}

export default withRouter(BackButton);
