export const JOB_STATISTICS_LIST = 'JOB_STATISTICS_LIST';
export const JOB_STATISTICS_LIST_PENDING = 'JOB_STATISTICS_LIST_PENDING';
export const JOB_STATISTICS_LIST_FULFILLED = 'JOB_STATISTICS_LIST_FULFILLED';
export const JOB_STATISTICS_LIST_REJECTED = 'JOB_STATISTICS_LIST_REJECTED';

export const S3_LISTNERS_LIST = 'S3_LISTNERS_LIST';
export const S3_LISTNERS_LIST_PENDING = 'S3_LISTNERS_LIST_PENDING';
export const S3_LISTNERS_LIST_FULFILLED = 'S3_LISTNERS_LIST_FULFILLED';
export const S3_LISTNERS_LIST_REJECTED = 'S3_LISTNERS_LIST_REJECTED';

export const GET_HEALTH_REPORT_REDSHIFT = 'GET_HEALTH_REPORT_REDSHIFT';
export const GET_HEALTH_REPORT_REDSHIFT_PENDING = 'GET_HEALTH_REPORT_REDSHIFT_PENDING';
export const GET_HEALTH_REPORT_REDSHIFT_FULFILLED = 'GET_HEALTH_REPORT_REDSHIFT_FULFILLED';
export const GET_HEALTH_REPORT_REDSHIFT_REJECTED = 'GET_HEALTH_REPORT_REDSHIFT_REJECTED';

export const GET_HEALTH_REPORT_RDS = 'GET_HEALTH_REPORT_RDS';
export const GET_HEALTH_REPORT_RDS_PENDING = 'GET_HEALTH_REPORT_RDS_PENDING';
export const GET_HEALTH_REPORT_RDS_FULFILLED = 'GET_HEALTH_REPORT_RDS_FULFILLED';
export const GET_HEALTH_REPORT_RDS_REJECTED = 'GET_HEALTH_REPORT_RDS_REJECTED';

export const GET_HEALTH_REPORT_ECS = 'GET_HEALTH_REPORT_ECS';
export const GET_HEALTH_REPORT_ECS_PENDING = 'GET_HEALTH_REPORT_ECS_PENDING';
export const GET_HEALTH_REPORT_ECS_FULFILLED = 'GET_HEALTH_REPORT_ECS_FULFILLED';
export const GET_HEALTH_REPORT_ECS_REJECTED = 'GET_HEALTH_REPORT_ECS_REJECTED';

export const GET_HEALTH_REPORT_NETWORK = 'GET_HEALTH_REPORT_NETWORK';
export const GET_HEALTH_REPORT_NETWORK_PENDING = 'GET_HEALTH_REPORT_NETWORK_PENDING';
export const GET_HEALTH_REPORT_NETWORK_FULFILLED = 'GET_HEALTH_REPORT_NETWORK_FULFILLED';
export const GET_HEALTH_REPORT_NETWORK_REJECTED = 'GET_HEALTH_REPORT_NETWORK_REJECTED';

export const GET_HEALTH_REPORT_SQS = 'GET_HEALTH_REPORT_SQS';
export const GET_HEALTH_REPORT_SQS_PENDING = 'GET_HEALTH_REPORT_SQS_PENDING';
export const GET_HEALTH_REPORT_SQS_FULFILLED = 'GET_HEALTH_REPORT_SQS_FULFILLED';
export const GET_HEALTH_REPORT_SQS_REJECTED = 'GET_HEALTH_REPORT_SQS_REJECTED';

export const GET_HEALTH_REPORT_S3 = 'GET_HEALTH_REPORT_S3';
export const GET_HEALTH_REPORT_S3_PENDING = 'GET_HEALTH_REPORT_S3_PENDING';
export const GET_HEALTH_REPORT_S3_FULFILLED = 'GET_HEALTH_REPORT_S3_FULFILLED';
export const GET_HEALTH_REPORT_S3_REJECTED = 'GET_HEALTH_REPORT_S3_REJECTED';

export const GET_HEALTH_REPORT_CLOUDWATCH = 'GET_HEALTH_REPORT_CLOUDWATCH';
export const GET_HEALTH_REPORT_CLOUDWATCH_PENDING = 'GET_HEALTH_REPORT_CLOUDWATCH_PENDING';
export const GET_HEALTH_REPORT_CLOUDWATCH_FULFILLED = 'GET_HEALTH_REPORT_CLOUDWATCH_FULFILLED';
export const GET_HEALTH_REPORT_CLOUDWATCH_REJECTED = 'GET_HEALTH_REPORT_CLOUDWATCH_REJECTED';

export const GET_HEALTH_REPORT_CLOUDFRONT = 'GET_HEALTH_REPORT_CLOUDFRONT';
export const GET_HEALTH_REPORT_CLOUDFRONT_PENDING = 'GET_HEALTH_REPORT_CLOUDFRONT_PENDING';
export const GET_HEALTH_REPORT_CLOUDFRONT_FULFILLED = 'GET_HEALTH_REPORT_CLOUDFRONT_FULFILLED';
export const GET_HEALTH_REPORT_CLOUDFRONT_REJECTED = 'GET_HEALTH_REPORT_CLOUDFRONT_REJECTED';

export const GET_HEALTH_REPORT_SECRET_MANAGER = 'GET_HEALTH_REPORT_SECRET_MANAGER';
export const GET_HEALTH_REPORT_SECRET_MANAGER_PENDING = 'GET_HEALTH_REPORT_SECRET_MANAGER_PENDING';
export const GET_HEALTH_REPORT_SECRET_MANAGER_FULFILLED = 'GET_HEALTH_REPORT_SECRET_MANAGER_FULFILLED';
export const GET_HEALTH_REPORT_SECRET_MANAGER_REJECTED = 'GET_HEALTH_REPORT_SECRET_MANAGER_REJECTED';

export const GET_QUICK_SIGHTS_DATASET_LIST = 'GET_QUICK_SIGHTS_DATASET_LIST';
export const GET_QUICK_SIGHTS_DATASET_LIST_PENDING = 'GET_QUICK_SIGHTS_DATASET_LIST_PENDING';
export const GET_QUICK_SIGHTS_DATASET_LIST_FULFILLED = 'GET_QUICK_SIGHTS_DATASET_LIST_FULFILLED';
export const GET_QUICK_SIGHTS_DATASET_LIST_REJECTED = 'GET_QUICK_SIGHTS_DATASET_LIST_REJECTED';

export const QUICK_SIGHTS_DATASET_LIST_RESET = 'QUICK_SIGHTS_DATASET_LIST_RESET';

export const getJobStatistics = () => ({
    type: JOB_STATISTICS_LIST,
});

export const getS3Listners = () => ({
    type: S3_LISTNERS_LIST,
});

export const getHealthOfRedshift = () => ({
    type: GET_HEALTH_REPORT_REDSHIFT,
});

export const getHealthOfRDS = () => ({
    type: GET_HEALTH_REPORT_RDS,
});

export const getHealthOfECS = () => ({
    type: GET_HEALTH_REPORT_ECS,
});

export const getHealthOfNetwork = () => ({
    type: GET_HEALTH_REPORT_NETWORK,
});

export const getHealthOfSQS = () => ({
    type: GET_HEALTH_REPORT_SQS,
});

export const getHealthOfS3 = () => ({
    type: GET_HEALTH_REPORT_S3,
});

export const getHealthOfCloudwatch = () => ({
    type: GET_HEALTH_REPORT_CLOUDWATCH,
});

export const getHealthOfCloudfront = () => ({
    type: GET_HEALTH_REPORT_CLOUDFRONT,
});

export const getHealthOfSecretManager = () => ({
    type: GET_HEALTH_REPORT_SECRET_MANAGER,
});

export const getQuickSightsDataSetList = () => ({
    type: GET_QUICK_SIGHTS_DATASET_LIST
});

export const quickSightsDataSetListReset = () => ({
    type: QUICK_SIGHTS_DATASET_LIST_RESET
});