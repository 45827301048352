import {
    METHOD_POST,
    SERVER_URL,
} from '../constants/api';
import {
    headersAuthSendReceiveJson
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getAssociatedList = (options) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/associated/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updateAssociatedStatus = (id, enabled) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/associated/status`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify({ id, enabled }),
    }).then(apiHandleResponse);
};

const selectDeselectAll = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/associated/status/all`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload)
    }).then(apiHandleResponse);
};

const refreshAssociatedCharges = (options) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/refresh/associatedcharges`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

export {
    getAssociatedList,
    updateAssociatedStatus,
    selectDeselectAll,
    refreshAssociatedCharges
};