import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { Loadable, PopoverLink } from '../../../../common';

const useStyles = makeStyles(theme => ({
    textField: {
        width: '100%'
    },
    fieldDivOdd: {
        paddingRight: '13px',
        marginTop: '15px'
    },
    fieldDivEven: {
        paddingLeft: '13px',
        marginTop: '15px'
    },
    row: {
        paddingLeft: '26px',
        paddingRight: '26px',
    },
    helpIcon: {
        color: 'red',
        padding: 0,
        minWidth: 'auto',
    },
}));

function PocketCostDialog(props) {
    const { intl, initialValues, open, handleClose, estimationId, claimAmount,
        calculateOOPPending, actions: { calculateOutOfPocketCost } } = props;

    const classes = useStyles();

    function hoverColumn(hoverData) {
        return (
            <PopoverLink
                columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-info`}></i>}
                hoverData={hoverData}
                tabIndex={-1}
            />
        );
    }

    return (

        <Dialog
            fullWidth
            scroll="paper"
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Grid item xs={11}>
                        Calculate Out Of Pocket Cost
                    </Grid>
                    <Grid item xs={1} className='text-right'>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers className='p-0'>
                <Loadable loading={calculateOOPPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};

                            if (!values.copay) {
                                errors.copay = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (!values.remainingDeductible) {
                                errors.remainingDeductible = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (!values.coInsurancePercent) {
                                errors.coInsurancePercent = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (!values.maxOutOfPocket) {
                                errors.maxOutOfPocket = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            let payload = {
                                estimationId: estimationId,
                                claimAmount: claimAmount,
                                copay: parseInt(values.copay),
                                remainingDeductible: parseInt(values.remainingDeductible),
                                coInsurancePercent: parseInt(values.coInsurancePercent),
                                maxOutOfPocket: parseInt(values.maxOutOfPocket)

                            }
                            calculateOutOfPocketCost(payload, handleClose);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <>

                                <Grid item xs={12} className='pb-4'>
                                    <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                        <Grid container className={classes.row}>
                                            <Grid xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6} className={classes.fieldDivOdd}>
                                                        <Grid className='d-flex flex-column h-100 justify-content-between'>
                                                            <Grid item>
                                                                <Typography variant="h6" className='font-14' gutterBottom>
                                                                    Copay <span className={classes.helpClass}>
                                                                        {hoverColumn([
                                                                            { label: 'Info', data: "Enter 0 if you don't have a copay." },
                                                                            { label: 'Defination', data: 'A fixed amount of money you must pay everytime you visit the doctor or need medication.' }
                                                                        ])}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            {/* <Grid item>
                                                            <Typography className='font-12' variant="subtitle1" gutterBottom>
                                                                Enter 0 if you don't have a copay
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography className='font-12 font-italic' variant="subtitle1" gutterBottom>
                                                                Definition: A fixed amount of money you must pay everytime you visit the doctor or need medication
                                                            </Typography>
                                                        </Grid> */}
                                                            <Grid item>
                                                                <TextField
                                                                    tabIndex={1}
                                                                    name="copay"
                                                                    value={values.copay}
                                                                    id="standard-start-adornment"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={`${classes.textField} mt-3`}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                    }}
                                                                    error={Boolean(touched.copay && errors.copay)}
                                                                    variant="standard"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item xs={6} className={classes.fieldDivEven}>
                                                        <Grid className='d-flex flex-column h-100 justify-content-between'>
                                                            <Grid item>
                                                                <Typography variant="h6" className='font-14' gutterBottom>
                                                                    Remaining Deductible <span className={classes.helpClass}>
                                                                        {hoverColumn([
                                                                            { label: 'Info', data: "Enter 0 if you have met your deductible or don't have one." },
                                                                            { label: 'Defination', data: 'The amount you must pay towards your covered medical expenses in a policy year before your health insurance kicks in.' }
                                                                        ])}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            {/* <Grid item>
                                                            <Typography variant="subtitle1" className='font-12' gutterBottom>
                                                            Enter 0 if you have met your deductible or don't have one.
                                                            </Typography></Grid>
                                                        <Grid item>
                                                            <Typography variant="subtitle1" gutterBottom className="font-italic font-12">
                                                            Definition: The amount you must pay towards your covered medical expenses in a policy year before your health insurance kicks in.
                                                            </Typography>
                                                        </Grid> */}
                                                            <Grid item>
                                                                <TextField
                                                                    tabIndex={2}
                                                                    name="remainingDeductible"
                                                                    value={values.remainingDeductible}
                                                                    id="standard-start-adornment"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={`${classes.textField} mt-3`}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                    }}
                                                                    error={Boolean(touched.remainingDeductible && errors.remainingDeductible)}
                                                                    variant="standard"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.fieldDivOdd}>
                                                        <Grid className='d-flex flex-column h-100 justify-content-between'>
                                                            <Grid item>
                                                                <Typography variant="h6" className='font-14' gutterBottom>
                                                                    Co-insurance <span className={classes.helpClass}>
                                                                        {hoverColumn([
                                                                            { label: 'Info', data: "If you're unsure of your co-insurance percentage, select 20%." },
                                                                            { label: 'Defination', data: "A percentage of your covered medical expenses that you pay after you've met your deductible." }
                                                                        ])}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            {/* <Grid item>
                                                            <Typography variant="subtitle1" className='font-12' gutterBottom>
                                                                If you're unsure of your co-insurance percentage, select 20%.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="subtitle1" gutterBottom className="font-italic font-12">
                                                                Definition: A percentage of your covered medical expenses that you pay after you've met your deductible.
                                                            </Typography>
                                                        </Grid> */}
                                                            <Grid item>
                                                                <TextField
                                                                    tabIndex={3}
                                                                    name="coInsurancePercent"
                                                                    value={values.coInsurancePercent}
                                                                    id="standard-start-adornment"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={`${classes.textField}`}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    error={Boolean(touched.coInsurancePercent && errors.coInsurancePercent)}
                                                                    variant="standard"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item xs={6} className={classes.fieldDivEven}>
                                                        <Grid className='d-flex flex-column h-100 justify-content-between'>
                                                            <Grid item>
                                                                <Typography variant="h6" className='font-14' gutterBottom>
                                                                    Remaining Annual Maximum Out-of-pocket <span className={classes.helpClass}>
                                                                        {hoverColumn([
                                                                            { data: "The most you have to pay for covered services in a plan year." },
                                                                        ])}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            {/* <Grid item>
                                                            <Typography variant="subtitle1" className='font-12' gutterBottom>
                                                                The most you have to pay for covered services in a plan year.
                                                            </Typography>
                                                        </Grid> */}
                                                            <Grid item>
                                                                <TextField
                                                                    tabIndex={4}
                                                                    name="maxOutOfPocket"
                                                                    value={values.maxOutOfPocket}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    id="standard-start-adornment"
                                                                    className={`${classes.textField}`}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                    }}
                                                                    error={Boolean(touched.maxOutOfPocket && errors.maxOutOfPocket)}
                                                                    variant="standard"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container className='pt-4 justify-content-end'>
                                                    <Button
                                                        onClick={() => handleClose(false)}
                                                        variant="contained"
                                                        color="default"
                                                        className={`mr-3`}>
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={calculateOOPPending}
                                                    >
                                                        Calculate
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>

    )
}

export default PocketCostDialog
