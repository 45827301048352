import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/brand';
import * as API from '../api/brand';
import { sessionErrorHandling } from './session';
import { parseThemeMetadata, parseFooterLinks } from '../../util/theme';

function* get(action) {
    try {
        yield put({ type: actions.BRAND_GET_PENDING });
        const { callback } = action.payload;
        const brand = yield call(API.getBrand);
        let payload = null;
        if (brand) {
            payload = {
                ...brand,
                themeMetadata: parseThemeMetadata(brand.themeMetadata),
                footerLinks: parseFooterLinks(brand.footerLinks),
            }
        }
        yield put({ type: actions.BRAND_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.BRAND_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDeploymentStatus(action) {
    try {
        yield put({ type: actions.BRAND_GET_DEPLOYMENT_STATUS_PENDING });
        const { callback } = action.payload;
        const payload = yield call(API.getDeploymentStatus);
      
        yield put({ type: actions.BRAND_GET_DEPLOYMENT_STATUS_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.BRAND_GET_DEPLOYMENT_STATUS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.BRAND_CREATE_PENDING });
        const {
            brand,
            callback
        } = action.payload;

        const payload = {
            ...brand,
        }
        const newBrand = yield call(API.createBrand, payload);
        yield put({ type: actions.BRAND_CREATE_FULFILLED, newBrand });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.BRAND_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.BRAND_UPDATE_PENDING });
        const {
            brand,
            callback
        } = action.payload;

        const updatedBrand = yield call(API.updateBrand, brand);
        yield put({ type: actions.BRAND_UPDATE_FULFILLED, updatedBrand });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.BRAND_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

// export function *reset() {
//     yield put({type: actions.ROLES_RESET});
// }

export default function* () {
    yield takeEvery(actions.BRAND_GET, get);
    yield takeEvery(actions.BRAND_CREATE, create);
    yield takeEvery(actions.BRAND_UPDATE, update);
    yield takeEvery(actions.BRAND_GET_DEPLOYMENT_STATUS, getDeploymentStatus);

}