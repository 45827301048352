import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import BasicDetailsSectionComponent from './BasicDetailsSection';
import { createSelector } from 'reselect';
import { selectHasCMSAccess, selectIsSuperAdmin } from '../../../../store/selectors/session';
import { selectRecoveredAmountFlag } from '../../../../store/selectors/settings';
import { selectClaimAllDetails, selectClaimHistory } from '../../../../store/selectors/claimAnalysis';
import { bindActionCreators } from 'redux';
import { getConsolidatedClaims, processBulkOnDemand, setAdjustment, setClaimServiceAdjustments, resetConsolidatedClaimsView, setClaimCommentsConfirmationDialog } from '../../../../store/actions/claimAnalysis';
import { getModelConsolidatedClaims, resetModelConsolidatedClaimsView } from '../../../../store/actions/modeling';

export const selectModelAllDetails = (state) => state.modeling.modelAllDetails;
const selectConsolidatedData = (state) => state.claimAnalysis.consolidatedClaimsData;
const selectModelConsolidatedData = (state) => state.modeling.consolidatedClaims;

const selectHasJSONViewAccess = createSelector(
    selectHasCMSAccess,
    selectIsSuperAdmin,
    (hasCMSAccess, isSuperAdmin) => {
        return hasCMSAccess || isSuperAdmin;
    }
);

const selectTraceSections = createSelector(selectClaimAllDetails,
    (claimAnalysis) => {
        const trace = JSON.parse(claimAnalysis?.claimAnalysisTraceLog ?? null);
        return trace ? trace?.Sections : [];
    }
);

const selectDRGCode = createSelector(
    (state) => state.claimAnalysis.detail,
    (detail) => {
        return detail.length > 0 ? detail[0]?.drg : ''
    }
);

const selectDRGDescription = createSelector(
    (state) => state.claimAnalysis.detail,
    (detail) => {
        return detail.length > 0 ? detail[0]?.drgDescription : ''
    }
);

const selectPrincipalDx = createSelector(
    (state) => state.claimAnalysis.detail,
    (detail) => {
        return detail.length > 0 ? detail[0]?.principalDx : ''
    }
);

const selectPrincipalDxDescription = createSelector(
    (state) => state.claimAnalysis.detail,
    (detail) => {
        return detail.length > 0 ? detail[0]?.principalDxDescription : ''
    }
);

const selectModelPrincipalDx = createSelector(
    (state) => state.modeling.analysisResults,
    (results) => {
        return results.length > 0 ? results[0]?.Principaldx : ''
    }
);

const selectModelDRG = createSelector(
    (state) => state.modeling.analysisResults,
    (results) => {
        return results.length > 0 ? results[0]?.Drg : ''
    }
);

const selectConsolidatedClaims = createSelector(
    selectClaimAllDetails,
    selectConsolidatedData,
    (state) => state.claimAnalysis.getClaimAllDetails.pending,
    (claimDetails, consolidatedData, claimDetailsPending) => {
        if(claimDetails && consolidatedData && !claimDetailsPending) {
            return consolidatedData?.length === 1 &&
            consolidatedData[0] === claimDetails?.claimnumber
                ? []
                : consolidatedData?.filter(x=> x !== claimDetails?.claimnumber)?.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))?.reverse();
        }
        else {
            return [];
        }
    }
);

const selectModelConsolidatedClaims = createSelector(
    selectModelAllDetails,
    selectModelConsolidatedData,
    (state) => state.modeling.getModelAllDetails.pending,
    (claimDetails, consolidatedData, claimDetailsPending) => {
        if(claimDetails && consolidatedData && !claimDetailsPending) {
            return consolidatedData?.length === 1 &&
            consolidatedData[0] === claimDetails?.claimnumber
                ? []
                : consolidatedData?.filter(x=> x !== claimDetails?.claimnumber)?.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))?.reverse();
        }
        else {
            return [];
        }
    }
);

export const BasicDetailsSection = connect(
    () => {
        return (state, props) => ({
            claimAllDetails: props.isFromModel ? selectModelAllDetails(state) : selectClaimAllDetails(state),
            hasJSONViewAccess: selectHasJSONViewAccess(state),
            sections: selectTraceSections(state),
            recoveredAmountFlag: selectRecoveredAmountFlag(state, props),
            drg: props.isFromModel ? selectModelDRG(state) : selectDRGCode(state),
            drgDescription: selectDRGDescription(state),
            principalDx: props.isFromModel ? selectModelPrincipalDx(state) : selectPrincipalDx(state),
            principalDxDescription: selectPrincipalDxDescription(state),
            claimHistory: selectClaimHistory(state,props),
            getClaimDetailsPending: props.isFromModel ? state.modeling.getModelAllDetails.pending : state.claimAnalysis.getClaimAllDetails.pending,
            claimBulkOnDemandPending: state.claimAnalysis.processBulkOnDemand.pending,
            comment: state.claimAnalysis.newComment,
            consolidatedClaims: props.isFromModel ? selectModelConsolidatedClaims(state) : selectConsolidatedClaims(state)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setAdjustment: setAdjustment,
            setClaimServiceAdjustments: setClaimServiceAdjustments,
            processBulkOnDemand: processBulkOnDemand,
            getConsolidatedClaims: getConsolidatedClaims,
            getModelConsolidatedClaims: getModelConsolidatedClaims,
            resetConsolidatedClaimsView: resetConsolidatedClaimsView,
            resetModelConsolidatedClaimsView: resetModelConsolidatedClaimsView,
            setClaimCommentsConfirmationDialog
        }, dispatch)
    })
)(injectIntl(BasicDetailsSectionComponent));