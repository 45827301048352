import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import MrfDialogComponent from './MrfDialog'



export const MrfDialog = connect(

    () => {
      
        return (state, props) => ({
   


        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
      
        }, dispatch)
    })
)(injectIntl(MrfDialogComponent));