import { FieldArray } from "formik";
import React, { useEffect } from "react";

import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginTop: theme.spacing(2),
        width: '85%',
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: '85%',
    },
    formControl1: {
        width: '85%',
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    autoComplete: {
        width: '85%',
    },
}));


export default function RuleNPIRoleFieldArray(props) {
    const {
        isPaySourceTypeSchedule, values, handleBlur, handleChange, touched, errors,
        npiRoles, feeScheduleLabels, setFieldValue, handleFeeScheduleLabelToRateXrefChange,
        actions: { npiRolesLanding }
    } = props;

    const classes = useStyles();

    useEffect(() => {
        if (values.useNpiRole) {
            npiRolesLanding();
        }
    }, [npiRolesLanding, values.useNpiRole]);

    function getHelperText(key, index) {
        if(touched.ruleToNpiroleXref) {
            if(touched.ruleToNpiroleXref[index]) {
                return touched.ruleToNpiroleXref[index][key];
            }
            return false;
        }
        return false;
    }

    function getError(key, index) {
        if(errors.ruleToNpiroleXref) {
            if(errors.ruleToNpiroleXref[index]) {
                return errors.ruleToNpiroleXref[index][key];
            }
            return false;
        }
        return false;
    }

    return (
        <FieldArray
            name="ruleToNpiroleXref"
            render={({ remove, insert }) => (
                <Grid container>
                    <Grid item xs={12}>
                        {values.ruleToNpiroleXref.map((npiRateItem, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="role">Select NPI Role</InputLabel>
                                                <Select
                                                    required
                                                    value={npiRateItem.npiRoleId}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={`ruleToNpiroleXref.${index}.npiRoleId`}
                                                    helperText={getHelperText('npiRoleId', index) && getError('npiRoleId', index)}
                                                    error={Boolean(getHelperText('npiRoleId', index) && getError('npiRoleId', index))}
                                                >
                                                    {npiRoles.map(role => (
                                                        <MenuItem
                                                            key={role.id}
                                                            value={role.id}
                                                        >
                                                            {role.name}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                type="number"
                                                label="Rate"
                                                name={`ruleToNpiroleXref.${index}.rate`}
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={npiRateItem.rate}
                                                helperText={getHelperText('rate', index) && getError('rate', index)}
                                                error={Boolean(getHelperText('rate', index) && getError('rate', index))}
                                            />
                                        </Grid>
                                        {values.feeScheduleId && isPaySourceTypeSchedule && feeScheduleLabels && feeScheduleLabels.length > 0 && 
                                            <Grid item xs={3}>
                                                <Autocomplete
                                                fullWidth
                                                className={classes.autoComplete}
                                                value={feeScheduleLabels.find(x=> x.id === npiRateItem?.feescheduleLabelToRateXRefId) ?? null}
                                                disableClearable={false}
                                                onChange={(e, value) =>  handleFeeScheduleLabelToRateXrefChange(`ruleToNpiroleXref.${index}.feescheduleLabelToRateXRefId`, setFieldValue, value, values.feeScheduleId)}
                                                getOptionSelected={(option, value) => value ? option.id === value.id : null}
                                                name={`ruleToNpiroleXref.${index}.feescheduleLabelToRateXRefId`}
                                                options={feeScheduleLabels}
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        name={`ruleToNpiroleXref.${index}.feescheduleLabelToRateXRefId`}
                                                        label="Rate Label"
                                                        error={getError('feescheduleLabelToRateXRefId', index)}
                                                        helperText={getError('feescheduleLabelToRateXRefId', index)}
                                                        margin="normal" />
                                                }
                                            />
                                            </Grid>
                                        }

                                        {index === 0 && (
                                            <Grid item xs={1}>
                                                <IconButton
                                                    edge="start"
                                                    color="primary"
                                                    aria-label="Add"
                                                    onClick={() => insert(values.ruleToNpiroleXref.length, {
                                                        rate: '',
                                                        npi: '',
                                                    })}
                                                    className={classes.iconButton}>
                                                    <AddCircleIcon />
                                                </IconButton>
                                            </Grid>
                                        )}

                                        {index !== 0 && (
                                            <Grid item xs={1}>
                                                {values.ruleToNpiroleXref.length > 1 && (
                                                    <IconButton
                                                        edge="start"
                                                        color="secondary"
                                                        aria-label="Delete"
                                                        onClick={() => remove(index)}
                                                        className={classes.iconButton}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            )}
        />
    );
}
