import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import AnalysisResultsDialogComponent from './AnalysisResultsDialog';
import { createSelector } from 'reselect';
import { set } from '../../../../../store/actions/pages';
import { createCurrencyDataField, createStringDataField } from '../../../../../util/format';
import { StringEquators, integerEquators } from '../../../../../store/constants/pages';
import { createFilteredSortedDataSelector } from '../../../../../util/selector';



export const AnalysisResultsDialog = connect(
    () => {
        const DEFAULT_SORT = [{ id: 'revcode', orderBy: 'asc' }];

        const selectkeys = createSelector(
            (state) => state.modeling.analysisResults,
            (data) => {
                if (data && data.length > 0) {
                    const keys = Object.entries(data[0]).map(([k, v]) => ({ key: k, type: typeof v }));
                    return keys;
                }
                return [];
            }
        );

        const selectFields = createSelector(
            selectkeys,
            (keys) => {
                return keys.filter(item => item.key !== 'AnalysisIds' && item.key !== 'ClaimChargeId').map(item => {
                    if (item.type === 'string') {
                        return createStringDataField(item.key, item.key, { sortable: true, equators: StringEquators });
                    } else if (item.type === 'number') {
                        return createCurrencyDataField(item.key, item.key, { sortable: true, equators: integerEquators, calculateTotal: true });
                    } else {
                        return createStringDataField(item.key, item.key, { sortable: true, equators: StringEquators, calculateTotal: true });
                    }
                });
            }
        );

        const selectResultsData = createSelector(
            (state) => state.modeling.analysisResults,
            (data) => {
                return data;
            }
        );

        const selectFilteredFields = createSelector(
            selectFields,
            (fields) => {
                return fields.map(field => field.id);
            }
        );
        
        const selectSort = createSelector(
            (state, props) => state.pages[`model-analysis-results-landing`].sort,
            (sort) => sort || DEFAULT_SORT,
        );
        
        const selectFilteredData = createFilteredSortedDataSelector(
            selectFilteredFields,
            (state, props) => state.pages[`model-analysis-results-landing`].filter,
            selectSort,
            selectResultsData,
        );

        return (state, props) => ({
            fields: selectFields(state),
            filteredData: selectFilteredData(state),
            getResultsPending: state.modeling.getAnalysisResults.pending,
            order: state.pages[`model-analysis-results-landing`].order,
            filter: state.pages[`model-analysis-results-landing`].filter,
            sort: selectSort(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setList: (value) => set('model-analysis-results-landing', 'list', value),
            setFilter: (value) => set('model-analysis-results-landing', 'filter', value),
            setSort: (value) => set('model-analysis-results-landing', 'sort', value),
            setOrder: (value) => set('model-analysis-results-landing', 'order', value),
        }, dispatch)
    })
)(injectIntl(AnalysisResultsDialogComponent));