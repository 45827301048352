import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import {createSelector} from 'reselect';

import DashboardTypesPageComponent from './DashboardTypesPage';
import { list, deleteDashboardType } from '../../../store/actions/dashboardTypes';
import {selectDashboardTypes} from '../../../store/selectors/dashboardTypes';
import { set } from '../../../store/actions/pages';
import {createFilteredSortedDataSelector} from '../../../util/selector';
import {createStringDataField} from '../../../util/format';

const DEFAULT_SORT = [{id: 'name', orderBy: 'asc'}];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Name', {sortable: true}),
        createStringDataField('description', 'Description'),
    ]
);

const selectFilteredFields = createSelector(    
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['dashboard-types-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['dashboard-types-landing'].filter,
    selectSort,
    selectDashboardTypes,
);

export const DashboardTypesPage = injectIntl(
    connect(
        (state, props) => ({
            fields: selectFields(state),
            dashboardsFilteredData: selectFilteredData(state),
            sort: selectSort(state),
            dashboardTypeListPending: state.dashboardTypes.list.pending,
            dashboardTypeDeletePending: state.dashboardTypes.delete.pending,
            order: state.pages['dashboard-types-landing'].order,
            filter: state.pages['dashboard-types-landing'].filter,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                dashboardTypeList: list,
                dashboardTypeDelete: deleteDashboardType,
                setFilter: (value) => set('dashboard-types-landing', 'filter', value),
                setOrder: (value) => set('dashboard-types-landing', 'order', value),
                setSort: (value) => set('dashboard-types-landing', 'sort', value),
            }, dispatch)
        })
)(DashboardTypesPageComponent));