export const TIMEZONES = [
    {
        displayName: 'UTC−05:00 (ET) — Eastern Time zone',
        id: 'America/New_York'
    },
    {
        displayName: 'UTC−06:00 (CT) — Central Time zone',
        id: 'America/Chicago'
    },
    {
        displayName: 'UTC−07:00 (MT) — Mountain Time zone',
        id: 'America/Denver'
    },
    {
        displayName: 'UTC−08:00 (PT) — Pacific Time zone',
        id: 'America/Los_Angeles'
    },
    {
        displayName: 'UTC−09:00 (AKT) — Alaska',
        id: 'America/Anchorage'
    },
    {
        displayName: 'UTC−10:00 (HT) — Hawaii',
        id: 'Pacific/Honolulu'
    },
];

export const STATES = [
    { displayName: 'ALABAMA', abbreviation: 'AL' }, { displayName: 'ALASKA', abbreviation: 'AK' }, { displayName: 'AMERICAN SAMOA', abbreviation: 'AS' }, { displayName: 'ARIZONA', abbreviation: 'AZ' }, { displayName: 'ARKANSAS', abbreviation: 'AR' }, { displayName: 'CALIFORNIA', abbreviation: 'CA' }, { displayName: 'COLORADO', abbreviation: 'CO' }, { displayName: 'CONNECTICUT', abbreviation: 'CT' }, { displayName: 'DELAWARE', abbreviation: 'DE' }, { displayName: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' }, { displayName: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' }, { displayName: 'FLORIDA', abbreviation: 'FL' }, { displayName: 'GEORGIA', abbreviation: 'GA' }, { displayName: 'GUAM', abbreviation: 'GU' }, { displayName: 'HAWAII', abbreviation: 'HI' }, { displayName: 'IDAHO', abbreviation: 'ID' }, { displayName: 'ILLINOIS', abbreviation: 'IL' }, { displayName: 'INDIANA', abbreviation: 'IN' }, { displayName: 'IOWA', abbreviation: 'IA' }, { displayName: 'KANSAS', abbreviation: 'KS' }, { displayName: 'KENTUCKY', abbreviation: 'KY' }, { displayName: 'LOUISIANA', abbreviation: 'LA' }, { displayName: 'MAINE', abbreviation: 'ME' }, { displayName: 'MARSHALL ISLANDS', abbreviation: 'MH' }, { displayName: 'MARYLAND', abbreviation: 'MD' }, { displayName: 'MASSACHUSETTS', abbreviation: 'MA' }, { displayName: 'MICHIGAN', abbreviation: 'MI' }, { displayName: 'MINNESOTA', abbreviation: 'MN' }, { displayName: 'MISSISSIPPI', abbreviation: 'MS' }, { displayName: 'MISSOURI', abbreviation: 'MO' }, { displayName: 'MONTANA', abbreviation: 'MT' }, { displayName: 'NEBRASKA', abbreviation: 'NE' }, { displayName: 'NEVADA', abbreviation: 'NV' }, { displayName: 'NEW HAMPSHIRE', abbreviation: 'NH' }, { displayName: 'NEW JERSEY', abbreviation: 'NJ' },
    { displayName: 'NEW MEXICO', abbreviation: 'NM' }, { displayName: 'NEW YORK', abbreviation: 'NY' }, { displayName: 'NORTH CAROLINA', abbreviation: 'NC' }, { displayName: 'NORTH DAKOTA', abbreviation: 'ND' }, { displayName: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' }, { displayName: 'OHIO', abbreviation: 'OH' }, { displayName: 'OKLAHOMA', abbreviation: 'OK' }, { displayName: 'OREGON', abbreviation: 'OR' }, { displayName: 'PALAU', abbreviation: 'PW' }, { displayName: 'PENNSYLVANIA', abbreviation: 'PA' }, { displayName: 'PUERTO RICO', abbreviation: 'PR' }, { displayName: 'RHODE ISLAND', abbreviation: 'RI' }, { displayName: 'SOUTH CAROLINA', abbreviation: 'SC' }, { displayName: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { displayName: 'TENNESSEE', abbreviation: 'TN' }, { displayName: 'TEXAS', abbreviation: 'TX' }, { displayName: 'UTAH', abbreviation: 'UT' }, { displayName: 'VERMONT', abbreviation: 'VT' }, { displayName: 'VIRGIN ISLANDS', abbreviation: 'VI' }, { displayName: 'VIRGINIA', abbreviation: 'VA' }, { displayName: 'WASHINGTON', abbreviation: 'WA' }, { displayName: 'WEST VIRGINIA', abbreviation: 'WV' }, { displayName: 'WISCONSIN', abbreviation: 'WI' }, { displayName: 'WYOMING', abbreviation: 'WY' }
]