import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { create, errorResetClear, update, updateBulk } from '../../../store/actions/payers';
import PayerDialogComponent from './PayerDialog';
import { setV2 } from '../../../store/actions/pagesV2';

export const PayerDialog = connect(
    () => {
        const selectSelectedPayers = (state, props) => state.pagesV2['payerinfo-landing'].selected;
        const selectPayerContracts = (state, props) => props.payerContracts;
        const selectPayerInfoEditDialog = (state, props) => state.payers.payerInfoEditDialog;

        const selectPayer = createSelector(
            selectPayerInfoEditDialog,
            (payerInfoEditDialog) => {
                if (payerInfoEditDialog && payerInfoEditDialog?.payer) {
                    return payerInfoEditDialog?.payer
                }
                return null
            }
        )

        const selectOpen = createSelector(
            selectPayerInfoEditDialog,
            (payerInfoEditDialog) => {
                if (payerInfoEditDialog && payerInfoEditDialog?.open) {
                    return true
                }
                return false
            }
        )

        const selectBulk = createSelector(
            selectPayerInfoEditDialog,
            (payerInfoEditDialog) => {
                if (payerInfoEditDialog && payerInfoEditDialog?.bulk) {
                    return true
                }
                return false
            }
        )

        const selectMode = createSelector(
            selectBulk, selectPayer,
            (isBulk, payer) => {
                if (isBulk) {
                    return 'edit';
                }
                return payer ? 'edit' : 'create';
            }
        )

        const selectInitialValues = createSelector(
            selectMode,
            selectPayer,
            selectPayerContracts,
            (mode, payer, payerContracts) => {
                if (mode === 'edit' && payer) {
                    let payload = {
                        id: payer.id,
                        payerContractId: payer.payerContractId,
                        enabled: payer.active,
                        name: payer.name,
                        type: payer.type,
                    }

                    if (payer.payerContractId && payerContracts && payerContracts.length > 0) {
                        const pc = payerContracts.find(x => x.id === payer.payerContractId);
                        if (pc) {
                            payload = {
                                ...payload,
                                payerContractId: pc,
                            }
                        }
                    }

                    return payload;
                }

                return {
                    payerContractId: null,
                    enabled: false,
                    name: '',
                    type: '',
                }
            }
        )



        return (state, props) => ({
            //create payer
            payerCreatePending: state.payers.create.pending,
            // update payer
            payerUpdatePending: state.payers.update.pending,
            // update payer
            payerUpdateBulkPending: state.payers.updateBulk.pending,
            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            selectedPayers: selectSelectedPayers(state, props),
            isBulk: selectBulk(state, props),
            open: selectOpen(state, props),
            bulk: selectBulk(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payerCreate: create,
            payerUpdate: update,
            payerBulkUpdate: updateBulk,
            payerErrorReset: errorResetClear,
            setSelected: (value) => setV2('payerinfo-landing', 'selected', value),
        }, dispatch)
    })
)(injectIntl(PayerDialogComponent));