import React, { useEffect } from 'react'
import { Loadable } from '../../../common';
import { Formik } from 'formik';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../partials/content/Portlet';
import { Button, Grid, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { BackButton } from '../../../common/BackButton';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '90%',
    },
}));

function DRGEditPage({ intl, initialValues, history, mode, createPending, editPending, getPending,
    drgId, actions: { createDRG, getDRG, editDRG } }) {
    const classes = useStyles();

    useEffect(() => {
        if (mode === 'edit') {
            getDRG(drgId)
        }
    }, [getDRG, mode, drgId]);

    const handleCallback = () => {
        history.push('/cms/ipps');
    }

    const handleChangeLowVolumeAdjustment = (setFieldValue, e) => {
        const min = 0;
        const max = 100;
        let value: string | number = parseInt(e.target.value);
        if (isNaN(value)) {
            value = '';
        } else if (value > max) {
            value = max;
        } else if (value < min) {
            value = min;
        }

        setFieldValue("lowVolumnAdjustment", value);
    }

    return (
        <Loadable loading={createPending || editPending || getPending}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors: { [key: string]: string } = {};

                    if (values.name === '') {
                        errors.name = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.opLaborRalted === '') {
                        errors.opLaborRalted = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.opNonlaborRalted === '') {
                        errors.opNonlaborRalted = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.opWageIndex === '') {
                        errors.opWageIndex = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.opCostOfLivingAdj === '') {
                        errors.opCostOfLivingAdj = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.opImeOperatingAdjFactor === '') {
                        errors.opImeOperatingAdjFactor = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.opDshOperatingAdjFactor === '') {
                        errors.opDshOperatingAdjFactor = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.opLaborRelatedPortion === '') {
                        errors.opLaborRelatedPortion = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.opNonlaborRelatedPortion === '') {
                        errors.opNonlaborRelatedPortion = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (values.opCostOfChargeRatio === '') {
                        errors.opCostOfChargeRatio = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.capFederalCapitalRate === '') {
                        errors.capFederalCapitalRate = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.capLargeUrbanAddon === '') {
                        errors.capLargeUrbanAddon = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.capCbsaGaf === '') {
                        errors.capCbsaGaf = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.capCostOfLivingAdj === '') {
                        errors.capCostOfLivingAdj = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.capImeOperatingAdjFactor === '') {
                        errors.capImeOperatingAdjFactor = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.capDshOperatingAdjFactor === '') {
                        errors.capDshOperatingAdjFactor = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.capCostToChargeRatio === '') {
                        errors.capCostToChargeRatio = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.otherFixedLossOutlinerTheshold === '') {
                        errors.otherFixedLossOutlinerTheshold = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.otherMarginalCostFactor === '') {
                        errors.otherMarginalCostFactor = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (!values.effectiveFrom) {
                        errors.effectiveFrom = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (!values.effectiveTo) {
                        errors.effectiveTo = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.lowVolumnAdjustment === '') {
                        errors.lowVolumnAdjustment = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    return errors;
                }}
                onSubmit={(values) => {
                    let payload = {
                        name: values.name,
                        opLaborRalted: values.opLaborRalted,
                        opNonlaborRalted: values.opNonlaborRalted,
                        opWageIndex: values.opWageIndex,
                        opCostOfLivingAdj: values.opCostOfLivingAdj,
                        opImeOperatingAdjFactor: values.opImeOperatingAdjFactor,
                        opDshOperatingAdjFactor: values.opDshOperatingAdjFactor,
                        opLaborRelatedPortion: values.opLaborRelatedPortion,
                        opNonlaborRelatedPortion: values.opNonlaborRelatedPortion,
                        opCostOfChargeRatio: values.opCostOfChargeRatio,
                        capFederalCapitalRate: values.capFederalCapitalRate,
                        capLargeUrbanAddon: values.capLargeUrbanAddon,
                        capCbsaGaf: values.capCbsaGaf,
                        capCostOfLivingAdj: values.capCostOfLivingAdj,
                        capImeOperatingAdjFactor: values.capImeOperatingAdjFactor,
                        capDshOperatingAdjFactor: values.capDshOperatingAdjFactor,
                        capCostToChargeRatio: values.capCostToChargeRatio,
                        otherFixedLossOutlinerTheshold: values.otherFixedLossOutlinerTheshold,
                        otherMarginalCostFactor: values.otherMarginalCostFactor,
                        lowVolumnAdjustment: values.lowVolumnAdjustment,
                        effectiveFrom: values.effectiveFrom,
                        effectiveTo: values.effectiveTo,
                        id: undefined as number
                    }
                    if (mode === 'create') {
                        createDRG(payload, handleCallback);
                    } else {
                        payload = {
                            ...payload,
                            id: parseInt(drgId)
                        }
                        editDRG(payload, handleCallback);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    dirty
                }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <Portlet>
                            <PortletHeader
                                title='IPPS Configuration'
                                toolbar={
                                    <PortletHeaderToolbar>
                                        <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/cms/ipps" />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className='ml-3'
                                            type="submit"
                                            disabled={createPending || editPending || (mode === 'edit' && !dirty)}
                                        >
                                            Save
                                        </Button>
                                    </PortletHeaderToolbar>
                                }
                            />
                            <PortletBody>
                                <div className="root">
                                    <Grid container>
                                        <Grid xs={8}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Name"
                                                        name="name"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        helperText={touched.name && errors.name}
                                                        error={Boolean(touched.name && errors.name)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Fixed Loss Outlier Threshold"
                                                        name="otherFixedLossOutlinerTheshold"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.otherFixedLossOutlinerTheshold}
                                                        helperText={touched.otherFixedLossOutlinerTheshold && errors.otherFixedLossOutlinerTheshold}
                                                        error={Boolean(touched.otherFixedLossOutlinerTheshold && errors.otherFixedLossOutlinerTheshold)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Marginal Cost Factor"
                                                        name="otherMarginalCostFactor"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.otherMarginalCostFactor}
                                                        helperText={touched.otherMarginalCostFactor && errors.otherMarginalCostFactor}
                                                        error={Boolean(touched.otherMarginalCostFactor && errors.otherMarginalCostFactor)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Low Volume Adjustment"
                                                        name="lowVolumnAdjustment"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => handleChangeLowVolumeAdjustment(setFieldValue, e)}
                                                        value={values.lowVolumnAdjustment}
                                                        helperText={touched.lowVolumnAdjustment && errors.lowVolumnAdjustment}
                                                        error={Boolean(touched.lowVolumnAdjustment && errors.lowVolumnAdjustment)}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        type="date"
                                                        label="Effective From"
                                                        name="effectiveFrom"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.effectiveFrom}
                                                        helperText={touched.effectiveFrom && errors.effectiveFrom}
                                                        error={Boolean(touched.effectiveFrom && errors.effectiveFrom)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        type="date"
                                                        label="Effective To"
                                                        name="effectiveTo"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.effectiveTo}
                                                        helperText={touched.effectiveTo && errors.effectiveTo}
                                                        error={Boolean(touched.effectiveTo && errors.effectiveTo)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Card as='h5' className='mt-3'>
                                                <Card.Header>
                                                    Operating Values
                                                </Card.Header>
                                                <Card.Body>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Labor-related"
                                                                name="opLaborRalted"
                                                                className={`${classes.textField} mt-2`}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opLaborRalted}
                                                                helperText={touched.opLaborRalted && errors.opLaborRalted}
                                                                error={Boolean(touched.opLaborRalted && errors.opLaborRalted)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Nonlabor-related"
                                                                name="opNonlaborRalted"
                                                                className={`${classes.textField} mt-2`}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opNonlaborRalted}
                                                                helperText={touched.opNonlaborRalted && errors.opNonlaborRalted}
                                                                error={Boolean(touched.opNonlaborRalted && errors.opNonlaborRalted)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="CBSA Wage Index"
                                                                name="opWageIndex"
                                                                className={`${classes.textField} mt-2`}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opWageIndex}
                                                                helperText={touched.opWageIndex && errors.opWageIndex}
                                                                error={Boolean(touched.opWageIndex && errors.opWageIndex)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Cost of Living Adjustment (COLA)"
                                                                name="opCostOfLivingAdj"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opCostOfLivingAdj}
                                                                helperText={touched.opCostOfLivingAdj && errors.opCostOfLivingAdj}
                                                                error={Boolean(touched.opCostOfLivingAdj && errors.opCostOfLivingAdj)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="IME Operating Adjustment Factor"
                                                                name="opImeOperatingAdjFactor"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opImeOperatingAdjFactor}
                                                                helperText={touched.opImeOperatingAdjFactor && errors.opImeOperatingAdjFactor}
                                                                error={Boolean(touched.opImeOperatingAdjFactor && errors.opImeOperatingAdjFactor)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="DSH Operating Adjustment Factor"
                                                                name="opDshOperatingAdjFactor"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opDshOperatingAdjFactor}
                                                                helperText={touched.opDshOperatingAdjFactor && errors.opDshOperatingAdjFactor}
                                                                error={Boolean(touched.opDshOperatingAdjFactor && errors.opDshOperatingAdjFactor)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Labor Related Portion"
                                                                name="opLaborRelatedPortion"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opLaborRelatedPortion}
                                                                helperText={touched.opLaborRelatedPortion && errors.opLaborRelatedPortion}
                                                                error={Boolean(touched.opLaborRelatedPortion && errors.opLaborRelatedPortion)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Nonlabor Related Portion"
                                                                name="opNonlaborRelatedPortion"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opNonlaborRelatedPortion}
                                                                helperText={touched.opNonlaborRelatedPortion && errors.opNonlaborRelatedPortion}
                                                                error={Boolean(touched.opNonlaborRelatedPortion && errors.opNonlaborRelatedPortion)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Operating Cost to Charge Ratio"
                                                                name="opCostOfChargeRatio"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.opCostOfChargeRatio}
                                                                helperText={touched.opCostOfChargeRatio && errors.opCostOfChargeRatio}
                                                                error={Boolean(touched.opCostOfChargeRatio && errors.opCostOfChargeRatio)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Card.Body>
                                            </Card>
                                            <Card as='h5' className='mt-4'>
                                                <Card.Header>
                                                    Capital Values
                                                </Card.Header>
                                                <Card.Body>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Federal Capital Rate"
                                                                name="capFederalCapitalRate"
                                                                className={`${classes.textField} mt-2`}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.capFederalCapitalRate}
                                                                helperText={touched.capFederalCapitalRate && errors.capFederalCapitalRate}
                                                                error={Boolean(touched.capFederalCapitalRate && errors.capFederalCapitalRate)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Large Urban Add on"
                                                                name="capLargeUrbanAddon"
                                                                className={`${classes.textField} mt-2`}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.capLargeUrbanAddon}
                                                                helperText={touched.capLargeUrbanAddon && errors.capLargeUrbanAddon}
                                                                error={Boolean(touched.capLargeUrbanAddon && errors.capLargeUrbanAddon)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="CBSA GAF"
                                                                name="capCbsaGaf"
                                                                className={`${classes.textField} mt-2`}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.capCbsaGaf}
                                                                helperText={touched.capCbsaGaf && errors.capCbsaGaf}
                                                                error={Boolean(touched.capCbsaGaf && errors.capCbsaGaf)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Cost of Living Adjustment"
                                                                name="capCostOfLivingAdj"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.capCostOfLivingAdj}
                                                                helperText={touched.capCostOfLivingAdj && errors.capCostOfLivingAdj}
                                                                error={Boolean(touched.capCostOfLivingAdj && errors.capCostOfLivingAdj)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="IME Operating Adjustment Factor"
                                                                name="capImeOperatingAdjFactor"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.capImeOperatingAdjFactor}
                                                                helperText={touched.capImeOperatingAdjFactor && errors.capImeOperatingAdjFactor}
                                                                error={Boolean(touched.capImeOperatingAdjFactor && errors.capImeOperatingAdjFactor)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="DSH Operating Adjustment Factor"
                                                                name="capDshOperatingAdjFactor"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.capDshOperatingAdjFactor}
                                                                helperText={touched.capDshOperatingAdjFactor && errors.capDshOperatingAdjFactor}
                                                                error={Boolean(touched.capDshOperatingAdjFactor && errors.capDshOperatingAdjFactor)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                type='number'
                                                                required
                                                                label="Capital Cost to Charge Ratio"
                                                                name="capCostToChargeRatio"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.capCostToChargeRatio}
                                                                helperText={touched.capCostToChargeRatio && errors.capCostToChargeRatio}
                                                                error={Boolean(touched.capCostToChargeRatio && errors.capCostToChargeRatio)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Card.Body>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </form>
                )}
            </Formik>

        </Loadable>
    )
}

export default DRGEditPage
