import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ModelEditPageComponent from './ModelEditPage';
import { getModelById } from '../../../../store/actions/modeling';
import { createSelector } from 'reselect';

export const ModelEditPage = connect(
    () => {
        const selectMode = (state, props) => props.match.params.modelId === 'new' ? 'create' : 'edit';
        const selectModelId = (state, props) => props.match.params.modelId;

        const selectModel = createSelector(
            (state) => state.modeling.model,
            selectModelId,
            (model, modelId) => {
                if (model && modelId && model.id === parseInt(modelId)) {
                    return model;
                }
                return null;
            }
        );

        return (state, props) => ({
            mode: selectMode(state, props),
            modelId: selectModelId(state, props),
            model: selectModel(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getModelById: getModelById
        }, dispatch)
    })
)(injectIntl(ModelEditPageComponent));