import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { createSelector } from "reselect";
import {tiny} from "../../../../store/actions/contracts";
import {
    createStringDataField,

} from "../../../../util/format";
import {
    get,
    changePrecedence,
    activateContractFamily,
    deactivateContractFamily,
    update

} from "../../../../store/actions/contractFamily";


import ContractFamilyEditComponent from "./ContractsFamilyEditPage";

const selectFields = createSelector(() => [
    createStringDataField("contractName", "Name"),

]);

const selectFamilyData = (state) => state.contractsFamily.data;

const selectContractActive = createSelector(
    selectFamilyData,
    (familyData)=>{return familyData[0]?.active}
);

const selectContractsInFamily = createSelector(
    selectFamilyData,
    (familyData)=>{return familyData[0]?.contractFamilyXref.sort((a, b) => a.precedence - b.precedence)}
);




export const ContractFamilyEditPage = connect(
    () => {
        const selectContractId = (state, props) => props.match.params.id;
        const selectInitialValues = createSelector(
            selectFamilyData,
            (familyData) => {
                return {
                    name: familyData[0]?.name ?? '',
                    description: familyData[0]?.description ?? '',
                    active: familyData[0]?.active ?? '',
                    contracts: familyData[0]?.contractFamilyXref?.sort((a, b) => a.precedence - b.precedence)?.map((item)=>({name:item?.contractName,id:item.contractId,...item})) ?? [],

                }

            }
        )

        return (state, props) => ({
            initialValues: selectInitialValues(state, props),
            getPending: state.contractsFamily.get.pending,
            activatePending: state.contractsFamily.activateContract.pending,
            deactivatePending: state.contractsFamily.deactivateContract.pending,
            changePrecedencePending: state.contractsFamily.changePrecedence.pending,
            updatePending: state.contractsFamily.update.pending,
            familyId: selectContractId(state, props),
            isActive:selectContractActive(state,props),
            familyData:selectContractsInFamily(state,props),
            // Rule
            fields: selectFields(state),
            tinyContracts: state.contracts.tiny

        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators(
            {
                getTinyContracts: tiny,
                updateContractFamily: update,
                activateContractFamily,
                deactivateContractFamily,
                getFamily: get,
                changePrecedence,
            },
            dispatch
        )
    })
)(injectIntl(ContractFamilyEditComponent));
