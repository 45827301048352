
export const APP_ID_GET = 'APP_ID_GET';
export const APP_ID_GET_PENDING = 'APP_ID_GET_PENDING';
export const APP_ID_GET_FULFILLED = 'APP_ID_GET_FULFILLED';
export const APP_ID_GET_REJECTED = 'APP_ID_GET_REJECTED';

export const APP_ID_CREATE = 'APP_ID_CREATE';
export const APP_ID_CREATE_PENDING = 'APP_ID_CREATE_PENDING';
export const APP_ID_CREATE_FULFILLED = 'APP_ID_CREATE_FULFILLED';
export const APP_ID_CREATE_REJECTED = 'APP_ID_CREATE_REJECTED';

export const APP_ID_KEY_VALUE = 'APP_ID_KEY_VALUE';
export const APP_ID_KEY_VALUE_PENDING = 'APP_ID_KEY_VALUE_PENDING';
export const APP_ID_KEY_VALUE_FULFILLED = 'APP_ID_KEY_VALUE_FULFILLED';
export const APP_ID_KEY_VALUE_REJECTED = 'APP_ID_KEY_VALUE_REJECTED';

export const APP_ID_RESET_ACTUAL_KEY='APP_ID_RESET_ACTUAL_KEY'

export const APP_ID_RESET_LIST='APP_ID_RESET_LIST'



export const getAppId = () => ({
    type: APP_ID_GET,
});



export const getAppIdValue = (id, callback) => ({
    type: APP_ID_KEY_VALUE,
    payload: { id, callback }
});


export const createAppId = (values, callback) => ({
    type: APP_ID_CREATE,
    payload: { values, callback }
 
});



export const resetActualAppIdKey = () => ({
    type: APP_ID_RESET_ACTUAL_KEY
});

export const resetAppIdsList = () => ({
    type: APP_ID_RESET_LIST
});