export const QUICKSIGHT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIMESTAMP_FORMAT = 'YYYY-MM-DD hh:mm:ss a';
export const DAYS = ['01', '02', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
export const MONTHS = [
    { id: '01', name: 'Jan' },
    { id: '02', name: 'Feb' },
    { id: '03', name: 'Mar' },
    { id: '04', name: 'Apr' },
    { id: '05', name: 'May' },
    { id: '06', name: 'June' },
    { id: '07', name: 'July' },
    { id: '08', name: 'Aug' },
    { id: '09', name: 'Sep' },
    { id: '10', name: 'Oct' },
    { id: '11', name: 'Nov' },
    { id: '12', name: 'Dec' }
];

export const searchPeriods = [
    { id: "Yesterday", value: "Yesterday" },
    { id: "Last7Days", value: "Last 7 Days" },
    { id: "Last30Days", value: "Last 30 Days" },
    { id: "Last90Days", value: "Last 90 Days" },
    { id: "ThisMonth", value: "This Month" },
    { id: "LastMonth", value: "Last Month" },
    { id: "Last6Months", value: "Last 6 Months" },
    { id: "Last12Months", value: "Last 12 Months" },
    { id: "ThisQuarter", value: "This Quarter" },
    { id: "LastQuarter", value: "Last Quarter" },
    { id: "ThisYear", value: "This Year" },
    { id: "LastYear", value: "Last Year" },
];

export const searchPeriodsMap = {
    "ThisMonth": "This Month",
    "LastMonth": "Last Month",
    "Yesterday": "Yesterday",
    "Last7Days": "Last 7 Days",
    "Last30Days": "Last 30 Days",
    "Last90Days": "Last 90 Days",
    "Last6Months": "Last 6 Months",
    "Last12Months": "Last 12 Months",
    "ThisQuarter": "This Quarter",
    "LastQuarter": "Last Quarter",
    "ThisYear": "This Year",
    "LastYear": "Last Year",
}