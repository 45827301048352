import {
    METHOD_GET,
    METHOD_POST,
    METHOD_DELETE,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../headers';
import { apiHandleResponse } from '../../util/api';

const listDashboardDataSources = () => {
    let endpoint = `${SERVER_URL}/api/v1/dashboard/datasources`;
    return fetch(endpoint, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getDashboardDataSource = (dashboardDataSourceId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/datasource/${dashboardDataSourceId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createDashboardDataSource = (dashboardDataSource) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/datasource`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(dashboardDataSource)
    }).then(apiHandleResponse);
};

const updateDashboardDataSource = (dashboardDataSource) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/datasource`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(dashboardDataSource)
    }).then(apiHandleResponse);
};

const deleteDashboardDataSource = (dashboardDataSourceId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/datasource/${dashboardDataSourceId}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
};

const getDashboardDataSourceFields = (dashboardDataSourceId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/datasource/fields/${dashboardDataSourceId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    listDashboardDataSources,
    getDashboardDataSource,
    createDashboardDataSource,
    updateDashboardDataSource,
    deleteDashboardDataSource,
    getDashboardDataSourceFields,
};