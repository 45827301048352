import { createReducer } from '../../util';
import * as actions from '../actions/dashboardDataSources';
import { StatusState } from './common';

const addObjectIfNotExist = (state, dataSource) => {
    const data = state.data;
    const { id } = dataSource;

    const dataSourceExist = data.find(x => x.id === id);
    if (!dataSourceExist) {
        return {
            data: [...state.data, dataSource]
        }
    }
};

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    fields: [],
    list: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getFields: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {

    [actions.DASHBOARD_DATA_SOURCES_LIST_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_LIST_FULFILLED]: (state, dashboards) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: dashboards,
    }),
    [actions.DASHBOARD_DATA_SOURCES_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.DASHBOARD_DATA_SOURCES_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_GET_FULFILLED]: (state, dataSource) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, dataSource),
    }),
    [actions.DASHBOARD_DATA_SOURCES_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.DASHBOARD_DATA_SOURCES_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.DASHBOARD_DATA_SOURCES_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_FIELDS_GET_PENDING]: (state) => ({
        ...state,
        getFields: {
            pending: true,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_FIELDS_GET_FULFILLED]: (state, fields) => ({
        ...state,
        getFields: {
            ...initialStatusState,
        },
        fields,
    }),
    [actions.DASHBOARD_DATA_SOURCES_FIELDS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFields: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.DASHBOARD_DATA_SOURCES_RESET]: () => ({ ...initialState }),
    [actions.DASHBOARD_DATA_SOURCES_ERROR_RESET]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        }
    }),
});