import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import VersionUpdatePageComponent from './VersionUpdatePage';
import { getReleaseNotes } from '../../../store/actions/settings';
import { selectReleaseNotes } from '../../../store/selectors/settings';
    

export const VersionUpdatePage = connect(
    () => {
        return (state, props) => ({
            getReleaseNotesPending: state.settings.getReleaseNotes.pending,
            releases: selectReleaseNotes(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getReleaseNotes: getReleaseNotes
        }, dispatch)
    })
)(injectIntl(VersionUpdatePageComponent));