import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import {
    createStringDataField,
    createDateField, createNumberDataField, createUploadsStatusField
} from '../../util/format';
import { dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import UploadsComponent from './UploadsPage';
import { uploadsLanding } from '../../store/actions/pages';

const selectFields = createSelector(
    () => {
        return [
            createStringDataField('batchId', 'Batch Id', { sortable: true, equators: StringEquators }),
            createStringDataField('name', 'Name', { sortable: true, equators: StringEquators }),
            createNumberDataField('length', 'Length', { sortable: true, equators: integerEquators }),
            createStringDataField('target', 'Target', { sortable: true, equators: StringEquators }),
            createStringDataField('uploadedBy', 'Uploaded By', { sortable: true, equators: StringEquators }),
            createDateField('uploadedAt', 'Uploaded At', { sortable: true, equators: dateEquators }),
            createUploadsStatusField('state', 'Status', 'exception', { hideFromFilter: true, returnWholeRow: true })
        ]
    }
);

const selectUploadsData = createSelector(
    (state) => state.pages['uploads-landing'].list,
    (data) => {
        return data
    }
);

export const UploadsPage = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            uploadsData: selectUploadsData(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            uploadsLanding: uploadsLanding,
        }, dispatch)
    })
)(injectIntl(UploadsComponent));