import {
    METHOD_GET,
    METHOD_PUT,
    METHOD_POST,
    SERVER_URL,
    METHOD_DELETE,
    METHOD_PATCH,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../../store/headers';
import { apiHandleResponse, apiHandleOctetResponse } from '../../util/api';

const getDefaults = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/defaults`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const list = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getContract = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createContract = (contract) => {
    return fetch(`${SERVER_URL}/api/v1/cms`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(contract)
    }).then(apiHandleResponse);
};

const updateContract = (contract) => {
    return fetch(`${SERVER_URL}/api/v1/cms`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(contract)
    }).then(apiHandleResponse);
};

const nameValidity = (ruleName) => {
    return fetch(`${SERVER_URL}/api/v1/cms/validate/${ruleName}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getDocumentList = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/documentmetadata/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const uploadContract = (file, { contractId, documentName, metadata, description }) => {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('contractId', contractId);
    formData.append('documentName', documentName);
    formData.append('metadata', metadata);
    formData.append('description', description);

    return fetch(`${SERVER_URL}/api/v1/cms/document`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: formData,
    }).then(apiHandleResponse);
};

const getDocument = (documentId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/document/${documentId}`, {
        method: METHOD_GET,
        headers: headersAuth(),
    }).then(apiHandleOctetResponse);
};

const getRulesList = (contractServiceId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rules/${contractServiceId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getFeeschedule = (feeScheduleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/${feeScheduleId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getFeescheduleLabels = (feeScheduleId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule/labels/${feeScheduleId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getFeescheduleItems = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feescheduleItems`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const listFeeSchedules = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/list`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getGroups = (ruleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/groups/${ruleId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getTreeGroups = (treePayload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/groups/tree`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(treePayload),
    }).then(apiHandleResponse);
};

const getContractedRates = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contractedrates`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getAlertUsers = (clientId) => {
    return fetch(`${SERVER_URL}/api/v1/users/${clientId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const tiny = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/list/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const paymentGroup = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payment/groups`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const paymentDetails = (payment) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payment/details`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payment),
    }).then(apiHandleResponse);
};

const deleteRule = (ruleId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/rule/${ruleId}`, {
        method: METHOD_DELETE,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

// const getInpatientServices = () => {
//     return fetch(`${SERVER_URL}/api/v1/cms/codeservices`, {
//         method: METHOD_GET,
//         headers: headersAuthReceiveJson(),
//     }).then(apiHandleResponse);
// };

const getFeescheduleRates = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/rates`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getFeescheduleTypes = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/types`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createFeeSchedule = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updateFeeSchedule = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/feeschedule`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getFeescheduleCodes = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/feeschedule/codes`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};


const copyContract = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/copy`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const copyContractCrossClient = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/contract/copyto`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteContract = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/${contractId}`, {
        method: METHOD_DELETE,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getContractCriteria = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/criteria`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getStates = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/states`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const updateContractState = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/state`, {
        method: METHOD_PATCH,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getContractCasCodes = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/cascodes`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getContractedServices = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contractedServices/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getContractDependencies = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/dependencies/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getTinyContractsByState = (state) => {
    return fetch(`${SERVER_URL}/api/v1/cms/list/filter/${state}/tiny?vendor=false`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getDefaults,
    list,
    nameValidity,
    getContract,
    createContract,
    updateContract,
    getDocumentList,
    uploadContract,
    getDocument,
    getRulesList,
    getFeeschedule,
    getFeescheduleLabels,
    getFeescheduleItems,
    listFeeSchedules,
    getGroups,
    getTreeGroups,
    getContractedRates,
    getAlertUsers,
    tiny,
    paymentGroup,
    paymentDetails,
    deleteRule,
    // getInpatientServices,
    getFeescheduleRates,
    getFeescheduleTypes,
    createFeeSchedule,
    updateFeeSchedule,
    getFeescheduleCodes,
    copyContract,
    copyContractCrossClient,
    deleteContract,
    getContractCriteria,
    getStates,
    updateContractState,
    getContractCasCodes,
    getContractedServices,
    getContractDependencies,
    getTinyContractsByState
};