import React, { useState } from 'react';
import { Portlet, PortletBody, PortletHeader } from '../../../partials/content/Portlet';
import { Helmet } from 'react-helmet';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { StopExecutionDialog } from './ModelResultsPage/StopExecutionsDialog';
import { PagedTable } from '../../../common';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useEffect } from 'react';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { ContractModelDialog } from './ContractModelDialog';
import { ROLE_SUPERADMIN } from '../../../store/constants/roles';
import { EXECUTION_STATE_INPROGRESS,EXECUTION_STATE_STARTED,EXECUTION_STATE_SUBMITTED } from '../../../store/constants/execution';


const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

function ContractModelingPage({ history, fields, modelsData, modelLockPending, modelUnlockPending,userRole,stopPending,canAnalyzeModel,
    actions: { contractModelingLanding, lockModel, unlockModel, setList, setContractModelEditDialog, getContractsTiny, searchGetByName,stopExecution,setStopExecutionDialog } }) {

    const [refresh, setRefresh] = useState(Promise.resolve());

    useEffect(() => {
        return () => {
            setList([]);
        }
    }, [setList]);

    useEffect(() => {
        getContractsTiny();
    }, [getContractsTiny]);

    useEffect(() => {
        const pageName = {
            name: 'claimAnalysis-landing'
        }
        searchGetByName(pageName);
    }, [searchGetByName]);

    useEffect(() => {
        const timer = setInterval(() => {
            handleRefresh();
        }, 30000);
        return () => {
            clearInterval(timer);
        }
    }, []); 

    function handleLoad(sort, filter, pagination) {
        contractModelingLanding(pagination, sort, filter);
    }

    function handleAddModel() {
        setContractModelEditDialog({ open: true, model: null, mode: 'create' });
    }

    function handleLock(modelId) {
        lockModel(modelId, handleRefresh);
    }

    function handleUnlock(modelId) {
        unlockModel(modelId, handleRefresh);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handleViewResults(modelId) {
        history.push(`/cms/modeling/results/${modelId}`);
    }

    function handleModelEdit(rowData) {
        setContractModelEditDialog({ open: true, model: rowData, mode: 'edit' });
    }
    function handleStopConfirmOpen(_id) {
        setStopExecutionDialog({id:_id,open:true})
    }
    
    function stopExecutionCallback() {
        handleRefresh();
        setStopExecutionDialog(null)

    }


   

   const canStopExecution = (rowData) => {

   return userRole === ROLE_SUPERADMIN && (rowData?.modelAnalysisExecution?.state === EXECUTION_STATE_INPROGRESS || rowData?.modelAnalysisExecution?.state ===EXECUTION_STATE_STARTED || rowData?.modelAnalysisExecution?.state ===EXECUTION_STATE_SUBMITTED) && rowData?.modelAnalysisExecution?.executionType!='MEMORY'
    
   }

   
    

    function modelsAction(rowData,index) {        
        return <div className='d-flex'>
            {rowData.locked ?
                <IconButton
                    color="primary"
                    className='p-1 mr-1'
                    aria-label="Edit"
                    onClick={() => handleUnlock(rowData.id)}
                >
                    <Tooltip title="Unlock this model">
                        <span className='fas fa-lock text-danger font-16'></span>
                    </Tooltip>
                </IconButton> :
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleLock(rowData.id)}
                ><Tooltip title="Lock this model">
                        <span className='fas fa-lock-open text-primary font-16'></span>
                    </Tooltip>
                </IconButton>
            }
            <IconButton
                color='primary'
                className='p-1'
                aria-label="Edit"
                onClick={() => handleViewResults(rowData.id)}
            >
                <Tooltip title="View Results">
                    <PageviewIcon className='font-16' />
                </Tooltip>
            </IconButton>
                {canStopExecution(modelsData[index]) ?
                    <IconButton 
                        aria-label="edit" 
                        size='small' 
                        onClick={() => handleStopConfirmOpen(modelsData[index]?.modelAnalysisExecution?.id)}>
                            <Tooltip title="Stop Execution">
                            <i className="fas fa-stop-circle"></i>
                        </Tooltip>

                    </IconButton>
                    :
                    canAnalyzeModel && <IconButton
                        color="primary"
                        className='p-1'
                        aria-label="Edit"
                        onClick={() => handleModelEdit(rowData)}
                    >
                        <Tooltip title="Analyze Model">
                            <PlayCircleOutlineIcon className='font-16' />
                        </Tooltip>
                    </IconButton>
                }
        </div >
    }

    return (
        <>
            <Helmet title="Contract Modeling" />
            <Portlet>
                <PortletHeader
                    title={"Models"}
                    name="model-claim-landing"
                />
                <PortletBody>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <PagedTable
                                name="model-claim-landing"
                                title="Model"
                                fields={fields}
                                data={modelsData}
                                onLoad={handleLoad}
                                onAdd={handleAddModel}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                criteriasSaveEnabled={true}
                                loading={stopPending || modelLockPending || modelUnlockPending}
                                hasActions={true}
                                renderActionColumn={modelsAction}
                            // showSearchFilters={true}
                            />
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet >
            <ContractModelDialog handleRefresh={handleRefresh} />
            <StopExecutionDialog stopExecution={stopExecution}  stopExecutionCallback={stopExecutionCallback}/>
        </>
    )
}

export default ContractModelingPage

