import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { getPayerPlansTiny, createPayerPlan } from '../../../store/actions/payers';
import PayerPlanDropdownComponent from './PayerPlanDropdown';

export const PayerPlanDropdown = connect(
    () => {
        const selectPayerPlans = (state, props) => state.payers.payerPlansTiny;

        const selectSortedPayerPlans = createSelector(
            selectPayerPlans,
            (plans) => {
                return plans.sort((a, b) => a.name.localeCompare(b.name));
            }
        );


        return (state, props) => ({
            payerPlansList: selectSortedPayerPlans(state),
            payerPlanCreatePending: state.payers.createPayerPlan.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getPayerPlansTiny: getPayerPlansTiny,
            createPayerPlan: createPayerPlan
        }, dispatch)
    })
)(injectIntl(PayerPlanDropdownComponent));