import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { contractsFamilyLanding, resetPage } from '../../../store/actions/pages';
import { deleteContractFamily } from '../../../store/actions/contractFamily';

import { getByName, reset } from '../../../store/actions/search';
import {
    createStringDataField,
    createDateField,createContractListField,createIconDataField
} from '../../../util/format';
import { dateEquators, StringEquators } from '../../../store/constants/pages';
import ContractsPageComponent from './ContractsFamilyPage';
import { deleteReset } from '../../../store/actions/contracts';
import { selectContractStates } from '../../../store/selectors/contracts';

const selectFields = createSelector(
    () => {
        return [
            createStringDataField('name', 'Name', { sortable: true, equators: StringEquators }),
            createStringDataField('description', 'Description', { sortable: true, equators: StringEquators }),
            createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
            createContractListField('contractFamilyXref', 'Contracts', { hideFromFilter: true, returnWholeRow: true,class:'ellipisis-btn'}),
            createDateField('updatedAt', 'Updated At', { sortable: true, equators: dateEquators }),
            createStringDataField('updatedBy', 'Updated By', { sortable: true, equators: StringEquators }),
        ]
    }
);

const selectContractsData = createSelector(
    (state) => state.pages['contracts-family-landing'].list,
    (data) => {
        return data
    }
);

export const ContractsFamilyPage = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            deletePending: state.contractsFamily.delete.pending,
            contractsData: selectContractsData(state),
            states: selectContractStates(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            contractsLanding: contractsFamilyLanding,
            deleteContractFamily,
            searchGetByName: getByName,
            searchReset: reset,
            deleteReset: deleteReset,
            resetPagedTable: resetPage,
        }, dispatch)
    })
)(injectIntl(ContractsPageComponent));