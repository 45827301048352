import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { create, update, errorReset, setChargeMasterEditDialog } from '../../../../store/actions/chargeMaster';
import { selectFacilityList } from '../../../../store/selectors/facilities';
import { list } from '../../../../store/actions/facilities';
import ChargeMasterDialogComponent from './ChargeMasterDialog';

const drugTypeMeasurementsDropdown=["GR","ME","ML","UN","F2","EA","GM"]

export const ChargeMasterDialog = connect(
    () => {
        const selectChargeMasterEditDialog = (state, props) => state.chargeMaster.chargeMasterEditDialog;

        const selectChargeMasterEditDialogOpen = createSelector(
            selectChargeMasterEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.open) {
                    return true;
                }
                return false;
            }
        );

        const selectCharge = createSelector(
            selectChargeMasterEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.charge) {
                    return editDialog?.charge;
                }
                return null;
            }
        );

        const selectMode = createSelector(
            selectChargeMasterEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.mode) {
                    return editDialog?.mode;
                }
                return 'create';
            }
        );

        const selectSortedFacilities = createSelector(
            selectMode,
            selectFacilityList,
            (mode, facilities) => {
                if (mode === 'edit') {
                    return facilities.sort((a, b) => a.name.localeCompare(b.name))
                }
                return facilities.filter(item => item.active).sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        const selectInitialValues = createSelector(
            selectMode,
            selectCharge,
            (mode, charge) => {
                if (mode === 'edit' && charge) {
                    const payload = {
                        id: charge.id,
                        code: charge.code,
                        departmentName: charge.departmentName,
                        description: charge.description,
                        gl: charge.gl,
                        amount: charge.amount,
                        revenueCode: charge.revenueCode,
                        cpT_HCPCS: charge.cpT_HCPCS,
                        drgCode: charge.drgCode,
                        mod1: charge.mod1,
                        mod2: charge.mod2,
                        mod3: charge.mod3,
                        mod4: charge.mod4,
                        ndc:charge?.ndc || '',
                        drugTypeOfMeasurement:charge?.drugTypeOfMeasurement || null,
                        drugUnitOfMeasurement:charge?.drugUnitOfMeasurement || '',
                        showNdc:(charge?.ndc || charge?.drugTypeOfMeasurement || charge?.drugUnitOfMeasurement) ? true:false,
                        facilityName: charge?.facilityName
                    }

                    return payload;
                }

                return {
                    code: '',
                    departmentName: '',
                    description: '',
                    gl: '',
                    amount: 0,
                    revenueCode: '',
                    cpT_HCPCS: '',
                    drgCode: '',
                    mod1: '',
                    mod2: '',
                    mod3: '',
                    mod4: '',
                    ndc:'',
                    drugTypeOfMeasurement:null,
                    drugUnitOfMeasurement:'',
                    showNdc:false,
                    facilityName: ''
                }
            }
        )

        return (state, props) => ({
            open: selectChargeMasterEditDialogOpen(state, props),
            //create charge Master
            chargeMasterCreatePending: state.chargeMaster.create.pending,
            // update charge Master
            chargeMasterUpdatePending: state.chargeMaster.update.pending,
            // edit
            getFacilitiesPending: state.facilities.list.pending,
            charge: selectCharge(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            drugMeasurementTypes:drugTypeMeasurementsDropdown,
            facilities: selectSortedFacilities(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            chargeMasterCreate: create,
            chargeMasterUpdate: update,
            chargeMasterErrorReset: errorReset,
            setChargeMasterEditDialog: setChargeMasterEditDialog,
            getFacilities: list,
        }, dispatch)
    })
)(injectIntl(ChargeMasterDialogComponent));