import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/drg';
import * as API from '../api/drg';
import { sessionErrorHandling } from './session';

function* create(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.DRG_CREATE_PENDING });
        const payload = yield call(API.createDRG, values);
        yield put({ type: actions.DRG_CREATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DRG_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.DRG_UPDATE_PENDING });
        const payload = yield call(API.editDRG, values);
        yield put({ type: actions.DRG_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DRG_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteDRG(action) {
    try {
        const { drgId, callback } = action.payload;
        yield put({ type: actions.DRG_DELETE_PENDING });
        const payload = yield call(API.deleteDRG, drgId);
        yield put({ type: actions.DRG_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DRG_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDRG(action) {
    try {
        const { drgId } = action.payload;
        yield put({ type: actions.DRG_GET_PENDING });
        const payload = yield call(API.getDRG, drgId);
        yield put({ type: actions.DRG_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DRG_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDRGTiny(action) {
    try {
        yield put({ type: actions.DRG_GET_TINY_PENDING });
        const payload = yield call(API.getDrgListTiny);
        yield put({ type: actions.DRG_GET_TINY_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DRG_GET_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getIPPSTinyByContract(action) {
    try {
        const { contractId } = action.payload;
        yield put({ type: actions.IPPS_GET_TINY_BY_CONTRACT_PENDING });
        const payload = yield call(API.getIPPSListTinyByContract, contractId);
        yield put({ type: actions.IPPS_GET_TINY_BY_CONTRACT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.IPPS_GET_TINY_BY_CONTRACT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getIPPSTinyByTargetClient(action) {
    try {
        const { targetClientId } = action.payload;
        yield put({ type: actions.IPPS_GET_TINY_BY_TARGET_CLIENT_PENDING });
        const payload = yield call(API.getIPPSListByTargetClient, targetClientId);
        yield put({ type: actions.IPPS_GET_TINY_BY_TARGET_CLIENT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.IPPS_GET_TINY_BY_TARGET_CLIENT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getIPPSDepdencies(action) {
    try {
        const { id } = action.payload;
        yield put({ type: actions.IPPS_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getIPPSDependencies, id);
        yield put({ type: actions.IPPS_DELETE_DEPENDENCIES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.IPPS_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.DRG_CREATE, create);
    yield takeEvery(actions.DRG_UPDATE, update);
    yield takeEvery(actions.DRG_DELETE, deleteDRG);
    yield takeEvery(actions.DRG_GET, getDRG);
    yield takeEvery(actions.DRG_GET_TINY, getDRGTiny);
    yield takeEvery(actions.IPPS_GET_TINY_BY_CONTRACT, getIPPSTinyByContract);
    yield takeEvery(actions.IPPS_GET_TINY_BY_TARGET_CLIENT, getIPPSTinyByTargetClient);
    yield takeEvery(actions.IPPS_DELETE_DEPENDENCIES, getIPPSDepdencies);
}