import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import ExportButtonComponent from './ExportButton';
import { exportShoppable } from '../../../../store/actions/shoppables';

import { set } from '../../../../store/actions/pages';
import { getPaginationFilter } from '../../../../util/pagination';

const selectCustomFilter = createSelector(
    (state) => state.pages['shoppable-landing'],
    (table) => {
        return table.customFilter;
    }
);

const selectFilter = createSelector(
    (state) => state.pages['shoppable-landing'],
    (state, props) => props.fields,
    (table, fields) => {
        return getPaginationFilter(fields, table.filter, null, [], table.customFilter);
    }
);

const selectSort = createSelector(
    (state) => state.pages['shoppable-landing'],
    (table) => {
        if (table.sort.length === 0) {
            return [];
        }
        return table.sort;
    },
);

const selectPage = createSelector(
    (state) => state.pages['shoppable-landing'],
    (table) => {
        return table.page;
    },
);

export const ExportButton = connect(
    () => {
        return (state, props) => ({
            customFilter: selectCustomFilter(state),
            shoppablesExportPending: state.shoppables.export.pending,

            filter: selectFilter(state, props),
            sort: selectSort(state, props),
            page: selectPage(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setCustomFilter: (value) => set('shoppable-landing', 'customFilter', value),
            exportShoppable: exportShoppable,
        }, dispatch)
    })
)(injectIntl(ExportButtonComponent));