import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { getPayerPlanDeleteDependencies, setPayerPlanDependencyDialog, deletePayerPlan } from '../../../store/actions/payers';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.payers.payerPlanDependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (payerPlanDependencyDialog) => {
                return payerPlanDependencyDialog?.open || false;
            }
        );

        const selectPlanId = createSelector(
            selectDependencyDialog,
            (payerPlanDependencyDialog) => {
                return payerPlanDependencyDialog?.planId || null;
            }
        );


        return (state, props) => ({
            open: selectOpen(state),
            planId: selectPlanId(state),
            payerPlanDependency: state.payers.payerPlanDependencies.records ?? [],
            canDelete: state.payers.payerPlanDependencies.canDelete,
            payerPlanDependencyPending: state.payers.getPayerPlanDependencies.pending,
            deletePayerPlanPending: state.payers.deletePayerPlan.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setPayerPlanDependencyDialog: setPayerPlanDependencyDialog,
            getPayerPlanDependency: getPayerPlanDeleteDependencies,
            deletePayerPlan: deletePayerPlan
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));