import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { claimPayerDependency, deleteClaimPayer, setClaimDependencyDialog } from '../../../store/actions/payers';
import { setV2 } from '../../../store/actions/pagesV2';

export const DependencyDialog = connect(
    () => {
        const selectClaimIds = (state, props) => state.pagesV2['payerinfo-landing'].selected;
        const selectDependencyDialog = (state) => state.payers.claimDependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            selectedItems: selectClaimIds(state, props),
            claimPayersDependency: state.payers.claimPayersDependency?.records ?? [],
            canDelete: state.payers.claimPayersDependency?.canDelete,
            claimDependencyPending: state.payers.getClaimPayerDependency.pending,
            deleteClaimPayersPending: state.payers.deleteClaimPayers.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('payerinfo-landing', 'selected', value),
            setClaimDependencyDialog: setClaimDependencyDialog,
            claimPayerDependency: claimPayerDependency,
            deleteClaimPayer: deleteClaimPayer
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));