import React, { useState } from 'react';
import { Typography, Slider, Button, Grid, makeStyles } from '@material-ui/core';

const marks = [{ value: 0, label: '0' }, { value: 10, label: '10' }, { value: 20, label: '20' }, { value: 30, label: '30' }, { value: 40, label: '40' }, { value: 50, label: '50' }, { value: 60, label: '60' }, { value: 70, label: '70' }, { value: 80, label: '80' }, { value: 90, label: '90' }, { value: 100, label: '100' },];

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
}));

export default function TimeFilter({ onFilterChange }) {
    const [range, setRange] = useState([70, 100]);
    const classes = useStyles();

    function handleFilterChange(event, newValue) {
        setRange(newValue);
    }

    function handleClick() {
        const newFilter = {
            lowRange: range[0],
            highRange: range[1],
        }
        onFilterChange(newFilter);
    }

    return (
        <div className="time-filter">
            <Grid container alignItems='center' spacing={3}>
                <Grid item xs={9} md={9} lg={9}>
                    <Typography id="range-slider" gutterBottom>
                        Charge association threshold
                    </Typography>
                    <Slider
                        value={range}
                        onChangeCommitted={handleFilterChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        marks={marks}
                    />
                </Grid>
                <Grid item xs={3} md={3} lg={3} className='text-right'>
                    <Button
                        className={classes.button}
                        onClick={handleClick}
                        variant="contained"
                        color="primary">
                        Refresh
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}