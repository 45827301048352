export const FEE_SCHEDULES_GET = 'FEE_SCHEDULES_GET';
export const FEE_SCHEDULES_GET_PENDING = 'FEE_SCHEDULES_GET_PENDING';
export const FEE_SCHEDULES_GET_FULFILLED = 'FEE_SCHEDULES_GET_FULFILLED';
export const FEE_SCHEDULES_GET_REJECTED = 'FEE_SCHEDULES_GET_REJECTED';

export const FEE_SCHEDULES_CREATE_DIRECTORY = 'FEE_SCHEDULES_CREATE_DIRECTORY';
export const FEE_SCHEDULES_CREATE_DIRECTORY_PENDING = 'FEE_SCHEDULES_CREATE_DIRECTORY_PENDING';
export const FEE_SCHEDULES_CREATE_DIRECTORY_FULFILLED = 'FEE_SCHEDULES_CREATE_DIRECTORY_FULFILLED';
export const FEE_SCHEDULES_CREATE_DIRECTORY_REJECTED = 'FEE_SCHEDULES_CREATE_DIRECTORY_REJECTED';

export const FEE_SCHEDULES_UPDATE_DIRECTORY = 'FEE_SCHEDULES_UPDATE_DIRECTORY';
export const FEE_SCHEDULES_UPDATE_DIRECTORY_PENDING = 'FEE_SCHEDULES_UPDATE_DIRECTORY_PENDING';
export const FEE_SCHEDULES_UPDATE_DIRECTORY_FULFILLED = 'FEE_SCHEDULES_UPDATE_DIRECTORY_FULFILLED';
export const FEE_SCHEDULES_UPDATE_DIRECTORY_REJECTED = 'FEE_SCHEDULES_UPDATE_DIRECTORY_REJECTED';

export const FEE_SCHEDULES_MOVE = 'FEE_SCHEDULES_MOVE';
export const FEE_SCHEDULES_MOVE_PENDING = 'FEE_SCHEDULES_MOVE_PENDING';
export const FEE_SCHEDULES_MOVE_FULFILLED = 'FEE_SCHEDULES_MOVE_FULFILLED';
export const FEE_SCHEDULES_MOVE_REJECTED = 'FEE_SCHEDULES_MOVE_REJECTED';

export const FEE_SCHEDULES_HIERARCHY = 'FEE_SCHEDULES_HIERARCHY';
export const FEE_SCHEDULES_HIERARCHY_PENDING = 'FEE_SCHEDULES_HIERARCHY_PENDING';
export const FEE_SCHEDULES_HIERARCHY_FULFILLED = 'FEE_SCHEDULES_HIERARCHY_FULFILLED';
export const FEE_SCHEDULES_HIERARCHY_REJECTED = 'FEE_SCHEDULES_HIERARCHY_REJECTED';

export const FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES = 'FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES';
export const FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_PENDING = 'FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_PENDING';
export const FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_FULFILLED = 'FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_FULFILLED';
export const FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_REJECTED = 'FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_REJECTED';

export const FEE_SCHEDULES_DIRECTORY_DELETE = 'FEE_SCHEDULES_DIRECTORY_DELETE';
export const FEE_SCHEDULES_DIRECTORY_DELETE_PENDING = 'FEE_SCHEDULES_DIRECTORY_DELETE_PENDING';
export const FEE_SCHEDULES_DIRECTORY_DELETE_FULFILLED = 'FEE_SCHEDULES_DIRECTORY_DELETE_FULFILLED';
export const FEE_SCHEDULES_DIRECTORY_DELETE_REJECTED = 'FEE_SCHEDULES_DIRECTORY_DELETE_REJECTED';

export const FEE_SCHEDULES_SEARCH = 'FEE_SCHEDULES_SEARCH';
export const FEE_SCHEDULES_SEARCH_PENDING = 'FEE_SCHEDULES_SEARCH_PENDING';
export const FEE_SCHEDULES_SEARCH_FULFILLED = 'FEE_SCHEDULES_SEARCH_FULFILLED';
export const FEE_SCHEDULES_SEARCH_REJECTED = 'FEE_SCHEDULES_SEARCH_REJECTED';

export const FEE_SCHEDULES_RESET = 'FEE_SCHEDULES_RESET';
export const FEE_SCHEDULES_RESET_DIRECTORY_DEPDENDENCY = 'FEE_SCHEDULES_RESET_DIRECTORY_DEPDENDENCY';
export const FEE_SCHEDULES_DIRECTORY_EDIT_DIALOG = 'FEE_SCHEDULES_DIRECTORY_EDIT_DIALOG';
export const FEE_SCHEDULES_SET_MOVE_DIALOG = 'FEE_SCHEDULES_SET_MOVE_DIALOG';
export const FEE_SCHEDULES_DIRECTORY_SET_DEPENDENCY_DIALOG = 'FEE_SCHEDULES_DIRECTORY_SET_DEPENDENCY_DIALOG';

export const FEE_SCHEDULE_SEARCH_FROM_CLIENT = 'FEE_SCHEDULE_SEARCH_FROM_CLIENT';
export const FEE_SCHEDULE_SEARCH_FROM_CLIENT_PENDING = 'FEE_SCHEDULE_SEARCH_FROM_CLIENT_PENDING';
export const FEE_SCHEDULE_SEARCH_FROM_CLIENT_FULFILLED = 'FEE_SCHEDULE_SEARCH_FROM_CLIENT_FULFILLED';
export const FEE_SCHEDULE_SEARCH_FROM_CLIENT_REJECTED = 'FEE_SCHEDULE_SEARCH_FROM_CLIENT_REJECTED';


export const FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET = 'FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET';
export const FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_PENDING = 'FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_PENDING';
export const FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_FULFILLED = 'FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_FULFILLED';
export const FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_REJECTED = 'FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_REJECTED';


export const FEE_SCHEDULE_LABELS_VALIDATE = 'FEE_SCHEDULE_LABELS_VALIDATE';
export const FEE_SCHEDULE_LABELS_VALIDATE_PENDING = 'FEE_SCHEDULE_LABELS_VALIDATE_PENDING';
export const FEE_SCHEDULE_LABELS_VALIDATE_FULFILLED = 'FEE_SCHEDULE_LABELS_VALIDATE_FULFILLED';
export const FEE_SCHEDULE_LABELS_VALIDATE_REJECTED = 'FEE_SCHEDULE_LABELS_VALIDATE_REJECTED';



export const getFeeSchedules = (directoryId) => ({
    type: FEE_SCHEDULES_GET,
    payload: { directoryId }
});

export const createDirectory = (options, callback) => ({
    type: FEE_SCHEDULES_CREATE_DIRECTORY,
    payload: { options, callback }
});

export const updateDirectory = (options, callback) => ({
    type: FEE_SCHEDULES_UPDATE_DIRECTORY,
    payload: { options, callback }
});

export const resetFeeSchedules = () => ({
    type: FEE_SCHEDULES_RESET
});

export const setDirectoryEditDialog = (directoryEditDialog) => ({
    type: FEE_SCHEDULES_DIRECTORY_EDIT_DIALOG,
    payload: { directoryEditDialog }
});

export const moveDirectoryOrFeeSchedule = (options, callback) => ({
    type: FEE_SCHEDULES_MOVE,
    payload: { options, callback }
});

export const getHierarchy = () => ({
    type: FEE_SCHEDULES_HIERARCHY
});

export const setMoveDialog = (moveDialog) => ({
    type: FEE_SCHEDULES_SET_MOVE_DIALOG,
    payload: { moveDialog }
});

export const setFeeScheduleDirectoryDependencyDialog = (directoryDependencyDialog) => ({
    type: FEE_SCHEDULES_DIRECTORY_SET_DEPENDENCY_DIALOG,
    payload: { directoryDependencyDialog }
});

export const deleteFeeScheduleDirectory = (directoryId, callback) => ({
    type: FEE_SCHEDULES_DIRECTORY_DELETE,
    payload: { directoryId, callback }
});

export const getFeeScheduleDirectoryDependencies = (directoryId) => ({
    type: FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES,
    payload: { directoryId }
});

export const resetDirectoryDependencies = () => ({
    type: FEE_SCHEDULES_RESET_DIRECTORY_DEPDENDENCY
});

export const feeScheduleSearch = (options) => ({
    type: FEE_SCHEDULES_SEARCH,
    payload: { options }
});

export const searchFeeScheduleFromClient = (targetClient) => ({
    type: FEE_SCHEDULE_SEARCH_FROM_CLIENT,
    payload: { targetClient }
});

export const getFeeScheduleLabelsFromTargetClient = (targetClient,feeScheduleId,callback) => ({
    type: FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET,
    payload: { targetClient,feeScheduleId,callback }
});

export const validateLabelsFromTargetClient = (targetClient,feeScheduleId,labelId,index) => ( {
    type: FEE_SCHEDULE_LABELS_VALIDATE,
    payload: { targetClient,feeScheduleId,labelId,index }
}); 