import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { get, create, update, getServiceType, setServiceFromFeeSchedule } from '../../../../store/actions/services';
import { getMethodTypes } from '../../../../store/actions/rules';
import { createServicSelector, selectServiceTypes } from "../../../../store/selectors/services";
import { selectMethodTypes } from "../../../../store/selectors/rules";
import CodeServicesEditPageComponent from './CodeServicesEditPage';
import { getFeeScheduleCodes } from '../../../../store/actions/contracts';

export const CodeServicesEditPage = connect(
    () => {
        const selectserviceId = (state, props) => props.match.params.serviceId;

        const selectMode = (state, props) => props.match.params.serviceId === 'new' ? 'create' : 'edit';

        const selectService = createServicSelector(selectserviceId);

        const selectIsServiceFromFeeSchedule = (state) => state.services.isServiceFromFeeSchedule;

        const SelectFeeScheduleItemName = createSelector(
            (state) => state.pages['feeschedule-items-landing'].filter,
            (data) => {
                return data.length > 0 ? data[0].value : ""
            }
        );

        const selectFeeScheduleCodes = (state) => state.contracts.feeScheduleCodes

        const selectInitialValues = createSelector(
            selectMode,
            selectService,
            SelectFeeScheduleItemName,
            selectFeeScheduleCodes,
            selectIsServiceFromFeeSchedule,
            (mode, service, name, codes, isServiceFromFeeSchedule) => {
                if (mode === 'edit' && service) {
                    const payload = {
                        id: service.id,
                        name: service.name,
                        description: service.description,
                        primaryRuleMethodId: service.primaryRuleMethod ? service.primaryRuleMethod.id : '',
                        primaryCodes: service.primaryCodes,
                        qualifierRuleMethodId: service.qualifierRuleMethod ? service.qualifierRuleMethod.id : '',
                        qualifierCodes: service.qualifierCodes,
                        serviceTypeId: service.serviceTypeId,
                        isExclusion: service.isExclusion
                    }
                    return payload;
                }
                return {
                    name: isServiceFromFeeSchedule ? name : '',
                    description: '',
                    primaryRuleMethodId: '',
                    primaryCodes: isServiceFromFeeSchedule ? codes : '',
                    qualifierRuleMethodId: '',
                    qualifierCodes: '',
                    serviceTypeId: '',
                    isExclusion: false
                }
            }
        )

        return (state, props) => ({
            methodTypes: selectMethodTypes(state, props),
            methodTypesPending: state.rules.getMethodTypes.pending,

            //create
            serviceCreatePending: state.services.create.pending,

            //edit
            serviceUpdatePending: state.services.update.pending,

            //delete
            serviceDeletePending: state.services.delete.pending,

            serviceGetPending: state.services.get.pending,
            serviceTypes: selectServiceTypes(state),


            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            serviceId: selectserviceId(state, props),
            isServiceFromFeeSchedule: state.services.isServiceFromFeeSchedule,
            feeScheduleFilter: state.pages['feeschedule-items-landing'].filter,
            feeSchedulePage: state.pages['feeschedule-items-landing'].page,
            feeScheduleSort: state.pages['feeschedule-items-landing'].sort,
            feeScheduleId: state.services.feeScheduleId
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            serviceCreate: create,
            serviceUpdate: update,
            serviceGetServiceType: getServiceType,
            ruleGetMethodTypes: getMethodTypes,
            serviceGet: get,
            getFeeScheduleCodes: getFeeScheduleCodes,
            setServiceFromFeeSchedule: setServiceFromFeeSchedule
        }, dispatch)
    })
)(injectIntl(CodeServicesEditPageComponent));