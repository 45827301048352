import { Button, Checkbox, Chip, CircularProgress, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, ListItem, ListItemIcon, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { DataTable, Loadable } from '../../common';
import { DeleteConfirmation } from '../../common/DeleteConfirmation';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../partials/content/Portlet';
import { CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_PROFESSIONAL, CONTRACT_STATE_FINALIZED } from '../../store/constants/contract';
import { asIsoDate } from '../../util/date';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90%'
    },
    autoComplete: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90%'
    },
    formControl: {
        marginLeft: theme.spacing(1),
        width: '90%',
        marginTop: theme.spacing(2),
    },
    textArea: {
        marginLeft: theme.spacing(1),
        width: '95%',
    },
    claimCharge: {
        boxShadow: '1px 1px 15px rgb(0 0 0 / 9%)',
        borderRadius: '10px',
        padding: '0px 16px 16px 16px'
    },
    deleteButton: {
        marginTop: theme.spacing(2),
        color: '#d9534f',
    },
    addModifierButton: {
        marginTop: theme.spacing(3),
    },
    chip: {
        marginTop: theme.spacing(3),
        marginLeft: '5px',
        "& span": {
            minWidth: 50
        }
    },
    buttonGrid: {
        textAlign: 'right',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '95%'
    },
    associatedChargeError: {
        color: '#fd397a',
        fontSize: '12px',
        marginBottom: 5
    },
    redColor: {
        color: '#fd397a',
    },
    associatedChargeGrid: {
        maxHeight: '70vh',
        overflowY: 'auto'
    }
}));

function ClaimEstimator({ mode, selectedClaimChargeIndex, intl, history, initialValues, cptCodes, sort, filter, order, getCPTCodesPending, fields,
    tinyContracts, associatedCharges, getAssociatedChargesPending, claimChargeData, estimatePending, estimationId, estimatorMode,
    estimateById, editEstimatePending, getEstimatePending, dxCodes, dxCodesPending,
    actions: { getCPTS, getTinyContractsByState, getAssociatedCharges,
        setClaimEstimatorCharges, setSort, setOrder, estimate, setClaimChargeMode, getEstimateById, editEstimateById, getDXCodes } }) {

    const [isFormRestricted, setRestrictFormSubmit] = useState(false);
    const [customChargeFieldsError, setCustomChargeError] = useState({
        cpt: false,
        revCode: false,
        associatedCharges: false,
        chargeAmount: false,
        units: false,
        serviceDate: false,
    })
    const classes = useStyles();

    useEffect(() => {
        if (estimatorMode === 'edit' && estimateById) {
            setClaimEstimatorCharges(estimateById.chargeEstimations.map(item => {
                let modifiers = [];
                if (item.mod1) {
                    modifiers.push(item.mod1)
                }
                if (item.mod2) {
                    modifiers.push(item.mod2)
                }
                if (item.mod3) {
                    modifiers.push(item.mod3)
                }
                if (item.mod4) {
                    modifiers.push(item.mod4)
                }
                return {
                    ...item,
                    units: item.units.toString() ?? '',
                    totalChargeAmount: item.chargeAmount.toString() ?? '',
                    chargeAmount: (parseFloat(item.chargeAmount) / parseInt(item.units)).toFixed(2),
                    modifiers: modifiers,
                    cpt: item.pxCode
                }
            }))
        }
    }, [estimatorMode, estimateById, setClaimEstimatorCharges])

    useEffect(() => {
        if (estimatorMode === 'edit') {
            getEstimateById(estimationId);
        }
    }, [getEstimateById, estimationId, estimatorMode]);

    useEffect(() => {
        getTinyContractsByState(CONTRACT_STATE_FINALIZED);
        return () => {
            setClaimEstimatorCharges([]);
            setClaimChargeMode('new', null);
        }
    }, [getTinyContractsByState]);

    const handleModifierDelete = (setFieldValue, modifiers, mIndex) => {
        const filteredModifiers = modifiers.filter((_, index) => index !== mIndex);
        setFieldValue(`charges.modifiers`, filteredModifiers);
    }

    const handleAddModifier = (setFieldValue, values) => {
        const updatedModifiers = [...values.charges.modifiers, values.charges.modifierField];
        setFieldValue(`charges.modifiers`, updatedModifiers);
        setFieldValue(`charges.modifierField`, '');
    }

    const handleCPTChange = (setFieldValue, value) => {
        setFieldValue(`charges.cpt`, value);
        if (value && value.code) {
            getAssociatedCharges({ pxCode: value.code, revCode: value.revenueCode });
            // default values
            setFieldValue(`charges.revCodeDescription`, value.revCodeDescription);
            setFieldValue(`charges.chargeAmount`, value.chargeAmount);
            setFieldValue(`charges.revCode`, value.revenueCode.toString());
        } else {
            setFieldValue(`charges.revCodeDescription`, '');
            setFieldValue(`charges.chargeAmount`, '');
            setFieldValue(`charges.revCode`, '');
        }
    }

    const handleAssociatedChargeChange = (setFieldValue, option, values) => {
        const currentIndex = values.charges.associatedCharges.findIndex(ac => ac.id === option.id)
        const temp = [...values.charges.associatedCharges];

        if (currentIndex === -1) {
            temp.push(option)
        } else {
            temp.splice(currentIndex, 1);
        }
        setFieldValue(`charges.associatedCharges`, [...temp]);
    }

    const handleAddToGrid = (values, setFieldValue) => {
        const { associatedCharges, chargeAmount, cpt, modifiers, revCode, units, serviceDate, revCodeDescription } = values.charges;

        if (mode === 'edit') {
            const updatedChargeData = claimChargeData.map((charge, index) => {
                if (index === parseInt(selectedClaimChargeIndex)) {
                    return {
                        ...charge,
                        units,
                        chargeAmount: chargeAmount.toString(),
                        totalChargeAmount: (parseInt(units) * parseFloat(chargeAmount)).toString(),
                        modifiers,
                        serviceDate
                    }
                }
                return charge

            });
            setClaimEstimatorCharges(
                [...updatedChargeData]
            );
            setClaimChargeMode('new', null)
        } else {
            // validating custom fields for claim charge
            if (!cpt || !chargeAmount || !units || !serviceDate) {
                setCustomChargeError({
                    ...customChargeFieldsError,
                    cpt: !cpt,
                    chargeAmount: !chargeAmount,
                    units: !units,
                    serviceDate: !serviceDate,
                })
                return
            }

            const uniqId = `chargeId-${new Date().getTime()}`;

            const secondaryEstimatedCharges = associatedCharges.map(ac => {
                return {
                    chargeAmount: ac.associatedCharges.toString(),
                    totalChargeAmount: ac.associatedCharges.toString(),
                    associatedCharge: `${ac.associatedCode} (${ac.associatedChargeDescription})`,
                    cpt: ac.associatedCode,
                    cptDescription: ac.associatedChargeDescription,
                    modifiers: [],
                    revCode: ac.associatedRevCode,
                    revCodeDescription: ac.associatedRevCodeDescription,
                    units: '1',
                    serviceDate: asIsoDate(),
                    primaryId: uniqId // used as a Foreign key
                }
            });

            //Reduce state
            setClaimEstimatorCharges(
                [
                    ...claimChargeData,
                    {
                        id: uniqId,
                        associatedCharges,
                        chargeAmount: chargeAmount.toString(),
                        totalChargeAmount: (parseInt(units) * parseFloat(chargeAmount)).toString(),
                        cpt: cpt.code,
                        cptDescription: cpt.description,
                        modifiers,
                        units,
                        serviceDate,
                        revCode: revCode,
                        revCodeDescription: revCodeDescription
                    },
                    ...secondaryEstimatedCharges
                ]
            );
        }

        //Reset charge object fields
        setFieldValue('charges', {
            modifierField: '',
            modifiers: [],

            cpt: null,
            revCode: '',
            associatedCharges: [],
            chargeAmount: '',
            totalChargeAmount: '',
            units: '1',
            serviceDate: asIsoDate(),

            cptInputValue: ''
        });
        setCustomChargeError({
            cpt: false,
            revCode: false,
            associatedCharges: false,
            chargeAmount: false,
            units: false,
            serviceDate: false,
        })
    }

    const handleChargeDelete = (index) => {
        const charge = claimChargeData[index];
        const uniqId = charge.id;
        if (uniqId) { // delete all child record if deleting parent e.g. deleting CPT record will delete all associated charges of it
            const temp = claimChargeData.filter(item => item.id !== uniqId && item.primaryId !== uniqId)
            setClaimEstimatorCharges(temp);
        } else { // only delete child record
            const temp = [...claimChargeData];
            temp.splice(index, 1);
            setClaimEstimatorCharges(temp);
        }
    }

    const handleEditCharge = (index, setFieldValue) => {
        setClaimChargeMode('edit', index);
        setFieldValue('charges.revCode', claimChargeData[index].revCode);
        setFieldValue('charges.chargeAmount', claimChargeData[index].chargeAmount);
        setFieldValue('charges.units', claimChargeData[index].units);
        setFieldValue('charges.serviceDate', claimChargeData[index].serviceDate);
        setFieldValue('charges.modifiers', claimChargeData[index].modifiers);
        setFieldValue('charges.cpt', claimChargeData[index].cpt);
    }

    const handleCancelEdit = (setFieldValue) => {
        setClaimChargeMode('new', null);
        setFieldValue('charges.chargeAmount', '');
        setFieldValue('charges.units', '1');
        setFieldValue('charges.revCode', '');
        setFieldValue('charges.cpt', null);
        setFieldValue('charges.modifiers', []);
        setFieldValue('charges.serviceDate', asIsoDate());
    }

    const claimChargeAction = (setFieldValue) => (_, index) => {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleEditCharge(index, setFieldValue)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
            <DeleteConfirmation buttonColor='primary' handleDelete={() => handleChargeDelete(index)} />
        </div>
    }

    function handleEstimate(values) {
        let claimChargePayload = claimChargeData.map(item => {
            let responsePayload = {
                "cpt": item.cpt,
                "cptDescription": item.cptDescription,
                "revCode": item.revCode,
                "revCodeDescription": item.revCodeDescription,
                "chargeAmount": item.totalChargeAmount,
                "units": item.units,
                "serviceDate": item.serviceDate,
            }
            for (let i = 0; i < item.modifiers.length; i++) {
                responsePayload[`mod${i + 1}`] = item.modifiers[i]
            }
            return responsePayload
        });

        let payload = {
            claimType: values.claimType,
            patientFName: values.patientFName,
            patientLName: values.patientLName,
            contractId: values.contractId?.id,
            drg: values.drg?.code,
            claimAmount: claimChargeData?.reduce((acc, curr) => acc + parseFloat(curr.totalChargeAmount), 0) || 0,
            principalDX: values.principalDX?.code ?? '',
            admitionDate: values.admitionDate,
            dischargeDate: values.dischargeDate === '' ? null : values.dischargeDate,
            estimationId: undefined,

            claimCharges: claimChargePayload,
        };
        if (estimatorMode === 'edit') {
            payload = {
                ...payload,
                estimationId,
                principalDX: values.principalDX?.code ?? values.dxInputValue
            }
            editEstimateById(payload, handleEstimateCallback)
        } else {
            estimate(payload, handleEstimateCallback);
        }
    }

    function handleEstimateCallback(payload) {
        history.push({pathname:`/estimator/history/view/${payload.estimationId}/op`,state:{editId:payload.estimationId}})
    }

    function handleClearFields(setFieldValue) {
        setClaimEstimatorCharges([]);
        setClaimChargeMode('new', null);
        setFieldValue('charges', {
            modifierField: '',
            modifiers: [],
            associatedCharges: [],

            cpt: null,
            revCode: '',
            revCodeDescription: '',
            chargeAmount: '',
            totalChargeAmount: '',
            associatedCharge: '',
            units: '1',
            serviceDate: asIsoDate(),

            cptInputValue: ''
        });
        setFieldValue('patientName', '');
        setFieldValue('claimType', '');
        setFieldValue('contractId', null);
        setFieldValue('drg', null);
        setFieldValue('claimAmount', '0');
        setFieldValue('principalDX', null);
        setFieldValue('dxInputValue', '');
        setFieldValue('admitionDate', asIsoDate());
        setFieldValue('dischargeDate', '');
    }

    function getClaimAmount() {
        return claimChargeData?.reduce((acc, curr) => acc + parseFloat(curr.totalChargeAmount), 0) || 0;
    }

    const hoverData = (result) => {
        const hoverColumns = new Map();
        const cptColumns = [
            {
                label: "",
                data: result?.cptDescription
            }
        ];
        const revCodeColumns = [
            {
                label: "",
                data: result?.revCodeDescription
            }
        ];

        hoverColumns.set('cpt', cptColumns);
        hoverColumns.set('revCode', revCodeColumns);

        return hoverColumns;
    }


    return (
        <>
            <Loadable loading={estimatePending || editEstimatePending || getEstimatePending}>
                <Helmet title="Claim Estimator" />
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validate={values => {
                        const errors: { claimType?: string; contractId?: string; admitionDate?: string } = {};

                        if (!values.claimType) {
                            errors.claimType = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (!values.contractId) {
                            errors.contractId = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (!values.admitionDate) {
                            errors.admitionDate = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        if (claimChargeData.length === 0) {
                            setRestrictFormSubmit(true);
                            return
                        }
                        handleEstimate(values)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <Portlet>
                                    <PortletHeader
                                        title={estimatorMode === 'edit' ? `Estimator (${estimationId})` : "Estimator"}
                                        showTitle={false}
                                        toolbar={
                                            <PortletHeaderToolbar>
                                                <Button onClick={() => handleClearFields(setFieldValue)} variant='contained' type='button'
                                                    color="default">
                                                    Clear
                                                </Button>
                                                <Button className='ml-3' variant='contained' type='submit'
                                                    color="primary">
                                                    Estimate
                                                </Button>
                                            </PortletHeaderToolbar>
                                        }
                                    />
                                    <PortletBody>
                                        <Grid container>
                                            <Grid xs={8}>
                                                <Grid xs={12}>
                                                    <Card>
                                                        <Card.Header as="h5">Claim Info</Card.Header>
                                                        <Card.Body>
                                                            <Grid container>
                                                                <Grid item xs={5}>
                                                                    <TextField
                                                                        label="Patient First Name"
                                                                        name="patientFName"
                                                                        className={classes.textField}
                                                                        margin="normal"
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values.patientFName}
                                                                        helperText={touched.patientFName && errors.patientFName}
                                                                        error={Boolean(touched.patientFName && errors.patientFName)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <TextField
                                                                        label="Patient Last Name"
                                                                        name="patientLName"
                                                                        className={classes.textField}
                                                                        margin="normal"
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values.patientLName}
                                                                        helperText={touched.patientLName && errors.patientLName}
                                                                        error={Boolean(touched.patientLName && errors.patientLName)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={5}>
                                                                    <FormControl className={classes.formControl}>
                                                                        <InputLabel htmlFor="role">Claim Type*</InputLabel>
                                                                        <Select
                                                                            required
                                                                            value={values.claimType}
                                                                            onChange={handleChange}
                                                                            error={Boolean(touched.claimType && errors.claimType)}
                                                                            input={<Input id="claimType" />}
                                                                            inputProps={{
                                                                                name: "claimType",
                                                                                id: "claimType"
                                                                            }}
                                                                        >
                                                                            {/* <MenuItem
                                                                                value={CONTRACTED_SERVICE_INPATIENT}
                                                                            >
                                                                                {CONTRACTED_SERVICE_INPATIENT}
                                                                            </MenuItem> */}
                                                                            <MenuItem
                                                                                value={CONTRACTED_SERVICE_OUTPATIENT}
                                                                            >
                                                                                {CONTRACTED_SERVICE_OUTPATIENT}
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                value={CONTRACTED_SERVICE_PROFESSIONAL}
                                                                            >
                                                                                {CONTRACTED_SERVICE_PROFESSIONAL}
                                                                            </MenuItem>
                                                                        </Select>
                                                                        <FormHelperText error>{touched.claimType && errors.claimType}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <Autocomplete
                                                                        className={classes.autoComplete}
                                                                        value={values.contractId}
                                                                        disableClearable={false}
                                                                        onChange={(e, value) => setFieldValue("contractId", value)}
                                                                        getOptionSelected={(option, value) => option === value}
                                                                        options={tinyContracts}
                                                                        getOptionLabel={(option) => option.name}
                                                                        renderInput={(params) =>
                                                                            <TextField {...params}
                                                                                name={`contractId`}
                                                                                label="Contract*"
                                                                                error={Boolean(touched.contractId && errors.contractId)}
                                                                                helperText={touched.contractId && errors.contractId}
                                                                                margin="normal" />
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={5}>
                                                                    <Autocomplete
                                                                        className={classes.autoComplete}
                                                                        value={values.principalDX}
                                                                        disableClearable={false}
                                                                        onChange={(e, value) => setFieldValue("principalDX", value)}
                                                                        getOptionSelected={(option, value) => option === value}
                                                                        inputValue={values.dxInputValue}
                                                                        onInputChange={(e : any, newInputValue) => {
                                                                            if (e && e.target.value && e.target.value.length > 2) {
                                                                                getDXCodes(e.target.value)
                                                                            }
                                                                            setFieldValue(`dxInputValue`, newInputValue);
                                                                        }}
                                                                        options={dxCodes}
                                                                        loading={dxCodesPending}
                                                                        getOptionLabel={(option) => option.description ? `${option?.code}-${option?.description}` : option?.code}
                                                                        renderInput={(params) =>
                                                                            <TextField {...params}
                                                                                name={`principalDX`}
                                                                                label="Principal DX"
                                                                                helperText={touched.principalDX && errors.principalDX}
                                                                                error={Boolean(touched.principalDX && errors.principalDX)}
                                                                                margin="normal"
                                                                                InputProps={{
                                                                                    ...params.InputProps,
                                                                                    endAdornment: (
                                                                                        <>
                                                                                            {dxCodesPending ? (
                                                                                                <CircularProgress color="secondary" size={20} />
                                                                                            ) : null}
                                                                                            {params.InputProps.endAdornment}
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            />
                                                                        }
                                                                    />

                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <TextField
                                                                        disabled
                                                                        type='number'
                                                                        label="Total Claim Amount"
                                                                        name="claimAmount"
                                                                        className={classes.textField}
                                                                        margin="normal"
                                                                        // onBlur={handleBlur}
                                                                        // onChange={handleChange}
                                                                        value={getClaimAmount()}
                                                                        helperText={touched.claimAmount && errors.claimAmount}
                                                                        error={Boolean(touched.claimAmount && errors.claimAmount)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={5}>
                                                                    <TextField
                                                                        type='date'
                                                                        label="Service Date*"
                                                                        name="admitionDate"
                                                                        className={classes.textField}
                                                                        margin="normal"
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values.admitionDate}
                                                                        helperText={touched.admitionDate && errors.admitionDate}
                                                                        error={Boolean(touched.admitionDate && errors.admitionDate)}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                {/* <Grid item xs={5}>
                                                                        <TextField
                                                                            type='date'
                                                                            label="Discharge Date"
                                                                            name="dischargeDate"
                                                                            className={classes.textField}
                                                                            margin="normal"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.dischargeDate}
                                                                            helperText={touched.dischargeDate && errors.dischargeDate}
                                                                            error={Boolean(touched.dischargeDate && errors.dischargeDate)}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid> */}
                                                            </Grid>
                                                        </Card.Body>
                                                    </Card>
                                                </Grid>
                                                <Grid xs={12} className='mt-4'>
                                                    <Card>
                                                        <Card.Header as="h5" className={(claimChargeData.length === 0 && isFormRestricted) && classes.redColor}>Claim Charges</Card.Header>
                                                        <Card.Body>
                                                            {(claimChargeData.length === 0 && isFormRestricted) && <p className={classes.associatedChargeError}>Please add atleast one claim charge</p>}
                                                            <Grid className={`${classes.claimCharge}`}>
                                                                <Grid container>
                                                                    <Grid item xs={5}>
                                                                        {mode === 'edit' ?
                                                                            <TextField
                                                                                disabled
                                                                                type='text'
                                                                                label="Procedure (CPT)"
                                                                                className={classes.textField}
                                                                                margin="normal"
                                                                                value={`${values.charges.cpt}`}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            /> :
                                                                            <Autocomplete
                                                                                className={classes.autoComplete}
                                                                                value={values.charges.cpt}
                                                                                disableClearable={false}
                                                                                onChange={(e, value) => handleCPTChange(setFieldValue, value)}
                                                                                getOptionSelected={(option, value) => option === value}
                                                                                inputValue={values.charges.cptInputValue}
                                                                                onInputChange={(e : any, newInputValue) => {
                                                                                    if (e.target.value && e.target.value.length > 2) {
                                                                                        getCPTS(e.target.value)
                                                                                    }
                                                                                    setFieldValue(`charges.cptInputValue`, newInputValue);
                                                                                }}
                                                                                // open={values.charges.cptInputValue.length > 1 && !values.charges.cpt}
                                                                                options={cptCodes}
                                                                                loading={getCPTCodesPending}
                                                                                getOptionLabel={(option) => `${option?.code}-${option?.description}`}
                                                                                renderInput={(params) =>
                                                                                    <TextField {...params}
                                                                                        name={`charges.cpt`}
                                                                                        label="Procedure (CPT)"
                                                                                        error={Boolean(customChargeFieldsError.cpt && !values.charges.cpt)}
                                                                                        helperText={Boolean(customChargeFieldsError.cpt && !values.charges.cpt)}
                                                                                        margin="normal"
                                                                                        InputProps={{
                                                                                            ...params.InputProps,
                                                                                            endAdornment: (
                                                                                                <>
                                                                                                    {getCPTCodesPending ? (
                                                                                                        <CircularProgress color="secondary" size={20} />
                                                                                                    ) : null}
                                                                                                    {params.InputProps.endAdornment}
                                                                                                </>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <TextField
                                                                            disabled
                                                                            type='text'
                                                                            label="Rev Code"
                                                                            name={`charges.revCode`}
                                                                            className={classes.textField}
                                                                            margin="normal"
                                                                            value={values.charges.revCode === '' ? `` : `${values.charges.revCode}-${values.charges.revCodeDescription}`}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={5}>
                                                                        <TextField
                                                                            type='number'
                                                                            label="Charge Amount"
                                                                            name={`charges.chargeAmount`}
                                                                            className={classes.textField}
                                                                            margin="normal"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.charges.chargeAmount}
                                                                            error={Boolean(customChargeFieldsError.chargeAmount && !values.charges.chargeAmount)}
                                                                            helperText={Boolean(customChargeFieldsError.chargeAmount && !values.charges.chargeAmount)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <TextField
                                                                            label="Units"
                                                                            name={`charges.units`}
                                                                            className={classes.textField}
                                                                            margin="normal"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.charges.units}
                                                                            error={Boolean(customChargeFieldsError.units && !values.charges.units)}
                                                                            helperText={Boolean(customChargeFieldsError.units && !values.charges.units)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <TextField
                                                                            type='date'
                                                                            label="Service Date"
                                                                            name={`charges.serviceDate`}
                                                                            className={classes.textField}
                                                                            margin="normal"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.charges.serviceDate}
                                                                            error={Boolean(customChargeFieldsError.serviceDate && !values.charges.serviceDate)}
                                                                            helperText={Boolean(customChargeFieldsError.serviceDate && !values.charges.serviceDate)}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            label="Modifiers"
                                                                            name={`charges.modifierField`}
                                                                            className={classes.textField}
                                                                            margin="normal"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.charges.modifierField}
                                                                            disabled={values.charges.modifiers.length === 4}
                                                                        // helperText={touched.name && errors.name}
                                                                        // error={Boolean(touched.name && errors.name)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Tooltip title="Add Modifier">
                                                                            <IconButton
                                                                                disabled={values.charges.modifiers.length === 4 || values.charges.modifierField?.length === 0}
                                                                                onClick={() => handleAddModifier(setFieldValue, values)}
                                                                                className={classes.addModifierButton}
                                                                            >
                                                                                {/* <SaveIcon /> */}
                                                                                <AddCircleIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <Grid container>
                                                                            {values.charges.modifiers.map((modifier, mIndex) =>
                                                                                <Chip variant="outlined"
                                                                                    size="medium"
                                                                                    key={`${modifier}-${mIndex}`}
                                                                                    color="primary"
                                                                                    label={modifier}
                                                                                    onDelete={() => handleModifierDelete(setFieldValue, values.charges.modifiers, mIndex)}
                                                                                    className={classes.chip}
                                                                                />)
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid xs={10}>
                                                                        <Grid className={classes.buttonGrid}>
                                                                            {mode === 'edit' &&
                                                                                <Button
                                                                                    onClick={() => handleCancelEdit(setFieldValue)}
                                                                                    color="default"
                                                                                    className='mr-2'
                                                                                >
                                                                                    Cancel
                                                                                </Button>}
                                                                            <Button variant='contained'
                                                                                onClick={() => handleAddToGrid(values, setFieldValue)}
                                                                                color="default"
                                                                            >
                                                                                {mode === 'new' ?
                                                                                    'Add Claim Charge' : 'save'}
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            {claimChargeData.length > 0 &&
                                                                <Grid className='pt-4'>
                                                                    <DataTable
                                                                        size="small"
                                                                        fields={fields}
                                                                        data={claimChargeData}
                                                                        order={order}
                                                                        onOrder={setOrder}
                                                                        sort={sort}
                                                                        onSort={setSort}
                                                                        loading={false}
                                                                        name="Claim Charge"
                                                                        hoverData={hoverData}

                                                                        renderActionColumn={claimChargeAction(setFieldValue)}
                                                                        hasActions={true}
                                                                    />
                                                                </Grid>
                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid xs={4}>
                                                <Grid className='pl-4'>
                                                    <Loadable loading={getAssociatedChargesPending}>
                                                        {associatedCharges.length > 0 && values.charges.cpt !== null && mode !== 'edit' &&
                                                            <Card className='h-100'>
                                                                <Card.Header className={Boolean(customChargeFieldsError.associatedCharges && values.charges.associatedCharges.length === 0) && classes.redColor} as="h5">Associated Charges</Card.Header>
                                                                <Card.Body>
                                                                    <Grid className={classes.associatedChargeGrid}>
                                                                        {Boolean(customChargeFieldsError.associatedCharges && values.charges.associatedCharges.length === 0) && <p className={classes.associatedChargeError}>Please select at least one charge</p>}

                                                                        {associatedCharges.map((option) => {
                                                                            return (
                                                                                <ListItem
                                                                                    key={option.code}
                                                                                    role={undefined}
                                                                                    dense
                                                                                    button
                                                                                    divider
                                                                                    onClick={() => handleAssociatedChargeChange(setFieldValue, option, values)}
                                                                                >
                                                                                    <ListItemIcon>
                                                                                        <Checkbox
                                                                                            edge="start"
                                                                                            disabled={mode === 'edit'}
                                                                                            checked={values.charges.associatedCharges.findIndex(ac => ac.id === option.id) !== -1}
                                                                                            tabIndex={-1}
                                                                                            disableRipple
                                                                                            inputProps={{ 'aria-labelledby': option.code }}
                                                                                        />
                                                                                    </ListItemIcon>
                                                                                    <Grid container>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" color="textSecondary">
                                                                                                {option.associatedChargeDescription}
                                                                                            </Typography>
                                                                                            <Typography variant="body1" color="textSecondary">
                                                                                                Charge Amount: {option.associatedCharges}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4} className='text-right'>
                                                                                            <Typography variant="body1" color="textSecondary">
                                                                                                CPT: {option.associatedCode}
                                                                                            </Typography>
                                                                                            <Typography variant="body1" color="textSecondary">
                                                                                                Revenue Code: {option.associatedRevCode}
                                                                                            </Typography>
                                                                                            <Typography variant="body1" color="textSecondary">
                                                                                                Percentage: {option?.percentage}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </ListItem>
                                                                            );
                                                                        })}
                                                                    </Grid>

                                                                </Card.Body>
                                                            </Card>
                                                        }
                                                    </Loadable>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </PortletBody>
                                </Portlet>
                            </form>
                        </>
                    )}
                </Formik>

            </Loadable>
        </>
    )
}

export default ClaimEstimator


