import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getDrgList = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/drg/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getDRG = (drgId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/drg/${drgId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createDRG = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/drg`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const editDRG = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/drg`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteDRG = (drgId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/drg/${drgId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getDrgListTiny = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/drg/list/tiny`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getIPPSListTinyByContract = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/ipps/list/tiny/contract/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getIPPSListByTargetClient = (targetClient) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/ipps/list/tiny/client/${targetClient}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getIPPSDependencies = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/ipps/dependencies/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getDrgList,
    createDRG,
    editDRG,
    deleteDRG,
    getDRG,
    getDrgListTiny,
    getIPPSListTinyByContract,
    getIPPSListByTargetClient,
    getIPPSDependencies
};