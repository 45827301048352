import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import ForcedResetPasswordComponent from './ForcedResetPassword';
import { forcedResetPassword } from '../../../store/actions/session';
import { selectForcedResetPasswordPending } from '../../../store/selectors/session';

export const ForcedResetPasswordPage = connect(
    () => {
        return (state, props) => ({
            changePasswordPending: selectForcedResetPasswordPending(state),

            sessionConstructPending: state.session.construct.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            sessionChangePassword: forcedResetPassword
        }, dispatch)
    })
)(injectIntl(ForcedResetPasswordComponent));