import { createReducer } from '../../util';
import * as actions from '../actions/brand';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: null,
    deploymentStatus:null,
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    getDeploymentStatus:{
        ...initialStatusState,

    }
};

export default createReducer(initialState, {
    [actions.BRAND_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.BRAND_GET_FULFILLED]: (state, brand) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        data: brand,
    }),
    [actions.BRAND_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    // Branding status 

    [actions.BRAND_GET_DEPLOYMENT_STATUS_PENDING]: (state) => ({
        ...state,
        getDeploymentStatus: {
            pending:true,
        },
    }),
    [actions.BRAND_GET_DEPLOYMENT_STATUS_FULFILLED]: (state, deploymentStatus) => ({
        ...state,
        getDeploymentStatus: {
            ...initialStatusState,
        },
        deploymentStatus,
    }),
    [actions.BRAND_GET_DEPLOYMENT_STATUS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDeploymentStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    // Branding status 



    [actions.BRAND_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.BRAND_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.BRAND_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.BRAND_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.BRAND_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.BRAND_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    //[actions.ROLES_RESET]: () => ({...initialState}),
});
