import { createReducer } from '../../util';
import * as actions from '../actions/executions';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
    errorDetails: '',
    innerException: ''
};

const initialState = {
    count: null,
    executionId: null,
    stop: {
        ...initialStatusState,
    },
    getExecuteCount: {
        ...initialStatusState,
    },
    claimExecute: {
        ...initialStatusState,
    },
    downloadReportById: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {
    [actions.STOP_EXECUTION_PENDING]: (state) => ({
        ...state,
        stop: {
            pending: true,
        },
    }),
    [actions.STOP_EXECUTION_FULFILLED]: (state, allowablesList) => ({
        ...state,
        stop: {
            ...initialStatusState,
        },
    }),
    [actions.STOP_EXECUTION_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        stop: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),
    [actions.CLAIM_ANALYSIS_EXECUTE_COUNT_PENDING]: (state) => ({
        ...state,
        getExecuteCount: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_EXECUTE_COUNT_FULFILLED]: (state, { count }) => ({
        ...state,
        getExecuteCount: {
            ...initialStatusState,
        },
        count,
    }),
    [actions.CLAIM_ANALYSIS_EXECUTE_COUNT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getExecuteCount: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_EXECUTE_PENDING]: (state) => ({
        ...state,
        claimExecute: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_EXECUTE_FULFILLED]: (state, { id }) => ({
        ...state,
        claimExecute: {
            ...initialStatusState,
        },
        executionId: id,
    }),
    [actions.CLAIM_ANALYSIS_EXECUTE_REJECTED]: (state, errorMessage) => ({
        ...state,
        claimExecute: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.EXECUTION_DOWNLOAD_REPORT_BY_ID_PENDING]: (state) => ({
        ...state,
        downloadReportById: {
            pending: true,
        },
    }),
    [actions.EXECUTION_DOWNLOAD_REPORT_BY_ID_FULFILLED]: (state) => ({
        ...state,
        downloadReportById: {
            ...initialStatusState,
        },
    }),
    [actions.EXECUTION_DOWNLOAD_REPORT_BY_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        downloadReportById: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.EXECUTION_ERROR_RESET]: (state, allowablesList) => ({
        ...state,
        stop: {
            ...initialStatusState,
        },
    }),
    [actions.EXECUTION_RESET]: () => ({ ...initialState }),
});