import { createSelector } from "reselect";

export const selectLookupData = (state) => state.pricers.data;
export const selectCharges = (state) => state.pricers.charges;
export const selectAllowables = (state) => state.pricers.allowables;

export const selectLookupV2Data = (state) => state.pricers.dataV2;
export const selectFacilities = (state) => state.pricers.facilities;
export const selectDepartmentList = (state) => state.pricers.departments;
export const selectNegotiatedRates = (state) => state.pricers.negotiatedRates;
export const selectOop = (state) => state.pricers.oop;

export const selectSignOff = (state) => state.pricers.signOff;

export const selectDepartments = createSelector(
    selectDepartmentList,
    (departments) => {
        return departments.map(({name}) => name);
    }
)

export const selectMinMaxFromNegotiatedRate = createSelector(
    selectNegotiatedRates,
    (negotiatedRates) => {
        let min = 0, max= 0;
        if(negotiatedRates && negotiatedRates.length > 0) {
            min = Math.min(...negotiatedRates.map(item => item.rate));
            max = Math.max(...negotiatedRates.map(item => item.rate));
        }
        
        return {
            min,
            max,
        }
    }
)