export const SEARCH_CRITERIAS_LIST = 'SEARCH_CRITERIAS_LIST';
export const SEARCH_CRITERIAS_LIST_PENDING = 'SEARCH_CRITERIAS_LIST_PENDING';
export const SEARCH_CRITERIAS_LIST_FULFILLED = 'SEARCH_CRITERIAS_LIST_FULFILLED';
export const SEARCH_CRITERIAS_LIST_REJECTED = 'SEARCH_CRITERIAS_LIST_REJECTED';

export const SEARCH_CRITERIAS_GET_BY_NAME = 'SEARCH_CRITERIAS_GET_BY_NAME';
export const SEARCH_CRITERIAS_GET_BY_NAME_PENDING = 'SEARCH_CRITERIAS_GET_BY_NAME_PENDING';
export const SEARCH_CRITERIAS_GET_BY_NAME_FULFILLED = 'SEARCH_CRITERIAS_GET_BY_NAME_FULFILLED';
export const SEARCH_CRITERIAS_GET_BY_NAME_REJECTED = 'SEARCH_CRITERIAS_GET_BY_NAME_REJECTED';

export const SEARCH_CRITERIAS_CREATE = 'SEARCH_CRITERIAS_CREATE';
export const SEARCH_CRITERIAS_CREATE_PENDING = 'SEARCH_CRITERIAS_CREATE_PENDING';
export const SEARCH_CRITERIAS_CREATE_FULFILLED = 'SEARCH_CRITERIAS_CREATE_FULFILLED';
export const SEARCH_CRITERIAS_CREATE_REJECTED = 'SEARCH_CRITERIAS_CREATE_REJECTED';

export const SEARCH_CRITERIAS_UPDATE = 'SEARCH_CRITERIAS_UPDATE';
export const SEARCH_CRITERIAS_UPDATE_PENDING = 'SEARCH_CRITERIAS_UPDATE_PENDING';
export const SEARCH_CRITERIAS_UPDATE_FULFILLED = 'SEARCH_CRITERIAS_UPDATE_FULFILLED';
export const SEARCH_CRITERIAS_UPDATE_REJECTED = 'SEARCH_CRITERIAS_UPDATE_REJECTED';

export const SEARCH_CRITERIAS_DELETE = 'SEARCH_CRITERIAS_DELETE';
export const SEARCH_CRITERIAS_DELETE_PENDING = 'SEARCH_CRITERIAS_DELETE_PENDING';
export const SEARCH_CRITERIAS_DELETE_FULFILLED = 'SEARCH_CRITERIAS_DELETE_FULFILLED';
export const SEARCH_CRITERIAS_DELETE_REJECTED = 'SEARCH_CRITERIAS_DELETE_REJECTED';

export const SEARCH_CRITERIA_TYPES = 'SEARCH_CRITERIA_TYPES';
export const SEARCH_CRITERIA_TYPES_PENDING = 'SEARCH_CRITERIA_TYPES_PENDING';
export const SEARCH_CRITERIA_TYPES_FULFILLED = 'SEARCH_CRITERIA_TYPES_FULFILLED';
export const SEARCH_CRITERIA_TYPES_REJECTED = 'SEARCH_CRITERIA_TYPES_REJECTED';

export const SEARCH_CRITERIAS_RESET_DELETE_STATE = 'SEARCH_CRITERIAS_RESET_DELETE_STATE';

export const SEARCH_CRITERIAS_RESET = 'SEARCH_CRITERIAS_RESET';

export const list = () => ({
    type: SEARCH_CRITERIAS_LIST,
});

export const getByName = (pageName, callback) => ({
    type: SEARCH_CRITERIAS_GET_BY_NAME,
    payload: { pageName, callback },
});

export const create = (searchCriteria, callback) => ({
    type: SEARCH_CRITERIAS_CREATE,
    payload: { searchCriteria, callback },
});

export const update = (searchCriteria, callback) => ({
    type: SEARCH_CRITERIAS_UPDATE,
    payload: { searchCriteria, callback },
});

export const deletesearchCriteria = (id, callback) => ({
    type: SEARCH_CRITERIAS_DELETE,
    payload: { id, callback },
});

export const resetCriteriaDeleteState = () => ({
    type: SEARCH_CRITERIAS_RESET_DELETE_STATE,
});

export const reset = () => ({
    type: SEARCH_CRITERIAS_RESET,
});

export const getSearchCriteriaTypes = () => ({
    type: SEARCH_CRITERIA_TYPES,
});