import { headersAuthReceiveJson, headersAuthSendReceiveJson } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';
import {
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    SERVER_URL
} from '../constants/api';

const createContractFamily = (contractFamily) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/family`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(contractFamily)
    }).then(apiHandleResponse);
}

const getContractsFamilylist = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/family/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};


const getContractFamily = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/family/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const setPrecedence = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/family/precedences`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleResponse);
};
const activateContractFamily = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/family/activate/${id}`, {
        method: METHOD_POST,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};
const deactivateContractFamily = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/family/deactivate/${id}`, {
        method: METHOD_POST,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};
const updateContractFamily = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/family`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteContractFamily = (familyId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/family/${familyId}`, {
        method: METHOD_DELETE,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    activateContractFamily, createContractFamily, deactivateContractFamily, deleteContractFamily, getContractFamily, getContractsFamilylist, setPrecedence, updateContractFamily
};
