export const BRAND_GET = 'BRAND_GET';
export const BRAND_GET_PENDING = 'BRAND_GET_PENDING';
export const BRAND_GET_FULFILLED = 'BRAND_GET_FULFILLED';
export const BRAND_GET_REJECTED = 'BRAND_GET_REJECTED';

export const BRAND_CREATE = 'BRAND_CREATE';
export const BRAND_CREATE_PENDING = 'BRAND_CREATE_PENDING';
export const BRAND_CREATE_FULFILLED = 'BRAND_CREATE_FULFILLED';
export const BRAND_CREATE_REJECTED = 'BRAND_CREATE_REJECTED';

export const BRAND_UPDATE = 'BRAND_UPDATE';
export const BRAND_UPDATE_PENDING = 'BRAND_UPDATE_PENDING';
export const BRAND_UPDATE_FULFILLED = 'BRAND_UPDATE_FULFILLED';
export const BRAND_UPDATE_REJECTED = 'BRAND_UPDATE_REJECTED';

export const BRAND_GET_DEPLOYMENT_STATUS = 'BRAND_GET_DEPLOYMENT_STATUS';
export const BRAND_GET_DEPLOYMENT_STATUS_PENDING = 'BRAND_GET_DEPLOYMENT_STATUS_PENDING';
export const BRAND_GET_DEPLOYMENT_STATUS_FULFILLED = 'BRAND_GET_DEPLOYMENT_STATUS_FULFILLED';
export const BRAND_GET_DEPLOYMENT_STATUS_REJECTED = 'BRAND_GET_DEPLOYMENT_STATUS_REJECTED';

export const BRAND_ERROR_RESET = 'BRAND_ERROR_RESET';
export const BRAND_RESET = 'BRAND_RESET';

export const get = (callback) => ({
    type: BRAND_GET,
    payload: { callback },
});

export const create = (brand, callback) => ({
    type: BRAND_CREATE,
    payload: { brand, callback },
});

export const update = (id, brand, callback) => ({
    type: BRAND_UPDATE,
    payload: { id, brand, callback },
});

export const errorReset = (...errorKeys) => ({
    type: BRAND_ERROR_RESET,
    payload: { errorKeys },
});

export const getDeploymentStatus = (callback) => ({
    type: BRAND_GET_DEPLOYMENT_STATUS,
    payload: { callback },
});