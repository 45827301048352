import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import PricerSetupComponent from './PricerSetup';

export const PricerSetup = connect(

    () => {

        return (state, props) => ({
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(PricerSetupComponent));