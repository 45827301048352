import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Loadable } from '../../../../common';
import { packExtension } from '../../../../util/phone';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import {
  Button, makeStyles, TextField
} from "@material-ui/core";
import { CheckboxTree } from "../../../../common";
import { arrayToTree, getParent } from '../../../../util/tree';
import { BackButton } from "../../../../common/BackButton";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  switch: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  badge: {
    top: '50%',
    right: '-28px'
  }
}));

export default function RolesEditPage(props) {

  const {
    intl, mode, rolesCreatePending, rolesUpdatePending, roleGetPending, rolePermissions,
    initialValues, role,
    actions: {
      roleCreate, roleUpdate, rolesPermission, rolesPermissionByRoleId,
    }
  } = props;

  const isEdit = mode === 'edit';
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);

  const handleCallback = () => {
    props.history.push('/admin/roles');
  }

  const buildPermissionTree = () => {
    const tree = arrayToTree(rolePermissions || []);
    const mainNodes = getParent(tree);
    return { tree, mainNodes };
  }

  const handleRoleCallback = () => {
    if (mode === 'edit' && role) {
      rolesPermissionByRoleId(role?.id, handlePermissionCallback);
    }
  }

  const handlePermissionCallback = (allocatedPermissions) => {
    // console.log({allocatedPermissions});
    if (allocatedPermissions && allocatedPermissions.length > 0) {
      const serverSelected = allocatedPermissions.filter(x => x.active).map(x => x.id.toString());
      // const { tree } = buildPermissionTree();
      const uniqueNode = [...new Set(allocatedPermissions.map(item => item.resource))];
      const parentNodes = [];
      if (uniqueNode && uniqueNode.length > 0) {
        uniqueNode.forEach(node => {
          const active = allocatedPermissions.filter(x => x.active && x.resource === node).length;
          const all = allocatedPermissions.filter(x => x.resource === node).length;
          if (active > 0 && active === all) {
            parentNodes.push(node);
          }
        });
      }
      // console.log({ parentNodes });
      const parentIds = formatParentNodes(parentNodes)

      setSelected([...serverSelected, ...parentIds]);
    }
  }

  function formatParentNodes(nodes) {
    let temp = []
    for (let node of nodes) {
      temp.push(node.split('.'));
    }
    return [...new Set(temp.flat(1))];
  }

  useEffect(() => {
    rolesPermission(handleRoleCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveChanges = (values) => {

    let payload = {
      name: values.name,
      description: values.description,
      active: true,
      permissionIds: selected.filter(item => {
        if (parseInt(item)) {
          return parseInt(item)
        }
      })
    }

    // create
    if (mode === 'create') {
      roleCreate(payload, handleCallback);
    }
    // update
    else {
      payload = {
        ...payload,
        id: values.id,
      }
      roleUpdate(payload, handleCallback);
    }
  }

  return (
    <Loadable loading={rolesCreatePending || rolesUpdatePending || roleGetPending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          return errors;
        }}
        onSubmit={(values) => {
          handleSaveChanges(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={!isEdit ? "Add new Role" : "Edit Role"}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/admin/roles" />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                      disabled={rolesCreatePending || rolesUpdatePending}
                    >
                      {(rolesCreatePending || rolesUpdatePending) ? 'Saving Changes' : 'Save Changes'}
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className="main-content">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            required
                            label="Role Name"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            disabled={isEdit}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Description"
                            name="description"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <CheckboxTree
                        permissionTree={buildPermissionTree()}
                        mode={mode}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    </div>
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </form>
        )}
      </Formik>
    </Loadable>
  );
}