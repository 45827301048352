import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { Route } from '../../../util';

import { LoginPage } from "../LoginPage";
import "../../../../_metronic/_assets/sass/pages/login/login-3.scss";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic";
import { RootState } from "../../../store/reducers";

const AuthPage = ({ constuctFromLogin, constructPending, clientProductsPending }) =>
    <>
        {Boolean(!constuctFromLogin && (constructPending || clientProductsPending)) ? <>
            <div className="d-flex align-items-center justify-content-center h-100">
                <img src={toAbsoluteUrl("/media/logos/logo-mini-md.v3.png?v=4")} className="logo-rotate" alt="SlicedHealth" />
            </div >
        </> :
            <div className="kt-grid kt-grid--ver kt-grid--root">
                <div
                    id="kt_login"
                    className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--signin"
                >
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
                            <Switch>
                                <Redirect from="/auth" exact={true} to="/auth/login" />
                                <Route path="/auth/login" component={LoginPage} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>

export default connect(
    ({ session, clients }: RootState) => ({
        constuctFromLogin: session.constructFromLogin,
        constructPending: session.construct.pending,
        clientProductsPending: clients.getProducts.pending
    }),
)(AuthPage);
