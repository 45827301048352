import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'
import Alert from '@material-ui/lab/Alert';
import { ProcessedClaimsDialog } from './ProcessedClaimsDialog';

export default function ProcessClaimDialog(props) {

    const [processClaimCallbackDialog, setProcessClaimCallbackDialog] = useState(false);

    const { open, handleClose, claimPageRecord, selectedItems, claimsData, filter, claimprocessError, significanceFlag,
        claimProcessPending, claimBulkProcessPending, handleProcessClaimCallback, claimBulkOnDemandPending,
        actions: { claimAnalysisProcess, claimAnalysisBulkProcess, setSelected, processBulkOnDemand, setProcessedDialog } } = props;

    const handleProcessClaimCallbackDialogClose = () => {
        setProcessClaimCallbackDialog(false);
    }

    const handleProcessClaimSuccessCallback = () => {
        setSelected([]);
        setProcessClaimCallbackDialog(true);
        handleProcessClaimCallback();

    }

    const handleBulkOndemandCallback = () => {
        setSelected([]);
        handleProcessClaimCallback();
        setProcessedDialog(true);
    }

    const handleErrorCallback = () => {
        handleClose();
    }

    const handleProcessClaimConfirmation = () => {
        
        if (selectedItems.length > 0) {
            if (claimsData.length === selectedItems.length && selectedItems.length > 5) {
               
                let operandsWithoutBoth = filter.operands.filter(obj => 
                    !obj.filters.some(filter => filter.value === "both")
                );
                
                operandsWithoutBoth={operator:filter.operator,operands:operandsWithoutBoth}
                const payload = {
                    filter:operandsWithoutBoth,
                }
                
                claimAnalysisBulkProcess(payload, significanceFlag, handleProcessClaimSuccessCallback, handleErrorCallback);

            } else {
                const claims = claimsData.filter(claim => selectedItems.some(y => y == claim.id));
                const payload = {
                    claimMetadata: claims.map((claim) => {
                        return {
                            claimNumber: claim.claimnumber,
                            contractId: claim.contractid,
                        }
                    }),
                };
                
                if (claims.length <= 5) {
                    processBulkOnDemand(payload, handleBulkOndemandCallback, handleErrorCallback)
                } else {
                    claimAnalysisProcess(payload, handleProcessClaimSuccessCallback, handleErrorCallback);
                }
            }
        }
    }

    return (
        <div>
            <Dialog open={open}>
                <DialogTitle>
                    Process Claims
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedItems?.length > 0 ?
                            `Are you sure you want to process ${claimsData.length === selectedItems.length ? `all (${claimPageRecord?.totalRecords})` : selectedItems.length} claims?` :
                            'No claims selected, please select atleast one claim to process'
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        {selectedItems?.length > 0 ? 'Cancel' : 'Close'}
                    </Button>
                    {selectedItems?.length > 0 && (
                        <Button
                            onClick={handleProcessClaimConfirmation}
                            disabled={claimProcessPending || claimBulkProcessPending || claimBulkOnDemandPending}
                            color="primary">
                            {(claimProcessPending || claimBulkProcessPending || claimBulkOnDemandPending) ? 'Processing...' : 'Process Claims'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={processClaimCallbackDialog} onClose={handleProcessClaimCallbackDialogClose}>
                <DialogTitle>
                    Process Claims
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="success">The request is submitted and progress can be monitored on execution logs page.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProcessClaimCallbackDialogClose} color="default">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <ProcessedClaimsDialog />
        </div>
    )
}

