import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import UploadDialogComponent from './UploadDialog';
import { uploadFiles } from '../../../store/actions/uploads';

export const UploadDialog = connect(

    () => {
        const selectInitialValues = createSelector(
            () => {
                return {
                    files: [],
                    fileType: '',
                    environment: ''
                }
            }
        )
        return (state, props) => ({
            initialValues: selectInitialValues(state),
            uploadFilePending: state.uploads.uploadFiles.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            uploadFiles: uploadFiles
        }, dispatch)
    })
)(injectIntl(UploadDialogComponent));