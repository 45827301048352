import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectClientList = (state : RootState ) => state.clients.data;

export const selectClientById = (state : RootState ) => state.clients.clientById;

export const selectClientListTiny = ( state : RootState ) => state.clients.tinyList;

export const selectSelectedClient = ( state : RootState ) => state.dashboards.selectedClient;

export const selectParserList = ( state : RootState ) => state.clients.parserList;

export const selectBucketValidity = ( state : RootState ) => state.clients.bucketValidity;

export const selectFtpUserValidity = ( state : RootState ) => state.clients.ftpUserValidity;

export const selectClientConfiguration = ( state : RootState ) => state.clients.clientConfiguration;
export const selectClientAccountConfiguration = ( state : RootState ) => state.clients.clientAccountConfiguration;


export const selectClientProducts = ( state : RootState ) => state.clients.products;

export const selectClientProductsForUserEdit = ( state : RootState ) => state.clients.productsForUserEdit;

export const selectClients = createSelector(
    selectClientList,
    (clients) => clients,
);

export const createClientSelector = (selectClientId : ( state: any, props: any ) => string ) => 
    createSelector(
        selectClientId,
        selectClientById,
        (clientId, client ) => {
            if (client && parseInt(client.clientId) === parseInt(clientId)) {
                return client
            }
            return null
        }
    );

export const selectClientListTinyWithoutSystem = createSelector(
    selectClientListTiny,
    (clients) => {
        return clients.filter(x => x.name !== 'SYSTEM');
    }
);

export const selectSelectedClientName = createSelector(
    selectSelectedClient,
    selectClientListTiny,
    (selectedClient, clientListTiny) => {
        if (selectedClient && clientListTiny.length > 0) {
            const clientObj = clientListTiny.find((client) => client.clientId === parseInt(selectedClient));
            if (clientObj) {
                return clientObj.name;
            }
        }
        return '';
    }
);

export const selectSelectedClientSchema = createSelector(
    selectSelectedClient,
    selectClientListTiny,
    (selectedClient, clientListTiny) => {
        if (selectedClient && clientListTiny.length > 0) {
            const clientObj = clientListTiny.find((client) => client.clientId === parseInt(selectedClient));
            if (clientObj) {
                return clientObj.schema;
            }
        }
        return '';
    }
);