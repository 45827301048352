import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/contractFamily';
import * as API from '../api/contractsFamily';
import { sessionErrorHandling } from './session';

function* create(action) {
    try {
        
        yield put({ type: actions.CONTRACTS_FAMILY_CREATE_PENDING });
        const {
            contractFamily,
            callback,
        } = action.payload;

        const newContract = yield call(API.createContractFamily, contractFamily);
        yield put({ type: actions.CONTRACTS_FAMILY_CREATE_FULFILLED, newContract });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_FAMILY_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.CONTRACTS_FAMILY_GET_PENDING });
        const { familyId } = action.payload;
        const payload = yield call(API.getContractFamily, familyId);
        yield put({ type: actions.CONTRACTS_FAMILY_GET_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_FAMILY_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* changePrecedence(action) {
    try {
        yield put({ type: actions.CONTRACT_CHANGE_PRECEDENCE_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.setPrecedence, options);
        yield put({ type: actions.CONTRACT_CHANGE_PRECEDENCE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACT_CHANGE_PRECEDENCE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* activateContractFamily(action) {
    try {
        yield put({ type: actions.ACTIVATE_CONTRACT_FAMILY_PENDING });
        const { id,callback } = action.payload;
        const payload = yield call(API.activateContractFamily, id);
        yield put({ type: actions.ACTIVATE_CONTRACT_FAMILY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ACTIVATE_CONTRACT_FAMILY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deactivateContractFamily(action) {
    try {
        yield put({ type: actions.DEACTIVATE_CONTRACT_FAMILY_PENDING });
        const { id,callback } = action.payload;
        const payload = yield call(API.deactivateContractFamily, id);
        yield put({ type: actions.DEACTIVATE_CONTRACT_FAMILY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DEACTIVATE_CONTRACT_FAMILY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.CONTRACT_FAMILY_UPDATE_PENDING });
        const {
            data,
            callback
        } = action.payload;

        yield call(API.updateContractFamily, data);
        yield put({ type: actions.CONTRACT_FAMILY_UPDATE_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACT_FAMILY_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteContractFamily(action) {
    try {
        yield put({ type: actions.DELETE_CONTRACT_FAMILY_PENDING });
        const { familyId, callback } = action.payload;
        const payload = yield call(API.deleteContractFamily, familyId);
        yield put({ type: actions.DELETE_CONTRACT_FAMILY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DELETE_CONTRACT_FAMILY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.CONTRACTS_FAMILY_CREATE, create);
    yield takeEvery(actions.CONTRACTS_FAMILY_GET, get);
    yield takeEvery(actions.CONTRACT_CHANGE_PRECEDENCE, changePrecedence);
    yield takeEvery(actions.ACTIVATE_CONTRACT_FAMILY, activateContractFamily);
    yield takeEvery(actions.DEACTIVATE_CONTRACT_FAMILY, deactivateContractFamily);
    yield takeEvery(actions.CONTRACT_FAMILY_UPDATE, update);
    yield takeEvery(actions.DELETE_CONTRACT_FAMILY, deleteContractFamily);



   
}