import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set } from '../../../../store/actions/pages';
import { createStringDataField, createDateField, createNumberDataField, createTypeMetadataDataField } from '../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import DocumentViewComponent from './DocumentView';
import { getDocumentList, getDocument } from '../../../../store/actions/contracts';
import { MODULE_TYPE_CONTRACT } from '../../../../store/constants/contract';
import { getVendorDocument, getVendorDocumentList } from '../../../../store/actions/vendors';

const DEFAULT_SORT = [{ id: 'documentName', orderBy: 'desc' }];


const selectFields = createSelector(
    () => [
        createStringDataField('documentName', 'Name'),
        createDateField('uploadDate', 'Upload Date'),
        createStringDataField('description', 'Description'),
        createNumberDataField('version', 'Version'),
        createTypeMetadataDataField('metadata', 'Type'),
    ]
);

export const selectDocuemntsList = (state, props) => props.module === MODULE_TYPE_CONTRACT ? state.contracts.documents : state.vendors.documents

export const selectDocuments = createSelector(
    selectDocuemntsList,
    (documents) => documents,
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['documents-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['documents-landing'].filter,
    selectSort,
    selectDocuments,
);

export const DocumentView = connect(
    () => {
        return (state, props) => ({
            contractListPending: state.contracts.documentList.pending,
            vendorListPending: state.vendors.documentList.pending,
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            order: state.pages['documents-landing'].order,
            filter: state.pages['documents-landing'].filter,
            sort: selectSort(state),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setFilter: (value) => set('documents-landing', 'filter', value),
            setOrder: (value) => set('documents-landing', 'order', value),
            setSort: (value) => set('documents-landing', 'sort', value),
            getDocumentList: props.module === MODULE_TYPE_CONTRACT ? getDocumentList : getVendorDocumentList,
            getDocument: props.module === MODULE_TYPE_CONTRACT ? getDocument : getVendorDocument
        }, dispatch)
    })
)(injectIntl(DocumentViewComponent));