import React, { useEffect } from 'react';
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { Button, TextField, makeStyles } from "@material-ui/core";
import { PASSWORD_REGEX } from '../../../store/constants/pages';

import { Loadable, Container } from '../../../common';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
}));

export default function ResetPasswordComponent(props) {
    const { intl, resetPasswordPending, resetPasswordToken, actions: { resetPassword, verifyToken } } = props;
    const classes = useStyles();

    useEffect(() => {
        verifyToken(props.tokenToverify, handleCallback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCallback = () => {
        props.history.push('/auth/reset-password-confirmation');
    }
    return (
        <Container>
            <Loadable loading={resetPasswordPending}>
                {resetPasswordToken && (
                    <div className="kt-login__signin">
                        <div className="kt-login__head">
                            <div className="kt-login__title">
                                <h3>
                                    {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                                    <FormattedMessage id="AUTH.INPUT.RESET_PASSWORD" />
                                </h3>
                            </div>
                        </div>
                        <Formik
                            initialValues={{
                                newPassword: "",
                                confirmPassword: "",
                            }}
                            validate={values => {
                                const errors = {};
                                if (!values.newPassword) {
                                    errors.newPassword = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                } else if (!values.newPassword.match(PASSWORD_REGEX)) {
                                    errors.newPassword = intl.formatMessage({
                                        id: "AUTH.VALIDATION.INVALID_PASSWORD"
                                    });
                                }

                                if (!values.confirmPassword) {
                                    errors.confirmPassword = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                } else if (values.newPassword !== values.confirmPassword) {
                                    errors.confirmPassword =
                                        "Password and Confirm Password didn't match.";
                                }
                                return errors;
                            }}
                            onSubmit={(values) => {
                                resetPassword(values.newPassword, resetPasswordToken, handleCallback);
                            }}
                        >
                            {({
                                values,
                                status,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                    <form
                                        noValidate={true}
                                        autoComplete="off"
                                        className="kt-form"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="input-group">
                                            <TextField
                                                required
                                                type="password"
                                                label="New Password"
                                                name="newPassword"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.newPassword}
                                                helperText={touched.newPassword && errors.newPassword}
                                                error={Boolean(touched.newPassword && errors.newPassword)}
                                            />
                                            <TextField
                                                required
                                                label="Confirm Password"
                                                name="confirmPassword"
                                                type="password"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.confirmPassword}
                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                            />
                                        </div>
                                        <div className="kt-login__actions">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={resetPasswordPending}
                                                size="large"
                                            >
                                                {resetPasswordPending ? 'Loading...' : 'Set Password'}
                                            </Button>
                                        </div>
                                    </form>
                                )}
                        </Formik>
                    </div>
                )}
            </Loadable>
        </Container>
    );
}