export const DASHBOARD_TYPES_GET = 'DASHBOARD_TYPES_GET';
export const DASHBOARD_TYPES_GET_PENDING = 'DASHBOARD_TYPES_GET_PENDING';
export const DASHBOARD_TYPES_GET_FULFILLED = 'DASHBOARD_TYPES_GET_FULFILLED';
export const DASHBOARD_TYPES_GET_REJECTED = 'DASHBOARD_TYPES_GET_REJECTED';

export const DASHBOARD_TYPES_CREATE = 'DASHBOARD_TYPES_CREATE';
export const DASHBOARD_TYPES_CREATE_PENDING = 'DASHBOARD_TYPES_CREATE_PENDING';
export const DASHBOARD_TYPES_CREATE_FULFILLED = 'DASHBOARD_TYPES_CREATE_FULFILLED';
export const DASHBOARD_TYPES_CREATE_REJECTED = 'DASHBOARD_TYPES_CREATE_REJECTED';

export const DASHBOARD_TYPES_UPDATE = 'DASHBOARD_TYPES_UPDATE';
export const DASHBOARD_TYPES_UPDATE_PENDING = 'DASHBOARD_TYPES_UPDATE_PENDING';
export const DASHBOARD_TYPES_UPDATE_FULFILLED = 'DASHBOARD_TYPES_UPDATE_FULFILLED';
export const DASHBOARD_TYPES_UPDATE_REJECTED = 'DASHBOARD_TYPES_UPDATE_REJECTED';

export const DASHBOARD_TYPES_LIST = 'DASHBOARD_TYPES_LIST';
export const DASHBOARD_TYPES_LIST_PENDING = 'DASHBOARD_TYPES_LIST_PENDING';
export const DASHBOARD_TYPES_LIST_FULFILLED = 'DASHBOARD_TYPES_LIST_FULFILLED';
export const DASHBOARD_TYPES_LIST_REJECTED = 'DASHBOARD_TYPES_LIST_REJECTED';

export const DASHBOARD_TYPES_DELETE = 'DASHBOARD_TYPES_DELETE';
export const DASHBOARD_TYPES_DELETE_PENDING = 'DASHBOARD_TYPES_DELETE_PENDING';
export const DASHBOARD_TYPES_DELETE_FULFILLED = 'DASHBOARD_TYPES_DELETE_FULFILLED';
export const DASHBOARD_TYPES_DELETE_REJECTED = 'DASHBOARD_TYPES_GET_URL_REJECTED';

export const DASHBOARD_TYPES_ERROR_RESET = 'DASHBOARD_TYPES_ERROR_RESET';
export const DASHBOARD_TYPES_RESET = 'DASHBOARD_TYPES_RESET';

export const list = () => ({
    type: DASHBOARD_TYPES_LIST,
});

export const get = (dashboardTypeId, callback) => ({
    type: DASHBOARD_TYPES_GET,
    payload: { dashboardTypeId, callback },
});

export const create = (dashboardType, callback) => ({
    type: DASHBOARD_TYPES_CREATE,
    payload: { dashboardType, callback },
});

export const update = (id, dashboardType, callback) => ({
    type: DASHBOARD_TYPES_UPDATE,
    payload: { id, dashboardType, callback },
});

export const deleteDashboardType = (id, callback) => ({
    type: DASHBOARD_TYPES_DELETE,
    payload: { id, callback },
});

export const errorReset = () => ({
    type: DASHBOARD_TYPES_ERROR_RESET,
});