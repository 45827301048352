import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { listClaimStatusLogs, setClaimStatusHistoryDialog } from '../../../../store/actions/claimAnalysis';
import { set } from '../../../../store/actions/pages';
import { StringEquators } from '../../../../store/constants/pages';
import { createStringDataField, createTimestampField } from '../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import ClaimStatusLogsComponent from './ClaimStatusLogs';

const DEFAULT_SORT = [{ id: 'updatedAt', orderBy: 'desc' }];
export const selectClaimStatusHistoryDialog = (state) => state.claimAnalysis.claimStatusHistoryDialog;

const selectFields = createSelector(
    (state) => [
        createStringDataField('status', 'Status', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createTimestampField('updatedAt', 'Updated At', { sortable: true, fieldType: 'Date' }),
        createStringDataField('updatedBy', 'Updated By', { sortable: true, equators: StringEquators }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['claim-status-logs-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['claim-status-logs-landing'].filter,
    selectSort,
    (state) => state.claimAnalysis.claimStatusLogs,
);

export const ClaimStatusLogs = connect(
    () => {
        return (state, props) => ({
            open: state.claimAnalysis.claimStatusHistoryDialogOpen,
            fields: selectFields(state),
            filteredData: selectFilteredData(state, props),
            order: state.pages[`claim-status-logs-landing`].order,
            filter: state.pages[`claim-status-logs-landing`].filter,
            sort: selectSort(state, props),

            claimStatusLogsPending: state.claimAnalysis.getClaimStatusLogs.pending,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getClaimStatusLogs: listClaimStatusLogs,
            setFilter: (value) => set(`claim-status-logs-landing`, 'filter', value),
            setOrder: (value) => set(`claim-status-logs-landing`, 'order', value),
            setSort: (value) => set(`claim-status-logs-landing`, 'sort', value),
            setClaimStatusHistoryDialog: setClaimStatusHistoryDialog
        }, dispatch)
    })
)(injectIntl(ClaimStatusLogsComponent));