import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/contracts';
import * as API from '../api/contracts';
import * as USERS_API from '../api/users';
import { sessionErrorHandling } from './session';

function* get(action) {
    try {
        yield put({ type: actions.CONTRACTS_GET_PENDING });
        const { contractId } = action.payload;
        const payload = yield call(API.getContract, contractId);
        yield put({ type: actions.CONTRACTS_GET_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


function* create(action) {
    try {
        yield put({ type: actions.CONTRACTS_CREATE_PENDING });
        const {
            contract,
            callback,
        } = action.payload;

        const newContract = yield call(API.createContract, contract);
        yield put({ type: actions.CONTRACTS_CREATE_FULFILLED, newContract });

        if (callback) {
            callback(newContract.id);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.CONTRACTS_UPDATE_PENDING });
        const {
            contract,
            callback
        } = action.payload;

        yield call(API.updateContract, contract);
        yield put({ type: actions.CONTRACTS_UPDATE_FULFILLED });

        if (callback) {
            callback(contract.id);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* upload(action) {
    try {
        const { file, documentObj, callback } = action.payload;
        yield put({ type: actions.CONTRACTS_UPLOAD_PENDING });
        const payload = yield call(API.uploadContract, file, documentObj);
        yield put({ type: actions.CONTRACTS_UPLOAD_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_UPLOAD_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDefaults(action) {
    try {
        yield put({ type: actions.CONTRACTS_GET_DEFAULT_PENDING });
        const {
            callback
        } = action.payload;
        const payload = yield call(API.getDefaults);
        yield put({ type: actions.CONTRACTS_GET_DEFAULT_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_DEFAULT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

// function* nameValidity(action) {
//     try {
//         const { index, ruleName } = action.payload;
//         yield put({ type: actions.RULE_NAME_CHECK_PENDING, payload: { index: index } });
//         const payload = yield call(API.nameValidity, ruleName);
//         yield put({ type: actions.RULE_NAME_CHECK_FULFILLED, payload: { error: payload, index: index } });

//     } catch (error) {
//         const { error: errorMessage } = (error && error.payload) || { error: '' };
//         yield put({ type: actions.RULE_NAME_CHECK_REJECTED, payload: errorMessage });
//         yield call(sessionErrorHandling, error);
//     }
// }

function* getDocumentList(action) {
    try {
        const { contractId } = action.payload;
        yield put({ type: actions.CONTRACTS_DOCUMENTS_LIST_PENDING });
        const payload = yield call(API.getDocumentList, contractId);
        yield put({ type: actions.CONTRACTS_DOCUMENTS_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_DOCUMENTS_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDocument(action) {
    try {
        const { documentId, documentName, metadata, callback } = action.payload;
        yield put({ type: actions.CONTRACTS_DOCUMENT_GET_PENDING });
        const payload = yield call(API.getDocument, documentId);
        yield put({ type: actions.CONTRACTS_DOCUMENT_GET_FULFILLED, payload });

        if (callback) {
            callback(payload, documentName, metadata);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_DOCUMENT_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getRules(action) {
    try {
        const { contractServiceId } = action.payload;
        yield put({ type: actions.CONTRACTS_RULES_LIST_PENDING });
        const payload = yield call(API.getRulesList, contractServiceId);
        yield put({ type: actions.CONTRACTS_RULES_LIST_FULFILLED, payload: { rules: payload, serviceId: contractServiceId } });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_RULES_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeeschedule(action) {
    try {
        const { feeScheduleId } = action.payload;
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_PENDING });
        const payload = yield call(API.getFeeschedule, feeScheduleId);
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_FULFILLED, payload: payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeescheduleLabels(action) {
    try {
        const { feeScheduleId, callback, feescheduleLabelToRateXRefId, setFieldValue } = action.payload;
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_LABELS_TINY_PENDING });
        const payload = yield call(API.getFeescheduleLabels, feeScheduleId);
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_LABELS_TINY_FULFILLED, payload: payload });

        if (callback) {
            callback(payload, feeScheduleId, feescheduleLabelToRateXRefId, setFieldValue);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_LABELS_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* listFeeScheduleLanding() {
    try {
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_LIST_PENDING });
        const payload = yield call(API.listFeeSchedules);
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getGroups(action) {
    try {
        const { ruleId } = action.payload;
        yield put({ type: actions.CONTRACTS_GET_GROUPS_PENDING });
        const payload = yield call(API.getGroups, ruleId);
        yield put({ type: actions.CONTRACTS_GET_GROUPS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_GROUPS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getTreeGroups(action) {
    try {
        const { treePayload, callback } = action.payload;
        yield put({ type: actions.CONTRACTS_GROUPS_TREE_PENDING });
        const groupsTree = yield call(API.getTreeGroups, treePayload);
        const payload = {
            groupsTree,
            treePayload,
        }
        yield put({ type: actions.CONTRACTS_GROUPS_TREE_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GROUPS_TREE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getAlertUsers(action) {
    try {
        yield put({ type: actions.CONTRACTS_GET_ALERT_USERS_PENDING });
        const { clientId, callback } = action.payload;
        const payload = yield call(USERS_API.getUsersTiny, clientId);
        yield put({ type: actions.CONTRACTS_GET_ALERT_USERS_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_ALERT_USERS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getTiny(action) {
    try {
        yield put({ type: actions.CONTRACTS_LIST_TINY_PENDING });
        const { callback } = action.payload;
        const payload = yield call(API.tiny);
        yield put({ type: actions.CONTRACTS_LIST_TINY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_LIST_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPaymentDetails(action) {
    try {
        const { payment, callback } = action.payload;
        yield put({ type: actions.CONTRACTS_PAYMENT_DETAILS_PENDING, payload: { payment } });
        const paymentDetails = yield call(API.paymentDetails, payment);
        const payload = {
            paymentDetails,
            payment,
        }
        yield put({ type: actions.CONTRACTS_PAYMENT_DETAILS_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { payment } = action.payload;
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_PAYMENT_DETAILS_REJECTED, payload: { errorMessage, payment } });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteService(action) {
    try {
        yield put({ type: actions.CONTRACTS_RULE_DELETE_PENDING });
        const { ruleId, callback } = action.payload;
        yield call(API.deleteRule, ruleId);
        yield put({ type: actions.CONTRACTS_RULE_DELETE_FULFILLED, payload: { ruleId } });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_RULE_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeeScheduleTypes() {
    try {
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_TYPES_PENDING });
        const payload = yield call(API.getFeescheduleTypes);
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_TYPES_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_FEE_SCHEDULE_TYPES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* createFeeSchedule(action) {
    try {
        yield put({ type: actions.CONTRACTS_CREATE__FEE_SCHEDULE_PENDING });
        const {
            feeSchedule,
            callback,
        } = action.payload;

        const newFeeSchedule = yield call(API.createFeeSchedule, feeSchedule);
        yield put({ type: actions.CONTRACTS_CREATE_FEE_SCHEDULE_FULFILLED, newFeeSchedule });

        if (callback) {
            callback(newFeeSchedule?.id);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_CREATE_FEE_SCHEDULE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateFeeSchedule(action) {
    try {
        yield put({ type: actions.CONTRACTS_UPDATE__FEE_SCHEDULE_PENDING });
        const {
            feeSchedule,
            callback,
        } = action.payload;

        const updatedFeeSchedule = yield call(API.updateFeeSchedule, feeSchedule);
        yield put({ type: actions.CONTRACTS_UPDATE_FEE_SCHEDULE_FULFILLED, updatedFeeSchedule });

        if (callback) {
            callback(feeSchedule?.id, updatedFeeSchedule);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_UPDATE_FEE_SCHEDULE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeeScheduleCodes(action) {
    try {
        yield put({ type: actions.CONTRACTS_GET_FEE_SCHEDULE_CODES_PENDING });
        const { options } = action.payload;
        const payload = yield call(API.getFeescheduleCodes, options);
        yield put({ type: actions.CONTRACTS_GET_FEE_SCHEDULE_CODES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_FEE_SCHEDULE_CODES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* copyContract(action) {
    try {
        yield put({ type: actions.COPY_CONTRACTS_PENDING });
        const { options, callback } = action.payload;
        if (options.targetClient) {
            yield call(API.copyContractCrossClient, options);
            yield put({ type: actions.COPY_CONTRACTS_FULFILLED });
        }
        else {
            yield call(API.copyContract, options);
            yield put({ type: actions.COPY_CONTRACTS_FULFILLED });
        }

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.COPY_CONTRACTS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteContract(action) {
    try {
        yield put({ type: actions.DELETE_CONTRACT_PENDING });
        const { contractId, callback } = action.payload;
        const payload = yield call(API.deleteContract, contractId);
        yield put({ type: actions.DELETE_CONTRACT_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DELETE_CONTRACT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* contractCriteria(action) {
    try {
        yield put({ type: actions.CONTRACTS_GET_CRITERIA_PENDING });
        const payload = yield call(API.getContractCriteria);
        yield put({ type: actions.CONTRACTS_GET_CRITERIA_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_CRITERIA_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getStates(action) {
    try {
        yield put({ type: actions.CONTRACTS_GET_STATES_PENDING });
        const payload = yield call(API.getStates);
        yield put({ type: actions.CONTRACTS_GET_STATES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_STATES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateState(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.CONTRACTS_UPDATE_STATE_PENDING });
        const payload = yield call(API.updateContractState, options);
        yield put({ type: actions.CONTRACTS_UPDATE_STATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_UPDATE_STATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getCascodes(action) {
    try {
        yield put({ type: actions.CONTRACTS_GET_CASCODES_PENDING });
        const payload = yield call(API.getContractCasCodes);
        yield put({ type: actions.CONTRACTS_GET_CASCODES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_CASCODES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getContractedServices(action) {
    try {
        const { contractId } = action.payload;
        yield put({ type: actions.CONTRACTS_GET_CONTRACTED_SERVICES_PENDING });
        const payload = yield call(API.getContractedServices, contractId);
        yield put({ type: actions.CONTRACTS_GET_CONTRACTED_SERVICES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_CONTRACTED_SERVICES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getContractDependencies(action) {
    try {
        const { contractId } = action.payload;
        yield put({ type: actions.CONTRACTS_GET_DELETE_DEPENDENCY_PENDING });
        const payload = yield call(API.getContractDependencies, contractId);
        yield put({ type: actions.CONTRACTS_GET_DELETE_DEPENDENCY_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_DELETE_DEPENDENCY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getTinyContractsByState(action) {
    try {
        const { state } = action.payload;
        yield put({ type: actions.CONTRACTS_GET_TINY_BY_STATE_PENDING });
        const payload = yield call(API.getTinyContractsByState, state);
        yield put({ type: actions.CONTRACTS_GET_TINY_BY_STATE_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTS_GET_TINY_BY_STATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

// export function* reset() {
//     yield put({ type: actions.CLIENTS_RESET });
// }

export default function* () {
    yield takeEvery(actions.CONTRACTS_GET_DEFAULT, getDefaults);
    yield takeEvery(actions.CONTRACTS_GET, get);
    yield takeEvery(actions.CONTRACTS_CREATE, create);
    yield takeEvery(actions.CONTRACTS_UPDATE, update);
    yield takeEvery(actions.CONTRACTS_DOCUMENTS_LIST, getDocumentList);
    yield takeEvery(actions.CONTRACTS_UPLOAD, upload);
    yield takeEvery(actions.CONTRACTS_DOCUMENT_GET, getDocument);
    yield takeEvery(actions.CONTRACTS_RULES_LIST, getRules);
    yield takeEvery(actions.CONTRACTS_FEE_SCHEDULE, getFeeschedule);
    yield takeEvery(actions.CONTRACTS_FEE_SCHEDULE_LABELS_TINY, getFeescheduleLabels);
    yield takeEvery(actions.CONTRACTS_FEE_SCHEDULE_LIST, listFeeScheduleLanding);
    yield takeEvery(actions.CONTRACTS_GET_GROUPS, getGroups);
    yield takeEvery(actions.CONTRACTS_GROUPS_TREE, getTreeGroups);
    yield takeEvery(actions.CONTRACTS_GET_ALERT_USERS, getAlertUsers);
    yield takeEvery(actions.CONTRACTS_LIST_TINY, getTiny);
    yield takeEvery(actions.CONTRACTS_PAYMENT_DETAILS, getPaymentDetails);
    yield takeEvery(actions.CONTRACTS_RULE_DELETE, deleteService);
    yield takeEvery(actions.CONTRACTS_FEE_SCHEDULE_TYPES, getFeeScheduleTypes);
    yield takeEvery(actions.CONTRACTS_FEE_SCHEDULE_CREATE, createFeeSchedule);
    yield takeEvery(actions.CONTRACTS_FEE_SCHEDULE_UPDATE, updateFeeSchedule);
    yield takeEvery(actions.CONTRACTS_GET_FEE_SCHEDULE_CODES, getFeeScheduleCodes);
    yield takeEvery(actions.COPY_CONTRACTS, copyContract);
    yield takeEvery(actions.DELETE_CONTRACT, deleteContract);
    yield takeEvery(actions.CONTRACTS_GET_CRITERIA, contractCriteria);
    yield takeEvery(actions.CONTRACTS_GET_STATES, getStates);
    yield takeEvery(actions.CONTRACTS_UPDATE_STATE, updateState);
    yield takeEvery(actions.CONTRACTS_GET_CASCODES, getCascodes);
    yield takeEvery(actions.CONTRACTS_GET_CONTRACTED_SERVICES, getContractedServices);
    yield takeEvery(actions.CONTRACTS_GET_DELETE_DEPENDENCY, getContractDependencies);
    yield takeEvery(actions.CONTRACTS_GET_TINY_BY_STATE, getTinyContractsByState);
}