/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { Suspense, useEffect } from "react";
import { Redirect, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { Route } from "../util";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import ReactGA from 'react-ga';
import { actions } from '../../_metronic/ducks/builder';
import { useLastLocation } from "react-router-last-location";
import AuthPage from "../pages/auth/AuthPage/AuthPage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import * as routerHelpers from "../router/RouterHelpers";
import { applyProtected, applyPreAuthentication } from './util/ApplyProtected';
import { ENV_PRODUCTION } from '../store/constants/env';

import { DashboardPage } from "../pages/dashboardPage";
import { ClientsPage } from "../pages/administration/ClientsPage";
import { ClientsEditPage } from "../pages/administration/ClientsPage/ClientsEditPage";
import { ClientsViewPage } from "../pages/administration/ClientsPage/ClientsViewPage";
import { ClientConfigurationPage } from "../pages/administration/ClientsPage/ClientConfigurationPage";
import { UsersPage } from "../pages/administration/UsersPage";
import { UsersEditPage } from "../pages/administration/UsersPage/UsersEditPage";
import { RolesPage } from "../pages/administration/RolesPage";
import { RolesEditPage } from "../pages/administration/RolesPage/RolesEditPage";
import { DashboardConfigurationPage } from "../pages/administration/DashboardConfigurationPage";
import { DashboardConfigurationEditPage } from "../pages/administration/DashboardConfigurationPage/DashboardConfigurationEditPage";
import { DashboardFiltersPage } from "../pages/administration/DashboardFiltersPage";
import { DashboardFiltersEditPage } from "../pages/administration/DashboardFiltersPage/DashboardFiltersEditPage";
import { DashboardDataSourcesPage } from "../pages/administration/DashboardDataSourcesPage";
import { DashboardDataSourcesEditPage } from "../pages/administration/DashboardDataSourcesPage/DashboardDataSourcesEditPage";
import { DashboardTypesPage } from "../pages/administration/DashboardTypesPage";
import { DashboardTypesEditPage } from "../pages/administration/DashboardTypesPage/DashboardTypesEditPage";
import { EulaPage } from "../pages/eulaPage";
import { ChangePasswordPage } from "../pages/auth/ChangePasswordPage";
import { StatisticsPage } from "../pages/administration/StatisticsPage";
import { PartnersPage } from "../pages/administration/PartnersPage";
import { PartnersEditPage } from "../pages/administration/PartnersPage/PartnersEditPage";
import { AppIdPage } from "../pages/administration/AppIdPage";



import { ForgotPasswordPage } from "../pages/auth/ForgotPassword";
import { ResetPasswordPage } from "../pages/auth/ResetPassword";
import { ForgotPasswordConfirmationPage } from "../pages/auth/ForgotPassword/Confirmation";
import { ResetPasswordConfirmationPage } from "../pages/auth/ResetPassword/Confirmation";

import { ContractsPage } from "../pages/contractsPage";

import { ContractsFamilyPage } from "../pages/contractsPage/ContractsFamily";
import { ContractFamilyEditPage } from "../pages/contractsPage/ContractsFamily/ContractsFamilyEditPage";



import { ContractsEditPage } from "../pages/contractsPage/ContractsEditPage";
import { RulesEditPage } from "../pages/contractsPage/RulesEditPage";
import { ContractViewPage } from "../pages/contractsPage/ContractViewPage";
import { FeeScheduleView } from "../pages/contractsPage/ContractViewPage/ServicesView/FeeScheduleView";
import { FeeSchedulePage } from "../pages/feeSchedulePage";
import { ContractedRatesPage } from "../pages/ContractedRatesPage";
import { PayerContractsPage } from "../pages/payerContractsPage";
import { PayerScoreCardPage } from "../pages/payerScoreCardPage";
import { PayerPlanManagementPage } from "../pages/PayerPlanManagementPage";
import { CodeServicesPage } from "../pages/contractsPage/CodeServicesPage";
import { CodeServicesEditPage } from "../pages/contractsPage/CodeServicesPage/CodeServicesEditPage";
// import { PaymentsPage } from "../pages/paymentsPage";
import { PayerInfoPage } from "../pages/payerInfoPage";
import { ClaimAnalysisPage } from "../pages/ClaimAnalysisPage";
import { ClaimLookupPage } from "../pages/ClaimLookupPage";

import { ClaimJsonView } from "../pages/ClaimAnalysisPage/ClaimJsonView"; 
import { SettingsPage } from "../pages/contractsPage/settingsPage";
import { FacilitiesPage } from '../pages/facilitiesPage/index';
import { CriteriaSettingsPage } from '../pages/contractsPage/CriteriaSettingsPage';
import { ExecutionPage } from "../pages/executionPage";

import { ChargeMasterPage } from '../pages/pricer/chargeMasterPage';
import { ShoppablePage } from '../pages/pricer/shoppablePage';
import { AssociatedPage } from '../pages/pricer/associatedPage';
import { AllowablesPage } from '../pages/pricer/allowablesPage';
import { BrandConfigurationPage } from '../pages/pricer/brandConfigurationPage';
import { ClientBrandingPage } from "../pages/clientBrandingPage";
import { PayerInfoPage as PricerPayerInfoPage } from '../pages/pricer/payerInfoPage';
import { PricerSetup } from '../pages/pricer/PricerSetup';

import { VendorsPage } from "../pages/vendorsPage";
import { VendorsEditPage } from "../pages/vendorsPage/vendorsEditPage";
import { VendorViewPage } from "../pages/vendorsPage/vendorView";

import { NPIRolesPage } from '../pages/npiRolesPage';
import { ModifiersPage } from '../pages/modifiersPage';

import { UploadsPage } from "../pages/uploads";
import { FileExecution } from "../pages/uploads/fileExecutions";

import { LayoutSplashScreen } from "../../_metronic";
import { selectRoleBasedMenu } from "../store/selectors/menu";
import { selectAuthenticated, selectHasEstimatorAccess, selectIsSuperAdmin, selectSessionUser } from "../store/selectors/session";
import {
  selectCanAccessClients, selectCanAccessUsers, selectCanAccessStatistics,
  selectCanAccessDashboardManagement, selectCanAccessEula,
  selectCanAccessPartnerManagement,
  selectCanAccessUploads,
  selectCanAccessClaimAnalysis,
  selectCanAccessCMS,
  selectCanAccessRoles,
  selectCanAccessClaimAudit,
  selectCanAccessExecutionLogs,selectCanAccessCmsModelling} from "../store/selectors/access";
import { createSelector } from "reselect";
import { selectCanAccessPricer } from "../store/selectors/access/pricer";
import { setIdleAlert } from "../store/actions/session";
import { selectCanAccessVendorManagement } from "../store/selectors/access/vendorManagement";
import { ForcedResetPasswordPage } from "../pages/auth/ForcedResetPassword";
import { ClaimEstimator } from "../pages/claimEstimatorPage";
import { UnauthorizedPage } from "../pages/errors/unauthorizedPage";
import { FallbackPage } from "../pages/errors/fallbackPage";
import { EstimateHistory } from "../pages/estimationHistoryPage";
import { EstimateHistoryDetails} from "../pages/estimationHistoryPage/estimateHistoryDetailsPage";
import { JobStatisticsPage } from "../pages/controlRoom/jobStatisticsPage";
import { S3ListnersPage } from "../pages/controlRoom/s3ListnersPage";
import { HealthCheckPage } from "../pages/controlRoom/healthCheckPage";
import { ClaimAuditsPage } from "../pages/claimAuditsPage";
import { ToastContainer } from "react-toastify";
import { ContractModelingPage } from "../pages/contractsPage/ContractModelingPage";
import { ModelEditPage } from "../pages/contractsPage/ContractModelingPage/ModelEditPage";
import { ModelExecutionsPage } from "../pages/contractsPage/ContractModelingPage/ExecutionsPage";
import { ModelResultsPage } from "../pages/contractsPage/ContractModelingPage/ModelResultsPage";
import { DRGPage } from "../pages/DRGPage";
import { DRGEditPage } from "../pages/DRGPage/DRGEditPage";
import { EstimationsResultPage } from "../pages/claimEstimatorPage/estimationsResultPage";
import { RemitPayersPage } from "../pages/RemitPayersPage";
import { OPPSPage } from "../pages/OPPSPage";
import { OPPSEditPage } from "../pages/OPPSPage/OPPSEditPage";

import { selectCanAccessDashoard } from "../store/selectors/access/dashboard";
import { selectClientProducts } from "../store/selectors/clients";
import { ROLE_SUPERADMIN } from "../store/constants/roles";
import { CopyRulePage } from "../pages/contractsPage/RulesEditPage/CopyRule";
import ReactIdleTimer from "../util/ReactIdleTimer";
import { QSDataSetPage } from "../pages/controlRoom/QSDataSetPage";
import { ClaimViewPage } from "../pages/ClaimAnalysisPage/ClaimViewPage";
import { BreadCrumb } from "../common/Breadcrumb";
import { APIKeysPage } from "../pages/administration/APIKeysPage";
import { APIKeyEditPage } from "../pages/administration/APIKeysPage/APIKeyEditPage";

import { APPIdEditPage } from "../pages/administration/AppIdPage/AppIdEditPage";

import { selectHasSettingsAccess } from "../store/selectors/access/settings";
import { FeeSchedulePageV2 } from "../pages/feeSchedulePage/feeSchedulePagev2";
import {FeeScheduleEditDialog } from "../pages/feeSchedulePage/feeScheduleEditPage";
import { VersionUpdatePage } from "../pages/about/versionUpdate";
import { ContractModelSimulator } from "../pages/ContractModelSimulator";
import { EAPGPage } from "../pages/EAPGPage";
import { EAPGEditPage } from "../pages/EAPGPage/EAPGEditPage";
import { FeeScheduleItemDialog } from "../pages/contractsPage/ContractViewPage/ServicesView/FeeScheduleView/FeeScheduleItems/FeeScheduleItemDialog";
import { ModelResultsDetailsPage } from "../pages/contractsPage/ContractModelingPage/ModelResultsPage/ModelResultsDetailsPage";
import {StatisticsTabPage} from "../pages/administration/StatisticsTabPage";

const PreAuthenticationLogInPage = applyPreAuthentication(AuthPage);
const ProtectedDashboardPage = applyProtected(DashboardPage, selectCanAccessDashoard);

const ProtectedEulaPage = applyProtected(EulaPage, selectCanAccessEula);
const ProtectedChangePasswordPage = applyProtected(ChangePasswordPage);

const ProtectedClientsPage = applyProtected(ClientsPage, selectCanAccessClients);
const ProtectedClientsEditPage = applyProtected(ClientsEditPage, selectCanAccessClients);
const ProtectedClientConfigurationPage = applyProtected(ClientConfigurationPage, selectCanAccessClients);
const ProtectedAppIdPage = applyProtected(AppIdPage, selectCanAccessClients);



const ProtectedUsersPage = applyProtected(UsersPage, selectCanAccessUsers);
const ProtectedUsersEditPage = applyProtected(UsersEditPage, selectCanAccessUsers);
const ProtectedRolesPage = applyProtected(RolesPage, selectCanAccessRoles);
const ProtectedRolesEditPage = applyProtected(RolesEditPage, selectCanAccessRoles);
const ProtectedClientViewPage = applyProtected(ClientsViewPage, selectCanAccessClients);

const ProtectedDashboardConfigurationPage = applyProtected(DashboardConfigurationPage, selectCanAccessDashboardManagement);
const ProtectedDashboardConfigurationEditPage = applyProtected(DashboardConfigurationEditPage, selectCanAccessDashboardManagement);

const ProtectedDashboardFiltersPage = applyProtected(DashboardFiltersPage, selectCanAccessDashboardManagement);
const ProtectedDashboardFiltersEditPage = applyProtected(DashboardFiltersEditPage, selectCanAccessDashboardManagement);

const ProtectedDashboardDataSourcesPage = applyProtected(DashboardDataSourcesPage, selectCanAccessDashboardManagement);
const ProtectedDashboardDataSourcesEditPage = applyProtected(DashboardDataSourcesEditPage, selectCanAccessDashboardManagement);

const ProtectedDashboardTypesPage = applyProtected(DashboardTypesPage, selectCanAccessDashboardManagement);
const ProtectedDashboardTypesEditPage = applyProtected(DashboardTypesEditPage, selectCanAccessDashboardManagement);

const ProtectedPartnersPage = applyProtected(PartnersPage, selectCanAccessPartnerManagement);
const ProtectedPartnersEditPage = applyProtected(PartnersEditPage, selectCanAccessPartnerManagement);

const ProtectedAPIKeysPage = applyProtected(APIKeysPage, selectIsSuperAdmin);
const ProtectedAPIKeyEditPage = applyProtected(APIKeyEditPage, selectIsSuperAdmin);

const ProtectedAPPIdEditPage = applyProtected(APPIdEditPage, selectIsSuperAdmin);


// const ProtectedPartnersEditPage = applyProtected(PartnersEditPage, selectCanAccessPartnerManagement);

const PreAuthenticationForgotPasswordPage = applyPreAuthentication(ForgotPasswordPage);
const PreAuthenticationResetPasswordPage = applyPreAuthentication(ResetPasswordPage);

const ProtecteContractsPage = applyProtected(ContractsPage, selectCanAccessCMS);
const ProtectedContractsFamilyPage = applyProtected(ContractsFamilyPage, selectCanAccessCMS);
const ProtectedContractsFamilyEditPage = applyProtected(ContractFamilyEditPage, selectCanAccessCMS);

const ProtecteContractsEditPage = applyProtected(ContractsEditPage, selectCanAccessCMS);
const ProtectedRulesEditPage = applyProtected(RulesEditPage, selectCanAccessCMS);
const ProtectedCopyRulePage = applyProtected(CopyRulePage, selectCanAccessCMS);
const ProtecteContractViewPage = applyProtected(ContractViewPage, selectCanAccessCMS);
const ProtectedFeeScheduleView = applyProtected(FeeScheduleView, selectCanAccessCMS);
const ProtectedFeeSchedulePage = applyProtected(FeeSchedulePageV2, selectCanAccessCMS);
const ProtectedFeeScheduleEditPage = applyProtected(FeeScheduleEditDialog, selectCanAccessCMS);
const ProtectedFeeScheduleItemEditPage = applyProtected(FeeScheduleItemDialog, selectCanAccessCMS);

const ProtectedContractedRatesPage = applyProtected(ContractedRatesPage, selectCanAccessCMS);
const ProtectedPayerContractsPage = applyProtected(PayerContractsPage, selectCanAccessCMS);
const ProtectedPayerScoreCardPage = applyProtected(PayerScoreCardPage, selectCanAccessCMS);
const ProtectedPayerInfoPage = applyProtected(PayerInfoPage, selectCanAccessCMS);
const ProtectedRemitPayersPage = applyProtected(RemitPayersPage, selectCanAccessCMS);
const ProtectedPayerPlanManagementPage = applyProtected(PayerPlanManagementPage, selectCanAccessCMS);

const ProtectedCodeServicesPage = applyProtected(CodeServicesPage, selectCanAccessCMS);
const ProtecteCodeServicesEditPage = applyProtected(CodeServicesEditPage, selectCanAccessCMS);

const ProtectedContractModelingPage = applyProtected(ContractModelingPage, selectCanAccessCmsModelling);
// const ProtectedModelExecutionsPage = applyProtected(ModelExecutionsPage, selectIsSuperAdmin);
const ProtectedModelResultsPage = applyProtected(ModelResultsPage, selectCanAccessCmsModelling);
const ProtectedModelResultsDetailsPage = applyProtected(ModelResultsDetailsPage, selectCanAccessCmsModelling);
const ProtectedModelEditPage = applyProtected(ModelEditPage, selectIsSuperAdmin);

const ProtectedContractModelSimulator = applyProtected(ContractModelSimulator, selectIsSuperAdmin);

// const ProtectedPaymentsPage = applyProtected(PaymentsPage);
const ProtectedClaimAnalysisPage = applyProtected(ClaimAnalysisPage, selectCanAccessClaimAnalysis);
const ProtectedClaimAnalysisJSONViewPage = applyProtected(ClaimJsonView, selectCanAccessClaimAnalysis);
const ProtectedClaimAnalysisLookupPage = applyProtected(ClaimLookupPage, selectCanAccessClaimAnalysis);

// const ProtectedPaymentsPage = applyProtected(PaymentsPage);

const ProtectedClaimViewPage = applyProtected(ClaimViewPage, selectCanAccessClaimAnalysis);
const ProtectedClaimEstimatorPage = applyProtected(ClaimEstimator, selectHasEstimatorAccess);
const ProtectedEstimateHistory = applyProtected(EstimateHistory, selectHasEstimatorAccess);
const ProtectedEstimateHistoryDetails = applyProtected(EstimateHistoryDetails, selectHasEstimatorAccess);
const ProtectedEstimationsResultPage = applyProtected(EstimationsResultPage, selectHasEstimatorAccess);
const ProtectedEstimatorChargeMasterPage = applyProtected(ChargeMasterPage, selectHasEstimatorAccess);
const ProtectedEstimatorAssociatedPage = applyProtected(AssociatedPage, selectHasEstimatorAccess);

const ProtectedSettingsPage = applyProtected(SettingsPage, selectHasSettingsAccess);
const ProtectedClientBrandingPage = applyProtected(ClientBrandingPage, selectHasSettingsAccess);
const ProtecteFacilitiesPage = applyProtected(FacilitiesPage, selectCanAccessCMS);
const ProtectedCriteriaSettingsPage = applyProtected(CriteriaSettingsPage, selectCanAccessCMS);
const ProtectedExecutionPage = applyProtected(ExecutionPage, selectCanAccessExecutionLogs);

const ProtectedStatisticsPage = applyProtected(StatisticsPage, selectCanAccessStatistics);
const ProtectedStatisticsTabPage = applyProtected(StatisticsTabPage, selectCanAccessStatistics);


const ProtectedChargeMasterPage = applyProtected(ChargeMasterPage, selectCanAccessPricer);
const ProtectedShoppablePage = applyProtected(ShoppablePage, selectCanAccessPricer);
const ProtectedAssociatedPage = applyProtected(AssociatedPage, selectCanAccessPricer);
const ProtectedAllowablesPage = applyProtected(AllowablesPage, selectCanAccessPricer);
const ProtectedBrandConfigurationPage = applyProtected(BrandConfigurationPage, selectCanAccessPricer);
const ProtectedPricerPayerInfoPage = applyProtected(PricerPayerInfoPage, selectCanAccessPricer);
const ProtectedPricerSetup = applyProtected(PricerSetup, selectCanAccessPricer);

const ProtectedVendorsPage = applyProtected(VendorsPage, selectCanAccessVendorManagement);
const ProtectedVendorsEditPage = applyProtected(VendorsEditPage, selectCanAccessVendorManagement);
const ProtectedVendorViewPage = applyProtected(VendorViewPage, selectCanAccessVendorManagement);

const ProtectedNPIRolesPage = applyProtected(NPIRolesPage);
const ProtectedModifiersPage = applyProtected(ModifiersPage);
const ProtectedDRGPage = applyProtected(DRGPage);
const ProtectedDRGEditPage = applyProtected(DRGEditPage);

const ProtectedOPPSPage = applyProtected(OPPSPage);
const ProtectedOPPSEditPage = applyProtected(OPPSEditPage);

const ProtectedUploadsPage = applyProtected(UploadsPage, selectCanAccessUploads);
const ProtectedFileExecution = applyProtected(FileExecution, selectCanAccessUploads);

const ProtectedJobStatisticsPage = applyProtected(JobStatisticsPage, selectIsSuperAdmin);
const ProtectedS3ListnersPage = applyProtected(S3ListnersPage, selectIsSuperAdmin);
const ProtectedQSDataSetPage = applyProtected(QSDataSetPage, selectIsSuperAdmin);
const ProtectedHealthCheckPage = applyProtected(HealthCheckPage, selectIsSuperAdmin);

const ProtectedClaimAuditsPage = applyProtected(ClaimAuditsPage, selectCanAccessClaimAudit);

const ProtectedVersionUpdatesPage = applyProtected(VersionUpdatePage);

const ProtectedEAPGPage = applyProtected(EAPGPage);
const ProtectedEAPGEditPage = applyProtected(EAPGEditPage);

const selectSessionDetails = createSelector(
  selectAuthenticated,
  selectRoleBasedMenu,
  selectSessionUser,
  selectClientProducts,
  (isAuthorized, menuConfig, user, clientProducts) => ({
    isAuthorized,
    menuConfig,
    eulaAccepted: user && user.eulaAccepted,
    userLastLocation: routerHelpers.getLastLocation() === "/eula" ? "/dashboard" : routerHelpers.getLastLocation(),
    forceResetPW: user && user.forceResetPW,
    clientProducts,
    userRole: user && user.role,
  })
)

export const Routes = withRouter(({ Layout, history }: RouteComponentProps & {
  Layout: React.ComponentType;
}) => {
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();
  routerHelpers.saveLastLocation(lastLocation);
  routerHelpers.saveBreadcrumBasedLocation(lastLocation,history);

  useEffect(() => {
    routerHelpers.updateTabCount();
    sessionStorage.removeItem("duplicated");                   
    
    const handleBeforeUnload = () => {
      routerHelpers.removeTab();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  
  const { isAuthorized, menuConfig, eulaAccepted, userLastLocation, forceResetPW, clientProducts, userRole } = useSelector(
    selectSessionDetails,
    shallowEqual
  );

  if (process.env.NODE_ENV === ENV_PRODUCTION) {
    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
  }

  useEffect(() => {
    dispatch(actions.setMenuConfig(menuConfig));
  }, [dispatch, menuConfig]);

  useEffect(() => {
    const handleInvalidToken = e => {
      if (e.key === 'authToken') {
        if (e.oldValue !== e.newValue && e.newValue === 'null') {
          console.log("useEffect --> event listner. Signed out due to cross tab", e)
          dispatch(setIdleAlert(true)); // Set Alert message in login page of idle session timeout
          history.push('/logout');
        }
      }
    }
    window.addEventListener('storage', handleInvalidToken)
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken)
    }
  }, [])

  const onIdleHandler = () => {
    if (isAuthorized) {
      console.log("React Idle Timer")
      dispatch(setIdleAlert(true)); // Set Alert message in login page of idle session timeout
    }
    history.push('/logout');
  }

  const getBaseRedirection = () => {
    if (userRole === ROLE_SUPERADMIN) {
      return `/dashboard`
    } else {
      const nextPath = (menuConfig.aside.items.length > 0 && menuConfig.aside.items[0].submenu.length > 0) ? `/${menuConfig.aside.items[0].submenu[0].page}` : '/fallback-page';
      if (!clientProducts?.hasDashboardAccess) {
        return nextPath
      }
      return nextPath
    }
  }

  const routes = [
    { path: "/dashboard/:dashboardId", name: "Dashboard", component: ProtectedDashboardPage },
    { path: "/dashboard", name: "Dashboard", component: ProtectedDashboardPage },
    { path: "/admin/clients/view/:clientId", name: "View Client", component: ProtectedClientViewPage },
    { path: "/admin/clients/edit/:clientId", name: "Edit Client", component: ProtectedClientsEditPage },
    { path: "/admin/clients/configuration/:clientId", name: "Configuration", component: ProtectedClientConfigurationPage },
    { path: "/admin/clients", name: "Clients", component: ProtectedClientsPage },

    { path: "/admin/dashboards/edit/:dashboardId", name: "Edit Dashboard", component: ProtectedDashboardConfigurationEditPage },
    { path: "/admin/dashboards", name: "Dashboards", component: ProtectedDashboardConfigurationPage },

    { path: "/admin/users/edit/:userId", name: "Edit User", component: ProtectedUsersEditPage },
    { path: "/admin/users", name: "Users", component: ProtectedUsersPage },

    { path: "/admin/roles/edit/:roleId", name: "Edit Role", component: ProtectedRolesEditPage },
    { path: "/admin/roles", name: "Roles", component: ProtectedRolesPage },

    { path: "/admin/dashboardfilters/edit/:dashboardFilterId", name: "", component: ProtectedDashboardFiltersEditPage },
    { path: "/admin/dashboardfilters", name: "", component: ProtectedDashboardFiltersPage },

    { path: "/admin/dashboardDataSources/edit/:dashboardDataSourceId", name: "", component: ProtectedDashboardDataSourcesEditPage },
    { path: "/admin/dashboardDataSources", name: "", component: ProtectedDashboardDataSourcesPage },

    { path: "/admin/dashboardTypes/edit/:dashboardTypeId", name: "", component: ProtectedDashboardTypesEditPage },
    { path: "/admin/dashboardTypes", name: "", component: ProtectedDashboardTypesPage },

    { path: "/admin/partners/edit/:partnerId", name: "Edit Partner", component: ProtectedPartnersEditPage },
    { path: "/admin/partners", name: "Partners", component: ProtectedPartnersPage },

    { path: "/admin/api-key-management/edit/:apiKeyId", name: "Partners", component: ProtectedAPIKeyEditPage },
    { path: "/admin/app-id-management/edit/:appId", name: "Partners", component: ProtectedAPPIdEditPage },

    { path: "/admin/api-key-management", name: "API Keys", component: ProtectedAPIKeysPage },


    { path: "/admin/app-id-management", name: "Applications", component: ProtectedAppIdPage },


    { path: "/changepassword", name: "Change Password", component: ProtectedChangePasswordPage },

    { path: "/admin/statistics/trim", name: "Statistics", component: ProtectedStatisticsTabPage },
    { path: "/admin/statistics", name: "", component: ProtectedStatisticsPage },

    { path: "/cms/feeschedules/edit/:directoryId/:id", name: "Edit Fee Schedules", component: ProtectedFeeScheduleEditPage },

    { path: "/cms/feeschedules/view/:feeScheduleId/:pxCode?/:directoryId?", name: "Fee Schedule Details", component: ProtectedFeeScheduleView },
    { path: "/cms/feeschedulesrate/edit/:feeScheduleId/:feeScheduleItemId?", name: "Edit Fee Schedule Rate", component: ProtectedFeeScheduleItemEditPage },
    { path: "/cms/feeschedules/:directoryId", name: "Fee Schedules ", component: ProtectedFeeSchedulePage },
    { path: "/cms/contract/contractedrates", name: "Contracted Rates", component: ProtectedContractedRatesPage },

    { path: "/cms/contract/view/:contractId", name: "View Contract", component: ProtecteContractViewPage },
    { path: "/cms/contract/edit/:contractId", name: "Edit Contract", component: ProtecteContractsEditPage },
    { path: "/cms/contract/:contractId/rule/:serviceId/edit/:ruleId", name: "", component: ProtectedRulesEditPage },
    { path: "/cms/contract/:contractId/rule/:serviceId/copy/:ruleId", name: "Copy Rule", component: ProtectedCopyRulePage },
    { path: "/cms/codeservice/edit/:serviceId", name: "Edit Code Service", component: ProtecteCodeServicesEditPage },
    { path: "/cms/codeservice", name: "Code Services", component: ProtectedCodeServicesPage },

    { path: "/configuration-settings", name: "Settings", component: ProtectedSettingsPage },
    { path: "/configuration-branding", name: "Branding", component: ProtectedClientBrandingPage },
    { path: "/cms/facilities", name: "Facilities", component: ProtecteFacilitiesPage },
    { path: "/cms/criteria", name: "Search Criteria", component: ProtectedCriteriaSettingsPage },
    { path: "/cms/npi-roles", name: "NPI Roles", component: ProtectedNPIRolesPage },
    { path: "/cms/modifiers", name: "Modifiers", component: ProtectedModifiersPage },

    { path: "/cms/ipps/edit/:id", name: "Edit IPPS", component: ProtectedDRGEditPage },
    { path: "/cms/ipps", name: "IPPS", component: ProtectedDRGPage },

    { path: "/cms/opps/edit/:id", name: "Edit OPPS", component: ProtectedOPPSEditPage },
    { path: "/cms/opps", name: "OPPS", component: ProtectedOPPSPage },
    
    { path: "/cms/family/edit/:id", name: "Edit Family", component: ProtectedContractsFamilyEditPage},
    { path: "/cms/family", name: "Contract Families", component: ProtectedContractsFamilyPage},

// Contracts Family Page 



    { path: "/cms/claimAnalysis/data-view/:cn/:cid", name: "Claim Data View", component: ProtectedClaimAnalysisJSONViewPage },
    { path: "/cms/modeling/data-view/:cn", name: "Claim Data View", component: ProtectedClaimAnalysisJSONViewPage },
    { path: "/cms/claimAnalysis/view/:id", name: "Claim Details", component: ProtectedClaimViewPage },
    { path: "/cms/claimAnalysis", name: "Claim Analysis", component: ProtectedClaimAnalysisPage },
    { path: "/cms/claimLookup/:cn?", name: "Claim Lookup", component: ProtectedClaimAnalysisLookupPage },


    { path: "/cms/claimAudits", name: "Claim Audits", component: ProtectedClaimAuditsPage },

    { path: "/cms/modeling/edit/:modelId", name: "Edit Model", component: ProtectedModelEditPage },
    { path: "/cms/modeling/results/view/:modelAnalysisId1/:modelAnalysisId2", exact: true, name: "Model Results Details", component: ProtectedModelResultsDetailsPage },
    { path: "/cms/modeling/results/:modelId", exact: true, name: "Model Results", component: ProtectedModelResultsPage },
    { path: "/cms/simulator", exact: true, name: "Contract Model Simulator", component: ProtectedContractModelSimulator },
    { path: "/cms/modeling", exact: true, name: "Modeling", component: ProtectedContractModelingPage },
    { path: "/cms/contract", name: "Contracts", component: ProtecteContractsPage,exact:true },

    { path: "/estimator/history/results/:estimatorId", name: "Estimation Results", component: ProtectedEstimationsResultPage },
    { path: "/estimator/history/edit/:estimatorId", name: "Edit Estimator", component: ProtectedClaimEstimatorPage },
    { path: "/estimator/history/view/:estimatorId/:op?", name: "View Estimator", component: ProtectedEstimateHistoryDetails },

    { path: "/estimator/history", name: "Estimator History", component: ProtectedEstimateHistory },
    { path: "/estimator/chargeMaster", name: "Charge Master", component: ProtectedEstimatorChargeMasterPage },
    { path: "/estimator/associated", name: "Associated Charges", component: ProtectedEstimatorAssociatedPage },

    { path: "/cms/payers/contracts", name: "Payer Contracts", component: ProtectedPayerContractsPage },
    { path: "/cms/payers/scorecard", name: "Payer Scorecard", component: ProtectedPayerScoreCardPage },
    { path: "/cms/payers/payerinfo", name: "Claim Payers", component: ProtectedPayerInfoPage },
    { path: "/cms/payers/remitPayers", name: "Remit Payers", component: ProtectedRemitPayersPage },
    { path: "/cms/payers/planManagement", name: "Plan Management", component: ProtectedPayerPlanManagementPage },

    { path: "/cms/executions", exact: true, name: "Analysis Executions", component: ProtectedExecutionPage },

    { path: "/pricer/chargeMaster", name: "Charge Master", component: ProtectedChargeMasterPage },
    { path: "/pricer/shoppable", name: "Shoppables", component: ProtectedShoppablePage },
    { path: "/pricer/associated", name: "Associated Charges", component: ProtectedAssociatedPage },
    { path: "/pricer/allowables", name: "Allowables", component: ProtectedAllowablesPage },
    { path: "/pricer/brandconfiguration", name: "Brand Configuration", component: ProtectedBrandConfigurationPage },
    { path: "/pricer/payerInfo", name: "Payer Info", component: ProtectedPricerPayerInfoPage },
    { path: "/pricer/configuration", name: "Pricer Setup", component: ProtectedPricerSetup },

    { path: "/vendor/view/:contractId", name: "View Vendor", component: ProtectedVendorViewPage },
    { path: "/vendor/edit/:contractId", name: "Edit Vendor", component: ProtectedVendorsEditPage },
    { path: "/vendor", name: "Vendors", component: ProtectedVendorsPage },

    { path: "/file-management/uploads", name: "Uploads", component: ProtectedUploadsPage },
    { path: "/file-management/fileExecutions", name: "File Executions", component: ProtectedFileExecution },

    { path: "/unauthorized", name: "Unauthorized", component: UnauthorizedPage },
    { path: "/fallback-page", name: "Fallback Page", component: FallbackPage },

    { path: "/controlRoom/jobStatistics", name: "Job Statistics", component: ProtectedJobStatisticsPage },
    { path: "/controlRoom/s3Listners", name: "S3 Listners", component: ProtectedS3ListnersPage },
    { path: "/controlRoom/qs-dataset", name: "QS Dataset", component: ProtectedQSDataSetPage },

    { path: "/controlRoom/healthCheck", name: "Health Check", component: ProtectedHealthCheckPage },

    { path: "/about/version", name: "Version Updates", component: ProtectedVersionUpdatesPage },

    { path: "/cms/eapg/edit/:id", name: "Edit EAPG", component: ProtectedEAPGEditPage },
    { path: "/cms/eapg", name: "EAPG", component: ProtectedEAPGPage },
  ];

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>

        {isAuthorized && forceResetPW && <Route path="/auth/forced-reset-password" component={ForcedResetPasswordPage} />}
        {isAuthorized && forceResetPW && <Redirect from="/auth" to="/auth/forced-reset-password" />}

        {!isAuthorized || !clientProducts ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Route path="/auth/login" component={PreAuthenticationLogInPage} />
        ) : !eulaAccepted ? (
          <Redirect from="/auth" to="/eula" />
        ) : (
          <Redirect from="/auth" to={userLastLocation} />
        )
        }
        <Route path="/eula" component={ProtectedEulaPage} />
        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        <Route path="/auth/forgot-password" component={PreAuthenticationForgotPasswordPage} />
        <Route path="/auth/reset-password" component={PreAuthenticationResetPasswordPage} />
        <Route path="/auth/forgot-password-confirmation" component={applyPreAuthentication(ForgotPasswordConfirmationPage)} />
        <Route path="/auth/reset-password-confirmation" component={applyPreAuthentication(ResetPasswordConfirmationPage)} />

        {!isAuthorized || forceResetPW ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <>
            <Layout>
              <BreadCrumb routes={routes} />
              <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                  <Redirect exact from="/" to={getBaseRedirection()} />

                  <Redirect exact from="/claimAnalysis" to='/cms/claimAnalysis' />
                  <Redirect exact from="/contract/edit/:contractId" to='/cms/contract/edit/:contractId' />
                  <Redirect exact from="/contract" to='/cms/contract' />
                  <Redirect exact from="/feeschedules" to='/cms/feeschedules' />
                  <Redirect exact from="/facilities" to='/cms/facilities' />
                  <Redirect exact from="/executions" to='/cms/executions' />
                  <Redirect exact from="/cms/settings" to='/configuration-settings' />
                  <Redirect exact from="/cms/branding" to='/configuration-branding' />
                  <Redirect exact from="/cms/feeschedules" to='/cms/feeschedules/root' />


                  {/* All routes goes here */}
                  {routes.map((route, idx) => {
                    return (
                      route.component && (
                        <Route
                          key={idx.toString()}
                          path={route.path}
                          component={route.component}
                        />
                      )
                    )
                  })}

                  <Redirect to="/error/error-v1" />
                </Switch>
              </Suspense>
            </Layout>
          </>
        )}
      </Switch>
      <ToastContainer autoClose={false} />
      <ReactIdleTimer onIdleHandler={onIdleHandler} />
    </LayoutContextProvider>
  );
});



