import React from "react";
import {
    Typography, makeStyles, Grid, Box, Tabs, Tab, AppBar
} from '@material-ui/core';
import { RuleView } from './RuleView';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box py={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => {
    return {
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        appbar: {
            'box-shadow': 'none',
            borderBottom: `1px solid #b2c3be`,
        }
    }
});

export default function ServicesView(props) {
    const { contractedService, contractId, value, updateServiceTab } = props;
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        updateServiceTab(newValue);
    };

    return (
        <>
            <div className={classes.root}>
                {contractedService.length > 0 && (
                    <AppBar color="transparent" className={classes.appbar} position="static">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Rules View Tab"
                            className={classes.tabs}
                        >
                            {contractedService.sort((a, b) => a?.serviceType?.name?.localeCompare(b?.serviceType?.name)).map(({ id, serviceType, rule }, index) => (
                                <Tab key={index} label={serviceType.name} {...a11yProps(index)} />
                            ))}
                        </Tabs>
                    </AppBar>
                )}
                {contractedService.sort((a, b) => a?.serviceType?.name?.localeCompare(b?.serviceType?.name)).map(({ id, serviceType, rule }, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <RuleView
                                            data={rule}
                                            serviceId={id}
                                            serviceTypeObj={serviceType}
                                            contractId={contractId}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                ))}
            </div>
            {contractedService.length <= 0 && (
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Typography variant="subtitle2">No Service found!</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
}