import { createReducer } from '../../util';
import * as actions from '../actions/contractFamily';
import { StatusState } from './common';


const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data:[],

    create: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    changePrecedence: {
        ...initialStatusState,
    },
    activateContract:{
        ...initialStatusState,

    },
    deactivateContract:{
        ...initialStatusState,

    },
    update: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
};

export default createReducer(initialState, {
    [actions.CONTRACTS_FAMILY_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_FAMILY_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.CONTRACTS_FAMILY_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.CONTRACTS_FAMILY_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.CONTRACTS_FAMILY_GET_FULFILLED]: (state, contract) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        data: [contract]
    }),
    [actions.CONTRACTS_FAMILY_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACT_CHANGE_PRECEDENCE_PENDING]: (state) => ({
        ...state,
        changePrecedence: {
            pending: true,
        },
    }),
    [actions.CONTRACT_CHANGE_PRECEDENCE_FULFILLED]: (state) => ({
        ...state,
        changePrecedence: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACT_CHANGE_PRECEDENCE_REJECTED]: (state, errorMessage) => ({
        ...state,
        changePrecedence: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.ACTIVATE_CONTRACT_FAMILY_PENDING]: (state) => ({
        ...state,
        activateContract: {
            pending: true,
        },
    }),
    [actions.ACTIVATE_CONTRACT_FAMILY_FULFILLED]: (state) => ({
        ...state,
        activateContract: {
            ...initialStatusState,
        },
    }),
    [actions.ACTIVATE_CONTRACT_FAMILY_REJECTED]: (state, errorMessage) => ({
        ...state,
        activateContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    

    [actions.DEACTIVATE_CONTRACT_FAMILY_PENDING]: (state) => ({
        ...state,
        deactivateContract: {
            pending: true,
        },
    }),
    [actions.DEACTIVATE_CONTRACT_FAMILY_FULFILLED]: (state) => ({
        ...state,
        deactivateContract: {
            ...initialStatusState,
        },
    }),
    [actions.DEACTIVATE_CONTRACT_FAMILY_REJECTED]: (state, errorMessage) => ({
        ...state,
        deactivateContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    
    [actions.CONTRACT_FAMILY_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.CONTRACT_FAMILY_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.CONTRACT_FAMILY_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.DELETE_CONTRACT_FAMILY_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.DELETE_CONTRACT_FAMILY_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        }
    }),
    [actions.DELETE_CONTRACT_FAMILY_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

});
