import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { injectIntl } from "react-intl";
import { setOpenAdvanceFiltersDialog } from '../../store/actions/pages';
import DataTableHeaderComponent from './DataTableHeader';

export const DataTableHeader = connect(
    () => {


        const selectSignificance = createSelector(
            (state, props) => props.pageName,
            (state) => state.pages,
            (name, pages) => {
                if (name === 'claimAnalysis-landing') {
                    return pages[name].significance;
                }
                return false
            }
        );

        return (state, props) => ({
            significance: selectSignificance(state, props),
            openFiltersPanel: state.pages.open

        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setOpenAdvanceFiltersDialog
        }, dispatch)
    })
)(injectIntl(DataTableHeaderComponent));