import { Button, Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { Card } from 'react-bootstrap'
import { CustomLabel, DataTable, Loadable, TraceDialog } from '../../../common'
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../partials/content/Portlet'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PrintIcon from '@material-ui/icons/Print'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { useReactToPrint } from 'react-to-print'
import { toAbsoluteUrl } from '../../../../_metronic'
import { asDate } from '../../../util/date'
import { AdvancedTrace } from '../../ClaimAnalysisPage/ClaimViewPage/AdvancedTrace'
import { PocketCostDialog } from './PocketCostDialog'

const useStyles = makeStyles(theme => ({
    backButton: {
        color: theme.palette.grey[500],
        marginRight: theme.spacing(1),
    },
}));

function EstimationsResultPage({ estimateById, fields, filteredData, sort, order, estimationId, getEstimatePending, sections,
    history, actions: { setSort, setOrder, getEstimateById } }) {

    const [isAnalysisTraceOpen, setAnalysisTraceOpen] = useState(false);
    const [isAdvancedTraceOpen, setAdvancedTraceOpen] = useState(false);

    const [isPocketCostDialogOpen, setPocketCostDialog] = useState(false);
    const classes = useStyles();
    const componentRef = useRef();

    useEffect(() => {
        getEstimateById(estimationId);
    }, [getEstimateById, estimationId]);

    const hoverData = (estimateById) => {
        const hoverColumns = new Map();
        const ruleColumns = [
            {
                label: "Name",
                data: estimateById?.rule?.name
            }, {
                label: "Type",
                data: estimateById?.rule?.ruleType?.name
            }, {
                label: "Pay Method",
                data: estimateById?.rule?.paymethod?.name
            }, {
                label: "Pay Value",
                data: estimateById?.rule?.payvalue
            }
        ];
        const pxCodeColumns = [
            {
                label: "",
                data: estimateById?.pxCodeDescription
            }
        ];
        const revCodeColumns = [
            {
                label: "",
                data: estimateById?.revCodeDescription
            }
        ];

        hoverColumns.set('rule', ruleColumns);
        hoverColumns.set('pxCode', pxCodeColumns);
        hoverColumns.set('revCode', revCodeColumns);

        return hoverColumns;
    }

    function handleTraceOpen() {
        if (sections && sections.length > 0) {
            setAdvancedTraceOpen(true);
        } else {
            setAnalysisTraceOpen(true);
        }
    }
    function handleTraceClose() {
        setAnalysisTraceOpen(false);
    }

    function handlePocketCostOpen() {
        setPocketCostDialog(true);
    }
    function handlePocketCostClose() {
        setPocketCostDialog(false);
    }

    function handleStyleForPrint() {
        document.getElementById('logo-img-print').style.display = 'block';
        document.getElementById('results-div').classList.add('mw-100');
        document.getElementById('card-1').classList.add('mx-5');
        document.getElementById('card-2').classList.add('mx-5');
        handlePrint();
        document.getElementById('logo-img-print').style.display = 'none';
        document.getElementById('results-div').classList.remove('mw-100');
        document.getElementById('card-1').classList.remove('mx-5');
        document.getElementById('card-2').classList.remove('mx-5');
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleRedirectToEdit = () => {
        history.push(`/estimator/history/edit/${estimateById?.estimationId}`)
    }

    return (
        <>
            <Loadable loading={getEstimatePending}>
                <Portlet>
                    <PortletHeader
                        toolbar={
                            <PortletHeaderToolbar className='w-100'>
                                <Grid container alignItems="center">
                                    <Grid xs={6}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <Tooltip title="Go back to Reestimate">
                                                    <IconButton aria-label="back" className={classes.backButton} onClick={handleRedirectToEdit}>
                                                        <ArrowBackIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid md={2}>
                                                <Typography className="text-dark" variant="h6">Estimator</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={6}>
                                        <Grid container className='justify-content-end'>
                                            <Button onClick={handlePocketCostOpen}
                                                variant='outlined'
                                                color="primary">
                                                Out of Pocket Cost
                                            </Button>
                                            <Button onClick={handleTraceOpen}
                                                className='ml-2'
                                                variant='outlined'
                                                color="primary">
                                                Analysis Trace
                                            </Button>
                                            <Button
                                                onClick={handleStyleForPrint}
                                                className='ml-2'
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<PrintIcon />}
                                            >
                                                Print
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody>
                        <Grid xs={10} id='results-div' ref={componentRef}>
                            <div id='logo-img-print' style={{ display: 'none' }} className="kt-login__logo text-center my-5">
                                <a href="/#">
                                    <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-light.v3.png?v=4")} />
                                </a>
                            </div>
                            <Card className='mt-4' id='card-1'>
                                <Card.Header as="h5">Claim Estimation</Card.Header>
                                <Card.Body>
                                    <Grid container md={12} className='px-3 py-2'>
                                        <Grid item xs={5} className='py-1'>
                                            <CustomLabel label="Estimation Id" data={estimateById?.estimationId} />
                                        </Grid>
                                        <Grid item xs={5} className='py-1'>
                                            <CustomLabel label="Patient Name" data={`${estimateById?.patientFName} ${estimateById?.patientLName}`} />
                                        </Grid>
                                        <Grid item xs={5} className='py-1'>
                                            <CustomLabel label="Contract" data={estimateById?.contract?.name} />
                                        </Grid>
                                        <Grid item xs={5} className='py-1'>
                                            <CustomLabel label="Claim Type" data={estimateById?.claimType} />
                                        </Grid>
                                        <Grid item xs={5} className='py-1'>
                                            <CustomLabel label="Total Claim Amount" data={`$${estimateById?.claimAmount?.toFixed(2)}`} />
                                        </Grid>
                                        <Grid item xs={5} className='py-1'>
                                            <CustomLabel label="Allowable" data={`$${estimateById?.estimatedClaimAmount?.toFixed(2)}`} />
                                        </Grid>
                                        <Grid item xs={5} className='py-1'>
                                            <CustomLabel label="Service Date" data={asDate(estimateById?.admitionDate)} />
                                        </Grid>
                                        {Boolean(estimateById?.copay !== null && estimateById?.remainingDeductible !== null && estimateById?.coInsurancePercent !== null && estimateById?.maxOutOfPocket) &&
                                            <Grid item xs={5} className='py-1'>
                                                <CustomLabel label="Out of Pocket" data={`$${estimateById?.outOfPocketAmount?.toFixed(2) ?? 'N/A'}`} />
                                            </Grid>
                                        }
                                    </Grid>
                                </Card.Body>
                            </Card>
                            <Card className='mt-4' id='card-2'>
                                <Card.Header as="h5">Charge Estimation</Card.Header>
                                <Card.Body>
                                    <DataTable
                                        fields={fields}
                                        data={filteredData}
                                        order={order}
                                        onOrder={setOrder}
                                        sort={sort}
                                        onSort={setSort}
                                        displayTotal={true}
                                        hoverData={hoverData}
                                    />
                                </Card.Body>
                            </Card>
                        </Grid>
                    </PortletBody>
                    <AdvancedTrace sections={sections} isTraceOpen={isAdvancedTraceOpen} setTraceOpen={setAdvancedTraceOpen} title={estimateById?.estimationId} />
                </Portlet>
                <TraceDialog open={isAnalysisTraceOpen} handleClose={handleTraceClose} traces={estimateById?.analysisTraces} />
                <PocketCostDialog open={isPocketCostDialogOpen} handleClose={handlePocketCostClose} estimationId={estimateById?.estimationId} claimAmount={estimateById?.estimatedClaimAmount} />
            </Loadable>
        </>
    )
}

export default withRouter(EstimationsResultPage);