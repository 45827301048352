import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { MrfDialog } from './MrfDialog';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    button: {
        color: '#FFF',
        '&:hover': {
            color: '#FFF'
        }
    },
}));

function PublishMRF(props) {
    const { isSuperAdmin, publishMRFloading, downloadMRFloading, mrfs, getNegotiatedRatesSignOffPending, 
        signOff, clientBrand, 
        actions: { publishMRFs, getNegotiatedRatesSignOff, exportCsv, getBrand } } = props;

    const classes = useStyles();

    
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [mrfDialogData, setMrfDialogData] = useState([]);


    useEffect(() => {
        getBrand();
    }, [getBrand]);

    useEffect(() => {
        getNegotiatedRatesSignOff();
    }, [getNegotiatedRatesSignOff]);

    function handlePublishMRFs() {
        publishMRFs(handleCallback);
    }
    function handleCallback() {
        getNegotiatedRatesSignOff();
        setDialogOpen(true);
    }
    function handleDialogClose() {
        setDialogOpen(false);
    }
    function handleDownloadMRFs() {
        exportCsv(handleDownloadMRFsCallback);
    }
    function handleDownloadMRFsCallback(blob, mrfHeaders, filename) {
        if(blob.size !== 0) {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            if(blob.type === 'application/zip') {
                a.download = `Files.zip`;
            }
            else {
                a.download = filename;
            }
            a.click();
        }
        // in case parsing error occurs
        try
        {        
            setMrfDialogData(JSON.parse(mrfHeaders))
        } 
        catch(e)
        {
        }   

    }

    return (
        <>
            {isSuperAdmin &&
                <Grid container className='justify-content-end'>
                    <grid item xs={2} className='text-right'>
                        <Tooltip title='Publish latest MRFs to update direct download links'>
                            <Button color='primary' disabled={publishMRFloading} variant='outlined' onClick={handlePublishMRFs}>
                                Publish Files
                                {publishMRFloading &&
                                    <CircularProgress className='ml-2' color='primary' size={15} />
                                }
                            </Button>
                            
                        </Tooltip>
                        <Tooltip title='Download latest published MRFs'>
                            <Button className='ml-2' color='primary' disabled={downloadMRFloading} variant='outlined' onClick={handleDownloadMRFs}>
                                Download Files
                                {downloadMRFloading &&
                                    <CircularProgress className='ml-2' color='primary' size={15} />
                                }
                            </Button>
                        </Tooltip>
                    </grid>
                </Grid>
            }
            <Dialog maxWidth='sm' fullWidth open={(isDialogOpen && !getNegotiatedRatesSignOffPending)}>
                <DialogTitle className='py-3'>
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                MRFs
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="close" onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid>
                        {mrfs.map(item => <Grid spacing={2} container className='pt-1'>
                            <Grid item xs={4}>
                                <b>{item.file}</b>
                            </Grid>
                            <Grid item xs={8}>
                                {item.status}
                            </Grid>
                        </Grid>
                        )}
                        {(signOff && signOff.signedOff === 'false') && (
                            <>
                                <hr />
                                <Grid>
                                    <Typography>A sign-off is required prior to publishing the MRF updates.</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose} color="default">
                        Close
                    </Button>
                    {(signOff && signOff.signedOff === 'false') && (
                        <Button className={classes.button} component={Link} to="/pricer/brandconfiguration" variant="contained" color="primary">
                            Go to Sign off
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <MrfDialog open={mrfDialogData?.length > 0} data={mrfDialogData} handleCancel={()=>setMrfDialogData([])}/>


        </>
    )
}

export default PublishMRF
