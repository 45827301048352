import { takeEvery, put, call, select } from 'redux-saga/effects';
import * as actions from '../actions/facilities';
import * as API from '../api/facilities';
import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.FACILITIES_LIST_PENDING });
        const payload = yield call(API.getFacilities);
        yield put({ type: actions.FACILITIES_LIST_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.FACILITIES_GET_PENDING });
        const { facilityId, callback } = action.payload;
        const payload = yield call(API.getFacility, facilityId);
        yield put({ type: actions.FACILITIES_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.FACILITIES_CREATE_PENDING });
        const {
            facility,
            callback,
            shouldReloadGrid,
        } = action.payload;

        const payload = {
            ...facility,
        }
        const newFacility = yield call(API.createFacility, payload);
        yield put({ type: actions.FACILITIES_CREATE_FULFILLED, newFacility });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.FACILITIES_UPDATE_PENDING });
        const {
            facility,
            callback,
            shouldReloadGrid,
        } = action.payload;

        const newFacility = yield call(API.updateFacility, facility);
        yield put({ type: actions.FACILITIES_UPDATE_FULFILLED, newFacility });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteFacility(action) {
    try {
        yield put({ type: actions.FACILITIES_DELETE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deleteFacility, id);
        yield put({ type: actions.FACILITIES_DELETE_FULFILLED, payload: { id } });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFacilitiesFromTargetClient(action) {
    try {
        const { targetClient } = action.payload;
        yield put({ type: actions.FACILITIES_BY_TARGET_CLIENT_PENDING });
        const payload = yield call(API.getFacilitiesFromTargetClient, targetClient);
        yield put({ type: actions.FACILITIES_BY_TARGET_CLIENT_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_BY_TARGET_CLIENT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFacilityDependencies(action) {
    try {
        const { facilityId } = action.payload;
        yield put({ type: actions.FACILITIES_GET_DEPENDENCIES_PENDING });
        const payload = yield call(API.getFacilityDependencies, facilityId);
        yield put({ type: actions.FACILITIES_GET_DEPENDENCIES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_GET_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* activateFacility(action) {
    try {
        const { facilityId, callback } = action.payload;
        yield put({ type: actions.FACILITIES_ACTIVATE_PENDING });
        const payload = yield call(API.activateFacility, facilityId);
        yield put({ type: actions.FACILITIES_ACTIVATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_ACTIVATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deactivateFacility(action) {
    try {
        const { facilityId, callback } = action.payload;
        yield put({ type: actions.FACILITIES_DEACTIVATE_PENDING });
        const payload = yield call(API.deactivateFacility, facilityId);
        yield put({ type: actions.FACILITIES_DEACTIVATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FACILITIES_DEACTIVATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.FACILITIES_RESET });
}

export default function* () {
    yield takeEvery(actions.FACILITIES_LIST, list);
    yield takeEvery(actions.FACILITIES_CREATE, create);
    yield takeEvery(actions.FACILITIES_UPDATE, update);
    yield takeEvery(actions.FACILITIES_GET, get);
    yield takeEvery(actions.FACILITIES_DELETE, deleteFacility);
    yield takeEvery(actions.FACILITIES_BY_TARGET_CLIENT, getFacilitiesFromTargetClient);
    yield takeEvery(actions.FACILITIES_ACTIVATE, activateFacility);
    yield takeEvery(actions.FACILITIES_DEACTIVATE, deactivateFacility);
    yield takeEvery(actions.FACILITIES_GET_DEPENDENCIES, getFacilityDependencies)
}