import { createReducer } from '../../util';
import * as actions from '../actions/uploads';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
    innerException: '',
    errorDetails: ''
};

const initialState = {
    uploadFiles: {
        ...initialStatusState
    },
    downloadTemplate: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {
    [actions.UPLOAD_FILES_PENDING]: (state) => ({
        ...state,
        uploadFiles: {
            pending: true,
        },
    }),
    [actions.UPLOAD_FILES_FULFILLED]: (state) => ({
        ...state,
        uploadFiles: {
            ...initialStatusState,
        },
    }),
    [actions.UPLOAD_FILES_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        uploadFiles: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),
    [actions.UPLOAD_DOWNLOAD_TEMPLATE_PENDING]: (state) => ({
        ...state,
        downloadTemplate: {
            pending: true,
        },
    }),
    [actions.UPLOAD_DOWNLOAD_TEMPLATE_FULFILLED]: (state) => ({
        ...state,
        downloadTemplate: {
            ...initialStatusState,
        },
    }),
    [actions.UPLOAD_DOWNLOAD_TEMPLATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        downloadTemplate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});
