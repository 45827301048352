import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { createEditReset, setDependencyDialog } from '../../store/actions/opps';
import { oppsLanding } from '../../store/actions/pages';
import { dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import { createNumberDataField, createPlainDateField, createStringDataField } from '../../util/format';
import OPPSPageComponent from './OPPSPage';

const selectFields = createSelector(
    () => [

        createStringDataField('name', 'Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createNumberDataField('wageIndex', 'Wage Index', { sortable: true, equators: integerEquators }),
        createNumberDataField('outpatientCostToChargeRatio', 'Outpatient Cost to Charge Ratio', { sortable: true, equators: integerEquators }),
        createNumberDataField('implantCostToChargeRatio', 'Implant Cost to Charge Ratio', { sortable: true, equators: integerEquators }),
        createNumberDataField('outlierMultiple', 'Outlier Multiple', { sortable: true, equators: integerEquators }),
        createNumberDataField('opFixedDollarThreshold', 'OP Fixed Dollar Threshold', { sortable: true, equators: integerEquators }),
        createPlainDateField('effectiveFrom', 'Effective From', { sortable: true, equators: dateEquators }),
        createPlainDateField('effectiveTill', 'Effective To', { sortable: true, equators: dateEquators }),

    ]
);

const selectFilteredData = createSelector(
    (state) => state.pages['opps-landing'].list,
    (data) => {
        return data
    }
);

export const OPPSPage = injectIntl(
    connect(
        (state, props) => ({
            data: selectFilteredData(state),
            fields: selectFields(state as never),
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                oppsLanding: oppsLanding,
                createEditReset: createEditReset,
                setDependencyDialog: setDependencyDialog
            }, dispatch)
        })
    )(OPPSPageComponent));