import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { createEditReset, setDependencyDialog } from '../../store/actions/eapg';
import { eapgLanding } from '../../store/actions/pages';
import { dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import { createCurrencyDataField, createPlainDateField, createStringDataField } from '../../util/format';
import EAPGPageComponent from './EAPGPage';

const selectFields = createSelector(
    () => [

        createStringDataField('name', 'Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createCurrencyDataField('significantProcedureRate', 'Significant Procedure Rate', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('ancillaryRate', 'Ancillary Rate', { sortable: true, equators: integerEquators }),
        createPlainDateField('effectiveFrom', 'Effective From', { sortable: true, equators: dateEquators }),
        createPlainDateField('effectiveTill', 'Effective To', { sortable: true, equators: dateEquators }),

    ]
);

const selectFilteredData = createSelector(
    (state) => state.pages['eapg-landing'].list,
    (data) => {
        return data
    }
);

export const EAPGPage = injectIntl(
    connect(
        (state, props) => ({
            data: selectFilteredData(state),
            fields: selectFields(state as never),
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                eapgLanding: eapgLanding,
                createEditReset: createEditReset,
                setDependencyDialog: setDependencyDialog
            }, dispatch)
        })
    )(EAPGPageComponent));