import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import {
    createStringDataField, createTimestampField, createNumberDataField
} from '../../../util/format';
import { StringEquators, dateEquators } from '../../../store/constants/pages';
import QSDataSetPageComponent from './QSDataSetPage';
import { getQuickSightsDataSetList, quickSightsDataSetListReset } from '../../../store/actions/controlRoom';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import { set } from '../../../store/actions/pages';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    () => {
        return [
            createStringDataField('name', 'Dataset name', { sortable: true, equators: StringEquators }),
            createNumberDataField('size', 'Size(GB)', { sortable: true }),
            createStringDataField('storage', 'Storage', { sortable: true, equators: StringEquators }),
            createTimestampField('createdAt', 'Created At', { sortable: true, equators: dateEquators }),
            createTimestampField('updatedAt', 'Updated At', { sortable: true, equators: dateEquators }),
        ]
    }
);

export const selectQSDataset = (state) => {
    return state.controlRoom.dataSetList.map(item => ({ ...item, size: item.size ?? 0 }));
};

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`qs-dataset-landing`] ? state.pages[`qs-dataset-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`qs-dataset-landing`] ? state.pages[`qs-dataset-landing`].filter : null,
    selectSort,
    selectQSDataset,
);
export const QSDataSetPage = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            dataSetList: selectFilteredData(state),
            order: state.pages['qs-dataset-landing'] ? state.pages['qs-dataset-landing'].order : null,
            filter: state.pages['qs-dataset-landing'] ? state.pages['qs-dataset-landing'].filter : null,
            sort: selectSort(state, props),

            getDataSetPending: state.controlRoom.getQuickSightsDataSetList.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getQuickSightsDataSetList: getQuickSightsDataSetList,
            setFilter: (value) => set(`qs-dataset-landing`, 'filter', value),
            setOrder: (value) => set(`qs-dataset-landing`, 'order', value),
            setSort: (value) => set(`qs-dataset-landing`, 'sort', value),
            quickSightsDataSetListReset: quickSightsDataSetListReset
        }, dispatch)
    })
)(injectIntl(QSDataSetPageComponent));