import React, { useState, useEffect } from 'react';

import {
    makeStyles,
    TextField,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    column: {
        flexBasis: '25%',
        margin: theme.spacing(2),
    },
    textField: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width: 200,
    },
}));

export default function TextFieldControl(props) {
    const classes = useStyles();
    const {
        name, setFilter, displayName, dataValue,
    } = props;

    const [value, setValue] = useState();

    useEffect(() => {
        setValue(dataValue);
    }, [dataValue])

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleBlur = () => {
        setFilter(name, value);
    }

    return (
        <div className="column">
            <TextField
                type="text"
                label={displayName}
                name={name}
                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                value={value}
                onBlur={handleBlur}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );
}