import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { set } from '../../../store/actions/pages';
import FeeScheduleSelectionComponent from './FeeScheduleSelectionComponent';

import { createFilteredSortedDataSelector } from '../../../util/selector';


import { createStringDataField } from '../../../util/format';

import { selectFeeScheduleLanding } from '../../../store/selectors/contracts';


import { feeScheduleSearch, searchFeeScheduleFromClient } from '../../../store/actions/feeScheduleV2';


const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

export const selectFeeSchedules = createSelector(
    selectFeeScheduleLanding,
    (feeSchedules) => feeSchedules,
);

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Schedule Name', { sortable: true }),
        createStringDataField('path', 'Path'),

    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['feeSchedules-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['feeSchedules-landing'].filter,
    selectSort,
    selectFeeSchedules,
);



export const FeeScheduleSelection = connect(
    () => {

        const pageName = 'fee-schedule-selection-landing';

        const selectData = (state) => state.feeScheduleV2.feeScheduleSearch;
        const selectClientData = (state) =>   state.feeScheduleV2.feeScheduleClientSearch;


        return (state, props) => ({
            data: selectData(state),
            clientData:selectClientData(state),
            pageName: pageName,
            pending: state.feeScheduleV2.getFeeScheduleSearch.pending,
            pendingTargetClient: state.feeScheduleV2.getFeeScheduleSearchFromClient.pending,
            fields: selectFields(state as never),
            filteredData: selectFilteredData(state),
            order: state.pages['feeSchedules-landing'].order,
            filter: state.pages['feeSchedules-landing'].filter,
            setOrder: (value) => set('feeSchedules-landing', 'order', value),
            setSort: (value) => set('feeSchedules-landing', 'sort', value),


            sort: selectSort(state),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            feeScheduleSearch: feeScheduleSearch,
            feeScheduleSearchFromClient:searchFeeScheduleFromClient
        }, dispatch)
    })
)(injectIntl(FeeScheduleSelectionComponent));