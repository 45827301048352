// import { createSelector } from "reselect";

import { createSelector } from "reselect";
import { getEndDate, getLast12Months, getStartDate } from "../../util/date";
import { DEFAULT_CONTRACT_STATE_ID, DEFAULT_VARIANCE_STATUS_ID, SEARCH_BY_ID, SEARCH_PERIOD_ID } from "../constants/settings";
import { RootState } from "../reducers";
import { selectSettings } from "./settings";

export const selectClaimAnalysis = (state : RootState) => state.claimAnalysis.detail;
export const selectClaimAnalysisStats = (state : RootState) => state.claimAnalysis.stats;
export const selectClaimDrilldown = (state: RootState) => state.claimAnalysis.drilldown;
export const selectClaimCount = (state : RootState) => state.claimAnalysis.count;
export const selectExecutedId = (state : RootState ) => state.claimAnalysis.executionId;
export const selectProcessedClaims = (state : RootState) => state.claimAnalysis.processBulkOnDemandResponse;

export const selectArchivedClaim = (state: RootState) => state.claimAnalysis.archivedClaim;

export const selectClaimHistory = (state: RootState, props: object) => state.claimAnalysis.historyData;

export const selectAdjustment = (state: RootState) => state.claimAnalysis.adjustment;
export const selectServiceAdjustments = (state: RootState) => state.claimAnalysis.serviceAdjustments;
export const selectClaimServiceAdjustments = (state: RootState) => state.claimAnalysis.claimServiceAdjustments;
export const selectClaimAdjustments = (state: RootState) => state.claimAnalysis.claimAdjustments;
export const selectClaimAdjustmentsData = (state: RootState) => state.claimAnalysis.claimServiceAdjustmentsData;
export const selectClaimAllDetails = (state: RootState) => state.claimAnalysis.claimAllDetails;
// default custom filter manipulation
export const selectDefaultCustomFilters = createSelector(
    selectSettings,
    (state) => state.claimAnalysis.defaultCustomFilters,
    (settings, defaultCustomFilters) => {
        // find "search by" setting i.e. remit date or service date
        const searchBy = settings.find(setting => setting.id === SEARCH_BY_ID);
        // find "search period" setting i.e. tenure 6,9,12 months
        const searchPeriod = settings.find(setting => setting.id === SEARCH_PERIOD_ID);
        const defaultVariance = settings.find(setting => setting.id === DEFAULT_VARIANCE_STATUS_ID);
        const defaultContractState = settings.find(setting => setting.id === DEFAULT_CONTRACT_STATE_ID);

        let cf = [...defaultCustomFilters];

        if (defaultVariance?.value.toLowerCase() !== 'all') {
            let temp : string | boolean = 'both';
            if (defaultVariance?.value.toLowerCase() === 'denials') {
                temp = true;
            } else if (defaultVariance?.value.toLowerCase() === 'underpayments') {
                temp = false;
            }
            cf = [...cf, {
                field: 'isDenial',
                equator: 'equals',
                value: temp,
            }];
        }

        if (defaultContractState?.value.toLowerCase() !== 'all') {
            cf = [...cf, {
                field: 'contract.contractState.name',
                equator: 'equals',
                value: defaultContractState?.value.toLowerCase(),
            }];
        }

        if (searchBy && searchPeriod) {
            return [...cf, {
                field: searchBy.value,
                equator: 'greater_than_or_equals_to',
                value: getStartDate(searchPeriod.value),
                relativeFilter: searchPeriod.value
            },
            {
                field: searchBy.value,
                equator: 'less_than_or_equals_to',
                value: getEndDate(searchPeriod.value),
                relativeFilter: searchPeriod.value
            }]
        }
        return cf
    });

export const selectClaimDrillDownStats = createSelector(
    (state) => state.claimAnalysis.detail,
    (data) => {
        if (data && data.length > 0) {
            return data.reduce((result, claimAnalysis) => {
                return {
                    chargedAmount: result.chargedAmount + claimAnalysis.chargedAmount,
                    expectedPay: result.expectedPay + claimAnalysis.expectedPay,
                    actualPay: result.actualPay + claimAnalysis.actualPay,
                    variance: result.variance + claimAnalysis.variance,
                };
            });
        } else {
            return {
                chargedAmount: 0,
                expectedPay: 0,
                actualPay: 0,
                variance: 0,
            };
        }
    }
);

export const selectClaimJson = (state) => {
    return {
        "claimJson": state.claimAnalysis.jsonView
    }
}