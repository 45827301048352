import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/modeling';
import * as pagesActions from '../actions/pages';
import * as API from '../api/modeling';
import { sessionErrorHandling } from './session';

function* searchClaimsForModeling(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.MODEL_SEARCH_CLAIMS_PENDING });
        const payload = yield call(API.searchClaimsForModeling, options);
        yield put({ type: pagesActions.MODEL_CLAIM_SELECTION_LANDING_INIT_FULFILLED, payload });
        yield put({ type: actions.MODEL_SEARCH_CLAIMS_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_SEARCH_CLAIMS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* modelEstimate(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.MODEL_ESTIMATE_PENDING });
        const payload = yield call(API.contractModelingEstimate, options);
        // let payload = { "id": 9, "executionId": "aa87972f-6094-46fa-a8ed-fecc3fb2155b", "modelId": 6, "batchId": 1, "executionType": "MEMORY", "state": "SUBMITTED", "totalClaims": 1, "processedClaims": 0, "criteria": "{\"Name\":\"Test Model 3\",\"ContractIds\":[31,72],\"SearchRequestType\":2,\"SearchParameters\":null,\"InputRequest\":null,\"ClaimNumbers\":[\"495297V11284\"]}", "executedBy": "faizan_admin", "executedAt": "2023-05-24 10:39:48", "exception": null, "taskArn": null, "model": { "id": 6, "name": "Test Model 3", "updatedBy": "faizan_admin", "updatedAt": "2023-05-24 10:39:48", "locked": true, "owner": "faizan_admin", "active": true, "contractModelXref": [{ "id": 17, "modelId": 6, "contractId": 31, "contract": null }, { "id": 18, "modelId": 6, "contractId": 72, "contract": null }], "modelAnalysisDetails": [], "modelAnalysisExecution": [] }, "modelAnalysisDetails": [] }
        yield put({ type: actions.MODEL_ESTIMATE_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_ESTIMATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* analyzeModelEstimate(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.MODEL_ANALYZE_ESTIMATE_PENDING });
        const payload = yield call(API.analyzeContractModelingEstimate, options);
        yield put({ type: actions.MODEL_ANALYZE_ESTIMATE_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_ANALYZE_ESTIMATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* lockModel(action) {
    try {
        const { modelId, callback } = action.payload;
        yield put({ type: actions.MODEL_LOCK_PENDING });
        const payload = yield call(API.lockModel, modelId);
        yield put({ type: actions.MODEL_LOCK_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_LOCK_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* unlockModel(action) {
    try {
        const { modelId, callback } = action.payload;
        yield put({ type: actions.MODEL_UNLOCK_PENDING });
        const payload = yield call(API.unlockModel, modelId);
        yield put({ type: actions.MODEL_UNLOCK_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_UNLOCK_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* analysisResultsLanding(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.MODEL_ANALYSIS_LANDING_PENDING });
        const payload = yield call(API.analysisResultsLanding, options);
        yield put({ type: actions.MODEL_ANALYSIS_LANDING_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_ANALYSIS_LANDING_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getModelById(action) {
    try {
        const { modelId } = action.payload;
        yield put({ type: actions.MODEL_GET_BY_ID_PENDING });
        const payload = yield call(API.getModelById, modelId);
        yield put({ type: actions.MODEL_GET_BY_ID_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_GET_BY_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getModelExecutionResultsStats(action) {
    const { pagination, sort, filter, modelId } = action.payload;
    try {
        yield put({ type: actions.MODEL_EXECUTION_RESULTS_STATISTICS_PENDING });
        let options = {
            page: {
                ...pagination,
            },
            filter: filter || null,
            sort: sort || [],
        };

        const payload = yield call(API.getExecutionResultsStats, options, modelId);
        yield put({ type: actions.MODEL_EXECUTION_RESULTS_STATISTICS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_EXECUTION_RESULTS_STATISTICS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* stopExecution(action) {
    try {
        const { executionId,callback } = action.payload;
        yield put({ type: actions.STOP_EXECUTION_MODELING_PENDING });
        const payload = yield call(API.stopExecutionModeling, executionId);
        yield put({ type: actions.STOP_EXECUTION_MODELING_FULFILLED, payload });
        if (callback) {
            callback();
        }

    } catch (error) {
        const { error: errorMessage, innerException, errorDetails } = (error && error.payload) || { error: '', errorDetails: '', innerException: '' };

        yield put({ type: actions.STOP_EXECUTION_MODELING_REJECTED, payload: { errorMessage, innerException, errorDetails } });
        yield call(sessionErrorHandling, error);
    }
    
}

function* getModelAllDetails(action) {
    try {
        const { analysisIds } = action.payload;
        yield put({ type: actions.MODEL_GET_MODEL_ALL_DETAILS_PENDING });
        const payload = yield call(API.getModelAllDetails, analysisIds);
        yield put({ type: actions.MODEL_GET_MODEL_ALL_DETAILS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_GET_MODEL_ALL_DETAILS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getModelConsolidatedClaims(action) {
    try {
        const { analysisIds } = action.payload;
        yield put({ type: actions.MODEL_GET_CONSOLIDATED_CLAIMS_PENDING });
        const payload = yield call(API.getModelConsolidatedClaims, analysisIds);
        yield put({ type: actions.MODEL_GET_CONSOLIDATED_CLAIMS_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODEL_GET_CONSOLIDATED_CLAIMS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.MODEL_SEARCH_CLAIMS, searchClaimsForModeling);
    yield takeEvery(actions.MODEL_ESTIMATE, modelEstimate);
    yield takeEvery(actions.MODEL_LOCK, lockModel);
    yield takeEvery(actions.MODEL_UNLOCK, unlockModel);
    yield takeEvery(actions.MODEL_ANALYSIS_LANDING, analysisResultsLanding);
    yield takeEvery(actions.MODEL_GET_BY_ID, getModelById);
    yield takeEvery(actions.MODEL_ANALYZE_ESTIMATE, analyzeModelEstimate);
    yield takeEvery(actions.MODEL_EXECUTION_RESULTS_STATISTICS, getModelExecutionResultsStats);
    yield takeEvery(actions.STOP_EXECUTION_MODELING, stopExecution);
    yield takeEvery(actions.MODEL_GET_MODEL_ALL_DETAILS, getModelAllDetails);
    yield takeEvery(actions.MODEL_GET_CONSOLIDATED_CLAIMS, getModelConsolidatedClaims);

}
