export const SETTINGS_LIST = 'SETTINGS_LIST';
export const SETTINGS_LIST_PENDING = 'SETTINGS_LIST_PENDING';
export const SETTINGS_LIST_FULFILLED = 'SETTINGS_LIST_FULFILLED';
export const SETTINGS_LIST_REJECTED = 'SETTINGS_LIST_REJECTED';

export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';
export const SETTINGS_UPDATE_PENDING = 'SETTINGS_UPDATE_PENDING';
export const SETTINGS_UPDATE_FULFILLED = 'SETTINGS_UPDATE_FULFILLED';
export const SETTINGS_UPDATE_REJECTED = 'SETTINGS_UPDATE_REJECTED';

export const RELEASE_VERSION = 'RELEASE_VERSION';
export const RELEASE_VERSION_PENDING = 'RELEASE_VERSION_PENDING';
export const RELEASE_VERSION_FULFILLED = 'RELEASE_VERSION_FULFILLED';
export const RELEASE_VERSION_REJECTED = 'RELEASE_VERSION_REJECTED';

export const RELEASE_NOTES = 'RELEASE_NOTES';
export const RELEASE_NOTES_PENDING = 'RELEASE_NOTES_PENDING';
export const RELEASE_NOTES_FULFILLED = 'RELEASE_NOTES_FULFILLED';
export const RELEASE_NOTES_REJECTED = 'RELEASE_NOTES_REJECTED';

export const list = (callback) => ({
    type: SETTINGS_LIST,
    payload: { callback },
});

export const update = (id, value, parentId, callback) => ({
    type: SETTINGS_UPDATE,
    payload: { id, value, parentId, callback },
});

export const getReleaseVersion = () => ({
    type: RELEASE_VERSION,
});

export const getReleaseNotes = () => ({
    type: RELEASE_NOTES,
});