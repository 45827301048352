import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import {
    Portlet, PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";

import {
    makeStyles, Grid, IconButton, Tooltip
} from "@material-ui/core";
import { PagedTable } from '../../common';
// import { EstimateHistoryDetails } from './estimateHistoryDetailsPage';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';

const DEFAULT_SORT = [
    { id: 'id', direction: 'asc' }
];

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function EstimateHistoryPage(props) {
    const classes = useStyles();
    const [refresh, setRefresh] = useState(Promise.resolve());

    const {
        fields,
        historyData,
        history,
        actions: {
            estimatorHistoryLanding,
            searchGetByName,
            searchReset,
            resetPagedTable,
            getTinyContracts,
            setEstimatorHistoryDetailsDialog,
            resetCriteriaWaiting
        }
    } = props;

    useEffect(() => {
        getTinyContracts();
    }, [getTinyContracts]);

    useEffect(() => {
        const pageName = {
            name: 'estimator-history-landing'
        }
        resetCriteriaWaiting(pageName.name)

        return function cleanup() {
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    function handleLoad(sort, filter, pagination) {
        estimatorHistoryLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function historyActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="View"
                onClick={() => 
                    props.history.push(`/estimator/history/view/${rowData?.id}`)

                }
            >
                <Tooltip title="View Details">
                    <PageviewIcon />
                </Tooltip>
            </IconButton>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="View"
                onClick={() => handleEditEstimate(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
        </div>
    }

    function handleEditEstimate(rowData) {
        history.push(`/estimator/history/edit/${rowData.id}`)
    }

    function handleAddEstimate() {
        history.push('/estimator/history/edit/new');
    }

    function handleDetailsOpen(rowData) {
        setEstimatorHistoryDetailsDialog({ open: true, result: rowData });
    }

    return (
        <>
            <Helmet title="Estimation History" />
            <Portlet>
                <PortletHeader
                    title="Estimation History"
                    name="estimator-history-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                />
                <PortletBody>
                    <div className={classes.container}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="estimator-history-landing"
                                    title="Estimation"
                                    fields={fields}
                                    data={historyData}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    criteriasSaveEnabled={true}
                                    onAdd={handleAddEstimate}

                                    hasActions={true}
                                    renderActionColumn={historyActions}
                                    showSearchFilters={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
            {/* <EstimateHistoryDetails /> */}
        </>
    );
}