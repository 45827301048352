import { apiHandleOctetResponseWithFileName, apiHandleResponse } from '../../util/api';
import {
    METHOD_GET,
    METHOD_POST,
    SERVER_URL
} from '../constants/api';
import {
    headersAuthReceiveJson,
    headersAuthSendReceiveJson
} from '../headers';

const getShoppables = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/shoppables`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getShoppablesList = (options) => {
    const facility = options.facility || '';
    delete options.facility;
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/shoppable/list/${facility}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updateShoppableStatus = (id, enabled) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/status`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify({ id, enabled }),
    }).then(apiHandleResponse);
};

const selectDeselectAll = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/shoppable/status`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload)
    }).then(apiHandleResponse);
};

const getCounts = (facilityId) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/charge/shoppable/svc/count/${facilityId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const exportShoppables = (options) => {
    return fetch(`${SERVER_URL}/api/v2/export/shoppables`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleOctetResponseWithFileName);
};

export {
    exportShoppables, getCounts, getShoppables, selectDeselectAll, updateShoppableStatus
};
