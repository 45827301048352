import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { deleteFacility, getFacilityDependencies, resetFacilityDependency, setFacilityDependencyDialog } from '../../../store/actions/facilities';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.facilities.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectFacilityId = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.facilityId) {
                    return claimDependencyDialog?.facilityId
                }
                return null
            }
        );

        const selectFacilityName = createSelector(
            selectDependencyDialog,
            (claimDependencyDialog) => {
                if (claimDependencyDialog && claimDependencyDialog?.name) {
                    return claimDependencyDialog?.name
                }
                return ''
            }
        );



        return (state, props) => ({
            open: selectOpen(state),
            facilityId: selectFacilityId(state, props),
            facilityDependencies: state.facilities.facilityDependencies?.records ?? [],
            canDelete: state.facilities.facilityDependencies?.canDelete ?? false,
            facilityDependencyPending: state.facilities.getFacilityDependencies.pending,
            facilityDeletePending: state.facilities.delete.pending,
            facilityName: selectFacilityName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setFacilityDependencyDialog: setFacilityDependencyDialog,
            getFacilityDependencies: getFacilityDependencies,
            deleteFacility: deleteFacility,
            resetFacilityDependency: resetFacilityDependency
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));