export const CODE_SERVICES_LIST = 'CODE_SERVICES_LIST';
export const CODE_SERVICES_LIST_PENDING = 'CODE_SERVICES_LIST_PENDING';
export const CODE_SERVICES_LIST_FULFILLED = 'CODE_SERVICES_LIST_FULFILLED';
export const CODE_SERVICES_LIST_REJECTED = 'CODE_SERVICES_LIST_REJECTED';

export const CODE_SERVICES_TINY = 'CODE_SERVICES_TINY';
export const CODE_SERVICES_TINY_PENDING = 'CODE_SERVICES_TINY_PENDING';
export const CODE_SERVICES_TINY_FULFILLED = 'CODE_SERVICES_TINY_FULFILLED';
export const CODE_SERVICES_TINY_REJECTED = 'CODE_SERVICES_TINY_REJECTED';

export const CODE_SERVICES_CREATE = 'CODE_SERVICES_CREATE';
export const CODE_SERVICES_CREATE_PENDING = 'CODE_SERVICES_CREATE_PENDING';
export const CODE_SERVICES_CREATE_FULFILLED = 'CODE_SERVICES_CREATE_FULFILLED';
export const CODE_SERVICES_CREATE_REJECTED = 'CODE_SERVICES_CREATE_REJECTED';

export const CODE_SERVICES_UPDATE = 'CODE_SERVICES_UPDATE';
export const CODE_SERVICES_UPDATE_PENDING = 'CODE_SERVICES_UPDATE_PENDING';
export const CODE_SERVICES_UPDATE_FULFILLED = 'CODE_SERVICES_UPDATE_FULFILLED';
export const CODE_SERVICES_UPDATE_REJECTED = 'CODE_SERVICES_UPDATE_REJECTED';

export const CODE_SERVICES_GET = 'CODE_SERVICES_GET';
export const CODE_SERVICES_GET_PENDING = 'CODE_SERVICES_GET_PENDING';
export const CODE_SERVICES_GET_FULFILLED = 'CODE_SERVICES_GET_FULFILLED';
export const CODE_SERVICES_GET_REJECTED = 'CODE_SERVICES_GET_REJECTED';

export const CODE_SERVICES_DELETE = 'CODE_SERVICES_DELETE';
export const CODE_SERVICES_DELETE_PENDING = 'CODE_SERVICES_DELETE_PENDING';
export const CODE_SERVICES_DELETE_FULFILLED = 'CODE_SERVICES_DELETE_FULFILLED';
export const CODE_SERVICES_DELETE_REJECTED = 'CODE_SERVICES_DELETE_REJECTED';

export const CODE_SERVICES_TYPE = 'CODE_SERVICES_TYPE';
export const CODE_SERVICES_TYPE_PENDING = 'CODE_SERVICES_TYPE_PENDING';
export const CODE_SERVICES_TYPE_FULFILLED = 'CODE_SERVICES_TYPE_FULFILLED';
export const CODE_SERVICES_TYPE_REJECTED = 'CODE_SERVICES_TYPE_REJECTED';

export const CODE_SERVICES_GET_DESCRIPTION = 'CODE_SERVICES_GET_DESCRIPTION';
export const CODE_SERVICES_GET_DESCRIPTION_PENDING = 'CODE_SERVICES_GET_DESCRIPTION_PENDING';
export const CODE_SERVICES_GET_DESCRIPTION_FULFILLED = 'CODE_SERVICES_GET_DESCRIPTION_FULFILLED';
export const CODE_SERVICES_GET_DESCRIPTION_REJECTED = 'CODE_SERVICES_GET_DESCRIPTION_REJECTED';

export const CODE_SERVICES_GET_BY_TARGET_CLIENT = 'CODE_SERVICES_GET_BY_TARGET_CLIENT';
export const CODE_SERVICES_GET_BY_TARGET_CLIENT_PENDING = 'CODE_SERVICES_GET_BY_TARGET_CLIENT_PENDING';
export const CODE_SERVICES_GET_BY_TARGET_CLIENT_FULFILLED = 'CODE_SERVICES_GET_BY_TARGET_CLIENT_FULFILLED';
export const CODE_SERVICES_GET_BY_TARGET_CLIENT_REJECTED = 'CODE_SERVICES_GET_BY_TARGET_CLIENT_REJECTED';

export const CODE_SERVICES_GET_DELETE_DEPENDECIES = 'CODE_SERVICES_GET_DELETE_DEPENDECIES';
export const CODE_SERVICES_GET_DELETE_DEPENDECIES_PENDING = 'CODE_SERVICES_GET_DELETE_DEPENDECIES_PENDING';
export const CODE_SERVICES_GET_DELETE_DEPENDECIES_FULFILLED = 'CODE_SERVICES_GET_DELETE_DEPENDECIES_FULFILLED';
export const CODE_SERVICES_GET_DELETE_DEPENDECIES_REJECTED = 'CODE_SERVICES_GET_DELETE_DEPENDECIES_REJECTED';

export const CODE_SERVICES_SET_DEPENDENCY_DIALOG = 'CODE_SERVICES_SET_DEPENDENCY_DIALOG';

export const CODE_SERVICES_SET_FEE_SCHEDULE = 'CODE_SERVICES_SET_FEE_SCHEDULE';

export const CODE_SERVICES_SET_RULES_DIALOG = 'CODE_SERVICES_SET_RULES_DIALOG';

export const CODE_SERVICES_RESET = 'CODE_SERVICES_RESET';

export const list = (callback) => ({
    type: CODE_SERVICES_LIST,
    payload: { callback },
});

export const tiny = () => ({
    type: CODE_SERVICES_TINY,
});

export const create = (service, callback) => ({
    type: CODE_SERVICES_CREATE,
    payload: { service, callback },
});

export const update = (id, service, callback) => ({
    type: CODE_SERVICES_UPDATE,
    payload: { id, service, callback },
});

export const get = (serviceId) => ({
    type: CODE_SERVICES_GET,
    payload: { serviceId },
});

export const deleteService = (id, callback) => ({
    type: CODE_SERVICES_DELETE,
    payload: { id, callback },
});

export const getServiceType = () => ({
    type: CODE_SERVICES_TYPE,
});

export const getDescription = (serviceId, callback) => ({
    type: CODE_SERVICES_GET_DESCRIPTION,
    payload: { serviceId, callback },
});

export const setServiceFromFeeSchedule = (isServiceFromFeeSchedule, feeScheduleId) => ({
    type: CODE_SERVICES_SET_FEE_SCHEDULE,
    payload: { isServiceFromFeeSchedule, feeScheduleId }
});

export const getServicesFromTargetClient = (targetClient) => ({
    type: CODE_SERVICES_GET_BY_TARGET_CLIENT,
    payload: { targetClient }
});

export const setRulesDialog = (rulesDialog) => ({
    type: CODE_SERVICES_SET_RULES_DIALOG,
    payload: { rulesDialog }
});

export const getCodeServiceDependencies = (serviceId) => ({
    type: CODE_SERVICES_GET_DELETE_DEPENDECIES,
    payload: { serviceId }
});

export const setCodeServiceDependencyDialog = (dependencyDialog) => ({
    type: CODE_SERVICES_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});