import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { contractedRatesLanding } from '../../store/actions/pages';
import { createStringDataField, createCurrencyDataField, createNumberDataField } from '../../util/format';
import { StringEquators, integerEquators } from '../../store/constants/pages';

import ContractedRatesComponent from './ContractedRatesPage';

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Rule Name', { sortable: true, equators: StringEquators }),
        createStringDataField('paymethod', 'Pay Method', { sortable: true, equators: integerEquators }),
        createNumberDataField('payvalue', 'Pay Value', { sortable: true, equators: integerEquators }),
        createStringDataField('code', 'Code', { sortable: true, equators: StringEquators }),
        createStringDataField('description', 'Description', { sortable: true, equators: StringEquators, truncate: true }),
        createStringDataField('group', 'Group', { sortable: true, equators: StringEquators, truncate: true }),
        createCurrencyDataField('discountedFacAllowedAmount', 'Discounted Facility Allowed Amount', { sortable: true, equators: integerEquators, truncateHeader: true }),
        createCurrencyDataField('discountedNonfacAllowedAmount', 'Discounted Non-Facility Allowed Amount', { sortable: true, equators: integerEquators, truncateHeader: true }),
    ]
);

const selectData = createSelector(
    (state) => state.pages['contracted-rates-landing'].list,
    (data) => data,
);

export const ContractedRatesPage = (connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            data: selectData(state),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            contractedRatesLanding: contractedRatesLanding,
        }, dispatch)
    })
)(injectIntl(ContractedRatesComponent)));