import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TableCell,
    Table,
    TableHead,
    TableRow,
    TableBody,
    makeStyles,
    Typography
} from "@material-ui/core";

import React from "react";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
    textField: {
        width: "85%"
    },
    formControlAuto: {
        marginTop: theme.spacing(5),
        width: "85%"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    dropZone: {
        minHeight: 150,
        border: "none",
        backgroundColor: "#EAEAEA",
        borderRadius: "20px"
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: "100%"
    }
}));

function MrfDialog(props) {
    const {
        intl,
        open,
        data,

        handleCancel
    } = props;
    const classes = useStyles();

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={handleCancel}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            MRF Report
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleCancel}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Facility</TableCell>
                            <TableCell>Status</TableCell>

                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item?.Name}</TableCell>

                                <TableCell>{item?.Status}</TableCell>

                                <TableCell>{item?.Message}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
}

export default MrfDialog;
