import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import PayerInfoComponent from './PayerInfoPage.js';

import { updatePayerStatus, selectDeselectAll, getPayerContractsTiny, setPayerInfoEditDialog, setPayerInfoEnableDialog } from '../../../store/actions/pricerPayers';
import { selectPayerContracts } from '../../../store/selectors/pricerPayers';
import { createReverseIconDataField, createStringDataField } from '../../../util/format';
import { StringEquators } from '../../../store/constants/pages';

import { pricerPayersLanding, resetPage } from '../../../store/actions/pages';
import { getByName, reset } from '../../../store/actions/search.js';
import { set } from '../../../store/actions/pages';

const selectFields = createSelector(
    () => [
        createStringDataField('remitPayer', 'Remit Payer', { sortable: true, equators: StringEquators }),
        createStringDataField('displayName', 'Display Payer', { sortable: true, equators: StringEquators }),
        createStringDataField('remitPayerType', 'Remit Payer Type', { sortable: true, equators: StringEquators }),
        createReverseIconDataField('disabled', 'Enabled', { hideFromFilter: true, icon: 'fas fa-check-circle' }),
    ]
);

const selectPayersData = createSelector(
    (state) => state.pages['pricer-payerinfo-landing'].list,
    (data) => data
);

export const selectCheckboxStatus = createSelector(
    selectPayersData,
    (payers) => {
        const enabledPayers = payers.filter(payer => !payer.disabled).length;
        if (payers.length === enabledPayers) {
            return true;
        }
        return false;
    }
)

export const PayerInfoPage = connect(
    () => {
        return (state, props) => ({
            payerListPending: state.pricerPayers.list.pending,
            updateStatusPending: state.pricerPayers.updateStatus.pending,
            payerData: selectPayersData(state),
            payerFields: selectFields(state),
            checkboxStatus: selectCheckboxStatus(state),
            payerContracts: selectPayerContracts(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payersLanding: pricerPayersLanding,
            updatePayerStatus: updatePayerStatus,
            selectDeselectAllPayers: selectDeselectAll,
            payersGetPayerContractsTiny: getPayerContractsTiny,
            searchGetByName: getByName,
            searchReset: reset,
            resetTableData: (value) => set('pricer-payerinfo-landing', 'list', value),
            resetPagedTable: resetPage,
            setPayerInfoEditDialog: setPayerInfoEditDialog,
            setPayerInfoEnableDialog: setPayerInfoEnableDialog
        }, dispatch)
    })
)(injectIntl(PayerInfoComponent));