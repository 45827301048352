import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as actions from '../actions/dashboards';
import * as usersActions from '../actions/users';
import * as API from '../api/dashboards';
import * as USERS_API from '../api/users';
import { sessionErrorHandling } from './session';
import {
    selectSessionUserClientId,
    selectIsSuperadminOrPartner
} from '../selectors/session';

function* list() {
    try {
        yield put({ type: actions.DASHBOARD_LIST_PENDING });
        const clientId = yield select((state) => state.session.user.clientId);
        const payload = yield call(API.listDashboards, clientId);
        yield put({ type: actions.DASHBOARD_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_LIST_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* listByClientId() {
    try {
        yield put({ type: actions.DASHBOARD_LIST_BY_CLIENT_ID_PENDING });
        const state = yield select((state) => state);
        const clientId = yield selectSessionUserClientId(state);
        const isSuperadminOrParter = yield selectIsSuperadminOrPartner(state);
        const payload = yield call(API.listDashboardsByClientId, clientId, isSuperadminOrParter);
        yield put({ type: actions.DASHBOARD_LIST_BY_CLIENT_ID_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_LIST_BY_CLIENT_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* listTiny(action) {
    try {
        yield put({ type: actions.DASHBOARD_LIST_TINY_PENDING });
        const {
            callback,
            clientId,
        } = action.payload;
        const payload = yield call(API.listDashboardTinyByClientId, clientId);
        yield put({ type: actions.DASHBOARD_LIST_TINY_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_LIST_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.DASHBOARD_GET_PENDING });
        const { dashboardId, callback } = action.payload;
        const payload = yield call(API.getDashboard, dashboardId);

        // get the list of users associated with dashboard;
        const { clientId } = payload;
        const userPayload = yield call(USERS_API.getUsersTiny, clientId);
        yield put({ type: usersActions.USERS_LIST_TINY_FULFILLED, payload: userPayload });

        // resume the last call
        yield put({ type: actions.DASHBOARD_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteDashboard(action) {
    try {
        yield put({ type: actions.DASHBOARD_DELETE_PENDING });
        const { id, callback } = action.payload;
        yield call(API.deleteDashboard, id);
        yield put({ type: actions.DASHBOARD_DELETE_FULFILLED });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getUrl(action) {
    try {
        yield put({ type: actions.DASHBOARD_GET_URL_PENDING });
        const { qs_Id } = action.payload;
        const payload = yield call(API.getDashboardUrl, qs_Id);
        yield put({ type: actions.DASHBOARD_GET_URL_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_GET_URL_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.DASHBOARD_CREATE_PENDING });
        const {
            dashboard,
            callback
        } = action.payload;

        const newDashboard = yield call(API.createDashboard, dashboard);
        yield put({ type: actions.DASHBOARD_CREATE_FULFILLED, newDashboard });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.DASHBOARD_UPDATE_PENDING });
        const {
            dashboard,
            callback
        } = action.payload;

        const newDashboard = yield call(API.updateDashboard, dashboard);
        yield put({ type: actions.DASHBOARD_UPDATE_FULFILLED, newDashboard });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.DASHBOARD_RESET });
}

function* switchClient(action) {
    try {
        yield put({ type: actions.DASHBOARD_LIST_BY_CLIENT_ID_PENDING });
        const {
            clientId,
            callback
        } = action.payload;
        const state = yield select((state) => state);
        const isSuperadminOrParter = selectIsSuperadminOrPartner(state);
        const dashboards = yield call(API.listDashboardsByClientId, clientId, isSuperadminOrParter);
        yield call(API.updateSelectedClient, clientId);
        yield put({ type: actions.DASHBOARD_LIST_BY_CLIENT_ID_FULFILLED, payload: dashboards });
        if (callback) {
            callback()
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_LIST_BY_CLIENT_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFieldValues(action) {
    try {
        const { filterId, dashboardId } = action.payload;
        const key = `${dashboardId}-${filterId}`;
        yield put({ type: actions.DASHBOARD_GET_FIELD_VALUES_PENDING, payload: key });
        const payload = yield call(API.getDashboardFieldValues, action.payload);
        const response = {
            payload,
            key,
        };
        yield put({ type: actions.DASHBOARD_GET_FIELD_VALUES_FULFILLED, payload: response });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.DASHBOARD_GET_FIELD_VALUES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.DASHBOARD_LIST, list);
    yield takeEvery(actions.DASHBOARD_LIST_BY_CLIENT_ID, listByClientId);
    yield takeEvery(actions.DASHBOARD_LIST_TINY, listTiny);
    yield takeEvery(actions.DASHBOARD_GET, get);
    yield takeEvery(actions.DASHBOARD_GET_URL, getUrl);
    yield takeEvery(actions.DASHBOARD_CREATE, create);
    yield takeEvery(actions.DASHBOARD_UPDATE, update);
    yield takeEvery(actions.DASHBOARD_DELETE, deleteDashboard);
    yield takeEvery(actions.DASHBOARD_SWITCH_LIST_BY_CLIENT_ID, switchClient);
    yield takeEvery(actions.DASHBOARD_GET_FIELD_VALUES, getFieldValues);
}