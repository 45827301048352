import React, { useEffect, useState, useCallback } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Loadable, TextMaskCustom } from '../../../../common';
import { ROLE_PARTNER_ADMIN, ROLE_ADMIN, PARTNER_CLIENT_ID, ROLE_USER, ROLE_SUPERADMIN, SUPERADMIN_CLIENT_ID, ROLE_PARTNER_USER, ROLE_API } from '../../../../store/constants/roles';
import { packExtension } from '../../../../util/phone';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import {
  Input, Button, makeStyles, TextField, FormControl, Select,
  InputLabel, MenuItem, List, ListItem, ListItemIcon, ListItemText,
  Checkbox, FormControlLabel, Switch, Dialog, DialogActions, DialogContent,
  DialogTitle, ListItemSecondaryAction, Chip, Badge, Grid, FormGroup, Typography, DialogContentText,
} from "@material-ui/core";
import DashboardIcon from '@material-ui/icons/Dashboard';

import { ConfirmationDialog } from '../../../../common/confirmationDialog';

import { PASSWORD_REGEX, EMAIL_REGEX } from '../../../../store/constants/pages';
import { Card } from "react-bootstrap";
import { Autocomplete } from "@material-ui/lab";
import { BackButton } from "../../../../common/BackButton";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  switch: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  clientNameFormControl: {
    width: '100%',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  badge: {
    top: '50%',
    right: '-28px'
  },
  forceResetSwitch: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  sendWelcomeEmailSwitch: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function UsersEditPage({
  intl, mode, roles, usersCreatePending, usersUpdatePending, clientsGetPending, history,
  isSuperAdmin, isPartner, initialValues, user, isAdmin, dashboardListTinyPending, sessionUserPartnerId,
  userId, clients, partners, sessionUserClientId, dashboardsTiny, userActive, getPartnerPending, partnerProducts,
  usersActivatePending, usersDeactivatePending, clientProducts, getClientProductsPending, getPartnersProductsPending,
  actions: {
    userCreate, userUpdate, userGet, dashboardListTiny, dashboardListTinyReset, getPartnersTiny, resetPartnerProducts,
    deactivateUser, activateUser, errorReset, getProductsForUserEdit, resetProductsForUserEdit, getPartnersClientsProducts
  }
}) {
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [isUserActive, setUserActive] = useState(true);
  const [isActiveConfirmOpen, setActiveConfirmOpen] = useState(false);

  const isEdit = mode === 'edit';
  const classes = useStyles();
  const isSuperAdminOrPatner = isSuperAdmin || isPartner;
  const [open, setOpen] = useState(false);
  const [hasCheckboxEdited, setHasCheckboxEdited] = useState(false);

  const handleCallback = () => {
    history.push('/admin/users?persist=true');
  }

  const handleGetCallback = useCallback(() => {
    if (user && user.partnerClientIds.length > 0) {
      setSelectedPartners(user.partnerClientIds);
    }
  }, [user]);

  const handleDashboardTinyCallback = useCallback(() => {
    if (user && user.dashboardIds.length > 0) {
      setSelectedDashboards(user.dashboardIds);
    }
  }, [user]);

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
  }

  useEffect(() => {
    getPartnersTiny()
  }, [getPartnersTiny]);

  useEffect(() => {
    if (mode === 'edit') {
      setUserActive(userActive)
    }
    return function cleanup() {
      resetProductsForUserEdit();
      resetPartnerProducts();
      errorReset();
    }
  }, [userActive]);

  useEffect(() => {
    if (user && mode === 'edit') {
      if ((user.role === ROLE_PARTNER_ADMIN || user.role === ROLE_PARTNER_USER)) {
        getPartnersClientsProducts(user.partnerId);
      }
    } else {
      if (isPartner) {
        getPartnersClientsProducts(sessionUserPartnerId);
      }
    }
  }, [getPartnersClientsProducts, mode, user]);

  useEffect(() => {
    if (mode === 'edit') {
      userGet(userId, handleGetCallback);
    }
  }, [handleGetCallback, userId, mode, userGet]);

  useEffect(() => {
    if (mode === 'edit') {
      if (user) {
        // In case of edit, we don't need to get dashboards
        // for Partner and superadmin as we don't assign those.
        if (user.role !== ROLE_PARTNER_ADMIN && user.role !== ROLE_SUPERADMIN) {
          dashboardListTiny(user.clientId, handleDashboardTinyCallback);
          getProductsForUserEdit(user.clientId);
        }

      }
    } else {
      // if logged in user is superadmin or partner, then while creating a user
      // he needs to select the client id.
      // else the user will get clientId from session.
      if (isSuperAdminOrPatner) {
        if (selectedClientId) {
          dashboardListTiny(selectedClientId);
          getProductsForUserEdit(selectedClientId);
        }
      } else {
        if (sessionUserClientId) {
          dashboardListTiny(sessionUserClientId);
          getProductsForUserEdit(sessionUserClientId);
        }
      }
    }

    return function cleanup() {
      dashboardListTinyReset();
    }

  }, [dashboardListTiny, sessionUserClientId, selectedClientId, isSuperAdminOrPatner, mode, user, handleDashboardTinyCallback, dashboardListTinyReset]);

  const handleClientChange = (setFieldValue, value) => {
    setFieldValue('hasDashboardAccess', false);
    setFieldValue('hasVendorManagementAccess', false);
    setFieldValue('hasEstimatorAccess', false);
    setFieldValue('hasClaimProcessorAccess', false);
    setFieldValue('hasCMSClaimAnalysisAccess', false);
    setFieldValue('hasCMSAccess', false);
    setFieldValue('hasPricerAccess', false);

    if (value) {
      setFieldValue('clientId', value);
      setSelectedClientId(value.clientId);
      setSelectedDashboards([]);
    } else {
      setFieldValue('clientId', null);
      setSelectedClientId(null);
    }
  };

  const handlePartnerChange = (setFieldValue, value, _role) => {
    setFieldValue('partnerId', value);

    if (_role === ROLE_PARTNER_USER) {
      setFieldValue('hasDashboardAccess', false);
      setFieldValue('hasVendorManagementAccess', false);
      setFieldValue('hasEstimatorAccess', false);
      setFieldValue('hasClaimProcessorAccess', false);
      setFieldValue('hasCMSClaimAnalysisAccess', false);
      setFieldValue('hasCMSAccess', false);
      setFieldValue('hasPricerAccess', false);
    }


    if (value) {
      getPartnersClientsProducts(value.id);
    }
  }

  const handleRoleChange = (handleChange, event, setFieldValue) => {
    handleChange(event);

    // if selected role is superadmin, set client as SYSTEM
    // if (event.target.value === ROLE_SUPERADMIN) {
    //   const systemClientobj = clients.find(client => client.name === 'SYSTEM');
    //   if (systemClientobj) {
    //     setFieldValue('clientId', systemClientobj.clientId)
    //   }
    // }
    // else {
    //   setFieldValue('clientId', null);
    //   setSelectedClientId(null);
    // }

    // // Resetting other dependent values
    // if (!isPartner) {
    //   resetProductsForUserEdit();
    //   resetPartnerProducts();
    //   setFieldValue('partnerId', null);
    // }
    // dashboardListTinyReset([]);
    setSelectedDashboards([]);
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleToggle = (clientId) => () => {
    setHasCheckboxEdited(true);
    const currentIndex = selectedPartners.indexOf(clientId);
    const newSelectedPartners = [...selectedPartners];

    if (currentIndex === -1) {
      newSelectedPartners.push(clientId);
    } else {
      newSelectedPartners.splice(currentIndex, 1);
    }
    setSelectedPartners(newSelectedPartners);
  };

  const handleDashboardToggle = (id) => () => {
    setHasCheckboxEdited(true);
    const currentIndex = selectedDashboards.indexOf(id);
    const newSelectedDashboards = [...selectedDashboards];

    if (currentIndex === -1) {
      newSelectedDashboards.push(id);
    } else {
      newSelectedDashboards.splice(currentIndex, 1);
    }
    setSelectedDashboards(newSelectedDashboards);
  };

  const renderDashboards = () => {
    if (selectedDashboards.length === 0 && !dashboardListTinyPending) {
      return <div>
        No dashboards selected!
      </div>
    }
    return (
      selectedDashboards.map(dashboardId => {
        const dashboardTiny = dashboardsTiny.find(x => x.id === dashboardId);
        if (dashboardTiny) {
          const { id, name } = dashboardTiny;
          return (
            <Chip
              icon={<DashboardIcon />}
              size="small"
              variant="outlined"
              key={id}
              label={name}
              onDelete={handleDashboardToggle(id)}
              className={classes.chip}
            />
          );
        }
      })
    )
  }

  const handleSaveBegin = (values) => {
    if (selectedDashboards.length <= 0 && values.role === ROLE_USER && values.hasDashboardAccess && clientProducts?.hasDashboardAccess) {
      setOpenConfirmation(true);
    } else {
      handleSaveChanges(values);
    }
  }

  const handleSaveChanges = (values) => {
    setOpenConfirmation(false);

    let payload : Record<string,any> = {
      username: values.username,
      title: values.title,
      name: values.name,
      role: values.role,
      email: values.email,
      phone: packExtension(values.phone, values.ext),
      clientId: values.clientId?.clientId,
      hasPricerAccess: values.hasPricerAccess,
      hasCMSAccess: values.hasCMSAccess,
      forceResetPW: values.forceResetPW,
      sendWelcomeEmail: values.sendWelcomeEmail,
      passwordRotationDays: values.passwordRotationDays,
      hasCMSClaimAnalysisAccess: values.hasCMSClaimAnalysisAccess,
      hasClaimProcessorAccess: values.hasCMSAccess ? values.hasClaimProcessorAccess : false,
      hasEstimatorAccess: values.hasEstimatorAccess,
      hasVendorManagementAccess: values.hasVendorManagementAccess,
      hasDashboardAccess: values.hasDashboardAccess
    }

    if (values.role === ROLE_SUPERADMIN) {
      payload = {
        ...payload,
        clientId: SUPERADMIN_CLIENT_ID, // -1 client id for superadmin
      }
    }

    if (values.role === ROLE_PARTNER_ADMIN || values.role === ROLE_PARTNER_USER) {
      payload = {
        ...payload,
        clientId: PARTNER_CLIENT_ID, // no preferred client for partner role
        // partnerClientIds: selectedPartners,
      }
    }

    // set partner id if session user is superadmin
    if (isSuperAdmin && (values.role === ROLE_PARTNER_ADMIN || values.role === ROLE_PARTNER_USER)) {
      payload = {
        ...payload,
        partnerId: values.partnerId?.id,
      }
    } else if (isPartner && (values.role === ROLE_PARTNER_ADMIN || values.role === ROLE_PARTNER_USER)) {
      payload = {
        ...payload,
        partnerId: sessionUserPartnerId,
      }
    }

    // need to send the alert only if the role is Administrator
    if (values.role === ROLE_ADMIN) {
      payload = {
        ...payload,
        alert: values.alert,
      }
    }

    // Admin doesn't have selection for clients.
    if (isAdmin) {
      payload = {
        ...payload,
        clientId: sessionUserClientId,
      }
    }

    // don't pass dashboard id's in case of partner and superadmin.
    // as they don't need dashboards assignments.
    if (values.role !== ROLE_PARTNER_ADMIN || values.role !== ROLE_SUPERADMIN) {
      payload = {
        ...payload,
        dashboardIds: selectedDashboards,
      }
    }

    // create
    if (mode === 'create') {
      payload = {
        ...payload,
        password: values.password,
      }
      userCreate(payload, handleCallback);
    }
    // update
    else {
      payload = {
        ...payload,
        id: values.id,
      }

      // Superadmin, admin and can reset the user's password in edit.
      if (isSuperAdmin || isPartner || isAdmin) {
        if (values.password) {
          payload = {
            ...payload,
            password: values.password,
          }
        }
      }
      userUpdate(values.id, payload, handleCallback);
    }
  }

  const handleStatusToggle = (e) => {
    setUserActive(e.target.checked);
    handleActiveConfirmOpen();
  }

  const handleActiveConfirmOpen = () => {
    setActiveConfirmOpen(true);
  }

  const handleActiveConfirmClose = () => {
    setActiveConfirmOpen(false);
    setUserActive(userActive);
  }

  const handleUserStatusChange = () => {
    setActiveConfirmOpen(false);
    if (isUserActive) {
      activateUser(userId, handleCallback)
    } else {
      deactivateUser(userId, handleCallback);
    }
  }

  return (
    <Loadable loading={usersCreatePending || usersUpdatePending || clientsGetPending || usersActivatePending || usersDeactivatePending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors : Record<string, string> = {};

          if (!values.title) {
            errors.title = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.role) {
            errors.role = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.phone) {
            errors.phone = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.email) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (!values.email.match(EMAIL_REGEX)) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_EMAIL"
            });
          }

          if (!values.username) {
            errors.username = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (mode === 'create') {
            // No client selection for administrator role
            if (!isAdmin) {
              // if not admin, then no client selection for partner role.
              if (values.role !== ROLE_PARTNER_ADMIN && values.role !== ROLE_PARTNER_USER && values.role !== ROLE_SUPERADMIN) {
                if (!values.clientId) {
                  errors.clientId = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }
              }
            }

            if ((values.role === ROLE_PARTNER_ADMIN || values.role === ROLE_PARTNER_USER) && isSuperAdmin) {
              if (!values.partnerId) {
                errors.partnerId = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (!values.password.match(PASSWORD_REGEX)) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_PASSWORD"
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm Password didn't match.";
            }
          } else if ((isAdmin || isSuperAdmin || isPartner) && (values.password?.length > 0 || values.sendWelcomeEmail)) {
            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (!values.password.match(PASSWORD_REGEX)) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_PASSWORD"
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm Password didn't match.";
            }
          }

          if (Boolean(values.hasPricerAccess || values.hasCMSAccess
            || values.hasCMSClaimAnalysisAccess || values.hasEstimatorAccess
            || values.hasVendorManagementAccess || values.hasDashboardAccess) === false
            && values.role === ROLE_PARTNER_USER) {
            errors.noPermissionError = 'At least one product permission is mandatory'
          }

          if (Boolean(values.hasPricerAccess || values.hasCMSAccess
            || values.hasCMSClaimAnalysisAccess || values.hasEstimatorAccess
            || values.hasVendorManagementAccess || values.hasDashboardAccess) === false
            && values.role === ROLE_USER && values.clientId !== null) {
            errors.noPermissionError = 'At least one product permission is mandatory'
          }

          return errors;
        }}
        onSubmit={(values) => {
          handleSaveBegin(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                // title={!isEdit ? "Add new User" : "Edit User"}
                toolbar={
                  <PortletHeaderToolbar className='w-100'>
                    <Grid container alignItems="center">
                      <Grid md={6} spacing={2} container alignItems="center" className="pl-2">
                        {/* <Grid md={2}>
                          <Typography className="text-dark" variant="h6">{!isEdit ? "Add new User" : "Edit User"}</Typography>
                        </Grid> */}
                        {(isSuperAdmin || isPartner || isAdmin) && mode === 'edit' &&
                          // <Grid md={7} className='pt-2'>
                          <FormGroup>
                            <Typography component="div">
                              <Grid component="label" className='mb-0' container alignItems="center" spacing={0}>
                                <Grid item>Inactive</Grid>
                                <Grid item>
                                  <Switch
                                    checked={isUserActive}
                                    onChange={handleStatusToggle}
                                    name="userActive" />
                                </Grid>
                                <Grid item>Active</Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                          // </Grid>
                        }
                      </Grid>
                      <Grid md={6} container className="justify-content-end">
                        <BackButton text={(dirty || hasCheckboxEdited) ? "Cancel" : "Back"} defaultRoute="/admin/users?persist=true" />
                        <Button
                          variant="contained"
                          color="primary"
                          className='ml-3'
                          type="submit"
                          disabled={usersCreatePending || usersUpdatePending || (isEdit && !dirty && !hasCheckboxEdited)}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className="main-content">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-6">
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="role">Role</InputLabel>
                            <Select
                              value={values.role}
                              onChange={(event) => handleRoleChange(handleChange, event, setFieldValue)}
                              disabled={isEdit && (values.role !== ROLE_USER && values.role !== ROLE_ADMIN)}
                              error={Boolean(touched.role && errors.role)}
                              input={<Input id="role" />}
                              inputProps={{
                                name: "role",
                                id: "role"
                              }}
                            >
                              {isEdit && (values.role === ROLE_ADMIN || values.role === ROLE_USER) ?
                                roles.filter(item => item.name === ROLE_USER || item.name === ROLE_ADMIN).map(role => (
                                  <MenuItem
                                    key={role.id}
                                    value={role.name}
                                  >
                                    {role.name}
                                  </MenuItem>
                                )) :
                                roles.map(role => (
                                  <MenuItem
                                    key={role.id}
                                    value={role.name}
                                  >
                                    {role.name}
                                  </MenuItem>
                                ))

                              }
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-6">
                          <TextField
                            required
                            label="User Name"
                            name="username"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            disabled={isEdit}
                            onChange={handleChange}
                            value={values.username}
                            helperText={touched.username && errors.username}
                            error={Boolean(touched.username && errors.username)}
                          />
                        </div>
                        {isSuperAdmin && (values.role === ROLE_PARTNER_ADMIN || values.role === ROLE_PARTNER_USER) && (
                          <div className="col-md-6">
                            <Autocomplete
                              value={values.partnerId}
                              disableClearable={false}
                              onChange={(e, value) => { handlePartnerChange(setFieldValue, value, values.role) }}
                              getOptionSelected={(option, value) => option === value}
                              options={partners}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) =>
                                <TextField {...params}
                                  name={`partnerId`}
                                  label="Partner"
                                  margin="normal"
                                  helperText={touched.partnerId && errors.partnerId}
                                  error={Boolean(touched.partnerId && errors.partnerId)}
                                />
                              }
                            />
                          </div>
                        )}
                        {(isSuperAdminOrPatner) && values.role !== ROLE_PARTNER_ADMIN && values.role !== ROLE_PARTNER_USER && values.role !== ROLE_SUPERADMIN && (
                          <div className="col-md-6">
                            {(isEdit) ? (
                              <FormControl className={classes.clientNameFormControl}>
                                <TextField
                                  disabled
                                  label="Client Name"
                                  value={values.clientName}
                                  className={classes.textField}
                                  margin="normal"
                                />
                              </FormControl>
                            ) : (
                              <Autocomplete
                                value={values.clientId}
                                disableClearable={false}
                                onChange={(e, value) => { handleClientChange(setFieldValue, value) }}
                                getOptionSelected={(option, value) => option === value}
                                options={clients}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) =>
                                  <TextField {...params}
                                    name={`clientId`}
                                    label="Client"
                                    margin="normal"
                                    helperText={touched.clientId && errors.clientId}
                                    error={Boolean(touched.clientId && errors.clientId)}
                                  />
                                }
                              />
                            )}
                          </div>
                        )}
                        <div className="col-md-6">
                          <TextField
                            required
                            label="Title"
                            name="title"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            required
                            label="Name"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            required
                            label="Email"
                            name="email"
                            placeholder="john@smith.com"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched.email && errors.email}
                            error={Boolean(touched.email && errors.email)}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            required
                            label="Phone"
                            name="phone"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            helperText={touched.phone && errors.phone}
                            error={Boolean(touched.phone && errors.phone)}
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }}
                          />
                        </div>
                        <div className="col-md-2">
                          <TextField
                            label="Ext"
                            name="ext"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.ext}
                          />
                        </div>
                        {(isSuperAdmin || isAdmin || isPartner) && <div className="col-md-6">
                          <TextField
                            type="number"
                            margin="normal"
                            label="Password Rotation Days"
                            className={classes.textField}
                            name="passwordRotationDays"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.passwordRotationDays}
                            helperText={touched.passwordRotationDays && errors.passwordRotationDays}
                            error={Boolean(
                              touched.passwordRotationDays && errors.passwordRotationDays
                            )}
                          />
                        </div>}
                      </div>
                      {(mode === "create" || isSuperAdmin || isAdmin || isPartner) && (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <TextField
                                type="password"
                                margin="normal"
                                label="Password"
                                className={classes.textField}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                helperText={(touched.password && errors.password) || (values.sendWelcomeEmail && errors.password)}
                                error={
                                  Boolean(touched.password && errors.password) || Boolean(values.sendWelcomeEmail && errors.password)
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <TextField
                                type="password"
                                margin="normal"
                                label="Confirm Password"
                                className={classes.textField}
                                name="confirmPassword"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.confirmPassword}
                                helperText={(touched.confirmPassword && errors.confirmPassword) || (values.sendWelcomeEmail && errors.confirmPassword)}
                                error={Boolean(
                                  (touched.confirmPassword && errors.confirmPassword) || (values.sendWelcomeEmail && errors.confirmPassword)
                                )}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="mt-4 col-md-7">
                      <Card>
                        <Loadable loading={getClientProductsPending || getPartnersProductsPending}>
                          <Card.Header className={errors.noPermissionError && values.role !== ROLE_SUPERADMIN && 'text-danger'} as="h5">
                            Configurations
                            {values.role !== ROLE_SUPERADMIN && errors.noPermissionError && <span className=" ml-3 text-danger font-12">({errors.noPermissionError})</span>}
                          </Card.Header>
                          <Card.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <FormControlLabel
                                  control={
                                    <Switch
                                      name="forceResetPW"
                                      checked={values.forceResetPW}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={classes.forceResetSwitch}
                                      value={values.forceResetPW}
                                    />
                                  }
                                  label="Force Password Reset"
                                />
                              </div>

                              {((mode === "edit") && (isSuperAdmin || isAdmin || isPartner) && (values.role !== ROLE_API)) && (
                                <div className="col-md-6">
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="sendWelcomeEmail"
                                        checked={values.sendWelcomeEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={classes.sendWelcomeEmailSwitch}
                                        value={values.sendWelcomeEmail}
                                      />
                                    }
                                    label="Send Welcome Email"
                                  />
                                </div>
                              )}
                              {(values.role === ROLE_ADMIN) && (
                                <div className="col-md-6">
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="alert"
                                        checked={values.alert}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={classes.switch}
                                        value={values.alert}
                                      />
                                    }
                                    label="Email Alerts"
                                  />
                                </div>
                              )}
                              {((values.role === ROLE_USER && values.clientId !== null) || (values.role === ROLE_USER && isAdmin)) && (
                                <>
                                  {clientProducts?.hasPricerAccess &&
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasPricerAccess"
                                            checked={values.hasPricerAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasPricerAccess}
                                          />
                                        }
                                        label="Pricer"
                                      />
                                    </div>
                                  }
                                  {(isSuperAdmin || isPartner || isAdmin) && clientProducts?.hasCMSAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasCMSAccess"
                                            checked={values.hasCMSAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasCMSAccess}
                                          />
                                        }
                                        label="Contract Management"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && values.hasCMSAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasClaimProcessorAccess"
                                            checked={values.hasClaimProcessorAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasClaimProcessorAccess}
                                          />
                                        }
                                        label="Claim Processor"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && clientProducts?.hasEstimatorAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasEstimatorAccess"
                                            checked={values.hasEstimatorAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasEstimatorAccess}
                                          />
                                        }
                                        label="Claim Estimator"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && clientProducts?.hasCMSAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasCMSClaimAnalysisAccess"
                                            checked={values.hasCMSClaimAnalysisAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasCMSClaimAnalysisAccess}
                                          />
                                        }
                                        label="Claim Analysis"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && clientProducts?.hasVendorManagementAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasVendorManagementAccess"
                                            checked={values.hasVendorManagementAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasVendorManagementAccess}
                                          />
                                        }
                                        label="Vendor Management"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && clientProducts?.hasDashboardAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasDashboardAccess"
                                            checked={values.hasDashboardAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasDashboardAccess}
                                          />
                                        }
                                        label="Dashboard"
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              {values.role === ROLE_PARTNER_USER && (
                                <>
                                  {partnerProducts?.hasPricerAccess &&
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasPricerAccess"
                                            checked={values.hasPricerAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasPricerAccess}
                                          />
                                        }
                                        label="Pricer"
                                      />
                                    </div>
                                  }
                                  {(isSuperAdmin || isPartner || isAdmin) && partnerProducts?.hasCMSAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasCMSAccess"
                                            checked={values.hasCMSAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasCMSAccess}
                                          />
                                        }
                                        label="Contract Management"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && values.hasCMSAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasClaimProcessorAccess"
                                            checked={values.hasClaimProcessorAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasClaimProcessorAccess}
                                          />
                                        }
                                        label="Claim Processor"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && partnerProducts?.hasEstimatorAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasEstimatorAccess"
                                            checked={values.hasEstimatorAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasEstimatorAccess}
                                          />
                                        }
                                        label="Claim Estimator"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && partnerProducts?.hasCMSAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasCMSClaimAnalysisAccess"
                                            checked={values.hasCMSClaimAnalysisAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasCMSClaimAnalysisAccess}
                                          />
                                        }
                                        label="Claim Analysis"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && partnerProducts?.hasVendorManagementAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasVendorManagementAccess"
                                            checked={values.hasVendorManagementAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasVendorManagementAccess}
                                          />
                                        }
                                        label="Vendor Management"
                                      />
                                    </div>
                                  )}
                                  {(isSuperAdmin || isPartner || isAdmin) && partnerProducts?.hasDashboardAccess && (
                                    <div className="col-md-6">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="hasDashboardAccess"
                                            checked={values.hasDashboardAccess}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={classes.switch}
                                            value={values.hasDashboardAccess}
                                          />
                                        }
                                        label="Dashboard"
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Card.Body>
                        </Loadable>
                      </Card>
                    </div>
                    {((values.role === ROLE_ADMIN && clientProducts?.hasDashboardAccess) || (values.role === ROLE_USER && values.hasDashboardAccess && clientProducts?.hasDashboardAccess)) && (
                      <div className="mt-4 col-md-7">
                        <Card>
                          <Card.Header as="h5">Dashboards</Card.Header>
                          <Card.Body>
                            <div className="row">
                              <div className="col-md-4">
                                <Button
                                  variant="outlined"
                                  onClick={handleClickOpen}
                                  color="primary"
                                  disabled={isSuperAdminOrPatner && values.role !== ROLE_PARTNER_ADMIN && !values.clientId}>
                                  Select Dashboards
                                </Button>
                              </div>
                              <div className="col-md-8">
                                {renderDashboards()}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </div>
                </div>
              </PortletBody>
            </Portlet>
            <ConfirmationDialog
              open={openConfirmation}
              handleClose={handleConfirmationClose}
              handleSubmit={() => handleSaveChanges(values)}
            />
          </form>
        )}
      </Formik>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Assign Dashboards</DialogTitle>
        <DialogContent>
          <Loadable loading={dashboardListTinyPending}>
            <List>
              {dashboardsTiny.map(({ id, name, active }) => {
                return (
                  <ListItem
                    key={id}
                    role={undefined}
                    dense
                    button
                    onClick={handleDashboardToggle(id)}
                    divider
                  >
                    <Badge
                      classes={{
                        badge: classes.badge,
                      }}
                      color={`${active ? 'secondary' : 'error'}`}
                      badgeContent={`${active ? 'Active' : 'Inactive'}`}
                    >
                      <ListItemText id={id} primary={`${name}`} />
                    </Badge>
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="start"
                        checked={selectedDashboards.indexOf(id) !== -1}
                        tabIndex={-1}
                        onClick={handleDashboardToggle(id)}
                        disableRipple
                        inputProps={{ 'aria-labelledby': id }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}

              {(dashboardsTiny.length === 0 && !dashboardListTinyPending) && (
                (isSuperAdminOrPatner) ? (
                  <div className="alert alert-danger">
                    No dashboards found for assignment. Please select different client and try again.
                  </div>
                ) : (
                  <div className="alert alert-danger">
                    No dashboards found for assignment!
                  </div>
                )
              )}
            </List>
          </Loadable>
        </DialogContent>
        <DialogActions>
          <Button className="mr-3 mb-3" variant="contained" onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isActiveConfirmOpen} onClose={handleActiveConfirmClose}>
        <DialogTitle>
          {isUserActive ? 'Activate' : 'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to  {isUserActive ? 'activate' : 'deactivate'} this user ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={handleActiveConfirmClose} color="default">
            Cancel
          </Button>
          <Button
            type='button'
            onClick={handleUserStatusChange}
            color="primary">
            {isUserActive ? 'Activate' : 'Deactivate'}
          </Button>
        </DialogActions>
      </Dialog>
    </Loadable>
  );
}




