import React, { useState } from "react";
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    TableCell,
    Table,
    TableHead,
    TableRow,
    TableBody,
    makeStyles
} from "@material-ui/core";
import { Formik } from "formik";

import { Loadable } from "../../../../common";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: "20px"
    }
}));

function AddClientsDialog(props) {
    const classes = useStyles();

    const {
        open,
        clientId,
        handleClose,
        initialValues,
        accountDigit,
        addClaimsPending,
        mergedClaims,
        intl,
        actions: { addClaims,resetAddedClaims }
    } = props;

    const [validationError, setValidationError] = useState(null);

    function handleClaimNumbersChange(value, setFieldValue) {
        const withoutLineBreaks = value.replace(/[\r\n]/gm, "");
        const regex = /^[0-9a-zA-Z,]*$/g;
        if (withoutLineBreaks?.length === 0) {
            setFieldValue("claims", "");
            setFieldValue("claimNumbers", []);
            setValidationError(null);
        } else if (withoutLineBreaks.match(regex)) {
            setFieldValue("claims", withoutLineBreaks);
            const splittedClaims = withoutLineBreaks.split(",");
            setFieldValue(
                "claimNumbers",
                splittedClaims.filter(item => item)
            );
            setValidationError(null);
        } else {
            setValidationError(
                "Codes are not pasted as per the required format"
            );
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>Validate Claims</DialogTitle>
                <DialogContent>
                    <Loadable loading={addClaimsPending}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validate={values => {
                                const errors = {};

                                return errors;
                            }}
                            onSubmit={(values, {}) => {
                                let claimNumbers = values.claimNumbers;
                                if (!values.claims) {
                                    setValidationError("Claims are Required");
                                    return;
                                }

                                for (var i = 0; i < claimNumbers.length; i++) {
                                    if (
                                        claimNumbers[i].length <= accountDigit
                                    ) {
                                        setValidationError(
                                            "All Claims Length must be greater than Account Digit"
                                        );
                                        return;
                                    }
                                }
                                let payload = {
                                    clientId,
                                    claimNumbers: claimNumbers
                                };
                                addClaims(payload);
                            }}
                        >
                            {({ values, handleSubmit, setFieldValue }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid className="mt-2" item xs={12} md={12}>
                                        <TextField
                                            required
                                            type="text"
                                            multiline
                                            maxRows={8}
                                            label="Claims"
                                            name="claims"
                                            // className={classes.formControlAuto}
                                            placeholder="Type comma separated Claims"
                                            margin="normal"
                                            onChange={e =>
                                                handleClaimNumbersChange(
                                                    e.target.value,
                                                    setFieldValue
                                                )
                                            }
                                            value={values?.claims}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={
                                                validationError ??
                                                "e.g. 1234544311,1232321142,2343434543"
                                            }
                                            error={Boolean(validationError)}
                                        />
                                    </Grid>

                                    {Object.keys(mergedClaims).length > 0 &&
                                        !addClaimsPending && (
                                            <Table stickyHeader={true}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Group
                                                        </TableCell>
                                                        <TableCell>
                                                            Merged Claims
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {Object.keys(
                                                        mergedClaims
                                                    )?.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {item}
                                                            </TableCell>

                                                            <TableCell>
                                                                {mergedClaims[
                                                                    item
                                                                ]?.map(item => (
                                                                    <li>
                                                                        {item}
                                                                    </li>
                                                                ))}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        )}
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        className={classes.mt}
                                    >
                                        <Button
                                            style={{ marginRight: "10px" }}
                                            onClick={() => {
                                                handleClose();
                                                resetAddedClaims();
                                            }}
                                            variant="contained"
                                            color="default"
                                            disabled={addClaimsPending}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={addClaimsPending}
                                        >
                                            {addClaimsPending
                                                ? "Saving Changes"
                                                : "Validate"}
                                        </Button>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Loadable>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default withRouter(AddClientsDialog);
