import { createSelector } from 'reselect';
import { orderBy } from 'lodash';

export const selectDailyStatistics = (state) => state.statistics.stats;
export const selectFiles = (state) => state.statistics.files;
export const selectFilters = (state) => state.statistics.filters;

export const selectStatistics = createSelector(
    selectDailyStatistics,
    (statistics) => statistics,
);

export const createStatisticSelector = (selectClientId) => createSelector(
    selectClientId,
    selectStatistics,
    (clientId, statistics) => {
        return statistics.reduce((result, stat) => {
            return result;
        });
    }
);

export const selectStatisticsObject = createSelector(
    selectStatistics,
    (statistics) => {
        if (statistics && statistics.length > 0) {
            return statistics.reduce((result, stat) => {
                return {
                    totalFilesUploaded: result.totalFilesUploaded + stat.totalFilesUploaded,
                    totalFilesFailed: result.totalFilesFailed + stat.totalFilesFailed,
                    totalFilesSucceeded: result.totalFilesSucceeded + stat.totalFilesSucceeded,
                    totalTransactionFilesProcessed: result.totalTransactionFilesProcessed + stat.totalTransactionFilesProcessed,
                };
            });
        } else {
            return {
                totalFilesUploaded: 0,
                totalFilesFailed: 0,
                totalFilesSucceeded: 0,
                totalTransactionFilesProcessed: 0,
            };
        }
    }
);

export const selectDataset = createSelector(
    selectStatistics,
    (statistics) => {
        let dataset = {
            labels: [],
            datasets: [
                {
                    label: 'Total Files Succeeded',
                    key: 'totalFilesSucceeded',
                    backgroundColor: 'rgba(52, 191, 163, 0.7)',
                    borderColor: 'rgb(52, 191, 163)',
                    borderWidth: 1,
                    data: [],
                },
                {
                    label: 'Total Files Failed',
                    key: 'totalFilesFailed',
                    backgroundColor: 'rgba(253, 57, 149, 0.7)',
                    borderColor: 'rgb(253, 57, 149)',
                    borderWidth: 1,
                    data: [],
                }
            ]
        };

        if (statistics && statistics.length > 0) {
            const sortedStatistics = orderBy(statistics, ['totalFilesSucceeded'], ['desc']);
            sortedStatistics.forEach(stats => {
                if (stats.totalFilesSucceeded === 0 && stats.totalFilesFailed === 0) {
                    return;
                }
                dataset.labels.push(stats.clientName.trim());
                dataset.datasets.forEach(element => {
                    element.data.push(stats[element.key]);
                });
            });
        }

        return dataset;
    }
)

export const selectStats = createSelector(
    selectStatistics,
    (statistics) => {
        return {
            labels: statistics.map(stat => stat.clientName),
            datasets: [
                {
                    label: 'Total Files Succeeded',
                    backgroundColor: '#34bfa3',
                    data: statistics.map(x => x.totalFilesSucceeded)
                },
                {
                    label: 'Total Files Failed',
                    backgroundColor: "#fd3995",
                    data: statistics.map(x => x.totalFilesFailed)
                }
            ]
        }
    }
)