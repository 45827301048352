import React, { useEffect, useState, useCallback } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Loadable } from '../../../../common';
import { alphaNumericPattern } from '../../../../util/validation';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import {
  Button, makeStyles, TextField, List,
  ListItem, ListItemSecondaryAction, ListItemText, Checkbox,
} from "@material-ui/core";
import { BackButton } from "../../../../common/BackButton";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '80%',
  },
  textArea: {
    width: '80%',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

export default function DashboardTypesEditPage(props) {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [hasCheckboxEdited, setHasCheckboxEdited] = React.useState(false);


  const {
    intl, initialValues, dashboardTypesGetPending, dashboardTypeId, dashboardType,
    dashboardTypesCreatePending, dashboardTypesUpdatePending, mode, filters,
    dashboardFilterListTinyPending,
    actions: { dashboardTypeGet, dashboardTypeCreate,
      dashboardTypeUpdate, dashboardFilterListTiny, dashboardTypesErrorReset } } = props;

  const classes = useStyles();
  const isEdit = mode === 'edit';

  const handleCallback = () => {
    props.history.push('/admin/dashboardTypes?persist=true');
  }

  const handleToggle = (clientId) => () => {
    setHasCheckboxEdited(true)
    const currentIndex = selectedFilters.indexOf(clientId);
    const newSelectedFilters = [...selectedFilters];

    if (currentIndex === -1) {
      newSelectedFilters.push(clientId);
    } else {
      newSelectedFilters.splice(currentIndex, 1);
    }
    setSelectedFilters(newSelectedFilters);
  };

  const handleGetCallback = useCallback(() => {
    if (dashboardType && dashboardType.filterIds.length > 0) {
      setSelectedFilters(dashboardType.filterIds);
    }
  }, [dashboardType]);

  useEffect(() => {
    if (mode !== 'edit') {
      dashboardFilterListTiny();
    }
  }, [dashboardFilterListTiny, mode]);

  useEffect(() => {
    if (mode === 'edit') {
      dashboardTypeGet(dashboardTypeId, handleGetCallback);
    }
  }, [handleGetCallback, mode, dashboardTypeGet, dashboardTypeId]);

  useEffect(() => {
    return function cleanup() {
      dashboardTypesErrorReset();
    }
  }, [dashboardTypesErrorReset]);

  return (
    <Loadable loading={dashboardTypesGetPending || dashboardTypesCreatePending || dashboardTypesUpdatePending || dashboardFilterListTinyPending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (!values.name.match(alphaNumericPattern)) {
            errors.name = "Only alpha numeric values are allowed";
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          let payload = {
            name: values.name,
            description: values.description,
            filterIds: selectedFilters,
          }
          if (mode === 'create') {
            dashboardTypeCreate(payload, handleCallback);
          }
          else {
            payload = {
              ...payload,
              id: values.id,
            }
            dashboardTypeUpdate(values.id, payload, handleCallback);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={isEdit ? 'Edit Dashboard Type' : 'Add new Dashboard Type'}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton text={(dirty || hasCheckboxEdited) ? "Cancel" : "Back"} defaultRoute="/admin/dashboardTypes?persist=true" />
                    <Button
                      variant="contained"
                      color="primary"
                      className='ml-3'
                      type="submit"
                      disabled={dashboardTypesCreatePending || dashboardTypesUpdatePending || (isEdit && !dirty && !hasCheckboxEdited)}
                    >
                      Save
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className="main-content">
                  <div className="row">
                    <div className="col-md-5">
                      <TextField
                        required
                        label="Name"
                        name="name"
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                      />
                      <TextField
                        label="Description"
                        name="description"
                        className={classes.textArea}
                        multiline
                        rows="4"
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        helperText={touched.description && errors.description}
                        error={Boolean(touched.description && errors.description)}
                      />
                    </div>
                    <div className="col-md-5">
                      <h4 className="text-left">Select Filters</h4>
                      <List dense>
                        {filters.map(({ id, name, datasourceName }) => {
                          const label = datasourceName ? `${name} (${datasourceName})` : name;
                          return (
                            <ListItem
                              key={id}
                              role={undefined}
                              dense
                              button
                              onClick={handleToggle(id)}
                            >
                              <ListItemText id={id} primary={label} />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  edge="start"
                                  onChange={handleToggle(id)}
                                  checked={selectedFilters.indexOf(id) !== -1}
                                  tabIndex={-1}
                                  inputProps={{ 'aria-labelledby': id }}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </form>
        )}
      </Formik>
    </Loadable>
  );
}
