import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import StepOneComponent from './StepOne';
import { getChargeMasterStats } from '../../../../store/actions/pricers';

export const StepOne = connect(
    () => {
        return (state, props) => ({
            stats: state.pricers.chargeMasterStats ?? [],
            getStatsPending: state.pricers.getChargeMasterStats.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getChargeMasterStats: getChargeMasterStats
        }, dispatch)
    })
)(injectIntl(StepOneComponent));