import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import LoginPageComponent from './LoginPage.js';
import { login, setIdleAlert } from '../../../store/actions/session';
import { selectLoginPending, selectLoginError, selectLoginErrorMessage } from '../../../store/selectors/session';
import { fromLocalStorage } from '../../../util/storage';

export const LoginPage = connect(
    () => {
        const selectInitialValues = createSelector(
            () => {
                return {
                    username: fromLocalStorage('username', null),
                    password: "",
                    rememberMe: fromLocalStorage('rememberMe', null) ? true : false,
                    showPassword: false,
                }
            }
        );
        return (state) => ({
            loginPending: selectLoginPending(state),
            clientProductPending: state.clients.getProducts.pending,
            loginError: selectLoginError(state),
            loginErrorMessage: selectLoginErrorMessage(state),
            initialValues: selectInitialValues(),
            idleAlert: state.session.idleAlert
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            sessionLogin: login,
            setIdleAlert: setIdleAlert
        }, dispatch)
    })
)(injectIntl(LoginPageComponent));