import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { createDRG, editDRG, getDRG } from '../../../store/actions/drg';
import { asIsoDate } from '../../../util/date';
import DRGEditPageComponent from './DRGEditPage';

const selectMode = (state, props) => props.match.params.id === 'new' ? 'create' : 'edit';
const selectDRGId = (state, props) => props.match.params.id;
const selectDRG = (state) => state.drg.drg;

export const DRGEditPage = injectIntl(
    connect(() => {
        const selectInitialValues = createSelector(
            selectMode,
            selectDRG,
            (mode, drg) => {
                if (mode === 'edit' && drg) {
                    return {
                        name: drg.name,
                        opLaborRalted: drg.opLaborRalted,
                        opNonlaborRalted: drg.opNonlaborRalted,
                        opWageIndex: drg.opWageIndex,
                        opCostOfLivingAdj: drg.opCostOfLivingAdj,
                        opImeOperatingAdjFactor: drg.opImeOperatingAdjFactor,
                        opDshOperatingAdjFactor: drg.opDshOperatingAdjFactor,
                        opLaborRelatedPortion: drg.opLaborRelatedPortion,
                        opNonlaborRelatedPortion: drg.opNonlaborRelatedPortion,
                        opCostOfChargeRatio: drg.opCostOfChargeRatio,
                        capFederalCapitalRate: drg.capFederalCapitalRate,
                        capLargeUrbanAddon: drg.capLargeUrbanAddon,
                        capCbsaGaf: drg.capCbsaGaf,
                        capCostOfLivingAdj: drg.capCostOfLivingAdj,
                        capImeOperatingAdjFactor: drg.capImeOperatingAdjFactor,
                        capDshOperatingAdjFactor: drg.capDshOperatingAdjFactor,
                        capCostToChargeRatio: drg.capCostToChargeRatio,
                        otherFixedLossOutlinerTheshold: drg.otherFixedLossOutlinerTheshold,
                        otherMarginalCostFactor: drg.otherMarginalCostFactor,
                        lowVolumnAdjustment: drg.lowVolumnAdjustment,
                        effectiveFrom: asIsoDate(drg.effectiveFrom),
                        effectiveTo: asIsoDate(drg.effectiveTo),
                    }
                }
                return {
                    name: "",
                    opLaborRalted: '',
                    opNonlaborRalted: '',
                    opWageIndex: '',
                    opCostOfLivingAdj: '',
                    opImeOperatingAdjFactor: '',
                    opDshOperatingAdjFactor: '',
                    opLaborRelatedPortion: '',
                    opNonlaborRelatedPortion: '',
                    opCostOfChargeRatio: '',
                    capFederalCapitalRate: '',
                    capLargeUrbanAddon: '',
                    capCbsaGaf: '',
                    capCostOfLivingAdj: '',
                    capImeOperatingAdjFactor: '',
                    capDshOperatingAdjFactor: '',
                    capCostToChargeRatio: '',
                    otherFixedLossOutlinerTheshold: '',
                    otherMarginalCostFactor: '',
                    lowVolumnAdjustment: 0,
                    effectiveFrom: '',
                    effectiveTo: '',
                }
            }
        )
        return (state, props) => ({
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            createPending: state.drg.create.pending,
            editPending: state.drg.update.pending,
            getPending: state.drg.get.pending,
            drgId: selectDRGId(state, props)
        })
    },
        (dispatch) => ({
            actions: bindActionCreators({
                createDRG: createDRG,
                getDRG: getDRG,
                editDRG: editDRG
            }, dispatch)
        })
    )(DRGEditPageComponent));