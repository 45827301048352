import {
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    METHOD_DELETE,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

const getPartners = () => {
    return fetch(`${SERVER_URL}/api/v1/partners`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPartner = (partnerId) => {
    return fetch(`${SERVER_URL}/api/v1/partner/${partnerId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createPartner = (partner) => {
    return fetch(`${SERVER_URL}/api/v1/partner`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(partner)
    }).then(apiHandleResponse);
};

const updatePartner = (partner) => {
    return fetch(`${SERVER_URL}/api/v1/partner`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(partner)
    }).then(apiHandleResponse);
};

const deletePartner = (partnerId) => {
    return fetch(`${SERVER_URL}/api/v1/partner/${partnerId}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
}

const activatePartner = (id) => {
    return fetch(`${SERVER_URL}/api/v1/partner/activate/${id}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const deactivatePartner = (id) => {
    return fetch(`${SERVER_URL}/api/v1/partner/deactivate/${id}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getPartnersTiny = () => {
    return fetch(`${SERVER_URL}/api/v1/partners/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPartnerDependencies = (partnerId) => {
    return fetch(`${SERVER_URL}/api/v1/partner/dependencies/${partnerId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPartnersClientsProducts = (partnerId) => {
    return fetch(`${SERVER_URL}/api/v2/partner/clients/product/${partnerId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getPartners,
    createPartner,
    updatePartner,
    getPartner,
    deletePartner,
    activatePartner,
    deactivatePartner,
    getPartnersTiny,
    getPartnerDependencies,
    getPartnersClientsProducts
};