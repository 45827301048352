import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import APIKeyEditPageComponent from './AppIdEditPage';
import { getUsersByRole } from '../../../../store/actions/users';
import { selectSelectedClient } from '../../../../store/selectors/clients';
import { selectUsersByRole } from '../../../../store/selectors/users';
import { createAppId } from '../../../../store/actions/appId';


export const APPIdEditPage = connect(
    () => {
        const selectInitialValues = createSelector(
            () => {
                return {
                    name: '',
                    description: ''
                }
            }
        );

        return (state, props) => ({
            initialValues: selectInitialValues(state, props),
            clientId: selectSelectedClient(state),
            users: selectUsersByRole(state, props),
            tinyList:state.apikey.tinyList,
            createPending: state.appId.create.pending,
            getUsersPending: state.users.getUsersByRole.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getUsersByRole: getUsersByRole,
            createAppId: createAppId,
        }, dispatch)
    })
)(injectIntl(APIKeyEditPageComponent));