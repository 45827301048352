import { createSelector } from 'reselect';

export const selectRulesList = (state, props) => state.rules.data;
export const selectMethodTypes = (state, props) => state.rules.methodTypes;
export const selectPaySources = (state, props) => state.rules.paySources
export const selectRuleTypes = (state, props) => state.rules.types;
export const selectGpcis = (state, props) => state.rules.gpcis;
export const selectPayMethods = (state, props) => state.rules.payMethods;
export const selectSelectedGroups = (state, props) => state.rules.selectedGroups;
export const selectCarveoutDescriptions = (state, props) => state.rules.carveOutCodesDescriptions;
export const selectMultiprocPrecedences = (state, props) => state.rules.multiprocPrecedences;
export const selectRuleStatus = (state, props) => state.rules.ruleStatus;

export const selectRules = createSelector(
    selectRulesList,
    (rules) => rules,
);

export const createRuleSelector = (selectRulesId) => createSelector(
    selectRulesId,
    selectRules,
    (ruleId, rules) => rules.find((rule) => rule.id === parseInt(ruleId))
);


