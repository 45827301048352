import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { listTiny } from '../../../../store/actions/clients';
import { create, update, get } from '../../../../store/actions/dashboards';
import { list as dashboardTypesList } from '../../../../store/actions/dashboardTypes';

import { selectClientListTinyWithoutSystem } from "../../../../store/selectors/clients";
import { createDashboardSelector } from "../../../../store/selectors/dashboards";
import { selectDashboardTypeList } from "../../../../store/selectors/dashboardTypes";

import DashboardConfigurationEditPageComponent from './DashboardConfigurationEditPage.js';

export const DashboardConfigurationEditPage = connect(
    () => {
        const selectDashboardId = (state, props) => props.match.params.dashboardId;

        const selectMode = (state, props) => props.match.params.dashboardId === 'new' ? 'create' : 'edit';

        const selectDashboard = createDashboardSelector(selectDashboardId);

        const selectInitialValues = createSelector(
            selectMode,
            selectDashboard,
            (mode, dashboard) => {
                if (mode === 'edit' && dashboard) {
                    return {
                        id: dashboard.id,
                        name: dashboard.name,
                        clientName: dashboard.clientName,
                        clientId: dashboard.clientId,
                        qS_Id: dashboard.qS_Id,
                        description: dashboard.description,
                        active: dashboard.active,
                        customFilters: dashboard.customFilters,
                        dashboardTypeId: dashboard.dashboardTypeId,
                        order: dashboard.order === 99999 ? '' : dashboard.order,
                    }
                }

                return {
                    name: '',
                    clientName: '',
                    clientId: '',
                    qS_Id: '',
                    description: '',
                    active: true,
                    customFilters: false,
                    dashboardTypeId: '',
                    order: '',
                }
            }
        )

        return (state, props) => ({
            //create dashboard
            dashboardsCreatePending: state.dashboards.create.pending,

            // update dashboard
            dashboardsUpdatePending: state.dashboards.update.pending,
            dashboardsGetPending: state.dashboards.get.pending,

            //dashboard types
            dashboardTypes: selectDashboardTypeList(state),
            dashboardTypeListPending: state.dashboardTypes.list.pending,

            // partners
            clients: selectClientListTinyWithoutSystem(state),

            // edit
            dashboard: selectDashboard(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            dashboardId: selectDashboardId(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            dashboardCreate: create,
            dashboardUpdate: update,
            dashboardGet: get,
            clientListTiny: listTiny,
            dashboardTypesList,
        }, dispatch)
    })
)(injectIntl(DashboardConfigurationEditPageComponent));