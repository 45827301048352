import { createSelector } from 'reselect';

export const selectPartnersList = (state) => state.partners.data;
export const selectPartnersTiny = (state) => state.partners.tiny;
export const selectPartnersProducts = (state) => state.partners.products;

export const selectPartners = createSelector(
    selectPartnersList,
    (partners) => partners,
);

export const createPartnerSelector = (selectPartnerId) => createSelector(
    selectPartnerId,
    selectPartners,
    (partnerId, partners) => {
        return partners.find((partner) => partner.id === parseInt(partnerId));
    }
);