import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export default function QuickStats(props) {
  const { title, desc, value, color = 'brand' } = props;
  return (
    <div className='kt-widget24'>
      <div className='kt-widget24__details'>
        <div className="kt-widget24__info" style={{ height: "70px" }}>
          <h4 className='kt-widget24__title'>
            {title}
          </h4>
          <span className='kt-widget24__desc'>
            {desc}
          </span>
        </div>
        <span style={{ height: "70px" }} className={classnames('kt-widget24__stats', {
          'kt-font-brand': color === 'brand',
          'kt-font-danger': color === 'danger',
          'kt-font-success': color === 'success',
          'kt-font-warning': color === 'warning'
        })}>
          {value}
        </span>
      </div>
    </div>
  );
}

QuickStats.prototype = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}