import { createReducer } from '../../util';
import * as actions from '../actions/modeling';
import { StatusState } from './common';

const initialStatusState  : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    anaylsisResultsDialog: null,
    claims: [],
    analysisResults: [],
    model: null,
    contractModelEditDialog: null, //{open:true,model:obj, mode: 'edit'}
    modelStats: {},
    stopExecutionDialog: null,
    modelAllDetails: null,
    consolidatedClaims: [],
    searchClaimsForModeling: {
        ...initialStatusState,
    },
    stop: {
        ...initialStatusState,
    },
    modelEstimate: {
        ...initialStatusState,
    },
    analyzeModelEstimate: {
        ...initialStatusState,
    },
    lockModel: {
        ...initialStatusState,
    },
    unlockModel: {
        ...initialStatusState,
    },
    getAnalysisResults: {
        ...initialStatusState,
    },
    getModelById: {
        ...initialStatusState
    },
    getModelStats: {
        ...initialStatusState
    },
    getModelAllDetails: {
        ...initialStatusState
    },
    getModelCClaims: {
        ...initialStatusState
    },
};

export default createReducer(initialState, {
    [actions.MODEL_SEARCH_CLAIMS_PENDING]: (state) => ({
        ...state,
        searchClaimsForModeling: {
            pending: true,
        },
    }),
    [actions.MODEL_SEARCH_CLAIMS_FULFILLED]: (state, claims) => ({
        ...state,
        searchClaimsForModeling: {
            ...initialStatusState,
        },
        claims,
    }),
    [actions.MODEL_SEARCH_CLAIMS_REJECTED]: (state, errorMessage) => ({
        ...state,
        searchClaimsForModeling: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MODEL_ESTIMATE_PENDING]: (state) => ({
        ...state,
        modelEstimate: {
            pending: true,
        },
    }),
    [actions.MODEL_ESTIMATE_FULFILLED]: (state) => ({
        ...state,
        modelEstimate: {
            ...initialStatusState,
        },
    }),
    [actions.MODEL_ESTIMATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        modelEstimate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MODEL_LOCK_PENDING]: (state) => ({
        ...state,
        lockModel: {
            pending: true,
        },
    }),
    [actions.MODEL_LOCK_FULFILLED]: (state) => ({
        ...state,
        lockModel: {
            ...initialStatusState,
        },
    }),
    [actions.MODEL_LOCK_REJECTED]: (state, errorMessage) => ({
        ...state,
        lockModel: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MODEL_UNLOCK_PENDING]: (state) => ({
        ...state,
        unlockModel: {
            pending: true,
        },
    }),
    [actions.MODEL_UNLOCK_FULFILLED]: (state) => ({
        ...state,
        unlockModel: {
            ...initialStatusState,
        },
    }),
    [actions.MODEL_UNLOCK_REJECTED]: (state, errorMessage) => ({
        ...state,
        unlockModel: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MODEL_ANALYSIS_LANDING_PENDING]: (state) => ({
        ...state,
        getAnalysisResults: {
            pending: true,
        },
    }),
    [actions.MODEL_ANALYSIS_LANDING_FULFILLED]: (state, analysisResults) => ({
        ...state,
        getAnalysisResults: {
            ...initialStatusState,
        },
        analysisResults
    }),
    [actions.MODEL_ANALYSIS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        getAnalysisResults: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MODEL_GET_BY_ID_PENDING]: (state) => ({
        ...state,
        getModelById: {
            pending: true,
        },
    }),
    [actions.MODEL_GET_BY_ID_FULFILLED]: (state, model) => ({
        ...state,
        getModelById: {
            ...initialStatusState,
        },
        model
    }),
    [actions.MODEL_GET_BY_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        getModelById: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MODEL_ANALYZE_ESTIMATE_PENDING]: (state) => ({
        ...state,
        analyzeModelEstimate: {
            pending: true,
        },
    }),
    [actions.MODEL_ANALYZE_ESTIMATE_FULFILLED]: (state) => ({
        ...state,
        analyzeModelEstimate: {
            ...initialStatusState,
        }
    }),
    [actions.MODEL_ANALYZE_ESTIMATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        analyzeModelEstimate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MODEL_SET_ANALYSIS_DIALOG]: (state, { anaylsisResultsDialog }) => ({
        ...state,
        anaylsisResultsDialog
    }),
    [actions.CONTRACT_MODEL_SET_EDIT_DIALOG]: (state, { contractModelEditDialog }) => ({
        ...state,
        contractModelEditDialog
    }),
    [actions.MODEL_EXECUTION_RESULTS_STATISTICS_PENDING]: (state) => ({
        ...state,
        getModelStats: {
            pending: true,
        },
    }),
    [actions.MODEL_EXECUTION_RESULTS_STATISTICS_FULFILLED]: (state, modelStats) => ({
        ...state,
        modelStats,
        getModelStats: {
            ...initialStatusState,
        }
    }),
    [actions.MODEL_EXECUTION_RESULTS_STATISTICS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getModelStats: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.STOP_EXECUTION_MODELING_PENDING]: (state) => ({
        ...state,
        stop: {
            pending: true,
        },
    }),
    [actions.STOP_EXECUTION_MODELING_FULFILLED]: (state, allowablesList) => ({
        ...state,
        stop: {
            ...initialStatusState,
        },
    }),
    [actions.STOP_EXECUTION_MODELING_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        stop: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),

    [actions.STOP_EXECUTION_MODELING_DIALOG]: (state, { stopExecutionDialog }) => ({
        ...state,
        stopExecutionDialog
    }),

    [actions.MODEL_GET_MODEL_ALL_DETAILS_PENDING]: (state) => ({
        ...state,
        getModelAllDetails: {
            pending: true,
        },
    }),
    [actions.MODEL_GET_MODEL_ALL_DETAILS_FULFILLED]: (state, modelAllDetails) => {

        const totalExpectedPayNames = [];
        const totalExpectedPayValues = [];

        for(const key in modelAllDetails) {
            if(key.endsWith('- Total Expected Pay')) { 
                totalExpectedPayNames.push(key);
                totalExpectedPayValues.push(modelAllDetails[key]);
            }
        }

        return {
            ...state,
            getModelAllDetails: {
                ...initialStatusState,
            },
            modelAllDetails: { 
                analysisIds: modelAllDetails?.AnalysisIds,
                chargedAmount: modelAllDetails?.ChargedAmount,
                consolidatedClaims: modelAllDetails?.ConsolidatedClaims,
                servicedate: modelAllDetails?.Servicedate,
                updatedAt: modelAllDetails?.UpdatedAt,
                updatedBy: modelAllDetails?.UpdatedBy,
                visit: modelAllDetails?.Visit,
                claimnumber: modelAllDetails?.ClaimNumber,
                totalExpectedPayNames: totalExpectedPayNames,
                totalExpectedPayValues: totalExpectedPayValues,
            }
        }
    },
    [actions.MODEL_GET_MODEL_ALL_DETAILS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getModelAllDetails: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODEL_GET_CONSOLIDATED_CLAIMS_PENDING]: (state) => ({
        ...state,
        getModelCClaims: {
            pending: true,
        },
    }),
    [actions.MODEL_GET_CONSOLIDATED_CLAIMS_FULFILLED]: (state, consolidatedClaims) => ({
        ...state,
        getModelCClaims: {
            ...initialStatusState,
        },
        consolidatedClaims
    }),
    [actions.MODEL_GET_CONSOLIDATED_CLAIMS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getModelCClaims: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MODEL_RESET_CONSOLIDATED_CLAIMS]: (state) => ({
        ...state,
        consolidatedClaims:  []
    }),
    
});