import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { list as getFacilities } from "../../store/actions/facilities";
import { resetPage, vendorContractsLanding } from '../../store/actions/pages';
import { getByName, reset } from '../../store/actions/search';
import { deleteVendor } from '../../store/actions/vendors';
import { dateEquators, StringEquators } from '../../store/constants/pages';
import {
    createPlainDateField,
    createStringDataField, createStringFieldFromArrayMapping
} from '../../util/format';
import VendorsPageComponent from './VendorsPage';

export const selectFacilityList = (state) => state.facilities.data;

const selectFields = createSelector(
    selectFacilityList,
    (facilitiesArray) => {
        return [
            createStringDataField('name', 'Contract Name', { sortable: true, equators: StringEquators }),
            createPlainDateField('effectiveDate', 'Effective Date', { sortable: true, equators: dateEquators }),
            createPlainDateField('terminationDate', 'Termination Date', { sortable: true, equators: dateEquators }),
            // createBooleanPayerDataField('isPayer', 'Type', { hideFromFilter: true }),
            createStringFieldFromArrayMapping('contractFacilityXref', 'Facilities', facilitiesArray, 'facilityId', { hideFromFilter: true, truncate: true }),
            createStringDataField('department', 'Department', { sortable: true, equators: StringEquators }),
            createStringDataField('location', 'Location', { sortable: true, equators: StringEquators }),
            createStringDataField('glCode', 'GL Code', { sortable: true, equators: StringEquators }),
            createStringDataField('resourceType', 'Resource Type', { sortable: true, equators: StringEquators }),

        ]
    }
);

const selectVendorsData = createSelector(
    (state) => state.pages['vendor-contracts-landing'].list,
    (data) => {
        return data
    }
);

export const VendorsPage = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            vendorContractsData: selectVendorsData(state),
            deleteVendorPending: state.vendors.deleteVendor.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            vendorContractsLanding: vendorContractsLanding,
            getFacilities: getFacilities,
            searchGetByName: getByName,
            searchReset: reset,
            resetPagedTable: resetPage,
            deleteVendor: deleteVendor
        }, dispatch)
    })
)(injectIntl(VendorsPageComponent));