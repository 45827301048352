import { FieldArray } from "formik";
import React, { useState } from "react";

import {
    Button, Grid,
    IconButton,
    InputAdornment, makeStyles,
    TextField
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";
import { asDate, asIsoDate, dateRangeOverlaps } from "../../../../util/date";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl: {
        width: '85%',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    autoComplete: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    effectiveBox: {
        height: '100%',
        paddingTop: '25px'
    }
}));

export default function ContractEAPGSelectionCriteriaFieldArray({
    values, touched, errors, eapgList, setFieldValue,
}) {
    const [customErrors, setCustomErrors] = useState([]);

    const classes = useStyles();

    function getError(key, index) {
        if (errors.contractEapgconfigurationXref) {
            if (errors.contractEapgconfigurationXref[index]) {
                return errors.contractEapgconfigurationXref[index][key];
            }
            return false;
        }
        return false;
    }

    function handleEAPGChangeHandler(index, criteriaObj) {
        if (criteriaObj) {
            setFieldValue(`contractEapgconfigurationXref.${index}.eapgConfigurationId`, criteriaObj);

            const tempXref = [...values.contractEapgconfigurationXref];
            tempXref[index].eapgConfigurationId = criteriaObj;

            const filteredConfgs = tempXref.filter(item => item.eapgConfigurationId)
            const dateAlreadyExist = filteredConfgs.find(item => {
                const isDateOverlapping = dateRangeOverlaps(criteriaObj.effectiveFrom, criteriaObj.effectiveTill, item.eapgConfigurationId.effectiveFrom, item.eapgConfigurationId.effectiveTill)

                return isDateOverlapping && item.eapgConfigurationId.id !== criteriaObj.id;
            });

            if (dateAlreadyExist) {
                const temp = [...customErrors];
                temp[index] = 'Configuration with same date range already exists. Please select different configuration.'
                setCustomErrors(temp);
                setFieldValue(`contractEapgconfigurationXref.${index}.customEAPGError`, 'error');
            } else {
                setFieldValue(`contractEapgconfigurationXref.${index}.customEAPGError`, undefined);
                const temp = [...customErrors];
                temp[index] = null
                setCustomErrors(temp);
            }
        } else {
            setFieldValue(`contractEapgconfigurationXref.${index}.eapgConfigurationId`, criteriaObj);
            setFieldValue(`contractEapgconfigurationXref.${index}.effectiveFrom`, '');

            const temp = [...customErrors];
            temp[index] = null
            setCustomErrors(temp);
            setFieldValue(`contractEapgconfigurationXref.${index}.customEAPGError`, undefined);
        }

    }

    function handlePercentageChage(e, index) {
        setFieldValue(`contractEapgconfigurationXref.${index}.percentage`, e.target.value);
    }

    function handleEffectiveFromChange(e, index) {
        setFieldValue(`contractEapgconfigurationXref.${index}.effectiveFrom`, asIsoDate(e.target.value));
    }

    function handleClearFields() {
        setFieldValue(`contractEapgconfigurationXref`, [{
            eapgConfigurationId: null,
            equator: '',
            value: '',
            percentage: '100',
            effectiveFrom: ''
        }]);

        setCustomErrors([]);
    }

    return (
        <FieldArray
            name="contractEapgconfigurationXref"
            render={({ remove, insert }) => (
                <Grid container>
                    <Grid item xs={12}>
                        {values.contractEapgconfigurationXref && values.contractEapgconfigurationXref.map((eapgCriteria, index) =>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        className={classes.autoComplete}
                                        value={eapgCriteria?.eapgConfigurationId}
                                        disableClearable={false}
                                        onChange={(e, value) => { handleEAPGChangeHandler(index, value) }}
                                        getOptionSelected={(option, value) => option === value}
                                        options={eapgList}
                                        getOptionLabel={(option) => `${option.name}`}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                name={`contractEapgconfigurationXref.${index}.eapgConfigurationId`}
                                                label="EAPG"
                                                margin="normal"
                                                error={customErrors[index]}
                                                helperText={customErrors[index]}
                                            />
                                        }
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    {eapgCriteria?.eapgConfigurationId &&
                                        <Grid className={classes.effectiveBox}>
                                            <Grid><b>Effective Range</b></Grid>
                                            <span>
                                                {asDate(eapgCriteria?.eapgConfigurationId?.effectiveFrom)}
                                                {' to '}
                                                {asDate(eapgCriteria?.eapgConfigurationId?.effectiveTill)}
                                            </span>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        type="date"
                                        label="Effective From"
                                        name={`contractEapgconfigurationXref.${index}.effectiveFrom`}
                                        className={classes.textField}
                                        margin="normal"
                                        value={eapgCriteria.effectiveFrom}
                                        onChange={e => handleEffectiveFromChange(e, index)}
                                        helperText={getError('effectiveFrom', index)}
                                        error={getError('effectiveFrom', index)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        type="number"
                                        label="Percentage"
                                        name={`contractEapgconfigurationXref.${index}.percentage`}
                                        className={classes.textField}
                                        margin="normal"
                                        value={eapgCriteria.percentage}
                                        onChange={e => handlePercentageChage(e, index)}
                                        helperText={getError('percentage', index)}
                                        error={getError('percentage', index)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                {index === 0 && (
                                    <Grid item xs={3}>
                                        <Grid container>
                                            <IconButton
                                                edge="start"
                                                color="primary"
                                                aria-label="Add"
                                                onClick={() => insert(values.contractEapgconfigurationXref.length, {
                                                    eapgConfigurationId: null,
                                                    effectiveFrom: '',
                                                    percentage: 100,
                                                })}
                                                className={classes.iconButton}>
                                                <AddCircleIcon />
                                            </IconButton>
                                            <Button
                                                size="small"
                                                onClick={handleClearFields}
                                                className={classes.clearButton}
                                                startIcon={<DeleteIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}

                                {index !== 0 && (
                                    <Grid item xs={1}>
                                        {values.contractEapgconfigurationXref.length > 1 && (
                                            <IconButton
                                                edge="start"
                                                aria-label="Delete"
                                                onClick={() => {
                                                    remove(index);
                                                    const temp = [...customErrors];
                                                    temp[index] = null
                                                    setCustomErrors(temp);
                                                    setFieldValue(`contractEapgconfigurationXref.${index}.customEAPGError`, undefined);
                                                }}
                                                className={classes.iconButton}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        />
    );
}
