import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import PayerInfoComponent from './PayerInfoPage';

import { resetPage, set } from '../../store/actions/pages';
import { getPayerContractsTiny, payerEditReset, selectDeselectAll, setClaimDependencyDialog, setPayerInfoEditDialog, setPayerInfoEnableDialog, updatePayerStatus } from '../../store/actions/payers';
import { integerEquators, StringEquators } from '../../store/constants/pages';
import { selectPayerContracts } from '../../store/selectors/payers';
import { createCurrencyDataField, createIconDataField, createNumberDataField, createStringDataField } from '../../util/format';

import { payersLanding } from '../../store/actions/pages';
import { setV2 } from '../../store/actions/pagesV2';
import { getByName, reset } from '../../store/actions/search';
import { selectSessionRole } from '../../store/selectors/session';

const selectFields = createSelector(
    selectPayerContracts,
    (payerContracts) => [
        createStringDataField('name', 'Payer Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('type', 'Type', { sortable: true, equators: StringEquators }),
        createStringDataField('payerContract.displayName', 'Payer Contract', { isDropdown: true, dropdownValues: payerContracts.map(pc => pc.displayName), sortable: true, equators: StringEquators, secondLevel: 'displayName' }),
        createStringDataField('claimTypes', 'Claim Types', { sortable: true, equators: StringEquators }),
        createNumberDataField('totalClaimCount', 'Total Claims', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('totalClaimAmount', 'Total Claim Amount', { sortable: true, equators: integerEquators }),
        createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
    ]
);

const selectPayersData = createSelector(
    (state) => state.pages['payerinfo-landing'].list,
    (data) => data
);

export const selectCheckboxStatus = createSelector(
    selectPayersData,
    (payers) => {
        const enabledPayers = payers.filter(payer => payer.active).length;
        if (payers.length === enabledPayers) {
            return true;
        }
        return false;
    }
);


export const PayerInfoPage = connect(
    () => {
        return (state, props) => ({
            payerListPending: state.payers.list.pending,
            payerData: selectPayersData(state),
            payerFields: selectFields(state),
            checkboxStatus: selectCheckboxStatus(state),
            payerContracts: selectPayerContracts(state),
            userRole: selectSessionRole(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payersLanding: payersLanding,
            updatePayerStatus: updatePayerStatus,
            selectDeselectAllPayers: selectDeselectAll,
            payersGetPayerContractsTiny: getPayerContractsTiny,
            searchGetByName: getByName,
            searchReset: reset,
            payerEditReset: payerEditReset,
            resetPagedTable: resetPage,
            setPayerInfoEditDialog: setPayerInfoEditDialog,
            setPayerInfoEnableDialog: setPayerInfoEnableDialog,
            setList: (value) => set('payerinfo-landing', 'list', value),
            setSelectedItems: (value) => setV2('payerinfo-landing', 'selected', value),
            setClaimDependencyDialog: setClaimDependencyDialog,
        }, dispatch)
    })
)(injectIntl(PayerInfoComponent));