import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/allowables';
import * as API from '../api/allowables';
import { sessionErrorHandling } from './session';

function* getList(action) {
    try {
        yield put({ type: actions.ALLOWABLES_PENDING });
        const payload = yield call(API.getAllowablesList);
        yield put({ type: actions.ALLOWABLES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ALLOWABLES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateStatus(action) {
    try {
        yield put({ type: actions.ALLOWABLES_UPDATE_STATUS_PENDING });
        const {
            id,
            enabled
        } = action.payload;
        yield call(API.updateAllowablesStatus, id, enabled);
        yield put({ type: actions.ALLOWABLES_UPDATE_STATUS_FULFILLED, payload: { id: id, enabled: enabled } });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ALLOWABLES_UPDATE_STATUS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* selectDeselectAll(action) {
    try {
        yield put({ type: actions.ALLOWABLES_SELECT_ALL_PENDING });
        const {
            payload,
            from,
            to,
            callback,
        } = action.payload;
        yield call(API.selectDeselectAll, payload);
        yield put({ type: actions.ALLOWABLES_SELECT_ALL_FULFILLED });
        if (callback) {
            callback(from, to);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.ALLOWABLES_SELECT_ALL_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.ALLOWABLES_RESET });
}

export default function*() {
    yield takeEvery(actions.ALLOWABLES_LIST, getList);
    yield takeEvery(actions.ALLOWABLES_UPDATE_STATUS, updateStatus);
    yield takeEvery(actions.ALLOWABLES_SELECT_ALL, selectDeselectAll);
}