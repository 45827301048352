import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ToleranceMatchDialogComponent from './ToleranceMatchDialog';
import { remitPayerMapping, remitPayerToleranceMatch } from '../../../store/actions/payers';

export const ToleranceMatchDialog = connect(
    () => {
        const selectEnableDialog = (state) => state.payers.toleranceMatchDialog;

        const selectOpen = createSelector(
            selectEnableDialog,
            (toleranceMatchDialog) => {
                if (toleranceMatchDialog && toleranceMatchDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            toleranceMatchPending: state.payers.toleranceMatch.pending,
            remitPayerMappingPending: state.payers.remitPayerMapping.pending,
            // toleranceMatchResult: [
            //     {
            //         "name": "UNITED UHc",
            //         "contractNames": [
            //             "United Healthcare", "Humana"
            //         ]
            //     },
            //     {
            //         "name": "CIGNA rm",
            //         "contractNames": [
            //             "CIGNA", "Humana", "Blue Cross"
            //         ]
            //     }
            // ],
            toleranceMatchResult: state.payers.toleranceMatchResult
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            remitPayerToleranceMatch: remitPayerToleranceMatch,
            remitPayerMapping: remitPayerMapping
        }, dispatch)
    })
)(injectIntl(ToleranceMatchDialogComponent));