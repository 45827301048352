export const VENDORS_GET_LOCATIONS = 'VENDORS_GET_LOCATIONS';
export const VENDORS_GET_LOCATIONS_PENDING = 'VENDORS_GET_LOCATIONS_PENDING';
export const VENDORS_GET_LOCATIONS_FULFILLED = 'VENDORS_GET_LOCATIONS_FULFILLED';
export const VENDORS_GET_LOCATIONS_REJECTED = 'VENDORS_GET_LOCATIONS_REJECTED';

export const VENDORS_GET_DEPARTMENTS = 'VENDORS_GET_DEPARTMENTS';
export const VENDORS_GET_DEPARTMENTS_PENDING = 'VENDORS_GET_DEPARTMENTS_PENDING';
export const VENDORS_GET_DEPARTMENTS_FULFILLED = 'VENDORS_GET_DEPARTMENTS_FULFILLED';
export const VENDORS_GET_DEPARTMENTS_REJECTED = 'VENDORS_GET_DEPARTMENTS_REJECTED';

export const VENDORS_GET_GL_CODE = 'VENDORS_GET_GL_CODE';
export const VENDORS_GET_GL_CODE_PENDING = 'VENDORS_GET_GL_CODE_PENDING';
export const VENDORS_GET_GL_CODE_FULFILLED = 'VENDORS_GET_GL_CODE_FULFILLED';
export const VENDORS_GET_GL_CODE_REJECTED = 'VENDORS_GET_GL_CODE_REJECTED';

export const VENDORS_GET_RESOURCE_TYPES = 'VENDORS_GET_RESOURCE_TYPES';
export const VENDORS_GET_RESOURCE_TYPES_PENDING = 'VENDORS_GET_RESOURCE_TYPES_PENDING';
export const VENDORS_GET_RESOURCE_TYPES_FULFILLED = 'VENDORS_GET_RESOURCE_TYPES_FULFILLED';
export const VENDORS_GET_RESOURCE_TYPES_REJECTED = 'VENDORS_GET_RESOURCE_TYPES_REJECTED';

export const VENDORS_CONTRACT_GET = 'VENDORS_CONTRACT_GET';
export const VENDORS_CONTRACT_GET_PENDING = 'VENDORS_CONTRACT_GET_PENDING';
export const VENDORS_CONTRACT_GET_FULFILLED = 'VENDORS_CONTRACT_GET_FULFILLED';
export const VENDORS_CONTRACT_GET_REJECTED = 'VENDORS_CONTRACT_GET_REJECTED';

export const VENDORS_CONTRACT_DOCUMENTS_LIST = 'VENDORS_CONTRACT_DOCUMENTS_LIST';
export const VENDORS_CONTRACT_DOCUMENTS_LIST_PENDING = 'VENDORS_CONTRACT_DOCUMENTS_LIST_PENDING';
export const VENDORS_CONTRACT_DOCUMENTS_LIST_FULFILLED = 'VENDORS_CONTRACT_DOCUMENTS_LIST_FULFILLED';
export const VENDORS_CONTRACT_DOCUMENTS_LIST_REJECTED = 'VENDORS_CONTRACT_DOCUMENTS_LIST_REJECTED';

export const VENDORS_CONTRACT_CREATE = 'VENDORS_CONTRACT_CREATE';
export const VENDORS_CONTRACT_CREATE_PENDING = 'VENDORS_CONTRACT_CREATE_PENDING';
export const VENDORS_CONTRACT_CREATE_FULFILLED = 'VENDORS_CONTRACT_CREATE_FULFILLED';
export const VENDORS_CONTRACT_CREATE_REJECTED = 'VENDORS_CONTRACT_CREATE_REJECTED';

export const VENDORS_CONTRACT_UPDATE = 'VENDORS_CONTRACT_UPDATE';
export const VENDORS_CONTRACT_UPDATE_PENDING = 'VENDORS_CONTRACT_UPDATE_PENDING';
export const VENDORS_CONTRACT_UPDATE_FULFILLED = 'VENDORS_CONTRACT_UPDATE_FULFILLED';
export const VENDORS_CONTRACT_UPDATE_REJECTED = 'VENDORS_CONTRACT_UPDATE_REJECTED';

export const VENDORS_CONTRACT_UPLOAD = 'VENDORS_CONTRACT_UPLOAD';
export const VENDORS_CONTRACT_UPLOAD_PENDING = 'VENDORS_CONTRACT_UPLOAD_PENDING';
export const VENDORS_CONTRACT_UPLOAD_FULFILLED = 'VENDORS_CONTRACT_UPLOAD_FULFILLED';
export const VENDORS_CONTRACT_UPLOAD_REJECTED = 'VENDORS_CONTRACT_UPLOAD_REJECTED';

export const VENDORS_CONTRACT_DOCUMENT_GET = 'VENDORS_CONTRACT_DOCUMENT_GET';
export const VENDORS_CONTRACT_DOCUMENT_GET_PENDING = 'VENDORS_CONTRACT_DOCUMENT_GET_PENDING';
export const VENDORS_CONTRACT_DOCUMENT_GET_FULFILLED = 'VENDORS_CONTRACT_DOCUMENT_GET_FULFILLED';
export const VENDORS_CONTRACT_DOCUMENT_GET_REJECTED = 'VENDORS_CONTRACT_DOCUMENT_GET_REJECTED';

export const VENDORS_CONTRACT_DELETE = 'VENDORS_CONTRACT_DELETE';
export const VENDORS_CONTRACT_DELETE_PENDING = 'VENDORS_CONTRACT_DELETE_PENDING';
export const VENDORS_CONTRACT_DELETE_FULFILLED = 'VENDORS_CONTRACT_DELETE_FULFILLED';
export const VENDORS_CONTRACT_DELETE_REJECTED = 'VENDORS_CONTRACT_DELETE_REJECTED';

export const VENDORS_EDIT_RESET = 'VENDORS_EDIT_RESET';


export const getDepartments = (keyWord = '') => ({
    type: VENDORS_GET_DEPARTMENTS,
    payload: { options: { keyWord, infoType: 0 } },
});

export const getLocations = (keyWord = '') => ({
    type: VENDORS_GET_LOCATIONS,
    payload: { options: { keyWord, infoType: 1 } },
});

export const getResourceTypes = (keyWord = '') => ({
    type: VENDORS_GET_RESOURCE_TYPES,
    payload: { options: { keyWord, infoType: 2 } },
});

export const getGLCode = (keyWord = '') => ({
    type: VENDORS_GET_GL_CODE,
    payload: { options: { keyWord, infoType: 3 } },
});

export const getVendorContract = (contractId) => ({
    type: VENDORS_CONTRACT_GET,
    payload: { contractId },
});

export const getVendorDocumentList = (contractId) => ({
    type: VENDORS_CONTRACT_DOCUMENTS_LIST,
    payload: { contractId },
});

export const createVendorContract = (contract, callback) => ({
    type: VENDORS_CONTRACT_CREATE,
    payload: { contract, callback },
});

export const updateVendorContract = (id, contract, callback) => ({
    type: VENDORS_CONTRACT_UPDATE,
    payload: { id, contract, callback },
});
export const vendorUpload = (file, documentObj, callback) => ({
    type: VENDORS_CONTRACT_UPLOAD,
    payload: { file, documentObj, callback },
});

export const getVendorDocument = (documentId, documentName, metadata, callback) => ({
    type: VENDORS_CONTRACT_DOCUMENT_GET,
    payload: { documentId, documentName, metadata, callback },
});

export const vendorEditReset = () => ({
    type: VENDORS_EDIT_RESET
});

export const deleteVendor = (id, callback) => ({
    type: VENDORS_CONTRACT_DELETE,
    payload: { id, callback }
});
