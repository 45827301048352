import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import ConfirmationDialogComponent from './ConfirmationDialog';
import { bindActionCreators } from 'redux';

export const ConfirmationDialog = connect(
    () => {

        return (state, props) => ({
            open: state.claimAnalysis.claimAnalysisConfirmationDialog?.open,
            nextPreviousState:state.claimAnalysis.claimAnalysisConfirmationDialog?.state
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        
        }, dispatch)
    })
)(injectIntl(ConfirmationDialogComponent));