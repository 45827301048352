import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import EstimateFormComponent from './EstimateForm';
import { getPaginationFilter } from '../../../../../../util/pagination';
import { analyzeModelEstimate, modelEstimate } from '../../../../../../store/actions/modeling';

export const EstimateForm = connect(
    () => {
        const selectMode = (state, props) => props.mode;
        const selectModel = (state, props) => props.model;

        const selectInitialValues = createSelector(
            selectMode,
            selectModel,
            (mode, model) => {
                if (mode === 'edit' && model) {
                    return {
                        baseContract: model?.contractModelXref[0]?.contract,
                        modelContract: model?.contractModelXref[1]?.contract
                    }
                }
                return {
                    modelName: '',
                    baseContract: '',
                    modelContract: ''
                }
            }
        );

        const selectSelectedItems = createSelector(
            (state) => state.pagesV2['model-claim-selection-landing'].selected,
            (data) => {
                return data
            }
        );

        const selectFilter = createSelector(
            (state) => state.pages['model-claim-selection-landing'],
            (state, props) => props.fields,
            (table, fields) => {
                return getPaginationFilter(fields, table.filter, null, [], table.customFilter);
            }
        );

        return (state, props) => ({
            initialValues: selectInitialValues(state, props),
            selectedItems: selectSelectedItems(state),
            filter: state.pages['model-claim-selection-landing'].filter,
            sort: state.pages['model-claim-selection-landing'].sort,
            page: state.pages['model-claim-selection-landing'].page,
            formattedFilter: selectFilter(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            modelEstimate: modelEstimate,
            analyzeModelEstimate: analyzeModelEstimate
        }, dispatch)
    })
)(injectIntl(EstimateFormComponent));