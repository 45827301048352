import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import FallbackPageComponent from './FallbackPage';
import { selectSessionUser } from '../../../store/selectors/session';
import { selectRoleBasedMenu } from '../../../store/selectors/menu';
import { createSelector } from 'reselect';

export const FallbackPage = connect(

    () => {

        const selectMenu = createSelector(
            selectRoleBasedMenu,
            (menu) => {
                return menu.aside.items
            }
        )

        return (state, props) => ({
            user: selectSessionUser(state, props),
            menu: selectMenu(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(FallbackPageComponent));