import { createReducer } from '../../util';
import * as actions from '../actions/pricers';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    charges: [],
    allowables: [],
    publishMRFsRes: null,

    //V2
    dataV2: [],
    departments: [],
    facilities: [],
    negotiatedRates: [],
    allowableRates: [],
    contracts: [],
    rates: [],
    payPercentage: 100,
    oop: { outOfPocketExpense: 0 },
    chargeMasterStats: [],
    shoppablesStats: [],
    negotiatedRatesState: null,
    pricerConfigFromShoppables: false,
    signOff : null,
    lookup: {
        ...initialStatusState,
    },
    getCharges: {
        ...initialStatusState,
    },
    getAllowables: {
        ...initialStatusState,
    },
    // V2
    lookupV2: {
        ...initialStatusState,
    },
    getFacilities: {
        ...initialStatusState,
    },
    getDepartments: {
        ...initialStatusState,
    },
    getNegotiatedRates: {
        ...initialStatusState,
    },
    getAllowableRates: {
        ...initialStatusState,
    },
    getPayPercentage: {
        ...initialStatusState,
    },
    getOop: {
        ...initialStatusState,
    },
    exportCsv: {
        ...initialStatusState,
    },
    addBooking: {
        ...initialStatusState,
    },
    getChargeMasterStats: {
        ...initialStatusState,
    },
    getShoppablesStats: {
        ...initialStatusState,
    },
    updateShoppablesConfig: {
        ...initialStatusState,
    },
    getContracts: {
        ...initialStatusState,
    },
    processContracts: {
        ...initialStatusState,
    },
    getConfigRates: {
        ...initialStatusState
    },
    getNegotiatedRatesState: {
        ...initialStatusState
    },
    cleanupAndProcess: {
        ...initialStatusState
    },
    publishMRFs: {
        ...initialStatusState
    },
    getNegotiatedRatesSignOff: {
        ...initialStatusState
    },
    negotiatedRatesSignOff: {
        ...initialStatusState,
    },
};

export default createReducer(initialState, {
    [actions.PRICERS_LOOKUP_PENDING]: (state) => ({
        ...state,
        lookup: {
            pending: true,
        },
    }),
    [actions.PRICERS_LOOKUP_FULFILLED]: (state, data) => ({
        ...state,
        lookup: {
            ...initialStatusState,
        },
        data,
    }),
    [actions.PRICERS_LOOKUP_REJECTED]: (state, errorMessage) => ({
        ...state,
        lookup: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_GET_CHARGES_PENDING]: (state) => ({
        ...state,
        getCharges: {
            pending: true,
        },
    }),
    [actions.PRICERS_GET_CHARGES_FULFILLED]: (state, charges) => ({
        ...state,
        getCharges: {
            ...initialStatusState,
        },
        charges,
    }),
    [actions.PRICERS_GET_CHARGES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCharges: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_GET_ALLOWABLES_PENDING]: (state) => ({
        ...state,
        getAllowables: {
            pending: true,
        },
    }),
    [actions.PRICERS_GET_ALLOWABLES_FULFILLED]: (state, allowables) => ({
        ...state,
        getAllowables: {
            ...initialStatusState,
        },
        allowables,
    }),
    [actions.PRICERS_GET_ALLOWABLES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getAllowables: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_LOOKUP_V2_PENDING]: (state) => ({
        ...state,
        lookup: {
            pending: true,
        },
    }),
    [actions.PRICERS_LOOKUP_V2_FULFILLED]: (state, data) => ({
        ...state,
        lookup: {
            ...initialStatusState,
        },
        dataV2: data,
    }),
    [actions.PRICERS_LOOKUP_V2_REJECTED]: (state, errorMessage) => ({
        ...state,
        lookup: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_GET_FACILITIES_V2_PENDING]: (state) => ({
        ...state,
        getFacilities: {
            pending: true,
        },
    }),
    [actions.PRICERS_GET_FACILITIES_V2_FULFILLED]: (state, facilities) => ({
        ...state,
        getFacilities: {
            ...initialStatusState,
        },
        facilities,
    }),
    [actions.PRICERS_GET_FACILITIES_V2_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFacilities: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_GET_DEPARTMENTS_V2_PENDING]: (state) => ({
        ...state,
        getDepartments: {
            pending: true,
        },
    }),
    [actions.PRICERS_GET_DEPARTMENTS_V2_FULFILLED]: (state, departments) => ({
        ...state,
        getDepartments: {
            ...initialStatusState,
        },
        departments: [
            {
                name: 'All',
            },
            ...departments,
        ],
    }),
    [actions.PRICERS_GET_DEPARTMENTS_V2_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDepartments: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_GET_NEGOTIATED_RATES_V2_PENDING]: (state) => ({
        ...state,
        getNegotiatedRates: {
            pending: true,
        },
    }),
    [actions.PRICERS_GET_NEGOTIATED_RATES_V2_FULFILLED]: (state, negotiatedRates) => ({
        ...state,
        getNegotiatedRates: {
            ...initialStatusState,
        },
        negotiatedRates,
    }),
    [actions.PRICERS_GET_NEGOTIATED_RATES_V2_REJECTED]: (state, errorMessage) => ({
        ...state,
        getNegotiatedRates: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_GET_ALOWABLES_V2_PENDING]: (state) => ({
        ...state,
        getAllowableRates: {
            pending: true,
        },
    }),
    [actions.PRICERS_GET_ALOWABLES_V2_FULFILLED]: (state, allowableRates) => ({
        ...state,
        getAllowableRates: {
            ...initialStatusState,
        },
        allowableRates,
    }),
    [actions.PRICERS_GET_ALOWABLES_V2_REJECTED]: (state, errorMessage) => ({
        ...state,
        getAllowableRates: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_GET_SELF_PAY_PERCENTAGE_PENDING]: (state) => ({
        ...state,
        getPayPercentage: {
            pending: true,
        },
    }),
    [actions.PRICERS_GET_SELF_PAY_PERCENTAGE_FULFILLED]: (state, payPercentage) => ({
        ...state,
        getPayPercentage: {
            ...initialStatusState,
        },
        payPercentage,
    }),
    [actions.PRICERS_GET_SELF_PAY_PERCENTAGE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayPercentage: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_EXPORT_CSV_PENDING]: (state) => ({
        ...state,
        exportCsv: {
            pending: true,
        },
    }),
    [actions.PRICERS_EXPORT_CSV_FULFILLED]: (state) => ({
        ...state,
        exportCsv: {
            ...initialStatusState,
        },
    }),
    [actions.PRICERS_EXPORT_CSV_REJECTED]: (state, errorMessage) => ({
        ...state,
        exportCsv: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_GET_OOP_PENDING]: (state) => ({
        ...state,
        getOop: {
            pending: true,
        },
    }),
    [actions.PRICERS_GET_OOP_FULFILLED]: (state, { outOfPocketExpense }) => ({
        ...state,
        getOop: {
            ...initialStatusState,
        },
        oop: { outOfPocketExpense: outOfPocketExpense },
    }),
    [actions.PRICERS_GET_OOP_REJECTED]: (state, errorMessage) => ({
        ...state,
        getOop: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICERS_OOP_RESET]: (state) => ({
        ...state,
        oop: { outOfPocketExpense: 0 },
        getOop: {
            ...initialStatusState,
        }
    }),
    [actions.PRICERS_ADD_BOOKING_PENDING]: (state) => ({
        ...state,
        addBooking: {
            pending: true,
        },
    }),
    [actions.PRICERS_ADD_BOOKING_FULFILLED]: (state) => ({
        ...state,
        addBooking: {
            ...initialStatusState,
        },
    }),
    [actions.PRICERS_ADD_BOOKING_REJECTED]: (state, errorMessage) => ({
        ...state,
        addBooking: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_GET_CHARGE_MASTER_STATS_PENDING]: (state) => ({
        ...state,
        getChargeMasterStats: {
            pending: true,
        },
    }),
    [actions.PRICER_GET_CHARGE_MASTER_STATS_FULFILLED]: (state, chargeMasterStats) => ({
        ...state,
        getChargeMasterStats: {
            ...initialStatusState,
        },
        chargeMasterStats
    }),
    [actions.PRICER_GET_CHARGE_MASTER_STATS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getChargeMasterStats: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_GET_SHOPPABLES_STATS_PENDING]: (state) => ({
        ...state,
        getShoppablesStats: {
            pending: true,
        },
    }),
    [actions.PRICER_GET_SHOPPABLES_STATS_FULFILLED]: (state, shoppablesStats) => ({
        ...state,
        getShoppablesStats: {
            ...initialStatusState,
        },
        shoppablesStats
    }),
    [actions.PRICER_GET_SHOPPABLES_STATS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getShoppablesStats: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_UPDATE_SHOPPABLES_CONFIG_PENDING]: (state) => ({
        ...state,
        updateShoppablesConfig: {
            pending: true,
        },
    }),
    [actions.PRICER_UPDATE_SHOPPABLES_CONFIG_FULFILLED]: (state, shoppablesStats) => ({
        ...state,
        updateShoppablesConfig: {
            ...initialStatusState,
        },
        shoppablesStats
    }),
    [actions.PRICER_UPDATE_SHOPPABLES_CONFIG_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateShoppablesConfig: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.PRICER_CONFIG_GET_CONTRACTS_PENDING]: (state) => ({
        ...state,
        getContracts: {
            pending: true,
        },
    }),
    [actions.PRICER_CONFIG_GET_CONTRACTS_FULFILLED]: (state, contracts) => ({
        ...state,
        getContracts: {
            ...initialStatusState,
        },
        contracts
    }),
    [actions.PRICER_CONFIG_GET_CONTRACTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getContracts: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_CONFIG_PROCESS_CONTRACTS_PENDING]: (state) => ({
        ...state,
        processContracts: {
            pending: true,
        },
    }),
    [actions.PRICER_CONFIG_PROCESS_CONTRACTS_FULFILLED]: (state, rates) => ({
        ...state,
        processContracts: {
            ...initialStatusState,
        },
        rates
    }),
    [actions.PRICER_CONFIG_PROCESS_CONTRACTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        processContracts: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_CONFIG_GET_RATES_PENDING]: (state) => ({
        ...state,
        getConfigRates: {
            pending: true,
        },
    }),
    [actions.PRICER_CONFIG_GET_RATES_FULFILLED]: (state, rates) => ({
        ...state,
        getConfigRates: {
            ...initialStatusState,
        },
        rates
    }),
    [actions.PRICER_CONFIG_GET_RATES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getConfigRates: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_PENDING]: (state) => ({
        ...state,
        getNegotiatedRatesState: {
            pending: true,
        },
    }),
    [actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_FULFILLED]: (state, negotiatedRatesState) => ({
        ...state,
        getNegotiatedRatesState: {
            ...initialStatusState,
        },
        negotiatedRatesState
    }),
    [actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_STATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getNegotiatedRatesState: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_CONFIG_CLEANUP_PROCESS_PENDING]: (state) => ({
        ...state,
        cleanupAndProcess: {
            pending: true,
        },
    }),
    [actions.PRICER_CONFIG_CLEANUP_PROCESS_FULFILLED]: (state, rates) => ({
        ...state,
        cleanupAndProcess: {
            ...initialStatusState,
        },
        rates
    }),
    [actions.PRICER_CONFIG_CLEANUP_PROCESS_REJECTED]: (state, errorMessage) => ({
        ...state,
        cleanupAndProcess: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_CONFIG_PUBLISH_MRF_PENDING]: (state) => ({
        ...state,
        publishMRFs: {
            pending: true,
        },
    }),
    [actions.PRICER_CONFIG_PUBLISH_MRF_FULFILLED]: (state, publishMRFsRes) => ({
        ...state,
        publishMRFs: {
            ...initialStatusState,
        },
        publishMRFsRes
    }),
    [actions.PRICER_CONFIG_PUBLISH_MRF_REJECTED]: (state, errorMessage) => ({
        ...state,
        publishMRFs: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_CONFIG_CLEAR_RATES]: (state) => ({
        ...state,
        rates: []
    }),

    [actions.PRICERS_ERROR_RESET]: (state) => ({
        ...state,
        updateShoppablesConfig: {
            ...initialStatusState
        }
    }),

    [actions.PRICER_CONFIG_SET_FROM_SHOPPABLES]: (state, { pricerConfigFromShoppables }) => ({
        ...state,
        pricerConfigFromShoppables
    }),

    [actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_PENDING]: (state) => ({
        ...state,
        getNegotiatedRatesSignOff: {
            pending: true,
        },
    }),
    [actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_FULFILLED]: (state, signOff) => ({
        ...state,
        getNegotiatedRatesSignOff: {
            ...initialStatusState,
        },
        signOff
    }),
    [actions.PRICER_CONFIG_GET_NEGOTIATED_RATES_SIGN_OFF_REJECTED]: (state, errorMessage) => ({
        ...state,
        getNegotiatedRatesSignOff: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_PENDING]: (state) => ({
        ...state,
        negotiatedRatesSignOff: {
            pending: true,
        },
    }),
    [actions.PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_FULFILLED]: (state) => ({
        ...state,
        negotiatedRatesSignOff: {
            ...initialStatusState,
        }
    }),
    [actions.PRICER_CONFIG_NEGOTIATED_RATES_SIGN_OFF_REJECTED]: (state, errorMessage) => ({
        ...state,
        negotiatedRatesSignOff: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICERS_RESET]: () => ({ ...initialState }),
});