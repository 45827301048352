import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DashboardPageComponent from './dashboardPage.js';
import {
    selectDashboardListByClient,
    createDashboardUrlSelector,
    createDashboardUrlObjectSelector,
    selectDashboadUrlPending,
    selectDashboardFilters,
} from '../../store/selectors/dashboards';
import { getUrl, urlReset, setFilter } from '../../store/actions/dashboards';

export const DashboardPage = connect(
    () => {
        const selectDashboardId = (state, props) => props.match.params.dashboardId;

        const selectDashboardUrl = createDashboardUrlSelector(selectDashboardId);
        const selectDashboardUrlObject = createDashboardUrlObjectSelector(selectDashboardId);

        return (state, props) => ({
            dashboardUrl: selectDashboardUrl(state, props),
            dashboardUrlObject: selectDashboardUrlObject(state, props),
            dashboardId: selectDashboardId(state, props),
            dashboardList: selectDashboardListByClient(state, props),
            dashboardFilters: selectDashboardFilters(state),
            dashboadUrlPending: selectDashboadUrlPending(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            dashboardGetUrl: getUrl,
            dashboardUrlReset: urlReset,
            dashboardSetFilter: setFilter,
        }, dispatch)
    })
)(DashboardPageComponent);