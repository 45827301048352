import { createReducer } from '../../util';
import * as actions from '../actions/pricerPayers';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    payersList: [],
    list: {
        ...initialStatusState,
    },
    payerContracts: [],
    payerInfoEditDialog: null, //{open:true,payer:obj,bulk:true}
    infoEnableDialog: null, //{open:true,isEnableClicked:true}
    updateStatus: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    all: {
        ...initialStatusState,
    },
    payerCreateContract: {
        ...initialStatusState,
    },
    payerUpdateContract: {
        ...initialStatusState,
    },
    getPayerContractsTiny: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {
    [actions.PRICER_PAYERS_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.PRICER_PAYERS_FULFILLED]: (state, payersList) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        payersList,
    }),
    [actions.PRICER_PAYERS_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_PAYERS_UPDATE_STATUS_PENDING]: (state) => ({
        ...state,
        updateStatus: {
            pending: true,
        },
    }),
    [actions.PRICER_PAYERS_UPDATE_STATUS_FULFILLED]: (state, payload) => ({
        ...state,
        payersList: state.payersList.map(
            (payer, i) => payer.id === payload.id ? { ...payer, disabled: payload.enabled } : payer //update payer status on the base of ID
        ),
        updateStatus: {
            ...initialStatusState,
        },
    }),
    [actions.PRICER_PAYERS_UPDATE_STATUS_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_PAYER_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.PRICER_PAYER_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.PRICER_PAYER_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_PAYER_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.PRICER_PAYER_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.PRICER_PAYER_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_PAYER_CONTRACT_CREATE_PENDING]: (state) => ({
        ...state,
        payerCreateContract: {
            pending: true,
        },
    }),
    [actions.PRICER_PAYER_CONTRACT_CREATE_FULFILLED]: (state) => ({
        ...state,
        payerCreateContract: {
            ...initialStatusState,
        }
    }),
    [actions.PRICER_PAYER_CONTRACT_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        payerCreateContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_PAYER_CONTRACT_UPDATE_PENDING]: (state) => ({
        ...state,
        payerUpdateContract: {
            pending: true,
        },
    }),
    [actions.PRICER_PAYER_CONTRACT_UPDATE_FULFILLED]: (state) => ({
        ...state,
        payerUpdateContract: {
            ...initialStatusState,
        }
    }),
    [actions.PRICER_PAYER_CONTRACT_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        payerUpdateContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_PAYER_SELECT_ALL_PENDING]: (state) => ({
        ...state,
        all: {
            pending: true,
        },
    }),
    [actions.PRICER_PAYER_SELECT_ALL_FULFILLED]: (state) => ({
        ...state,
        all: {
            ...initialStatusState,
        }
    }),
    [actions.PRICER_PAYER_SELECT_ALL_REJECTED]: (state, errorMessage) => ({
        ...state,
        all: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_PENDING]: (state) => ({
        ...state,
        getPayerContractsTiny: {
            pending: true,
        },
    }),
    [actions.PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_FULFILLED]: (state, payerContracts) => ({
        ...state,
        getPayerContractsTiny: {
            ...initialStatusState,
        },
        payerContracts,
    }),
    [actions.PRICER_PAYER_GET_PAYER_CONTRACTS_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerContractsTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_INFO_SET_EDIT_DIALOG]: (state, { payerInfoEditDialog }) => ({
        ...state,
        payerInfoEditDialog
    }),
    [actions.PAYER_INFO_SET_ENABLE_DIALOG]: (state, { infoEnableDialog }) => ({
        ...state,
        infoEnableDialog
    }),
    [actions.PRICER_PAYERS_RESET]: () => ({ ...initialState }),
});