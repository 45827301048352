import React, { useEffect } from 'react';
import {
    Button,
    makeStyles,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import { Card } from 'react-bootstrap';
import { DataTable, Loadable } from '../../../../common';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    skipButton: {
        borderColor: '#ffc107',
        backgroundColor: '#ffc107',
        "&:hover": {
            borderColor: '#e0a800',
            backgroundColor: '#e0a800',
        },
    },
}));

const DEFAULT_SORT = [{ id: 'displayName', direction: 'asc' }];

export default function PayerContractStep(props) {
    const {
        processContractsPending, getContractsPending, handleNext, handleBack,
        order, sort, selectedItems, filter, contractsFields, contractsData, handleCallNegotiatedAPI,
        actions: { setSort, setOrder, getPricerConfigContracts,
            setSelected, processContracts, getConfigNegotiatedRates }
    } = props;
    const [isConfirmOpen, setConfirmOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        getPricerConfigContracts();
        return () => {
            setSelected([]);
        }
    }, [getPricerConfigContracts]);

    const handleRefresh = () => {
        getPricerConfigContracts();
    }

    const handleOpenConfirmDialog = () => {
        setConfirmOpen(true);
    }
    const handleCloseConfirmDialog = () => {
        setConfirmOpen(false);
    }
    const handleDialogConfirmation = () => {
        handleCloseConfirmDialog();
        handleCallNegotiatedAPI(false); // disable API call on 4th step
        const payload = selectedItems;
        processContracts(payload, handleCallback)
    }

    const handleSkip = () => {
        const payload = selectedItems;
        getConfigNegotiatedRates(payload, handleCallback);
    }

    const handleCallback = () => {
        handleNext();
    }


    return (
        <Loadable loading={processContractsPending || getContractsPending}>
            <Grid item xs={12} className="mt-3">
                <Card variant="outlined">
                    <Card.Header as="h5" className='text-center'>Contracts</Card.Header>
                    <Card.Body className='text-dark px-5 py-4'>
                        <Grid container>
                            <Grid item md={12} className="col-md-12">
                                <DataTable
                                    name="pricer-config-contracts-landing"
                                    fields={contractsFields}
                                    data={contractsData}
                                    handleRefresh={handleRefresh}
                                    defaultSort={DEFAULT_SORT}
                                    loading={false}
                                    title="Contracts"
                                    order={order}
                                    onOrder={setOrder}
                                    sort={sort}
                                    onSort={setSort}
                                    stickyHeader={true}
                                    hasCheckbox={true}
                                    showSelectAll={contractsData.some(contract => contract.disabledCheckbox === true) ? false : true}
                                    pageName="pricer-config-contracts-landing"
                                />
                            </Grid>
                        </Grid>
                        <Grid className='pt-2 justify-content-end' container>
                            <Button
                                size='sm'
                                onClick={handleBack}
                                variant="contained"
                                color="default"
                                className='mr-2'
                            >
                                Back
                            </Button>
                            <Button
                                size='sm'
                                onClick={handleSkip}
                                variant="contained"
                                className={`${classes.skipButton} mr-2`}
                                disabled={processContractsPending}
                            >
                                Skip
                            </Button>
                            <Button
                                size='sm'
                                onClick={handleOpenConfirmDialog}
                                variant="contained"
                                color="primary"
                                disabled={processContractsPending}
                            >
                                Start
                            </Button>
                        </Grid>
                    </Card.Body>
                </Card>
            </Grid>
            <Dialog open={isConfirmOpen} onClose={handleCloseConfirmDialog}>
                <DialogTitle>
                    Pricer
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedItems.length === 0
                            ? <p className='text-danger'>Please select atleast one contract for process</p>
                            : 'Are you sure to process negotiated rates from latest contracts?'
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDialogConfirmation}
                        disabled={selectedItems.length === 0}
                        color="primary">
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </Loadable>
    )
}