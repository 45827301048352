import {
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveOctet, headersAuthSendReceiveJson } from '../../store/headers';
import { apiHandleResponse, apiHandleOctetResponse } from '../../util/api';

const list = (options, significance) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analyses/${significance}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const get = (analysisId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/details/${analysisId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getConsolidatedClaims = (claimId,claimNumber) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analysis/claimdata${claimId===0 && claimNumber ? `/${claimId}/list/${claimNumber}`:`/${claimId}/list` }`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const acknowledge = (acknowledgePayload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/acknowledge`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(acknowledgePayload),
    }).then(apiHandleResponse);
};

const getStats = (getStatspayload, significance) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analyses/total/${significance}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(getStatspayload),
    }).then(apiHandleResponse);
};

const downloadReport = (payload,showSignificantVariance) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analysis/report/${showSignificantVariance}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveOctet(),
        body: JSON.stringify(payload),
    }).then(apiHandleOctetResponse);
};

const flag = (flagPayload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/flag`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(flagPayload),
    }).then(apiHandleResponse);
};

const processClaims = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/claims`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const processBulkClaims = (payload, significanceFlag) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/claims/criteria/${significanceFlag}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const getJSONView = (claimNumber) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/claimdata/${claimNumber}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const deleteByPayerContractId = (payerContractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analyses/${payerContractId}`, {
        method: METHOD_DELETE,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const deleteByContractId = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analyses/contract/${contractId}`, {
        method: METHOD_DELETE,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getClaimHistory = (analysisId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/history/${analysisId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getClaimAdjustments = (paymentId,analysisId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analysis/claimadjustments/${paymentId}/${analysisId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getServiceAdjustments = (servicePaymentId,analysisId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analysis/serviceadjustments/${servicePaymentId}/${analysisId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getDRGS = (analysisId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/drgs`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getCPTS = (query) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/cpts/${query}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getRevenueCodes = (analysisId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/revenue`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getAssociatedCharges = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/associatedcharges`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const estimate = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimate`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const estimateHistoryLanding = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/history`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const listClaimAudits = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/audits`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const processClaimsFromAudit = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/audit/claims/criteria`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getClaimServiceAdjustments = (paymentId,claimId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analysis/claimserviceadjustments/${paymentId}/${claimId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const calculateOutOfPocketCost = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimate/oop`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getEstimateById = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimate/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const editEstimateById = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimate/${options.estimationId}`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getDXCodes = (keyword) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/dxcodes/${keyword}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getClaimAllDetails = (analysisId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analyses/${analysisId}/false`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const processBulkOnDemand = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/bulkondemand`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const overrideContract = (payload) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analysis/override/contract`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const getClaimComments = (analysisId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analyses/comments/${analysisId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const removeOverridenContract = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/override/remove`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const getClaimStatuses = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/analyse/status/list`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const updateClaimStatus = (payload) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analyses/status`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};
const updateSingleClaimStatus = (payload,status,analysisId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analyse/${analysisId}/status/${status}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const listClaimStatusLogs = (analysisId) => {
    return fetch(`${SERVER_URL}/api/v2/cms/analyse/status/history/${analysisId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};



export {
    list,
    get,
    getConsolidatedClaims,
    acknowledge,
    getStats,
    downloadReport,
    flag,
    processClaims,
    processBulkClaims,
    getJSONView,
    deleteByPayerContractId,
    deleteByContractId,
    getClaimHistory,
    getClaimAdjustments,
    getServiceAdjustments,
    getDRGS,
    getCPTS,
    getRevenueCodes,
    getAssociatedCharges,
    estimate,
    estimateHistoryLanding,
    listClaimAudits,
    processClaimsFromAudit,
    getClaimServiceAdjustments,
    calculateOutOfPocketCost,
    getEstimateById,
    editEstimateById,
    getDXCodes,
    getClaimAllDetails,
    processBulkOnDemand,
    overrideContract,
    getClaimComments,
    removeOverridenContract,
    getClaimStatuses,
    updateClaimStatus,
    updateSingleClaimStatus,
    listClaimStatusLogs
};