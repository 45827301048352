import { takeEvery, put, call } from "redux-saga/effects";

import * as actions from "../actions/clients";
import * as API from "../api/clients";
import { sessionErrorHandling } from "./session";

function* list() {
    try {
        yield put({ type: actions.CLIENTS_LIST_PENDING });
        const payload = yield call(API.getClients);
        yield put({ type: actions.CLIENTS_LIST_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_LIST_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* listTiny(action) {
    try {
        yield put({ type: actions.CLIENTS_LIST_TINY_PENDING });
        const { callback } = action.payload;
        const payload = yield call(API.getClientsTiny);
        yield put({ type: actions.CLIENTS_LIST_TINY_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_LIST_TINY_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* get(action) {
    try {
        yield put({ type: actions.CLIENTS_GET_PENDING });
        const { clientId } = action.payload;
        const payload = yield call(API.getClient, clientId);
        yield put({ type: actions.CLIENTS_GET_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_GET_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.CLIENTS_CREATE_PENDING });
        const { client, callback } = action.payload;

        const newClient = yield call(API.createClient, client);
        yield put({ type: actions.CLIENTS_CREATE_FULFILLED, newClient });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: "Error occurred, please contact administrator."
        };
        yield put({
            type: actions.CLIENTS_CREATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.CLIENTS_UPDATE_PENDING });
        const { client, callback } = action.payload;

        const newClient = yield call(API.updateClient, client);
        yield put({ type: actions.CLIENTS_UPDATE_FULFILLED, newClient });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: "Error occurred, please contact administrator."
        };
        yield put({
            type: actions.CLIENTS_UPDATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* parserList(action) {
    try {
        const payload = yield call(API.getParsers);
        yield put({ type: actions.PARSERS_GET_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.PARSERS_GET_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* bucketAvailable(action) {
    try {
        const { index, bucketName } = action.payload;
        yield put({
            type: actions.BUCKET_CHECK_PENDING,
            payload: { index: index }
        });
        const payload = yield call(API.bucketAvailability, bucketName);
        yield put({
            type: actions.BUCKET_CHECK_FULFILLED,
            payload: { error: payload, index: index }
        });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.BUCKET_CHECK_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* ftpUserAvailable(action) {
    try {
        const { index, username } = action.payload;
        yield put({
            type: actions.FTPUSER_CHECK_PENDING,
            payload: { index: index }
        });
        const payload = yield call(API.ftpuserAvailability, username);
        yield put({
            type: actions.FTPUSER_CHECK_FULFILLED,
            payload: { error: payload, index: index }
        });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.FTPUSER_CHECK_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* deactivate(action) {
    try {
        yield put({ type: actions.CLIENTS_DEACTIVATE_PENDING });
        const { clientId, callback } = action.payload;
        const payload = yield call(API.deactivateClient, clientId);
        yield put({ type: actions.CLIENTS_DEACTIVATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_DEACTIVATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* activate(action) {
    try {
        yield put({ type: actions.CLIENTS_ACTIVATE_PENDING });
        const { clientId, callback } = action.payload;
        const payload = yield call(API.activateClient, clientId);
        yield put({ type: actions.CLIENTS_ACTIVATE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_ACTIVATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* createConfiguration(action) {
    try {
        const { clientConfigs, callback } = action.payload;
        yield put({ type: actions.CLIENTS_CONFIGURATION_CREATE_PENDING });
        const payload = yield call(API.createConfiguration, clientConfigs);
        yield put({
            type: actions.CLIENTS_CONFIGURATION_CREATE_FULFILLED,
            payload
        });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_CONFIGURATION_CREATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* createAccountConfiguration(action) {
    try {
        const { clientAccountConfigs, callback } = action.payload;
        yield put({ type: actions.CLIENTS_ACCOUNT_CONFIGURATION_CREATE_PENDING });
        const payload = yield call(API.createAccountConfiguration, clientAccountConfigs);
        yield put({
            type: actions.CLIENTS_ACCOUNT_CONFIGURATION_CREATE_FULFILLED,
            payload
        });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_ACCOUNT_CONFIGURATION_CREATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}


function* addClaims(action) {
    try {
        const { claims, callback } = action.payload;
        yield put({ type: actions.CLIENTS_ACCOUNT_ADD_CLAIMS_PENDING });
        const payload = yield call(API.addClaims, claims);
        yield put({
            type: actions.CLIENTS_ACCOUNT_ADD_CLAIMS_FULFILLED,
            payload
        });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_ACCOUNT_ADD_CLAIMS_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* updateConfiguration(action) {
    try {
        const { clientConfigs, callback } = action.payload;
        yield put({ type: actions.CLIENTS_CONFIGURATION_UPDATE_PENDING });
        const payload = yield call(API.editConfiguration, clientConfigs);
        yield put({
            type: actions.CLIENTS_CONFIGURATION_UPDATE_FULFILLED,
            payload
        });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_CONFIGURATION_UPDATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* updateAccountConfiguration(action) {
    try {
        const { clientAccountConfigs, callback } = action.payload;
        yield put({ type: actions.CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_PENDING });
        const payload = yield call(API.editAccountConfiguration, clientAccountConfigs);
        yield put({
            type: actions.CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_FULFILLED,
            payload
        });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_ACCOUNT_CONFIGURATION_UPDATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}



function* getConfiguration(action) {
    try {
        const { clientId } = action.payload;
        yield put({ type: actions.CLIENTS_CONFIGURATION_GET_PENDING });
        const payload = yield call(API.getConfiguration, clientId);
        yield put({
            type: actions.CLIENTS_CONFIGURATION_GET_FULFILLED,
            payload
        });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_CONFIGURATION_GET_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* getAccountConfiguration(action) {
    try {
        const { clientId } = action.payload;
        yield put({ type: actions.CLIENTS_ACCOUNT_CONFIGURATION_GET_PENDING });
        const payload = yield call(API.getAccountConfiguration, clientId);
        yield put({
            type: actions.CLIENTS_ACCOUNT_CONFIGURATION_GET_FULFILLED,
            payload
        });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_ACCOUNT_CONFIGURATION_GET_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteConfiguration(action) {
    try {
        const { clientId, callback } = action.payload;
        yield put({ type: actions.CLIENTS_CONFIGURATION_DELETE_PENDING });
        const payload = yield call(API.deleteConfiguration, clientId);
        yield put({
            type: actions.CLIENTS_CONFIGURATION_DELETE_FULFILLED,
            payload
        });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_CONFIGURATION_DELETE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* getTimezones() {
    try {
        yield put({ type: actions.CLIENTS_GET_TIMEZONES_PENDING });
        const payload = yield call(API.getTimezones);
        yield put({ type: actions.CLIENTS_GET_TIMEZONES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_GET_TIMEZONES_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* getClientProducts(action) {
    try {
        const { clientId } = action.payload;
        yield put({ type: actions.CLIENTS_GET_PRODUCTS_PENDING });
        let payload = yield call(API.getClientProducts, clientId);

        if (payload === undefined) {
            payload = null;
        }

        yield put({
            type: actions.CLIENTS_GET_PRODUCTS_FULFILLED,
            payload: payload
        });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_GET_PRODUCTS_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* getClientProductsForUserEdit(action) {
    try {
        const { clientId } = action.payload;
        yield put({ type: actions.CLIENTS_GET_PRODUCTS_USER_EDIT_PENDING });
        let payload = yield call(API.getClientProducts, clientId);

        if (payload === undefined) {
            payload = null;
        }

        yield put({
            type: actions.CLIENTS_GET_PRODUCTS_USER_EDIT_FULFILLED,
            payload: payload
        });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_GET_PRODUCTS_USER_EDIT_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.CLIENTS_RESET });
}

function* getBranding(action) {
    try {
        yield put({ type: actions.CLIENTS_BRANDING_GET_PENDING });
        const payload = yield call(API.getClientBranding);
        yield put({ type: actions.CLIENTS_BRANDING_GET_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_BRANDING_GET_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* getBrandingDetails(action) {
    try {
        yield put({ type: actions.CLIENTS_BRANDING_DETAILS_GET_PENDING });
        const { facilityId, callback } = action.payload;
        const payload = yield call(API.getClientBrandingDetails, facilityId);
        yield put({ type: actions.CLIENTS_BRANDING_DETAILS_GET_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.CLIENTS_BRANDING_DETAILS_GET_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* createBranding(action) {
    try {
        yield put({ type: actions.CLIENTS_BRANDING_CREATE_PENDING });
        const { branding, callback } = action.payload;

        const newClientBranding = yield call(API.createClientBranding, branding);
        yield put({ type: actions.CLIENTS_BRANDING_CREATE_FULFILLED, newClientBranding });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: "Error occurred, please contact administrator."
        };
        yield put({
            type: actions.CLIENTS_BRANDING_CREATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* updateBranding(action) {
    try {
        yield put({ type: actions.CLIENTS_BRANDING_UPDATE_PENDING });
        const { branding, callback } = action.payload;

        const newClientBranding = yield call(API.updateClientBranding, branding);
        yield put({ type: actions.CLIENTS_BRANDING_UPDATE_FULFILLED, newClientBranding });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: "Error occurred, please contact administrator."
        };
        yield put({
            type: actions.CLIENTS_BRANDING_UPDATE_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.CLIENTS_LIST, list);
    yield takeEvery(actions.CLIENTS_LIST_TINY, listTiny);
    yield takeEvery(actions.CLIENTS_GET, get);
    yield takeEvery(actions.CLIENTS_CREATE, create);
    yield takeEvery(actions.CLIENTS_UPDATE, update);
    yield takeEvery(actions.PARSERS_GET, parserList);
    yield takeEvery(actions.BUCKET_CHECK, bucketAvailable);
    yield takeEvery(actions.FTPUSER_CHECK, ftpUserAvailable);
    yield takeEvery(actions.CLIENTS_DEACTIVATE, deactivate);
    yield takeEvery(actions.CLIENTS_ACTIVATE, activate);

    yield takeEvery(actions.CLIENTS_CONFIGURATION_CREATE, createConfiguration);
    yield takeEvery(actions.CLIENTS_ACCOUNT_CONFIGURATION_CREATE, createAccountConfiguration);
    yield takeEvery(actions.CLIENTS_ACCOUNT_ADD_CLAIMS, addClaims);


    yield takeEvery(actions.CLIENTS_CONFIGURATION_UPDATE, updateConfiguration);
    yield takeEvery(actions.CLIENTS_ACCOUNT_CONFIGURATION_UPDATE, updateAccountConfiguration);

    yield takeEvery(actions.CLIENTS_CONFIGURATION_GET, getConfiguration);
    yield takeEvery(actions.CLIENTS_ACCOUNT_CONFIGURATION_GET, getAccountConfiguration);

    yield takeEvery(actions.CLIENTS_CONFIGURATION_DELETE, deleteConfiguration);
    yield takeEvery(actions.CLIENTS_GET_TIMEZONES, getTimezones);
    yield takeEvery(actions.CLIENTS_GET_PRODUCTS, getClientProducts);
    yield takeEvery(actions.CLIENTS_GET_PRODUCTS_USER_EDIT, getClientProductsForUserEdit);

    yield takeEvery(actions.CLIENTS_BRANDING_GET, getBranding);
    yield takeEvery(actions.CLIENTS_BRANDING_DETAILS_GET, getBrandingDetails);
    yield takeEvery(actions.CLIENTS_BRANDING_CREATE, createBranding);
    yield takeEvery(actions.CLIENTS_BRANDING_UPDATE, updateBranding);
}
