import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const selectMethodTypes = (state: RootState) => state.contracts.methodTypes;
export const selectBaseRateTypes = (state: RootState) => state.contracts.baseRateTypes;
export const selectCarveOutTypes = (state: RootState) => state.contracts.carveOutTypes;
export const selectServicesTypes = (state: any) => state.contracts.services;
export const selectGroups = (state: RootState) => state.contracts.groups;
export const selectAlertUsers = (state: RootState) => state.contracts.alertUsers;
export const selectTiny = (state: RootState) => state.contracts.tiny;
export const selectTinyContractsByState = (state: RootState) => state.contracts.tinyContractsByState;

export const selectContractList = (state: RootState, props) => state.contracts.data;
export const selectFeeScheduleLanding = (state: RootState, props) => state.contracts.feeScheduleLanding;
export const selectPaymentDetails = (state: RootState) => state.contracts.paymentDetails;
export const selectFeeSchduleTypes = (state: RootState) => state.contracts.feeScheduleTypes;
export const selectContractsCriteria = (state: RootState) => state.contracts.contractsCriteria;
export const selectContractStates = (state: RootState) => state.contracts.states;
export const selectContractCasCodes = (state: RootState) => state.contracts.casCodes;
export const selectContractedService = (state: RootState, props) => state.contracts.contractedServices;
export const selectFeeScheduleLabelsTiny = (state: RootState, props) => state.contracts.labelsTiny;

export const selectIsPayer = (state) => state.contracts.isPayer;

export const selectContracts = createSelector(
    selectContractList,
    (contract) => contract,
);

export const createContractSelector = (selectContractId) => createSelector(
    selectContractId,
    selectContracts,
    (contractId, contracts) => contracts.find((contract) => contract.id === parseInt(contractId))
);

export const createPaymentDetailSelector = (payment) => createSelector(
    payment,
    selectPaymentDetails,
    (payment, paymentDetails) => {
        if (payment) {
            return paymentDetails[payment.claimnumber];
        }
        return [];
    }
);

export const createContractedServiceSelector = (selectContractId, selectServiceId) => createSelector(
    selectContractId,
    selectServiceId,
    selectContracts,
    (contractId, serviceId, contracts) => {
        if (contracts && contracts.length > 0) {
            const contract = contracts.find((contract) => contract.id === parseInt(contractId));
            if (contract && contract.contractedService) {
                const service = contract.contractedService.find((service) => service.id === parseInt(serviceId));
                return service;
            }
            return null;
        }
        return null;
    }
)

export const selectGroupsTree = (state) => state.contracts.groupsTree;

export const selectFeeScheduleList = (state) => state.contracts.feeScheduleLanding

export const selectFeeSchedule = createSelector(
    selectFeeScheduleList,
    (contract) => contract,
);

