import { FieldArray } from "formik";
import React, { useState } from "react";

import {
    Button, Grid,
    IconButton,
    InputAdornment, makeStyles,
    TextField
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";
import { asDate, dateRangeOverlaps } from "../../../../util/date";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl: {
        width: '85%',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    autoComplete: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    effectiveBox: {
        height: '100%',
        paddingTop: '25px'
    }
}));

export default function ContractPpsSelectionCriteriaFieldArray({
    values, touched, errors, drgs, setFieldValue
}) {
    const [customErrors, setCustomErrors] = useState([]);

    const classes = useStyles();

    function getError(key, index) {
        if (errors.drgconfigurationToContractXref) {
            if (errors.drgconfigurationToContractXref[index]) {
                return errors.drgconfigurationToContractXref[index][key];
            }
            return false;
        }
        return false;
    }

    function handlePpsChangeHandler(index, criteriaObj) {
        if (criteriaObj) {
            setFieldValue(`drgconfigurationToContractXref.${index}.drgConfigurationId`, criteriaObj);

            const tempXref = [...values.drgconfigurationToContractXref];
            tempXref[index].drgConfigurationId = criteriaObj;

            const filteredConfgs = tempXref.filter(item => item.drgConfigurationId)
            const dateAlreadyExist = filteredConfgs.find(item => {
                const isDateOverlapping = dateRangeOverlaps(criteriaObj.effectiveFrom, criteriaObj.effectiveTo, item.drgConfigurationId.effectiveFrom, item.drgConfigurationId.effectiveTo)

                return isDateOverlapping && item.drgConfigurationId.id !== criteriaObj.id;
            });

            if (dateAlreadyExist) {
                const temp = [...customErrors];
                temp[index] = 'Configuration with same date range already exists. Please select different configuration.'
                setCustomErrors(temp);
                setFieldValue(`drgconfigurationToContractXref.${index}.customPPSError`, 'error');
            } else {
                setFieldValue(`drgconfigurationToContractXref.${index}.customPPSError`, undefined);
                const temp = [...customErrors];
                temp[index] = null
                setCustomErrors(temp);
            }

        } else {
            setFieldValue(`drgconfigurationToContractXref.${index}.drgConfigurationId`, criteriaObj);
            setFieldValue(`drgconfigurationToContractXref.${index}.effectiveFrom`, '');

            const temp = [...customErrors];
            temp[index] = null
            setCustomErrors(temp);
            setFieldValue(`drgconfigurationToContractXref.${index}.customPPSError`, undefined);
        }
    }

    function handleWageIndexChage(e, index) {
        setFieldValue(`drgconfigurationToContractXref.${index}.percentage`, e.target.value);
    }

    function handleEffectiveFromChange(e, index) {
        setFieldValue(`drgconfigurationToContractXref.${index}.effectiveFrom`, e.target.value);
    }

    function handleClearFields() {
        setFieldValue(`drgconfigurationToContractXref`, [{
            drgConfigurationId: null,
            value: '',
            percentage: 100,
            effectiveFrom: ''
        }]);

        setCustomErrors([]);
    }

    return (
        <FieldArray
            name="drgconfigurationToContractXref"
            render={({ remove, insert }) => (
                <Grid container>
                    <Grid item xs={12}>
                        {values.drgconfigurationToContractXref.map((drgCriteria, index) =>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        className={classes.autoComplete}
                                        value={drgCriteria?.drgConfigurationId}
                                        disableClearable={false}
                                        onChange={(e, value) => { handlePpsChangeHandler(index, value) }}
                                        getOptionSelected={(option, value) => option === value}
                                        options={drgs}
                                        getOptionLabel={(option) => `${option.name}`}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                name={`drgconfigurationToContractXref.${index}.drgConfigurationId`}
                                                label="IPPS"
                                                margin="normal"
                                                error={customErrors[index]}
                                                helperText={customErrors[index]}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    {drgCriteria?.drgConfigurationId &&
                                        <Grid className={classes.effectiveBox}>
                                            <Grid><b>Effective Range</b></Grid>
                                            <span>
                                                {asDate(drgCriteria?.drgConfigurationId?.effectiveFrom)}
                                                {' to '}
                                                {asDate(drgCriteria?.drgConfigurationId?.effectiveTo)}
                                            </span>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        type="date"
                                        label="Effective From"
                                        name={`drgconfigurationToContractXref.${index}.effectiveFrom`}
                                        className={classes.textField}
                                        margin="normal"
                                        value={drgCriteria.effectiveFrom}
                                        onChange={e => handleEffectiveFromChange(e, index)}
                                        helperText={getError('effectiveFrom', index)}
                                        error={getError('effectiveFrom', index)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        type="number"
                                        label="Percentage"
                                        name={`drgconfigurationToContractXref.${index}.percentage`}
                                        className={classes.textField}
                                        margin="normal"
                                        value={drgCriteria.percentage}
                                        onChange={e => handleWageIndexChage(e, index)}
                                        helperText={getError('percentage', index)}
                                        error={getError('percentage', index)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                {index === 0 && (
                                    <Grid item xs={3}>
                                        <Grid container>
                                            <IconButton
                                                edge="start"
                                                color="primary"
                                                aria-label="Add"
                                                onClick={() => insert(values.drgconfigurationToContractXref.length, {
                                                    drgConfigurationId: null,
                                                    effectiveFrom: '',
                                                    percentage: '100'

                                                })}
                                                className={classes.iconButton}>
                                                <AddCircleIcon />
                                            </IconButton>
                                            <Button
                                                size="small"
                                                onClick={handleClearFields}
                                                className={classes.clearButton}
                                                startIcon={<DeleteIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}

                                {index !== 0 && (
                                    <Grid item xs={1}>
                                        {values.drgconfigurationToContractXref.length > 1 && (
                                            <IconButton
                                                edge="start"
                                                aria-label="Delete"
                                                onClick={() => {
                                                    remove(index)
                                                    const temp = [...customErrors];
                                                    temp[index] = null
                                                    setCustomErrors(temp);
                                                    setFieldValue(`drgconfigurationToContractXref.${index}.customPPSError`, undefined);
                                                }}
                                                className={classes.iconButton}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        />
    );
}


// (new Date(criteriaObj.effectiveFrom) >= new Date(item.drgConfigurationId.effectiveFrom)
// && new Date(criteriaObj.effectiveFrom) <= new Date(item.drgConfigurationId.effectiveTo))
// ||
// (new Date(criteriaObj.effectiveTo) >= new Date(item.drgConfigurationId.effectiveFrom)
// && new Date(criteriaObj.effectiveTo) <= new Date(item.drgConfigurationId.effectiveTo)
// ))