import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { DetailsPopover } from '../detailsPopover';

const useStyles = makeStyles(theme => ({
    underline: {
        textDecoration: ({ textType }) => textType === 'string' ? 'underline' : 'none',
        color: "#2739c1",
    },
 
}));

export default function PopoverLink(props) {

    const { rowData, columnData, hoverData, title, tabIndex } = props;

    
    const [anchorEl, setAnchorEl] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [popoverTitle, setPopoverTitle] = useState('');
    const classes = useStyles({ textType: typeof columnData });


    function handleHoverColumn(event, rowData) {
        setAnchorEl(event?.target);
        setPopoverTitle(title);
        setIsPopoverOpen(true);
    }

    function popoverLeave() {
        setIsPopoverOpen(false);
    }

    function paperEnter() {
        setIsPopoverOpen(true);
    }


    return (
        <>
            <Link
                to=""
                onClick={(e) => e.preventDefault()}
                aria-owns={`${columnData}-mouse-over-popover`}
                aria-haspopup="true"
                onMouseEnter={(event) => handleHoverColumn(event, rowData)}
                onMouseLeave={popoverLeave}
                className={` ${props?.customClasses} ${classes.underline}`}
                tabIndex={tabIndex}
            >
                {columnData}
            </Link>
           {hoverData && <DetailsPopover
                items={hoverData}
                title={popoverTitle}
                isPopoverOpen={isPopoverOpen}
                anchorEl={anchorEl}
                actions={{
                    popoverLeave,
                    paperEnter
                }}
            />}
        </>
    );
}