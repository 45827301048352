import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import StepTwoComponent from './StepTwo';
import { cleanupAndProcess, errorReset, getNegotiatedRatesState, getPricerConfigContracts, getShoppablesStats, setConfigFromShoppables } from '../../../../store/actions/pricers';

export const StepTwo = connect(
    () => {
        return (state, props) => ({
            stats: state.pricers.shoppablesStats ?? [],
            getStatsLoading: state.pricers.getShoppablesStats.pending,
            getNegotiatedRatesStatePending: state.pricers.getNegotiatedRatesState.pending,
            cleanupAndProcessPending: state.pricers.cleanupAndProcess.pending,
            negotiatedRatesState: state.pricers.negotiatedRatesState
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getShoppablesStats: getShoppablesStats,
            errorReset: errorReset,
            getNegotiatedRatesState: getNegotiatedRatesState,
            cleanupAndProcess: cleanupAndProcess,
            getPricerConfigContracts: getPricerConfigContracts,
            setConfigFromShoppables: setConfigFromShoppables
        }, dispatch)
    })
)(injectIntl(StepTwoComponent));