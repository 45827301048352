export const RULES_GET = 'RULES_GET';
export const RULES_GET_PENDING = 'RULES_GET_PENDING';
export const RULES_GET_FULFILLED = 'RULES_GET_FULFILLED';
export const RULES_GET_REJECTED = 'RULES_GET_REJECTED';

export const RULES_CREATE = 'RULES_CREATE';
export const RULES_CREATE_PENDING = 'RULES_CREATE_PENDING';
export const RULES_CREATE_FULFILLED = 'RULES_CREATE_FULFILLED';
export const RULES_CREATE_REJECTED = 'RULES_CREATE_REJECTED';

export const RULES_UPDATE = 'RULES_UPDATE';
export const RULES_UPDATE_PENDING = 'RULES_UPDATE_PENDING';
export const RULES_UPDATE_FULFILLED = 'RULES_UPDATE_FULFILLED';
export const RULES_UPDATE_REJECTED = 'RULES_UPDATE_REJECTED';

export const RULES_LIST = 'RULES_LIST';
export const RULES_LIST_PENDING = 'RULES_LIST_PENDING';
export const RULES_LIST_FULFILLED = 'RULES_LIST_FULFILLED';
export const RULES_LIST_REJECTED = 'RULES_LIST_REJECTED';

export const RULES_GET_METHOD_TYPES = 'RULES_GET_METHOD_TYPES';
export const RULES_GET_METHOD_TYPES_PENDING = 'RULES_GET_METHOD_TYPES_PENDING';
export const RULES_GET_METHOD_TYPES_FULFILLED = 'RULES_GET_METHOD_TYPES_FULFILLED';
export const RULES_GET_METHOD_TYPES_REJECTED = 'RULES_GET_METHOD_TYPES_REJECTED';

export const RULES_GET_TYPES = 'RULES_GET_TYPES';
export const RULES_GET_TYPES_PENDING = 'RULES_GET_TYPES_PENDING';
export const RULES_GET_TYPES_FULFILLED = 'RULES_GET_TYPES_FULFILLED';
export const RULES_GET_TYPES_REJECTED = 'RULES_GET_TYPES_REJECTED';

export const RULES_GET_GPCIS = 'RULES_GET_GPCIS';
export const RULES_GET_GPCIS_PENDING = 'RULES_GET_GPCIS_PENDING';
export const RULES_GET_GPCIS_FULFILLED = 'RULES_GET_GPCIS_FULFILLED';
export const RULES_GET_GPCIS_REJECTED = 'RULES_GET_GPCIS_REJECTED';

export const RULES_GET_PAYMETHODS = 'RULES_GET_PAYMETHODS';
export const RULES_GET_PAYMETHODS_PENDING = 'RULES_GET_PAYMETHODS_PENDING';
export const RULES_GET_PAYMETHODS_FULFILLED = 'RULES_GET_PAYMETHODS_FULFILLED';
export const RULES_GET_PAYMETHODS_REJECTED = 'RULES_GET_PAYMETHODS_REJECTED';

export const RULES_ERROR_RESET = 'RULES_ERROR_RESET';
export const RULES_RESET = 'RULES_RESET';

export const RULES_GET_CARVEOUT_DESCRIPTION = 'RULES_GET_CARVEOUT_DESCRIPTION';
export const RULES_GET_CARVEOUT_DESCRIPTION_PENDING = 'RULES_GET_CARVEOUT_DESCRIPTION_PENDING';
export const RULES_GET_CARVEOUT_DESCRIPTION_FULFILLED = 'RULES_GET_CARVEOUT_DESCRIPTION_FULFILLED';
export const RULES_GET_CARVEOUT_DESCRIPTION_REJECTED = 'RULES_GET_CARVEOUT_DESCRIPTION_REJECTED';

export const RULES_GET_PAYSOURCES = 'RULES_GET_PAYSOURCES';
export const RULES_GET_PAYSOURCES_PENDING = 'RULES_GET_PAYSOURCES_PENDING';
export const RULES_GET_PAYSOURCES_FULFILLED = 'RULES_GET_PAYSOURCES_FULFILLED';
export const RULES_GET_PAYSOURCES_REJECTED = 'RULES_GET_PAYSOURCES_REJECTED';

export const RULES_GET_MULTIPROC_PRECEDENCES = 'RULES_GET_MULTIPROC_PRECEDENCES';
export const RULES_GET_MULTIPROC_PRECEDENCES_PENDING = 'RULES_GET_MULTIPROC_PRECEDENCES_PENDING';
export const RULES_GET_MULTIPROC_PRECEDENCES_FULFILLED = 'RULES_GET_MULTIPROC_PRECEDENCES_FULFILLED';
export const RULES_GET_MULTIPROC_PRECEDENCES_REJECTED = 'RULES_GET_MULTIPROC_PRECEDENCES_REJECTED';

export const RULES_DISABLE = 'RULES_DISABLE';
export const RULES_DISABLE_PENDING = 'RULES_DISABLE_PENDING';
export const RULES_DISABLE_FULFILLED = 'RULES_DISABLE_FULFILLED';
export const RULES_DISABLE_REJECTED = 'RULES_DISABLE_REJECTED';

export const RULES_ENABLE = 'RULES_ENABLE';
export const RULES_ENABLE_PENDING = 'RULES_ENABLE_PENDING';
export const RULES_ENABLE_FULFILLED = 'RULES_ENABLE_FULFILLED';
export const RULES_ENABLE_REJECTED = 'RULES_ENABLE_REJECTED';
export const RULES_ENABLE_DISABLE_RESET = 'RULES_ENABLE_DISABLE_RESET';

export const RULES_CHANGE_PRECEDENCE = 'RULES_CHANGE_PRECEDENCE';
export const RULES_CHANGE_PRECEDENCE_PENDING = 'RULES_CHANGE_PRECEDENCE_PENDING';
export const RULES_CHANGE_PRECEDENCE_FULFILLED = 'RULES_CHANGE_PRECEDENCE_FULFILLED';
export const RULES_CHANGE_PRECEDENCE_REJECTED = 'RULES_CHANGE_PRECEDENCE_REJECTED';

export const RULES_CODES_VALIDATE = 'RULES_CODES_VALIDATE';
export const RULES_CODES_VALIDATE_PENDING = 'RULES_CODES_VALIDATE_PENDING';
export const RULES_CODES_VALIDATE_FULFILLED = 'RULES_CODES_VALIDATE_FULFILLED';
export const RULES_CODES_VALIDATE_REJECTED = 'RULES_CODES_VALIDATE_REJECTED';
export const RULES_CODES_VALIDATE_RESET = 'RULES_CODES_VALIDATE_RESET';

export const RULES_GET_CRITERIA = 'RULES_GET_CRITERIA';
export const RULES_GET_CRITERIA_PENDING = 'RULES_GET_CRITERIA_PENDING';
export const RULES_GET_CRITERIA_FULFILLED = 'RULES_GET_CRITERIA_FULFILLED';
export const RULES_GET_CRITERIA_REJECTED = 'RULES_GET_CRITERIA_REJECTED';

export const RULES_GET_BY_CONTRACT_ID = 'RULES_GET_BY_CONTRACT_ID';
export const RULES_GET_BY_CONTRACT_ID_PENDING = 'RULES_GET_BY_CONTRACT_ID_PENDING';
export const RULES_GET_BY_CONTRACT_ID_FULFILLED = 'RULES_GET_BY_CONTRACT_ID_FULFILLED';
export const RULES_GET_BY_CONTRACT_ID_REJECTED = 'RULES_GET_BY_CONTRACT_ID_REJECTED';
export const RULES_GET_BY_CONTRACT_ID_RESET = 'RULES_GET_BY_CONTRACT_ID_RESET';

export const RULES_CLONE = 'RULES_CLONE';
export const RULES_CLONE_PENDING = 'RULES_CLONE_PENDING';
export const RULES_CLONE_FULFILLED = 'RULES_CLONE_FULFILLED';
export const RULES_CLONE_REJECTED = 'RULES_CLONE_REJECTED';

export const RULES_SET_CLONE_DIALOG = 'RULES_SET_CLONE_DIALOG';
export const RULES_SET_RULE_STATUS = 'RULES_SET_RULE_STATUS';

export const list = () => ({
    type: RULES_LIST,
});

export const get = (ruleId, callback) => ({
    type: RULES_GET,
    payload: { ruleId, callback },
});

export const create = (rule, callback) => ({
    type: RULES_CREATE,
    payload: { rule, callback },
});

export const update = (id, rule, callback) => ({
    type: RULES_UPDATE,
    payload: { id, rule, callback },
});

export const getMethodTypes = (callback) => ({
    type: RULES_GET_METHOD_TYPES,
    payload: { callback },
});

export const getRuleTypes = (callback) => ({
    type: RULES_GET_TYPES,
    payload: { callback },
});

export const getGpcis = (callback) => ({
    type: RULES_GET_GPCIS,
    payload: { callback },
});

export const getPayMethods = (callback) => ({
    type: RULES_GET_PAYMETHODS,
    payload: { callback },
});

export const errorReset = () => ({
    type: RULES_GET_GPCIS,
});

export const getcarveoutDescriptions = (serviceId, callback) => ({
    type: RULES_GET_CARVEOUT_DESCRIPTION,
    payload: { serviceId, callback },
});

export const getPaySources = () => ({
    type: RULES_GET_PAYSOURCES,
});

export const getMultiPrecedences = () => ({
    type: RULES_GET_MULTIPROC_PRECEDENCES,
});

export const disableRule = (ruleId, callback) => ({
    type: RULES_DISABLE,
    payload: { ruleId, callback }
});

export const enableRule = (ruleId, callback) => ({
    type: RULES_ENABLE,
    payload: { ruleId, callback }
});

export const enableDisableRuleReset = () => ({
    type: RULES_ENABLE_DISABLE_RESET,
});

export const changePrecedence = (options, callback) => ({
    type: RULES_CHANGE_PRECEDENCE,
    payload: { options, callback }
});

export const validate = (codes) => ({
    type: RULES_CODES_VALIDATE,
    payload: { codes },
});

export const getRuleCriteria = () => ({
    type: RULES_GET_CRITERIA
});

export const resetCodesValidate = () => ({
    type: RULES_CODES_VALIDATE_RESET
});

export const getRulesByContractId = (contractId) => ({
    type: RULES_GET_BY_CONTRACT_ID,
    payload: { contractId }
});

export const resetRulesByContractId = () => ({
    type: RULES_GET_BY_CONTRACT_ID_RESET,
});

export const ruleClone = (options, callback) => ({
    type: RULES_CLONE,
    payload: { options, callback }
});

export const setCloneRuleDialog = (cloneRuleDialog) => ({
    type: RULES_SET_CLONE_DIALOG,
    payload: { cloneRuleDialog }
});

export const setRuleStatus = (ruleStatus) => ({
    type: RULES_SET_RULE_STATUS,
    payload: { ruleStatus }
});