import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';

import {
    Portlet, PortletBody,
    PortletHeader, PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import {
    Button, makeStyles, Grid, IconButton, Tooltip, Dialog, DialogContent,
} from "@material-ui/core";
import { ChargeMasterDialog } from './ChargeMasterDialog';
import { PagedTable } from '../../../common';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Edit as EditIcon } from '@material-ui/icons';
import { Announcement } from '../../../common/Announcement';
import { errorToastify } from '../../../util/toast';

const DEFAULT_SORT = [{ id: 'code', direction: 'asc' }];

const useStyles = makeStyles(theme => ({
    // root: {
    //     display: 'flex',
    //     overflowX: 'auto',
    //     flexDirection: 'column',
    // },
    container: {
        flexGrow: 1,
    },
    portlet: {
        display: 'flex',
        flexGrow: 1,
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    loader: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    }
}));

const DEFAULT_HIDDEN_FIELDS = ['drugTypeOfMeasurement', 'drugUnitOfMeasurement', 'ndc'];


export default function ChargeMasterPage({
    fields, history, intl,
    chargeMasterUploadPending, brandId, chargeMasterDownloadTemplatePending,
    chargeMasterData,
    actions: {
        chargeMasterdownloadTemplate, chargeMasterLanding, getBrand, searchGetByName,
        searchReset, resetPageTable, setChargeMasterEditDialog, resetList,resetCriteriaWaiting,chargeMasterUpload
    }
}) {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [isPromptDialogOpen, setPromptDialog] = useState(false);
    const uploadChargeMasterRef = useRef(null);


    useEffect(() => {
        const pageName = {
            name: 'charge-master-landing'
        }
        resetCriteriaWaiting(pageName.name)


        return function cleanup() {
            resetPageTable(pageName.name)
        }
    }, [searchGetByName]);

    useEffect(() => {
        getBrand()
    }, [getBrand]);

    function handleFileUpload() {
        uploadChargeMasterRef.current.click();
    }

    const handleFileChange = (event) => {
        if (event.target.files.length > 0 && event.target.files[0].type === 'text/csv') {
            setFile(event.target.files[0]);
            chargeMasterUpload(event.target.files[0], handleFileUploadCallback, errorCallback);
        }
        else if (event.target.files.length > 0) {
            errorToastify('Invalid format, please make sure the file is in CSV format.');
        }
    }

    const handleFileUploadCallback = () => {
        setPromptDialog(true);
        setFile(null);
        handleRefresh();
        (document.getElementById('charge-master-upload-input') as HTMLInputElement).value = '';
    }

    const errorCallback = () => {
        setFile(null);
        (document.getElementById('charge-master-upload-input') as HTMLInputElement).value = '';
    }

    const handleChargeEdit = (charge) => {
        setChargeMasterEditDialog({ open: true, charge: charge, mode: 'edit' });
    }

    function handleDowloadTemplate() {
        chargeMasterdownloadTemplate(downloadTemplateCallback);
    }

    function downloadTemplateCallback({ file, fileName }) {
        const displayName = brandId ?? fileName
        let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${displayName.replace(/\s/g, '')}_ChargeMaster.csv`;
        a.click();
    }

    function handleAddCharge() {
        setChargeMasterEditDialog({ open: true, charge: null, mode: 'create' });
    }

    function handleLoad(sort, filter, pagination) {
        chargeMasterLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        resetList();
        setRefresh(Promise.resolve());
    }

    function handlePromptDialogClose() {
        setPromptDialog(false);
    }

    function handleRedirectToPricerSetup() {
        history.push('/pricer/configuration');
    }

    const claimAnalysisTableAction = () => {
        return (
            <>
                <Grid item>
                    <Tooltip title="Download Template">
                        <IconButton aria-label="download" size='small' onClick={handleDowloadTemplate} disabled={chargeMasterDownloadTemplatePending}>
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Upload Charge Master">
                        <IconButton aria-label="upload" size='small'
                            onClick={handleFileUpload}
                            disabled={chargeMasterUploadPending}
                        >
                            <i className="fas fa-upload"></i>
                            <input
                                id='charge-master-upload-input'
                                onInput={handleFileChange}
                                ref={uploadChargeMasterRef}
                                type="file"
                                hidden
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    function chargeMasterActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                aria-label="Edit"
                onClick={() => handleChargeEdit(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
        </div>
    }


    return (
        <>
            <Announcement text='This page (Charge Master) is a dependency for both Pricer and Estimator products' backgroudColor={undefined}/>
            <Helmet title="Charge Master" />
            <Portlet >
                <PortletHeader
                    title={'Charge Master'}
                    name="charge-master-landing"
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    handleRefresh={handleRefresh}
                    
                />
                <PortletBody>
                    <div className={classes.container}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PagedTable
                                    title="Charge Master"
                                    name="charge-master-landing"
                                    fields={fields}
                                    data={chargeMasterData}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    onAdd={handleAddCharge}
                                    tableLevelActions={claimAnalysisTableAction}
                                    tableLevelLoading={chargeMasterDownloadTemplatePending || chargeMasterUploadPending}
                                    criteriasSaveEnabled={true}
                                    hasActions={true}
                                    renderActionColumn={chargeMasterActions}
                                    showSearchFilters={true}
                                    defaultHiddenFields={DEFAULT_HIDDEN_FIELDS}
                                    showFilterColumnsDialog={true}


                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>

            <ChargeMasterDialog handleRefresh={handleRefresh} />
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="sm"
                open={isPromptDialogOpen}
                aria-labelledby="form-dialog-title">
                <DialogContent className='p-4'>
                    <Grid container className='pt-2'>
                        <Grid>
                            <p>
                                Charge Master has been uploaded. You need to reprocess negotiated rates. Click OK to reprocess now.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container className='justify-content-end'>
                        <Button
                            onClick={handlePromptDialogClose}
                            variant="contained"
                            color="default"
                            className="mr-3">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleRedirectToPricerSetup}
                            variant="contained"
                            color="default"
                        >
                            OK
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

