import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getEAPGList = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/eapg/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getEAPG = (eapgId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/eapg/${eapgId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createEAPG = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/eapg`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const editEAPG = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/eapg`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteEAPG = (eapgId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/eapg/${eapgId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getEAPGListTiny = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/eapg/list/tiny`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getEAPGListTinyByContract = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/eapg/list/tiny/contract/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getEAPGListByTargetClient = (targetClient) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/eapg/list/tiny/client/${targetClient}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getEAPGDependency = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/eapg/dependencies/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getEAPGVersions = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/eapg/versions`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getEAPGList,
    createEAPG,
    editEAPG,
    deleteEAPG,
    getEAPG,
    getEAPGListTiny,
    getEAPGListTinyByContract,
    getEAPGListByTargetClient,
    getEAPGDependency,
    getEAPGVersions
};