import {
    makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { PagedTable } from '../../common';
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";

const DEFAULT_SORT = [{ id: 'payerName', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function PayerStatisticsPage({
    intl,
    tab, payerListPending, payerData, remitPayerData, payerFields, remitPayerFields, payerContracts, userRole,
    actions: {
        payersStatsLanding,
        remitPayersStatsLanding,
        searchGetByName,
        searchReset,
        resetPagedTable
    }
}) {
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [remitRefresh, setRemitRefresh] = useState(Promise.resolve());

    const classes = useStyles();

    useEffect(() => {
        let pageName = {
            name: 'payers-stats-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    useEffect(() => {
        let pageName = {
            name: 'remit-payers-stats-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    function handleLoad(sort, filter, pagination) {
        payersStatsLanding(pagination, sort, filter);
    }

    function handleRemitLoad(sort, filter, pagination) {
        remitPayersStatsLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handleRemitRefresh() {
        setRemitRefresh(Promise.resolve());
    }

    return (
        <>
            <Helmet title="Payers Statistics" />
            <Portlet>
            <PortletHeader title="Statistics" name={tab === 1 ? "payers-stats-landing":"remit-payers-stats-landing"}/>
                <PortletBody>
                    
                    {tab === 1 &&
                        <>
                        <div className="row">
                            <div className="col-md-12">
                                <PagedTable
                                    name="payers-stats-landing"
                                    fields={payerFields}
                                    data={payerData}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    title="Claim Payer Stats"
                                    tableLevelLoading={payerListPending}
                                />
                            </div>
                        </div>
                        </>
                    }
                    {tab === 2 && 
                        <>
                        <div className="row">
                            <div className="col-md-12">
                                <PagedTable
                                    name="remit-payers-stats-landing"
                                    fields={remitPayerFields}
                                    data={remitPayerData}
                                    onLoad={handleRemitLoad}
                                    refresh={remitRefresh}
                                    defaultSort={DEFAULT_SORT}
                                    title="Remit Payer Stats"
                                    tableLevelLoading={payerListPending}
                                />
                            </div>
                        </div>
                        </>
                    }
                </PortletBody>
            </Portlet>
        </>
    );
}