import { makeStyles } from '@material-ui/core';
import React, { useEffect } from "react";

import { Loadable } from '../../../../../common';

import { FeeScheduleItemsView } from './FeeScheduleItems';

const useStyles = makeStyles(theme => {
    return {
        root: {
            color: '#48465b',
        },
        portlet: {
            'box-shadow': 'none',
            '& .kt-portlet__head': {
                paddingLeft: 0
            }
        },
    }
});

export default function FeeSchedule({ feeScheduleId, feeSchedule, feeSchedulePending,
    actions: { getFeeschedule,getHierarchy }
}) {
    const classes = useStyles();

    useEffect(() => {
        getFeeschedule(feeScheduleId);
    }, [feeScheduleId, getFeeschedule]);

    useEffect(() => {
        getHierarchy()
    }, [getHierarchy]);

    return (
        <Loadable loading={feeSchedulePending}>
            <FeeScheduleItemsView
                feeScheduleId={feeScheduleId}
                feeSchedule={feeSchedule}
            />
        </Loadable>
    );
}
