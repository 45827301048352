import {
    METHOD_POST,
    SERVER_URL
} from '../constants/api';
import { headersSendReceiveJson } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';


export const forgotPassword = (username = '') => {
    return fetch(`${SERVER_URL}/api/v1/authenticate/forgotpassword/${username}`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
    }).then(apiHandleResponse);
};

export const resetPassword = (password = '', token = '') => {
    return fetch(`${SERVER_URL}/api/v1/authenticate/resetpassword/`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify({ password, token }),
    }).then(apiHandleResponse);
};

export const verifyToken = (token) => {
    return fetch(`${SERVER_URL}/api/v1/authenticate/verifytoken`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify({ token }),
    }).then(apiHandleResponse);
};