import {
    METHOD_GET,
    METHOD_PUT,
    METHOD_POST,
    SERVER_URL,
    METHOD_DELETE,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../../store/headers';
import { apiHandleResponse, apiHandleOctetResponse } from '../../util/api';

const getInfoLookup = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/vendors/infolookup`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

//Vendors
const vendorsList = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/vendors/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getVendorContract = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/vendor/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getVendorDocumentList = (contractId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/vendor/documentmetadata/${contractId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createVendorContract = (contract) => {
    return fetch(`${SERVER_URL}/api/v1/cms/vendor`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(contract)
    }).then(apiHandleResponse);
};

const updateVendorContract = (contract) => {
    return fetch(`${SERVER_URL}/api/v1/cms/vendor`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(contract)
    }).then(apiHandleResponse);
};

const uploadVendorDocument = (file, { contractId, documentName, metadata, description }) => {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('contractId', contractId);
    formData.append('documentName', documentName);
    formData.append('metadata', metadata);
    formData.append('description', description);

    return fetch(`${SERVER_URL}/api/v1/cms/vendor/document`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: formData,
    }).then(apiHandleResponse);
};

const getVendorDocument = (documentId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/vendor/document/${documentId}`, {
        method: METHOD_GET,
        headers: headersAuth(),
    }).then(apiHandleOctetResponse);
};

const deleteVendor = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/vendor/${id}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleOctetResponse);
};


export {
    getInfoLookup,
    vendorsList,
    getVendorContract,
    getVendorDocumentList,
    createVendorContract,
    updateVendorContract,
    uploadVendorDocument,
    getVendorDocument,
    deleteVendor
};