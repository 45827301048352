export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_FULFILLED = 'FORGOT_PASSWORD_FULFILLED';
export const FORGOT_PASSWORD_REJECTED = 'FORGOT_PASSWORD_REJECTED';


export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED';
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED';

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const VERIFY_TOKEN_PENDING = 'VERIFY_TOKEN_PENDING';
export const VERIFY_TOKEN_FULFILLED = 'VERIFY_TOKEN_FULFILLED';
export const VERIFY_TOKEN_REJECTED = 'VERIFY_TOKEN_REJECTED';


export const forgotPassword = (username = '', callback) => ({
    type: FORGOT_PASSWORD,
    payload: { username, callback }
});

export const resetPassword = (newPassword = '', token = '', callback) => ({
    type: RESET_PASSWORD,
    payload: { newPassword, token, callback }
});

export const verifyToken = (tokenToverify, callback) => ({
    type: VERIFY_TOKEN,
    payload: { tokenToverify, callback }
});