import {
    METHOD_POST,
    SERVER_URL,
} from '../constants/api';
import { headersAuthSendReceiveJson } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

const list = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/executions`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const stopExecution = (executionId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/stop/${executionId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getClaimExecutionCount = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/count`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const executeClaims = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const downloadReportById = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/analysis/report/${id}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    list,
    stopExecution,
    getClaimExecutionCount,
    executeClaims,
    downloadReportById
};