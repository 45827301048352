import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import EnableDialogComponent from './EnableDialog';
import { selectDeselectAll, setPayerInfoEnableDialog } from '../../../store/actions/payers';
import { setV2 } from '../../../store/actions/pagesV2';

export const EnableDialog = connect(
    () => {
        const selectClaimIds = (state, props) => state.pagesV2['payerinfo-landing'].selected;
        const selectEnableDialog = (state) => state.payers.infoEnableDialog;

        const selectOpen = createSelector(
            selectEnableDialog,
            (infoEnableDialog) => {
                if (infoEnableDialog && infoEnableDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectEnabledClick = createSelector(
            selectEnableDialog,
            (infoEnableDialog) => {
                if (infoEnableDialog && infoEnableDialog?.isEnableClicked) {
                    return true
                }
                return false
            }
        )

        return (state, props) => ({
            open: selectOpen(state),
            isEnableClicked: selectEnabledClick(state),
            selectedItems: selectClaimIds(state, props),
            selectAllStatusPending: state.payers.all.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('payerinfo-landing', 'selected', value),
            setPayerInfoEnableDialog: setPayerInfoEnableDialog,
            selectDeselectAll: selectDeselectAll,
        }, dispatch)
    })
)(injectIntl(EnableDialogComponent));