import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import {createSelector} from 'reselect';

import DashboardConfigurationPageComponent from './DashboardConfigurationPage.js';
import { list, deleteDashboard } from '../../../store/actions/dashboards';
import {selectDashboards} from '../../../store/selectors/dashboards';
import { set } from '../../../store/actions/pages';
import {createFilteredSortedDataSelector} from '../../../util/selector';
import {createStringDataField, createBooleanDataField, createOrderDataField, createIconDataField} from '../../../util/format';

const DEFAULT_SORT = [{id: 'clientName', orderBy: 'asc'}];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Dashboard Name', {sortable: true}),
        createStringDataField('clientName', 'Client Name', {sortable: true}),
        createStringDataField('description', 'Description'),
        createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createOrderDataField('order', 'Order', {sortable: true}),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['dashboards-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['dashboards-landing'].filter,
    selectSort,
    selectDashboards,
);

export const DashboardConfigurationPage = injectIntl(
    connect(
        (state, props) => ({
            dashboards: selectDashboards(state, props),
            fields: selectFields(state),
            dashboardsFilteredData: selectFilteredData(state),
            sort: selectSort(state),
            dashboardListPending: state.dashboards.list.pending,
            dashboardsDeletePending: state.dashboards.delete.pending,
            order: state.pages['dashboards-landing'].order,
            filter: state.pages['dashboards-landing'].filter,
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                dashboardList: list,
                dashboardDelete: deleteDashboard,
                setFilter: (value) => set('dashboards-landing', 'filter', value),
                setOrder: (value) => set('dashboards-landing', 'order', value),
                setSort: (value) => set('dashboards-landing', 'sort', value),
            }, dispatch)
        })
)(DashboardConfigurationPageComponent));