import React from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Loadable } from '../../../common';

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import {
    Button,
    makeStyles,
    TextField,
} from "@material-ui/core";
//import TextInputPassword from '../../../common/TextInput/TextInputPassword'
import { PASSWORD_REGEX } from '../../../store/constants/pages';



const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '80%',
    },
    textArea: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    switch: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '80%',
    },

    fullWidth: {
        width: '100%',
    }
}));

export default function ChangePasswordPage(props) {
    const {
        intl, changePasswordError,
        changePasswordPending, changePasswordErrorMessage,
        actions: { sessionChangePassword } } = props;

    const classes = useStyles();

    const handleCallback = () => {
        props.history.push('/dashboard');
    }

    return (
        <Loadable loading={changePasswordPending}>
            <Formik
                initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                }}
                validate={values => {
                    const errors = {};

                    if (!values.oldPassword) {
                        errors.oldPassword = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!values.newPassword) {
                        errors.newPassword = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    } else if (!values.newPassword.match(PASSWORD_REGEX)) {
                        errors.newPassword = intl.formatMessage({
                            id: "AUTH.VALIDATION.INVALID_PASSWORD"
                        });
                    }

                    if (!values.confirmPassword) {
                        errors.confirmPassword = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    } else if (values.newPassword !== values.confirmPassword) {
                        errors.confirmPassword =
                            "Password and Confirm Password didn't match.";
                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    sessionChangePassword(values.oldPassword, values.newPassword, handleCallback);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                            <Portlet>
                                <PortletHeader
                                    title='Change Password'
                                    toolbar={
                                        <PortletHeaderToolbar>
                                            <Button
                                                component={Link}
                                                to="/dashboard"
                                                variant="contained"
                                                color="default"
                                                className={classes.button}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={changePasswordPending}
                                            >
                                                Change Password
                                            </Button>
                                        </PortletHeaderToolbar>
                                    }
                                />
                                <PortletBody>
                                    {changePasswordError && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{changePasswordErrorMessage}</div>
                                        </div>
                                    )}
                                    <div className={classes.container}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TextField
                                                    required
                                                    type="password"
                                                    label="Old Password"
                                                    name="oldPassword"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.oldPassword}
                                                    helperText={touched.oldPassword && errors.oldPassword}
                                                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                                                />
                                                <TextField
                                                    required
                                                    type="password"
                                                    label="New Password"
                                                    name="newPassword"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.newPassword}
                                                    helperText={touched.newPassword && errors.newPassword}
                                                    error={Boolean(touched.newPassword && errors.newPassword)}
                                                />
                                                <TextField
                                                    required
                                                    label="Confirm Password"
                                                    name="confirmPassword"
                                                    type="password"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.confirmPassword}
                                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </form>
                    )}
            </Formik>
        </Loadable>
    );
}