import { Checkbox } from '@material-ui/core';
import React from 'react'

function RowCheckbox(props) {
    const { customClasses = '', rowDataId, selected, disabledCheckbox, actions: { setSelected } } = props;

    function onCheckboxClick(rowId, isChecked) {

        if (isChecked) {
            const sRows = [
                ...selected,
                rowId,
            ]
            setSelected(sRows);
        } else {
            const sRows = selected.filter(x => x !== rowId);
            setSelected(sRows);
        }
    }

    return (
        <Checkbox
            disabled={disabledCheckbox ? true : false}
            className={customClasses}
            color="primary"
            checked={selected.find(item => item === rowDataId) ? true : false}
            onChange={(event) => onCheckboxClick(rowDataId, event.target.checked)}
            tabIndex={-1}
            disableRipple
        />
    )
}

export default RowCheckbox
