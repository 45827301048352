import React from 'react';
import { DateControl } from './DateControl';
import { DynamicDropdownControl } from './DynamicDropdownControl';
import { TextFieldControl } from './TextFieldControl';
import { DropdownControl } from './DropdownControl';

export default function DashboardControls(props) {
    const { setFilter, values, filters, dashboardId, fiscalYear } = props;

    const onChange = (name, values) => {
        setFilter(name, values);
    }

    const renderControls = () => {
        return filters.map(({ id, datatype, displayName, parameterName1, parameterName2, datasourceId, datavalue, valuetype }, index) => {
            if (datatype === 'Datetime') {
                return <DateControl
                    key={`${parameterName1}-${index}`}
                    setFilter={setFilter}
                    label={displayName}
                    values={values}
                    fiscalYear={fiscalYear}
                    startDateParam={parameterName1}
                    endDateParam={parameterName2}
                />
            } else if (datasourceId === -1) {
                // if -1, means its a static filter
                if (valuetype === "Multiple") {
                    const items = datavalue.split(',');
                    return <DropdownControl
                        key={`${parameterName1}-${index}`}
                        items={items}
                        label={displayName}
                        selectAllLabel="Select all"
                        onChange={(selectedValues) => onChange(parameterName1, selectedValues)}
                    />
                }
                else {
                    return <TextFieldControl
                        key={`${parameterName1}-${index}`}
                        id={`${parameterName1}-${index}`}
                        name={parameterName1}
                        displayName={displayName}
                        setFilter={setFilter}
                        dataValue={datavalue}
                    />
                }
            } else {
                return <DynamicDropdownControl
                    key={`${parameterName1}-${index}`}
                    label={displayName}
                    selectAllLabel="Select all"
                    filterId={id}
                    dashboardId={dashboardId}
                    onChange={(selectedValues) => onChange(parameterName1, selectedValues)}
                />
            }
        });
    }

    return (
        <>
            {renderControls()}
        </>
    );
};