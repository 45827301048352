import { createReducer } from '../../util';
import * as actions from '../actions/services';
import { StatusState } from './common';

const addObjectIfNotExist = (state, service) => {
    const data = state.data;
    const { id } = service;

    const serviceExist = data.find(x => x.id === id);
    if (!serviceExist) {
        return {
            data: [...state.data, service]
        }
    }
};

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    serviceTypes: [],
    serviceDescriptions: [],
    tiny: [],
    codeServicesFromTargetClient: [],
    rulesDialog: null, //{open,true,serviceId:null}
    dependencyDialog: null, //{open:true,serviceId:null}
    dependencies: [],
    get: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getServiceType: {
        ...initialStatusState,
    },
    getTiny: {
        ...initialStatusState,
    },
    getCodeDescription: {
        ...initialStatusState,
    },
    getCodeServicesFromTargetClient: {
        ...initialStatusState,
    },
    getCodeServiceDependencies: {
        ...initialStatusState
    },
    isServiceFromFeeSchedule: false,
    feeScheduleId: null
};

export default createReducer(initialState, {
    [actions.CODE_SERVICES_LIST_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
        data: []
    }),
    [actions.CODE_SERVICES_LIST_FULFILLED]: (state, data) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        data: data,
    }),
    [actions.CODE_SERVICES_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.CODE_SERVICES_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.CODE_SERVICES_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.CODE_SERVICES_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.CODE_SERVICES_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.CODE_SERVICES_GET_FULFILLED]: (state, service) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        ...addObjectIfNotExist(state, service),
    }),
    [actions.CODE_SERVICES_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.CODE_SERVICES_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.CODE_SERVICES_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_TYPE_PENDING]: (state) => ({
        ...state,
        getServiceType: {
            pending: true,
        },
        serviceTypes: [],
    }),
    [actions.CODE_SERVICES_TYPE_FULFILLED]: (state, serviceTypes) => ({
        ...state,
        getServiceType: {
            ...initialStatusState,
        },
        serviceTypes,
    }),
    [actions.CODE_SERVICES_TYPE_REJECTED]: (state, errorMessage) => ({
        ...state,
        getServiceType: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_TINY_PENDING]: (state) => ({
        ...state,
        getTiny: {
            pending: true,
        },
        tiny: []
    }),
    [actions.CODE_SERVICES_TINY_FULFILLED]: (state, tiny) => ({
        ...state,
        getTiny: {
            ...initialStatusState,
        },
        tiny: tiny,
    }),
    [actions.CODE_SERVICES_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_GET_DESCRIPTION_PENDING]: (state) => ({
        ...state,
        getCodeDescription: {
            pending: true,
        },
        serviceDescriptions: []
    }),
    [actions.CODE_SERVICES_GET_DESCRIPTION_FULFILLED]: (state, serviceDescriptions) => ({
        ...state,
        getCodeDescription: {
            ...initialStatusState,
        },
        serviceDescriptions: serviceDescriptions,
    }),
    [actions.CODE_SERVICES_GET_DESCRIPTION_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCodeDescription: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_GET_BY_TARGET_CLIENT_PENDING]: (state) => ({
        ...state,
        getCodeServicesFromTargetClient: {
            pending: true,
        }
    }),
    [actions.CODE_SERVICES_GET_BY_TARGET_CLIENT_FULFILLED]: (state, codeServicesFromTargetClient) => ({
        ...state,
        getCodeServicesFromTargetClient: {
            ...initialStatusState,
        },
        codeServicesFromTargetClient,
    }),
    [actions.CODE_SERVICES_GET_BY_TARGET_CLIENT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCodeServicesFromTargetClient: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_GET_DELETE_DEPENDECIES_PENDING]: (state) => ({
        ...state,
        getCodeServiceDependencies: {
            pending: true,
        }
    }),
    [actions.CODE_SERVICES_GET_DELETE_DEPENDECIES_FULFILLED]: (state, dependencies) => ({
        ...state,
        getCodeServiceDependencies: {
            ...initialStatusState,
        },
        dependencies,
    }),
    [actions.CODE_SERVICES_GET_DELETE_DEPENDECIES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCodeServiceDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CODE_SERVICES_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),

    [actions.CODE_SERVICES_SET_FEE_SCHEDULE]: (state, { isServiceFromFeeSchedule, feeScheduleId }) => ({
        ...state,
        isServiceFromFeeSchedule,
        feeScheduleId
    }),
    [actions.CODE_SERVICES_SET_RULES_DIALOG]: (state, { rulesDialog }) => ({
        ...state,
        rulesDialog
    }),
    [actions.CODE_SERVICES_RESET]: () => ({ ...initialState }),
});