import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import ReorderColumnsComponent from './ReorderColumns';

export const ReorderColumns = (connect(
    () => {

        const selectOpen = (state, props) => state.pagesV2[props.pageName]?.isReorderDialogOpen ?? false

        return (state, props) => ({
            open: selectOpen(state, props)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(ReorderColumnsComponent))); 