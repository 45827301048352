import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import { upload } from '../../../store/actions/contracts';
import DocumentUploadComponent from './DocumentUpload';
import { vendorUpload } from '../../../store/actions/vendors';

export const DocumentUpload = connect(
    () => {

        return (state, props) => ({
            //create dashboard
            contractsUploadPending: state.contracts.upload.pending,

            vendorUploadPending: state.vendors.upload.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            contractUpload: upload,
            vendorUpload: vendorUpload
        }, dispatch)
    })
)(injectIntl(DocumentUploadComponent));