import { createReducer } from '../../util';
import * as actions from '../actions/shoppables';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    shoppableList: [],
    shoppableCounts: {},
    enableDialog: null,// {open:true,isEnableClicked:true}
    counts: {
        ...initialStatusState,
    },
    list: {
        ...initialStatusState,
    },
    updateStatus: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    all: {
        ...initialStatusState,
    },
    export: {
        ...initialStatusState,
    }
};

export default createReducer(initialState, {
    [actions.SHOPPABLE_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.SHOPPABLE_FULFILLED]: (state, shoppableList) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        shoppableList,
    }),
    [actions.SHOPPABLE_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SHOPPABLE_UPDATE_STATUS_PENDING]: (state) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            pending: true,
        },
    }),
    [actions.SHOPPABLE_UPDATE_STATUS_FULFILLED]: (state, payload) => ({
        ...state,
        shoppableList: state.shoppableList.map(
            (shoppable, i) => shoppable.id === payload.id ? { ...shoppable, enabled: payload.enabled } : shoppable //update shoppable status on the base of ID
        ),
        updateStatus: {
            ...initialStatusState,
        },
    }),
    [actions.SHOPPABLE_UPDATE_STATUS_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SHOPPABLE_SELECT_ALL_PENDING]: (state) => ({
        ...state,
        all: {
            pending: true,
        },
    }),
    [actions.SHOPPABLE_SELECT_ALL_FULFILLED]: (state) => ({
        ...state,
        all: {
            ...initialStatusState,
        }
    }),
    [actions.SHOPPABLE_SELECT_ALL_REJECTED]: (state, errorMessage) => ({
        ...state,
        all: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SHOPPABLE_COUNTS_PENDING]: (state) => ({
        ...state,
        counts: {
            pending: true,
        },
    }),
    [actions.SHOPPABLE_COUNTS_FULFILLED]: (state, shoppableCounts) => ({
        ...state,
        counts: {
            ...initialStatusState
        },
        shoppableCounts
    }),
    [actions.SHOPPABLE_COUNTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        counts: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SHOPPABLE_EXPORT_PENDING]: (state) => ({
        ...state,
        export: {
            pending: true,
        },
    }),
    [actions.SHOPPABLE_EXPORT_FULFILLED]: (state) => ({
        ...state,
        export: {
            ...initialStatusState
        },
    }),
    [actions.SHOPPABLE_EXPORT_REJECTED]: (state, errorMessage) => ({
        ...state,
        export: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.SHOPPABLE_SET_ENABLE_DIALOG]: (state, { enableDialog }) => ({
        ...state,
        enableDialog
    }),

    [actions.SHOPPABLE_RESET]: () => ({ ...initialState }),
});