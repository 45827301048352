import React, { useState } from "react";
import { Formik } from "formik";
import { Loadable } from '../../../common';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
    Button, Grid,
    makeStyles,
    TextField,
} from "@material-ui/core";

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";
import { DocumentView } from '../ContractViewPage/DocumentView';
import { MODULE_TYPE_VENDOR } from "../../../store/constants/contract";
import { Card } from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '85%',
    },
    textArea: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        margin: theme.spacing(1),
    },
    title: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
}));

export default function DocumentUpload(props) {
    const {
        intl, contractsUploadPending, contractId, handleUploadCallback, module, vendorUploadPending,
        actions: { contractUpload, vendorUpload } } = props;

    const classes = useStyles();
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    const uploadCallback = () => {
        setFile({});
        handleUploadCallback();
    }

    return (
        <Loadable loading={contractsUploadPending || vendorUploadPending}>
            <Formik
                enableReinitialize={true}
                initialValues={
                    {
                        documentName: '',
                        description: '',
                        file: {},
                    }
                }
                validate={values => {
                    const errors = {};

                    if (!values.documentName) {
                        errors.documentName = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!file) {
                        errors.file = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    return errors;
                }}
                onSubmit={(values, { resetForm }) => {
                    const metadata = {
                        type: file.type,
                    }
                    const documentObj = {
                        contractId,
                        documentName: values.documentName,
                        metadata: JSON.stringify(metadata),
                        description: values.description,
                    }
                    if (module === MODULE_TYPE_VENDOR) {
                        vendorUpload(file, documentObj, uploadCallback)
                        resetForm();
                    } else {
                        contractUpload(file, documentObj, uploadCallback);
                        resetForm();
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                        <Card variant="outlined">
                            <Card.Header as="h5">Upload Document</Card.Header>
                            <Card.Body>
                                <Grid container>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            required
                                            label="Document Name"
                                            name="documentName"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.documentName}
                                            helperText={touched.documentName && errors.documentName}
                                            error={Boolean(touched.documentName && errors.documentName)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            type="text"
                                            multiline
                                            label="Description"
                                            name="description"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            helperText={touched.description && errors.description}
                                            error={Boolean(touched.description && errors.description)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <TextField
                                            type="file"
                                            label="Upload Contract"
                                            name="file"
                                            //ref={uploadInputRef}
                                            className={classes.textField}
                                            margin="normal"
                                            onChange={handleFileChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText={touched.file && errors.file}
                                            error={Boolean(touched.file && errors.file)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className={classes.button}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DocumentView isEditView={true} contractId={contractId} module={module} />
                                    </Grid>
                                </Grid>
                            </Card.Body>
                        </Card>
                    </form>
                )}
            </Formik>
        </Loadable>
    );
}
