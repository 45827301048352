import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { get, getFiles } from '../../../store/actions/statistics';

import { selectStatisticsObject, selectDataset } from '../../../store/selectors/statistics';
import { selectClientListTiny } from "../../../store/selectors/clients";
import {
    selectSessionUserClientId, selectSessionUserPreferredClientId,
    selectIsPartner, selectIsSuperAdmin
} from "../../../store/selectors/session";
import StatisticsPageComponent from './StatisticsPage.js';
import { createSelector } from 'reselect';

const selectDefaultClientFilter = createSelector(
    selectSessionUserClientId,
    selectSessionUserPreferredClientId,
    selectIsSuperAdmin,
    selectIsPartner,
    (sessionClientId, sessionUserPreferredClientId, isSuperAdmin, isPartner) => {
        if (isSuperAdmin) {
            return 'all';
        } else if (isPartner) {
            return sessionUserPreferredClientId;
        } else {
            return sessionClientId;
        }
    }
);

export const StatisticsPage = injectIntl(
    connect(
        (state) => ({
            statisticsPending: state.statistics.get.pending,
            statistics: selectStatisticsObject(state),
            stats: selectDataset(state),
            clients: selectClientListTiny(state),
            isPartner: selectIsPartner(state),
            isSuperAdmin: selectIsSuperAdmin(state),
            sessionClientId: selectSessionUserClientId(state),
            defaultClientFilter: selectDefaultClientFilter(state),
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                statisticsGet: get,
            }, dispatch)
        })
    )(StatisticsPageComponent));