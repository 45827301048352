import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectUserList = (state : RootState, props) => state.users.data;
export const selectUserRoles = (state : RootState, props) => state.users.userRoles;

export const selectUsers = createSelector(
    selectUserList,
    (users) => users,
);

export const createUserSelector = (selectUserId) => createSelector(
    selectUserId,
    selectUsers,
    (userId, users) => {
        return users.find((user) => user.id === parseInt(userId));
    }
);

export const selectUserListTiny = (state : RootState, props) => state.users.tinyList;
export const selectUserObjectTiny = (state : RootState, props) => state.users.tinyObjectAll;
export const selectUsersByRole = (state : RootState, props) => state.users.usersByRole;