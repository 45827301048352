import React, { useState } from "react";

import {
    FormControl,
    makeStyles,
    TextField,
    Grid,
    IconButton,
    Checkbox,
    Typography,
    Chip,
    Tooltip,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    formControlAuto: {
        margin: theme.spacing(2),
        marginTop: 0,
        width: '85%',
    },
    listGrid: {
        maxHeight: '300px',
        overflowY: 'auto'
    },
}));

export default function ContractPatientResponsibilitySelection({
    values, setFieldValue, casCodes
}) {
    const [codes, setCodes] = useState('');
    const [validationError, setValidationError] = useState(null);

    const classes = useStyles();

    const casCodesFilterOption = createFilterOptions({
        stringify: (option: { code: string, shortdesc: string }) => `${option.code} (${option.shortdesc})`,
    });

    const handleDeleteCascode = (index) => {
        const temp = [...values.contractPatientResponsibilityXref];
        temp.splice(index, 1);
        setFieldValue('contractPatientResponsibilityXref', temp);
    }

    const handleCodesChange = (e) => {
        setCodes(e.target.value);
    }

    const handleAddToList = () => {
        const regex = /^[0-9a-zA-Z,]*$/g;
        let newlyAddedItems = [];
        if (codes.match(regex)) {
            const splittedCodes = [...new Set(codes.split(','))];
            let hasError = false;
            for (let item of splittedCodes) {
                if (item !== '1' && item !== '2' && item !== '3') {
                    hasError = true;
                }
                else {
                    const codeObj = casCodes.find(cc => cc.code.toLowerCase() === item.toLowerCase());
                    const isAlreadyExisting = values.contractPatientResponsibilityXref.find(cc => cc.code.toLowerCase() === item.toLowerCase());
                    if (codeObj && isAlreadyExisting === undefined) {
                        newlyAddedItems.push(codeObj);
                    }
                }
            }
            if (hasError) {
                setValidationError('Other allowable codes cannot be used in patient responsibility');
            }
            else {
                setFieldValue("contractPatientResponsibilityXref", [...values.contractPatientResponsibilityXref, ...newlyAddedItems])
                setValidationError(null);
                setCodes('');
            }
        } else {
            setValidationError('Codes are not pasted as per the required format');
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={5}>
                    <TextField
                        type="text"
                        multiline
                        label="Codes"
                        name="codes"
                        className={classes.formControlAuto}
                        placeholder="Type comma separated denial codes to be selected automatically"
                        margin="normal"
                        onChange={e => handleCodesChange(e)}
                        value={codes}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={validationError ?? 'e.g. 1,2,3'}
                        error={Boolean(validationError)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton
                        color="primary"
                        onClick={handleAddToList}
                        edge="start"
                        aria-label="Add"
                        className='mt-3'
                    >
                        <Tooltip title='Add Items from text field to selected list'>
                            <AddIcon />
                        </Tooltip>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={5}>
                    <FormControl className={classes.formControlAuto}>
                        <Autocomplete
                            fullWidth
                            onChange={(e, value) => setFieldValue("contractPatientResponsibilityXref", value)}
                            filterOptions={casCodesFilterOption}
                            value={values.contractPatientResponsibilityXref}
                            multiple
                            options={casCodes}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option?.code}
                            getOptionSelected={(option, value) => option?.code === value?.code}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {`${option?.code} (${option?.shortdesc})`}
                                </React.Fragment>
                            )}
                            renderTags={(tagValue, getTagProps) => {
                                const numTags = tagValue.length;
                                return (
                                    <Typography variant="caption">
                                        {tagValue.slice(0, 2).map((option, index) => (
                                            <Chip
                                                size="small"
                                                color="secondary"
                                                key={index}
                                                label={option?.code}
                                                {...getTagProps({ index })}
                                            />
                                        ))}
                                        {numTags > 2 && ` + ${numTags - 2}`}
                                    </Typography>
                                )
                            }
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Contract Cascodes"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                {values.contractPatientResponsibilityXref.length > 0 &&
                    <Grid item xs={2}>
                        <Grid container className="align-items-end h-100">
                            <p><b>Total Selected:</b> {values.contractPatientResponsibilityXref.length}</p>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} className={`${classes.listGrid} pt-2`}>
                    <ul>
                        {values.contractPatientResponsibilityXref.sort((a, b) => a?.code?.localeCompare(b?.code, 'en', { numeric: true })).map((option, index) => <li key={option?.code}>
                            <Grid container className="align-items-center">
                                <Grid item xs={7}>
                                    {`${option?.code} - ${option?.shortdesc}`}
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        onClick={() => handleDeleteCascode(index)}
                                        edge="start"
                                        aria-label="Delete"
                                        className='p-2'
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </li>
                        )}
                    </ul>
                </Grid>
            </Grid>
        </>
    );
}
