import React from 'react';
import { DataTable } from '../../../../../common';

function AnalysisResultsDialog(props) {
    const { fields, filteredData, order, sort, getResultsPending, refreshLineItems,
        actions: { setOrder, setSort } } = props;

    return (
        <DataTable
            fields={fields}
            data={filteredData}
            order={order}
            onOrder={setOrder}
            sort={sort}
            onSort={setSort}
            loading={getResultsPending}
            displayTotal={true}
            handleRefresh={refreshLineItems}
        />
    )
}

export default AnalysisResultsDialog;
