import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getModifiers = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/modifiers`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createModifier = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/modifier`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const editModifier = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/modifier`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteModifier = (modifierId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/modifier/${modifierId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getModifiersPayMethods = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/modifier/paymethods`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
}

export {
    getModifiers,
    createModifier,
    editModifier,
    deleteModifier,
    getModifiersPayMethods
};