import { createSelector } from 'reselect';

export const selectPayerList = (state) => state.payers.payersList;
export const selectPayerContracts = (state) => state.payers.payerContracts;


export const selectPayers = createSelector(
    selectPayerList,
    (payers) => payers,
);

export const selectCheckboxStatus = createSelector(
    selectPayers,
    (payers) => {
        const enabledPayers = payers.filter(payer => !payer.disabled).length;
        if (payers.length === enabledPayers) {
            return true;
        }
        return false;
    }
)