import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { deleteRule, getFeeschedule, getGroups, getRules, ruleReset } from '../../../../../store/actions/contracts';
import { set } from '../../../../../store/actions/pages';
import { changePrecedence, disableRule, enableDisableRuleReset, enableRule, setCloneRuleDialog } from '../../../../../store/actions/rules';
import { CONFIG_TYPE_EAPG, CONFIG_TYPE_IPPS, CONFIG_TYPE_OPPS, RULE_TYPE_CARVEOUT, RULE_TYPE_DEFAULT, RULE_TYPE_RULE } from '../../../../../store/constants/contract';
import { dateEquators } from '../../../../../store/constants/pages';
import { selectRuleStatus } from '../../../../../store/selectors/rules';
import { createContractRuleTypeField, createNumberDataField, createPayMethodDataField, createPlainDateField, createSecondLevelField, createStringDataField } from '../../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../../util/selector';
import RuleViewComponent from './RuleView';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Name'),
        createContractRuleTypeField('ruleType', 'Rule Type', 'name'),
        createPayMethodDataField('paymethod', 'Pay Method'),
        createNumberDataField('payvalue', 'Pay Value'),
        createSecondLevelField('paySource', 'Pay Source', 'name'),
        createPlainDateField('fromDate', 'From Date', { equators: dateEquators, whiteSpace: 'nowrap' }),
        createPlainDateField('toDate', 'To Date', { equators: dateEquators, whiteSpace: 'nowrap' }),
    ]
);

export const selectRulesList = (state) => state.contracts.rules;
const selectContractData = (state) => state.contracts.data;
const selectServiceTypeObj = (state, props) => props.serviceTypeObj;

const selectRules = createSelector(
    (state, props) => props.serviceId || [],
    selectRulesList,
    selectRuleStatus,
    (serviceId, rules, status) => {
        const rulesList = rules[`rules-${serviceId}`] ?? [];
        if (status) {
            return rulesList.filter(item => item.active);
        }
        return rulesList
    }
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`${props.serviceId}rules-landing`] ? state.pages[`${props.serviceId}rules-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`${props.serviceId}rules-landing`] ? state.pages[`${props.serviceId}rules-landing`].filter : null,
    selectSort,
    selectRules,
);

const selectFormattedData = createSelector(selectFilteredData, selectContractData, selectServiceTypeObj, (items, contractData, serviceType) => {
    let contract = contractData[0];
    // Sort with Precedence
    const precedenceSort = items.sort((a, b) => {
        if (a.precedence < b.precedence) {
            return -1;
        }
        if (a.precedence > b.precedence) {
            return 1;
        }
        return 0;
    })

    let ppsConfigurationRules = [];
    let eapgConfigurationRules = [];
    if(contract) {
        if(serviceType && serviceType.name === 'INPATIENT' && contract.drgconfigurationToContractXref && contract.drgconfigurationToContractXref.length > 0) {
            contract.drgconfigurationToContractXref.forEach(item => {
                let tempRule = {
                    id:item.id,
                    configId: item.drgConfiguration.id,
                    name:item.drgConfiguration.name,
                    ruleType: {id: 0, name: CONFIG_TYPE_IPPS},
                    paymethod: {id: 0, name: "Percentage"},
                    payvalue: item.percentage,
                    paySource: {id: 0, name: "N/A"},
                    fromDate: item.drgConfiguration.effectiveFrom,
                    toDate: item.drgConfiguration.effectiveTo,
                    hideDragAndDrop: true,
                    hideCollapsible: true,
                    hideActionsExceptEdit: true
                };
                ppsConfigurationRules.push(tempRule);
            });

        }
        else if(serviceType && serviceType.name === 'OUTPATIENT' && contract.contractOppsconfigurationXref && contract.contractOppsconfigurationXref.length > 0) {
            contract.contractOppsconfigurationXref.forEach(item => {
                let tempRule = {
                    id:item.id,
                    configId: item.oppsConfiguration.id,
                    name:item.oppsConfiguration.name,
                    ruleType: {id: 0, name: CONFIG_TYPE_OPPS },
                    paymethod: {id: 0, name: "Percentage"},
                    payvalue: item.percentage,
                    paySource: {id: 0, name: "N/A"},
                    fromDate: item.oppsConfiguration.effectiveFrom,
                    toDate: item.oppsConfiguration.effectiveTill,
                    hideDragAndDrop: true,
                    hideCollapsible: true,
                    hideActionsExceptEdit: true
                };
                ppsConfigurationRules.push(tempRule);
            });
        }
        else if(serviceType && serviceType.name === 'OUTPATIENT' && contract.contractEapgconfigurationXref && contract.contractEapgconfigurationXref.length > 0) {
            contract.contractEapgconfigurationXref.forEach(item => {
                let tempRule = {
                    id:item.id,
                    configId: item.eapgConfiguration.id,
                    name:item.eapgConfiguration.name,
                    ruleType: {id: 0, name: CONFIG_TYPE_EAPG },
                    paymethod: {id: 0, name: "Percentage"},
                    payvalue: item.percentage,
                    paySource: {id: 0, name: "N/A"},
                    fromDate: item.eapgConfiguration.effectiveFrom,
                    toDate: item.eapgConfiguration.effectiveTill,
                    hideDragAndDrop: true,
                    hideCollapsible: true,
                    hideActionsExceptEdit: true
                };
                eapgConfigurationRules.push(tempRule);
            });
        }
    }

    // Create grouping of rules
    const carveOutRules = precedenceSort.filter(d => d.ruleType.name === RULE_TYPE_CARVEOUT);
    const typeRules = precedenceSort.filter(d => d.ruleType.name === RULE_TYPE_RULE);
    const defaultRules = precedenceSort.filter(d => d.ruleType.name === RULE_TYPE_DEFAULT);
    return [...ppsConfigurationRules, ...eapgConfigurationRules, ...carveOutRules, ...typeRules, ...defaultRules];
})

export const RuleView = connect(
    () => {
        return (state, props) => ({
            // Rule
            rulesListPending: state.contracts.rulesList.pending,
            fields: selectFields(state as never),
            filteredData: selectFormattedData(state, props),
            order: state.pages[`${props.serviceId}rules-landing`] ? state.pages[`${props.serviceId}rules-landing`].order : null,
            filter: state.pages[`${props.serviceId}rules-landing`] ? state.pages[`${props.serviceId}rules-landing`].filter : null,
            sort: selectSort(state, props),
            codeServices: state.services.data,

            // fee schedule
            feeSchedule: state.contracts.feeSchedule,
            feeSchedulePending: state.contracts.feeScheduleList.pending,

            //enable/disable
            ruleEnablePending: state.rules.enableRule.pending,

            ruleDisablePending: state.rules.disableRule.pending,

            ruleChangePrecedencePending: state.rules.changePrecedence.pending
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getRules: getRules,
            getFeeschedule: getFeeschedule,
            getGroups: getGroups,
            deleteRule: deleteRule,
            setFilter: (value) => set(`${props.serviceId}rules-landing`, 'filter', value),
            setOrder: (value) => set(`${props.serviceId}rules-landing`, 'order', value),
            setSort: (value) => set(`${props.serviceId}rules-landing`, 'sort', value),
            ruleReset: ruleReset,
            disableRule: disableRule,
            enableRule: enableRule,
            enableDisableRuleReset: enableDisableRuleReset,
            changePrecedence: changePrecedence,
            setCloneRuleDialog: setCloneRuleDialog
        }, dispatch)
    })
)(injectIntl(RuleViewComponent));
