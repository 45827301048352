import { createReducer } from '../../util';
import * as actions from '../actions/feeScheduleV2';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    feeSchedules: [],
    directory: null,
    directoryEditDialog: null,
    moveDialog: null,
    hierarchy: [],
    directoryDependencyDialog: null, //{open:true,feeScheduleId:null}
    directoryDependencies: [],
    feeScheduleSearch: [],
    feeScheduleClientSearch: [],
    feeScheduleTargetClientLabels:null,

    getFeeSchedule: {
        ...initialStatusState
    },
    createDirectory: {
        ...initialStatusState
    },
    updateDirectory: {
        ...initialStatusState
    },
    getDirectory: {
        ...initialStatusState
    },
    deleteDirectory: {
        ...initialStatusState
    },
    move: {
        ...initialStatusState
    },
    getHierarchy: {
        ...initialStatusState
    },
    deleteFeeScheduleDirectory: {
        ...initialStatusState
    },
    getFeeScheduleDirectoryDependencies: {
        ...initialStatusState
    },
    getFeeScheduleSearch: {
        ...initialStatusState
    },
    getFeeScheduleSearchFromClient: {
        ...initialStatusState
    },
    getFeeScheduleLabelsFromTargetClients: {
        ...initialStatusState
    },
    feeScheduleLabelsValidate: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {
    [actions.FEE_SCHEDULES_GET_PENDING]: (state) => ({
        ...state,
        getFeeSchedule: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULES_GET_FULFILLED]: (state, feeSchedules) => ({
        ...state,
        getFeeSchedule: {
            ...initialStatusState,
        },
        feeSchedules,
    }),
    [actions.FEE_SCHEDULES_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFeeSchedule: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULES_CREATE_DIRECTORY_PENDING]: (state) => ({
        ...state,
        createDirectory: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULES_CREATE_DIRECTORY_FULFILLED]: (state) => ({
        ...state,
        createDirectory: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULES_CREATE_DIRECTORY_REJECTED]: (state, errorMessage) => ({
        ...state,
        createDirectory: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULES_UPDATE_DIRECTORY_PENDING]: (state) => ({
        ...state,
        updateDirectory: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULES_UPDATE_DIRECTORY_FULFILLED]: (state) => ({
        ...state,
        updateDirectory: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULES_UPDATE_DIRECTORY_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateDirectory: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULES_MOVE_PENDING]: (state) => ({
        ...state,
        move: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULES_MOVE_FULFILLED]: (state) => ({
        ...state,
        move: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULES_MOVE_REJECTED]: (state, errorMessage) => ({
        ...state,
        move: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULES_HIERARCHY_PENDING]: (state) => ({
        ...state,
        getHierarchy: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULES_HIERARCHY_FULFILLED]: (state, hierarchy) => ({
        ...state,
        getHierarchy: {
            ...initialStatusState,
        },
        hierarchy
    }),
    [actions.FEE_SCHEDULES_HIERARCHY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getHierarchy: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULES_DIRECTORY_EDIT_DIALOG]: (state, { directoryEditDialog }) => ({
        ...state,
        directoryEditDialog
    }),
    [actions.FEE_SCHEDULES_SET_MOVE_DIALOG]: (state, { moveDialog }) => ({
        ...state,
        moveDialog
    }),

    [actions.FEE_SCHEDULES_RESET]: (state) => ({
        ...state,
        feeSchedules: []
    }),
    [actions.FEE_SCHEDULES_DIRECTORY_SET_DEPENDENCY_DIALOG]: (state, { directoryDependencyDialog }) => ({
        ...state,
        directoryDependencyDialog
    }),

    [actions.FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getFeeScheduleDirectoryDependencies: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_FULFILLED]: (state, directoryDependencies) => ({
        ...state,
        getFeeScheduleDirectoryDependencies: {
            ...initialStatusState,
        },
        directoryDependencies
    }),
    [actions.FEE_SCHEDULES_DIRECTORY_DELETE_DEPENDENCIES_REJECTED]: (state, { errorMessage }) => ({
        ...state,
        getFeeScheduleDirectoryDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.FEE_SCHEDULES_DIRECTORY_DELETE_PENDING]: (state) => ({
        ...state,
        deleteFeeScheduleDirectory: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULES_DIRECTORY_DELETE_FULFILLED]: (state, types) => ({
        ...state,
        deleteFeeScheduleDirectory: {
            ...initialStatusState,
        },
    }),
    [actions.FEE_SCHEDULES_DIRECTORY_DELETE_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        deleteFeeScheduleDirectory: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),

    [actions.FEE_SCHEDULES_SEARCH_PENDING]: (state) => ({
        ...state,
        getFeeScheduleSearch: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULES_SEARCH_FULFILLED]: (state, feeScheduleSearch) => ({
        ...state,
        getFeeScheduleSearch: {
            ...initialStatusState,
        },
        feeScheduleSearch
    }),
    [actions.FEE_SCHEDULES_SEARCH_REJECTED]: (state) => ({
        ...state,
        getFeeScheduleSearch: {
            ...initialStatusState,
            error: true,
        },
    }),

    

    [actions.FEE_SCHEDULE_SEARCH_FROM_CLIENT_PENDING]: (state) => ({
        ...state,
        getFeeScheduleSearchFromClient: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_SEARCH_FROM_CLIENT_FULFILLED]: (state, feeScheduleClientSearch) => ({
        ...state,
        getFeeScheduleSearchFromClient: {
            ...initialStatusState,
        },
        feeScheduleClientSearch
    }),
    [actions.FEE_SCHEDULE_SEARCH_FROM_CLIENT_REJECTED]: (state) => ({
        ...state,
        getFeeScheduleSearchFromClient: {
            ...initialStatusState,
            error: true,
        },
    }),

    [actions.FEE_SCHEDULES_RESET_DIRECTORY_DEPDENDENCY]: (state) => ({
        ...state,
        directoryDependencies: []
    }),

    

    [actions.FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_PENDING]: (state) => ({
        ...state,
        getFeeScheduleLabelsFromTargetClients: {
            pending: true,
        },
    }),
    [actions.FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_FULFILLED]: (state, feeScheduleTargetClientLabels) => ({
        ...state,
        getFeeScheduleLabelsFromTargetClients: {
            ...initialStatusState,
        },
        feeScheduleTargetClientLabels
    }),
    [actions.FEE_SCHEDULE_LABELS_TARGET_CLIENT_GET_REJECTED]: (state) => ({
        ...state,
        getFeeScheduleLabelsFromTargetClients: {
            ...initialStatusState,
            error: true,
        },
    }),

    [actions.FEE_SCHEDULE_LABELS_VALIDATE_PENDING]: (state,payload) => ({
        ...state,
        feeScheduleLabelsValidate: {
                ...state.feeScheduleLabelsValidate,
                [`pending-${payload.index}`]: true,

            }
    }),
    [actions.FEE_SCHEDULE_LABELS_VALIDATE_FULFILLED]: (state, { index, error }) => ({
        ...state,
        feeScheduleLabelsValidate: {
            ...state.feeScheduleLabelsValidate,
            [`pending-${index}`]: false,
            [`done-${index}`]: error,
        },
        // feeScheduleTargetClientLabels
    }),
    [actions.FEE_SCHEDULE_LABELS_VALIDATE_REJECTED]: (state) => ({
        ...state,
        feeScheduleLabelsValidate: {
            ...initialStatusState,
            error: true,
        },
    }),
});