import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/feeScheduleItems';
import * as API from '../api/feeScheduleItems';
import { sessionErrorHandling } from './session';

function* deleteFeeSchedule(action) {
    try {
        yield put({ type: actions.FEE_SCHEDULE_DELETE_PENDING });
        const {
            feeScheduleId,
            callback,
        } = action.payload;
        yield call(API.deleteFeeSchedule, feeScheduleId);
        yield put({ type: actions.FEE_SCHEDULE_DELETE_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateFeeScheduleItemStatus(action) {
    try {
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_STATUS_PENDING });
        const {
            payload,
            callback,
        } = action.payload;
        yield call(API.updateFeeScheduleItemStatus, payload);
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_STATUS_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_STATUS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_CREATE_PENDING });
        const {
            feeScheduleItem,
            callback,
            shouldReloadGrid
        } = action.payload;

        const payload = {
            ...feeScheduleItem,
        }
        yield call(API.createFeeScheduleItem, payload);
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_CREATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_UPDATE_PENDING });
        const {
            feeScheduleItem,
            callback,
            shouldReloadGrid
        } = action.payload;

        const response = yield call(API.updateFeeScheduleItem, feeScheduleItem);
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_UPDATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid, response);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteFeeScheduleItems(action) {
    try {
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_DELETE_PENDING });
        const {
            feeScheduleItemsIds,
            callback,
        } = action.payload;
        yield call(API.deleteFeeScheduleItems, feeScheduleItemsIds);
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_DELETE_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getCodeTypes(action) {
    try {
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_CODE_TYPES_PENDING });
        const payload = yield call(API.getCodeTypes);
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_CODE_TYPES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_CODE_TYPES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* downloadRates(action) {
    try {
        const { callback, feeScheduleId } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_PENDING });
        const payload = yield call(API.exportFeeScheduleRates, feeScheduleId);
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_FULFILLED });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* uploadRates(action) {
    try {
        const { callback, feeScheduleId, ratesCSV } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_UPLOAD_FILE_PENDING });
        const payload = yield call(API.importFeeScheduleRates, { feeScheduleId, ratesCSV });
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_UPLOAD_FILE_FULFILLED });
        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage, innerException, errorDetails } = (error && error.payload) || { error: '', errorDetails: '', innerException: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEMS_UPLOAD_FILE_REJECTED, payload: { errorMessage, innerException, errorDetails } });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeeScheduleFromClient(action) {
    try {
        const { targetClient } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_GET_FROM_CLIENT_PENDING });
        const payload = yield call(API.getFeeScheduleOfClient, targetClient);
        yield put({ type: actions.FEE_SCHEDULE_GET_FROM_CLIENT_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_GET_FROM_CLIENT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeeScheduleDependencies(action) {
    try {
        const { feeScheduleId } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getFeeScheduleDependencies, feeScheduleId);
        yield put({ type: actions.FEE_SCHEDULE_DELETE_DEPENDENCIES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeescheduleItem(action) {
    try {
        const { feeScheduleItemId } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_ITEM_PENDING });
        const payload = yield call(API.getFeescheduleItem, feeScheduleItemId);
        yield put({ type: actions.FEE_SCHEDULE_ITEM_FULFILLED, payload: payload });

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEM_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getFeescheduleRateLabel(action) {
    try {
        const { feeScheduleId, labelId, refId, setFieldValue, callback } = action.payload;
        yield put({ type: actions.FEE_SCHEDULE_ITEM_LABEL_RATE_PENDING });
        const payload = yield call(API.getFeescheduleRateLabel, feeScheduleId, labelId);
        yield put({ type: actions.FEE_SCHEDULE_ITEM_LABEL_RATE_FULFILLED, payload: payload });
        if (callback) {
            callback(refId, setFieldValue, labelId, payload);
        }

    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.FEE_SCHEDULE_ITEM_LABEL_RATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.FEE_SCHEDULE_ITEMS_STATUS, updateFeeScheduleItemStatus);
    yield takeEvery(actions.FEE_SCHEDULE_ITEMS_CREATE, create);
    yield takeEvery(actions.FEE_SCHEDULE_ITEMS_UPDATE, update);
    yield takeEvery(actions.FEE_SCHEDULE_ITEMS_DELETE, deleteFeeScheduleItems);
    yield takeEvery(actions.FEE_SCHEDULE_ITEMS_CODE_TYPES, getCodeTypes);
    yield takeEvery(actions.FEE_SCHEDULE_ITEMS_DOWNLOAD_TEMPLATE, downloadRates);
    yield takeEvery(actions.FEE_SCHEDULE_ITEMS_UPLOAD_FILE, uploadRates);
    yield takeEvery(actions.FEE_SCHEDULE_DELETE, deleteFeeSchedule);
    yield takeEvery(actions.FEE_SCHEDULE_GET_FROM_CLIENT, getFeeScheduleFromClient);
    yield takeEvery(actions.FEE_SCHEDULE_DELETE_DEPENDENCIES, getFeeScheduleDependencies);
    yield takeEvery(actions.FEE_SCHEDULE_ITEM, getFeescheduleItem);
    yield takeEvery(actions.FEE_SCHEDULE_ITEM_LABEL_RATE, getFeescheduleRateLabel);
}