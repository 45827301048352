import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/modifiers';
import * as API from '../api/modifiers';
import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.MODIFIERS_LANDING_PENDING });
        const payload = yield call(API.getModifiers);
        yield put({ type: actions.MODIFIERS_LANDING_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODIFIERS_LANDING_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.MODIFIERS_CREATE_PENDING });
        const payload = yield call(API.createModifier, values);
        yield put({ type: actions.MODIFIERS_CREATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODIFIERS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.MODIFIERS_UPDATE_PENDING });
        const payload = yield call(API.editModifier, values);
        yield put({ type: actions.MODIFIERS_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODIFIERS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteModifier(action) {
    try {
        const { modifierId, callback } = action.payload;
        yield put({ type: actions.MODIFIERS_DELETE_PENDING });
        const payload = yield call(API.deleteModifier, modifierId);
        yield put({ type: actions.MODIFIERS_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODIFIERS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPaymenthods() {
    try {
        yield put({ type: actions.MODIFIERS_PAYMETHODS_GET_PENDING });
        const payload = yield call(API.getModifiersPayMethods);
        yield put({ type: actions.MODIFIERS_PAYMETHODS_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.MODIFIERS_PAYMETHODS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.MODIFIERS_LANDING, list);
    yield takeEvery(actions.MODIFIERS_CREATE, create);
    yield takeEvery(actions.MODIFIERS_UPDATE, update);
    yield takeEvery(actions.MODIFIERS_DELETE, deleteModifier);
    yield takeEvery(actions.MODIFIERS_PAYMETHODS_GET, getPaymenthods);
}