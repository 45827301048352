import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { toAbsoluteUrl } from "../../../../_metronic";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
    rowGrid: {
        gap: '25px',
        paddingTop: '20px'
    },
    unauthorizedIcon: {
        height: '150px'
    },
    textGrid: {
        paddingTop: '45px',
        flex: '1 1 0%',
        maxWidth: 'max-content'
    },
    portlet: {
        height: 'calc(100% - 12px)'
    },
    heading: {
        fontSize: "20px",
        fontWeight: 'bold'
    },
    text: {
        fontSize: "15px"
    }
}));

export default function FallbackPage({ user, history, menu }) {

    const classes = useStyles();

    useEffect(() => {
        if (menu && menu.length > 0) {
            for (let item of menu) {
                if (item.submenu && item.submenu.length > 0) {
                    history.push(item.submenu[0].page)
                    break
                }
            }
        }
    }, [menu])


    return (
        <>
            <Helmet title="Unauthorized" />
            <Portlet className={classes.portlet}>
                <PortletBody>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container className={classes.rowGrid}>
                                <Grid item>
                                    <img className={classes.unauthorizedIcon} alt="Unauthorized" src={`${toAbsoluteUrl("/media/error/unauthorized.png")}`} />
                                </Grid>
                                <Grid item className={classes.textGrid}>
                                    <span class={classes.heading}>Access Denied </span>
                                    <Grid>
                                        <span className={classes.text}>User '{user?.name}' does not have any module available. <br />Try switching client if you are Partner Admin or Partner User </span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet>
        </>
    );
}
