import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { create, update, get, errorReset } from '../../../../store/actions/dashboardTypes';
import { listTiny } from '../../../../store/actions/dashboardFilters';
import { createDashboardTypeSelector } from "../../../../store/selectors/dashboardTypes";
import { selectDashboardFilterListTiny } from '../../../../store/selectors/dashboardFilters';
import DashboardTypesEditPageComponent from './DashboardTypesEditPage';

export const DashboardTypesEditPage = connect(
    () => {
        const selectDashboardTypeId = (state, props) => props.match.params.dashboardTypeId;

        const selectMode = (state, props) => props.match.params.dashboardTypeId === 'new' ? 'create' : 'edit';

        const selectDashboardType = createDashboardTypeSelector(selectDashboardTypeId);

        const selectInitialValues = createSelector(
            selectMode,
            selectDashboardType,
            (mode, dashboardType) => {
                if (mode === 'edit' && dashboardType) {
                    const payload = {
                        id: dashboardType.id,
                        name: dashboardType.name,
                        description: dashboardType.description,
                    }

                    return payload;
                }

                return {
                    name: '',
                    description: '',
                    filterIds: [],
                }
            }
        )

        return (state, props) => ({
            //create dashboard
            dashboardTypesCreatePending: state.dashboardTypes.create.pending,

            // update dashboard
            dashboardTypesUpdatePending: state.dashboardTypes.update.pending,
            dashboardTypesGetPending: state.dashboardTypes.get.pending,

            // filters
            filters: selectDashboardFilterListTiny(state),
            dashboardFilterListTinyPending: state.dashboardFilters.tiny.pending,

            // edit
            dashboardType: selectDashboardType(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            dashboardTypeId: selectDashboardTypeId(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            dashboardTypeCreate: create,
            dashboardTypeUpdate: update,
            dashboardTypeGet: get,
            dashboardFilterListTiny: listTiny,
            dashboardTypesErrorReset: errorReset,
        }, dispatch)
    })
)(injectIntl(DashboardTypesEditPageComponent));