import {
    METHOD_GET,
    METHOD_POST,
    METHOD_DELETE,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuth } from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

// this is to get the list of dashboard for admin
const listDashboards = () => {
    let endpoint = `${SERVER_URL}/api/v1/dashboard/listall`;
    return fetch(endpoint, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

// this method is to list the dashboard in navigation
const listDashboardsByClientId = (clientId, isSuperAdminOrPartner) => {
    let endpoint = null;
    if (isSuperAdminOrPartner) {
        endpoint = `${SERVER_URL}/api/v1/dashboard/listallforclient/${clientId}`;
    }
    else {
        endpoint = `${SERVER_URL}/api/v1/dashboard/list/${clientId}`;
    }

    return fetch(endpoint, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

// This is to get the dashboard tiny list to display in user section
const listDashboardTinyByClientId = (clientId) => {
    let endpoint = `${SERVER_URL}/api/v1/dashboard/listallforclient/tiny/${clientId}`;
    return fetch(endpoint, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getDashboardUrl = (qs_Id) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/url/${qs_Id}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getDashboard = (dashboardId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/${dashboardId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createDashboard = (dashboard) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(dashboard)
    }).then(apiHandleResponse);
};

const updateDashboard = (dashboard) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(dashboard)
    }).then(apiHandleResponse);
};

const deleteDashboard = (dashboardId) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/${dashboardId}`, {
        method: METHOD_DELETE,
        headers: headersAuth(),
    }).then(apiHandleResponse);
}

const updateSelectedClient = (clientId) => {
    return fetch(`${SERVER_URL}/api/v1/user/preferredclient/${clientId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getDashboardFieldValues = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/dashboard/filter/values`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload)
    }).then(apiHandleResponse);
};

export {
    listDashboards,
    getDashboard,
    getDashboardUrl,
    createDashboard,
    updateDashboard,
    deleteDashboard,
    listDashboardsByClientId,
    updateSelectedClient,
    getDashboardFieldValues,
    listDashboardTinyByClientId,
};